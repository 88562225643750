import React, { Component } from 'react';
import { selectLoggedInSpace, selectLoggedInUserSpaces } from 'src/selectors/spaces';
import * as customPropTypes from 'src/customPropTypes';
import * as userActions from 'src/actions/loggedInUser';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import MenuButton from 'src/components/buttons/MenuButton';
import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/spaceSelectorPopover.scss';

class SpaceSelectorPopover extends Component {
    constructor(props) {
        super(props);
        this.switchSpace = this.switchSpace.bind(this);
    }

    switchSpace(spaceId) {
        const { userSwitchSpaceRequestAction, hidePopover } = this.props;
        userSwitchSpaceRequestAction(spaceId);
        hidePopover();
    }

    render() {
        const { spaces, space, onBackClick } = this.props;

        return (
            <Popover id="spaceSelectorPopover">
                <div className={styles.wrapper}>
                    <MenuButton label="Back" icon="chevron-big-left" onClick={onBackClick} />
                    <hr className={styles.spacer} />
                    <div className={styles.scrollable}>
                        <ButtonGroup vertical>
                            {
                                spaces.map((curr) => (
                                    <MenuButton
                                      key={`space-${curr.id}`}
                                      onClick={() => { this.switchSpace(curr.id); }}
                                      label={curr.name}
                                      activeSpace={curr.id === space.id}
                                    />
                                ))
                            }
                        </ButtonGroup>
                    </div>
                </div>
            </Popover>
        );
    }
}

SpaceSelectorPopover.propTypes = {
    hidePopover: PropTypes.func,
    userSwitchSpaceRequestAction: PropTypes.func.isRequired,
    space: customPropTypes.space.isRequired,
    spaces: customPropTypes.spaces.isRequired,
    onBackClick: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        space: selectLoggedInSpace(state),
        spaces: selectLoggedInUserSpaces(state)
    };
}

export default connect(mapStateToProps, {
    userSwitchSpaceRequestAction: userActions.userSwitchSpaceRequest
})(SpaceSelectorPopover);
