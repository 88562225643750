import {
    CRON_EXPRESSION_TRANSLATION_ERROR,
    CRON_EXPRESSION_TRANSLATION_REQUEST,
    CRON_EXPRESSION_TRANSLATION_SUCCESS,
    CRON_EXPRESSION_NEXT_RUN_DATE_ERROR,
    CRON_EXPRESSION_NEXT_RUN_DATE_REQUEST,
    CRON_EXPRESSION_NEXT_RUN_DATE_SUCCESS,
    STANDARD_SCHEDULE_NEXT_RUN_DATE_ERROR,
    STANDARD_SCHEDULE_NEXT_RUN_DATE_REQUEST,
    STANDARD_SCHEDULE_NEXT_RUN_DATE_SUCCESS,
    DASHBOARD_REPORT_SCHEDULE_CLEANUP
} from 'src/actions/dashboardReportSchedule';
import { combineReducers } from 'redux';
import { setIn } from 'src/reducers/utils';

const translationInitialState = {
    loading: true, translation: '', error: null, requested: true
};
const nextRunDateInitialState = {
    loading: true, date: '', error: null, requested: true
};

export function translationByExpression(state = {}, action) {
    const { type, payload } = action;
    switch (type) {
        case CRON_EXPRESSION_TRANSLATION_REQUEST: {
            const { expression } = payload;
            return Object.assign({}, state, { [expression]: translationInitialState });
        }

        case CRON_EXPRESSION_TRANSLATION_SUCCESS: {
            const { expression, translation } = payload;
            return Object.assign({}, state, {
                [expression]: {
                    loading: false, error: null, translation, requested: true
                }
            });
        }

        case CRON_EXPRESSION_TRANSLATION_ERROR: {
            const { expression, error } = payload;
            return Object.assign({}, state, {
                [expression]: {
                    loading: false, error, translation: '', requested: false
                }
            });
        }
        case DASHBOARD_REPORT_SCHEDULE_CLEANUP: {
            return {};
        }
        default:
            return state;
    }
}

export function nextRunDateByExpressionAndTimezone(state = {}, action) {
    const { type, payload } = action;
    switch (type) {
        case CRON_EXPRESSION_NEXT_RUN_DATE_REQUEST:
        case STANDARD_SCHEDULE_NEXT_RUN_DATE_REQUEST: {
            const { identifier } = payload;
            return setIn(state, identifier, nextRunDateInitialState);
        }

        case CRON_EXPRESSION_NEXT_RUN_DATE_SUCCESS:
        case STANDARD_SCHEDULE_NEXT_RUN_DATE_SUCCESS: {
            const { identifier, date } = payload;
            return setIn(state, identifier, {
                loading: false, error: null, date, requested: true
            });
        }

        case CRON_EXPRESSION_NEXT_RUN_DATE_ERROR:
        case STANDARD_SCHEDULE_NEXT_RUN_DATE_ERROR: {
            const { identifier, error } = payload;
            return setIn(state, identifier, {
                loading: false, error, date: '', requested: false
            });
        }
        case DASHBOARD_REPORT_SCHEDULE_CLEANUP: {
            return {};
        }
        default:
            return state;
    }
}

export const dashboardReportSchedule = combineReducers(
    {
        translationByExpression,
        nextRunDateByExpressionAndTimezone
    }
);
