import { connect } from 'react-redux';
import DashboardMetricScroller from 'src/components/DashboardMetricScroller';
import { makeSelectDashboardMetricLink } from 'src/selectors/filterSelectors';
import { push } from 'react-router-redux';
import { selectNextAndPreviousMetricIdsByDashboardIdAndDashboardMetricId } from 'src/selectors/dashboards';

const makeMapStateToProps = () => {
    const selectDashboardMetricLink = makeSelectDashboardMetricLink();
    return (state, ownProps) => {
        const nextAndPrevious = selectNextAndPreviousMetricIdsByDashboardIdAndDashboardMetricId(
            state,
            ownProps.dashboardId,
            ownProps.dashboardMetricId
        );
        const props = {
            nextLink: undefined,
            previousLink: undefined
        };
        if (nextAndPrevious.next) {
            props.nextLink = selectDashboardMetricLink(state, ownProps.filterContext, ownProps.dashboardId, nextAndPrevious.next);
        }
        if (nextAndPrevious.previous) {
            props.previousLink = selectDashboardMetricLink(state, ownProps.filterContext, ownProps.dashboardId, nextAndPrevious.previous);
        }
        return props;
    };
};

export default connect(makeMapStateToProps, { routePushAction: push })(DashboardMetricScroller);
