import { OPEN_SIDE_BAR, TOGGLE_SIDE_BAR } from 'src/actions/sidebars';

const defaultState = {
    analysis: true,
    metricBuilder: true
};

export const sidebars = (state = defaultState, action) => {
    const { type, payload } = action;
    switch (type) {
        case TOGGLE_SIDE_BAR: {
            const { sidebar } = payload;
            return Object.assign({}, state, { [sidebar]: !state[sidebar] });
        }
        case OPEN_SIDE_BAR: {
            const { sidebar } = payload;
            return Object.assign({}, state, { [sidebar]: true });
        }
        default:
            return state;
    }
};
