import { adAccountForceDeleteSuccess, adAccountDeleteSuccess } from 'src/actions/adAccounts';
import {
    GROUP_DELETE_SUCCESS,
    GROUP_FORCE_DELETE_SUCCESS
} from 'src/actions/groups';
import {
    PROFILE_DELETE_SUCCESS,
    PROFILE_FORCE_DELETE_SUCCESS
} from 'src/actions/profiles';
import {
    POST_TAGS_ARCHIVE_SUCCESS,
    POST_TAGS_FORCE_ARCHIVE_SUCCESS,
    POST_TAGS_REMOVE_SUCCESS,
    POST_TAG_FORCE_REMOVE_SUCCESS
} from 'src/actions/postTags';
import _difference from 'lodash/difference';
import { SET_FILTER_SELECTION } from 'src/actions/filterSelector';
import { combineReducers } from 'redux';
import _has from 'lodash/has';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import _isString from 'lodash/isString';
import {
    getMappingAdditionalFilterToUrlParams,
    defaultDateState,
    defaultProfileState,
    defaultPostTagState,
    defaultPostTextState,
    getDashboardContext,
    computeNewStateForValuesAndConjunction,
    defaultPostTextExcludeState, defaultAdCampaignState
} from 'src/utils/filterSelectors';
import { SHARED_DASHBOARD_BOOTSTRAP_SUCCESS } from 'src/actions/sharedDashboard';
import { FOLDERS_AND_DASHBOARDS_DELETE_SUCCESS } from 'src/actions/folders';
import _omit from 'lodash/omit';
import { DASHBOARD_CLONE_SUCCESS } from 'src/actions/dashboard';

const copyState = (state, sourceContext, destinationContext) => {
    const sourceState = Object.assign({}, state[sourceContext]);
    if (sourceState !== undefined && !_isEmpty(sourceState)) {
        return Object.assign({}, state, { [destinationContext]: sourceState });
    }
    return state;
};

const removeEntityIds = (state, entityIdsName, entityIds) => {
    const updatedStateByContext = {};
    Object.keys(state).forEach((context) => {
        const currentState = state[context];
        Object.assign(updatedStateByContext, { [context]: Object.assign({}, currentState, { [entityIdsName]: _omit(currentState[entityIdsName], entityIds) }) });
    });

    if (!_isEmpty(updatedStateByContext)) {
        return Object.assign({}, state, updatedStateByContext);
    }
    return state;
};

function readOnlyDateSelector(state = defaultDateState, action) {
    const {
        type,
        payload
    } = action;

    switch (type) {
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS: {
            const { dashboardId, filter } = payload;
            return { [getDashboardContext(dashboardId)]: filter.date };
        }
        default:
            return state;
    }
}

function readOnlyPostTextSelector(state = {}, action) {
    const {
        type,
        payload
    } = action;

    switch (type) {
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS: {
            const { dashboardId, filter } = payload;
            if (filter.postText) {
                return { [getDashboardContext(dashboardId)]: filter.postText };
            }
            return state;
        }
        default:
            return state;
    }
}

function readOnlyPostTextExcludeSelector(state = {}, action) {
    const {
        type,
        payload
    } = action;

    switch (type) {
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS: {
            const { dashboardId, filter } = payload;
            if (filter.postTextExclude) {
                return { [getDashboardContext(dashboardId)]: filter.postTextExclude };
            }
            return state;
        }
        default:
            return state;
    }
}

function readOnlyProfileSelector(state = {}, action) {
    const { type, payload } = action;
    switch (type) {
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS: {
            const { dashboardId, filter } = payload;
            return { [getDashboardContext(dashboardId)]: filter.profile };
        }
        default:
            return state;
    }
}

function readOnlyPostTagSelector(state = {}, action) {
    const { type, payload } = action;
    switch (type) {
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS: {
            const { dashboardId, filter } = payload;
            if (filter.postTag) {
                return { [getDashboardContext(dashboardId)]: filter.postTag };
            }
            return state;
        }
        default:
            return state;
    }
}

const profileSelector = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_FILTER_SELECTION: {
            const { context, query } = payload;
            const { profile } = query;
            if (profile && _isString(profile)) {
                const selectionArray = profile.split(' ');

                const groupIds = [];
                const profileIds = [];
                const adAccountIds = [];
                selectionArray.forEach((item) => {
                    const regexTags = /^(group|profile|adAccount)-(\d+)$/;
                    const match = regexTags.exec(item);
                    if (match !== null) {
                        const selectionType = match[1];
                        const id = match[2];

                        if (selectionType === 'group') {
                            groupIds.push(`${id}`);
                        }

                        if (selectionType === 'profile') {
                            profileIds.push(`${id}`);
                        }

                        if (selectionType === 'adAccount') {
                            adAccountIds.push(`${id}`);
                        }
                    }
                });

                const oldState = state[context];
                if (oldState) {
                    if (oldState.selected !== profile) {
                        return Object.assign({}, state, { [context]: { groupIds, profileIds, adAccountIds } });
                    }
                } else {
                    return Object.assign({}, state, { [context]: { groupIds, profileIds, adAccountIds } });
                }
            }
            return state;
        }
        case PROFILE_DELETE_SUCCESS:
        case PROFILE_FORCE_DELETE_SUCCESS: {
            const { profileIds } = payload;
            return removeEntityIds(state, 'profileIds', profileIds);
        }
        case adAccountDeleteSuccess.type:
        case adAccountForceDeleteSuccess.type: {
            const { adAccountIds } = payload;
            return removeEntityIds(state, 'adAccountIds', adAccountIds);
        }
        case GROUP_DELETE_SUCCESS:
        case GROUP_FORCE_DELETE_SUCCESS: {
            const { groupIds } = payload;
            return removeEntityIds(state, 'groupIds', groupIds);
        }
        case FOLDERS_AND_DASHBOARDS_DELETE_SUCCESS: {
            const { dashboardIds } = payload;
            const deletedDashboardContexts = dashboardIds.map((dashboardId) => getDashboardContext(dashboardId));
            let newState = Object.assign({}, state);
            deletedDashboardContexts.forEach((dashboardContext) => {
                newState = _omit(newState, dashboardContext);
            });
            return newState;
        }
        case DASHBOARD_CLONE_SUCCESS: {
            const { cloneDashboardId, dashboard } = payload;
            return copyState(state, getDashboardContext(cloneDashboardId), getDashboardContext(dashboard.id));
        }
        default:
            return state;
    }
};

function dateSelector(state = {}, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_FILTER_SELECTION: {
            const { context, query } = payload;

            if (query.dynamicDate && query.interval && query.timezone) {
                return Object.assign({}, state, {
                    [context]: {
                        interval: query.interval,
                        timezone: query.timezone,
                        dynamicDate: query.dynamicDate
                    }
                });
            }

            if (query.to && query.from && query.interval && query.timezone) {
                return Object.assign({}, state, {
                    [context]: {
                        from: query.from,
                        to: query.to,
                        interval: query.interval,
                        timezone: query.timezone,
                    }
                });
            }
            return state;
        }
        case DASHBOARD_CLONE_SUCCESS: {
            const { cloneDashboardId, dashboard } = payload;
            return copyState(state, getDashboardContext(cloneDashboardId), getDashboardContext(dashboard.id));
        }
        default:
            return state;
    }
}

const postTextSelector = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_FILTER_SELECTION: {
            const { context, query } = payload;
            const mappings = getMappingAdditionalFilterToUrlParams();
            const valuesParam = mappings.postText.values;
            const conjunctionParam = mappings.postText.conjunction;
            const oldState = Object.assign({}, state[context]);
            const newState = computeNewStateForValuesAndConjunction(!_isEmpty(oldState) ? oldState : defaultPostTextState, defaultPostTextState, query, valuesParam, conjunctionParam);
            if (oldState) {
                if (!_isEqual(oldState, newState)) {
                    return Object.assign({}, state, { [context]: newState });
                }
            } else {
                return Object.assign({}, state, { [context]: newState });
            }
            return state;
        }
        case DASHBOARD_CLONE_SUCCESS: {
            const { cloneDashboardId, dashboard } = payload;
            return copyState(state, getDashboardContext(cloneDashboardId), getDashboardContext(dashboard.id));
        }
        default:
            return state;
    }
};

const adCampaignSelector = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_FILTER_SELECTION: {
            const { context, query } = payload;
            const mappings = getMappingAdditionalFilterToUrlParams();
            const valuesParam = mappings.adCampaign.values;
            const conjunctionParam = mappings.adCampaign.conjunction;
            const oldState = Object.assign({}, state[context]);
            const newState = computeNewStateForValuesAndConjunction(!_isEmpty(oldState) ? oldState : defaultAdCampaignState, defaultAdCampaignState, query, valuesParam, conjunctionParam);
            if (oldState) {
                if (!_isEqual(oldState, newState)) {
                    return Object.assign({}, state, { [context]: newState });
                }
            } else {
                return Object.assign({}, state, { [context]: newState });
            }
            return state;
        }
        case DASHBOARD_CLONE_SUCCESS: {
            const { cloneDashboardId, dashboard } = payload;
            return copyState(state, getDashboardContext(cloneDashboardId), getDashboardContext(dashboard.id));
        }
        case SHARED_DASHBOARD_BOOTSTRAP_SUCCESS: {
            const { dashboardId, filter } = payload;
            if (filter.adCampaign) {
                return { [getDashboardContext(dashboardId)]: filter.adCampaign };
            }
            return state;
        }
        default:
            return state;
    }
};

const postTextExcludeSelector = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_FILTER_SELECTION: {
            const { context, query } = payload;
            const mappings = getMappingAdditionalFilterToUrlParams();
            const valuesParam = mappings.postTextExclude.values;
            const conjunctionParam = mappings.postTextExclude.conjunction;
            const oldState = Object.assign({}, state[context]);
            const newState = computeNewStateForValuesAndConjunction(!_isEmpty(oldState) ? oldState : defaultPostTextExcludeState, defaultPostTextExcludeState, query, valuesParam, conjunctionParam);
            if (oldState) {
                if (!_isEqual(oldState, newState)) {
                    return Object.assign({}, state, { [context]: newState });
                }
            } else {
                return Object.assign({}, state, { [context]: newState });
            }
            return state;
        }
        case DASHBOARD_CLONE_SUCCESS: {
            const { cloneDashboardId, dashboard } = payload;
            return copyState(state, getDashboardContext(cloneDashboardId), getDashboardContext(dashboard.id));
        }
        default:
            return state;
    }
};

const postTagSelector = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_FILTER_SELECTION: {
            const { context, query } = payload;
            const mappings = getMappingAdditionalFilterToUrlParams();
            const valuesParam = mappings.postTag.values;
            const conjunctionParam = mappings.postTag.conjunction;
            const untaggedParam = mappings.postTag.untagged;
            const oldState = Object.assign({}, state[context]);
            let newState = computeNewStateForValuesAndConjunction(!_isEmpty(oldState) ? oldState : defaultPostTagState, defaultPostTagState, query, valuesParam, conjunctionParam);
            if (_has(query, untaggedParam)) {
                newState = Object.assign({}, newState, { untagged: query[untaggedParam] === 'true' });
            }

            if (oldState) {
                if (!_isEqual(oldState, newState)) {
                    return Object.assign({}, state, { [context]: newState });
                }
            } else {
                return Object.assign({}, state, { [context]: newState });
            }

            return state;
        }
        case POST_TAGS_ARCHIVE_SUCCESS:
        case POST_TAGS_FORCE_ARCHIVE_SUCCESS: {
            const { postTags } = payload;
            const postTagIds = Object.keys(postTags);
            const updatedStateByContext = {};
            Object.keys(state).forEach((context) => {
                const currentState = state[context];
                const newValues = _difference(currentState.values, postTagIds);
                const newState = Object.assign({}, currentState, { values: newValues });
                Object.assign(updatedStateByContext, { [context]: newState });
            });
            return Object.assign({}, state, updatedStateByContext);
        }
        case POST_TAGS_REMOVE_SUCCESS:
        case POST_TAG_FORCE_REMOVE_SUCCESS: {
            const { ids } = payload;
            const updatedStateByContext = {};
            Object.keys(state).forEach((context) => {
                const currentState = state[context];
                const newValues = _difference(currentState.values, ids);
                const newState = Object.assign({}, currentState, { values: newValues });
                Object.assign(updatedStateByContext, { [context]: newState });
            });
            return Object.assign({}, state, updatedStateByContext);
        }
        case DASHBOARD_CLONE_SUCCESS: {
            const { cloneDashboardId, dashboard } = payload;
            return copyState(state, getDashboardContext(cloneDashboardId), getDashboardContext(dashboard.id));
        }
        default:
            return state;
    }
};

const defaultSelectors = combineReducers({
    profile: (state = defaultProfileState) => state,
    date: (state = defaultDateState) => state,
    postText: (state = defaultPostTextState) => state,
    postTextExclude: (state = defaultPostTextExcludeState) => state,
    postTag: (state = defaultPostTagState) => state,
    adCampaign: (state = defaultAdCampaignState) => state,
});

const contextAwareFilterSelectors = combineReducers({
    profile: profileSelector,
    date: dateSelector,
    postText: postTextSelector,
    postTextExclude: postTextExcludeSelector,
    postTag: postTagSelector,
    adCampaign: adCampaignSelector
});

export const filterSelectors = combineReducers({
    defaultSelectors,
    contextAwareFilterSelectors
});

export const readOnlyFilterSelectors = combineReducers({
    defaultSelectors,
    contextAwareFilterSelectors: combineReducers({
        profile: readOnlyProfileSelector,
        date: readOnlyDateSelector,
        postText: readOnlyPostTextSelector,
        postTextExclude: readOnlyPostTextExcludeSelector,
        postTag: readOnlyPostTagSelector,
        adCampaign: adCampaignSelector
    })
});
