import {
    put, call, takeEvery, select
} from 'redux-saga/effects';
import {
    GET_DATA_PUSH_TASK_REQUEST,
    GET_DATA_PUSH_TASKS_REQUEST,
    getDataPushTaskError,
    getDataPushTaskSuccess,
    getDataPushTasksError,
    getDataPushTasksSuccess,
    dataPushTaskCreateValidationSuccess,
    dataPushTaskCreateValidationError,
    DATA_PUSH_TASK_CREATE_VALIDATION_REQUEST,
    DATA_PUSH_TASK_DELETE_REQUEST,
    DataPushTaskDeleteError,
    dataPushTaskDeleteSuccess,
    DATA_PUSH_TASK_ACTIVATE_REQUEST,
    dataPushTaskActivateError,
    dataPushTaskActivateSuccess,
    DATA_PUSH_TASK_DEACTIVATE_REQUEST,
    dataPushTaskDeactivateError,
    dataPushTaskDeactivateSuccess,
    DATA_PUSH_TASK_UPDATE_REQUEST,
    dataPushTaskUpdateError,
    dataPushTaskUpdateSuccess,
    DATA_PUSH_TASK_CREATE_REQUEST,
    DATA_PUSH_TASK_DESTINATION_UPDATE_REQUEST,
    dataPushTaskDestinationUpdateError,
    dataPushTaskDestinationUpdateSuccess,
    dataPushTaskCreateSuccess,
    dataPushTaskCreateError,
    DATA_PUSH_TASK_DRY_RUN_REQUEST,
    dataPushTaskDryRunError,
    dataPushTaskDryRunSuccess,
    GOOGLE_BIG_QUERY_DESTINATION_CREATE_TABLE_REQUEST,
    googleBigQueryDestinationCreateTableError,
    googleBigQueryDestinationCreateTableSuccess,
    DATA_PUSH_TASK_DESTINATION_CONNECTION_TEST_REQUEST,
    dataPushTaskDestinationConnectionTestError,
    dataPushTaskDestinationConnectionTestSuccess,
    DATA_PUSH_TASK_SCHEDULE_NOW_REQUEST,
    dataPushTaskScheduleNowError,
    dataPushTaskScheduleNowSuccess
} from 'src/actions/dataPushTasks';
import {
    parseDataPushTaskLog, parseDataPushTaskLogs, parseDataPushTask, parseDataPushTaskWithItsDestination, parseDataPushTasksWithDestinations
} from 'src/parsers';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { getFileBlob, handleAuthorizedServerRequest } from 'src/sagas/utils';
import _parseInt from 'lodash/parseInt';
import { showNotification, showTypedNotification } from 'src/actions/notifications';
import { SubmissionError, getFormValues, destroy } from 'redux-form';
import {
    modalHideDataPushTaskDelete,
    modalHideDataPushTaskAdd,
    modalHideDataPushTaskEdit,
    modalHideDataPushTaskDestinationAdd,
    modalHideDataPushTaskDestinationEdit,
    modalShowDataPushTaskDestinationAdd,
    modalHideGoogleBigQueryDestinationCreateTable
} from 'src/actions/overlays';
import {
    googleBigQueryType, awsS3Type, dataPushTaskAddFormName, dataPushTaskDestinationAddFormName
} from 'src/utils/dataPushTasks';
import createFormDataServerRequest from 'src/requestHandling/createFormDataServerRequest';
import objectUrlFileRequest from 'src/requestHandling/objectUrlFileRequest';

function* getDataPushTasksRequest() {
    try {
        const serverRequest = createServerRequest();
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/get-data-push-tasks');
        if (response) {
            const { jsonData } = response;
            const { dataPushTasks, latestDataPushTaskLogs } = jsonData;
            const { parsedDataPushTaskLogs } = parseDataPushTaskLogs(latestDataPushTaskLogs);
            const { parsedDataPushTasks, parsedDataPushTaskDestinations } = parseDataPushTasksWithDestinations(dataPushTasks);
            yield put(getDataPushTasksSuccess(parsedDataPushTasks, parsedDataPushTaskDestinations, parsedDataPushTaskLogs));
        }

        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(getDataPushTasksError(applicationError));
    }
}

function* getDataPushTaskRequest(action) {
    const { id } = action.payload;
    try {
        const serverRequest = createServerRequest({ dataPushTaskId: _parseInt(id) });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/get-data-push-task');
        if (response) {
            const { jsonData } = response;
            const { dataPushTask, latestDataPushTaskLog } = jsonData;
            const parsedDataPushTaskLog = latestDataPushTaskLog ? parseDataPushTaskLog(latestDataPushTaskLog) : null;
            const { parsedDataPushTask, parsedDataPushTaskDestination } = parseDataPushTaskWithItsDestination(dataPushTask);
            yield put(getDataPushTaskSuccess(id, parsedDataPushTask, parsedDataPushTaskDestination, parsedDataPushTaskLog));
        }

        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(getDataPushTaskError(id, applicationError));
    }
}

const getDataPushTaskParams = (payload) => {
    const params = {
        dataSource: payload.dataSource,
        dataSourceColumns: JSON.stringify(payload.dataSourceColumns),
        dynamicDate: payload.dynamicDate,
        timezone: payload.timezone,
        interval: payload.interval,
        dataPushTimeColumnName: payload.dataPushTimeColumnName,
        delivery: payload.deliveryCronExpression.cronExpression
    };
    if (payload.dataPushTaskId) {
        params.dataPushTaskId = payload.dataPushTaskId;
    }
    return params;
};

function* getDataPushTaskDestinationParamsByDestinationType(payload) {
    const {
        dataPushTaskId,
        destinationId,
        destinationType,
        googleBigQueryProjectId,
        googleBigQueryDatasetId,
        googleBigQueryAuth,
        googleBigQueryTimePartitioningType,
        googleBigQueryTimePartitioningField,
        googleBigQueryTableId,
        awsS3AccessKeyId,
        awsS3SecretAccessKey,
        awsS3Region,
        awsS3Bucket,
        awsS3FilePrefix,
        awsS3FileFormat
    } = payload;

    const params = {};

    if (dataPushTaskId) {
        params.dataPushTaskId = dataPushTaskId;
    }

    if (destinationId) {
        params.destinationId = destinationId;
    }

    if (destinationType === googleBigQueryType) {
        if (googleBigQueryAuth && googleBigQueryAuth.url !== '') {
            const authFile = yield getFileBlob(objectUrlFileRequest, googleBigQueryAuth.url);
            Object.assign(params, {
                googleBigQueryAuth: authFile
            });
        }

        Object.assign(params, {
            googleBigQueryProjectId,
            googleBigQueryDatasetId
        });

        if (googleBigQueryTimePartitioningType) {
            params.googleBigQueryTimePartitioningType = googleBigQueryTimePartitioningType;
        }
        if (googleBigQueryTimePartitioningField) {
            params.googleBigQueryTimePartitioningField = googleBigQueryTimePartitioningField;
        }
        if (googleBigQueryTableId) {
            params.googleBigQueryTableId = googleBigQueryTableId;
        }
    }

    if (destinationType === awsS3Type) {
        Object.assign(params, {
            awsS3AccessKeyId,
            awsS3SecretAccessKey,
            awsS3Region,
            awsS3Bucket,
            awsS3FilePrefix,
            awsS3FileFormat
        });
    }
    return params;
}

function* dataPushTaskCreateValidationRequest(action) {
    const { payload } = action;
    const dataPushTaskParams = getDataPushTaskParams(payload);
    try {
        const serverRequest = createServerRequest(dataPushTaskParams);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/validate-data-push-task-creation');
        if (response) {
            yield put(dataPushTaskCreateValidationSuccess());
            yield put(modalHideDataPushTaskAdd());
            yield put(modalShowDataPushTaskDestinationAdd());
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(dataPushTaskCreateValidationError(new SubmissionError({ _error: applicationError })));
    }
}

function* dataPushTaskUpdateRequest(action) {
    const { payload } = action;
    try {
        const serverRequest = createServerRequest(getDataPushTaskParams(payload));
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/update-data-push-task');
        if (response) {
            const { dataPushTask } = response.jsonData;
            const { parsedDataPushTask, parsedDataPushTaskDestination } = parseDataPushTaskWithItsDestination(dataPushTask);
            yield put(dataPushTaskUpdateSuccess(parsedDataPushTask, parsedDataPushTaskDestination));
            yield put(modalHideDataPushTaskEdit());
            yield put(showNotification('Data push task successfully updated.', 'success'));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(dataPushTaskUpdateError(new SubmissionError({ _error: applicationError })));
    }
}

function* DataPushTaskDeleteRequest(action) {
    const { payload } = action;
    const { dataPushTaskId } = payload;

    const params = { dataPushTaskId };
    try {
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/remove-data-push-task');

        if (response) {
            yield put(modalHideDataPushTaskDelete());
            yield put(dataPushTaskDeleteSuccess(dataPushTaskId));
            yield put(showNotification('Data push task successfully removed.', 'success'));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(DataPushTaskDeleteError(new SubmissionError({ _error: applicationError })));
    }
}

function* dataPushTaskActivateRequest(action) {
    const { payload } = action;
    const { dataPushTaskId } = payload;
    try {
        const serverRequest = createServerRequest({ dataPushTaskId });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/activate-data-push-task');

        if (response) {
            const { dataPushTask } = response.jsonData;
            const parsedDataPushTask = parseDataPushTask(dataPushTask);
            yield put(dataPushTaskActivateSuccess(dataPushTaskId, parsedDataPushTask));
            yield put(showNotification('Data push task successfully activated.', 'success'));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification(`Data push task could not be activated, because: ${applicationError.message}`, 'error'));
        yield put(dataPushTaskActivateError(dataPushTaskId, applicationError));
    }
}

function* dataPushTaskDeactivateRequest(action) {
    const { payload } = action;
    const { dataPushTaskId } = payload;
    try {
        const serverRequest = createServerRequest({ dataPushTaskId });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/deactivate-data-push-task');

        if (response) {
            const { dataPushTask } = response.jsonData;
            const parsedDataPushTask = parseDataPushTask(dataPushTask);
            yield put(dataPushTaskDeactivateSuccess(dataPushTaskId, parsedDataPushTask));
            yield put(showNotification('Data push task successfully deactivated.', 'success'));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification('Data push task could not be deactivated', 'error'));
        yield put(dataPushTaskDeactivateError(dataPushTaskId, applicationError));
    }
}

function* getCreateDataPushTaskParamsAndEndpoint(payload) {
    const { destinationType } = payload;
    const params = yield getDataPushTaskDestinationParamsByDestinationType(payload);
    const formValueSelector = getFormValues(dataPushTaskAddFormName);
    const dataPushTaskFormValues = yield select(formValueSelector);
    const dataPushTaskParams = getDataPushTaskParams(dataPushTaskFormValues);
    Object.assign(params, dataPushTaskParams);
    return {
        endpoint: destinationType === googleBigQueryType ? '/client-index/create-data-push-task-with-google-big-query-destination' : '/client-index/create-data-push-task-with-aws-s3-destination',
        params
    };
}

function* getUpdateDataPushTaskParamsAndEndpoint(payload) {
    const { destinationType } = payload;
    return {
        endpoint: destinationType === googleBigQueryType ? '/client-index/update-google-big-query-data-push-task-destination' : '/client-index/update-aws-s3-data-push-task-destination',
        params: yield call(getDataPushTaskDestinationParamsByDestinationType, payload)
    };
}

function* dataPushTaskDestinationCreateRequest(action) {
    const { payload } = action;
    try {
        const { params, endpoint } = yield call(getCreateDataPushTaskParamsAndEndpoint, payload);
        const serverRequest = createFormDataServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, endpoint);

        if (response) {
            const { dataPushTask } = response.jsonData;
            const { parsedDataPushTask, parsedDataPushTaskDestination } = parseDataPushTaskWithItsDestination(dataPushTask);
            yield put(dataPushTaskCreateSuccess(parsedDataPushTask, parsedDataPushTaskDestination));
            yield put(modalHideDataPushTaskDestinationAdd());
            yield put(destroy(dataPushTaskAddFormName));
            yield put(destroy(dataPushTaskDestinationAddFormName));
            yield put(showNotification('Data push task successfully created.', 'success'));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(dataPushTaskCreateError(new SubmissionError({ _error: applicationError })));
        yield put(showNotification('Data push task creation failed.', 'error'));
    }
}

function* dataPushTaskDestinationUpdateRequest(action) {
    const { payload } = action;
    try {
        const { params, endpoint } = yield call(getUpdateDataPushTaskParamsAndEndpoint, payload);
        const serverRequest = createFormDataServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, endpoint);

        if (response) {
            const { dataPushTask } = response.jsonData;
            const { parsedDataPushTask, parsedDataPushTaskDestination } = parseDataPushTaskWithItsDestination(dataPushTask);
            yield put(dataPushTaskDestinationUpdateSuccess(parsedDataPushTask, parsedDataPushTaskDestination));
            yield put(modalHideDataPushTaskDestinationEdit());
            yield put(showNotification('Data push task destination was successfully updated.', 'success'));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(dataPushTaskDestinationUpdateError(new SubmissionError({ _error: applicationError })));
    }
}

function* dataPushTaskDryRunRequest(action) {
    const { payload } = action;
    const { dataPushTaskId } = payload;
    try {
        const serverRequest = createFormDataServerRequest({ dataPushTaskId });
        yield put(showNotification('Dry run is now being performed and another message will show up once it’s done.', 'info'));
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/dry-run-data-push-task');
        if (response) {
            yield put(dataPushTaskDryRunSuccess(dataPushTaskId));
            yield put(showTypedNotification('DataPushTaskDryRunSuccessAndFailedNotification', { dataPushTaskId }, dataPushTaskId, 'success', 8));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(dataPushTaskDryRunError(dataPushTaskId, applicationError));
        yield put(showTypedNotification('DataPushTaskDryRunSuccessAndFailedNotification', { dataPushTaskId }, dataPushTaskId, 'error', 8));
    }
}

function* dataPushTaskScheduleNowRequest(action) {
    const { payload } = action;
    const { dataPushTaskId } = payload;
    try {
        const serverRequest = createFormDataServerRequest({ dataPushTaskId });
        yield put(showNotification('Your task is now being scheduled.', 'info'));
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/schedule-now-data-push-task');
        if (response) {
            yield put(dataPushTaskScheduleNowSuccess(dataPushTaskId));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(dataPushTaskScheduleNowError(dataPushTaskId, applicationError));
        yield put(showNotification(`Push data failed. ${applicationError.message}`, 'error'));
    }
}

function* googleBigQueryDestinationCreateTableRequest(action) {
    const { payload } = action;
    const { dataPushTaskId, destinationId } = payload;
    try {
        const serverRequest = createFormDataServerRequest({ dataPushTaskId, destinationId });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/create-google-big-query-destination-table');
        if (response) {
            yield put(googleBigQueryDestinationCreateTableSuccess());
            yield put(showNotification('Table successfully created.', 'success'));
            yield put(modalHideGoogleBigQueryDestinationCreateTable());
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(googleBigQueryDestinationCreateTableError(new SubmissionError({ _error: applicationError })));
    }
}

function* dataPushTaskDestinationConnectionTestRequest(action) {
    const { payload } = action;
    const { dataPushTaskId, destinationId } = payload;
    try {
        const serverRequest = createFormDataServerRequest({ dataPushTaskId, destinationId });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/test-data-push-task-destination-connection');
        if (response) {
            yield put(dataPushTaskDestinationConnectionTestSuccess(destinationId));
            yield put(showNotification('Connection test successfully passed.', 'success'));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(dataPushTaskDestinationConnectionTestError(destinationId, applicationError));
        yield put(showNotification(`Connection test failed: reason: ${applicationError.message}`, 'error'));
    }
}

export default function* dataPushTasksSagas() {
    yield takeEvery(GET_DATA_PUSH_TASKS_REQUEST, getDataPushTasksRequest);
    yield takeEvery(GET_DATA_PUSH_TASK_REQUEST, getDataPushTaskRequest);
    yield takeEvery(DATA_PUSH_TASK_UPDATE_REQUEST, dataPushTaskUpdateRequest);
    yield takeEvery(DATA_PUSH_TASK_DELETE_REQUEST, DataPushTaskDeleteRequest);
    yield takeEvery(DATA_PUSH_TASK_ACTIVATE_REQUEST, dataPushTaskActivateRequest);
    yield takeEvery(DATA_PUSH_TASK_DEACTIVATE_REQUEST, dataPushTaskDeactivateRequest);
    yield takeEvery(DATA_PUSH_TASK_CREATE_VALIDATION_REQUEST, dataPushTaskCreateValidationRequest);
    yield takeEvery(DATA_PUSH_TASK_CREATE_REQUEST, dataPushTaskDestinationCreateRequest);
    yield takeEvery(DATA_PUSH_TASK_DESTINATION_UPDATE_REQUEST, dataPushTaskDestinationUpdateRequest);
    yield takeEvery(DATA_PUSH_TASK_DRY_RUN_REQUEST, dataPushTaskDryRunRequest);
    yield takeEvery(DATA_PUSH_TASK_SCHEDULE_NOW_REQUEST, dataPushTaskScheduleNowRequest);
    yield takeEvery(GOOGLE_BIG_QUERY_DESTINATION_CREATE_TABLE_REQUEST, googleBigQueryDestinationCreateTableRequest);
    yield takeEvery(DATA_PUSH_TASK_DESTINATION_CONNECTION_TEST_REQUEST, dataPushTaskDestinationConnectionTestRequest);
}
