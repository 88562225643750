import React from 'react';
import styles from 'src/stylesheets/views/settings/userListItem.scss';
import PropTypes from 'prop-types';

const UserDetail = ({ topRow, smallDetail, smallestDetail }) => (
    <div className={styles.userDetail}>
        <div className={styles.userName}>{topRow}</div>
        <div className={styles.smallDetail}>
            {smallDetail}
        </div>
        <div className={styles.smallestDetail}>{smallestDetail}</div>
    </div>
);

UserDetail.propTypes = {
    topRow: PropTypes.node,
    smallDetail: PropTypes.node,
    smallestDetail: PropTypes.node,
};

export default UserDetail;
