import Body from 'src/components/Body';
import Footer from 'src/components/Footer';
import React from 'react';
import ScrollView from 'src/components/ScrollView';
import SpaceHeader from 'src/components/header/SpaceHeader';
import SpaceListTable from 'src/components/views/settings/space/SpaceListTable';
import { selectSpaces } from 'src/selectors/spaces';
import AdminSettingsLoader from 'src/components/views/settings/AdminSettingsLoader';

const SpaceManagement = () => (
    <ScrollView dataViewName="space-management">
        <SpaceHeader />
        <Body>
            <AdminSettingsLoader dataSelector={selectSpaces} isListTable>
                {
                    (spaces) => <SpaceListTable spaces={spaces} />
                }
            </AdminSettingsLoader>
        </Body>
        <Footer />
    </ScrollView>
);

export default SpaceManagement;
