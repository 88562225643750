import {
    GET_DATA_PUSH_TASK_LOGS_SUCCESS,
    GET_DATA_PUSH_TASK_LOG_SUCCESS,
    GET_DATA_PUSH_TASK_LOG_ERROR,
    GET_DATA_PUSH_TASK_LOG_REQUEST,
    GET_DATA_PUSH_TASK_LOG_DATA_SUCCESS,
    GET_DATA_PUSH_TASK_LOG_DATA_ERROR,
    GET_DATA_PUSH_TASK_LOG_DATA_REQUEST
} from 'src/actions/dataPushTaskLogs';
import {
    GET_DATA_PUSH_TASKS_SUCCESS,
    GET_DATA_PUSH_TASK_SUCCESS
} from 'src/actions/dataPushTasks';
import _uniq from 'lodash/uniq';
import { combineReducers } from 'redux';
import { createAsyncStatesReducerForActionsWithErrorAndSuccessStates } from 'src/reducers/utils';

const allIds = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_DATA_PUSH_TASKS_SUCCESS: {
            const { latestDataPushTaskLogs } = payload;
            return _uniq([...state, ...Object.keys(latestDataPushTaskLogs)]);
        }
        case GET_DATA_PUSH_TASK_SUCCESS:
        case GET_DATA_PUSH_TASK_LOG_SUCCESS: {
            const { dataPushTaskLog } = payload;
            if (dataPushTaskLog) {
                return _uniq([...state, dataPushTaskLog.id]);
            }
            return state;
        }
        case GET_DATA_PUSH_TASK_LOGS_SUCCESS: {
            const { dataPushTaskLogs } = payload;
            return _uniq([...state, ...Object.keys(dataPushTaskLogs)]);
        }
        default:
            return state;
    }
};

const byIds = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_DATA_PUSH_TASK_LOGS_SUCCESS: {
            const { dataPushTaskLogs } = payload;
            return Object.assign({}, state, dataPushTaskLogs);
        }
        case GET_DATA_PUSH_TASKS_SUCCESS: {
            const { latestDataPushTaskLogs } = payload;
            return Object.assign({}, state, latestDataPushTaskLogs);
        }
        case GET_DATA_PUSH_TASK_SUCCESS:
        case GET_DATA_PUSH_TASK_LOG_SUCCESS: {
            const { dataPushTaskLog } = payload;
            if (dataPushTaskLog) {
                return Object.assign({}, state, { [dataPushTaskLog.id]: dataPushTaskLog });
            }
            return state;
        }
        default:
            return state;
    }
};

const logData = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_DATA_PUSH_TASK_LOG_DATA_SUCCESS: {
            const { id, data } = payload;
            return Object.assign({}, state, { [id]: data });
        }
        default:
            return state;
    }
};

const asyncStates = combineReducers({
    get: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        (payload) => payload.id,
        [GET_DATA_PUSH_TASK_LOG_REQUEST],
        [GET_DATA_PUSH_TASK_LOG_SUCCESS],
        [GET_DATA_PUSH_TASK_LOG_ERROR]
    ),
    getData: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        (payload) => payload.id,
        [GET_DATA_PUSH_TASK_LOG_DATA_REQUEST],
        [GET_DATA_PUSH_TASK_LOG_DATA_SUCCESS],
        [GET_DATA_PUSH_TASK_LOG_DATA_ERROR]
    )
});

export const dataPushTaskLogs = combineReducers({
    allIds,
    byIds,
    logData,
    asyncStates
});
