import React from 'react';
import NetworkIcon from 'src/components/NetworkIcon';
import PropTypes from 'prop-types';

const getPlatformTypeByEnvironment = (authUserEnvironment) => {
    switch (authUserEnvironment) {
        case 'graph':
            return 'meta';
        case 'snapchat':
            return 'snapchatShow';
        default:
            return authUserEnvironment;
    }
};

const AuthUserEnvIcon = (props) => {
    const { environment, className, isDeprecated } = props;
    const platformType = getPlatformTypeByEnvironment(environment);
    return <NetworkIcon isDeprecated={isDeprecated} platformType={platformType} className={className} />;
};

AuthUserEnvIcon.propTypes = {
    environment: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired.propTypes,
    isDeprecated: PropTypes.bool
};

AuthUserEnvIcon.defaultProps = {
    isDeprecated: false
};

export default AuthUserEnvIcon;
