import React from 'react';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { dataPushTaskDestinationConnectionTestRequest } from 'src/actions/dataPushTasks';
import * as customPropTypes from 'src/customPropTypes';
import { makeSelectIsDataPushTaskDestinationConnectionTesting } from 'src/selectors/dataPushTaskDestination';

const DataPushTaskDestinationTestButton = (props) => {
    const { isTesting } = props;
    const handleOnClick = () => {
        const { dataPushTaskDestination, dataPushTaskDestinationConnectionTestRequestAction } = props;
        const { id, dataPushTaskId } = dataPushTaskDestination;
        dataPushTaskDestinationConnectionTestRequestAction(dataPushTaskId, id);
    };

    return (
        <Button label="Test connection" loading={isTesting} onClick={handleOnClick} />
    );
};

DataPushTaskDestinationTestButton.propTypes = {
    isTesting: PropTypes.bool.isRequired,
    dataPushTaskDestination: customPropTypes.dataPushTaskDestination.isRequired,
    dataPushTaskDestinationConnectionTestRequestAction: PropTypes.func.isRequired
};

const mapStateToProps = () => {
    const selectIsDataPushTaskDestinationConnectionTesting = makeSelectIsDataPushTaskDestinationConnectionTesting();
    return (state, ownProps) => ({
        isTesting: selectIsDataPushTaskDestinationConnectionTesting(state, ownProps.dataPushTaskDestination.id)
    });
};

export default connect(mapStateToProps, {
    dataPushTaskDestinationConnectionTestRequestAction: dataPushTaskDestinationConnectionTestRequest
})(DataPushTaskDestinationTestButton);
