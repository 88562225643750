import React from 'react';
import styles from 'src/stylesheets/pageNumbersWithScroller.scss';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const PaginatorItem = (props) => {
    const { onPageClick, pageNumber, selected } = props;
    return (
        <li>
            <div
              onClick={() => { onPageClick(pageNumber); }}
              className={
                  classnames(styles.listItem,
                      {
                          [styles.selected]: selected
                      })
              }
            >
                {pageNumber}
            </div>
        </li>
    );
};

PaginatorItem.propTypes = {
    onPageClick: PropTypes.func.isRequired,
    pageNumber: PropTypes.number.isRequired,
    selected: PropTypes.bool
};

PaginatorItem.defaultProps = {
    selected: false
};

export default PaginatorItem;
