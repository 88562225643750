import React, { Component } from 'react';
import _throttle from 'lodash/throttle';
import getOffset from 'dom-helpers/offset';
import PropTypes from 'prop-types';
import scrollParent from 'src/utils/scrollParent';

const containerStyle = { height: 80 };

const withStickyListHeaderHandling = (WrappedComponent) => {
    class WithStickyListHeaderHandling extends Component {
        constructor(props) {
            super(props);

            this.state = { sticky: false, width: null };
            this.onScroll = _throttle(this.onScroll.bind(this), 25);
            this.onResize = _throttle(this.onResize.bind(this), 25);

            this.update = this.update.bind(this);
            this.updateWidth = this.updateWidth.bind(this);
        }

        componentDidMount() {
            const parent = scrollParent(this.measurer);
            parent.addEventListener('scroll', this.onScroll);
            window.addEventListener('resize', this.onResize);
            this.update();
        }

        componentWillUnmount() {
            const parent = scrollParent(this.measurer);
            parent.removeEventListener('scroll', this.onScroll);
            window.removeEventListener('resize', this.onResize);
        }

        onScroll() {
            this.update();
        }

        onResize() {
            this.updateWidth();
        }

        updateWidth() {
            const offsets = getOffset(this.measurer);
            const { sticky, width } = this.state;

            if (sticky && width !== offsets.width) {
                this.setState({ width: offsets.width });
            }
        }

        update() {
            const { offsetTop } = this.props;
            const { sticky } = this.state;

            const offsets = getOffset(this.measurer);
            if (!sticky && offsets.top <= offsetTop) {
                this.setState({ sticky: true, width: offsets.width });
            }

            if (sticky && offsets.top > offsetTop) {
                this.setState({ sticky: false, width: offsets.width });
            }
        }

        render() {
            const { sticky, width } = this.state;
            const { offsetTop } = this.props;
            const styles = sticky ? {
                position: 'fixed', width, top: offsetTop, zIndex: 10
            } : {};

            return (
                <div style={containerStyle} ref={(c) => { this.measurer = c; }}>
                    <div style={styles}>
                        <WrappedComponent {...this.props} />
                    </div>
                </div>
            );
        }
    }

    WithStickyListHeaderHandling.propTypes = {
        offsetTop: PropTypes.number.isRequired
    };

    return WithStickyListHeaderHandling;
};

export default withStickyListHeaderHandling;
