import React from 'react';
import ListTable from 'src/components/listTable/ListTable';
import ListTableHeader from 'src/components/listTable/ListTableHeader';
import ReportsOverviewTableRow from 'src/components/views/reports/ReportsOverviewTableRow';
import withBulkCheckboxHandling from 'src/components/bulkList/withBulkCheckboxHandling';
import CheckBox from 'src/components/forms/inputs/Checkbox';
import { connect } from 'react-redux';
import * as postTagActions from 'src/actions/postTags';
import * as customPropTypes from 'src/customPropTypes';
import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import emptyImage from 'src/resources/illustrations/reports_empty.png';
import styles from 'src/stylesheets/views/viewListTable.scss';
import reportOverviewStyle from 'src/stylesheets/views/reports/reportOverviewTable.scss';
import { listName } from 'src/utils/reportOverviews';
import { makeSelectReportOverviewWithFiltersApplied } from 'src/selectors/reportOverView';
import PropTypes from 'prop-types';
import Button from 'src/components/buttons/Button';
import withReactRouterLinkHandling from 'src/components/withReactRouterLinkHandling';
import { dashboardsRoute } from 'src/routePaths';

const ButtonWithReactRouterLinkHandling = withReactRouterLinkHandling(Button);

const ReportsBulkCheckbox = withBulkCheckboxHandling(CheckBox, true);

const columns = [
    {
        id: 'bulkCheckbox', content: <ReportsBulkCheckbox listName={listName} />, className: styles.checkbox
    },
    {
        id: 'type', content: 'Type', isSortable: true, width: '5%'
    },
    {
        id: 'name', content: 'Name', isSortable: true, width: '20%'
    },
    {
        id: 'createTime', content: 'Creation date', isSortable: true, width: '10%'
    },
    {
        id: 'creator', content: 'Creator', isSortable: true, width: '18%'
    },
    {
        id: 'numberOfVisits', content: 'Views', isSortable: true, width: '7%'
    },
    {
        id: 'lastVisitTime', content: 'Last viewed', isSortable: true, width: '15%'
    },
    {
        id: 'isActive', content: 'Status', isSortable: true, width: '25%'
    }
];

const ReportsOverviewTable = (props) => {
    const {
        reportOverviews, handleOnSortByAction, sortBy, sortDir
    } = props;
    const tableHeader = <ListTableHeader columns={columns} sortByAction={handleOnSortByAction} sortBy={sortBy} sortDir={sortDir} />;

    if (reportOverviews.length > 0) {
        return (
            <ListTable header={tableHeader}>
                {
                    reportOverviews.map((reportOverview) => (
                        <ReportsOverviewTableRow key={reportOverview.id} reportOverview={reportOverview} />
                    ))
                }
            </ListTable>
        );
    }
    return (
        <EmptyInfoScreen
          image={emptyImage}
          title="No reports yet"
          message="Create an interactive report or scheduled report from a dashboard by clicking ‘Share’."
          buttons={<ButtonWithReactRouterLinkHandling label="Go to dashboards" action to={dashboardsRoute} />}
          imageClassName={reportOverviewStyle.emptyImage}
        />
    );
};

ReportsOverviewTable.propTypes = {
    reportOverviews: customPropTypes.reportOverviews.isRequired,
    handleOnSortByAction: PropTypes.func.isRequired,
    sortBy: PropTypes.string.isRequired,
    sortDir: PropTypes.string.isRequired
};

const makeMapStateToProps = () => {
    const selectReportOverviewWithFiltersApplied = makeSelectReportOverviewWithFiltersApplied();
    return (state, ownProps) => {
        const { sortBy, sortDir } = ownProps;
        return {
            reportOverviews: selectReportOverviewWithFiltersApplied(state, sortBy, sortDir)
        };
    };
};

export default connect(makeMapStateToProps, {
    sortPostTagsAction: postTagActions.sortPostTags
})(ReportsOverviewTable);
