import {
    GET_DATA_SOURCES_REQUEST,
    getDataSourcesError,
    getDataSourcesSuccess
} from 'src/actions/dataSources';
import {
    put, takeEvery, select
} from 'redux-saga/effects';
import { parseDataSources } from 'src/parsers';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import { reportError } from 'src/utils/reportError';
import { formName } from 'src/utils/dataSources';
import * as routeActions from 'react-router-redux';
import { makeSelectLinkToDataSources } from 'src/selectors/dataSources';
import { GET_DATA_PUSH_TASKS_REQUEST, GET_DATA_PUSH_TASK_REQUEST } from 'src/actions/dataPushTasks';

const selectLinkToDataSources = makeSelectLinkToDataSources(formName);

function* getDataSourcesRequest() {
    try {
        const serverRequest = createServerRequest();
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/get-data-sources-meta-data');
        if (response) {
            const { tables } = response.jsonData;
            const { parsedDataSources, parsedDataSourceColumns } = parseDataSources(tables);
            yield put(getDataSourcesSuccess(parsedDataSources, parsedDataSourceColumns));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(getDataSourcesError(applicationOrServerError));
    }
}

function* formChangeRequest(action) {
    const { meta } = action;
    if (meta.form === formName) {
        const linkToDataSourceSection = yield select(selectLinkToDataSources);
        yield put(routeActions.push(linkToDataSourceSection));
    }
}

export default function* dataSourcesSagas() {
    yield takeEvery([
        GET_DATA_SOURCES_REQUEST,
        GET_DATA_PUSH_TASKS_REQUEST,
        GET_DATA_PUSH_TASK_REQUEST
    ], getDataSourcesRequest);
    yield takeEvery('@@redux-form/CHANGE', formChangeRequest);
}
