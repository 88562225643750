import { modalShowDataPushTaskDelete } from 'src/actions/overlays';
import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import MenuButton from 'src/components/buttons/MenuButton';
import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import React from 'react';
import { dataPushTaskActivateRequest, dataPushTaskDeactivateRequest } from 'src/actions/dataPushTasks';

const DataPushTaskRowOptionsPopover = (props) => {
    const {
        dataPushTask,
        modalShowDataPushTaskDeleteAction,
        hidePopover,
        dataPushTaskActivateRequestAction,
        dataPushTaskDeactivateRequestAction
    } = props;
    const { isActive } = dataPushTask;
    return (
        <Popover id="dataPushTaskRowOptionPopover" width={180}>
            {
                isActive && (
                    <MenuButton
                      label="Deactivate"
                      onClick={() => {
                          dataPushTaskDeactivateRequestAction(dataPushTask.id);
                          hidePopover();
                      }}
                    />
                )
            }
            {
                !isActive && (
                    <MenuButton
                      label="Activate"
                      onClick={() => {
                          dataPushTaskActivateRequestAction(dataPushTask.id);
                          hidePopover();
                      }}
                    />
                )
            }

            <MenuButton
              label="Delete"
              onClick={() => {
                  modalShowDataPushTaskDeleteAction(dataPushTask.id);
                  hidePopover();
              }}
            />
        </Popover>
    );
};

DataPushTaskRowOptionsPopover.propTypes = {
    dataPushTask: customPropTypes.dataPushTask.isRequired,
    modalShowDataPushTaskDeleteAction: PropTypes.func.isRequired,
    hidePopover: PropTypes.func.isRequired,
    dataPushTaskActivateRequestAction: PropTypes.func.isRequired,
    dataPushTaskDeactivateRequestAction: PropTypes.func.isRequired
};

export default connect(null, {
    modalShowDataPushTaskDeleteAction: modalShowDataPushTaskDelete,
    dataPushTaskActivateRequestAction: dataPushTaskActivateRequest,
    dataPushTaskDeactivateRequestAction: dataPushTaskDeactivateRequest
})(DataPushTaskRowOptionsPopover);
