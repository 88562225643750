import React, { useEffect } from 'react';
import AceEditor from 'src/components/forms/inputs/AceEditor';
import * as dataPushTaskActions from 'src/actions/dataPushTaskLogs';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeSelectDataPushTaskLogData, makeSelectIsDataPushLogDataLoading } from 'src/selectors/dataPushTaskLogs';
import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import fileNotFoundImage from 'src/resources/illustrations/empty-folder.png';
import EmptyDiv from 'src/components/loadingIndicators/EmptyDiv';
import _get from 'lodash/get';
import Feedback from 'src/components/feedback/Feedback';
import * as customPropTypes from 'src/customPropTypes';

const DataPushTaskRawDataLog = (props) => {
    useEffect(() => {
        const { id, dataPushTaskId, getDataPushTaskLogDataRequestAction } = props;
        getDataPushTaskLogDataRequestAction(id, dataPushTaskId);
    }, []);
    const { logData, loadingState, className } = props;
    const isPending = _get(loadingState, 'isPending', false);
    const error = _get(loadingState, 'error', null);

    if (isPending) {
        return (
            <div className={className}>
                <EmptyDiv />
            </div>
        );
    }

    if (error) {
        if (error.errorType === 'dataPushTaskLogFileNotFoundError') {
            return (
                <EmptyInfoScreen
                  image={fileNotFoundImage}
                  title="File not found"
                  message={error.message}
                />
            );
        }
        return (
            <Feedback content={error.message} type="error" responsive={false} />
        );
    }

    return (
        <AceEditor
          name="logs"
          mode="json"
          isReadOnly
          value={logData || ''}
          className={className}
        />
    );
};

DataPushTaskRawDataLog.propTypes = {
    id: PropTypes.string.isRequired,
    dataPushTaskId: PropTypes.string.isRequired,
    getDataPushTaskLogDataRequestAction: PropTypes.func.isRequired,
    logData: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    loadingState: customPropTypes.dataLoadingError.isRequired,
    className: PropTypes.string.isRequired
};

const makeMapStateToProps = () => {
    const selectDataPushTaskLogData = makeSelectDataPushTaskLogData();
    const selectIsDataPushLogDataLoading = makeSelectIsDataPushLogDataLoading();
    return (state, ownProps) => ({
        logData: selectDataPushTaskLogData(state, ownProps.id),
        loadingState: selectIsDataPushLogDataLoading(state, ownProps.id)
    });
};

export default connect(makeMapStateToProps, {
    getDataPushTaskLogDataRequestAction: dataPushTaskActions.getDataPushTaskLogDataRequest
})(DataPushTaskRawDataLog);
