import * as customPropTypes from 'src/customPropTypes';
import BrokenProfileUseCasesRow from 'src/components/views/authentications/table/BrokenProfileUseCasesRow';
import BrokenAdAccountUseCasesRow from 'src/components/views/authentications/table/BrokenAdAccountUseCasesRow';
import { connect } from 'react-redux';
import PlatformUseCasesTableHeader from 'src/components/views/authentications/table/PlatformUseCasesTableHeader';
import PropTypes from 'prop-types';
import React from 'react';
import { selectUseCasesByPlatform } from 'src/selectors/useCases';
import ListTable from 'src/components/listTable/ListTable';

const BrokenAccountsUseCasesTable = (props) => {
    const {
        brokenProfilesUseCases,
        brokenAdAccountsUseCases,
        platformUseCases,
        platformType
    } = props;
    return (
        <ListTable header={(
            <PlatformUseCasesTableHeader
              platformUseCases={platformUseCases}
              platformType={platformType}
            />
        )}
        >
            {
                brokenProfilesUseCases.map(({ profileId, invalidReasonsByUseCaseId }) => (
                    <BrokenProfileUseCasesRow
                      key={profileId}
                      profileId={profileId}
                      platformUseCases={platformUseCases}
                      invalidReasonsByUseCaseId={invalidReasonsByUseCaseId}
                    />
                ))
            }
            {
                brokenAdAccountsUseCases.map(({ adAccountId, invalidReasonsByUseCaseId }) => (
                    <BrokenAdAccountUseCasesRow
                      key={adAccountId}
                      adAccountId={adAccountId}
                      platformUseCases={platformUseCases}
                      invalidReasonsByUseCaseId={invalidReasonsByUseCaseId}
                    />
                ))
            }
        </ListTable>
    );
};

BrokenAccountsUseCasesTable.propTypes = {
    platformUseCases: customPropTypes.useCases.isRequired,
    platformType: PropTypes.string.isRequired,
    brokenProfilesUseCases: customPropTypes.brokenProfilesUseCases.isRequired,
    brokenAdAccountsUseCases: customPropTypes.brokenAdAccountsUseCases.isRequired
};

const mapStateToProps = (state, ownProps) => ({
    platformUseCases: selectUseCasesByPlatform(state, ownProps.platformType)
});

export default connect(mapStateToProps)(BrokenAccountsUseCasesTable);
