import { connect } from 'react-redux';
import { getUserNotificationIndicator } from 'src/selectors/userNotification';
import PropTypes from 'prop-types';
import React from 'react';
import UserNotificationIndicator from 'src/components/buttons/UserNotificationIndicator';
import UserNotificationPopover from 'src/components/popovers/UserNotificationPopover';
import withPopover from 'src/components/withPopover';

const UserNotificationIndicatorWithPopover = withPopover(UserNotificationIndicator);

const UserNotificationMenu = (props) => {
    const { hasNotification } = props;
    const overlay = <UserNotificationPopover hasNotification={hasNotification} />;
    return (
        <UserNotificationIndicatorWithPopover
          placement="right"
          overlay={overlay}
          hasNotification={hasNotification}
        />
    );
};

UserNotificationMenu.propTypes = {
    hasNotification: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
    hasNotification: getUserNotificationIndicator(state)
});

export default connect(mapStateToProps)(UserNotificationMenu);
