import _range from 'lodash/range';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/loadingIndicators/emptyAutocompleteSkeletonList.scss';

const EmptyAutocompleteListSkeleton = (props) => {
    const { itemCount } = props;
    const items = _range(itemCount);
    return (
        <ul className={styles.list}>
            {
                items.map((item) => (
                    <li key={item}>
                        <div key={item} className={styles.item}>
                            <div className={styles.icon} />
                            <div className={styles.text} />
                        </div>
                    </li>
                ))
            }
        </ul>
    );
};

EmptyAutocompleteListSkeleton.propTypes = {
    itemCount: PropTypes.number
};

EmptyAutocompleteListSkeleton.defaultProps = {
    itemCount: 10
};

export default EmptyAutocompleteListSkeleton;
