import DataSourceLabel from 'src/components/DataSourceLabel';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/views/metricDataSourceList.scss';

const MetricDataSourceList = (props) => {
    const { dataSources } = props;
    return (
        <ul className={styles.dataSources}>
            {
                dataSources.map((dataSource) => (
                    <li key={`${dataSource}`}>
                        <DataSourceLabel name={dataSource} />
                    </li>
                ))
            }
        </ul>
    );
};

MetricDataSourceList.propTypes = {
    dataSources: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default MetricDataSourceList;
