import { put, takeEvery } from 'redux-saga/effects';
import { USER_INVOICES_REQUEST, userInvoicesError, userInvoicesSuccess } from 'src/actions/invoices';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import { reportError } from 'src/utils/reportError';
import { parseInvoices } from 'src/parsers';

function* userInvoicesDetailRequest() {
    try {
        const serverRequest = createServerRequest();
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/get-invoices');
        if (response) {
            const { jsonData } = response;
            yield put(userInvoicesSuccess(parseInvoices(jsonData.results)));
        }
        if (serverError) {
            yield put(userInvoicesError(serverError));
        }
    } catch (applicationError) {
        reportError(applicationError);
        yield put(userInvoicesError(applicationError));
    }
}

export default function* invoicesSaga() {
    yield takeEvery(USER_INVOICES_REQUEST, userInvoicesDetailRequest);
}
