import React from 'react';
import TopNav from 'src/components/navigations/TopNav';
import TopNavTextLink from 'src/components/buttons/TopNavTextLink';
import { apiRoute, dataPushTasksRoute, dataPushTaskRoutes } from 'src/routePaths';

const IntegrationsNav = () => (
    <TopNav>
        <TopNavTextLink to={apiRoute}>API</TopNavTextLink>
        <TopNavTextLink to={dataPushTasksRoute} alsoActiveOn={dataPushTaskRoutes}>Data push tasks</TopNavTextLink>
    </TopNav>
);

export default IntegrationsNav;
