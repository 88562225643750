import {
    ANALYSIS_AUTOCOMPLETE_ERROR,
    ANALYSIS_AUTOCOMPLETE_REQUEST,
    ANALYSIS_AUTOCOMPLETE_SUCCESS,
    ANALYSIS_LAST_VISITED_METRIC_ID,
    ANALYSIS_QUICK_ACCESS_LINKS_ERROR,
    ANALYSIS_QUICK_ACCESS_LINKS_REQUEST,
    ANALYSIS_QUICK_ACCESS_LINKS_SUCCESS,
    ANALYSIS_TOGGLE_FAV_ERROR,
    ANALYSIS_TOGGLE_FAV_REQUEST,
    ANALYSIS_TOGGLE_FAV_SUCCESS,
    ANALYSIS_UPDATE_FILTER
} from 'src/actions/analysis';
import { createAsyncStatesReducerForAction, setIn } from 'src/reducers/utils';
import _uniq from 'lodash/uniq';
import { combineReducers } from 'redux';
import { CUSTOM_METRIC_DELETE_SUCCESS } from 'src/actions/customMetric';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';

export const initialState = { loading: true, data: null, error: null };
export const initialStateAutocomplete = { loading: true, data: [], error: null };

const filter = (state = '1', action) => {
    const { type, payload } = action;
    switch (type) {
        case ANALYSIS_UPDATE_FILTER:
            return payload.value;
        default:
            return state;
    }
};

const quickAccessLinksResults = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case ANALYSIS_QUICK_ACCESS_LINKS_REQUEST: {
            const { identifier } = payload;
            return setIn(state, identifier, initialState);
        }

        case ANALYSIS_QUICK_ACCESS_LINKS_SUCCESS: {
            const { identifier, results } = payload;
            const resultIds = results.filter((result) => result.type === 'Metric').map((result) => result.id);
            return setIn(state, identifier, { loading: false, error: null, data: resultIds });
        }

        case ANALYSIS_QUICK_ACCESS_LINKS_ERROR: {
            const { identifier, error } = payload;
            return setIn(state, identifier, { loading: false, error, data: null });
        }
        default:
            return state;
    }
};

const autocomplete = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case ANALYSIS_AUTOCOMPLETE_REQUEST: {
            const { identifier } = payload;
            return setIn(state, identifier, initialStateAutocomplete);
        }

        case ANALYSIS_AUTOCOMPLETE_SUCCESS: {
            const { identifier, results } = payload;
            const resultIds = results.filter((result) => result.type === 'Metric').map((result) => result.id);
            return setIn(state, identifier, { loading: false, error: null, data: resultIds });
        }

        case ANALYSIS_AUTOCOMPLETE_ERROR: {
            const { identifier, error } = payload;
            return setIn(state, identifier, { loading: false, error, data: [] });
        }
        default:
            return state;
    }
};

const asyncStatesByAction = combineReducers({
    toggle: createAsyncStatesReducerForAction(
        (payload) => payload.metricId,
        ANALYSIS_TOGGLE_FAV_REQUEST,
        [ANALYSIS_TOGGLE_FAV_SUCCESS, ANALYSIS_TOGGLE_FAV_ERROR]
    )
});

const favoritesAllIds = (state = [], action) => {
    const { payload, type } = action;
    switch (type) {
        case USER_LOGGED_IN: {
            const { favoriteMetricIds } = payload;
            return favoriteMetricIds;
        }
        case ANALYSIS_TOGGLE_FAV_SUCCESS: {
            const { metricId, active } = payload;
            if (active === true) {
                return _uniq([...state, `${metricId}`]);
            } if (active === false) {
                return state.filter((value) => value !== `${metricId}`);
            }
            return state;
        }
        default:
            return state;
    }
};

const favorites = combineReducers({
    allIds: favoritesAllIds
});

const lastVisitedMetricId = (state = null, action) => {
    const { payload, type } = action;
    switch (type) {
        case ANALYSIS_LAST_VISITED_METRIC_ID: {
            if (state !== payload.metricId) {
                return payload.metricId;
            }
            return state;
        }
        case CUSTOM_METRIC_DELETE_SUCCESS: {
            const { id } = payload;
            if (state === id) {
                return null;
            }
            return state;
        }
        default:
            return state;
    }
};

export const analysis = combineReducers({
    filter,
    results: quickAccessLinksResults,
    autocomplete,
    asyncStatesByAction,
    favorites,
    lastVisitedMetricId
});
