import { makeSelectAdminSettingsState } from 'src/selectors/adminSettings';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Column from 'src/components/header/layout/Column';
import { connect } from 'react-redux';
import Header from 'src/components/header/layout/Header';
import { modalShowCreateUser } from 'src/actions/overlays';
import PageHeadline from 'src/components/header/layout/PageHeadline';
import PropTypes from 'prop-types';
import React from 'react';
import TopRow from 'src/components/header/layout/TopRow';
import * as customPropTypes from 'src/customPropTypes';

const UsersHeader = (props) => {
    const { modalShowCreateUserAction, loadingState } = props;
    const { isPending, error } = loadingState;

    return (
        <Header>
            <TopRow>
                <Column flexGrow>
                    <PageHeadline>User management</PageHeadline>
                </Column>
                <Column>
                    <ButtonGroup>
                        <Button
                          onClick={modalShowCreateUserAction}
                          label="Invite user"
                          action
                          icon="plus"
                          disabled={isPending || !!error}
                        />
                    </ButtonGroup>
                </Column>
            </TopRow>
        </Header>
    );
};

UsersHeader.propTypes = {
    modalShowCreateUserAction: PropTypes.func.isRequired,
    loadingState: customPropTypes.asyncDataLoadingState.isRequired
};

const makeMapStateToProps = () => {
    const selectAdminSettingsState = makeSelectAdminSettingsState();
    return (state) => ({
        loadingState: selectAdminSettingsState(state)
    });
};

export default connect(makeMapStateToProps, { modalShowCreateUserAction: modalShowCreateUser })(UsersHeader);
