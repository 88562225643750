import DivWithToolTip from 'src/components/toolTips/DivWithTooltip';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/analysisQuickLink.scss';
import withReactRouterLinkHandling from 'src/components/withReactRouterLinkHandling';
import VisualizationTag from 'src/components/views/analysis/VisualizationTag';
import * as customPropTypes from 'src/customPropTypes';

const ENTER = 13;
const SPACE = 32;

const handleKeyPress = (event, callback) => {
    if (callback && (event.keyCode === ENTER || event.keyCode === SPACE)) {
        callback();
        event.preventDefault();
    }
};

const AnalysisQuickLink = ({ onClick, label, visualizationTags }) => (
    <div
      role="button"
      tabIndex="0"
      className={styles.linkWrapper}
      onClick={onClick}
      onKeyDown={(event) => { handleKeyPress(event, onClick); }}
    >
        {
            visualizationTags.length > 0
            && (
                <div className={styles.tagIcon}>
                    <VisualizationTag tag={visualizationTags[0]} />
                </div>
            )
        }
        <div className={styles.ellipsis}>
            <DivWithToolTip tooltipPlacement="top-end" tooltip={label}>
                {label}
            </DivWithToolTip>
        </div>
    </div>
);

AnalysisQuickLink.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    visualizationTags: customPropTypes.tags.isRequired
};

export default withReactRouterLinkHandling(AnalysisQuickLink);
