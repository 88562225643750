import * as customPropTypes from 'src/customPropTypes';
import { Field, formValueSelector } from 'redux-form';
import FormError from 'src/components/forms/feedback/FormError';
import PropTypes from 'prop-types';
import React from 'react';
import {
    ProfileSelector, SingleAbsoluteDateSelector, TextFilterTokenInput, ConjunctionInput
} from 'src/components/forms/fields';
import withReduxForm from 'src/components/forms/withReduxForm';
import { required } from 'src/components/forms/validators';
import { connect } from 'react-redux';
import styles from 'src/stylesheets/forms/postTagRuleAddForm.scss';
import moment from 'moment';
import classnames from 'classnames';
import TimezoneSelect from 'src/components/forms/fields/TimezoneSelect';
import _has from 'lodash/has';

const validateProfileGroupSelection = (value) => {
    if ((_has(value, 'groupIds') && _has(value, 'profileIds')) && value.groupIds.length + value.profileIds.length > 0) {
        return undefined;
    }
    return 'This auto-tag rule is missing a profile. Select a profile to validate the rule.';
};

const validateEndDate = (value, { from }) => {
    if (value === null) {
        return undefined;
    }
    return value >= from ? undefined : 'To select a valid date range, the end date needs to occur after the start date.';
};

const Section = ({ children, title }) => (
    <div className={styles.section}>
        {
            title
            && (
                <div className={styles.sectionTitle}>
                    {title}
                </div>
            )
        }
        <div>
            {children}
        </div>
    </div>
);

Section.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node.isRequired
};

const PostTagRuleAddForm = (props) => {
    const {
        error, change, postTextValues, submitting, postTextExcludeValues, profileSelection
    } = props;
    const handleOnTodayClick = () => {
        const today = moment().format('YYYY-MM-DD');
        change('from', today);
    };

    const handleOnForeverClick = () => {
        change('to', null);
    };

    let postTextConjunctionDisabled = submitting;
    let postTextExcludeConjunctionDisabled = submitting;
    if (!submitting) {
        postTextConjunctionDisabled = postTextValues ? postTextValues.length < 2 : true;
        postTextExcludeConjunctionDisabled = postTextExcludeValues ? postTextExcludeValues.length < 2 : true;
    }
    const adAccountsSelected = profileSelection && profileSelection.adAccountIds && profileSelection.adAccountIds.length > 0;
    return (
        <div>
            {
                error && <FormError message={error.message} />
            }
            <Section title="IF posts belong to:">
                <Field
                  name="profileSelection"
                  component={ProfileSelector}
                  validate={validateProfileGroupSelection}
                  disabled={submitting}
                  info={adAccountsSelected ? 'You have selected at least one ad account. Tagging ads is not yet available.' : undefined}
                />
            </Section>
            <Section title="AND published from:">
                <div className={styles.dateSelectors}>
                    <div className={styles.startDate}>
                        <div className={styles.dateSelectorHelperWrapper}>
                            <div className={styles.dateSelectorLabel}>Start</div>
                            <div className={styles.dateSelectorHelper} onClick={handleOnTodayClick}>Today</div>
                        </div>
                        <Field name="from" component={SingleAbsoluteDateSelector} validate={required} disabled={submitting} />
                    </div>
                    <div className={styles.endDate}>
                        <div className={styles.dateSelectorHelperWrapper}>
                            <div className={styles.dateSelectorLabel}>End</div>
                            <div className={styles.dateSelectorHelper} onClick={handleOnForeverClick}>Forever</div>
                        </div>
                        <Field name="to" component={SingleAbsoluteDateSelector} labelIfNull="Forever" validate={validateEndDate} disabled={submitting} />
                    </div>
                    <div className={styles.timezone}>
                        <div className={styles.dateSelectorHelperWrapper}>
                            <div className={styles.dateSelectorLabel}>Timezone</div>
                        </div>
                        <div className={styles.timezoneSelect}>
                            <Field name="timezone" component={TimezoneSelect} shortcutOnly disabled={submitting} />
                        </div>
                    </div>
                </div>
            </Section>
            <div className={styles.postTextFilter}>
                <div className={styles.conjunctionWrapper}>
                    <div className={classnames(styles.sectionTitle, styles.conjunctionLabel)}>
                        AND includes
                    </div>
                    <div className={styles.conjunction}>
                        <Field
                          name="postTextConjunction"
                          component={ConjunctionInput}
                          disabled={postTextConjunctionDisabled}
                        />
                        <div className={classnames(styles.sectionTitle, styles.keyword)}>
                            of the following keywords:
                        </div>
                    </div>
                </div>
                <Field
                  name="postTextValues"
                  component={TextFilterTokenInput}
                  wrapElements
                  disabled={submitting}
                  addActionLabel="Add keyword"
                  editActionLabel="Edit keyword"
                  buttonLabel="Add keyword"
                />
            </div>

            <div className={styles.postTextFilter}>
                <div className={styles.conjunctionWrapper}>
                    <div className={classnames(styles.sectionTitle, styles.conjunctionLabel)}>
                        AND excludes
                    </div>
                    <div className={styles.conjunction}>
                        <Field
                          name="postTextExcludeConjunction"
                          component={ConjunctionInput}
                          disabled={postTextExcludeConjunctionDisabled}
                        />
                        <div className={classnames(styles.sectionTitle, styles.keyword)}>
                            of the following keywords:
                        </div>
                    </div>
                </div>
                <Field
                  name="postTextExcludeValues"
                  component={TextFilterTokenInput}
                  wrapElements
                  disabled={submitting}
                  addActionLabel="Add keyword"
                  editActionLabel="Edit keyword"
                  buttonLabel="Add keyword"
                />
            </div>
        </div>
    );
};

PostTagRuleAddForm.propTypes = {
    error: customPropTypes.dataLoadingError,
    change: PropTypes.func.isRequired,
    postTextValues: PropTypes.arrayOf(PropTypes.string),
    postTextExcludeValues: PropTypes.arrayOf(PropTypes.string),
    profileSelection: customPropTypes.profileFilter.isRequired,
    submitting: PropTypes.bool.isRequired
};

PostTagRuleAddForm.defaultProps = {
    postTextValues: [],
    postTextExcludeValues: []
};

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector(ownProps.form);
    return {
        postTextValues: selector(state, 'postTextValues'),
        postTextExcludeValues: selector(state, 'postTextExcludeValues'),
        profileSelection: selector(state, 'profileSelection')
    };
};

export default connect(mapStateToProps)(withReduxForm(PostTagRuleAddForm, {
    enableReinitialize: true,
}));
