import UsersList from 'src/components/views/settings/users/UsersList';
import Body from 'src/components/Body';
import Footer from 'src/components/Footer';
import React from 'react';
import ScrollView from 'src/components/ScrollView';
import UsersHeader from 'src/components/header/UsersHeader';
import { selectUserIds } from 'src/selectors/users';
import AdminSettingsLoader from 'src/components/views/settings/AdminSettingsLoader';

const UserSettings = () => (
    <ScrollView dataViewName="user-settings">
        <UsersHeader />
        <Body>
            <AdminSettingsLoader dataSelector={selectUserIds}>
                {(userIds) => <UsersList userIds={userIds} />}
            </AdminSettingsLoader>
        </Body>
        <Footer />
    </ScrollView>
);

export default UserSettings;
