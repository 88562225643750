import adminSettingsSagas from 'src/sagas/adminSettings';
import analysisSagas from 'src/sagas/analysis';
import authTransactionSagas from 'src/sagas/authTransactions';
import authUsersSaga from 'src/sagas/authUsers';
import customMetricSaga from 'src/sagas/customMetric';
import dashboardMetricsSagas from 'src/sagas/dashboardMetrics';
import dashboardsSagas from 'src/sagas/dashboards';
import dashboardReportsSagas from 'src/sagas/dashboardReports';
import dashboardShareLinksSagas from 'src/sagas/dashboardShareLinks';
import dataPushTaskLogsSagas from 'src/sagas/dataPushTaskLogs';
import dataPushTasksSagas from 'src/sagas/dataPushTasks';
import dataSourcesSagas from 'src/sagas/dataSources';
import exportsSagas from 'src/sagas/exports';
import foldersSaga from 'src/sagas/folders';
import { formActionSaga } from 'redux-form-saga';
import googleTagManagerSagas from 'src/sagas/googleTagManager';
import graphAuthUsersSagas from 'src/sagas/graphAuthUsers';
import groupSagas from 'src/sagas/groups';
import invoicesSaga from 'src/sagas/invoices';
import limitsSagas from 'src/sagas/limits';
import linkedInAuthUsersSagas from 'src/sagas/linkedInAuthUsers';
import metricSaga from 'src/sagas/metric';
import metricSearchSagas from 'src/sagas/metricSearch';
import modalsSagas from 'src/sagas/modals';
import facebookSDKSagas from 'src/sagas/facebookSDK';
import socialNetworkProfileSearch from 'src/sagas/socialNetworkProfileSearch';
import profilesSagas from 'src/sagas/profiles';
import profileUseCasesSaga from 'src/sagas/profileUseCases';
import adAccountUseCasesSagas from 'src/sagas/adAccountUseCases';
import recommendationsSagas from 'src/sagas/recommendations';
import sampleServerDataSagas from 'src/sagas/sampleServerData';
import serverDataSagas from 'src/sagas/serverData';
import snapchatAuthUsers from 'src/sagas/snapchatAuthUsers';
import switchToolsSaga from 'src/sagas/switchTools';
import threadsAuthUsersSagas from 'src/sagas/threadsAuthUsers';
import twitterAuthUsersSagas from 'src/sagas/twitterAuthUsers';
import userNotification from 'src/sagas/userNotification';
import loggedInUserSaga from 'src/sagas/loggedInUser';
import usersSaga from 'src/sagas/users';
import spacesSaga from 'src/sagas/spaces';
import youtubeAuthUsersSagas from 'src/sagas/youtubeAuthUsers';
import profileEventSagas from 'src/sagas/profileEvents';
import profileHealthSagas from 'src/sagas/profileHealth';
import postTagsSagas from 'src/sagas/postTags';
import postsSagas from 'src/sagas/posts';
import localizationSagas from 'src/sagas/localization';
import postTagRulesSagas from 'src/sagas/postTagRules';
import postTagsPostInfoSagas from 'src/sagas/postTagsPostInfo';
import dashboardReportScheduleSagas from 'src/sagas/dashboardReportSchedule';
import userInvitationSagas from 'src/sagas/userInvitations';
import profileAudienceDataOverviewSagas from 'src/sagas/profileAudienceDataOverview';
import reportOverview from 'src/sagas/reportOverview';
import tiktokAuthUsers from 'src/sagas/tiktokAuthUsers';
import accountsSagas from 'src/sagas/accounts';
import adAccountsSagas from 'src/sagas/adAccounts';
import adAccountSearchSagas from 'src/sagas/adAccountSearch';

export default [
    accountsSagas,
    adAccountSearchSagas,
    authTransactionSagas,
    authUsersSaga,
    loggedInUserSaga,
    usersSaga,
    dashboardsSagas,
    dashboardReportsSagas,
    dashboardMetricsSagas,
    dashboardShareLinksSagas,
    dataPushTaskLogsSagas,
    dataPushTasksSagas,
    exportsSagas,
    foldersSaga,
    graphAuthUsersSagas,
    recommendationsSagas,
    metricSearchSagas,
    socialNetworkProfileSearch,
    profilesSagas,
    profileEventSagas,
    profileHealthSagas,
    groupSagas,
    googleTagManagerSagas,
    adminSettingsSagas,
    customMetricSaga,
    dataSourcesSagas,
    invoicesSaga,
    formActionSaga,
    serverDataSagas,
    snapchatAuthUsers,
    tiktokAuthUsers,
    threadsAuthUsersSagas,
    facebookSDKSagas,
    profileUseCasesSaga,
    limitsSagas,
    linkedInAuthUsersSagas,
    metricSaga,
    switchToolsSaga,
    twitterAuthUsersSagas,
    modalsSagas,
    sampleServerDataSagas,
    analysisSagas,
    userNotification,
    spacesSaga,
    youtubeAuthUsersSagas,
    postTagsSagas,
    postsSagas,
    localizationSagas,
    postTagRulesSagas,
    postTagsPostInfoSagas,
    dashboardReportScheduleSagas,
    userInvitationSagas,
    profileAudienceDataOverviewSagas,
    reportOverview,
    adAccountsSagas,
    adAccountUseCasesSagas
];
