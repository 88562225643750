import Column from 'src/components/header/layout/Column';
import Header from 'src/components/header/layout/Header';
import PageHeadline from 'src/components/header/layout/PageHeadline';
import PropTypes from 'prop-types';
import React from 'react';
import TopRow from 'src/components/header/layout/TopRow';
import Button from 'src/components/buttons/Button';
import { submit, isSubmitting } from 'redux-form';
import { connect } from 'react-redux';

const PreferenceHeader = (props) => {
    const { submitAction, formName, isFormSubmitting } = props;
    return (
        <Header>
            <TopRow>
                <Column flexGrow>
                    <PageHeadline>Preferences</PageHeadline>
                </Column>
                <Column>
                    <Button
                      label="Save preferences"
                      action
                      loading={isFormSubmitting}
                      onClick={() => { submitAction(formName); }}
                    />
                </Column>
            </TopRow>
        </Header>
    );
};

PreferenceHeader.propTypes = {
    formName: PropTypes.string.isRequired,
    submitAction: PropTypes.func.isRequired,
    isFormSubmitting: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => ({
    isFormSubmitting: isSubmitting(ownProps.formName)(state)
});

export default connect(mapStateToProps, {
    submitAction: submit
})(PreferenceHeader);
