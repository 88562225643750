import { put, select, takeEvery } from 'redux-saga/effects';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { handleAuthorizedServerRequest, parseReportDateSelection } from 'src/sagas/utils';
import { parseDashboardShareLinks, parseDashboardShareLink } from 'src/parsers';
import {
    ALL_DASHBOARD_SHARE_LINKS_GET_REQUEST,
    DASHBOARD_SHARE_LINKS_GET_REQUEST,
    dashboardShareLinksGetSuccess,
    dashboardShareLinksGetError,
    CREATE_SHAREABLE_LINK_REQUEST,
    DASHBOARD_SHARE_LINK_REMOVE_REQUEST,
    DASHBOARD_SHARE_LINK_UPDATE_REQUEST,
    dashboardShareLinkUpdateError,
    dashboardShareLinkUpdateSuccess,
    allDashboardShareLinksGetError,
    allDashboardShareLinksGetSuccess,
    createShareableLinkError,
    createShareableLinkSuccess,
    dashboardShareLinkRemoveSuccess,
    dashboardShareLinkRemoveError
} from 'src/actions/dashboardShareLinks';
import _parseInt from 'lodash/parseInt';
import { SubmissionError } from 'redux-form';
import { selectDashboardTypeById } from 'src/selectors/ownership';
import {
    modalHideCreateShareableLink,
    modalHideEditShareableLink,
    modalShowCreatedLink,
    modalHideDashboardShareLinkDelete
} from 'src/actions/overlays';
import { reportError } from 'src/utils/reportError';
import { showNotification } from 'src/actions/notifications';

function* allDashboardShareLinksGetRequest() {
    try {
        const serverRequest = createServerRequest();
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/get-all-dashboard-shared-links');
        if (response) {
            const { dashboardShareLinks } = response.jsonData;
            const parsedDashboardExportLinks = parseDashboardShareLinks(dashboardShareLinks);
            yield put(allDashboardShareLinksGetSuccess(parsedDashboardExportLinks));
        }

        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(allDashboardShareLinksGetError(applicationError));
    }
}

function* dashboardShareLinksGetRequest(action) {
    const { payload } = action;
    const { dashboardId } = payload;

    const dashboardType = yield select(selectDashboardTypeById, dashboardId);
    try {
        const serverRequest = createServerRequest({ dashboardId, dashboardType });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/get-dashboard-shared-links');
        if (response) {
            const { dashboardShareLinks } = response.jsonData;
            const parsedDashboardExportLinks = parseDashboardShareLinks(dashboardShareLinks);
            yield put(dashboardShareLinksGetSuccess(dashboardId, parsedDashboardExportLinks));
        }

        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(dashboardShareLinksGetError(dashboardId, applicationError));
    }
}

function* dashboardShareLinkRemoveRequest(action) {
    const { payload } = action;
    const { id } = payload;
    try {
        const serverRequest = createServerRequest({ id });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/remove-dashboard-share-link');
        if (response) {
            yield put(modalHideDashboardShareLinkDelete());
            yield put(dashboardShareLinkRemoveSuccess(id));
        }

        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(dashboardShareLinkRemoveError(new SubmissionError({ _error: applicationError })));
    }
}

const getDashboardShareableParams = (dashboardId, dashboardType, profileSelection, dateSelection, additionalFilter, hasExpiry, validUntil, isPasswordProtected, password, id = null) => ({
    id: _parseInt(id),
    dashboardId: _parseInt(dashboardId),
    profileSelection: profileSelection !== null ? JSON.stringify(profileSelection) : null,
    dateSelection: JSON.stringify(parseReportDateSelection(dateSelection)),
    additionalFilter: JSON.stringify(additionalFilter),
    isPasswordProtected,
    password,
    hasExpiry,
    validUntil,
    dashboardType,
});

export function* createShareableLinkRequest(action) {
    const {
        profileSelection,
        dateSelection,
        validUntil,
        hasExpiry,
        dashboardId,
        additionalFilterSelection,
        isPasswordProtected,
        password
    } = action.payload;
    const dashboardType = yield select(selectDashboardTypeById, dashboardId);
    const params = getDashboardShareableParams(dashboardId, dashboardType, profileSelection, dateSelection, additionalFilterSelection, hasExpiry, validUntil, isPasswordProtected, password);

    try {
        const serverRequest = createServerRequest(params);
        const {
            response,
            serverError
        } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/create-dashboard-link');
        if (response) {
            const { jsonData } = response;
            const { dashboardShareLink } = jsonData;
            const parsedDashboardShareLink = parseDashboardShareLink(dashboardShareLink);
            yield put(createShareableLinkSuccess(parsedDashboardShareLink));
            yield put(modalHideCreateShareableLink(dashboardId));
            yield put(modalShowCreatedLink(parsedDashboardShareLink.link, parsedDashboardShareLink.validUntil));
        }
        if (serverError) {
            yield put(createShareableLinkError(new SubmissionError({ _error: serverError })));
        }
    } catch (applicationError) {
        reportError(applicationError);
        yield put(createShareableLinkError(new SubmissionError({ _error: applicationError })));
    }
}

export function* dashboardShareLinkUpdateRequest(action) {
    const {
        id,
        profileSelection,
        dateSelection,
        hasExpiry,
        validUntil,
        dashboardId,
        additionalFilterSelection,
        isPasswordProtected,
        password
    } = action.payload;
    const dashboardType = yield select(selectDashboardTypeById, dashboardId);

    const params = getDashboardShareableParams(dashboardId, dashboardType, profileSelection, dateSelection, additionalFilterSelection, hasExpiry, validUntil, isPasswordProtected, password, id);

    try {
        const serverRequest = createServerRequest(params);
        const {
            response,
            serverError
        } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/update-dashboard-share-link');
        if (response) {
            const { jsonData } = response;
            const { dashboardShareLink } = jsonData;
            const parsedDashboardShareLink = parseDashboardShareLink(dashboardShareLink);
            yield put(dashboardShareLinkUpdateSuccess(parsedDashboardShareLink));
            yield put(modalHideEditShareableLink(id));
            yield put(showNotification('Report link successfully updated.'));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        reportError(applicationError);
        yield put(dashboardShareLinkUpdateError(new SubmissionError({ _error: applicationError })));
    }
}

export default function* dashboardShareLinksSagas() {
    yield takeEvery(ALL_DASHBOARD_SHARE_LINKS_GET_REQUEST, allDashboardShareLinksGetRequest);
    yield takeEvery(DASHBOARD_SHARE_LINKS_GET_REQUEST, dashboardShareLinksGetRequest);
    yield takeEvery(CREATE_SHAREABLE_LINK_REQUEST, createShareableLinkRequest);
    yield takeEvery(DASHBOARD_SHARE_LINK_UPDATE_REQUEST, dashboardShareLinkUpdateRequest);
    yield takeEvery(DASHBOARD_SHARE_LINK_REMOVE_REQUEST, dashboardShareLinkRemoveRequest);
}
