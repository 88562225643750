import FolderListItemSuperRoot from 'src/components/views/folders/FolderListItemSuperRoot';
import _capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeSelectFolderById } from 'src/selectors/folders';
import { makeSelectAllChildrenIdsByParentIdAndType, makeSelectDashboardCountByFolderIdsAndType, selectFolderTypeById } from 'src/selectors/ownership';
import { selectLoggedInUser } from 'src/selectors/users';
import { getFolderIconByOwnersipType, getReadableOwnershipType } from 'src/utils/ownership';

const makeMapStateToProps = () => {
    const selectFolderById = makeSelectFolderById();
    const selectDashboardCountsbyFolderIds = makeSelectDashboardCountByFolderIdsAndType();
    const selectAllChildrenByParentIdAndType = makeSelectAllChildrenIdsByParentIdAndType();
    return (state, ownProps) => {
        const { folderId } = ownProps;
        const folder = selectFolderById(state, folderId);
        const folderType = selectFolderTypeById(state, folderId);
        const allChildrenIds = folder ? selectAllChildrenByParentIdAndType(state, folderId, folderType) : [];
        return {
            folder,
            loggedInUser: selectLoggedInUser(state),
            dashboardCount: selectDashboardCountsbyFolderIds(state, [folderId, ...allChildrenIds], folderType),
            folderCount: allChildrenIds.length,
            label: `${_capitalize(getReadableOwnershipType(folderType))} folder`,
            labelIcon: getFolderIconByOwnersipType(folderType)
        };
    };
};

const FolderListItemSuperRootContainer = connect(makeMapStateToProps)(FolderListItemSuperRoot);

FolderListItemSuperRootContainer.propTypes = {
    folderId: PropTypes.string.isRequired
};

export default FolderListItemSuperRootContainer;
