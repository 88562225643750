import {
    GET_DATA_PUSH_TASKS_SUCCESS,
    GET_DATA_PUSH_TASK_SUCCESS,
    DATA_PUSH_TASK_UPDATE_SUCCESS,
    DATA_PUSH_TASK_CREATE_SUCCESS,
    DATA_PUSH_TASK_DESTINATION_UPDATE_SUCCESS,
    DATA_PUSH_TASK_DESTINATION_CONNECTION_TEST_SUCCESS,
    DATA_PUSH_TASK_DESTINATION_CONNECTION_TEST_ERROR,
    DATA_PUSH_TASK_DESTINATION_CONNECTION_TEST_REQUEST
} from 'src/actions/dataPushTasks';
import _uniq from 'lodash/uniq';
import { combineReducers } from 'redux';
import { createAsyncStatesReducerForAction } from 'src/reducers/utils';

const allIds = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_DATA_PUSH_TASKS_SUCCESS: {
            const { dataPushTaskDestinations } = payload;
            return Object.keys(dataPushTaskDestinations);
        }
        case GET_DATA_PUSH_TASK_SUCCESS:
        case DATA_PUSH_TASK_UPDATE_SUCCESS:
        case DATA_PUSH_TASK_CREATE_SUCCESS:
        case DATA_PUSH_TASK_DESTINATION_UPDATE_SUCCESS: {
            const { dataPushTaskDestination } = payload;
            return _uniq([...state, dataPushTaskDestination.id]);
        }
        default:
            return state;
    }
};

const byIds = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_DATA_PUSH_TASKS_SUCCESS: {
            const { dataPushTaskDestinations } = payload;
            return Object.assign({}, state, dataPushTaskDestinations);
        }
        case GET_DATA_PUSH_TASK_SUCCESS:
        case DATA_PUSH_TASK_UPDATE_SUCCESS:
        case DATA_PUSH_TASK_CREATE_SUCCESS:
        case DATA_PUSH_TASK_DESTINATION_UPDATE_SUCCESS: {
            const { dataPushTaskDestination } = payload;
            return Object.assign({}, state, { [dataPushTaskDestination.id]: dataPushTaskDestination });
        }
        default:
            return state;
    }
};

const asyncStates = combineReducers({
    connectionTest: createAsyncStatesReducerForAction(
        (payload) => payload.destinationId,
        [DATA_PUSH_TASK_DESTINATION_CONNECTION_TEST_REQUEST],
        [DATA_PUSH_TASK_DESTINATION_CONNECTION_TEST_SUCCESS, DATA_PUSH_TASK_DESTINATION_CONNECTION_TEST_ERROR]
    )
});

export const dataPushTaskDestinations = combineReducers({
    asyncStates,
    allIds,
    byIds
});
