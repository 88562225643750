import { createSelector } from 'reselect';

const getSampleServerDataFromStore = (state) => state.sampleServerData;

export const makeSelectSampleServerData = () => createSelector(
    [
        createSelector(
            [
                getSampleServerDataFromStore,
                (_, metricId, visualizationId) => `${metricId}-${visualizationId}`
            ],
            (sampleServerData, identifier) => sampleServerData[identifier] || false
        )
    ],
    (sampleServerDataForIdentifier) => sampleServerDataForIdentifier || {
        loading: true, data: null, error: null, requested: false
    }
);
