import PostInfo from 'src/components/views/postTags/PostInfo';
import React, { memo } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from 'src/stylesheets/views/postTags/postTagging/postTile.scss';
import ProfileInfoListItem from 'src/components/views/profiles/ProfileInfoListItem';
import { makeSelectProfileById } from 'src/selectors/profiles';
import PostKPIs from 'src/components/views/postTags/PostKPIs';
import { modalShowPostDetailInPostTagging } from 'src/actions/overlays';
import PostImage from 'src/components/views/postTags/postTagging/PostImage';
import PostTextHighlighter from 'src/components/views/postTags/postTagging/PostTextHighlighter';

const PostTileBody = (props) => {
    const {
        post, profile, renderFooter, highlightWords, onClick
    } = props;
    const {
        id, snippet, image, kpis
    } = post;

    return (
        <div className={styles.body}>
            <div className={styles.header} onClick={() => { onClick(id); }}>
                <div className={styles.profileInfo}>
                    <ProfileInfoListItem profile={profile} />
                </div>
                <div className={styles.postInfo}>
                    <PostInfo post={post} />
                </div>
            </div>
            <div className={styles.postDetail} onClick={() => { onClick(id); }}>
                <div className={styles.imageWrapper}>
                    <PostImage url={image} />
                </div>
                <div className={styles.detail}>

                    <div className={styles.message}>
                        {
                            snippet
                            && (
                                <PostTextHighlighter phrase={snippet} values={highlightWords} />
                            )
                        }
                        {
                            !snippet
                            && <span className={styles.noMessage}>This post contains no text</span>
                        }
                    </div>
                    <div className={styles.kpis}>
                        <PostKPIs postKpis={kpis} />
                    </div>
                </div>
            </div>

            <div className={styles.postTags}>
                {
                    renderFooter(post)
                }
            </div>
        </div>
    );
};

PostTileBody.propTypes = {
    post: customPropTypes.post.isRequired,
    profile: customPropTypes.profile.isRequired,
    modalShowPostDetailAction: PropTypes.func.isRequired,
    renderFooter: PropTypes.func,
    highlightWords: PropTypes.arrayOf(PropTypes.string),
    onClick: PropTypes.func
};

PostTileBody.defaultProps = {
    renderFooter: () => {},
    highlightWords: [],
    onClick: () => {}
};

const makeMapStateToProps = () => {
    const selectProfileById = makeSelectProfileById();
    return (state, ownProps) => {
        const { post } = ownProps;
        return {
            profile: selectProfileById(state, post.profileId),
        };
    };
};

export default memo(connect(makeMapStateToProps, {
    modalShowPostDetailAction: modalShowPostDetailInPostTagging
})(PostTileBody));
