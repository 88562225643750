import { dashboardTemplateImagesUrl, dashboardTemplatePlaceholderImagesUrl } from 'src/config';
import React, { Component } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import * as routeActions from 'react-router-redux';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import classnames from 'classnames';
import { connect } from 'react-redux';
import DiscoverTileBody from 'src/components/metricSearchResultTile/layout/DiscoverTileBody';
import DiscoverTileFooter from 'src/components/metricSearchResultTile/layout/DiscoverTileFooter';
import DiscoverTileHeader from 'src/components/metricSearchResultTile/layout/DiscoverTileHeader';
import { modalsShowSaveDashboard } from 'src/actions/overlays';
import PropTypes from 'prop-types';
import { selectIsBasedOnPrivateStatisticsByTagIds } from 'src/selectors/tags';
import styles from 'src/stylesheets/discoverTile.scss';
import { withRouter } from 'react-router';

class DiscoverDashboardTemplateTile extends Component {
    constructor(props) {
        super(props);
        this.handleDashboardAddClick = this.handleDashboardAddClick.bind(this);
        this.onDashboardTileClick = this.onDashboardTileClick.bind(this);
    }

    handleDashboardAddClick() {
        const { modalsShowSaveDashboardAction, metricSearchResult } = this.props;
        modalsShowSaveDashboardAction(metricSearchResult.id);
    }

    onDashboardTileClick() {
        const { routePushAction, metricSearchResult } = this.props;
        const { id } = metricSearchResult;
        routePushAction(`/discover/dashboard/${id}`);
    }

    render() {
        const {
            metricSearchResult, className, tags, lazyLoad, isBasedOnPrivateStatistics
        } = this.props;
        const {
            id, name, summary, usage, platformType
        } = metricSearchResult;

        const buttons = (
            <ButtonGroup className={styles.buttons}>
                <Button small icon="dashboard" label="Save dashboard" onClick={this.handleDashboardAddClick} />
            </ButtonGroup>
        );

        return (
            <div className={classnames(styles.tile, className, 'globalTileForHover')} onClick={this.onDashboardTileClick}>
                <DiscoverTileHeader
                  platformType={platformType}
                  name={name}
                  isBasedOnPrivateStatistics={isBasedOnPrivateStatistics}
                />
                <DiscoverTileBody
                  className={styles.tileImage}
                  imageUrl={`${dashboardTemplateImagesUrl}${id}.png`}
                  placeholderImage={dashboardTemplatePlaceholderImagesUrl}
                  summary={summary}
                  tags={tags}
                  lazyLoad={lazyLoad}
                />

                <DiscoverTileFooter
                  usage={usage}
                  buttons={buttons}
                />
            </div>
        );
    }
}

DiscoverDashboardTemplateTile.contextTypes = {
    router: PropTypes.object.isRequired
};

DiscoverDashboardTemplateTile.propTypes = {
    routePushAction: PropTypes.func.isRequired,
    metricSearchResult: customPropTypes.searchResultDashboardTemplate.isRequired,
    tags: customPropTypes.tags.isRequired,
    modalsShowSaveDashboardAction: PropTypes.func.isRequired,
    className: PropTypes.string,
    lazyLoad: PropTypes.bool,
    isBasedOnPrivateStatistics: PropTypes.bool.isRequired
};

DiscoverDashboardTemplateTile.defaultProps = {
    className: '',
    lazyLoad: true
};

const mapStateToProps = (state, ownProps) => ({
    isBasedOnPrivateStatistics: selectIsBasedOnPrivateStatisticsByTagIds(state, ownProps.metricSearchResult.tagIds)
});

export default connect(mapStateToProps, {
    routePushAction: routeActions.push,
    modalsShowSaveDashboardAction: modalsShowSaveDashboard
})(withRouter(DiscoverDashboardTemplateTile));
