import * as customPropTypes from 'src/customPropTypes';
import Body from 'src/components/Body';
import { connect } from 'react-redux';
import DataSourceColumnTable from 'src/components/views/dataSources/DataSourceColumnTable';
import PropTypes from 'prop-types';
import React from 'react';
import ScrollView from 'src/components/ScrollView';
import { selectDataSourceById } from 'src/selectors/dataSources';
import DataSourceDetailHeader from 'src/components/header/DataSourceDetailHeader';
import DataSourceInfo from 'src/components/views/dataSources/DataSourceInfo';
import withStickyHeaderHandling from 'src/components/withStickyHeaderHandling';
import Footer from 'src/components/Footer';
import ErrorView from 'src/components/errors/ErrorView';

const ScrollViewWithStickyHeader = withStickyHeaderHandling(ScrollView);
const dataSourceColumnTableStyle = {
    paddingTop: '18px'
};

const DataSourceDetail = (props) => {
    const { dataSource } = props;

    if (!dataSource) {
        return (
            <ErrorView
              headline="Data source not found"
              error={{ message: 'The data source you tried to access does not exist.' }}
            />
        );
    }

    const {
        name,
        description,
        isPrivateStatistic,
        isTimeSeries,
        hints,
        isApiAccessSupported,
        isDeprecated,
        deprecationDate,
        optionalFilters,
        postTextFilterAttributes
    } = dataSource;
    const stickyIdentifier = `data-source-${name}`;

    return (
        <ScrollViewWithStickyHeader
          dataViewName="dataSourceDetailView"
          stickyHeaderIdentifier={stickyIdentifier}
        >
            <DataSourceDetailHeader
              dataSource={dataSource}
              stickyIdentifier={stickyIdentifier}
            />
            <Body>
                <DataSourceInfo
                  isPrivateStatistic={isPrivateStatistic}
                  isTimeSeries={isTimeSeries}
                  description={description}
                  hints={hints}
                  isApiAccessSupported={isApiAccessSupported}
                  isDeprecated={isDeprecated}
                  deprecationDate={deprecationDate}
                  optionalFilters={optionalFilters}
                  postTextFilterAttributes={postTextFilterAttributes}
                />
                <div style={dataSourceColumnTableStyle}>
                    <DataSourceColumnTable dataSourceName={name} />
                </div>
            </Body>
            <Footer />
        </ScrollViewWithStickyHeader>
    );
};

DataSourceDetail.propTypes = {
    dataSource: PropTypes.oneOfType([customPropTypes.dataSource, PropTypes.bool]).isRequired,
    dataSourceId: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
    const { dataSourceId } = ownProps;
    return {
        dataSource: selectDataSourceById(state, dataSourceId)
    };
};

export default connect(mapStateToProps)(DataSourceDetail);
