import { Select, TextWithResetIcon } from 'src/components/forms/fields';
import { Field } from 'redux-form';
import ListFilters from 'src/components/ListFilters';
import ListOptionsBar from 'src/components/ListOptionsBar';
import PropTypes from 'prop-types';
import React from 'react';
import withReduxForm from 'src/components/forms/withReduxForm';

export const formName = 'customMetricsFilterForm';
export const initialValues = { freeTextFilter: '', sortBy: 'alphabetically' };
const searchFilterStyle = { width: 250 };

const sortingOptions = [
    { value: 'name', label: 'Name (A-Z)' },
    { value: 'lastUpdateTime', label: 'Last modified first' },
    { value: 'createTime', label: 'Newest first' }
];

const CustomMetricSearchForm = (props) => (
    <ListOptionsBar
      filters={(
          <ListFilters>
              <div style={searchFilterStyle}>
                  <Field
                    autoFocus
                    name="freeTextFilter"
                    placeholder="Search custom metrics"
                    component={TextWithResetIcon}
                    layout="listFilter"
                    onResetClick={() => { props.change('freeTextFilter', ''); }}
                    activeOnDirty
                  />
              </div>
          </ListFilters>
      )}
      sorting={(
          <Field
            name="sortBy"
            component={Select}
            layout="listFilter"
          >
              {
                  sortingOptions.map((option) => (
                      <option key={`select-option-${option.value}`} value={option.value}>{option.label}</option>
                  ))
              }
          </Field>
      )}
    />
);

CustomMetricSearchForm.propTypes = {
    change: PropTypes.func.isRequired
};

export default withReduxForm(CustomMetricSearchForm, {
    form: formName,
    initialValues,
    destroyOnUnmount: false
});
