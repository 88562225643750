import { createSelector } from 'reselect';
import { makeSelectQueryFromFilterSelectorByContext } from 'src/selectors/filterSelectors';
import { getPostsSearchInput } from 'src/selectors/posts';
import {
    postsRoute, analysisRoute, metricBuilderRoute, dashboardRoute
} from 'src/routePaths';
import { POST_TAGGING, QUICK_ANALYSIS, METRIC_BUILDER } from 'src/utils/filterSelectors';

export const getLastMainMenuLocations = (state) => state.lastMainMenuLocations;

export const makeSelectLastLocationByMenuName = () => createSelector(
    [
        getLastMainMenuLocations,
        (_, menuName) => menuName
    ],
    (lastMainMenuLocations, menuName) => lastMainMenuLocations[menuName] || false
);

export const makeSelectPostTaggingRoute = () => {
    const selectLastLocationByMenuName = makeSelectLastLocationByMenuName();
    const selectQueryFromFilterSelectorByContext = makeSelectQueryFromFilterSelectorByContext();
    return createSelector(
        [
            (state) => selectQueryFromFilterSelectorByContext(state, POST_TAGGING),
            getPostsSearchInput,
            selectLastLocationByMenuName
        ],
        (query, input, location) => {
            if (location.pathname === postsRoute) {
                const { parameters } = input;
                const { sortDir } = parameters;
                const newQuery = Object.assign({}, query, { sortDir });
                return Object.assign({}, { pathname: location.pathname }, { query: newQuery });
            }
            return location;
        }
    );
};

export const makeSelectAnalysisRoute = () => {
    const selectLastLocationByMenuName = makeSelectLastLocationByMenuName();
    const selectQueryFromFilterSelectorByContextMetricBuilder = makeSelectQueryFromFilterSelectorByContext();
    const selectQueryFromFilterSelectorByContextAnalysis = makeSelectQueryFromFilterSelectorByContext();
    return createSelector(
        [
            (state) => selectQueryFromFilterSelectorByContextAnalysis(state, QUICK_ANALYSIS),
            (state) => selectQueryFromFilterSelectorByContextMetricBuilder(state, METRIC_BUILDER),
            selectLastLocationByMenuName
        ],
        (quickAnalysisQuery, metricBuilderQuery, location) => {
            if (location.pathname.startsWith(analysisRoute)) {
                return Object.assign({}, { pathname: location.pathname }, { query: quickAnalysisQuery });
            }
            if (location.pathname.startsWith(metricBuilderRoute)) {
                return Object.assign({}, { pathname: location.pathname }, { query: metricBuilderQuery });
            }
            if (location.pathname.startsWith(dashboardRoute)) {
                // we can just rely on the old location query here, since the context related location params will always be the same as the last location
                return Object.assign({}, { pathname: location.pathname }, { query: location.query });
            }
            return location;
        }
    );
};
