import React from 'react';
import emptyDataPushTasks from 'src/resources/illustrations/datapushtasks.png';
import IntegrationMissingAdminRoleErrorView from 'src/components/errors/IntegrationMissingAdminRoleErrorView';

const DataPushTaskMissingAdminRoleError = () => (
    <IntegrationMissingAdminRoleErrorView
      headline="Data push tasks"
      title="Push data to an external database"
      description="Data push tasks may only be seen by admin users. Contact an admin user to give you permission."
      image={emptyDataPushTasks}
    />
);

export default DataPushTaskMissingAdminRoleError;
