import notificationEmptyResult from 'src/resources/illustrations/notifications-80.png';
import React from 'react';
import styles from 'src/stylesheets/userNotificationMenu/userNotificationMenuContainer.scss';

const NoUserNotificationView = () => (
    <div className={styles.noNotificationWrapper}>
        <img src={notificationEmptyResult} alt="empty" />
        <div className={styles.noNotificationText}>
            You have no notifications.
        </div>
    </div>
);

export default NoUserNotificationView;
