import { POST_TAGS_POSTS_GET_SUCCESS } from 'src/actions/postTagsPostInfo';
import { POST_TAG_ADD_SUCCESS } from 'src/actions/postTags';

export const postTagsPostsInfo = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case POST_TAGS_POSTS_GET_SUCCESS: {
            const { numberOfPostsByPostTag: numberOfPostsByPostTagFromPayload } = payload;
            return Object.assign({}, state, numberOfPostsByPostTagFromPayload);
        }
        case POST_TAG_ADD_SUCCESS: {
            const { postTag } = payload;
            return Object.assign({}, state, { [postTag.id]: { postTagId: postTag.id, numberOfPosts: 0 } });
        }
        default: {
            return state;
        }
    }
};
