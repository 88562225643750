import { createDeepEqualSelector, createShallowEqualSelector } from 'src/selectors/utils';
import {
    getTagsByIdFormStore, getTagTypesFormStore, selectTagsClusteredByType, visualizationTagIds
} from 'src/selectors/tags';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isArray from 'lodash/isArray';
import _includes from 'lodash/includes';
import _isString from 'lodash/isString';
import { createSelector } from 'reselect';
import { getVisualizationsFromStore } from 'src/selectors/visualizations';
import { selectLastVisitedDiscoverState } from 'src/selectors/lastVisited';
import { convertFilterByToRoute } from 'src/utils/discoverUtiles';
import { discoverRoute } from 'src/routePaths';
import _difference from 'lodash/difference';

const stringToTag = (string) => {
    const regexTags = /^id-(\d+)$/i;
    const match = regexTags.exec(string);
    if (match !== null) {
        return { id: match[1] };
    }
    return false;
};

const extractTagsFromQueryString = (searchParams) => {
    if (_isString(searchParams) && searchParams.length > 0) {
        const tag = stringToTag(searchParams);
        if (tag) {
            return [stringToTag(searchParams)];
        }
        return [];
    } if (_isArray(searchParams)) {
        const matchedTags = [];
        searchParams.forEach((searchParam) => {
            if (searchParam.length > 0) {
                const tag = stringToTag(searchParam);
                if (tag) {
                    matchedTags.push(stringToTag(searchParam));
                }
            }
        });
        return matchedTags;
    }
    return [];
};

export const generateParams = (values) => values.map((value) => {
    if (value.customOption === true) {
        return `${value.name}`;
    }
    return `id-${value.id}`;
});

export const getValidDashboardSearchParams = (search) => {
    const tags = extractTagsFromQueryString(search);
    // this should be made against real visualizationTagTypes 3 not against the array
    const allowedTagIds = _difference(tags.map((tag) => tag.id), visualizationTagIds);
    return generateParams(tags.filter((tag) => _includes(allowedTagIds, tag.id)));
};

export function selectMetricSearchInput(state) {
    return state.metricSearch.input;
}

export function selectMetricSearchFilterBy(state) {
    const input = selectMetricSearchInput(state);
    return input.filterBy;
}

export function selectMetricSearchTerm(state) {
    const input = selectMetricSearchInput(state);
    return input.term;
}

const resolveTags = (tags, tagsById, tagTypes) => tags.map((unresolvedTag) => {
    const tag = tagsById[unresolvedTag.id];
    if (tag) {
        const tagType = tagTypes[tag.tagTypeId];
        return {
            id: tag.id,
            name: tag.name,
            tagType: tagType.name,
            tagTypeId: tagType.id
        };
    }
    return {
        id: '0',
        name: 'Invalid tag',
        tagType: 'undefined',
        tagTypeId: '0'
    };
});

export const selectMetricSearchSelectedTags = createSelector(
    [
        (state) => state.metricSearch.input.selectedTags,
        getTagsByIdFormStore,
        getTagTypesFormStore
    ],
    (tagsFromRoute, tagsById, tagTypes) => resolveTags(tagsFromRoute, tagsById, tagTypes)
);

export const selectMetricSearchQueryParams = createSelector(
    [
        selectMetricSearchInput,
        selectMetricSearchSelectedTags
    ],
    (metricSearchInput, selectedTags) => {
        const query = {};
        if (selectedTags.length > 0) {
            query.search = generateParams(selectedTags);
        }
        if (metricSearchInput.orderBy) {
            query.orderBy = metricSearchInput.orderBy;
        }
        if (metricSearchInput.term !== '') {
            query.term = metricSearchInput.term;
        }
        return query;
    }
);

export const selectTopLevelLinkToDiscover = createSelector(
    [
        selectMetricSearchInput,
        selectLastVisitedDiscoverState
    ],
    (metricSearchInput, lastVisitedDiscoverState) => {
        const route = metricSearchInput.filterBy === '' ? lastVisitedDiscoverState : convertFilterByToRoute(metricSearchInput.filterBy);
        return `${discoverRoute}/${route}`;
    }
);

export const selectLinkToDiscover = createSelector(
    [
        selectMetricSearchQueryParams,
        selectTopLevelLinkToDiscover,
    ],
    (metricSearchQueryParams, basePathname) => {
        if (_has(metricSearchQueryParams, 'search') || _has(metricSearchQueryParams, 'term')) {
            return {
                pathname: `${basePathname}/search`,
                query: metricSearchQueryParams
            };
        }
        return { pathname: basePathname };
    }
);

export const selectLinkToDiscoverForMetrics = createSelector(
    [
        selectMetricSearchQueryParams
    ],
    (metricSearchQueryParams) => {
        const filterBy = 'metrics';
        const basePathname = `${discoverRoute}/${filterBy}`;
        if (!_has(metricSearchQueryParams, 'search')) {
            return { pathname: basePathname };
        }
        return {
            pathname: `${basePathname}/search`,
            query: metricSearchQueryParams
        };
    }
);

const getMetricsFromStore = (state) => state.entities.metricSearchResults.metrics.byId;

const getMetricByIdFromStore = (state, id) => {
    const publicMetrics = getMetricsFromStore(state);
    return publicMetrics[id] || false;
};

export const makeSelectMetricSearchResultMetric = () => createShallowEqualSelector(
    [
        (state, id) => {
            const metric = getMetricByIdFromStore(state, id);
            if (metric) {
                const visualization = getVisualizationsFromStore(state)[metric.visualizationId];
                return {
                    metric, visualization
                };
            }
            return { metric: false };
        }
    ],
    ({ metric, visualization }) => {
        if (metric) {
            return Object.assign({}, metric, { visualization });
        }
        return false;
    }
);

export const makeSelectMetricSearchResultMetricsByIds = () => {
    const selectorCache = {};
    const getSelector = (id) => {
        const selector = _get(selectorCache, id);
        if (selector) { return selector; }
        Object.assign(selectorCache, { [id]: makeSelectMetricSearchResultMetric() });
        return selectorCache[id];
    };

    return createShallowEqualSelector(
        [
            (state, ids) => {
                const entities = {};
                ids.forEach((id) => Object.assign(entities, { [id]: getSelector(id)(state, id) }));
                return entities;
            },
            (state, ids) => ids
        ],
        (metricSearchResultMetric, ids) => (
            ids.filter((id) => metricSearchResultMetric[id] !== false).map((id) => metricSearchResultMetric[id])
        )
    );
};

export const makeSelectMetricSearchResultDashboardTemplate = () => createSelector(
    [
        (state) => state.entities.metricSearchResults.dashboardTemplates.byId,
        (_, id) => id,
    ],
    (dashboardTemplates, id) => dashboardTemplates[id] || false
);

export const makeSelectMetricSearchResultByTypeAndId = () => {
    const selectMetricSearchResultDashboardTemplate = makeSelectMetricSearchResultDashboardTemplate();
    const selectMetricSearchResultMetric = makeSelectMetricSearchResultMetric();
    return (state, type, id) => {
        if (type === 'DashboardTemplate') {
            return selectMetricSearchResultDashboardTemplate(state, id);
        }
        return selectMetricSearchResultMetric(state, id);
    };
};

export const selectIsSearchResultLoading = createSelector(
    [
        (state) => state.entities.metricSearchResults.isLoading,
        (_, type, id) => `${type}_${id}`
    ],
    (isLoading, identifier) => _get(isLoading, [identifier, 'isPending'], false)
);

export const selectHasSearchResultError = createSelector(
    [
        (state) => state.entities.metricSearchResults.errors,
        (_, type, id) => `${type}_${id}`
    ],
    (errors, identifier) => _get(errors, identifier, false)
);

export const makeSelectSearchQueryInputFromLocation = () => createDeepEqualSelector(
    [
        (filterBy) => filterBy,
        (_, search) => search,
        (_, x, orderBy) => orderBy,
        (_, x, y, term) => term
    ],
    (filterBy, search, orderBy, term) => {
        const selectedTags = extractTagsFromQueryString(search);

        // a valid search url needs filterBy, oderBy and either selectedTags or a search term
        if (!!filterBy && !!orderBy && (selectedTags.length > 0 || !!term)) {
            return {
                filterBy,
                selectedTags,
                orderBy,
                term: term !== undefined ? term : ''
            };
        }
        return false;
    }
);

export const makeSelectSearchResultsByInput = () => createSelector(
    [
        (state) => state.metricSearch.results,
        (_, input) => input
    ],
    (results, input) => {
        const identifier = JSON.stringify(input);
        return _get(results, identifier, { loading: false, data: null, error: null });
    }
);

export const selectAutoCompleteSuggestionsByTerm = createSelector(
    [
        (_, term) => term,
        selectTagsClusteredByType
    ],
    (term, tagsClusteredByType) => {
        const termToLower = term.toLowerCase();
        const results = [];
        tagsClusteredByType.forEach((tagsCluster) => {
            const { tags } = tagsCluster;
            tags.forEach((tag) => {
                const tagNameToLower = tag.name.toLowerCase();
                if (tagNameToLower.indexOf(termToLower) !== -1) {
                    results.push({ type: 'tag', item: tag });
                }
            });
        });
        results.push({ type: 'term', item: { term } });
        return results;
    }
);
