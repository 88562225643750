import ContextAwareAdditionalFilterSelector from 'src/components/filterSelectors/ContextAwareAdditionalFilterSelector';
import Column from 'src/components/header/layout/Column';
import ContextAwareDateSelector from 'src/components/filterSelectors/ContextAwareDateSelector';
import ContextAwareProfileSelector from 'src/components/filterSelectors/ContextAwareProfileSelector';
import Header from 'src/components/header/layout/Header';
import PageHeadline from 'src/components/header/layout/PageHeadline';
import React from 'react';
import SelectorButtonGroup from 'src/components/buttons/SelectorButtonGroup';
import TopRow from 'src/components/header/layout/TopRow';

const AnalysisContentHeader = () => (
    <Header>
        <TopRow>
            <Column flexGrow>
                <PageHeadline>Quick analysis</PageHeadline>
            </Column>
            <Column flexShrink>
                <SelectorButtonGroup
                  dateSelector={<ContextAwareDateSelector />}
                  profileSelector={<ContextAwareProfileSelector />}
                  filterSelector={<ContextAwareAdditionalFilterSelector />}
                  rightAlign
                />
            </Column>
        </TopRow>
    </Header>
);

export default AnalysisContentHeader;
