import {
    makeSelectMetricSearchResultMetricsByIds,
    makeSelectMetricSearchResultDashboardTemplate,
    selectHasSearchResultError,
    selectIsSearchResultLoading
} from 'src/selectors/metricSearch';
import { makeSelectTagsById, selectIsBasedOnPrivateStatisticsByTagIds } from 'src/selectors/tags';
import React, { useEffect } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import Body from 'src/components/Body';
import { connect } from 'react-redux';
import DashboardDetailAdditionalInfo from 'src/components/views/discover/dashboardDetail/DashboardDetailAdditionalInfo';
import DashboardDetailHeader from 'src/components/header/DashboardDetailHeader';
import DashboardDetailPageLoading from 'src/components/loadingIndicators/DashboardDetailPageLoading';
import DashboardDetailScreenShot from 'src/components/views/discover/dashboardDetail/DashboardDetailScreenshot';
import DashboardNotFoundPage from 'src/components/views/discover/dashboardDetail/DashboardNotFoundPage';
import Footer from 'src/components/Footer';
import { metricSearchResultRequest } from 'src/actions/metricSearch';
import PropTypes from 'prop-types';
import ScrollView from 'src/components/ScrollView';
import styles from 'src/stylesheets/views/discover/dashboardDetail.scss';

const DashboardDetail = (props) => {
    useEffect(() => {
        const {
            dashboardSearchResultRequestAction,
            searchResultDashboardTemplate,
            error,
            dashboardId
        } = props;

        if (!searchResultDashboardTemplate && !error) {
            dashboardSearchResultRequestAction('DashboardTemplate', dashboardId);
        }
    }, []);

    const {
        searchResultDashboardTemplate,
        searchResultMetrics,
        isLoading,
        error,
        tags,
        isBasedOnPrivateStatistics
    } = props;

    if (isLoading) {
        return <DashboardDetailPageLoading />;
    }

    if (error) {
        return <DashboardNotFoundPage />;
    }

    if (!isLoading && searchResultDashboardTemplate !== false && searchResultMetrics) {
        const { id, name } = searchResultDashboardTemplate;
        return (
            <ScrollView dataViewName="dashboard-detail">
                <DashboardDetailHeader
                  searchResultDashboardTemplate={searchResultDashboardTemplate}
                  isBasedOnPrivateStatistics={isBasedOnPrivateStatistics}
                />
                <Body>
                    <div className={styles.wrapper}>
                        <div className={styles.dashboardDetailScreenshot}>
                            <DashboardDetailScreenShot
                              id={id}
                              name={name}
                            />
                        </div>
                        <DashboardDetailAdditionalInfo
                          searchResultDashboardTemplate={searchResultDashboardTemplate}
                          searchResultMetrics={searchResultMetrics}
                          tags={tags}
                        />
                    </div>
                </Body>
                <Footer />
            </ScrollView>
        );
    }
    return null;
};

DashboardDetail.propTypes = {
    searchResultDashboardTemplate: customPropTypes.searchResultDashboardTemplateOrFalse.isRequired,
    searchResultMetrics: PropTypes.arrayOf(customPropTypes.searchResultMetricOrFalse).isRequired,
    dashboardSearchResultRequestAction: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([customPropTypes.dataLoadingError, PropTypes.bool]).isRequired,
    dashboardId: PropTypes.string.isRequired,
    tags: customPropTypes.tags.isRequired,
    isBasedOnPrivateStatistics: PropTypes.bool.isRequired
};

const makeMapStateToProps = () => {
    const selectTagsById = makeSelectTagsById();
    const selectMetricSearchResultDashboardTemplate = makeSelectMetricSearchResultDashboardTemplate();
    const selectMetricSearchResultMetricsByIds = makeSelectMetricSearchResultMetricsByIds();

    return (state, ownProps) => {
        const { dashboardId } = ownProps.params;
        const searchResultDashboardTemplate = selectMetricSearchResultDashboardTemplate(state, dashboardId);
        const searchResultMetrics = selectMetricSearchResultMetricsByIds(state, searchResultDashboardTemplate.metricIds || []);
        const tags = searchResultDashboardTemplate ? selectTagsById(state, searchResultDashboardTemplate.tagIds) : [];
        const isBasedOnPrivateStatistics = selectIsBasedOnPrivateStatisticsByTagIds(state, searchResultDashboardTemplate.tagIds);

        return {
            searchResultDashboardTemplate,
            searchResultMetrics,
            tags,
            isLoading: selectIsSearchResultLoading(state, 'DashboardTemplate', dashboardId),
            error: selectHasSearchResultError(state, 'DashboardTemplate', dashboardId),
            dashboardId,
            isBasedOnPrivateStatistics
        };
    };
};

export default connect(makeMapStateToProps, {
    dashboardSearchResultRequestAction: metricSearchResultRequest
})(DashboardDetail);
