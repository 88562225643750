export const SWITCH_TO_OLD_TOOL_REQUEST = 'SWITCH_TO_OLD_TOOL_REQUEST';
export const SWITCH_TO_OLD_TOOL_ERROR = 'SWITCH_TO_OLD_TOOL_ERROR';

export const switchToOldToolRequest = () => ({
    type: SWITCH_TO_OLD_TOOL_REQUEST
});

export const switchToOldToolError = (error) => ({
    type: SWITCH_TO_OLD_TOOL_ERROR,
    payload: { error }
});
