import Popover from 'src/components/popovers/layout/Popover';
import React from 'react';
import MenuButton from 'src/components/buttons/MenuButton';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as overlayActions from 'src/actions/overlays';
import { ensurePermission, writePermissions } from 'src/middleware/userPermissionCheck';
import * as postTagActions from 'src/actions/postTags';
import * as customPropTypes from 'src/customPropTypes';
import { tagManagerRoute } from 'src/routePaths';
import * as routeActions from 'react-router-redux';

const PostTagActionOptionsPopover = (props) => {
    const {
        postTag, hidePopover, modalShowPostTagsDeleteAction, postTagsUnarchiveRequestAction, postTagsArchiveRequestAction, routePushAction
    } = props;
    const { id, archived } = postTag;

    return (
        <Popover id="post-tag-action-options-popover" width={140}>
            <ButtonGroup vertical>
                <MenuButton label="Edit" onClick={() => { hidePopover(); routePushAction({ pathname: `${tagManagerRoute}/${id}` }); }} />
                {
                    archived
                    && (
                        <MenuButton label="Unarchive" onClick={() => { hidePopover(); postTagsUnarchiveRequestAction([id]); }} />
                    )
                }
                {
                    !archived
                    && (
                        <MenuButton label="Archive" onClick={() => { hidePopover(); postTagsArchiveRequestAction([id]); }} />
                    )
                }
                <MenuButton label="Delete" onClick={() => { hidePopover(); modalShowPostTagsDeleteAction([id]); }} />
            </ButtonGroup>
        </Popover>
    );
};

PostTagActionOptionsPopover.propTypes = {
    postTag: customPropTypes.postTag.isRequired,
    hidePopover: PropTypes.func,
    modalShowPostTagsDeleteAction: PropTypes.func.isRequired,
    postTagsUnarchiveRequestAction: PropTypes.func.isRequired,
    postTagsArchiveRequestAction: PropTypes.func.isRequired,
    routePushAction: PropTypes.func.isRequired
};

export default connect(null, {
    modalShowPostTagsDeleteAction: ensurePermission(overlayActions.modalShowPostTagsDelete, writePermissions.deletePostTag),
    postTagsUnarchiveRequestAction: ensurePermission(postTagActions.postTagsUnarchiveRequest, writePermissions.archivePostTag),
    postTagsArchiveRequestAction: ensurePermission(postTagActions.postTagsArchiveRequest, writePermissions.archivePostTag),
    routePushAction: routeActions.push,
})(PostTagActionOptionsPopover);
