import React, { useState } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import SpaceSelectorPopover from 'src/components/popovers/SpaceSelectorPopover';
import UserSettingsPopover from 'src/components/popovers/UserSettingsPopover';

const UserSettingWithSpaceSelectorPopover = (props) => {
    const [spaceSelector, changeSpaceSelector] = useState(false);
    const { user, hidePopover } = props;
    return spaceSelector
        ? <SpaceSelectorPopover onBackClick={() => { changeSpaceSelector(false); }} hidePopover={hidePopover} />
        : <UserSettingsPopover id={user.id} onSpaceSelectorClick={() => { changeSpaceSelector(true); }} hidePopover={hidePopover} />;
};

UserSettingWithSpaceSelectorPopover.propTypes = {
    user: customPropTypes.user.isRequired,
    hidePopover: PropTypes.func
};

export default UserSettingWithSpaceSelectorPopover;
