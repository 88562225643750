import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import DashboardInfoListItem from 'src/components/views/dashboards/DashboardInfoListItem';
import { makeSelectDashboardsByIds } from 'src/selectors/dashboards';
import { makeSelectFoldersByFolderIds } from 'src/selectors/folders';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/views/dashboards/checkedDashboardsAndFolders.scss';
import withRemoveListItemIcon from 'src/components/withRemoveListItemIcon';
import SelectedEntityCountWithClearOption from 'src/components/views/SelectedEntityCountWithClearOption';

const DashboardInfoListItemWithRemoveListIcon = withRemoveListItemIcon(DashboardInfoListItem);

const CheckedDashboardsAndFolders = (props) => {
    const {
        folders, dashboards, listName
    } = props;
    const length = folders.length + dashboards.length;
    const dashboardAndFoldersSelected = length > 0;
    return (
        <div className={styles.wrapper}>
            <div className={styles.titleWrapper}>
                <SelectedEntityCountWithClearOption label="Selected" count={length} listName={listName} />
            </div>
            <div className={styles.list}>
                {
                    folders.map(({ id, name }) => (
                        <div
                          className={styles.item}
                          key={`folder-${id}`}
                        >
                            <DashboardInfoListItemWithRemoveListIcon
                              listName={listName}
                              fieldName={`folderListItem-${id}`}
                              name={name}
                              icon="folder"
                            />
                        </div>
                    ))
                }
                {
                    dashboards.map(({ id, name }) => (
                        <div
                          className={styles.item}
                          key={`dashboard-${id}`}
                        >
                            <DashboardInfoListItemWithRemoveListIcon
                              listName={listName}
                              fieldName={`dashboardListItem-${id}`}
                              name={name}
                              icon="dashboard"
                            />
                        </div>
                    ))
                }
                {
                    !dashboardAndFoldersSelected
                    && <div>None</div>
                }
            </div>
        </div>
    );
};

const makeMapStateToProps = () => {
    const selectDashboardsByIds = makeSelectDashboardsByIds();
    const selectFoldersByFolderIds = makeSelectFoldersByFolderIds();

    return (state, ownProps) => {
        const { dashboardIds, folderIds } = ownProps;
        const dashboards = selectDashboardsByIds(state, dashboardIds);
        const folders = selectFoldersByFolderIds(state, folderIds);
        return {
            dashboards,
            folders
        };
    };
};

CheckedDashboardsAndFolders.propTypes = {
    dashboardIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    folderIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    folders: customPropTypes.folders.isRequired,
    dashboards: customPropTypes.dashboards.isRequired,
    listName: PropTypes.string.isRequired
};

export default connect(makeMapStateToProps)(CheckedDashboardsAndFolders);
