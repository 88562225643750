import * as customPropTypes from 'src/customPropTypes';
import Popover from 'src/components/popovers/layout/Popover';
import React, { useEffect, useContext } from 'react';
import ProfileUseCases from 'src/components/ProfileUseCases';
import AdAccountUseCases from 'src/components/AdAccountUseCases';
import PropTypes from 'prop-types';
import { hasSubmitSucceeded } from 'redux-form';
import { connect } from 'react-redux';
import AccountTypeContext from 'src/components/context/AccountTypeContext';
import { getFormNameByAccountNameAndContext } from 'src/utils/accountInsights';

const AccountUseCasePopover = (props) => {
    const {
        account, useCasesUpdated, formName
    } = props;

    useEffect(() => {
        if (useCasesUpdated) {
            const { hidePopover } = props;
            hidePopover();
        }
    }, [useCasesUpdated]);

    const { accountType } = useContext(AccountTypeContext);

    return (
        <Popover id="accountUseCasePopover" key={account.id} width={820}>
            {
                accountType === 'profiles' && <ProfileUseCases profile={account} formName={formName} />
            }
            {
                accountType === 'adAccounts' && <AdAccountUseCases adAccount={account} formName={formName} />
            }
        </Popover>
    );
};

AccountUseCasePopover.propTypes = {
    account: customPropTypes.account.isRequired,
    useCasesUpdated: PropTypes.bool.isRequired,
    formName: PropTypes.string.isRequired,
    hidePopover: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
    const formName = getFormNameByAccountNameAndContext('popover_use_cases_', ownProps.account.id);
    return {
        useCasesUpdated: hasSubmitSucceeded(formName)(state),
        formName
    };
};

export default connect(mapStateToProps)(AccountUseCasePopover);
