import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import PlatformUseCasesTableHeader from 'src/components/views/authentications/table/PlatformUseCasesTableHeader';
import PropTypes from 'prop-types';
import React from 'react';
import { selectUseCasesByPlatform } from 'src/selectors/useCases';
import { MAX_NUMBER_OF_COLUMNS } from 'src/utils/accountInsights';
import AccountInsightsByAuthUserRow from 'src/components/views/authentications/table/AccountInsightsByAuthUserRow';
import ListTable from 'src/components/listTable/ListTable';
import ListTableCell from 'src/components/listTable/ListTableCell';
import EmptyProfileItem from 'src/components/views/profiles/EmptyProfileItem';
import EmptyCells from 'src/components/listTable/EmptyCells';
import ListTableRow from 'src/components/listTable/ListTableRow';

const AccountsUseCasesStatusTable = (props) => {
    const {
        accounts,
        authUser,
        platformUseCases,
        platformType
    } = props;
    const platformUseCasesLength = platformUseCases.length;
    const emptyColumnLength = MAX_NUMBER_OF_COLUMNS - platformUseCasesLength;
    return (
        <ListTable header={(
            <PlatformUseCasesTableHeader
              platformUseCases={platformUseCases}
              platformType={platformType}
            />
        )}
        >
            {
                accounts.map((account) => (
                    <AccountInsightsByAuthUserRow
                      key={account.id}
                      account={account}
                      platformUseCases={platformUseCases}
                      authUserId={authUser.id}
                    />
                ))
            }
            {
                    accounts.length === 0 && (
                    <ListTableRow>
                        <ListTableCell padded={false}><EmptyProfileItem /></ListTableCell>
                        {
                            emptyColumnLength > 0 && <EmptyCells emptyColumnLength={emptyColumnLength} />
                        }
                    </ListTableRow>
                    )
            }
        </ListTable>
    );
};

AccountsUseCasesStatusTable.propTypes = {
    accounts: customPropTypes.accounts.isRequired,
    authUser: customPropTypes.authUser.isRequired,
    platformUseCases: customPropTypes.useCases.isRequired,
    platformType: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
    const { platformType } = ownProps;
    return {
        platformUseCases: selectUseCasesByPlatform(state, platformType)
    };
};

export default connect(mapStateToProps)(AccountsUseCasesStatusTable);
