import React, { useEffect } from 'react';
import { postSearchByTagRequest } from 'src/actions/postTags';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';
import PostTile from 'src/components/views/postTags/postTagManager/PostTile';
import styles from 'src/stylesheets/views/postTags/postTagManager/postTagPostSearch.scss';
import FloatingListTilesSkeleton from 'src/components/loadingIndicators/FloatingListTilesSkeleton';
import Error from 'src/components/errors/Error';
import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import emptyImage from 'src/resources/illustrations/posts_empty.png';
import withTooltip from 'src/components/withToolTip';
import IcomoonIcon from 'src/components/IcomoonIcon';
import classnames from 'classnames';
import { selectPostIdsByPostTagId, makeSelectPostSearchStateByPostTagId, makeSelectPostByIds } from 'src/selectors/posts';

const IcomoonWithTooltip = withTooltip(IcomoonIcon);

const PostTagPostSearch = (props) => {
    const {
        posts, postSearchByTagRequestAction, loadingState, postTag
    } = props;

    useEffect(() => {
        if (!postTag.archived) {
            postSearchByTagRequestAction(postTag.id);
        }
    }, []);

    const handleOnRefreshIconClick = () => {
        postSearchByTagRequestAction(postTag.id);
    };

    const { isPending, error } = loadingState;
    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.title}>Recently tagged posts</div>
                {
                    !postTag.archived
                    && (
                        <IcomoonWithTooltip
                          onClick={handleOnRefreshIconClick}
                          className={classnames(styles.icon, { [styles.disabled]: isPending })}
                          tooltip="Refresh"
                          icon="refresh"
                        />
                    )
                }
            </div>
            <div>
                {
                    (posts.length === 0 && isPending)
                    && (
                        <FloatingListTilesSkeleton numberOfItems={1} listItemClassName={styles.postTileSkeleton} emptyDivClassName={styles.postTileBackground} />
                    )
                }
                {
                    posts.map((post) => (
                        <div key={post.id} className={styles.postTile}>
                            <PostTile post={post} disabled={isPending} postTagId={postTag.id} />
                        </div>
                    ))
                }
                {
                    (!isPending && error)
                    && <Error error={error} />
                }
                {
                    (!isPending && posts.length === 0 && !error)
                    && <EmptyInfoScreen message="No recently tagged posts found" image={emptyImage} imageClassName={styles.emptyImage} />
                }
            </div>
        </div>
    );
};

PostTagPostSearch.propTypes = {
    postTag: customPropTypes.postTag.isRequired,
    posts: customPropTypes.posts.isRequired,
    postSearchByTagRequestAction: PropTypes.func.isRequired,
    loadingState: customPropTypes.asyncDataLoadingState.isRequired
};

const makeMapStateToProps = () => {
    const selectPostSearchStateByPostTagId = makeSelectPostSearchStateByPostTagId();
    const selectPostByIds = makeSelectPostByIds();
    return (state, ownProps) => {
        const { postTag } = ownProps;
        const postIds = selectPostIdsByPostTagId(state, postTag.id);
        return {
            posts: selectPostByIds(state, postIds),
            loadingState: selectPostSearchStateByPostTagId(state, postTag.id),
        };
    };
};

export default connect(makeMapStateToProps, {
    postSearchByTagRequestAction: postSearchByTagRequest
})(PostTagPostSearch);
