export const TOGGLE_SIDE_BAR = 'TOGGLE_SIDE_BAR';
export const OPEN_SIDE_BAR = 'OPEN_SIDE_BAR';

export const toggleSideBar = (sidebar) => ({
    type: TOGGLE_SIDE_BAR,
    payload: { sidebar }
});

export const openSideBar = (sidebar) => ({
    type: OPEN_SIDE_BAR,
    payload: { sidebar }
});
