import * as customPropTypes from 'src/customPropTypes';
import FixBrokenProfileUseCasePopover from 'src/components/popovers/FixBrokenAccountUseCasePopover';
import React from 'react';
import styles from 'src/stylesheets/authTransactions/authTransactionRequirementsTable.scss';
import UseCaseStatusLabel from 'src/components/labels/UseCaseStatusLabel';
import { MAX_NUMBER_OF_COLUMNS } from 'src/utils/accountInsights';
import ListTableCell from 'src/components/listTable/ListTableCell';
import EmptyCells from 'src/components/listTable/EmptyCells';

const getAccountUseCase = (accountUseCaseAuthUsers, useCaseId) => {
    const finalAccountUseCaseAuthUser = accountUseCaseAuthUsers.filter((accountUseCaseAuthUser) => accountUseCaseAuthUser.useCaseId === useCaseId);
    return finalAccountUseCaseAuthUser.length === 1 ? finalAccountUseCaseAuthUser[0] : false;
};

const AccountUseCasesStatusTableColumns = (props) => {
    const {
        accountUseCaseAuthUsers,
        authUserAuthAccountUseCases,
        platformUseCases
    } = props;
    const emptyColumnLength = MAX_NUMBER_OF_COLUMNS - platformUseCases.length;
    return (
        <>
            {
                platformUseCases.map((platformUseCase) => {
                    const accountUseCaseAuthUser = getAccountUseCase(accountUseCaseAuthUsers, platformUseCase.id);
                    const authUserAuthAccountUseCase = getAccountUseCase(authUserAuthAccountUseCases, platformUseCase.id);
                    if (accountUseCaseAuthUser) {
                        return (
                            <ListTableCell key={platformUseCase.id}>
                                <div className={styles.useCase}>
                                    <UseCaseStatusLabel
                                      valid={accountUseCaseAuthUser.valid}
                                      invalidReason={accountUseCaseAuthUser.invalidReason}
                                      overlay={<FixBrokenProfileUseCasePopover />}
                                    />
                                </div>
                            </ListTableCell>
                        );
                    }
                    if (authUserAuthAccountUseCase) {
                        return (
                            <ListTableCell key={platformUseCase.id}>
                                <div className={styles.useCase}>
                                    <UseCaseStatusLabel valid />
                                </div>
                            </ListTableCell>
                        );
                    }
                    return (
                        <ListTableCell key={platformUseCase.id}>
                            <div className={styles.unavailable} />
                        </ListTableCell>
                    );
                })
            }
            {
                emptyColumnLength > 0 && <EmptyCells emptyColumnLength={emptyColumnLength} />
            }
        </>
    );
};

AccountUseCasesStatusTableColumns.propTypes = {
    accountUseCaseAuthUsers: customPropTypes.accountUseCasesAuthUsers.isRequired,
    authUserAuthAccountUseCases: customPropTypes.authUserAuthAccountUseCases.isRequired,
    platformUseCases: customPropTypes.useCases.isRequired
};

export default AccountUseCasesStatusTableColumns;
