export const USER_SECURITY_LOAD_REQUEST = 'USER_SECURITY_LOAD_REQUEST';
export const USER_SECURITY_LOAD_SUCCESS = 'USER_IDENTITIES_LOAD_SUCCESS';
export const USER_SECURITY_LOAD_ERROR = 'USER_IDENTITIES_LOAD_ERROR';

export const userSecurityLoadRequest = (userId) => ({
    type: USER_SECURITY_LOAD_REQUEST,
    payload: { userId }
});
export const userSecurityLoadSuccess = (userId, data) => ({
    type: USER_SECURITY_LOAD_SUCCESS,
    payload: {
        userId,
        data
    }
});
export const userSecurityLoadError = (userId, error) => ({
    type: USER_SECURITY_LOAD_ERROR,
    payload: {
        userId,
        error
    }
});
