import React from 'react';
import Body from 'src/components/Body';
import ReportsHeader from 'src/components/header/ReportsHeader';
import Footer from 'src/components/Footer';
import ScrollView from 'src/components/ScrollView';
import withScrollPosition from 'src/components/withScrollPosition';
import * as customPropTypes from 'src/customPropTypes';
import * as dashboardShareLinksAction from 'src/actions/dashboardShareLinks';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReportsOverviewSidebar from 'src/components/views/reports/sidebar/ReportsOverviewSidebar';
import ContentWithSidebar from 'src/components/views/ContentWithSidebar';
import withListStateHandling from 'src/components/bulkList/withListStateHandling';
import ReportsOverview from 'src/components/views/reports/ReportsOverview';
import { listName } from 'src/utils/reportOverviews';
import { makeSelectReportOverviewIds } from 'src/selectors/reportOverView';
import { makeSelectIsOneItemSelected } from 'src/selectors/lists';
import { selectIsGettingAllDashboardShareLinks } from 'src/selectors/dashboardShareLinks';

import AsyncDataLoader from 'src/components/AsyncDataLoader';

const ReportsWithListHandling = withListStateHandling(ReportsOverview, listName);
const ScrollViewWithScrollPosition = withScrollPosition(ScrollView, 'reports');
const selectReportOverviewIds = makeSelectReportOverviewIds();

const ReportsOverviewManager = (props) => {
    const {
        location,
        allDashboardShareLinksGetRequestAction,
        isOnItemSelected
    } = props;

    return (
        <ContentWithSidebar sidebar={<ReportsOverviewSidebar listName={listName} />} showSidebar={isOnItemSelected}>
            <ScrollViewWithScrollPosition
              scrollPositionIdentifier="reports"
              locationKey={location.key}
              dataViewName="reports"
            >
                <ReportsHeader />
                <Body>
                    <AsyncDataLoader
                      dataSelector={selectReportOverviewIds}
                      requestAction={allDashboardShareLinksGetRequestAction}
                      asyncStateSelector={selectIsGettingAllDashboardShareLinks}
                      isListTable
                      alwaysReload
                    >
                        {
                            (reportOverViewIds) => <ReportsWithListHandling listItemIds={reportOverViewIds} />
                        }
                    </AsyncDataLoader>
                </Body>
                <Footer />
            </ScrollViewWithScrollPosition>
        </ContentWithSidebar>
    );
};

ReportsOverviewManager.propTypes = {
    location: customPropTypes.location,
    allDashboardShareLinksGetRequestAction: PropTypes.func.isRequired,
    isOnItemSelected: PropTypes.bool.isRequired
};

const makeMapStateToProps = () => {
    const selectIsOneItemSelected = makeSelectIsOneItemSelected();
    return (state) => ({
        isOnItemSelected: selectIsOneItemSelected(state, listName)
    });
};

export default connect(makeMapStateToProps, {
    allDashboardShareLinksGetRequestAction: dashboardShareLinksAction.allDashboardShareLinksGetRequest
})(ReportsOverviewManager);
