import FolderInfo from 'src/components/views/folders/FolderInfo';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/folderListItem.scss';
import classnames from 'classnames';

const FolderListItemSuperRoot = ({
    folder, loggedInUser, dashboardCount, folderCount, label, showLabel, showFolderCount, icon, labelIcon, onClick
}) => (
    <div className={styles.listItem}>
        <div className={classnames(styles.folderInfoWrapper, styles.superRoot)} onClick={onClick}>
            <FolderInfo
              folder={folder}
              loggedInUser={loggedInUser}
              dashboardCount={dashboardCount}
              folderCount={folderCount}
              label={label}
              showLabel={showLabel}
              showFolderCount={showFolderCount}
              icon={icon}
              labelIcon={labelIcon}
            />
        </div>
    </div>
);

FolderListItemSuperRoot.propTypes = {
    folder: customPropTypes.folder,
    loggedInUser: customPropTypes.user.isRequired,
    dashboardCount: PropTypes.number.isRequired,
    folderCount: PropTypes.number,
    label: PropTypes.string,
    labelIcon: PropTypes.string,
    showLabel: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    showFolderCount: PropTypes.bool,
    icon: PropTypes.string
};

export default FolderListItemSuperRoot;
