import {
    call, put, takeEvery
} from 'redux-saga/effects';
import {
    SWITCH_TO_OLD_TOOL_REQUEST,
    switchToOldToolError,
} from 'src/actions/switchTools';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import { hideLoading } from 'react-redux-loading-bar';
import { reportError } from 'src/utils/reportError';
import { showNotification } from 'src/actions/notifications';

function* switchToOldToolRequest() {
    try {
        yield put(showNotification('You will be redirected to the current tool version in a few moments.'));
        const serverRequest = createServerRequest();
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-switch-tools/switch-to-old-tool');

        if (response) {
            yield put(hideLoading());
            yield call(() => { window.location = '/'; });
        }
        if (serverError) {
            yield put(switchToOldToolError(serverError));
            yield put(showNotification(serverError.message, 'error'));
        }
    } catch (applicationError) {
        reportError(applicationError);
        yield put(switchToOldToolError(applicationError));
        yield put(showNotification('We are sorry, but something went wrong. Please try again in a few moments.', 'error'));
    }
}

export default function* switchToolsSagas() {
    yield takeEvery(SWITCH_TO_OLD_TOOL_REQUEST, switchToOldToolRequest);
}
