import Body from 'src/components/Body';
import React from 'react';
import ScrollView from 'src/components/ScrollView';
import Footer from 'src/components/Footer';
import DataSourceDetailLoadingHeader from 'src/components/loadingIndicators/DataSourceDetailLoadingHeader';
import styles from 'src/stylesheets/views/dataSources/dataSourceInfo.scss';
import EmptyDiv from 'src/components/loadingIndicators/EmptyDiv';

const tableStyle = {
    paddingTop: '24px'
};

const DataSourceDetailLoading = () => (
    <ScrollView>
        <DataSourceDetailLoadingHeader />
        <Body>
            <div className={styles.wrapper}>
                <div className={styles.leftSection}>
                    <EmptyDiv height={270} />
                </div>
                <div className={styles.rightSection}>
                    <EmptyDiv height={270} />
                </div>
            </div>
            <div style={tableStyle}>
                <EmptyDiv height={600} />
            </div>
        </Body>
        <Footer />
    </ScrollView>
);

export default DataSourceDetailLoading;
