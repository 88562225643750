import AnalysisSidebar from 'src/components/views/analysis/AnalysisSidebar';
import AnalysisStart from 'src/components/views/analysis/AnalysisStart';
import ContentWithSidebar from 'src/components/views/ContentWithSidebar';
import PropTypes from 'prop-types';
import React from 'react';

const Analysis = (props) => {
    const { analysisContent } = props;
    const content = analysisContent || <AnalysisStart />;
    const sidebar = <AnalysisSidebar />;
    return (
        <ContentWithSidebar sidebar={sidebar} sidebarName="analysis">
            {content}
        </ContentWithSidebar>
    );
};

Analysis.propTypes = {
    analysisContent: PropTypes.node
};

export default Analysis;
