import * as customPropTypes from 'src/customPropTypes';
import DataBody from 'src/components/metricTiles/layout/DataBody';
import MetricTile from 'src/components/metricTiles/layout/MetricTile';
import PropTypes from 'prop-types';
import React from 'react';
import SampleDashboardDataHeader from 'src/components/sampleDashboard/SampleDashboardDataHeader';
import styles from 'src/stylesheets/metricTile.scss';

const settings = {};

const selectedDate = {
    from: '2017-08-21',
    to: '2017-08-29',
    interval: 'daily'
};

const selectedGroup = {
    groupIds: [0],
    profileIds: []
};

const SampleMetricDataTile = (props) => {
    const {
        metric, accountOptions, data, loading, error, breakpoint, isAllowedToChangeLayout
    } = props;

    return (
        <MetricTile>
            <SampleDashboardDataHeader
              name={metric.name}
              platformType={metric.platformType}
              isBasedOnPrivateStatistics={metric.isBasedOnPrivateStatistics}
              isAllowedToChangeLayout={isAllowedToChangeLayout}
              breakpoint={breakpoint}
              dragHandleClassName="dragHandle"
            />
            <div className={styles.metricBodyWrapper}>
                <DataBody
                  selectedDate={selectedDate}
                  selectedProfilesOrGroups={selectedGroup}
                  accountOptions={accountOptions}
                  metric={metric}
                  settings={settings}
                  data={data}
                  loading={loading}
                  error={error}
                  isSampleMetric
                  showLinkInTable="none"
                />
            </div>
        </MetricTile>
    );
};

SampleMetricDataTile.propTypes = {
    metric: customPropTypes.minimalMetricShapeForDataLoading.isRequired,
    accountOptions: customPropTypes.accountOptions.isRequired,
    data: customPropTypes.metricServerResponse,
    breakpoint: customPropTypes.gridBreakpoints.isRequired,
    loading: PropTypes.bool.isRequired,
    isAllowedToChangeLayout: PropTypes.bool.isRequired,
    error: customPropTypes.dataLoadingError
};

export default SampleMetricDataTile;
