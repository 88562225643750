import { createTarget } from 'src/middleware/reduxBeaconLoggingTarget';
import { applyMiddleware, compose, createStore } from 'redux';
import { browserHistory } from 'react-router';
import { createMiddleware } from 'redux-beacon';
import createSagaMiddleware from 'redux-saga';
import { GoogleTagManager } from 'redux-beacon/targets/google-tag-manager';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import quintlyApp from 'src/reducers/app.tool';
import { reportError } from 'src/utils/reportError';
import { routerMiddleware } from 'react-router-redux';
import sagas from 'src/sagas/app.tool';
import serverDataCacheInvalidator from 'src/middleware/serverDataCacheInvalidator';
import { createToolAppEventsMapper } from 'src/appEventsMapper';
import userPermissionCheck from 'src/middleware/userPermissionCheck';
import { persistStore, persistReducer } from 'redux-persist';

export default function configureStore(initialState, auth0, persistConfig) {
    const sagaMiddleware = createSagaMiddleware({
        onError(error) { reportError(error); },
        context: auth0
    });

    const eventMap = createToolAppEventsMapper();
    const googleTagManagerMiddleware = createMiddleware(eventMap, GoogleTagManager());
    const customEventMiddleware = createMiddleware(eventMap, createTarget(auth0));
    const persistedReducer = persistReducer(persistConfig, quintlyApp);

    const finalCreateStore = compose(
        applyMiddleware(
            routerMiddleware(browserHistory),
            loadingBarMiddleware({
                promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'ERROR'],
            }),
            sagaMiddleware,
            googleTagManagerMiddleware,
            customEventMiddleware,
            serverDataCacheInvalidator,
            userPermissionCheck
        )
    )(createStore);

    const finalStore = finalCreateStore(persistedReducer, initialState);
    const persistor = persistStore(finalStore);
    sagas.map((saga) => sagaMiddleware.run(saga));
    return { store: finalStore, persistor };
}
