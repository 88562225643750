import DataSourcesLoader from 'src/components/views/dataSources/DataSourcesLoader';
import DataSourceDetailLoading from 'src/components/loadingIndicators/DataSourceDetailLoading';
import React from 'react';
import PropTypes from 'prop-types';
import DataSourceDetail from 'src/components/views/dataSources/DataSourceDetail';

const DataSourceDetailWithDataSources = (props) => {
    const { params } = props;
    const { dataSourceId } = params;
    return (
        <DataSourcesLoader loadingScreen={<DataSourceDetailLoading />}>
            {
                () => <DataSourceDetail dataSourceId={dataSourceId} />
            }
        </DataSourcesLoader>
    );
};

DataSourceDetailWithDataSources.propTypes = {
    params: PropTypes.shape({
        dataSourceId: PropTypes.string.isRequired
    }).isRequired
};

export default DataSourceDetailWithDataSources;
