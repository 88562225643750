import React, { useEffect } from 'react';
import ContentWithSidebar from 'src/components/views/ContentWithSidebar';
import PostTagManagerSidebar from 'src/components/views/postTags/postTagManager/sidebar/PostTagManagerSidebar';
import ScrollView from 'src/components/ScrollView';
import TagManagerHeader from 'src/components/header/TagManagerHeader';
import Body from 'src/components/Body';
import withScrollPosition from 'src/components/withScrollPosition';
import * as customPropTypes from 'src/customPropTypes';
import { makeSelectAllPostTagIds } from 'src/selectors/postTags';
import { connect } from 'react-redux';
import withListStateHandling from 'src/components/bulkList/withListStateHandling';
import PropTypes from 'prop-types';
import { makeSelectIsOneItemSelected } from 'src/selectors/lists';
import styles from 'src/stylesheets/views/postTags/postTagManager/postTagManager.scss';
import PostTags from 'src/components/views/postTags/postTagManager/PostTags';
import Footer from 'src/components/Footer';
import { postTagsPostInfoGetRequest } from 'src/actions/postTagsPostInfo';

const listName = 'postTags';
const PostTagsWithListStateHandling = withListStateHandling(PostTags, listName);

const ScrollViewWithScrollPosition = withScrollPosition(ScrollView, 'tag-manager');

const PostTagManager = (props) => {
    const { location, isAtleastOneTagSelected, postTagIds } = props;

    useEffect(() => {
        const { postTagsPostInfoGetRequestAction } = props;
        postTagsPostInfoGetRequestAction();
    }, []);

    return (
        <ContentWithSidebar sidebar={<PostTagManagerSidebar listName={listName} />} showSidebar={isAtleastOneTagSelected}>
            <ScrollViewWithScrollPosition
              scrollPositionIdentifier="tag-manager"
              locationKey={location.key}
              dataViewName="tag-manager"
            >
                <TagManagerHeader />
                <Body className={styles.body}>
                    <PostTagsWithListStateHandling
                      listItemIds={postTagIds}
                      postTagIds={postTagIds}
                      listName={listName}
                    />
                </Body>
                <Footer />
            </ScrollViewWithScrollPosition>
        </ContentWithSidebar>
    );
};

PostTagManager.propTypes = {
    location: customPropTypes.location,
    postTagIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    isAtleastOneTagSelected: PropTypes.bool.isRequired,
    postTagsPostInfoGetRequestAction: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const selectIsOneItemSelected = makeSelectIsOneItemSelected();
    const selectAllPostTagIds = makeSelectAllPostTagIds();
    return (state) => ({
        isAtleastOneTagSelected: selectIsOneItemSelected(state, listName),
        postTagIds: selectAllPostTagIds(state)
    });
};

export default connect(makeMapStateToProps, {
    postTagsPostInfoGetRequestAction: postTagsPostInfoGetRequest
})(PostTagManager);
