import HotKeys from 'src/components/HotKeys';
import React, { Component, createRef } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import * as routeActions from 'react-router-redux';
import { connect } from 'react-redux';
import ProfileSelector from 'src/components/profileSelector/ProfileSelector';
import PropTypes from 'prop-types';
import {
    makeSelectProfileSelectorSelectionByContext
} from 'src/selectors/filterSelectors';
import { withRouter } from 'react-router';
import withFilterContextConsumer from 'src/components/withFilterContextConsumer';
import { selectedProfilesOrGroupsToParams } from 'src/selectors/utils';

class ContextAwareProfileSelector extends Component {
    constructor(props) {
        super(props);
        this.handleOnProfileOrGroupClick = this.handleOnProfileOrGroupClick.bind(this);
        this.openProfileSelector = this.openProfileSelector.bind(this);
        this.absoluteProfileSelectorRef = createRef();
    }

    handleOnProfileOrGroupClick(selectedProfilesOrGroups) {
        const { routePushAction, routeReplaceAction, location } = this.props;
        const combined = selectedProfilesOrGroupsToParams(selectedProfilesOrGroups);

        if (combined.length > 0) {
            // The router turn the whitespace into +
            const query = Object.assign({}, location.query, { profile: combined.join(' ') });

            if (location.query.profile) {
                routePushAction({
                    pathname: location.pathname,
                    query
                });
            } else {
                routeReplaceAction({
                    pathname: location.pathname,
                    query
                });
            }
        }
    }

    openProfileSelector(event) {
        if (this.absoluteProfileSelectorRef.current) {
            event.preventDefault();
            this.absoluteProfileSelectorRef.current.click();
        }
    }

    render() {
        const {
            placement, small, disabled, selectedProfilesOrGroups
        } = this.props;

        return (
            <HotKeys keys="p" callback={this.openProfileSelector}>
                <ProfileSelector
                  selectedProfilesOrGroups={selectedProfilesOrGroups}
                  handleProfileOrGroupClick={this.handleOnProfileOrGroupClick}
                  placement={placement}
                  small={small}
                  disabled={disabled}
                  ref={this.absoluteProfileSelectorRef}
                />
            </HotKeys>
        );
    }
}

ContextAwareProfileSelector.propTypes = {
    selectedProfilesOrGroups: customPropTypes.profileFilter.isRequired,
    placement: PropTypes.string,
    small: PropTypes.bool,
    routePushAction: PropTypes.func.isRequired,
    routeReplaceAction: PropTypes.func.isRequired,
    location: customPropTypes.location.isRequired,
    disabled: PropTypes.bool,
    context: PropTypes.string,
    filterContext: PropTypes.string.isRequired
};

ContextAwareProfileSelector.defaultProps = {
    placement: 'bottom-start',
    small: false,
    disabled: false,
    context: 'global'
};

const makeMapStateToProps = () => {
    const selectProfileSelectorSelectionByContext = makeSelectProfileSelectorSelectionByContext();
    return (state, ownProps) => ({
        selectedProfilesOrGroups: selectProfileSelectorSelectionByContext(state, ownProps.filterContext),
    });
};

export default withFilterContextConsumer(connect(makeMapStateToProps, {
    routePushAction: routeActions.push,
    routeReplaceAction: routeActions.replace
})(withRouter(ContextAwareProfileSelector)));
