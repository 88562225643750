import ContentBlock from 'src/components/views/ContentBlock';
import { selectAccountDataState, makeSelectBillingAddressAndCreditCardDetail } from 'src/selectors/adminSettings';
import PaymentMethodBody from 'src/components/views/settings/billing/PaymentMethodBody';
import React from 'react';
import AsyncDataLoader from 'src/components/AsyncDataLoader';
import * as adminSettingsAction from 'src/actions/adminSettings';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const selectBillingAddressAndCreditCardDetail = makeSelectBillingAddressAndCreditCardDetail();

const PaymentMethod = (props) => {
    const { accountGetDataRequestAction } = props;
    return (
        <ContentBlock headline="Payment method">
            <AsyncDataLoader
              requestAction={accountGetDataRequestAction}
              dataSelector={selectBillingAddressAndCreditCardDetail}
              asyncStateSelector={selectAccountDataState}
            >
                {
                    ({ creditCard, billingAddress }) => (
                        <PaymentMethodBody
                          creditCard={creditCard}
                          billingAddress={billingAddress}
                        />
                    )
                }
            </AsyncDataLoader>
        </ContentBlock>
    );
};

PaymentMethod.propTypes = {
    accountGetDataRequestAction: PropTypes.func.isRequired
};

export default connect(null, {
    accountGetDataRequestAction: adminSettingsAction.accountGetDataRequest
})(PaymentMethod);
