import { USER_LOGGED_IN } from 'src/actions/loggedInUser';
import { USER_CLIENT_INFO_REGISTRATION_SUCCESS, USER_ONLY_INFO_REGISTRATION_SUCCESS } from 'src/actions/onboarding';
import { EXPORT_FILE_SUCCESS } from 'src/actions/exports';
import { DASHBOARD_ADD_SUCCESS, DASHBOARD_CLONE_DASHBOARD_TEMPLATE_SUCCESS, DASHBOARD_CLONE_SUCCESS } from 'src/actions/dashboard';
import { DASHBOARD_METRICS_ADD_SUCCESS, DASHBOARD_METRICS_DELETE_SUCCESS, DASHBOARD_METRICS_UPDATE_SETTINGS_SUCCESS } from 'src/actions/dashboardMetrics';
import { GROUP_ADD_SUCCESS, GROUP_EDIT_SUCCESS } from 'src/actions/groups';
import {
    APP_STARTED_EVENT,
    ONBOARDING_STARTED_EVENT,
    DELETE_ACCOUNT_EVENT,
    ADJUST_PACKAGE_EVENT,
    USER_ONBOARDING_COMPLETION_EVENT,
    FIRE_CUSTOM_EVENT
} from 'src/actions/appEvents';
import { LOCATION_CHANGE } from 'react-router-redux';
import { BULK_PROFILE_ADD_SUCCESS, ONBOARDING_BULK_PROFILE_ADD_SUCCESS, PROFILE_ADD_TO_OR_REMOVE_FROM_GROUP_SUCCESS } from 'src/actions/profiles';
import { POST_TAG_ADD_SUCCESS } from 'src/actions/postTags';
import {
    ADD_REMOVE_POST_TAGS_FROM_POSTS_BY_FILTER_SUCCESS,
    ADD_REMOVE_POST_TAGS_FROM_POSTS_SUCCESS,
    ADD_REMOVE_POST_TAGS_FROM_POST_SUCCESS
} from 'src/actions/posts';
import { FOLDER_ADD_SUCCESS } from 'src/actions/folders';
import {
    CREATE_USER_SUCCESS, DELETE_USER_SUCCESS, TRANSFER_AND_DELETE_SUCCESS
} from 'src/actions/users';
import { CREATE_SPACE_SUCCESS } from 'src/actions/spaces';
import { CUSTOM_METRIC_SAVE_AS_SUCCESS } from 'src/actions/customMetric';
import { PROFILE_SEARCH_BY_KEYWORD_SUCCESS } from 'src/actions/profileSearch';
import { CREATE_SHAREABLE_LINK_SUCCESS } from 'src/actions/dashboardShareLinks';
import { DASHBOARD_REPORT_ADD_SUCCESS, DASHBOARD_REPORT_UPDATE_SUCCESS } from 'src/actions/dashboardReports';
import _uniq from 'lodash/uniq';
import { FACEBOOK_AUTH_USER_CONNECT_SUCCESS, FACEBOOK_AUTH_USER_FORCE_CONNECT_SUCCESS } from 'src/actions/graphAuthUsers';
import { TWITTER_AUTH_USER_CONNECT_SUCCESS } from 'src/actions/twitterAuthUsers';
import { YOUTUBE_AUTH_USER_CONNECT_SUCCESS } from 'src/actions/youtubeAuthUsers';
import { LINKED_IN_AUTH_USER_CONNECT_SUCCESS, LINKED_IN_AUTH_USER_FORCE_CONNECT_SUCCESS } from 'src/actions/linkedInAuthUsers';
import { SNAPCHAT_AUTH_USER_CONNECT_SUCCESS } from 'src/actions/snapchatAuthUsers';
import { basicUseCaseIdsByProfileType } from 'src/selectors/useCases';
import _includes from 'lodash/includes';

const customNamedEvent = (eventName, option = {}) => ({
    event: 'custom',
    eventName,
    params: JSON.stringify(option),
});

const identify = (action) => {
    const { loggedInUser, clientSetting, packageStatus } = action.payload;
    const { clientId, registerTime, clientName } = clientSetting;
    const { status } = packageStatus;
    const { user, intercomHash } = loggedInUser;
    const {
        id,
        email,
        firstName,
        lastName
    } = user;
    return {
        event: 'identify',
        user: {
            id,
            email,
            intercomHash,
            registerTime,
            name: `${firstName} ${lastName}`,
            companyId: clientId,
            companyName: clientName,
            packageStatus: status,
        }
    };
};

const pageView = (action) => {
    const { pathname, search, hash } = action.payload;
    const page = `${pathname}${search}${hash}`;
    return {
        event: 'virtualPageView',
        page
    };
};

// export
const exportFile = (action) => {
    const { fileType } = action.payload;
    const params = {
        format: fileType,
        nrWidgets: 0,
        oldestDateCovered: null
    };
    return customNamedEvent('export', params);
};

const addFolder = (action) => {
    const { payload } = action;
    const { folders } = payload.folders;
    const name = Object.keys(folders).map((folderId) => folders[folderId].name).join(', ');
    return customNamedEvent('addFolder', { name });
};

const createAddDashboardEvent = (eventType) => (action) => {
    const { dashboard, sharedDashboard } = action.payload;
    const params = {
        name: dashboard.name,
        shared: !!sharedDashboard
    };
    return customNamedEvent(eventType, params);
};

const fireCustomEvent = (action) => {
    const { payload } = action;
    const { eventName } = payload;
    return customNamedEvent(eventName);
};

const addDashboardFromTemplate = createAddDashboardEvent('addDashboardFromTemplate');
const addDashboardFromScratch = createAddDashboardEvent('addDashboard');
const cloneDashboard = createAddDashboardEvent('cloneDashboard');

const addWidget = (action) => {
    const { dashboardMetric, metric } = action.payload;
    const params = {
        metricId: metric.id,
        dashboardId: dashboardMetric.dashboardId
    };
    return customNamedEvent('addWidget', params);
};

const removeWidget = (action) => {
    const { dashboardId, metricId } = action.payload;
    const params = {
        metricId,
        dashboardId
    };
    return customNamedEvent('removeWidget', params);
};

const addProfile = (action, eventName) => {
    const { profiles } = action.payload;
    const networks = _uniq(Object.values(profiles).map((profile) => profile.platformType));
    const params = {
        nrProfiles: Object.keys(profiles).length,
        networks
    };
    return customNamedEvent(eventName, params);
};

const profileSearch = (action) => {
    const { profileSearchInput, resultType } = action.payload;
    return customNamedEvent('profileSearch', { input: profileSearchInput, type: resultType });
};

const addOrRemoveProfilesFromGroups = (action) => {
    const { payload } = action;
    const { profileAddOrRemoveResults } = payload;
    const profilesAddedToGroups = [];
    const profilesRemovedFromGroups = [];
    profileAddOrRemoveResults.forEach((result) => {
        const { profileId, successAdded, successRemoved } = result;
        if (successAdded.length > 0) {
            profilesAddedToGroups.push({
                profileId,
                groupIds: successAdded
            });
        }
        if (successRemoved.length > 0) {
            profilesRemovedFromGroups.push({
                profileId,
                groupIds: successRemoved
            });
        }
    });

    const events = [];
    if (profilesAddedToGroups.length > 0) {
        events.push(customNamedEvent('addProfilesToGroups', { profilesAddedToGroups }));
    }
    if (profilesRemovedFromGroups.length > 0) {
        events.push(customNamedEvent('removeProfilesFromGroups', { profilesRemovedFromGroups }));
    }
    return events;
};

const renameGroup = (action) => {
    const { name, newName } = action.payload;
    const params = {
        oldName: name,
        newName
    };
    return customNamedEvent('renameGroup', params);
};

// addGroup
const addGroup = (action) => {
    const { name } = action.payload;
    const params = {
        name
    };
    return customNamedEvent('addGroup', params);
};

const adjustPackage = (action) => {
    const { packageStatus } = action.payload;
    return customNamedEvent('adjustPackage', { packageStatus });
};

const addUser = (action) => {
    const { payload } = action;
    const { user } = payload;
    return customNamedEvent('addUser', { email: user.email });
};

const deleteUser = (action) => {
    const { payload } = action;
    const { email } = payload;
    return customNamedEvent('deleteUser', { email });
};

const addSpace = (action) => {
    const { payload } = action;
    const { space } = payload;
    return customNamedEvent('addSpace', { name: space.name });
};

const saveAsCustomMetric = (action) => {
    const { payload } = action;
    const { isNewMetric, metric } = payload;
    const eventName = isNewMetric ? 'createCustomMetric' : 'updateCustomMetric';
    return customNamedEvent(eventName, { name: metric.name });
};

const addPostTag = (action) => {
    const { payload } = action;
    const { postTag } = payload;
    return customNamedEvent('addPostTag', { name: postTag.name });
};

const tagOrUnTagPosts = (action) => {
    const { payload } = action;
    const { addToPostTags, removeFromPostTags } = payload;
    const events = [];
    if (addToPostTags.length > 0) {
        events.push(customNamedEvent('tagPosts', { postTagIds: addToPostTags }));
    }
    if (removeFromPostTags.length > 0) {
        events.push(customNamedEvent('untagPosts', { postTagIds: removeFromPostTags }));
    }
    return events;
};

const authUserConnectEvent = (action, platformType) => {
    const { payload } = action;
    const { authTransactionRequirements } = payload;
    if (Object.keys(authTransactionRequirements).length > 0) {
        const useCaseIds = [];
        if (platformType === 'graph') {
            useCaseIds.push(basicUseCaseIdsByProfileType.facebook);
            useCaseIds.push(basicUseCaseIdsByProfileType.instagram);
        } else if (basicUseCaseIdsByProfileType[platformType]) {
            useCaseIds.push(basicUseCaseIdsByProfileType[platformType]);
        }

        let succeededProfileIds = [];
        let failedProfileIds = [];

        // we consider one requirement for one profile, because in onboarding we ask only basic insights use case
        Object.values(authTransactionRequirements).forEach((requirement) => {
            const { profileId, isFulfilled, useCaseId } = requirement;
            if (_includes(useCaseIds, useCaseId)) {
                if (isFulfilled) {
                    succeededProfileIds.push(profileId);
                } else {
                    failedProfileIds.push(profileId);
                }
            }
        });
        succeededProfileIds = _uniq(succeededProfileIds);
        failedProfileIds = _uniq(failedProfileIds);

        if (succeededProfileIds.length > failedProfileIds.length) {
            return customNamedEvent('obStep5AuthenticateSuccess', { network: platformType });
        }
        return customNamedEvent('obStep5AuthenticateFailed', { network: platformType });
    }

    return [];
};

export const createToolAppEventsMapper = () => ({
    [USER_LOGGED_IN]: identify,
    [APP_STARTED_EVENT]: () => customNamedEvent('appStarted'),
    [LOCATION_CHANGE]: (action) => pageView(action),
    [BULK_PROFILE_ADD_SUCCESS]: (action) => addProfile(action, 'addProfile'),
    [PROFILE_SEARCH_BY_KEYWORD_SUCCESS]: profileSearch,
    [PROFILE_ADD_TO_OR_REMOVE_FROM_GROUP_SUCCESS]: addOrRemoveProfilesFromGroups,
    [GROUP_EDIT_SUCCESS]: renameGroup,
    [GROUP_ADD_SUCCESS]: addGroup,
    [FOLDER_ADD_SUCCESS]: addFolder,
    [DASHBOARD_ADD_SUCCESS]: addDashboardFromScratch,
    [DASHBOARD_CLONE_DASHBOARD_TEMPLATE_SUCCESS]: addDashboardFromTemplate,
    [DASHBOARD_CLONE_SUCCESS]: cloneDashboard,
    [DASHBOARD_METRICS_ADD_SUCCESS]: addWidget,
    [DASHBOARD_METRICS_DELETE_SUCCESS]: removeWidget,
    [DASHBOARD_METRICS_UPDATE_SETTINGS_SUCCESS]: () => customNamedEvent('saveWidgetSettings'),
    [DASHBOARD_REPORT_ADD_SUCCESS]: () => customNamedEvent('addReport'),
    [DASHBOARD_REPORT_UPDATE_SUCCESS]: () => customNamedEvent('savedReport'),
    [CREATE_SHAREABLE_LINK_SUCCESS]: () => customNamedEvent('sharedDashboardLink'),
    [EXPORT_FILE_SUCCESS]: exportFile,
    [CUSTOM_METRIC_SAVE_AS_SUCCESS]: saveAsCustomMetric,
    [POST_TAG_ADD_SUCCESS]: addPostTag,
    [ADD_REMOVE_POST_TAGS_FROM_POSTS_BY_FILTER_SUCCESS]: tagOrUnTagPosts,
    [ADD_REMOVE_POST_TAGS_FROM_POSTS_SUCCESS]: tagOrUnTagPosts,
    [ADD_REMOVE_POST_TAGS_FROM_POST_SUCCESS]: tagOrUnTagPosts,
    [CREATE_USER_SUCCESS]: addUser,
    [DELETE_USER_SUCCESS]: deleteUser,
    [TRANSFER_AND_DELETE_SUCCESS]: deleteUser,
    [CREATE_SPACE_SUCCESS]: addSpace,
    [ADJUST_PACKAGE_EVENT]: adjustPackage,
    [DELETE_ACCOUNT_EVENT]: () => customNamedEvent('deleteAccount'),
    [FIRE_CUSTOM_EVENT]: fireCustomEvent
});

export const createOnboardingAppEventsMapper = () => ({
    [USER_LOGGED_IN]: identify,
    [ONBOARDING_STARTED_EVENT]: () => customNamedEvent('onboardingStarted'),
    [USER_CLIENT_INFO_REGISTRATION_SUCCESS]: () => customNamedEvent('obStep1UserCompanyContactInfo'),
    [USER_ONLY_INFO_REGISTRATION_SUCCESS]: () => customNamedEvent('obStep1UserContactInfo'),
    [PROFILE_SEARCH_BY_KEYWORD_SUCCESS]: profileSearch,
    [ONBOARDING_BULK_PROFILE_ADD_SUCCESS]: (action) => addProfile(action, 'obStep2AddProfiles'),
    [USER_ONBOARDING_COMPLETION_EVENT]: () => customNamedEvent('onboardingCompleted'),
    [FIRE_CUSTOM_EVENT]: fireCustomEvent,
    [FACEBOOK_AUTH_USER_CONNECT_SUCCESS]: (action) => authUserConnectEvent(action, 'graph'),
    [FACEBOOK_AUTH_USER_FORCE_CONNECT_SUCCESS]: (action) => authUserConnectEvent(action, 'graph'),
    [TWITTER_AUTH_USER_CONNECT_SUCCESS]: (action) => authUserConnectEvent(action, 'twitter'),
    [LINKED_IN_AUTH_USER_CONNECT_SUCCESS]: (action) => authUserConnectEvent(action, 'linkedIn'),
    [LINKED_IN_AUTH_USER_FORCE_CONNECT_SUCCESS]: (action) => authUserConnectEvent(action, 'linkedIn'),
    [YOUTUBE_AUTH_USER_CONNECT_SUCCESS]: (action) => authUserConnectEvent(action, 'youtube'),
    [SNAPCHAT_AUTH_USER_CONNECT_SUCCESS]: (action) => authUserConnectEvent(action, 'snapchatShow')
});

export const createPublicAppEventsMapper = () => ({
});
