import { put, takeEvery } from 'redux-saga/effects';
import { parseResults, parseResultsForEntities } from 'src/parsers';
import {
    RECOMMENDATIONS_LOAD_REQUEST,
    RECOMMENDATIONS_SEARCH_LOAD_REQUEST,
    recommendationsLoadError,
    recommendationsLoadSuccess,
    recommendationsSearchLoadError,
    recommendationsSearchLoadSuccess
} from 'src/actions/recommendations';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import { reportError } from 'src/utils/reportError';

function* recommendationsLoadRequest(action) {
    const { payload } = action;
    try {
        const serverRequest = createServerRequest({
            identifier: payload.identifier,
            payload: payload.payload
        });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-discover/recommendations');
        if (response) {
            const { results } = response.jsonData;
            yield put(recommendationsLoadSuccess(payload.identifier, parseResults(results), parseResultsForEntities(results)));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(recommendationsLoadError(payload.identifier, applicationOrServerError));
    }
}

function* recommendationsSearchLoadRequest(action) {
    const { payload } = action;

    try {
        const { searchParams } = payload;
        const params = {
            tagIds: searchParams.tagIds.join(','),
        };

        if (searchParams.freeTextTags !== undefined) {
            params.freeTextTags = searchParams.freeTextTags;
        }
        if (searchParams.orderBy !== undefined) {
            params.orderBy = searchParams.orderBy;
        }
        if (searchParams.filterBy !== undefined) {
            params.filterBy = searchParams.filterBy;
        }
        if (searchParams.limit !== undefined) {
            params.limit = searchParams.limit;
        }

        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-discover/search');
        if (response) {
            const { results } = response.jsonData;
            yield put(recommendationsSearchLoadSuccess(payload.identifier, parseResults(results), parseResultsForEntities(results)));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(recommendationsSearchLoadError(payload.identifier, applicationOrServerError));
    }
}

export default function* recommendationsSagas() {
    yield takeEvery(RECOMMENDATIONS_LOAD_REQUEST, recommendationsLoadRequest);
    yield takeEvery(RECOMMENDATIONS_SEARCH_LOAD_REQUEST, recommendationsSearchLoadRequest);
}
