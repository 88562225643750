import React, { useEffect } from 'react';
import * as dataPushTaskActions from 'src/actions/dataPushTasks';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeSelectDataPushTaskById, makeSelectDataPushTaskLoadingState } from 'src/selectors/dataPushTasks';
import * as customPropTypes from 'src/customPropTypes';
import _get from 'lodash/get';
import ErrorView from 'src/components/errors/ErrorView';

const DataPushTaskLoader = (props) => {
    const {
        loadingState, children, dataPushTask, loadingScreen
    } = props;
    const isPending = _get(loadingState, 'isPending', false);
    const error = _get(loadingState, 'error', null);

    useEffect(() => {
        const { getDataPushTaskRequestAction, dataPushTaskId } = props;
        getDataPushTaskRequestAction(`${dataPushTaskId}`);
    }, []);

    if (dataPushTask) {
        return children(dataPushTask);
    }

    if (isPending) {
        return loadingScreen;
    }

    if (error) {
        return (
            <ErrorView
              headline="Data push task failed to load"
              error={error}
            />
        );
    }

    return null;
};

DataPushTaskLoader.propTypes = {
    dataPushTask: PropTypes.oneOfType([customPropTypes.dataPushTask, PropTypes.bool]).isRequired,
    dataPushTaskId: PropTypes.string.isRequired,
    loadingState: customPropTypes.asyncDataLoadingState.isRequired,
    children: PropTypes.func.isRequired,
    loadingScreen: PropTypes.node.isRequired,
    getDataPushTaskRequestAction: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const selectDataPushTaskLoadingState = makeSelectDataPushTaskLoadingState();
    const selectDataPushTaskById = makeSelectDataPushTaskById();
    return (state, ownProps) => {
        const { dataPushTaskId } = ownProps;
        return {
            dataPushTaskId,
            dataPushTask: selectDataPushTaskById(state, dataPushTaskId),
            loadingState: selectDataPushTaskLoadingState(state, dataPushTaskId)
        };
    };
};

export default connect(makeMapStateToProps, {
    getDataPushTaskRequestAction: dataPushTaskActions.getDataPushTaskRequest
})(DataPushTaskLoader);
