import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import React, { memo } from 'react';
import styles from 'src/stylesheets/views/postTags/postTagging/postFloatingListItem.scss';
import PropTypes from 'prop-types';

const PostFloatingListItem = ({ children, className }) => (
    <li className={classnames(styles.post, styles.wrapper, className)}>
        {children}
    </li>
);

PostFloatingListItem.propTypes = {
    children: customPropTypes.children,
    className: PropTypes.string
};

PostFloatingListItem.defaultProps = {
    className: ''
};

export default memo(PostFloatingListItem);
