import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/views/detailText.scss';
import * as customPropTypes from 'src/customPropTypes';

const DetailText = (props) => {
    const {
        label, children, className, moreInfo
    } = props;
    return (
        <div className={classnames(styles.wrapper, className)}>
            <div className={styles.labelSection}>
                <div className={styles.label}>{label}</div>
                {
                    moreInfo
                        && (
                            <div className={styles.moreInfo}>
                                {moreInfo}
                            </div>
                        )
                }
            </div>
            <div className={styles.children}>{children}</div>
        </div>
    );
};

DetailText.propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    moreInfo: customPropTypes.children
};

DetailText.defaultProps = {
    className: ''
};

export default DetailText;
