import * as customPropTypes from 'src/customPropTypes';
import { Highlighter } from 'react-bootstrap-typeahead';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/autocompleteMenuItem.scss';

const AutocompleteMenuTagItem = (props) => {
    const {
        autocompleteSuggestionTag, searchTerm
    } = props;
    const { item } = autocompleteSuggestionTag;
    return (
        <div
          className={styles.item}
        >
            <Highlighter search={searchTerm} matchClass={styles.highlight}>{item.name}</Highlighter>
        </div>
    );
};

AutocompleteMenuTagItem.propTypes = {
    autocompleteSuggestionTag: customPropTypes.autocompleteSuggestionTag.isRequired,
    searchTerm: PropTypes.string.isRequired
};

export default AutocompleteMenuTagItem;
