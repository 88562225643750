import { selectMetricSearchFilterBy, selectMetricSearchSelectedTags, selectMetricSearchTerm } from 'src/selectors/metricSearch';
import * as customPropTypes from 'src/customPropTypes';
import * as metricSearchActionCreators from 'src/actions/metricSearch';
import { connect } from 'react-redux';
import IcomoonIcon from 'src/components/IcomoonIcon';
import OrderBy from 'src/components/forms/inputs/OrderBy';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/searchSummaryText.scss';
import TagList from 'src/components/TagList';

const orderByOptions = [
    { value: 'mostUsed', label: 'Most used' },
    { value: 'newestFirst', label: 'Newest first' },
    { value: 'alphabetically', label: 'Alphabetically' }
];

const filters = {
    customMetrics: 'custom metrics',
    publicMetrics: 'public metrics',
    metrics: 'metrics',
    dashboards: 'dashboards',
};

const generateText = ({ loading, error, data }, filterBy) => {
    const filterName = filters[filterBy];
    if (loading) {
        return `Loading ${filterName} filtered by`;
    } if (error) {
        return `Loading ${filterName} resulted in an error for`;
    } if (data) {
        return `Showing ${data.length} ${filterName} filtered by`;
    }
    return null;
};

const SearchSummaryText = (props) => {
    const {
        selectedTagsForResult,
        metricSearchSearchResult,
        orderByUpdateAction,
        metricSearchInput,
        termForResult,
        metricSearchSelectionRemoveAction,
        metricSearchTermChangedAction,
        filterBy,
        metricSearchEmptySelectionAction
    } = props;

    const { data, error, loading } = metricSearchSearchResult;

    let tagsToRender = selectedTagsForResult;

    if (termForResult !== '') {
        tagsToRender = [...selectedTagsForResult, { name: termForResult, customOption: true }];
    }

    const handleCloseClick = (tag) => {
        if (tag.customOption === true) {
            metricSearchTermChangedAction('');
        } else {
            metricSearchSelectionRemoveAction(tag);
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.textWrapper}>
                <div key="searchSummaryText" className={styles.summaryLineText}>{ generateText({ loading, error, data }, filterBy) }</div>
                <TagList key="searchSummaryTags" tags={tagsToRender} onCloseClick={handleCloseClick} />
                <div key="searchSummaryClearAll" className={styles.clearAll}>
                    <a
                      className={styles.clearAllText}
                      onClick={metricSearchEmptySelectionAction}
                      role="button"
                    >
                        <span className={styles.buttonText}>Clear all</span> <IcomoonIcon className={styles.clearAllIcon} icon="clear" />
                    </a>
                </div>
            </div>
            <div className={styles.orderByWrapper}>
                <OrderBy
                  disabled={loading || !!error || !data || (data.length < 1)}
                  orderBy={metricSearchInput.orderBy}
                  options={orderByOptions}
                  onSelectionChange={orderByUpdateAction}
                />
            </div>
        </div>
    );
};

SearchSummaryText.propTypes = {
    metricSearchSearchResult: customPropTypes.metricSearchSearchResult.isRequired,
    metricSearchInput: customPropTypes.metricSearchInput.isRequired,
    selectedTagsForResult: customPropTypes.tags.isRequired,
    orderByUpdateAction: PropTypes.func.isRequired,
    termForResult: PropTypes.string.isRequired,
    metricSearchSelectionRemoveAction: PropTypes.func.isRequired,
    metricSearchTermChangedAction: PropTypes.func.isRequired,
    filterBy: PropTypes.string.isRequired,
    metricSearchEmptySelectionAction: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    const selectedTagsForResult = selectMetricSearchSelectedTags(state);
    const termForResult = selectMetricSearchTerm(state);
    const filterBy = selectMetricSearchFilterBy(state);
    return {
        selectedTagsForResult,
        termForResult,
        filterBy
    };
}

export default connect(mapStateToProps, {
    orderByUpdateAction: metricSearchActionCreators.metricSearchOrderByUpdate,
    metricSearchSelectionRemoveAction: metricSearchActionCreators.metricSearchSelectionRemove,
    metricSearchTermChangedAction: metricSearchActionCreators.metricSearchTermChanged,
    metricSearchEmptySelectionAction: metricSearchActionCreators.metricSearchEmptySelection
})(SearchSummaryText);
