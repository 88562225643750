import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/userInfo.scss';
import UserIcon from 'src/components/UserIcon';

const UserInfo = (props) => {
    const { name, iconUrl } = props;
    return (
        <div className={styles.wrapper}>
            <div className={styles.iconWrapper}>
                <UserIcon iconUrl={iconUrl} />
            </div>
            <DivWithTooltip tooltip={name} className={styles.name}>
                {name}
            </DivWithTooltip>
        </div>
    );
};

UserInfo.propTypes = {
    name: PropTypes.string.isRequired,
    iconUrl: PropTypes.string.isRequired
};

export default UserInfo;
