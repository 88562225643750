import ButtonGroup from 'src/components/buttons/ButtonGroup';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/navigations/topNav.scss';
import { getPackageStatus } from 'src/selectors/adminSettings';
import { connect } from 'react-redux';
import TrialPeriodReminderButton from 'src/components/buttons/TrialPeriodReminderButton';
import * as customPropTypes from 'src/customPropTypes';

const TopNav = ({ children, packageStatus }) => (
    <div className={styles.wrapper}>
        <ButtonGroup className={styles.buttons}>
            {children}
        </ButtonGroup>
        {
            packageStatus.status === 'trial'
            && (
                <div className={styles.trial}>
                    <TrialPeriodReminderButton packageValid={packageStatus.packageValid} />
                </div>
            )
        }
    </div>
);

TopNav.propTypes = {
    children: PropTypes.node.isRequired,
    packageStatus: customPropTypes.packageStatus.isRequired
};

const mapStateToProps = (state) => ({
    packageStatus: getPackageStatus(state)
});

export default connect(mapStateToProps)(TopNav);
