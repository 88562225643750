import React from 'react';
import Sidebar from 'src/components/sidebar/Sidebar';
import { connect } from 'react-redux';
import styles from 'src/stylesheets/views/postTags/postTagManager/postTagManagerSidebar.scss';
import Button from 'src/components/buttons/Button';
import { makeSelectCheckedValues } from 'src/selectors/lists';
import PropTypes from 'prop-types';
import PostTagListItem from 'src/components/views/postTags/postTagManager/sidebar/PostTagListItem';
import withRemoveListItemIcon from 'src/components/withRemoveListItemIcon';
import * as overlayActions from 'src/actions/overlays';
import { ensurePermission, writePermissions } from 'src/middleware/userPermissionCheck';
import * as postTagActions from 'src/actions/postTags';
import { makeSelectPostTagsByIds, selectIsPostTagsArchiving, selectIsPostTagsUnarchiving } from 'src/selectors/postTags';
import * as customPropTypes from 'src/customPropTypes';
import memoizeOne from 'memoize-one';
import IntercomVerticalWhiteSpace from 'src/components/sidebar/IntercomVerticalWhiteSpace';
import SelectedEntityCountWithClearOption from 'src/components/views/SelectedEntityCountWithClearOption';

const PostTagListItemWithRemoveListItemIcon = withRemoveListItemIcon(PostTagListItem);
const isAllPostTagsArchived = (postTags) => postTags.every((postTag) => postTag.archived === true);
const memoizedIsAllPostTagsArchived = memoizeOne(isAllPostTagsArchived);

const PostTagManagerSidebar = (props) => {
    const {
        postTags,
        postTagIds,
        listName,
        modalShowPostTagsDeleteAction,
        postTagsArchiveRequestAction,
        postTagsUnarchiveRequestAction,
        isPostTagsArchiving,
        isPostTagsUnarchiving
    } = props;
    const isAllArchived = memoizedIsAllPostTagsArchived(postTags);

    return (
        <Sidebar>
            <div className={styles.wrapper}>
                <div className={styles.controls}>
                    <Button
                      small
                      stretch
                      label="Delete"
                      className={styles.button}
                      onClick={() => { modalShowPostTagsDeleteAction(postTagIds); }}
                    />
                    {
                        isAllArchived
                        && (
                            <Button
                              small
                              stretch
                              disabled={isPostTagsUnarchiving}
                              onClick={() => { postTagsUnarchiveRequestAction(postTagIds); }}
                              label="Unarchive"
                            />
                        )
                    }
                    {
                        !isAllArchived
                        && (
                            <Button
                              small
                              stretch
                              disabled={isPostTagsArchiving}
                              onClick={() => { postTagsArchiveRequestAction(postTagIds); }}
                              label="Archive"
                            />
                        )
                    }
                </div>
                <div className={styles.selectionInfo}>
                    <SelectedEntityCountWithClearOption label="Selected tag" count={postTagIds.length} listName={listName} />
                </div>
                <div className={styles.selectedTags}>
                    {
                        postTags.map(({ id }) => (
                            <div className={styles.tagListItem} key={id}>
                                <PostTagListItemWithRemoveListItemIcon
                                  id={id}
                                  listName={listName}
                                  fieldName={id}
                                />
                            </div>
                        ))
                    }
                    <IntercomVerticalWhiteSpace />
                </div>
            </div>
        </Sidebar>
    );
};

PostTagManagerSidebar.propTypes = {
    postTags: customPropTypes.postTags.isRequired,
    postTagIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    listName: PropTypes.string.isRequired,
    modalShowPostTagsDeleteAction: PropTypes.func.isRequired,
    postTagsUnarchiveRequestAction: PropTypes.func.isRequired,
    postTagsArchiveRequestAction: PropTypes.func.isRequired,
    isPostTagsArchiving: PropTypes.bool.isRequired,
    isPostTagsUnarchiving: PropTypes.bool.isRequired
};

const makeMapStateToProps = () => {
    const selectCheckedValues = makeSelectCheckedValues();
    const selectPostTagsByIds = makeSelectPostTagsByIds();
    return (state, ownProps) => {
        const postTagIds = selectCheckedValues(state, ownProps.listName);
        const postTags = selectPostTagsByIds(state, postTagIds);
        return {
            postTags,
            postTagIds,
            isPostTagsArchiving: selectIsPostTagsArchiving(state),
            isPostTagsUnarchiving: selectIsPostTagsUnarchiving(state)
        };
    };
};

export default connect(makeMapStateToProps, {
    modalShowPostTagsDeleteAction: ensurePermission(overlayActions.modalShowPostTagsDelete, writePermissions.deletePostTag),
    postTagsUnarchiveRequestAction: ensurePermission(postTagActions.postTagsUnarchiveRequest, writePermissions.archivePostTag),
    postTagsArchiveRequestAction: ensurePermission(postTagActions.postTagsArchiveRequest, writePermissions.archivePostTag)
})(PostTagManagerSidebar);
