import * as customPropTypes from 'src/customPropTypes';
import * as exportActions from 'src/actions/exports';
import { connect } from 'react-redux';
import { createSingleServerRequestForExport } from 'src/requestHandling/metricDataRequests';
import ExportButton from 'src/components/exportButtons/ExportButton';
import { makeSelectPaginationAndSorting } from 'src/selectors/tablePagination';
import PropTypes from 'prop-types';
import React from 'react';
import { getLoggedInSpaceId } from 'src/selectors/loggedInUser';

const MetricExportButton = (props) => {
    const {
        triggerMetricExportAction,
        small,
        multiAction,
        label,
        tooltip,
        icon,
        paginationAndSorting,
        serverRequest,
        fileName,
        spaceId
    } = props;

    const metricExportActionWithSortingParameters = (fileType) => {
        const singleServerRequestForExport = createSingleServerRequestForExport(serverRequest, paginationAndSorting, fileType, { spaceId });
        triggerMetricExportAction(singleServerRequestForExport, fileName);
    };

    return (
        <ExportButton
          exportAction={metricExportActionWithSortingParameters}
          small={small}
          multiAction={multiAction}
          label={label}
          tooltip={tooltip}
          icon={icon}
        />
    );
};

MetricExportButton.propTypes = {
    triggerMetricExportAction: PropTypes.func.isRequired,
    small: PropTypes.bool,
    multiAction: PropTypes.bool,
    label: PropTypes.string,
    tooltip: PropTypes.string,
    icon: PropTypes.string,
    fileName: PropTypes.string.isRequired,
    paginationAndSorting: customPropTypes.paginationAndSorting,
    serverRequest: customPropTypes.serverRequest.isRequired,
    spaceId: PropTypes.string.isRequired
};

MetricExportButton.defaultProps = {
    small: false,
    multiAction: false,
    label: '',
    tooltip: '',
    icon: '',
};

const makeMapStateToProps = () => {
    const selectPaginationAndSorting = makeSelectPaginationAndSorting();
    return (state, ownProps) => {
        const { identifier } = ownProps.serverRequest.request;
        return {
            paginationAndSorting: selectPaginationAndSorting(state, identifier),
            spaceId: getLoggedInSpaceId(state)
        };
    };
};

export default connect(makeMapStateToProps, {
    triggerMetricExportAction: exportActions.triggerMetricExport,
})(MetricExportButton);
