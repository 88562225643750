import React, { useEffect } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import * as routeActions from 'react-router-redux';
import _pick from 'lodash/pick';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeSelectQueryFromFilterSelectorByContext } from 'src/selectors/filterSelectors';
import { getDashboardContext } from 'src/utils/filterSelectors';
import FilterContextProvider from 'src/components/views/FilterContextProvider';

const withDashboardEnforcedSelectorParameters = (WrappedComponent) => {
    const WithDashboardEnforcedSelectorParameters = (props) => {
        const {
            location, defaultQueryParams, routeReplaceAction, filterContext
        } = props;

        useEffect(() => {
            const requiredKeys = Object.keys(defaultQueryParams);
            const availableKeys = Object.keys(location.query);
            const missingKeys = requiredKeys.filter((el) => !availableKeys.includes(el));
            if (missingKeys.length > 0) {
                const missingValues = _pick(defaultQueryParams, missingKeys);
                const newQuery = Object.assign({}, location.query, missingValues);
                routeReplaceAction({ pathname: location.pathname, query: newQuery });
            }
        }, []);
        return (
            <FilterContextProvider context={filterContext}>
                <WrappedComponent {...props} />
            </FilterContextProvider>
        );
    };

    WithDashboardEnforcedSelectorParameters.propTypes = {
        filterContext: PropTypes.string.isRequired,
        location: customPropTypes.location.isRequired,
        defaultQueryParams: PropTypes.shape({
            from: PropTypes.string.isRequired,
            to: PropTypes.string.isRequired,
            interval: PropTypes.string.isRequired,
            profile: PropTypes.string.isRequired
        }).isRequired,
        routeReplaceAction: PropTypes.func.isRequired
    };

    const makeMapStateToProps = () => {
        const selectQueryFromFilterSelectorByContext = makeSelectQueryFromFilterSelectorByContext();
        return (state, ownProps) => {
            const { dashboardId } = ownProps.params;
            const filterContext = getDashboardContext(dashboardId);
            return {
                defaultQueryParams: selectQueryFromFilterSelectorByContext(state, filterContext),
                filterContext
            };
        };
    };

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    WithDashboardEnforcedSelectorParameters.displayName = `withDashboardEnforcedSelectorParameters(${wrappedComponentName})`;

    return connect(makeMapStateToProps, {
        routeReplaceAction: routeActions.replace
    })(WithDashboardEnforcedSelectorParameters);
};

export default withDashboardEnforcedSelectorParameters;
