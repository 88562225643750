import { connect } from 'react-redux';
import { makeSelectAnalysisMetricLink } from 'src/selectors/analysis';
import { makeSelectCustomMetricById } from 'src/selectors/metrics';
import { makeSelectMetricSearchResultByTypeAndId } from 'src/selectors/metricSearch';
import { QUICK_ANALYSIS } from 'src/utils/filterSelectors';
import { makeSelectVisualizationTagsByIds } from 'src/selectors/tags';

const withAnalysisQuickLinkFromStore = (WrappedComponent) => {
    const makeMapStateToProps = () => {
        const selectMetricSearchResultByTypeAndId = makeSelectMetricSearchResultByTypeAndId();
        const selectAnalysisMetricLink = makeSelectAnalysisMetricLink();
        const selectCustomMetricById = makeSelectCustomMetricById();
        const selectVisualizationTagsByIds = makeSelectVisualizationTagsByIds();
        return (state, ownProps) => {
            // it can either be a customMetric or a publicMetric so lookup customMetric first
            const to = selectAnalysisMetricLink(state, QUICK_ANALYSIS, ownProps.id);
            const customMetric = selectCustomMetricById(state, ownProps.id);

            if (customMetric) {
                return {
                    label: customMetric.name,
                    visualizationTags: selectVisualizationTagsByIds(state, customMetric.tagIds),
                    to
                };
            }
            const metricSearchResult = selectMetricSearchResultByTypeAndId(state, 'Metric', ownProps.id);
            return {
                label: metricSearchResult.name,
                visualizationTags: selectVisualizationTagsByIds(state, metricSearchResult.tagIds),
                to
            };
        };
    };

    const WithAnalysisQuickLinkFromStore = connect(makeMapStateToProps)(WrappedComponent);

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    WithAnalysisQuickLinkFromStore.displayName = `withAnalysisQuickLinkFromStore(${wrappedComponentName})`;

    return WithAnalysisQuickLinkFromStore;
};

export default withAnalysisQuickLinkFromStore;
