import Column from 'src/components/header/layout/Column';
import Header from 'src/components/header/layout/Header';
import PageHeadline from 'src/components/header/layout/PageHeadline';
import React from 'react';
import TopRow from 'src/components/header/layout/TopRow';
import Button from 'src/components/buttons/Button';
import * as dataPushTaskActions from 'src/actions/dataPushTasks';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getShowInactiveDataPushTasks } from 'src/selectors/showInactiveDataPushTasks';
import { makeSelectHasAtLeastOneInActiveDataPushTask } from 'src/selectors/dataPushTasks';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { modalShowDataPushTaskAdd } from 'src/actions/overlays';
import { selectDataPushTaskLimitAndUsage } from 'src/selectors/client';
import withTooltip from 'src/components/withToolTip';
import DataPushTaskLimit from 'src/components/views/integrations/dataPushTasks/DataPushTaskLimit';
import Separator from 'src/components/header/layout/Separator';
import * as customPropTypes from 'src/customPropTypes';

const ButtonWithTooltip = withTooltip(Button);

const DataPushTasksHeader = (props) => {
    const {
        toggleInactiveDataPushTasksAction,
        isInactiveDataPushTaskVisible,
        hasAtLeastOneInActiveDataPushTask,
        modalShowDataPushTaskAddAction,
        dataPushTaskLimitsAndUsage
    } = props;
    const icon = isInactiveDataPushTaskVisible ? 'hide' : 'show';
    const label = isInactiveDataPushTaskVisible ? 'Hide inactive tasks' : 'Show inactive tasks';
    const { usage, limit } = dataPushTaskLimitsAndUsage;
    const numberOfFreeDataPushTasks = limit - usage;
    return (
        <Header>
            <TopRow>
                <Column flexGrow>
                    <PageHeadline>Data push tasks</PageHeadline>
                </Column>
                {
                    limit > 0 && (
                        <>
                            <Column>
                                <DataPushTaskLimit />
                            </Column>
                            <Column>
                                <Separator />
                            </Column>
                            <Column>
                                <ButtonGroup>
                                    {
                                        (hasAtLeastOneInActiveDataPushTask) && (
                                            <Button
                                              label={label}
                                              onClick={() => { toggleInactiveDataPushTasksAction(); }}
                                              icon={icon}
                                            />
                                        )
                                    }
                                    <ButtonWithTooltip
                                      label="Add new"
                                      disabled={numberOfFreeDataPushTasks <= 0}
                                      tooltip={numberOfFreeDataPushTasks > 0 ? 'Add new data push task' : 'Out of data push task limit'}
                                      onClick={() => { modalShowDataPushTaskAddAction(); }}
                                      action
                                    />
                                </ButtonGroup>
                            </Column>
                        </>
                    )
                }
            </TopRow>
        </Header>
    );
};

DataPushTasksHeader.propTypes = {
    isInactiveDataPushTaskVisible: PropTypes.bool.isRequired,
    hasAtLeastOneInActiveDataPushTask: PropTypes.bool.isRequired,
    toggleInactiveDataPushTasksAction: PropTypes.func.isRequired,
    modalShowDataPushTaskAddAction: PropTypes.func.isRequired,
    dataPushTaskLimitsAndUsage: customPropTypes.dataPushTaskLimitAndUsage.isRequired
};

const makeMapStateToProps = () => {
    const selectHasAtLeastOneInActiveDataPushTask = makeSelectHasAtLeastOneInActiveDataPushTask();
    return (state) => ({
        isInactiveDataPushTaskVisible: getShowInactiveDataPushTasks(state),
        hasAtLeastOneInActiveDataPushTask: selectHasAtLeastOneInActiveDataPushTask(state),
        dataPushTaskLimitsAndUsage: selectDataPushTaskLimitAndUsage(state)
    });
};

export default connect(makeMapStateToProps, {
    toggleInactiveDataPushTasksAction: dataPushTaskActions.toggleInactiveDataPushTasks,
    modalShowDataPushTaskAddAction: modalShowDataPushTaskAdd
})(DataPushTasksHeader);
