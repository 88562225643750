import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import React from 'react';
import Tag from 'src/components/Tag';
import TagGroup from 'src/components/TagGroup';

/**
 * This is the preferred class to render a list of tags based on an array of tag definitions. On top click handlers
 * are injected into each tag component.
 */
const TagList = (props) => {
    const {
        tags, onTagClick, onTagMetaKeyClick, onCloseClick
    } = props;

    return (
        <TagGroup>
            {
                tags.map((tag) => {
                    const key = tag.customOption === true ? `custom-${tag.name}` : `tag-${tag.id}`;
                    return (
                        <Tag
                          key={key}
                          tag={tag}
                          onClick={onTagClick}
                          onMetaKeyClick={onTagMetaKeyClick}
                          onCloseClick={onCloseClick}
                        />
                    );
                })

            }
        </TagGroup>
    );
};

TagList.propTypes = {
    tags: customPropTypes.tagsOrCustomTags.isRequired,
    onTagClick: PropTypes.func,
    onTagMetaKeyClick: PropTypes.func,
    onCloseClick: PropTypes.func
};

export default TagList;
