import { enrollMultiFactorAuthenticationRequest } from 'src/actions/loggedInUser';
import Button from 'src/components/buttons/Button';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { selectMultiFactorAuthenticationEnrollmentLoading } from 'src/selectors/userSecurity';

const EnrollMultiFactorAuthenticationButton = ({ onClick, isLoading }) => <Button onClick={onClick} loading={isLoading} label="Send an enrollment invitation" action />;

EnrollMultiFactorAuthenticationButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
    isLoading: selectMultiFactorAuthenticationEnrollmentLoading(state)
});

export default connect(mapStateToProps, { onClick: enrollMultiFactorAuthenticationRequest })(EnrollMultiFactorAuthenticationButton);
