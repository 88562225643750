import * as customPropTypes from 'src/customPropTypes';
import DetailText from 'src/components/views/DetailText';
import React from 'react';

const BillingAddress = (props) => {
    const { billingAddress } = props;
    return (
        <DetailText label="Billing address">
            <div>{billingAddress.name}</div>
            <div>{billingAddress.person}</div>
            <div>{billingAddress.address}</div>
            <div>{billingAddress.zip} {billingAddress.city}</div>
            <div>{billingAddress.country}</div>
        </DetailText>
    );
};

BillingAddress.propTypes = {
    billingAddress: customPropTypes.billingAddress.isRequired
};

export default BillingAddress;
