import React, { useEffect } from 'react';
import * as dataSourceActions from 'src/actions/dataSources';
import _get from 'lodash/get';
import ErrorView from 'src/components/errors/ErrorView';
import {
    selectDataSourceLoadingState, makeSelectDataSources
} from 'src/selectors/dataSources';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';

const DataSourcesLoader = (props) => {
    const {
        loadingState, children, dataSources, loadingScreen
    } = props;
    const isPending = _get(loadingState, 'isPending', false);
    const success = _get(loadingState, 'success', false);
    const error = _get(loadingState, 'error', null);

    useEffect(() => {
        const { getDataSourcesRequestAction } = props;
        if (!success) {
            getDataSourcesRequestAction();
        }
    }, []);

    if (isPending) {
        return loadingScreen;
    }
    if (error) {
        return <ErrorView headline="Data sources failed to load." error={error} />;
    }

    if (dataSources) {
        return (
            children(dataSources)
        );
    }

    return null;
};

DataSourcesLoader.propTypes = {
    dataSources: customPropTypes.dataSources.isRequired,
    getDataSourcesRequestAction: PropTypes.func.isRequired,
    loadingState: customPropTypes.asyncReducerState.isRequired,
    loadingScreen: PropTypes.node.isRequired,
    children: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const selectDataSources = makeSelectDataSources();
    return (state) => ({
        loadingState: selectDataSourceLoadingState(state),
        dataSources: selectDataSources(state)
    });
};

export default connect(makeMapStateToProps, {
    getDataSourcesRequestAction: dataSourceActions.getDataSourcesRequest
})(DataSourcesLoader);
