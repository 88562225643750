import * as customPropTypes from 'src/customPropTypes';
import BackLink from 'src/components/BackLink';
import { connect } from 'react-redux';
import React from 'react';
import { selectLinkToDiscover } from 'src/selectors/metricSearch';

const DiscoverBackButton = (props) => {
    const { linkToDiscover } = props;
    const backToSearch = linkToDiscover.pathname.includes('search');
    const linkToDiscoverText = backToSearch ? 'Back to results' : 'Browse discover';
    return (
        <BackLink to={linkToDiscover} icon="back" tooltip={linkToDiscoverText} />
    );
};

DiscoverBackButton.propTypes = {
    linkToDiscover: customPropTypes.linkToDiscover.isRequired,
};

const mapStateToProps = (state) => ({
    linkToDiscover: selectLinkToDiscover(state),
});

export default connect(mapStateToProps)(DiscoverBackButton);
