import * as customPropTypes from 'src/customPropTypes';
import DetailText from 'src/components/views/DetailText';
import React from 'react';
import GenerateBillingLinkButton from 'src/components/buttons/GenerateBillingLinkButton';

const CreditCard = (props) => {
    const { creditCard } = props;
    const { expMonth, expYear, last4 } = creditCard;
    return (
        <DetailText label="Credit card">
            {
                last4 === ''
                && <div>Credit card not set.</div>
            }
            {
                last4 !== ''
                && (
                <div>
                    <div>xxxx xxxx xxxx {last4}</div>
                    <div>{expMonth}/{expYear}</div><br />
                    <GenerateBillingLinkButton />
                </div>
                )
            }
        </DetailText>
    );
};

CreditCard.propTypes = {
    creditCard: customPropTypes.creditCard.isRequired
};

export default CreditCard;
