import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { changePostSortDir, paginatePostsRequest } from 'src/actions/posts';
import { getCurrentPageNumber, getResultTotalRowCount } from 'src/selectors/posts';
import * as customPropTypes from 'src/customPropTypes';
import PageScroller from 'src/components/paginator/PageScroller';
import styles from 'src/stylesheets/views/postTags/postTagging/postSortOrderWithPageScroller.scss';
import classnames from 'classnames';
import { getNumberOfPages } from 'src/utils/postSearch';
import withTooltip from 'src/components/withToolTip';
import IcomoonIcon from 'src/components/IcomoonIcon';
import { getPaginationServerRequest } from 'src/requestHandling/datasourceDataRequests';

const IcomoonIconWithTooltip = withTooltip(IcomoonIcon);

const sortDirOptions = {
    desc: { icon: 'descending', tooltip: 'Newest first', reverse: 'asc' },
    asc: { icon: 'ascending', tooltip: 'Oldest first', reverse: 'desc' }
};

const PostSortOrderWithPageScroller = (props) => {
    const {
        searchRequestInput, paginatePostsRequestAction, pageNumber, totalCount, disabled
    } = props;
    const { parameters } = searchRequestInput;
    const { sortDir, limit } = parameters;

    const handlePageChange = (page) => {
        const manipulatedServerRequest = getPaginationServerRequest(searchRequestInput, page, parameters.limit);
        paginatePostsRequestAction(page, manipulatedServerRequest);
    };

    const changeSortDir = (option) => {
        const { changePostSortDirAction } = props;
        changePostSortDirAction(option);
    };

    const { icon, tooltip, reverse } = sortDirOptions[sortDir];
    const pageCount = getNumberOfPages(totalCount, limit);

    return (
        <nav className={classnames(styles.nav, { [styles.disabled]: disabled })}>
            <IcomoonIconWithTooltip icon={icon} tooltip={tooltip} onClick={() => { changeSortDir(reverse); }} className={styles.sortDir} />
            <div className={styles.scroller}>
                <div className={styles.pageInfo}>
                    {`Page ${pageNumber}/${pageCount}`}
                </div>
                <ul className={styles.list}>
                    <PageScroller
                      onClick={() => handlePageChange(pageNumber - 1)}
                      icon="chevron-big-left"
                      tooltip="Previous page"
                      disabled={pageNumber <= 1}
                    />
                    <PageScroller
                      onClick={() => handlePageChange(pageNumber + 1)}
                      icon="chevron-big-right"
                      tooltip="Next page"
                      disabled={pageNumber >= pageCount}
                    />
                </ul>
            </div>
        </nav>
    );
};

PostSortOrderWithPageScroller.propTypes = {
    changePostSortDirAction: PropTypes.func.isRequired,
    searchRequestInput: customPropTypes.postSearchRequestInput.isRequired,
    paginatePostsRequestAction: PropTypes.func.isRequired,
    pageNumber: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
    pageNumber: getCurrentPageNumber(state),
    totalCount: getResultTotalRowCount(state)
});

export default connect(mapStateToProps, {
    changePostSortDirAction: changePostSortDir,
    paginatePostsRequestAction: paginatePostsRequest
})(PostSortOrderWithPageScroller);
