import Body from 'src/components/Body';
import DashboardAndMetricNotFoundHeader from 'src/components/header/DashboardAndMetricNotFoundHeader';
import pageNotFoundImage from 'src/resources/illustrations/key-metrics-web.png';
import React from 'react';
import ScrollView from 'src/components/ScrollView';
import styles from 'src/stylesheets/views/discover/metricDashboardNotFound.scss';

const MetricPageNotFound = () => (
    <ScrollView dataViewName="metric-page-not-found">
        <DashboardAndMetricNotFoundHeader title="Metric not found" detail="This metric does not exist or has been removed." />
        <Body>
            <div className={styles.wrapper}>
                <img src={pageNotFoundImage} alt="empty" />
            </div>
        </Body>
    </ScrollView>
);

export default MetricPageNotFound;
