import React from 'react';
import IcomoonIcon from 'src/components/IcomoonIcon';
import styles from 'src/stylesheets/views/integrations/dataPushTaskLogStatus.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import withTooltip from 'src/components/withToolTip';
import Date from 'src/components/Date';
import * as customPropTypes from 'src/customPropTypes';

const IcomoonIconWithTooltip = withTooltip(IcomoonIcon);

const DataPushTaskLogStatus = (props) => {
    const { status, startTime } = props;

    let icon;
    let label;
    switch (status) {
        case 'open':
            icon = 'schedule';
            label = 'Scheduled';
            break;

        case 'pending':
            icon = 'pending';
            label = 'Processing';
            break;

        case 'success':
            icon = 'success';
            label = 'Successful';
            break;

        case 'partialFailure':
            icon = 'alert';
            label = 'Partially failed';
            break;

        case 'failure':
        default:
            icon = 'close';
            label = 'Failed';
            break;
    }

    const classNames = classnames(styles.icon, styles[icon]);
    if (!startTime) {
        return (
            <div className={styles.wrapper}>
                <IcomoonIcon icon={icon} className={classNames} />
                <div>{label}</div>
            </div>
        );
    }
    return (
        <div className={styles.wrapper}>
            <IcomoonIconWithTooltip
              icon={icon}
              tooltip={label}
              className={classnames(styles.icon, styles[icon])}
            />
            <Date date={startTime} format="datetime" />
        </div>
    );
};

DataPushTaskLogStatus.propTypes = {
    status: customPropTypes.dataPushTaskLogStatus,
    startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

DataPushTaskLogStatus.defaultProps = {
    startTime: false
};

export default DataPushTaskLogStatus;
