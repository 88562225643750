import {
    makeSelectDateByContext,
    makeSelectDashboardMetricLink,
    makeSelectProfileSelectorSelectionByContext
} from 'src/selectors/filterSelectors';
import { connect } from 'react-redux';
import GridDataTile from 'src/components/metricTiles/layout/GridDataTile';
import { makeSelectDashboardMetricById } from 'src/selectors/dashboardMetrics';
import PropTypes from 'prop-types';
import { selectAccountOptions } from 'src/selectors/loggedInUser';
import withAdditionalSettingsInterpretation from 'src/components/chartViews/withAdditionalSettingsInterpretation';
import withServerData from 'src/components/withServerData';
import withFilterContextConsumer from 'src/components/withFilterContextConsumer';

const GridDataTileWithServerData = withServerData(GridDataTile);
const GridDataTileWithServerDataAdditionalSettingsAndCustomColor = withAdditionalSettingsInterpretation(GridDataTileWithServerData);

const makeMapStateToProps = () => {
    const selectDashboardMetricById = makeSelectDashboardMetricById();
    const selectDashboardMetricLink = makeSelectDashboardMetricLink();
    const selectDateByContext = makeSelectDateByContext();
    const selectProfileSelectorSelectionByContext = makeSelectProfileSelectorSelectionByContext();

    return (state, ownProps) => {
        const { filterContext, dashboardMetricId } = ownProps;
        const dashboardMetric = selectDashboardMetricById(state, dashboardMetricId);
        return {
            selectedProfilesOrGroups: selectProfileSelectorSelectionByContext(state, filterContext),
            selectedDate: selectDateByContext(state, filterContext),
            dashboardMetric,
            accountOptions: selectAccountOptions(state),
            settings: dashboardMetric.settings,
            metric: dashboardMetric.metric,
            dashboardMetricLink: selectDashboardMetricLink(state, filterContext, dashboardMetric.dashboardId, dashboardMetric.id)
        };
    };
};

GridDataTileWithServerDataAdditionalSettingsAndCustomColor.propTypes = {
    dashboardMetricId: PropTypes.string.isRequired
};
export default withFilterContextConsumer(connect(makeMapStateToProps)(GridDataTileWithServerDataAdditionalSettingsAndCustomColor));
