import React from 'react';
import { connect } from 'react-redux';
import { makeSelectPostTagById } from 'src/selectors/postTags';
import * as customPropTypes from 'src/customPropTypes';
import styles from 'src/stylesheets/views/postTags/postTagManager/postTagListItem.scss';
import IcomoonIcon from 'src/components/IcomoonIcon';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import PropTypes from 'prop-types';

const PostTagListItem = (props) => {
    const { postTag } = props;
    const { name } = postTag;
    return (
        <div className={styles.wrapper}>
            <IcomoonIcon icon="tag" className={styles.icon} />
            <div className={styles.name}>
                <DivWithTooltip tooltip={name}>
                    {name}
                </DivWithTooltip>
            </div>
        </div>
    );
};

PostTagListItem.propTypes = {
    id: PropTypes.string.isRequired,
    postTag: customPropTypes.postTag.isRequired
};

const makeMapStateToProps = () => {
    const selectPostTagById = makeSelectPostTagById();
    return (state, ownProps) => ({
        postTag: selectPostTagById(state, ownProps.id)
    });
};

export default connect(makeMapStateToProps)(PostTagListItem);
