import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';
import Error from 'src/components/errors/Error';
import EmptyInfoLoadingScreen from 'src/components/EmptyInfoLoadingScreen';
import ListTableSkeleton from 'src/components/loadingIndicators/ListTableSkeleton';
import _isArray from 'lodash/isArray';
import _isObject from 'lodash/isObject';

const hasData = (data) => {
    if (_isArray(data)) {
        return data.length > 0;
    }
    if (_isObject(data)) {
        return Object.keys(data).length > 0;
    }
    return false;
};

const AsyncDataLoader = (props) => {
    const {
        loadingState, requestAction, data, children, alwaysReload, isListTable, identifier
    } = props;

    const {
        isPending, error, requested
    } = loadingState;

    useEffect(() => {
        if (alwaysReload || (!requested)) {
            requestAction(identifier);
        }
    }, []);

    if (!hasData(data) && isPending && !error) {
        return isListTable ? <ListTableSkeleton itemCount={5} /> : <EmptyInfoLoadingScreen />;
    }

    if (error) {
        return <Error error={error} />;
    }

    if (data) {
        return children(data);
    }

    return null;
};

AsyncDataLoader.propTypes = {
    requestAction: PropTypes.func.isRequired,
    dataSelector: PropTypes.func.isRequired,
    asyncStateSelector: PropTypes.func.isRequired,
    loadingState: customPropTypes.asyncDataLoadingState.isRequired,
    children: PropTypes.func.isRequired,
    alwaysReload: PropTypes.bool,
    isListTable: PropTypes.bool,
    data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    identifier: PropTypes.string
};

AsyncDataLoader.defaultProps = {
    alwaysReload: false,
    isListTable: false,
    identifier: ''
};

const mapStateToProps = (state, ownProps) => {
    const { dataSelector, asyncStateSelector, identifier } = ownProps;
    return {
        data: dataSelector(state, identifier),
        loadingState: asyncStateSelector(state, identifier)
    };
};

export default connect(mapStateToProps)(AsyncDataLoader);
