import React from 'react';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import { selectDefaultTimezoneFromAccountSettings } from 'src/selectors/loggedInUser';
import { getInitialFormValues, postTagRuleAddFormName } from 'src/utils/postTagRule';
import memoizeOne from 'memoize-one';
import { postTagRuleAddRequestFormAction, postTagRuleUpdateRequestFormAction } from 'src/actions/postTagRule';
import PostTagRuleAddForm from 'src/components/forms/PostTagRuleAddForm';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Button from 'src/components/buttons/Button';
import { isSubmitting, submit, isPristine } from 'redux-form';
import styles from 'src/stylesheets/views/postTags/postTagManager/postTagRule.scss';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PostTagRuleDeleteIcon from 'src/components/views/postTags/postTagManager/postTagRules/PostTagRuleDelete';

const memoizedGetInitialFormValues = memoizeOne(getInitialFormValues);

const AddOrEditPostTagRule = (props) => {
    const handleBackClick = () => {
        const { onBackClick } = props;
        onBackClick();
    };

    const {
        postTagRule, postTag, submitting, isFormPristine, submitAction, defaultTimezone, duplicateRule
    } = props;

    const initialValues = memoizedGetInitialFormValues(postTagRule, postTag, duplicateRule, defaultTimezone);
    const editExistingRule = postTagRule !== false && duplicateRule === false;
    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                <div className={styles.back} onClick={handleBackClick}>
                    <IcomoonIcon icon="back" className={styles.icon} /><div className={styles.backTitle}>Back</div>
                </div>
                <div className={styles.controls}>
                    <ButtonGroup>
                        <Button
                          action
                          loading={submitting}
                          disabled={editExistingRule ? isFormPristine : false}
                          onClick={() => { submitAction(postTagRuleAddFormName); }}
                          label={editExistingRule ? 'Apply changes' : 'Add rule'}
                        />
                        {
                            editExistingRule && <PostTagRuleDeleteIcon postTagRuleId={postTagRule.id} />
                        }
                    </ButtonGroup>
                </div>
            </div>
            <div>
                <PostTagRuleAddForm
                  form={postTagRuleAddFormName}
                  onSubmit={editExistingRule ? postTagRuleUpdateRequestFormAction : postTagRuleAddRequestFormAction}
                  initialValues={initialValues}
                />
            </div>
        </div>
    );
};

AddOrEditPostTagRule.propTypes = {
    postTag: customPropTypes.postTag.isRequired,
    postTagRule: PropTypes.oneOfType([customPropTypes.postTagRule, PropTypes.bool]).isRequired,
    onBackClick: PropTypes.func.isRequired,
    submitAction: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    isFormPristine: PropTypes.bool.isRequired,
    defaultTimezone: PropTypes.string.isRequired,
    duplicateRule: PropTypes.bool.isRequired
};

const mapStatesToProps = () => (state) => ({
    submitting: isSubmitting(postTagRuleAddFormName)(state),
    isFormPristine: isPristine(postTagRuleAddFormName)(state),
    defaultTimezone: selectDefaultTimezoneFromAccountSettings(state)
});

export default connect(mapStatesToProps, {
    submitAction: submit,
})(AddOrEditPostTagRule);
