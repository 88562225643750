import { put, takeEvery } from 'redux-saga/effects';
import {
    GET_DATA_PUSH_TASK_LOG_REQUEST,
    GET_DATA_PUSH_TASK_LOGS_REQUEST,
    GET_DATA_PUSH_TASK_LOG_DATA_REQUEST,
    getDataPushTaskLogDataError,
    getDataPushTaskLogDataSuccess,
    getDataPushTaskLogError,
    getDataPushTaskLogSuccess,
    getDataPushTaskLogsError,
    getDataPushTaskLogsSuccess,
} from 'src/actions/dataPushTaskLogs';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import { parseDataPushTaskLogs, parseDataPushTaskLog } from 'src/parsers';
import _parseInt from 'lodash/parseInt';
import {
    DATA_PUSH_TASK_DRY_RUN_SUCCESS,
    DATA_PUSH_TASK_DRY_RUN_ERROR,
    DATA_PUSH_TASK_SCHEDULE_NOW_SUCCESS,
    DATA_PUSH_TASK_SCHEDULE_NOW_ERROR
} from 'src/actions/dataPushTasks';

function* getDataPushTaskLogsRequest(action) {
    const { payload } = action;
    const { dataPushTaskId, startFrom } = payload;
    const params = {
        dataPushTaskId: _parseInt(dataPushTaskId),
        startFrom
    };
    try {
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/get-data-push-task-logs');
        if (response) {
            const { dataPushTaskLogs } = response.jsonData;
            const { parsedDataPushTaskLogs, sortedIds } = parseDataPushTaskLogs(dataPushTaskLogs);
            let lastDataPushTaskLogLastTime = null;
            if (sortedIds.length > 0) {
                const lastDataPushTaskLog = parsedDataPushTaskLogs[sortedIds[sortedIds.length - 1]];
                lastDataPushTaskLogLastTime = lastDataPushTaskLog.startTime;
            }
            yield put(getDataPushTaskLogsSuccess(dataPushTaskId, parsedDataPushTaskLogs, lastDataPushTaskLogLastTime));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(getDataPushTaskLogsError(dataPushTaskId, applicationError));
    }
}

function* getDataPushTaskLogRequest(action) {
    const { payload } = action;
    const { id, dataPushTaskId } = payload;
    const params = { id: _parseInt(id), dataPushTaskId };

    try {
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/get-data-push-task-log');
        if (response) {
            const { dataPushTaskLog } = response.jsonData;
            yield put(getDataPushTaskLogSuccess(id, parseDataPushTaskLog(dataPushTaskLog)));
        }

        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(getDataPushTaskLogError(id, applicationError));
    }
}

function* getDataPushTaskLogDataRequest(action) {
    const { payload } = action;
    const { id, dataPushTaskId } = payload;

    try {
        const serverRequest = createServerRequest({ id: _parseInt(id), dataPushTaskId });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/get-data-push-task-log-data');
        if (response) {
            const { data } = response.jsonData;
            const rawData = data;
            yield put(getDataPushTaskLogDataSuccess(id, rawData));
        }

        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(getDataPushTaskLogDataError(id, applicationError));
    }
}

export default function* dataPushTaskLogsSagas() {
    yield takeEvery(
        [
            GET_DATA_PUSH_TASK_LOGS_REQUEST,
            DATA_PUSH_TASK_DRY_RUN_SUCCESS,
            DATA_PUSH_TASK_DRY_RUN_ERROR,
            DATA_PUSH_TASK_SCHEDULE_NOW_SUCCESS,
            DATA_PUSH_TASK_SCHEDULE_NOW_ERROR
        ],
        getDataPushTaskLogsRequest
    );
    yield takeEvery(GET_DATA_PUSH_TASK_LOG_REQUEST, getDataPushTaskLogRequest);
    yield takeEvery(GET_DATA_PUSH_TASK_LOG_DATA_REQUEST, getDataPushTaskLogDataRequest);
}
