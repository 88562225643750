import AuthenticatorContext from 'src/components/context/AuthenticatorContext';
import { selectLoggedInSpace, selectLoggedInUserSpaces } from 'src/selectors/spaces';
import * as customPropTypes from 'src/customPropTypes';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import MenuButton from 'src/components/buttons/MenuButton';
import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import React from 'react';
import { selectIsAdmin } from 'src/selectors/loggedInUser';
import { makeSelectLastLocationByMenuName } from 'src/selectors/lastMainMenuLocations';
import { USER_ADMIN, USER_PERSONAL } from 'src/utils/routes';
import { withAuth0 } from '@auth0/auth0-react';

const wrapperStyle = {
    width: 200
};

const UserSettingsPopover = (props) => {
    const triggerHidePopover = () => {
        const { hidePopover } = props;
        hidePopover();
    };

    const {
        isAdmin, id, spaces, space, onSpaceSelectorClick, lastAdminLink, lastPersonalLink
    } = props;

    return (
        <Popover id={id}>
            <div style={wrapperStyle}>
                <ButtonGroup vertical>
                    <MenuButton to={lastPersonalLink} onClick={triggerHidePopover} label="Personal settings" />
                    {
                        isAdmin
                        && <MenuButton to={lastAdminLink} onClick={triggerHidePopover} label="Admin settings" />
                    }
                    {
                        spaces.length > 1
                        && <MenuButton label={`Space: ${space.name}`} multi onClick={onSpaceSelectorClick} />
                    }
                    <MenuButton to="https://changes.quintly.com/" label="Product changes" />
                    <MenuButton to="https://support.quintly.com/" label="Help" />

                    <AuthenticatorContext.Consumer>
                        {(authenticator) => (
                            <MenuButton
                              onClick={authenticator.logout}
                              label="Logout"
                            />
                        )}
                    </AuthenticatorContext.Consumer>
                </ButtonGroup>
            </div>
        </Popover>
    );
};

UserSettingsPopover.propTypes = {
    id: PropTypes.string.isRequired,
    hidePopover: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    spaces: customPropTypes.spaces.isRequired,
    space: customPropTypes.space.isRequired,
    onSpaceSelectorClick: PropTypes.func.isRequired,
    lastAdminLink: customPropTypes.linkTo.isRequired,
    lastPersonalLink: customPropTypes.linkTo.isRequired,
    auth0: customPropTypes.auth0.isRequired
};

const makeMapStateToProps = (state) => {
    const selectLastLocationByMenuName = makeSelectLastLocationByMenuName();
    return {
        isAdmin: selectIsAdmin(state),
        spaces: selectLoggedInUserSpaces(state),
        space: selectLoggedInSpace(state),
        lastAdminLink: selectLastLocationByMenuName(state, USER_ADMIN),
        lastPersonalLink: selectLastLocationByMenuName(state, USER_PERSONAL),
    };
};

export default withAuth0(connect(makeMapStateToProps, {
})(UserSettingsPopover));
