import React from 'react';
import { getClientSetting } from 'src/selectors/client';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import styles from 'src/stylesheets/views/integrations/apiInfo.scss';
import ContentBlock from 'src/components/views/ContentBlock';
import Password from 'src/components/forms/inputs/Password';
import Text from 'src/components/forms/inputs/Text';
import CopyToClipboard from 'src/components/CopyToClipboard';

const renderDetail = (title, value, isPlainText = true) => (
    <div className={styles.infoWrapper}>
        <div className={styles.title}>{title}</div>
        <div className={styles.text}>
            {
                isPlainText && <Text defaultValue={value} readOnly />
            }
            {
                !isPlainText && <Password defaultValue={value} readOnly />
            }
        </div>
        <div className={styles.copyText}>
            <CopyToClipboard link={value} label="Copy to clipboard" icon="notification-no" />
        </div>
    </div>
);

const APIInfo = (props) => {
    const { clientSetting, apiAccess } = props;
    const { clientId } = clientSetting;
    const { secret } = apiAccess;
    return (
        <div className={styles.sectionWrapper}>
            <div className={styles.section}>
                <ContentBlock headline="Authentication">
                    <div className={styles.description}>For authentication we use Basic Auth via HTTPS. For the username you must use your quintly Client ID, and for the password your API token.</div>
                    <div className={styles.clientId}>
                        {
                            renderDetail('Client ID', clientId)
                        }
                    </div>
                    {
                        renderDetail('Token', secret, false)
                    }
                </ContentBlock>
            </div>
            <div className={styles.section} />
        </div>
    );
};

APIInfo.propTypes = {
    clientSetting: customPropTypes.clientSettingTool.isRequired,
    apiAccess: customPropTypes.apiAccess.isRequired
};

const mapStateToProps = (state) => ({
    clientSetting: getClientSetting(state)
});

export default connect(mapStateToProps)(APIInfo);
