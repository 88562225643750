import { getFirstAppStartedTime, selectIsAgent } from 'src/selectors/loggedInUser';
import React, { Component } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import * as userActions from 'src/actions/loggedInUser';
import AgentBar from 'src/components/AgentBar';
import { connect } from 'react-redux';
import Content from 'src/components/Content';
import { getPackageStatus } from 'src/selectors/adminSettings';
import LoadingBar from 'react-redux-loading-bar';
import ModalHandler from 'src/components/ModalHandler';
import NotificationHandler from 'src/components/NotificationHandler';
import PropTypes from 'prop-types';
import SidebarNav from 'src/components/navigations/SidebarNav';
import styles from 'src/stylesheets/app.scss';
import { appStartedEvent } from 'src/actions/appEvents';

class AppTool extends Component {
    componentDidMount() {
        const {
            appStartedEventAction,
            firstTimeAppStarted,
            isUserAgent,
            packageStatus
        } = this.props;
        const { status, blocked } = packageStatus;

        appStartedEventAction();
        if (firstTimeAppStarted && !isUserAgent && status !== 'expired' && blocked === false) {
            const { addFirstAppStartedTimeRequestAction } = this.props;
            addFirstAppStartedTimeRequestAction();
        }
    }

    render() {
        const { content, location, isUserAgent } = this.props;
        const { pathname } = location;
        const isSampleDashboard = pathname.includes('sample-dashboard');

        const globalLoadingBar = (
            <div className={styles.loadingBarContainer}>
                <LoadingBar key="globalHandler" className={styles.loadingBar} />
            </div>
        );

        return (
            <div className={styles.app}>
                {
                    !isSampleDashboard
                    && (
                        <>
                            <div className={styles.navContents}>
                                {globalLoadingBar}
                                {
                                    isUserAgent
                                    && <AgentBar />
                                }
                            </div>
                            <div className={styles.sidebar}>
                                <SidebarNav />
                            </div>
                        </>
                    )
                }
                <Content content={content} />
                <ModalHandler />
                <NotificationHandler />
            </div>
        );
    }
}

AppTool.propTypes = {
    content: PropTypes.node.isRequired,
    appStartedEventAction: PropTypes.func.isRequired,
    firstTimeAppStarted: PropTypes.bool.isRequired,
    addFirstAppStartedTimeRequestAction: PropTypes.func.isRequired,
    isUserAgent: PropTypes.bool.isRequired,
    packageStatus: customPropTypes.packageStatus.isRequired,
    location: customPropTypes.location.isRequired
};

const mapStateToProps = (state) => ({
    isUserAgent: selectIsAgent(state),
    firstTimeAppStarted: getFirstAppStartedTime(state) === null,
    packageStatus: getPackageStatus(state)
});

export default connect(mapStateToProps, {
    appStartedEventAction: appStartedEvent,
    addFirstAppStartedTimeRequestAction: userActions.addFirstAppStartedTimeRequest
})(AppTool);
