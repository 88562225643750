import {
    ANALYSIS_AUTOCOMPLETE_REQUEST,
    ANALYSIS_QUICK_ACCESS_LINKS_REQUEST,
    ANALYSIS_TOGGLE_FAV_REQUEST,
    analysisAutocompleteError,
    analysisAutocompleteSuccess,
    analysisQuickAccessLinksError,
    analysisQuickAccessLinksSuccess,
    analysisToggleFavError,
    analysisToggleFavSuccess
} from 'src/actions/analysis';
import { put, takeEvery } from 'redux-saga/effects';
import { parseResults, parseResultsForEntities } from 'src/parsers';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import { reportError } from 'src/utils/reportError';
import { showNotification } from 'src/actions/notifications';
import { tagIdPrivateStatistics } from 'src/selectors/tags';

function* analysisQuickLinksRequest(action) {
    const { identifier } = action.payload;

    // identifier is comma separated string
    const params = {
        tagIds: identifier,
        orderBy: 'mostUsed',
        filterBy: 'publicMetrics',
        limit: 10
    };
    // if tagIds does not include tagIdPrivateStatistics we need to explicitly exlcude it
    const tagIds = identifier.split(',');
    if (tagIds.indexOf(tagIdPrivateStatistics) === -1) {
        params.excludeTagIds = tagIdPrivateStatistics;
    }

    try {
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-discover/search');
        if (response) {
            const { results } = response.jsonData;
            yield put(analysisQuickAccessLinksSuccess(identifier, parseResults(results), parseResultsForEntities(results)));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(analysisQuickAccessLinksError(identifier, applicationOrServerError));
    }
}

function* analysisAutocompleteRequest(action) {
    const { identifier } = action.payload;
    const params = {
        freeTextTags: identifier,
        orderBy: 'mostUsed',
        filterBy: 'metrics',
        limit: 20
    };

    try {
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-discover/search');
        if (response) {
            const { results } = response.jsonData;
            yield put(analysisAutocompleteSuccess(identifier, parseResults(results), parseResultsForEntities(results)));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(analysisAutocompleteError(identifier, applicationOrServerError));
    }
}

function* analaysisToggleFavRequest(action) {
    const { metricId, active } = action.payload;
    const params = {
        metricId,
        active
    };

    try {
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-discover/toggle-favorite');
        if (response) {
            if (active) {
                yield put(showNotification('The metric has been added to your favorites.'));
            } else {
                yield put(showNotification('The metric has been removed from your favorites.'));
            }
            yield put(analysisToggleFavSuccess(metricId, active));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        if (active) {
            yield put(showNotification('The metric could not be added to your favorites.', 'error'));
        } else {
            yield put(showNotification('The metric could not be removed from your favorites.', 'error'));
        }
        yield put(analysisToggleFavError(metricId, active, applicationOrServerError));
    }
}

export default function* analysisSagas() {
    yield takeEvery(ANALYSIS_QUICK_ACCESS_LINKS_REQUEST, analysisQuickLinksRequest);
    yield takeEvery(ANALYSIS_AUTOCOMPLETE_REQUEST, analysisAutocompleteRequest);
    yield takeEvery(ANALYSIS_TOGGLE_FAV_REQUEST, analaysisToggleFavRequest);
}
