import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import notificationEmptyResult from 'src/resources/illustrations/notifications-80.png';
import React from 'react';

const NoUserNotificationView = () => (
    <EmptyInfoScreen
      image={notificationEmptyResult}
      title="You have no notifications"
    />
);

export default NoUserNotificationView;
