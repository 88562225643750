import React, { PureComponent } from 'react';
import { selectDashboardLayoutById, selectIsDashboardGridLoading } from 'src/selectors/dashboards';
import * as customPropTypes from 'src/customPropTypes';
import * as dashboardMetricsActionCreators from 'src/actions/dashboardMetrics';
import { connect } from 'react-redux';
import Grid from 'src/components/Grid';
import memoizeOne from 'memoize-one';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/dashboardGrid.scss';

const calculateGridWidth = (viewportWidth) => (viewportWidth - (2 * 64)) + (2 * 30);

const margins = [30, 30];
const breakpoints = { lg: 800, sm: 0 };

const columnDefinition = {
    lg: 12, sm: 1
};

const defaultHeight = 5;
const minHeight = 3;
const minWidth = 2;
const defaultWidth = 4;
const defaultWidthTable = columnDefinition.lg;

// copied from react-grid-layout/build/responsiveUtils';
function getBreakpointFromWidth(breakpointsParam, width) {
    const keys = Object.keys(breakpointsParam);
    const sorted = keys.sort((a, b) => breakpointsParam[a] - breakpointsParam[b]);
    let matching = sorted[0];
    for (let i = 1, len = sorted.length; i < len; i += 1) {
        const breakpointName = sorted[i];
        if (width > breakpointsParam[breakpointName]) {
            matching = breakpointName;
        }
    }
    return matching;
}

const getGridConfig = (dashboard, staticGrid) => ({
    items: dashboard.dashboardMetricIds.length,
    rowHeight: 80,
    draggableHandle: '.dragHandle',
    margin: margins,
    useCSSTransforms: false,
    className: styles.dashboardGrid,
    isDraggable: !staticGrid,
    isResizable: !staticGrid,
    usePercentages: false
});

const computeGridElements = (dashboardMetricLayouts, generateGridTile, currentBreakpoint, isAllowedToChangeLayout, isGridLoading) => (
    dashboardMetricLayouts.map((dashboardMetric) => generateGridTile(dashboardMetric, currentBreakpoint, isAllowedToChangeLayout, isGridLoading))
);

const sortGridTilesByRow = (gridTiles) => gridTiles.slice().sort((a, b) => {
    if (a.y > b.y) {
        return 1;
    }
    if (b.y > a.y) {
        return -1;
    }
    return 0;
});

const generateLayout = (dashboardMetricLayout) => {
    const {
        col, row, width, height, id
    } = dashboardMetricLayout;
    const gridDefinition = {
        x: col,
        y: row,
        w: width,
        h: height,
        i: id,
        minW: minWidth,
        minH: minHeight
    };
    return gridDefinition;
};

const generateSmallLayout = (dashboardMetricLayout, index) => {
    const { height, id } = dashboardMetricLayout;
    return {
        x: 0,
        y: index,
        w: 1,
        h: height,
        i: id
    };
};

const getStyles = (width) => ({ width });

const generateLayoutFromDashboardMetricLayouts = (dashboardMetricLayouts) => ({
    lg: sortGridTilesByRow(
        dashboardMetricLayouts.map((dashboardMetric) => generateLayout(dashboardMetric))
    ),
    sm: sortGridTilesByRow(
        dashboardMetricLayouts.map((dashboardMetric, index) => generateSmallLayout(dashboardMetric, index))
    )
});

const hasLayoutChanged = (dashboardMetricsObject, newLayout) => newLayout.some((layout) => {
    const id = layout.i;
    const dashboardMetricCurrentState = dashboardMetricsObject[id];
    return (
        dashboardMetricCurrentState.row !== layout.y
            || dashboardMetricCurrentState.col !== layout.x
            || dashboardMetricCurrentState.width !== layout.w
            || dashboardMetricCurrentState.height !== layout.h
    );
});

class DashboardGrid extends PureComponent {
    constructor(props) {
        super(props);

        this.handleLayoutChange = this.handleLayoutChange.bind(this);
        this.handleBreakpointChange = this.handleBreakpointChange.bind(this);
        this.memoizedComputeGridElements = memoizeOne(computeGridElements);
        this.memoizedGenerateLayoutFromDashboardMetricLayouts = memoizeOne(generateLayoutFromDashboardMetricLayouts);
        this.memoizedStyles = memoizeOne(getStyles);

        const { width } = props;

        const currentBreakpoint = getBreakpointFromWidth(breakpoints, calculateGridWidth(width));

        this.state = {
            currentBreakpoint
        };
    }

    handleBreakpointChange(newBreakpoint) {
        this.setState({
            currentBreakpoint: newBreakpoint,
        });
    }

    handleLayoutChange(newLayout, allLayouts) {
        const {
            dashboardMetricsUpdateLayoutRequestAction, dashboard, dashboardMetricLayouts, staticGrid
        } = this.props;

        const dashboardMetricsObject = {};
        dashboardMetricLayouts.forEach((dashboardMetric) => { dashboardMetricsObject[dashboardMetric.id] = dashboardMetric; });
        const shouldUpdateLayout = hasLayoutChanged(dashboardMetricsObject, allLayouts.lg);

        // only trigger layout when the dashboard is not static
        if (!staticGrid && shouldUpdateLayout) {
            dashboardMetricsUpdateLayoutRequestAction(dashboard.id, newLayout);
        }
    }

    render() {
        const {
            dashboard,
            width,
            staticGrid,
            dashboardMetricLayouts,
            generateGridTile,
            isAllowedToChangeLayout,
            isGridLoading
        } = this.props;

        const { currentBreakpoint } = this.state;

        const gridConfig = getGridConfig(dashboard, staticGrid);
        const layouts = this.memoizedGenerateLayoutFromDashboardMetricLayouts(dashboardMetricLayouts);

        const gridElements = this.memoizedComputeGridElements(
            dashboardMetricLayouts,
            generateGridTile,
            currentBreakpoint,
            isAllowedToChangeLayout,
            isGridLoading
        );

        const calculatedWidth = calculateGridWidth(width);
        const memoizedStyles = this.memoizedStyles(calculatedWidth);
        return (
            <Grid
              style={memoizedStyles}
              key={dashboard.id}
              // width - 2 * padding from body + 2 * negative margin on grid
              width={calculatedWidth}
              {...gridConfig}
              cols={columnDefinition}
              layouts={layouts}
              breakpoints={breakpoints}
              onLayoutChange={this.handleLayoutChange}
              onBreakpointChange={this.handleBreakpointChange}
            >
                {
                    gridElements
                }
            </Grid>
        );
    }
}

// generate position for the newly added dashboardMetric because
// the grid engine does not automatically position the new metric
// in the optimal position
export const calculateNewDashboardMetricPosition = (dashboardMetrics, dashboard, isTable) => {
    // checks if two layouts collide *modified from STRML*
    const collides = (dashboardMetric1, dashboardMetric2) => {
        // dashboardMetric1 is left of dashboardMetric2
        if (dashboardMetric1.col + dashboardMetric1.width <= dashboardMetric2.col) return false;

        // dashboardMetric1 is right of dashboardMetric2
        if (dashboardMetric1.col >= dashboardMetric2.col + dashboardMetric2.width) return false;

        // dashboardMetric1 is above dashboardMetric2
        if (dashboardMetric1.row + dashboardMetric1.height <= dashboardMetric2.row) return false;

        // dashboardMetric1 is below dashboardMetric2
        if (dashboardMetric1.row >= dashboardMetric2.row + dashboardMetric2.height) return false;

        // boxes overlap
        return true;
    };

    // returns true when the first collision occurs
    const getFirstCollision = (dashboardMetricsArg, position) => dashboardMetricsArg.some((dashboardMetric) => collides(dashboardMetric, position));

    // sort existing dashboardMetrics by their rows and columns *modified from STRML*
    const getSortedDashboardMetricsByRowCol = (dashboardMetricsArg) => [].concat(dashboardMetricsArg).sort((a, b) => {
        if (a.row > b.row || (a.row === b.row && a.col > b.col)) {
            return 1;
        }
        return -1;
    });

    // detects if the current position overflows on width
    const overflowsWidth = (position, col) => (position.col + position.width > col);

    const position = {
        col: 0,
        row: 0,
        width: isTable ? defaultWidthTable : defaultWidth,
        height: defaultHeight
    };

    if (dashboardMetrics.length > 0) {
        const sortedDashboardMetrics = getSortedDashboardMetricsByRowCol(dashboardMetrics);
        let collision = getFirstCollision(sortedDashboardMetrics, position);
        while (collision) {
            position.col += 1;
            if (overflowsWidth(position, columnDefinition.lg)) {
                position.row += 1;
                position.col = 0;
            }
            collision = getFirstCollision(sortedDashboardMetrics, position);
        }
    }
    return position;
};

DashboardGrid.propTypes = {
    dashboard: customPropTypes.dashboard.isRequired,
    dashboardMetricsUpdateLayoutRequestAction: PropTypes.func.isRequired,
    dashboardMetricLayouts: customPropTypes.dashboardMetricLayouts.isRequired,
    width: PropTypes.number.isRequired,
    isAllowedToChangeLayout: PropTypes.bool,
    staticGrid: PropTypes.bool,
    generateGridTile: PropTypes.func.isRequired,
    isGridLoading: PropTypes.bool
};

DashboardGrid.defaultProps = {
    isAllowedToChangeLayout: true,
    staticGrid: false,
    isGridLoading: false
};

const mapStateToProps = (state, ownProps) => ({
    dashboardMetricLayouts: selectDashboardLayoutById(state, ownProps.dashboard.id),
    isGridLoading: selectIsDashboardGridLoading(state, ownProps.dashboard.id)
});

export default connect(mapStateToProps, {
    dashboardMetricsUpdateLayoutRequestAction: dashboardMetricsActionCreators.dashboardMetricsUpdateLayoutRequest
})(DashboardGrid);
