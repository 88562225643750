import React, { Component } from 'react';
import _debounce from 'lodash/debounce';
import _omit from 'lodash/omit';
import { analysisAutocompleteRequest } from 'src/actions/analysis';
import { connect } from 'react-redux';
import { makeSelectAnalysisAutoCompleteMetricsForSearchFromDiscover } from 'src/selectors/analysis';
import PropTypes from 'prop-types';

const dataLoading = (requestAction) => (searchTerm, requested) => {
    if (searchTerm.length > 1 && requested === false) {
        requestAction(searchTerm);
    }
};

const withMetricAutocompleteLoading = (WrappedComponent) => {
    class WithMetricAutocompleteLoading extends Component {
        constructor(props) {
            super(props);
            const { analysisAutocompleteRequestAction } = this.props;
            this.debouncedDataLoading = _debounce(dataLoading(analysisAutocompleteRequestAction), 300);
        }

        componentDidUpdate() {
            const { searchTerm, requested } = this.props;
            this.debouncedDataLoading(searchTerm, requested);
        }

        render() {
            const cleanedProps = _omit(this.props, ['requested', 'analysisAutocompleteRequestAction']);
            return <WrappedComponent {...cleanedProps} />;
        }
    }

    WithMetricAutocompleteLoading.propTypes = {
        analysisAutocompleteRequestAction: PropTypes.func.isRequired,
        autocompleteSuggestions: PropTypes.array.isRequired,
        searchTerm: PropTypes.string.isRequired,
        requested: PropTypes.bool
    };

    WithMetricAutocompleteLoading.defaultProps = {
        requested: true
    };

    const makeMapStateToProps = () => {
        const selectAnalysisAutoCompleteMetricsForSearch = makeSelectAnalysisAutoCompleteMetricsForSearchFromDiscover();

        return (state, ownProps) => {
            const filteredMetricIds = selectAnalysisAutoCompleteMetricsForSearch(state, ownProps.searchTerm);
            return {
                autocompleteSuggestions: filteredMetricIds.data,
                loading: filteredMetricIds.loading,
                requested: filteredMetricIds.requested
            };
        };
    };
    return connect(makeMapStateToProps, {
        analysisAutocompleteRequestAction: analysisAutocompleteRequest
    })(WithMetricAutocompleteLoading);
};

export default withMetricAutocompleteLoading;
