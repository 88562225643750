import {
    POST_TAG_ADD_REQUEST,
    POST_TAGS_REMOVE_REQUEST,
    POST_TAG_EDIT_REQUEST,
    POST_TAG_FORCE_REMOVE_REQUEST,
    POST_TAGS_UNARCHIVE_REQUEST,
    POST_TAGS_ARCHIVE_REQUEST,
    POST_TAGS_FORCE_ARCHIVE_REQUEST,
    POST_TAG_AUTOMATION_ACTIVATE_REQUEST,
    POST_TAG_AUTOMATION_DEACTIVATE_REQUEST,
    DEACTIVATE_AUTOMATION_IF_LAST_POST_TAG_RULE_REMOVED,
    POST_SEARCH_BY_TAG_REQUEST,
    postSearchByTagRequest as postSearchByTagRequestAction,
    postSearchByTagError,
    postSearchByTagSuccess,
    postTagAutomationDeactivateRequest,
    postTagAutomationActivateError,
    postTagAutomationActivateSuccess,
    postTagAutomationDeactivateError,
    postTagAutomationDeactivateSuccess,
    postTagsArchiveSuccess,
    postTagsArchiveError,
    postTagsForceArchiveError,
    postTagsForceArchiveSuccess,
    postTagsUnarchiveError,
    postTagsUnarchiveSuccess,
    postTagEditError,
    postTagEditSuccess,
    postTagAddError,
    postTagAddSuccess,
    postTagsRemoveError,
    postTagForceRemoveError,
    postTagForceRemoveSuccess,
    postTagsRemoveSuccess,
    postTagRuleIdCleanUp
} from 'src/actions/postTags';
import {
    put, takeEvery, select, delay
} from 'redux-saga/effects';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import {
    convertArrayElementsToString,
    parsePostTag,
    parsePostTags,
    getForceOperationalEntityIds,
    getForceOperationalEntityInfos,
    parsePosts
} from 'src/parsers';
import {
    modalHidePostTagsDelete,
    modalHidePostTagAdd,
    modalShowPostTagsForceDelete,
    modalHidePostTagsForceDelete,
    modalShowPostTagsForceArchive,
    modalHidePostTagsForceArchive,
    modalShowPostTagEditWarning,
    modalShowPostTagAutomationWarning
} from 'src/actions/overlays';
import { showNotification } from 'src/actions/notifications';
import { SubmissionError } from 'redux-form';
import _parseInt from 'lodash/parseInt';
import { makeSelectActivePostTagsByIds, makeSelectActivePostTagById } from 'src/selectors/postTags';
import * as routeActions from 'react-router-redux';
import { tagManagerRoute } from 'src/routePaths';
import { selectPostTagEditFormNameIgnoreCheck } from 'src/selectors/forms';
import { makeSelectPostTagRuleIdsByPostTagIds } from 'src/selectors/postTagRules';

const selectActivePostTagsByIds = makeSelectActivePostTagsByIds();
const selectActivePostTagById = makeSelectActivePostTagById();
const selectPostTagRuleIdsByPostTagIds = makeSelectPostTagRuleIdsByPostTagIds();

function* getNumberOfPostsToBeTagged(postTagId) {
    const serverRequest = createServerRequest({ postTagId: _parseInt(postTagId) });
    const { response } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/get-number-of-posts-to-be-tagged-by-post-tag-rules');
    if (response) {
        const { allNumberOfPostsToBeTagged } = response.jsonData;
        return allNumberOfPostsToBeTagged;
    }
    return 0;
}

function* getForceOperationalTagInfos(forceOperationalTagsInfo) {
    const forceOperationalTagIds = getForceOperationalEntityIds(forceOperationalTagsInfo);
    const forceOperationalTags = yield select(selectActivePostTagsByIds, forceOperationalTagIds);
    const forceOperationalTagInfos = getForceOperationalEntityInfos(forceOperationalTags, forceOperationalTagsInfo);
    return forceOperationalTagInfos;
}

function* navigateToTagManager() {
    yield put(routeActions.push({ pathname: tagManagerRoute }));
}

function* postTagAddRequest(actions) {
    const { payload } = actions;
    const { name } = payload;
    try {
        const serverRequest = createServerRequest({ name });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/add-post-tag');
        if (response) {
            const { postTag } = response.jsonData;
            const parsedPostTag = parsePostTag(postTag);
            yield put(modalHidePostTagAdd());
            yield put(postTagAddSuccess(parsedPostTag));
            yield put(showNotification(`${parsedPostTag.name} tag successfully added.`));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification(applicationError.message, 'error'));
        yield put(postTagAddError(new SubmissionError({ _error: applicationError })));
    }
}

function* postTagEditRequest(actions) {
    const { payload } = actions;
    const {
        id, name, archived, automation, automationInitial
    } = payload;
    const ignoreCheck = yield select(selectPostTagEditFormNameIgnoreCheck);
    try {
        const numberOfPostsTobeTagged = (!ignoreCheck && (!automationInitial && automation)) ? yield getNumberOfPostsToBeTagged(id) : 0;
        if (numberOfPostsTobeTagged > 0) {
            const errorMessage = `The automation you want to activate applies to ${numberOfPostsTobeTagged} historical post and will be automatically tagged. Are you sure you want to save?`;
            yield put(modalShowPostTagEditWarning(errorMessage));
            yield put(postTagEditSuccess());
        } else {
            const serverRequest = createServerRequest({
                id: _parseInt(id), name, archived, automation
            });
            const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/edit-post-tag');
            if (response) {
                const { postTag, nonArchivableTagIds, forceArchivableTagsInfo } = response.jsonData;
                const parsedPostTag = parsePostTag(postTag);
                if (nonArchivableTagIds.length > 0 || forceArchivableTagsInfo.length > 0) {
                    const forceArchivableTagInfos = yield getForceOperationalTagInfos(forceArchivableTagsInfo);
                    yield put(modalShowPostTagsForceArchive(forceArchivableTagInfos, convertArrayElementsToString(nonArchivableTagIds)));
                } else {
                    yield navigateToTagManager();
                }
                yield put(showNotification(`Tag ${parsedPostTag.name} successfully updated.`));
                yield put(postTagEditSuccess(parsedPostTag));
            }
            if (serverError) {
                throw serverError;
            }
        }
    } catch (applicationError) {
        yield put(postTagEditError(new SubmissionError({ _error: applicationError })));
    }
}

function* postTagAutomationActivateRequest(actions) {
    const { payload } = actions;
    const { postTagId, ignoreCheck, showModal } = payload;
    try {
        if (showModal) {
            yield put(modalShowPostTagAutomationWarning(postTagId));
        }
        const numberOfPostsTobeTagged = !ignoreCheck ? yield getNumberOfPostsToBeTagged(postTagId) : 0;
        if (numberOfPostsTobeTagged > 0) {
            const error = Error(`The automation you want to activate applies to ${numberOfPostsTobeTagged} historical post and will be automatically tagged. Are you sure you want to activate the automation?`);
            yield put(postTagAutomationActivateError(error, postTagId));
        } else {
            const serverRequest = createServerRequest({ postTagId: _parseInt(postTagId) });
            const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/post-tag-automation-activate');
            if (response) {
                const { postTag } = response.jsonData;
                yield put(postTagAutomationActivateSuccess(parsePostTag(postTag), postTagId));
                yield put(postSearchByTagRequestAction(postTagId, true));
            }
            if (serverError) {
                throw serverError;
            }
        }
    } catch (applicationError) {
        yield put(postTagAutomationActivateError(applicationError, postTagId));
        yield put(showNotification(`Activation failed, because : ${applicationError.message}`, 'error'));
    }
}

function* postTagAutomationDeactivateRequestAction(actions) {
    const { payload } = actions;
    const { postTagId } = payload;
    try {
        const serverRequest = createServerRequest({ postTagId: _parseInt(postTagId) });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/post-tag-automation-de-activate');
        if (response) {
            const { postTag } = response.jsonData;
            yield put(postTagAutomationDeactivateSuccess(parsePostTag(postTag), postTagId));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(postTagAutomationDeactivateError(applicationError, postTagId));
        yield put(showNotification('Deactivation failed.', 'error'));
    }
}

function* deactivateAutomationIfLastPostTagRuleRemoved(actions) {
    const { payload } = actions;
    const { postTagId } = payload;
    const postTag = yield select(selectActivePostTagById, postTagId);
    const { postTagRuleIds } = postTag;
    if (postTagRuleIds.length === 0) {
        yield put(postTagAutomationDeactivateRequest(postTagId));
    }
}

function* postTagsRemoveRequest(actions) {
    const { payload } = actions;
    const { postTagIds } = payload;
    try {
        const serverRequest = createServerRequest({ postTagIds: JSON.stringify(postTagIds.map((postTagId) => _parseInt(postTagId))) });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/remove-post-tags');
        if (response) {
            const { deletedTagIds, nonDeletableTagIds, forceDeletableTagsInfo } = response.jsonData;
            yield put(modalHidePostTagsDelete());
            const parsedDeletedTagIds = deletedTagIds.map((id) => id.toString());

            // collect all postTagRule ids from deleted post Tags
            const postTagRuleIds = yield select(selectPostTagRuleIdsByPostTagIds, parsedDeletedTagIds);
            yield put(postTagRuleIdCleanUp(postTagRuleIds));

            yield put(postTagsRemoveSuccess(parsedDeletedTagIds));
            if (deletedTagIds.length > 0) {
                yield put(showNotification('Tag successfully removed.'));
                yield navigateToTagManager();
                yield navigateToTagManager();
            }
            if (forceDeletableTagsInfo.length > 0 || nonDeletableTagIds.length > 0) {
                const forceDeletableTagInfos = yield getForceOperationalTagInfos(forceDeletableTagsInfo);
                yield put(modalShowPostTagsForceDelete(forceDeletableTagInfos, convertArrayElementsToString(nonDeletableTagIds)));
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification(applicationError.message, 'error'));
        yield put(postTagsRemoveError(new SubmissionError({ _error: applicationError })));
    }
}

function* postTagForceRemoveRequest(actions) {
    const { payload } = actions;
    const { postTagIds } = payload;
    try {
        const serverRequest = createServerRequest({ postTagIds: JSON.stringify(postTagIds.map((postTagId) => _parseInt(postTagId))) });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/force-remove-post-tags');
        if (response) {
            const { deletedTagIds } = response.jsonData;
            const parsedDeletedTagIds = deletedTagIds.map((id) => id.toString());

            // collect all postTagRule ids from deleted post Tags
            const postTagRuleIds = yield select(selectPostTagRuleIdsByPostTagIds, parsedDeletedTagIds);
            yield put(postTagRuleIdCleanUp(postTagRuleIds));

            yield put(modalHidePostTagsForceDelete());
            yield put(postTagForceRemoveSuccess(parsedDeletedTagIds));
            if (deletedTagIds.length > 0) {
                yield put(showNotification('Tag successfully removed.'));
                yield navigateToTagManager();
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification(applicationError.message, 'error'));
        yield put(modalHidePostTagsForceDelete());
        yield put(postTagForceRemoveError(new SubmissionError({ _error: applicationError })));
    }
}

function* postTagsArchiveRequest(actions) {
    const { payload } = actions;
    const { postTagIds } = payload;
    try {
        const serverRequest = createServerRequest({ postTagIds: JSON.stringify(postTagIds.map((postTagId) => _parseInt(postTagId))) });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/archive-post-tags');
        if (response) {
            const { archivedPostsTags, nonArchivableTagIds, forceArchivableTagsInfo } = response.jsonData;
            if (archivedPostsTags.length === postTagIds.length) {
                yield put(showNotification('Successfully archived.'));
            } else if (archivedPostsTags.length > 0) {
                yield put(showNotification('Some of tags Successfully archived.', 'warning'));
            }
            yield put(postTagsArchiveSuccess(parsePostTags(archivedPostsTags)));
            if (nonArchivableTagIds.length > 0 || forceArchivableTagsInfo.length > 0) {
                const forceArchivableTagInfos = yield getForceOperationalTagInfos(forceArchivableTagsInfo);
                yield put(modalShowPostTagsForceArchive(forceArchivableTagInfos, convertArrayElementsToString(nonArchivableTagIds)));
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification(applicationError.message, 'error'));
        yield put(postTagsArchiveError(applicationError));
    }
}

function* postTagsForceArchiveRequest(actions) {
    const { payload } = actions;
    const { postTagIds } = payload;
    try {
        const serverRequest = createServerRequest({ postTagIds: JSON.stringify(postTagIds.map((postTagId) => _parseInt(postTagId))) });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/force-archive-post-tags');
        if (response) {
            const { archivedPostsTags } = response.jsonData;
            yield put(modalHidePostTagsForceArchive());
            if (archivedPostsTags.length === postTagIds.length) {
                yield put(showNotification('Successfully archived.'));
                yield put(postTagsForceArchiveSuccess(parsePostTags(archivedPostsTags)));
            } else {
                yield put(showNotification('Tag was not successfully archived', 'error'));
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification(applicationError.message, 'error'));
        yield put(modalHidePostTagsForceArchive());
        yield put(postTagsForceArchiveError(new SubmissionError({ _error: applicationError })));
    }
}

function* postTagsUnarchiveRequest(actions) {
    const { payload } = actions;
    const { postTagIds } = payload;
    try {
        const serverRequest = createServerRequest({ postTagIds: JSON.stringify(postTagIds), archiveFlag: false });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/unarchive-post-tags');
        if (response) {
            const { archivedPostsTags } = response.jsonData;
            yield put(postTagsUnarchiveSuccess(parsePostTags(archivedPostsTags)));
            yield put(showNotification('Successfully unarchived.'));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification(applicationError.message, 'error'));
        yield put(postTagsUnarchiveError(applicationError));
    }
}

function* postSearchByPostTagRequest(action) {
    const { payload } = action;
    const { postTagId, delayRequest } = payload;
    const requestParams = {
        postTagId: _parseInt(postTagId)
    };
    try {
        const serverRequest = createServerRequest(requestParams);
        if (delayRequest) {
            yield delay(1000);
        }
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/search-recent-posts-by-post-tag');
        if (response) {
            const { posts } = response.jsonData;
            const { posts: parsedPosts, postsPostTags } = parsePosts(posts);
            yield put(postSearchByTagSuccess(postTagId, parsedPosts, postsPostTags));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(postSearchByTagError(postTagId, applicationError));
    }
}

export default function* postTagsSagas() {
    yield takeEvery(POST_TAG_ADD_REQUEST, postTagAddRequest);
    yield takeEvery(POST_TAG_EDIT_REQUEST, postTagEditRequest);
    yield takeEvery(POST_TAG_AUTOMATION_ACTIVATE_REQUEST, postTagAutomationActivateRequest);
    yield takeEvery(POST_TAG_AUTOMATION_DEACTIVATE_REQUEST, postTagAutomationDeactivateRequestAction);
    yield takeEvery(DEACTIVATE_AUTOMATION_IF_LAST_POST_TAG_RULE_REMOVED, deactivateAutomationIfLastPostTagRuleRemoved);
    yield takeEvery(POST_TAGS_REMOVE_REQUEST, postTagsRemoveRequest);
    yield takeEvery(POST_TAG_FORCE_REMOVE_REQUEST, postTagForceRemoveRequest);
    yield takeEvery(POST_TAGS_ARCHIVE_REQUEST, postTagsArchiveRequest);
    yield takeEvery(POST_TAGS_FORCE_ARCHIVE_REQUEST, postTagsForceArchiveRequest);
    yield takeEvery(POST_TAGS_UNARCHIVE_REQUEST, postTagsUnarchiveRequest);
    yield takeEvery(POST_SEARCH_BY_TAG_REQUEST, postSearchByPostTagRequest);
}
