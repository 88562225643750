import React from 'react';
import styles from 'src/stylesheets/views/integrations/dataPushTaskStatus.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const DataPushTaskStatus = (props) => {
    const { isActive, className, isTableContext } = props;
    return (
        <div className={classnames(styles.wrapper, className)}>
            <div className={classnames(
                styles.indicator, {
                    [styles.tableContext]: isTableContext,
                    [styles.active]: isActive,
                    [styles.inActive]: !isActive
                }
            )}
            />
            <div>
                {
                    isActive ? 'Active' : 'Inactive'
                }
            </div>
        </div>
    );
};

DataPushTaskStatus.propTypes = {
    isActive: PropTypes.bool.isRequired,
    className: PropTypes.string,
    isTableContext: PropTypes.bool
};

DataPushTaskStatus.defaultProps = {
    className: '',
    isTableContext: false
};

export default DataPushTaskStatus;
