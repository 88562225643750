import React, { Component } from 'react';
import * as userNotificationAction from 'src/actions/userNotification';
import { connect } from 'react-redux';
import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import UserNotificationMenuContainer from 'src/components/userNotificationMenu/UserNotificationMenuContainer';

class UserNotificationPopover extends Component {
    componentDidMount() {
        const { updateUserNotificationsLastSeenTimeAction } = this.props;
        updateUserNotificationsLastSeenTimeAction();
    }

    render() {
        const { hidePopover } = this.props;

        return (
            <Popover id="userNotificationsPopover">
                <UserNotificationMenuContainer hidePopover={hidePopover} />
            </Popover>
        );
    }
}

UserNotificationPopover.propTypes = {
    updateUserNotificationsLastSeenTimeAction: PropTypes.func.isRequired,
    hidePopover: PropTypes.func.isRequired
};

export default connect(null, {
    updateUserNotificationsLastSeenTimeAction: userNotificationAction.updateUserNotificationsLastSeenTime,
})(UserNotificationPopover);
