import Body from 'src/components/Body';
import Footer from 'src/components/Footer';
import LocalizationForm from 'src/components/forms/LocalizationForm';
import { localizationFormAction } from 'src/actions/loggedInUser';
import React from 'react';
import ScrollView from 'src/components/ScrollView';
import PreferenceHeader from 'src/components/header/PreferenceHeader';

const formName = 'preferencesForm';

const Preferences = () => (
    <ScrollView dataViewName="account">
        <PreferenceHeader formName={formName} />
        <Body>
            <LocalizationForm onSubmit={localizationFormAction} form={formName} />
        </Body>
        <Footer />
    </ScrollView>
);

export default Preferences;
