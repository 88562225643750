import Button from 'src/components/buttons/Button';
import PropTypes from 'prop-types';
import React from 'react';
import withTooltip from 'src/components/withToolTip';
import withExportMenuPopover from './withExportMenuPopover';

const ButtonWithTooltip = withTooltip(Button);
const ButtonWithTooltipAndExportMenuPopover = withExportMenuPopover(ButtonWithTooltip);
const ButtonWithExportMenuPopover = withExportMenuPopover(Button);

const ExportButton = (props) => {
    const {
        tooltip,
        exportAction,
        small,
        multiAction,
        label,
        className,
        additionalButton,
        icon,
        disabled
    } = props;
    const sharedProps = {
        className,
        exportAction,
        small,
        multiAction,
        icon,
        additionalButton,
        label,
        disabled,
        placement: 'bottom-end'
    };
    if (tooltip) {
        return (
            <ButtonWithTooltipAndExportMenuPopover
              tooltip={tooltip}
              {...sharedProps}
            />
        );
    }
    return (
        <ButtonWithExportMenuPopover
          {...sharedProps}
        />
    );
};

ExportButton.propTypes = {
    exportAction: PropTypes.func.isRequired,
    small: PropTypes.bool,
    multiAction: PropTypes.bool,
    label: PropTypes.string,
    tooltip: PropTypes.string,
    className: PropTypes.string,
    additionalButton: PropTypes.node,
    icon: PropTypes.string,
    disabled: PropTypes.bool
};

ExportButton.defaultProps = {
    small: false,
    multiAction: false,
    label: '',
    tooltip: '',
    className: '',
    icon: '',
    disabled: false
};

export default ExportButton;
