import React from 'react';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';
import memoizeOne from 'memoize-one';
import { useHotkeys } from 'react-hotkeys-hook';

const getOptions = memoizeOne((enableOnInputs) => {
    const options = {};
    if (enableOnInputs) {
        options.enableOnTags = ['INPUT', 'TEXTAREA', 'SELECT'];
    }
    return options;
});

const HotKeys = ({
    children, keys, callback, enableOnInputs
}) => {
    useHotkeys(keys, callback, getOptions(enableOnInputs));
    return React.Children.only(children);
};

HotKeys.propTypes = {
    children: customPropTypes.children.isRequired,
    keys: PropTypes.string.isRequired,
    enableOnInputs: PropTypes.bool,
    callback: PropTypes.func.isRequired
};

HotKeys.defaultProps = {
    enableOnInputs: false
};

export default HotKeys;
