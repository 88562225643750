import * as customPropTypes from 'src/customPropTypes';
import AuthTransactionState from 'src/components/authTransactions/AuthTransactionState';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import React from 'react';
import styles from 'src/stylesheets/views/authentications/authTransactionListItem.scss';

const AuthTransactionListItem = (props) => {
    const { authTransaction } = props;
    const {
        name,
        isOpen,
        validUntil,
        isInProgress,
        isFulfilled
    } = authTransaction;
    return (
        <div className={styles.wrapper}>
            <div className={styles.name}>
                <div className={styles.ellipsisWrapper}>
                    <DivWithTooltip tooltip={name}>
                        {name}
                    </DivWithTooltip>
                </div>
            </div>
            <div className={styles.state}>
                <AuthTransactionState
                  className={styles.icon}
                  isOpen={isOpen}
                  isInProgress={isInProgress}
                  validUntil={validUntil}
                  isFulfilled={isFulfilled}
                />
            </div>
        </div>
    );
};

AuthTransactionListItem.propTypes = {
    authTransaction: customPropTypes.authTransaction.isRequired
};

export default AuthTransactionListItem;
