import objectHash from 'object-hash';
import { createFilterParams, createServerParamsForDataSourceRequest } from 'src/requestHandling/createServerParams';

const getHash = (params, filter) => `${objectHash(params)}-${objectHash(filter)}`;

export const createSingleServerRequest = (
    globalSelectedProfilesOrGroups,
    globalSelectedDate,
    selectedPostText,
    selectedPostTextExclude,
    selectedPostTag,
    selectedAdCampaign,
    paginationAndSorting = {}
) => {
    const filter = createFilterParams(
        globalSelectedProfilesOrGroups,
        globalSelectedDate,
        selectedPostText,
        selectedPostTextExclude,
        selectedPostTag,
        selectedAdCampaign
    );

    const parameters = createServerParamsForDataSourceRequest(paginationAndSorting);
    const request = {
        // start stop sortDir
        parameters,
        filter,
        hash: getHash(parameters, filter)
    };

    const endpointInfo = {
        to: '/client-widget/search-posts-for-post-tagging',
    };
    return {
        request,
        endpointInfo
    };
};

export const createFreshPostServerRequest = (
    globalSelectedProfilesOrGroups,
    globalSelectedDate,
    selectedPostText,
    selectedPostTextExclude,
    selectedPostTag,
    selectedAdCampaign,
    sortDir,
    limit
) => {
    const paginationParams = {
        sortDir,
        limit,
        start: 0
    };
    const singleServerRequest = createSingleServerRequest(
        globalSelectedProfilesOrGroups,
        globalSelectedDate,
        selectedPostText,
        selectedPostTextExclude,
        selectedPostTag,
        selectedAdCampaign,
        paginationParams
    );

    return singleServerRequest;
};

export const createNewServerRequestWithNewParameters = (searchRequestInput, parameters) => Object.assign({}, searchRequestInput, {
    parameters,
    hash: getHash(parameters, searchRequestInput.filter)
});

export const getPaginationServerRequest = (searchRequestInput, pageNumber, limit) => {
    const parameters = Object.assign({}, searchRequestInput.parameters);
    parameters.start = (pageNumber - 1) * limit;
    return Object.assign({}, searchRequestInput, { parameters });
};
