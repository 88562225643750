import _range from 'lodash/range';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/loadingIndicators/emptyListSkeleton.scss';

const EmptyListSkeleton = (props) => {
    const { itemCount } = props;
    const items = _range(itemCount);

    return (
        <ul className={styles.list}>
            {
                items.map((item) => <li key={item}><div key={item} className={styles.item} /></li>)
            }
        </ul>
    );
};

EmptyListSkeleton.propTypes = {
    itemCount: PropTypes.number
};

EmptyListSkeleton.defaultProps = {
    itemCount: 10
};

export default EmptyListSkeleton;
