import React from 'react';
import styles from 'src/stylesheets/sampleDataLabel.scss';

const SampleDataLabel = () => (
    <div
      className={styles.label}
    >
        Sample data
    </div>
);

export default SampleDataLabel;
