import { createSelector } from 'reselect';

export const getLoggedInUser = (state) => state.loggedInUser;
export const getLoggedInUserId = (state) => state.loggedInUser.userId;
export const getLoggedInSpaceId = (state) => state.loggedInUser.loggedInSpaceId;
export const getUserSpaceIds = (state) => state.loggedInUser.userSpaceIds;
export const selectIsAdmin = (state) => state.loggedInUser.isAdmin;
export const selectIsReadOnly = (state) => state.loggedInUser.isReadOnly;
export const selectIsAgent = (state) => state.loggedInUser.isAgent;
export const getFirstAppStartedTime = (state) => state.loggedInUser.firstAppStartedTime;
export const getAccountOptions = (state) => state.loggedInUser.accountOptions;

export const selectAccountOptions = createSelector(
    [
        getAccountOptions
    ],
    (accountOptions) => ({
        csvSeparator: accountOptions.csvSeparator,
        numberFormat: {
            thousandSeparator: accountOptions.thousandSeparator,
            decimalSeparator: accountOptions.decimalSeparator
        },
        exportEncoding: accountOptions.exportEncoding,
        timezone: accountOptions.timezone,
        dateFormat: accountOptions.dateFormat,
        weekDefinition: accountOptions.weekDefinition
    })
);

export const selectDefaultTimezoneFromAccountSettings = (state) => {
    const { timezone } = selectAccountOptions(state);
    return timezone;
};

export const selectLocalizationFormInitialValues = createSelector(
    [
        getAccountOptions
    ],
    (accountOptions) => ({
        csvSeparator: accountOptions.csvSeparator,
        numberFormat: `${accountOptions.thousandSeparator}${accountOptions.decimalSeparator}`,
        exportEncoding: accountOptions.exportEncoding,
        timezone: accountOptions.timezone,
        dateFormat: accountOptions.dateFormat,
        weekDefinition: accountOptions.weekDefinition
    })
);
