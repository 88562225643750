import ListInfoCreatedBy from 'src/components/ListInfoCreatedBy';
import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import moment from 'moment';
import NetworkIcon from 'src/components/NetworkIcon';
import React from 'react';
import RelativeTime from 'src/components/RelativeTime';
import styles from 'src/stylesheets/views/customMetrics/customMetricInfo.scss';

const CustomMetricInfo = (props) => {
    const { customMetric, loggedInUser } = props;
    const {
        name, createTime, platformType, usage, lastUpdateTime, createdByUserId, createdByUserName
    } = customMetric;
    return (
        <div className={styles.wrapper}>
            <div className={styles.nameAndIcons}>
                <div className={classnames('customMetricNameForHover', styles.name)}>
                    {name}
                </div>
                <NetworkIcon
                  platformType={platformType}
                  className={styles.icon}
                />
            </div>
            <ul className={styles.labels}>
                <li className={styles.label}>
                    <ListInfoCreatedBy createdTime={createTime} loggedInUserId={loggedInUser.id} cratedByUserId={createdByUserId || '0'} createdByUserName={createdByUserName || 'unknown'} />
                </li>
                <li className={styles.label}>
                    <span>&nbsp;·&nbsp;</span>
                    {
                        moment(lastUpdateTime).isValid()
                            ? <span className={styles.dateInfo}>Modified&nbsp;<RelativeTime date={lastUpdateTime} thresholdTimestamp={5 * 86400} /> </span>
                            : <span>Modification date unknown</span>
                    }
                    <span>&nbsp;·&nbsp;</span>
                </li>
                <li className={styles.label}>{usage || 0} x used</li>
            </ul>
        </div>
    );
};

CustomMetricInfo.propTypes = {
    customMetric: customPropTypes.metric.isRequired,
    loggedInUser: customPropTypes.user.isRequired
};

export default CustomMetricInfo;
