import { Select, TextWithResetIcon } from 'src/components/forms/fields';
import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import ListFilters from 'src/components/ListFilters';
import ListOptionsBar from 'src/components/ListOptionsBar';
import PropTypes from 'prop-types';
import React from 'react';
import { selectGroups } from 'src/selectors/groups';
import withReduxForm from 'src/components/forms/withReduxForm';

export const initialValues = {
    groupFilter: '0',
    networkFilter: 'all',
    freeTextFilter: '',
    sortBy: 'alphabetically'
};

const sortingOptions = [
    { value: 'alphabetically', label: 'Name (A-Z)' },
    { value: 'recentlyAdded', label: 'Recently added' }
];

const groupFilterStyle = { maxWidth: 220 };
const searchFilterStyle = { width: 230 };

const AccountFilterBarForm = (props) => {
    const {
        groups, change, freeSearchPlaceholder, networkFilterOptions
    } = props;
    return (
        <ListOptionsBar
          filters={(
              <ListFilters>
                  <div style={groupFilterStyle}>
                      <Field
                        name="groupFilter"
                        component={Select}
                        layout="listFilter"
                        activeOnDirty
                      >
                          {
                                groups.map(({ id, name }) => (
                                    <option key={`select-option-${id}`} value={id}>
                                        {name}
                                    </option>
                                ))
                            }
                      </Field>
                  </div>
                  {
                      networkFilterOptions.length > 0 && (
                          <Field
                            name="networkFilter"
                            component={Select}
                            layout="listFilter"
                            activeOnDirty
                          >
                              {
                                  networkFilterOptions.map((network) => (
                                      <option key={`select-option-${network.value}`} value={network.value}>{network.label}</option>
                                  ))
                              }
                          </Field>
                      )
                  }
                  <div style={searchFilterStyle}>
                      <Field
                        name="freeTextFilter"
                        component={TextWithResetIcon}
                        onResetClick={() => { change('freeTextFilter', ''); }}
                        placeholder={freeSearchPlaceholder}
                        layout="listFilter"
                        activeOnDirty
                        autoFocus
                      />
                  </div>
              </ListFilters>
          )}
          sorting={(
              <Field
                name="sortBy"
                component={Select}
                layout="listFilter"
              >
                  {
                        sortingOptions.map((option) => (
                            <option key={`select-option-${option.value}`} value={option.value}>{option.label}</option>
                        ))
                    }
              </Field>
          )}
        />
    );
};

const mapStateToProps = (state) => ({
    groups: selectGroups(state)
});

AccountFilterBarForm.defaultProps = {
    networkFilterOptions: []
};

AccountFilterBarForm.propTypes = {
    groups: customPropTypes.groups.isRequired,
    change: PropTypes.func.isRequired,
    freeSearchPlaceholder: PropTypes.string.isRequired,
    networkFilterOptions: customPropTypes.networkFilterOptions
};

export default connect(mapStateToProps)(withReduxForm(AccountFilterBarForm, {
    initialValues
}));
