import DataWarningButton from 'src/components/buttons/DataWarningButton';
import MetricWarningProvider from 'src/components/MetricWarningProvider';
import React from 'react';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import MetricRefreshButton from 'src/components/buttons/MetricRefreshButton';
import DashboardMetricExportActionProvider from 'src/components/exportButtons/DashboardMetricExportActionProvider';
import DragHandle from 'src/components/metricTiles/layout/DragHandle';
import * as customPropTypes from 'src/customPropTypes';
import DashboardMetricMenuWithMetricOpenInPopover from 'src/components/DashboardMetricMenuWithMetricOpenInPopover';
import PropTypes from 'prop-types';
import Button from 'src/components/buttons/Button';
import withPopover from 'src/components/withPopover';
import withTooltip from 'src/components/withToolTip';
import ExportButton from 'src/components/exportButtons/ExportButton';
import classnames from 'classnames';

const ButtonWithTooltip = withTooltip(Button);
const ButtonWithPopover = withPopover(ButtonWithTooltip);

const MetricMenuLarge = (props) => {
    const {
        serverRequest,
        isGridLoading,
        gridDragHandleClassName,
        dashboardMetric,
        dashboardMetricLink,
        dashboardGridBreakpoint,
        isAllowedToChangeLayout
    } = props;

    const dashboardMetricOptionsPopover = (
        <DashboardMetricMenuWithMetricOpenInPopover
          dashboardMetricLink={dashboardMetricLink}
          dashboardMetric={dashboardMetric}
        />
    );

    return (
        <ButtonGroup>
            <MetricWarningProvider requestHash={serverRequest.request.hash}>
                {(warnings) => (
                    <DataWarningButton
                      warnings={warnings}
                      small
                      className={classnames('grid-hover-button-opacity', 'medium')}
                    />
                )}
            </MetricWarningProvider>
            <MetricRefreshButton
              small
              className="grid-hover-button-opacity"
              metricRequestIdentifier={serverRequest.request.identifier}
              serverDataHash={serverRequest.request.hash}
            />
            <DashboardMetricExportActionProvider
              renderButton={(exportAction) => (
                  <ExportButton exportAction={exportAction} small icon="export" tooltip="Export" className="grid-hover-button-opacity" />
              )}
              dashboardMetric={dashboardMetric}
              serverRequest={serverRequest}
            />
            <ButtonWithPopover
              className="grid-hover-button-opacity"
              small
              icon="more"
              overlay={dashboardMetricOptionsPopover}
              placement="bottom-end"
              tooltip="More"
            />
            <DragHandle
              breakpoint={dashboardGridBreakpoint}
              isAllowedToChangeLayout={isAllowedToChangeLayout}
              className="grid-hover-button-opacity"
              gridDragHandleClassName={gridDragHandleClassName}
              isGridLoading={isGridLoading}
            />
        </ButtonGroup>
    );
};

MetricMenuLarge.propTypes = {
    serverRequest: customPropTypes.serverRequest.isRequired,
    isGridLoading: PropTypes.bool.isRequired,
    gridDragHandleClassName: PropTypes.string.isRequired,
    dashboardMetric: customPropTypes.dashboardMetric.isRequired,
    dashboardGridBreakpoint: PropTypes.string.isRequired,
    isAllowedToChangeLayout: PropTypes.bool.isRequired,
    dashboardMetricLink: customPropTypes.linkTo.isRequired
};

export default MetricMenuLarge;
