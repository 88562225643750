import {
    SOCIAL_NETWORK_AD_ACCOUNT_SHOW_LIST_REQUEST,
    SOCIAL_NETWORK_AD_ACCOUNT_SHOW_LIST_SUCCESS,
    SOCIAL_NETWORK_AD_ACCOUNT_SHOW_LIST_ERROR,
    socialNetworkAdAccountSearchReset,
    adAccountSearchByAuthAdAccountSuccess,
    adAccountSearchByAuthAdAccountRequest,
    adAccountSearchByAuthAdAccountError
} from 'src/actions/adAccountSearch';
import { combineReducers } from 'redux';
import { createAsyncStatesReducerForActionsWithErrorAndSuccessStates } from 'src/reducers/utils';
import _uniq from 'lodash/uniq';

const defaultState = {
    network: '',
    loading: false,
    error: null,
    data: null
};

export const authenticatedSearch = (state = defaultState, action) => {
    switch (action.type) {
        case SOCIAL_NETWORK_AD_ACCOUNT_SHOW_LIST_REQUEST: {
            const { payload } = action;
            return {
                network: payload.network,
                loading: true,
                error: null,
                data: null,
            };
        }
        case SOCIAL_NETWORK_AD_ACCOUNT_SHOW_LIST_ERROR: {
            const { payload } = action;
            return Object.assign({}, state, { loading: false, error: payload.error, data: null });
        }
        case SOCIAL_NETWORK_AD_ACCOUNT_SHOW_LIST_SUCCESS: {
            const { payload } = action;
            const { searchResults } = payload;
            return Object.assign({}, state, { loading: false, error: null, data: Object.keys(searchResults) });
        }
        case socialNetworkAdAccountSearchReset.type: {
            return defaultState;
        }
        default:
            return state;
    }
};

function searchedAdAccountsById(state = {}, action) {
    const { type, payload } = action;
    switch (type) {
        case SOCIAL_NETWORK_AD_ACCOUNT_SHOW_LIST_SUCCESS:
        case adAccountSearchByAuthAdAccountSuccess.type: {
            const { searchResults } = payload;
            return Object.assign({}, state, searchResults);
        }
        case socialNetworkAdAccountSearchReset.type: {
            return {};
        }
        default:
            return state;
    }
}

const isAuthenticatedSearchResultPage = (state = false, action) => {
    const { type } = action;
    switch (type) {
        case SOCIAL_NETWORK_AD_ACCOUNT_SHOW_LIST_REQUEST:
            return true;
        case socialNetworkAdAccountSearchReset.type:
            return false;
        default:
            return state;
    }
};

const searchedAdAccountIds = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case adAccountSearchByAuthAdAccountSuccess.type: {
            const { searchResults } = payload;
            return _uniq([...state, ...Object.keys(searchResults)]);
        }
        case socialNetworkAdAccountSearchReset.type: {
            return [];
        }
        default:
            return state;
    }
};

const authAdAccountSearch = combineReducers({
    search: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        () => 'state',
        [adAccountSearchByAuthAdAccountRequest.type],
        [adAccountSearchByAuthAdAccountSuccess.type],
        [adAccountSearchByAuthAdAccountError.type]
    ),
    searchedAdAccountIds
});

export const adAccountSearch = combineReducers({
    searchedAdAccountsById,
    authenticatedSearch,
    isAuthenticatedSearchResultPage,
    authAdAccountSearch
});
