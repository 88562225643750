import { makeSelectFolderLink } from 'src/selectors/folders';
import { makeSelectAllChildrenIdsByParentIdAndType, makeSelectFolderByFolderIdAndType, makeSelectDashboardCountByFolderIdsAndType } from 'src/selectors/ownership';
import { modalShowEditFolder, modalShowMoveToFolder } from 'src/actions/overlays';
import * as routeActions from 'react-router-redux';
import { connect } from 'react-redux';
import FolderListItem from 'src/components/views/folders/FolderListItem';
import { makeSelectIsOneItemSelected } from 'src/selectors/lists';
import PropTypes from 'prop-types';
import { selectLoggedInUser } from 'src/selectors/users';

const makeMapStateToProps = () => {
    const selectFolderLink = makeSelectFolderLink();
    const selectFolderByIdAndType = makeSelectFolderByFolderIdAndType();
    const selectIsOneItemSelected = makeSelectIsOneItemSelected();
    const selectDashboardCountByFolderIdsAndType = makeSelectDashboardCountByFolderIdsAndType();
    const selectAllChildrenIdsByParentIdAndType = makeSelectAllChildrenIdsByParentIdAndType();

    return (state, ownProps) => {
        const { folderId, listName, type } = ownProps;
        const folder = selectFolderByIdAndType(state, type, folderId);
        const parentFolder = selectFolderByIdAndType(state, type, folder.parentId);
        const allChildrenIds = folder ? selectAllChildrenIdsByParentIdAndType(state, folder.id, type) : [];
        return {
            folder,
            parentFolder,
            link: selectFolderLink(state, folder.id),
            loggedInUser: selectLoggedInUser(state),
            actionsDisabled: selectIsOneItemSelected(state, listName),
            dashboardCount: selectDashboardCountByFolderIdsAndType(state, [folderId, ...allChildrenIds], type),
            listName
        };
    };
};

const FolderListItemContainer = connect(makeMapStateToProps, {
    onEditClickAction: modalShowEditFolder,
    onMoveClickAction: modalShowMoveToFolder,
    routePushAction: routeActions.push,
})(FolderListItem);

FolderListItemContainer.propTypes = {
    folderId: PropTypes.string.isRequired,
    listName: PropTypes.string.isRequired
};

export default FolderListItemContainer;
