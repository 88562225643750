import { ensurePermission, writePermissions } from 'src/middleware/userPermissionCheck';
import * as customPropTypes from 'src/customPropTypes';
import * as overlayActions from 'src/actions/overlays';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import React from 'react';
import { selectIsDeactivating } from 'src/selectors/accountUseCases';
import styles from 'src/stylesheets/popovers/fixBrokenProfileUseCasePopover.scss';
import { makeSelectUseCasesById } from 'src/selectors/useCases';
import AccountInfoListItemByAccountType from 'src/components/AccountInfoListItemByAccountType';
import { renderAccountType } from 'src/utils/accountType';
import { removeProfileUseCaseRequest } from 'src/actions/profileUseCases';
import { adAccountUseCaseRemoveRequest } from 'src/actions/adAccountUseCases';

const FixBrokenAccountUseCasePopover = (props) => {
    const {
        hidePopover,
        account,
        accountType,
        isRemoving,
        useCase,
        invalidReason
    } = props;

    const handleOnNewRequest = () => {
        const { modalsShowCreateAuthTransactionRequirementsAction } = props;
        hidePopover();
        if (accountType === 'profiles') {
            modalsShowCreateAuthTransactionRequirementsAction([account.id], [], true);
        }

        if (accountType === 'adAccounts') {
            modalsShowCreateAuthTransactionRequirementsAction([], [account.id], true);
        }
    };

    const handleOnDeactivateRequest = () => {
        const { removeProfileUseCaseRequestAction, adAccountUseCaseRemoveRequestAction } = props;
        if (accountType === 'adAccounts') {
            adAccountUseCaseRemoveRequestAction(account.id, useCase.id);
        }
        if (accountType === 'profiles') {
            removeProfileUseCaseRequestAction(account.id, useCase.id);
        }
    };

    return (
        <Popover id="fixBrokenAccountUseCasePopover">
            <div className={styles.wrapper}>
                <div className={styles.left}>
                    <div className={styles.title}>The following error has occurred:</div>
                    <div className={styles.error}>
                        <DivWithTooltip tooltip={invalidReason}>
                            {invalidReason}
                        </DivWithTooltip>
                    </div><br />
                    <div className={styles.title}>How do I resolve this error?</div>
                    <div>
                        {`You can authenticate this ${renderAccountType(accountType)} using an account that has access to the required permissions. If you are unable to grant these permissions you can deactivate the use case to clear this error.`}
                    </div>
                </div>
                <div className={styles.right}>
                    <div className={styles.profileInfo}>
                        <AccountInfoListItemByAccountType account={account} accountType={accountType} />
                    </div>
                    <div className={styles.title}>Use case: </div>
                    <div>{useCase.name}</div>
                    <div className={styles.buttons}>
                        <div className={styles.button}>
                            <Button
                              label="New request"
                              icon="plus"
                              action
                              stretch
                              small
                              onClick={handleOnNewRequest}
                            />
                        </div>
                        <Button
                          label="Deactivate this use case"
                          stretch
                          small
                          loading={isRemoving}
                          onClick={handleOnDeactivateRequest}
                        />
                    </div>
                </div>
            </div>
        </Popover>
    );
};

FixBrokenAccountUseCasePopover.propTypes = {
    account: customPropTypes.account.isRequired,
    accountType: customPropTypes.accountTypes.isRequired,
    useCase: customPropTypes.useCase.isRequired,
    useCaseId: PropTypes.string.isRequired,
    invalidReason: PropTypes.string.isRequired,
    removeProfileUseCaseRequestAction: PropTypes.func.isRequired,
    adAccountUseCaseRemoveRequestAction: PropTypes.func.isRequired,
    modalsShowCreateAuthTransactionRequirementsAction: PropTypes.func.isRequired,
    hidePopover: PropTypes.func.isRequired,
    isRemoving: PropTypes.bool.isRequired
};

const mapStateToProps = () => {
    const selectUseCasesById = makeSelectUseCasesById();
    return (state, ownProps) => {
        const { account, useCaseId } = ownProps;
        return {
            useCase: selectUseCasesById(state, useCaseId),
            isRemoving: selectIsDeactivating(state, account.id, useCaseId)
        };
    };
};

export default connect(mapStateToProps, {
    removeProfileUseCaseRequestAction: ensurePermission(removeProfileUseCaseRequest, writePermissions.deactivateBrokenUseCase),
    adAccountUseCaseRemoveRequestAction: ensurePermission(adAccountUseCaseRemoveRequest, writePermissions.deactivateBrokenUseCase),
    modalsShowCreateAuthTransactionRequirementsAction: ensurePermission(overlayActions.modalsShowCreateAuthTransactionRequirements, writePermissions.createAuthTransaction)
})(FixBrokenAccountUseCasePopover);
