import Popover from 'src/components/popovers/layout/Popover';
import React from 'react';
import { dryRunInfo } from 'src/utils/dataPushTasks';

const wrapperStyle = {
    width: 400,
    padding: 20,
    fontSize: '13px',
    lineHeight: '1.38'
};

const DryRunDescriptionPopover = () => (
    <Popover id="dryRunDescriptionPopover">
        <div style={wrapperStyle}>
            <b>What is dry run?</b><br /><br />
            <div>
                {dryRunInfo}
            </div>
        </div>
    </Popover>
);

export default DryRunDescriptionPopover;
