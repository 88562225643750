import Column from 'src/components/header/layout/Column';
import EmptyDiv from 'src/components/loadingIndicators/EmptyDiv';
import Header from 'src/components/header/layout/Header';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/headers/discoverDashboardAndMetricHeader.scss';
import TopRow from 'src/components/header/layout/TopRow';

const DashboardAndMetricLoadingHeader = (props) => {
    const { isMetricPage } = props;
    return (
        <Header>
            <TopRow>
                <Column flexGrow>
                    <div className={styles.headline}>
                        <div className={styles.sections}>
                            <EmptyDiv height={24} width={24} />
                        </div>
                        <div className={styles.sections}>
                            <EmptyDiv height={24} width={24} />
                        </div>
                        <EmptyDiv height={35} width={300} />
                    </div>
                </Column>
                <Column>
                    <div className={styles.headline}>

                        {
                        isMetricPage
                        && (
                            <>
                                <div className={styles.sections}>
                                    <EmptyDiv height={32} width={151} />
                                </div>
                                <div className={styles.sections}>
                                    <EmptyDiv height={32} width={151} />
                                </div>
                            </>
                        )
                    }
                        <EmptyDiv height={32} width={151} />
                    </div>
                </Column>
            </TopRow>
        </Header>
    );
};

DashboardAndMetricLoadingHeader.propTypes = {
    isMetricPage: PropTypes.bool
};

DashboardAndMetricLoadingHeader.defaultProps = {
    isMetricPage: false
};

export default DashboardAndMetricLoadingHeader;
