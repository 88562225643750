import { selectedDateFromDefaultContext } from 'src/utils/filterSelectors';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _pick from 'lodash/pick';

import { PAGE_LENGTH } from 'src/components/chartViews/fixedDataTable/utils';

const getSortByAndSortDir = (metric, settings, paginationAndSorting) => {
    let sortBy = _get(metric, 'config.metaData.sortBy');
    let sortDir = _get(metric, 'config.metaData.sortDir');

    sortBy = _get(settings, 'settingAdditional.sortBy', sortBy);
    sortDir = _get(settings, 'settingAdditional.sortDir', sortDir);

    sortBy = _get(paginationAndSorting, 'sortBy', sortBy);
    sortDir = _get(paginationAndSorting, 'sortDir', sortDir);
    return { sortDir, sortBy };
};

const getAbsoluteLimit = (metric, settings) => _get(settings, 'settingAdditional.limit');

const getVisualizationId = (metric, settings) => {
    const metricVisualizationId = metric.visualizationId;
    // can be null as well
    const settingsVisualizationId = _get(settings, 'visualizationId');
    if (settingsVisualizationId) {
        return settingsVisualizationId;
    }
    return metricVisualizationId;
};

const getStartAndLimit = (paginate, paginationAndSorting) => {
    // if not paginated or export => 0 limit or max limit from additional settings
    if (!paginate) {
        return { start: 0, limit: 0 };
    }
    const onPageOrDefault = _get(paginationAndSorting, 'page', 0);
    const start = onPageOrDefault * PAGE_LENGTH;
    return { start, limit: PAGE_LENGTH };
};

export const getSelectedProfile = (globalSelectedProfilesOrGroups, settings) => {
    const selectedProfilesOrGroups = {};
    let { groupIds, profileIds, adAccountIds } = globalSelectedProfilesOrGroups;
    if (settings.isCustomProfileSelected === true && settings.settingProfileSelection) {
        groupIds = settings.settingProfileSelection.groupIds;
        profileIds = settings.settingProfileSelection.profileIds;
        adAccountIds = settings.settingProfileSelection.adAccountIds;
    }
    if (groupIds.length > 0) {
        selectedProfilesOrGroups.groupIds = groupIds;
    }
    if (profileIds.length > 0) {
        selectedProfilesOrGroups.profileIds = profileIds;
    }
    if (adAccountIds.length > 0) {
        selectedProfilesOrGroups.adAccountIds = adAccountIds;
    }

    return selectedProfilesOrGroups;
};

const getSelectedDate = (globalSelectedDate, settings) => {
    if (settings.isCustomDateSelected === true && settings.settingDateSelection) {
        const transformedSelection = selectedDateFromDefaultContext(settings.settingDateSelection, {
            isDefault: settings.inheritTimezone, defaultTimezone: globalSelectedDate.timezone
        });
        if (_get(transformedSelection, 'dynamicDate')) {
            return {
                dynamicDate: transformedSelection.dynamicDate,
                interval: transformedSelection.interval,
                timezone: transformedSelection.timezone
            };
        }

        return {
            startTime: transformedSelection.from,
            endTime: transformedSelection.to,
            interval: transformedSelection.interval,
            timezone: transformedSelection.timezone
        };
    }

    if (_get(globalSelectedDate, 'dynamicDate')) {
        return {
            dynamicDate: globalSelectedDate.dynamicDate,
            interval: globalSelectedDate.interval,
            timezone: globalSelectedDate.timezone
        };
    }

    return {
        startTime: globalSelectedDate.from,
        endTime: globalSelectedDate.to,
        interval: globalSelectedDate.interval,
        timezone: globalSelectedDate.timezone
    };
};

const filterValues = (values) => {
    const filteredValues = [];
    values.forEach((value) => {
        if (value) {
            filteredValues.push(value);
        }
    });

    return filteredValues;
};

const getParamsTextExcludeFilter = (textExcludeFilter) => {
    const { values } = textExcludeFilter;
    const filteredValues = filterValues(values);
    if (filteredValues.length > 0) {
        return Object.assign({}, textExcludeFilter, { values: filteredValues });
    }
    return null;
};

const getParamsTextFilter = (textFilter) => {
    const { values } = textFilter;
    const filteredValues = filterValues(values);
    if (filteredValues.length > 0) {
        return Object.assign({}, textFilter, { values: filteredValues });
    }
    return null;
};

const getParamsForTagFilter = (tagFilter) => {
    const { values, untagged } = tagFilter;
    const filteredValues = filterValues(values);
    if (filteredValues.length > 0 || untagged === true) {
        return Object.assign({}, tagFilter, { values: filteredValues });
    }
    return null;
};

const getParamsForAdCampaignFilter = (adCampainFilter) => {
    const { values } = adCampainFilter;
    const filteredValues = filterValues(values);
    if (filteredValues.length > 0) {
        return Object.assign({}, adCampainFilter, { values: filteredValues });
    }
    return null;
};

const getSelectedPostTextOrNull = (selectedPostText, settings) => {
    if (settings.isCustomAdditionalFilterSelected) {
        if (_has(settings, 'settingAdditionalFilterSelection.postText')) {
            return getParamsTextFilter(settings.settingAdditionalFilterSelection.postText);
        }
        return null;
    }
    return getParamsTextFilter(selectedPostText);
};

const getSelectedAdCampaignOrNull = (selectedAdCampaign, settings) => {
    if (settings.isCustomAdditionalFilterSelected) {
        if (_has(settings, 'settingAdditionalFilterSelection.adCampaign')) {
            return getParamsForAdCampaignFilter(settings.settingAdditionalFilterSelection.adCampaign);
        }
        return null;
    }
    return getParamsForAdCampaignFilter(selectedAdCampaign);
};

const getSelectedPostExcludeTextOrNull = (selectedPostTextExclude, settings) => {
    if (settings.isCustomAdditionalFilterSelected) {
        if (_has(settings, 'settingAdditionalFilterSelection.postTextExclude')) {
            return getParamsTextExcludeFilter(settings.settingAdditionalFilterSelection.postTextExclude);
        }
        return null;
    }
    return getParamsTextExcludeFilter(selectedPostTextExclude);
};

const getSelectedPostTagsOrNull = (selectedPostTag, settings) => {
    if (settings.isCustomAdditionalFilterSelected) {
        if (_has(settings, 'settingAdditionalFilterSelection.postTag')) {
            return getParamsForTagFilter(settings.settingAdditionalFilterSelection.postTag);
        }
        return null;
    }
    return getParamsForTagFilter(selectedPostTag);
};

export const createFilterParams = (
    globalSelectedProfilesOrGroups,
    globalSelectedDate,
    optionalSelectedPostText,
    optionalSelectedPostTextExclude,
    optionalSelectedPostTag,
    optionalSelectedAdCampaign,
    settings = {}
) => {
    const filter = {
        date: getSelectedDate(globalSelectedDate, settings),
        profile: getSelectedProfile(globalSelectedProfilesOrGroups, settings)
    };

    const isPostTextSupported = true;
    const isPostTextExcludeSupported = true;
    const isPostTagsSupported = true;
    const isAdCampaignSupported = true;
    // if supports filter?
    // put filter to request, otherwise leave it out.

    if (isAdCampaignSupported) {
        const adCampaign = getSelectedAdCampaignOrNull(optionalSelectedAdCampaign, settings);
        if (adCampaign) {
            Object.assign(filter, { adCampaign });
        }
    }

    if (isPostTextSupported) {
        const postText = getSelectedPostTextOrNull(optionalSelectedPostText, settings);
        if (postText) {
            Object.assign(filter, { postText });
        }
    }

    if (isPostTextExcludeSupported) {
        const postTextExclude = getSelectedPostExcludeTextOrNull(optionalSelectedPostTextExclude, settings);
        if (postTextExclude) {
            Object.assign(filter, { postTextExclude });
        }
    }
    if (isPostTagsSupported) {
        const postTag = getSelectedPostTagsOrNull(optionalSelectedPostTag, settings);
        if (postTag) {
            Object.assign(filter, { postTag });
        }
    }
    return filter;
};

export const createServerParamsForDataSourceRequest = (
    paginationAndSorting
) => _pick(paginationAndSorting, ['start', 'limit', 'sortBy', 'sortDir']);

export const createServerParamsForMetric = (
    metric,
    settings,
    paginationAndSorting
) => {
    const visualizationId = getVisualizationId(metric, settings);

    const params = {
        visualizationId
    };

    const currentVisualizationId = parseInt(visualizationId, 10);
    const requiresMetaConfig = currentVisualizationId === 10 || currentVisualizationId === 27;
    if (requiresMetaConfig) {
        const paginate = _get(metric, 'config.metaData.paginate', true);
        const { start, limit } = getStartAndLimit(paginate, paginationAndSorting);
        const { sortBy, sortDir } = getSortByAndSortDir(metric, settings, paginationAndSorting);
        const absoluteLimit = getAbsoluteLimit(metric, settings);

        Object.assign(params, {
            start, limit, sortBy, sortDir, absoluteLimit
        });
    }
    return params;
};

// Overwrite Start And Limit for Export and set SortBy and SortDir
export const overwriteServerRequestParametersForExport = (originalServerRequest, paginationAndSorting) => {
    const { params } = originalServerRequest;
    const newParams = {};
    if (_has(params, 'start')) {
        Object.assign(newParams, { start: 0 });
    }
    if (_has(params, 'limit')) {
        Object.assign(newParams, { limit: 0 });
    }
    const sortBy = _get(paginationAndSorting, 'sortBy');
    const sortDir = _get(paginationAndSorting, 'sortDir');
    if (sortBy) {
        Object.assign(newParams, { sortBy });
    }
    if (sortDir) {
        Object.assign(newParams, { sortDir });
    }
    return Object.assign({}, params, newParams);
};

export const overwriteServerRequestParametersForPagination = (originalServerRequest, paginationAndSorting) => {
    const { params } = originalServerRequest;
    const { start, limit } = getStartAndLimit(paginationAndSorting.paginate, paginationAndSorting);
    const sortBy = _get(paginationAndSorting, 'sortBy');
    const sortDir = _get(paginationAndSorting, 'sortDir');
    const newParams = Object.assign({}, params, {
        start, limit, sortBy, sortDir
    });
    return Object.assign({}, params, newParams);
};
