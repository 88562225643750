import React, { PureComponent } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import MetricNameHeaderWithIcons from 'src/components/MetricNameHeaderWithIcons';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/metricHeader.scss';
import ResponsiveMetricMenu from 'src/components/metricTiles/layout/ResponsiveMetricMenu';

import withFlexDimensionProvider from 'src/components/withFlexDimensionProvider';

class GridDataHeader extends PureComponent {
    render() {
        const {
            metric,
            dragHandleClassName,
            dashboardMetric,
            breakpoint,
            isAllowedToChangeLayout,
            showMetricDetailsAction,
            dashboardMetricLink,
            isGridLoading,
            width
        } = this.props;

        let menuBreakpoint = 'large';
        if (width && width < 170) {
            menuBreakpoint = 'small';
        } else if (width && width < 300) {
            menuBreakpoint = 'medium';
        }
        return (
            <div className={styles.metricHeader}>
                <div className={classnames(styles.topRow, styles.grid, styles[menuBreakpoint] || '')}>
                    <div
                      className={classnames(styles.left, styles.withHover)}
                      onClick={showMetricDetailsAction}
                    >
                        <MetricNameHeaderWithIcons
                          name={dashboardMetric.displayName}
                          platformType={metric.platformType}
                          isBasedOnPrivateStatistics={metric.isBasedOnPrivateStatistics}
                          isCustomMetric={metric.isCustomMetric}
                        />
                    </div>
                    <div className={classnames(styles.right)}>
                        <ResponsiveMetricMenu
                          dashboardMetric={dashboardMetric}
                          dashboardGridBreakpoint={breakpoint}
                          isAllowedToChangeLayout={isAllowedToChangeLayout}
                          isGridLoading={isGridLoading}
                          gridDragHandleClassName={dragHandleClassName}
                          dashboardMetricLink={dashboardMetricLink}
                          breakpoint={menuBreakpoint}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

GridDataHeader.propTypes = {
    metric: customPropTypes.metric.isRequired,
    dragHandleClassName: PropTypes.string.isRequired,
    dashboardMetric: customPropTypes.dashboardMetric.isRequired,
    breakpoint: customPropTypes.gridBreakpoints.isRequired,
    isAllowedToChangeLayout: PropTypes.bool.isRequired,
    showMetricDetailsAction: PropTypes.func.isRequired,
    dashboardMetricLink: customPropTypes.linkTo.isRequired,
    isGridLoading: PropTypes.bool,
    width: PropTypes.number
};

GridDataHeader.defaultProps = {
    isGridLoading: false,
    width: undefined
};

export default withFlexDimensionProvider(GridDataHeader, true, false, 300, true);
