import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import { makeSelectOnlyProfileById } from 'src/selectors/profiles';
import PropTypes from 'prop-types';
import React from 'react';
import BrokenAccountUseCasesRow from 'src/components/views/authentications/table/BrokenAccountUseCasesRow';

const BrokenProfileUseCasesRow = (props) => {
    const {
        profile,
        platformUseCases,
        invalidReasonsByUseCaseId
    } = props;
    return (
        <BrokenAccountUseCasesRow
          invalidReasonsByUseCaseId={invalidReasonsByUseCaseId}
          account={profile}
          accountType="profiles"
          platformUseCases={platformUseCases}
        />
    );
};

BrokenProfileUseCasesRow.propTypes = {
    invalidReasonsByUseCaseId: PropTypes.objectOf(PropTypes.string).isRequired,
    profile: customPropTypes.profile.isRequired,
    profileId: PropTypes.string.isRequired,
    platformUseCases: customPropTypes.useCases.isRequired
};

const makeMapStateToProps = () => {
    const selectOnlyProfileById = makeSelectOnlyProfileById();
    return (state, ownProps) => ({
        profile: selectOnlyProfileById(state, ownProps.profileId)
    });
};

export default connect(makeMapStateToProps)(BrokenProfileUseCasesRow);
