import {
    accept,
    base,
    createFetch,
    createStack,
    init,
    method,
    body,
    requestInfo,
} from 'http-client';
import config from 'react-global-configuration';
import { requestUrl } from 'src/config';
import { parseWithOwnErrorText, exceptionOnNotOk } from 'src/requestHandling/utils';

export default (parameters = {}, parser = 'json') => {
    const formData = new FormData();
    Object.keys(parameters).forEach((key) => {
        formData.append(key, parameters[key]);
    });
    const csrfToken = config.get('csrfToken', false);
    formData.append('csrfToken', csrfToken);
    const stack = createStack(
        base(requestUrl),
        accept('application/json'),
        init('credentials', 'include'),
        requestInfo(),
        method('POST'),
        body(formData),
        parseWithOwnErrorText(parser, 'jsonData'),
        exceptionOnNotOk(parser)
    );
    return createFetch(stack);
};
