import React from 'react';
import styles from 'src/stylesheets/views/integrations/dataPushTaskFrequencyIndicator.scss';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';

const DataPushTaskFrequencyIndicator = ({ delivery }) => (
    <div className={styles.wrapper}>
        <div className={styles.text}>{delivery || <i>Delivery is disabled</i>}</div>
        <IcomoonIcon icon="forward" className={styles.icon} />
    </div>
);

DataPushTaskFrequencyIndicator.propTypes = {
    delivery: PropTypes.string
};

export default DataPushTaskFrequencyIndicator;
