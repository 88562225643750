import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { selectLoggedInUser } from 'src/selectors/users';
import styles from 'src/stylesheets/agentBar.scss';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import { getAgentModeText } from 'src/utils/user';

const AgentBar = ({ loggedInUser }) => {
    const {
        user
    } = useAuth0();
    return (<div className={styles.agentBar}>{getAgentModeText(user, loggedInUser)}</div>);
};

AgentBar.propTypes = {
    loggedInUser: customPropTypes.user.isRequired
};

const mapStateToProps = (state) => ({
    loggedInUser: selectLoggedInUser(state)
});

export default connect(mapStateToProps)(AgentBar);
