import { ensurePermission, writePermissions } from 'src/middleware/userPermissionCheck';
import React, { useState } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import * as overlayActions from 'src/actions/overlays';
import BrokenAccountUseCasesWarning from 'src/components/account/BrokenAccountUseCasesWarning';
import BrokenAccountsUseCasesTable from 'src/components/views/authentications/table/BrokenAccountsUseCasesTable';
import Button from 'src/components/buttons/Button';
import classnames from 'classnames';
import { connect } from 'react-redux';
import IcomoonIcon from 'src/components/IcomoonIcon';
import { makeSelectBrokenProfileUseCasesByPlatformType, makeSelectBrokenAdAccountUseCasesByPlatformType } from 'src/selectors/authUsers';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/views/authentications/profilesWithBrokenUseCases.scss';
import { platformTypeOrder } from 'src/utils/authTransactionUtils';
import { getAllBrokenAccountIds } from 'src/utils/accountInsights';

const BrokenProfilesUseCasesList = (props) => {
    const {
        isMenuOpen,
        brokenProfilesUseCasesByPlatformType,
        brokenAdAccountsUseCasesByPlatformType
    } = props;
    const [menuState, toggleMenuState] = useState(isMenuOpen);
    const toggleMenuStateAction = () => {
        toggleMenuState(!menuState);
    };
    const icon = menuState ? 'chevron-big-up' : 'chevron-big-down';
    const brokenProfileIds = getAllBrokenAccountIds(brokenProfilesUseCasesByPlatformType, 'profiles');
    const brokenAdAccountIds = getAllBrokenAccountIds(brokenAdAccountsUseCasesByPlatformType, 'adAccounts');
    const failedAccountCount = brokenProfileIds.length + brokenAdAccountIds.length;
    const handleQuickRequestButton = () => {
        const { modalsShowCreateAuthTransactionRequirementsAction } = props;
        modalsShowCreateAuthTransactionRequirementsAction(brokenProfileIds, brokenAdAccountIds, true);
    };

    return (
        <div className={styles.wrapper}>
            <div className={classnames(styles.header, { [styles.disabled]: failedAccountCount === 0 })}>
                <div className={styles.left} onClick={() => { toggleMenuStateAction(); }}>
                    {
                        failedAccountCount > 0
                        && (
                        <div className={styles.toggleButton}>
                            <IcomoonIcon icon={icon} className={styles.icon} />
                        </div>
                        )
                    }
                    <BrokenAccountUseCasesWarning failedAccountCount={failedAccountCount} />
                </div>
                {
                    failedAccountCount > 0
                    && (
                    <div className={styles.right}>
                        <Button
                          small
                          icon="plus"
                          label="Quick authentication with these accounts"
                          onClick={handleQuickRequestButton}
                        />
                    </div>
                    )
                }
            </div>
            {
                (menuState && failedAccountCount > 0)
                && (
                <div className={styles.container}>
                    <div className={styles.body}>
                        {
                            platformTypeOrder.map((platformType) => {
                                const brokenProfilesUseCases = brokenProfilesUseCasesByPlatformType[platformType] || [];
                                const brokenAdAccountsUseCases = brokenAdAccountsUseCasesByPlatformType[platformType] || [];
                                if (brokenProfilesUseCases.length > 0 || brokenAdAccountsUseCases.length > 0) {
                                    return (
                                        <div className={styles.network} key={platformType}>
                                            <BrokenAccountsUseCasesTable
                                              brokenProfilesUseCases={brokenProfilesUseCases}
                                              brokenAdAccountsUseCases={brokenAdAccountsUseCases}
                                              platformType={platformType}
                                            />
                                        </div>
                                    );
                                }
                                return null;
                            })
                        }
                    </div>
                </div>
                )
            }
        </div>
    );
};

BrokenProfilesUseCasesList.propTypes = {
    brokenProfilesUseCasesByPlatformType: customPropTypes.brokenProfilesUseCasesByPlatformType.isRequired,
    brokenAdAccountsUseCasesByPlatformType: customPropTypes.brokenAdAccountsUseCasesByPlatformType.isRequired,
    modalsShowCreateAuthTransactionRequirementsAction: PropTypes.func.isRequired,
    isMenuOpen: PropTypes.bool
};

BrokenProfilesUseCasesList.defaultProps = {
    isMenuOpen: false
};

const makeMapStateToProps = () => {
    const selectBrokenProfileUseCasesByPlatformType = makeSelectBrokenProfileUseCasesByPlatformType();
    const selectBrokenAdAccountUseCasesByPlatformType = makeSelectBrokenAdAccountUseCasesByPlatformType();
    return (state) => ({
        brokenProfilesUseCasesByPlatformType: selectBrokenProfileUseCasesByPlatformType(state),
        brokenAdAccountsUseCasesByPlatformType: selectBrokenAdAccountUseCasesByPlatformType(state)
    });
};

export default connect(makeMapStateToProps, {
    modalsShowCreateAuthTransactionRequirementsAction: ensurePermission(overlayActions.modalsShowCreateAuthTransactionRequirements, writePermissions.createAuthTransaction)
})(BrokenProfilesUseCasesList);
