import React from 'react';
import styles from 'src/stylesheets/views/postTags/postTagging/postTilesByCreateTime.scss';
import EmptyDiv from 'src/components/loadingIndicators/EmptyDiv';
import FloatingListTilesSkeleton from 'src/components/loadingIndicators/FloatingListTilesSkeleton';

const PostTaggingLoadingSkeleton = () => (
    <div className={styles.wrapper}>
        <div className={styles.timeWrapper}>
            <div className={styles.horizontalLine} />
            <div className={styles.createTime}><EmptyDiv height={24} width={100} classNames={styles.tileBackgroundColor} /></div>
            <div className={styles.horizontalLine} />
        </div>
        <FloatingListTilesSkeleton numberOfItems={5} emptyDivClassName={styles.tileBackgroundColor} />
    </div>
);

export default PostTaggingLoadingSkeleton;
