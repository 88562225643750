import Column from 'src/components/header/layout/Column';
import Header from 'src/components/header/layout/Header';
import React from 'react';
import TopRow from 'src/components/header/layout/TopRow';
import styles from 'src/stylesheets/headers/dataSourceDetailHeader.scss';
import EmptyDiv from 'src/components/loadingIndicators/EmptyDiv';

const DataSourceDetailLoadingHeader = () => (
    <Header>
        <TopRow>
            <Column flexGrow>
                <div className={styles.wrapper}>
                    <div className={styles.backButton}>
                        <EmptyDiv height={24} width={28} />
                    </div>
                    <div style={{ paddingRight: '16px' }}>
                        <EmptyDiv height={24} width={24} />
                    </div>
                    <EmptyDiv height={35} width={300} />
                </div>
            </Column>
        </TopRow>
    </Header>
);

export default DataSourceDetailLoadingHeader;
