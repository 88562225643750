import _get from 'lodash/get';
import _isString from 'lodash/isString';
import { setIn } from 'src/reducers/utils';
import { createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import getStoredStateMigrateV4 from 'redux-persist/lib/integration/getStoredStateMigrateV4';
import _has from 'lodash/has';

const getMigratedSelection = (oldSelection) => {
    const profiles = [];
    const groups = [];
    if (_isString(oldSelection.selected)) {
        if (oldSelection.selected.startsWith('profile')) {
            const profileId = oldSelection.selected.replace('profile-', '');
            profiles.push(profileId);
        }
        if (oldSelection.selected.startsWith('group')) {
            const groupId = oldSelection.selected.replace('group-', '');
            groups.push(groupId);
        }
    }
    return { profileIds: profiles, groupIds: groups };
};

const getProfileSelectionsWithAccountIds = (oldSelection) => {
    if (!_has(oldSelection, 'accountIds')) {
        return { profileIds: oldSelection.profileIds, groupIds: oldSelection.groupIds, accountIds: [] };
    }
    return oldSelection;
};

export const migrations = {
    // migrate old profile selector format to new
    1: (state) => {
        let modifiedState = Object.assign({}, state);
        const path = 'filterSelectors.contextAwareFilterSelectors.profile';
        const profileSelectors = _get(modifiedState, path, {});
        const newState = {};
        Object.keys(profileSelectors).forEach((key) => {
            Object.assign(newState, { [key]: getMigratedSelection(profileSelectors[key]) });
        });
        modifiedState = setIn(modifiedState, path, newState);

        const pathDefault = 'filterSelectors.defaultSelectors.profile';
        const defaultSelection = _get(modifiedState, pathDefault, {});
        modifiedState = setIn(modifiedState, pathDefault, getMigratedSelection(defaultSelection));
        return modifiedState;
    },
    // append accountIds to profile selector state
    2: (state) => {
        let modifiedState = Object.assign({}, state);
        const path = 'filterSelectors.contextAwareFilterSelectors.profile';
        const profileSelectors = _get(modifiedState, path, {});
        const newState = {};
        Object.keys(profileSelectors).forEach((key) => {
            Object.assign(newState, { [key]: getProfileSelectionsWithAccountIds(profileSelectors[key]) });
        });
        modifiedState = setIn(modifiedState, path, newState);

        const pathDefault = 'filterSelectors.defaultSelectors.profile';
        const defaultSelection = _get(modifiedState, pathDefault, {});
        modifiedState = setIn(modifiedState, pathDefault, getProfileSelectionsWithAccountIds(defaultSelection));
        return modifiedState;
    }
};

export const getPersistConfig = (userId, spaceId) => {
    const yourOldV4Config = {
        whitelist: ['filterSelectors', 'lastVisited', 'recentSearches'],
        keyPrefix: `${spaceId}_${userId}_`
    };

    return {
        whitelist: ['filterSelectors', 'lastVisited', 'recentSearches'],
        key: `${spaceId}_${userId}_`,
        storage,
        getStoredState: getStoredStateMigrateV4(yourOldV4Config),
        migrate: createMigrate(migrations, { debug: false }),
        version: 2
    };
};
