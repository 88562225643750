import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Column from 'src/components/header/layout/Column';
import Header from 'src/components/header/layout/Header';
import PageHeadline from 'src/components/header/layout/PageHeadline';
import React from 'react';
import TopRow from 'src/components/header/layout/TopRow';
import withReactRouterLinkHandling from 'src/components/withReactRouterLinkHandling';
import { makeSelectMetricBuilderLink } from 'src/selectors/filterSelectors';
import { connect } from 'react-redux';
import { METRIC_BUILDER } from 'src/utils/filterSelectors';
import * as customPropTypes from 'src/customPropTypes';

const ButtonWithReactRouterLinkHandling = withReactRouterLinkHandling(Button);

const CustomMetricsHeader = ({ metricBuilderLink }) => (
    <Header>
        <TopRow>
            <Column flexGrow>
                <PageHeadline>Custom metrics</PageHeadline>
            </Column>
            <Column>
                <ButtonGroup>
                    <ButtonWithReactRouterLinkHandling label="Build new metric" to={metricBuilderLink} />
                </ButtonGroup>
            </Column>
        </TopRow>
    </Header>
);

CustomMetricsHeader.propTypes = {
    metricBuilderLink: customPropTypes.linkTo.isRequired
};

const makeMapStateToProps = () => {
    const selectMetricBuilderLink = makeSelectMetricBuilderLink();
    return (state) => ({
        metricBuilderLink: selectMetricBuilderLink(state, METRIC_BUILDER)
    });
};

export default connect(makeMapStateToProps)(CustomMetricsHeader);
