import React, { Component } from 'react';
import * as routerActions from 'react-router-redux';
import { connect } from 'react-redux';
import DashboardsAndFolderSearchAutocomplete from 'src/components/autocomplete/DashboardsAndFolderSearchAutocomplete';
import PropTypes from 'prop-types';
import TextWithAutocomplete from 'src/components/forms/inputs/TextWithAutocomplete';
import withDashboardAndFolderAutocomplete from 'src/components/views/dashboards/withDashboardAndFolderAutocomplete';
import withOuterSearchTerm from 'src/components/forms/inputs/withOuterSearchTerm';

const TextWithDashboardAndFolderAutocomplete = withOuterSearchTerm(withDashboardAndFolderAutocomplete(TextWithAutocomplete));

const renderAutocompleteMenu = (autocompleteSuggestions, activeIndex, hide, onItemSelect) => (
    <DashboardsAndFolderSearchAutocomplete
      filteredDashboardAndFolderIds={autocompleteSuggestions}
      activeIndex={activeIndex}
      hidePopover={hide}
      onItemClick={onItemSelect}
    />
);

class DashboardsOrFolderSearchInput extends Component {
    constructor(props) {
        super(props);
        this.handleOnItemSelect = this.handleOnItemSelect.bind(this);
    }

    handleOnItemSelect(item) {
        const { routePushAction } = this.props;
        const { type, id } = item;
        switch (type) {
            case 'folder':
                routePushAction({ pathname: `/folder/${id}` });
                break;
            case 'dashboard':
                routePushAction({ pathname: `/dashboard/${id}` });
                break;
            default:
                break;
        }
    }

    render() {
        const { autoFocus } = this.props;

        return (
            <TextWithDashboardAndFolderAutocomplete
              onItemSelect={this.handleOnItemSelect}
              renderAutocompleteMenu={renderAutocompleteMenu}
              placeholder="Search all dashboards or folders"
              blurAfterSubmit
              layout="listFilter"
              minLength={1}
              autoFocus={autoFocus}
            />
        );
    }
}

DashboardsOrFolderSearchInput.propTypes = {
    routePushAction: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool
};

DashboardsOrFolderSearchInput.defaultProps = {
    autoFocus: false
};

export default connect(null, { routePushAction: routerActions.push })(DashboardsOrFolderSearchInput);
