import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Date from 'src/components/Date';
import UserDetail from 'src/components/views/settings/users/UserDetail';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/views/settings/userListItem.scss';
import UserIcon from 'src/components/UserIcon';

const UserListItem = (props) => {
    const {
        id,
        email,
        isAdmin,
        modalShowDeleteUserAction,
        modalShowEditUserAction,
        displayName,
        gravatar80px,
        lastActiveTime,
        onboarded,
        invitationPending
    } = props;

    const buttons = (
        <ButtonGroup>
            <Button small onClick={() => { modalShowEditUserAction(id); }} label="Edit" />
            <Button small onClick={() => { modalShowDeleteUserAction(id, email); }} label="Delete" icon="delete" />
        </ButtonGroup>
    );

    const emailOrPending = (invitationPending) ? 'Invitation pending' : email;
    const lastActiveOrUndefined = onboarded ? <>Last active: <Date format="datetime" appendTimezone date={lastActiveTime} /></> : undefined;
    return (
        <div className={styles.listItem}>
            <div className={styles.userInfoWrapper}>
                <UserIcon iconUrl={gravatar80px} size={80} />
                <UserDetail
                  topRow={<>{displayName}{ isAdmin ? ' (Admin)' : '' }</>}
                  smallDetail={emailOrPending}
                  smallestDetail={lastActiveOrUndefined}
                />
            </div>
            <div className={styles.buttonWrapper}>
                {buttons}
            </div>
        </div>
    );
};

UserListItem.propTypes = {
    id: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    lastActiveTime: PropTypes.string,
    invitationPending: PropTypes.bool.isRequired,
    onboarded: PropTypes.bool.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    gravatar80px: PropTypes.string.isRequired,
    modalShowDeleteUserAction: PropTypes.func.isRequired,
    modalShowEditUserAction: PropTypes.func.isRequired
};

export default UserListItem;
