import * as metricSearchActionCreators from 'src/actions/metricSearch';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import { convertRouteToFilter } from 'src/utils/discoverUtiles';
import MenuButton from 'src/components/buttons/MenuButton';
import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import React from 'react';
import { selectLastVisitedDiscoverMetricState } from 'src/selectors/lastVisited';

const style = {
    width: '145px'
};

const DiscoverFilterPopover = (props) => {
    const { hidePopover, filterByUpdateAction, lastSelectedMetric } = props;
    const onChangeAndHidePopover = (value) => {
        filterByUpdateAction(value);
        hidePopover();
    };
    return (
        <Popover id="discoverFilterPopover">
            <div style={style}>
                <ButtonGroup vertical>
                    <MenuButton label="Metrics" onClick={() => { onChangeAndHidePopover(lastSelectedMetric); }} />
                    <MenuButton label="Dashboards" onClick={() => { onChangeAndHidePopover('dashboards'); }} />
                </ButtonGroup>
            </div>
        </Popover>
    );
};

DiscoverFilterPopover.propTypes = {
    hidePopover: PropTypes.func.isRequired,
    filterByUpdateAction: PropTypes.func.isRequired,
    lastSelectedMetric: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
    lastSelectedMetric: convertRouteToFilter(selectLastVisitedDiscoverMetricState(state))
});

export default connect(mapStateToProps, {
    filterByUpdateAction: metricSearchActionCreators.metricSearchFilterByUpdate
})(DiscoverFilterPopover);
