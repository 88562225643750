import Column from 'src/components/header/layout/Column';
import Header from 'src/components/header/layout/Header';
import PageHeadline from 'src/components/header/layout/PageHeadline';
import React from 'react';
import TopRow from 'src/components/header/layout/TopRow';

const ReportsHeader = () => (
    <Header>
        <TopRow>
            <Column flexGrow>
                <PageHeadline>Reports</PageHeadline>
            </Column>
        </TopRow>
    </Header>
);

export default ReportsHeader;
