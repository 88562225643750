import * as customPropTypes from 'src/customPropTypes';
import * as exportActions from 'src/actions/exports';
import _has from 'lodash/has';
import { connect } from 'react-redux';
import { createBatchServerRequestForExport } from 'src/requestHandling/metricDataRequests';
import { makeSelectDateByContext } from 'src/selectors/filterSelectors';
import { makeSelectPaginationAndSortingForMultipleIdentifier } from 'src/selectors/tablePagination';
import PropTypes from 'prop-types';
import React from 'react';
import { selectSortedDashboardMetricIdsIndexById } from 'src/selectors/dashboards';
import withFilterContextConsumer from 'src/components/withFilterContextConsumer';
import _pick from 'lodash/pick';
import ExportButton from './ExportButton';

// ensure that it is ordered cause there is no convention that dashboardMetricIds are ordered by layout
const orderBatchRequestsForExports = (unorderedBatchRequest, sortIndex) => {
    const orderedRequests = [];
    const newEntityIdToRequestMapping = {};
    sortIndex.forEach((id) => {
        if (_has(unorderedBatchRequest, ['entityIdToRequestMapping', id])) {
            const requestIndex = unorderedBatchRequest.entityIdToRequestMapping[id];
            const idRelatedRequest = unorderedBatchRequest.requests[requestIndex];
            const newIndex = orderedRequests.length;
            Object.assign(newEntityIdToRequestMapping, { [id]: newIndex });
            orderedRequests.push(idRelatedRequest);
        }
    });
    return Object.assign({}, unorderedBatchRequest, { requests: orderedRequests, entityIdToRequestMapping: newEntityIdToRequestMapping });
};

const DashboardExportButton = (props) => {
    const {
        dashboardId,
        triggerDashboardExportAction,
        disabled,
        fileName,
        batchPaginationAndSorting,
        batchServerRequest,
        sortIndex,
        selectedDate
    } = props;

    const dashboardExportActionWithSortingParameters = (fileType) => {
        const batchDateFilter = _pick(selectedDate, ['interval', 'timezone', 'dynamicDate']);

        if (_has(selectedDate, 'to')) {
            batchDateFilter.endTime = selectedDate.to;
        }
        if (_has(selectedDate, 'from')) {
            batchDateFilter.startTime = selectedDate.from;
        }
        const batchRequestForExport = createBatchServerRequestForExport(
            batchServerRequest, batchPaginationAndSorting, fileType, { dashboardId, batchDateFilter }
        );
        const orderedBatchRequestsForExport = (orderBatchRequestsForExports(batchRequestForExport, sortIndex));
        triggerDashboardExportAction(orderedBatchRequestsForExport, fileName);
    };

    return (
        <ExportButton
          fileName={fileName}
          exportAction={dashboardExportActionWithSortingParameters}
          label="Export"
          multiAction
          disabled={disabled}
        />
    );
};

DashboardExportButton.propTypes = {
    dashboardId: PropTypes.string.isRequired,
    triggerDashboardExportAction: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    fileName: PropTypes.string.isRequired,
    batchServerRequest: customPropTypes.batchServerRequest.isRequired,
    batchPaginationAndSorting: customPropTypes.batchPaginationAndSorting.isRequired,
    sortIndex: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedDate: customPropTypes.dateFilter.isRequired
};

DashboardExportButton.defaultProps = {
    disabled: false
};

const makeMapStateToProps = () => {
    const selectPaginationAndSortingForMultipleIdentifier = makeSelectPaginationAndSortingForMultipleIdentifier();
    const selectDateByContext = makeSelectDateByContext();

    return (state, ownProps) => {
        const { batchServerRequest, dashboardId, filterContext } = ownProps;
        const identifiers = batchServerRequest.requests.map((request) => request.identifier);
        return {
            batchPaginationAndSorting: selectPaginationAndSortingForMultipleIdentifier(state, identifiers),
            sortIndex: selectSortedDashboardMetricIdsIndexById(state, dashboardId),
            selectedDate: selectDateByContext(state, filterContext)
        };
    };
};

export default withFilterContextConsumer(connect(makeMapStateToProps, {
    triggerDashboardExportAction: exportActions.triggerDashboardExport,
})(DashboardExportButton));
