import {
    POST_TAG_RULE_DELETE_REQUEST,
    POST_TAG_RULE_ADD_REQUEST,
    POST_TAG_RULE_UPDATE_REQUEST,
    postTagRuleUpdateError,
    postTagRuleUpdateSuccess,
    postTagRuleAddError,
    postTagRuleAddSuccess,
    postTagRulesDeleteError,
    postTagRulesDeleteSuccess,
    postTagRulesGetError,
    postTagRulesGetSuccess
} from 'src/actions/postTagRule';
import { put, takeEvery, select } from 'redux-saga/effects';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import { parsePostTagRules, parsePostTagRule } from 'src/parsers';
import _parseInt from 'lodash/parseInt';
import { SubmissionError } from 'redux-form';
import { showNotification } from 'src/actions/notifications';
import {
    modalHidePostTagRulesDelete, modalShowPostTagRuleWarning
} from 'src/actions/overlays';
import { PROFILE_DELETE_SUCCESS, PROFILE_FORCE_DELETE_SUCCESS } from 'src/actions/profiles';
import { GROUP_DELETE_SUCCESS, GROUP_FORCE_DELETE_SUCCESS } from 'src/actions/groups';
import {
    POST_TAG_FORCE_REMOVE_SUCCESS,
    POST_TAGS_ARCHIVE_SUCCESS,
    POST_TAGS_FORCE_ARCHIVE_SUCCESS,
    POST_TAGS_REMOVE_SUCCESS,
    deactivateAutomationIfLastPostTagRuleRemoved,
    postTagAutomationActivateRequest, postSearchByTagRequest as postSearchByTagRequestAction
} from 'src/actions/postTags';
import { selectPostTagRuleIgnoreCheck } from 'src/selectors/forms';
import { createFreshPostServerRequest } from 'src/requestHandling/datasourceDataRequests';
import {
    defaultAdCampaignState,
    defaultPostTagState,
    isPostTextExcludeSelected,
    isPostTextSelected
} from 'src/utils/filterSelectors';
import {
    getDateSelectionArray, getDateSelectionForSearchArray, isPostTagRuleDuplicate, getPostTagRuleRequestParams
} from 'src/utils/postTagRule';
import { makeSelectPostTagRulesByPostTagId } from 'src/selectors/postTagRules';

const selectPostTagRulesByPostTagId = makeSelectPostTagRulesByPostTagId();

export const createConjunctionFilter = (values, conjunction) => ({ values, conjunction });
const getHistoricalWarningMessage = (operationType, numberOfPostsTobeTagged) => `The rule you want to ${operationType} applies to ${numberOfPostsTobeTagged} historical post and will be automatically tagged. Are you sure you want to ${operationType} the rule?`;

function* getPostTagRulesRequest() {
    try {
        const serverRequest = createServerRequest();
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/get-auto-tag-rules');
        if (response) {
            const { postTagRules } = response.jsonData;
            yield put(postTagRulesGetSuccess(parsePostTagRules(postTagRules)));
        }

        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(postTagRulesGetError(applicationError));
    }
}

function* getNumberOfPostsToBeTagged(dateSelection, profileSelection, postText, postTextExclude, postTagId) {
    const { request } = createFreshPostServerRequest(profileSelection, getDateSelectionForSearchArray(dateSelection), postText, postTextExclude, defaultPostTagState, defaultAdCampaignState);
    const serverRequest = createServerRequest({
        dataSourceDataRequest: JSON.stringify(request), postTagId: _parseInt(postTagId)
    });
    const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/get-number-of-posts-to-be-tagged-by-rule-property');
    return {
        response,
        serverError
    };
}

function* postTagRuleDeleteRequest(action) {
    const { payload } = action;
    const { postTagRuleId, postTagId, removeTagsOnPosts } = payload;
    try {
        const serverRequest = createServerRequest({ postTagRuleId: _parseInt(postTagRuleId), removeTagsOnPosts });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/remove-auto-tag-rule');
        if (response) {
            yield put(modalHidePostTagRulesDelete());
            yield put(postTagRulesDeleteSuccess(postTagId, postTagRuleId));
            yield put(showNotification('Rule was successfully removed.'));
            yield put(deactivateAutomationIfLastPostTagRuleRemoved(postTagId));
            if (removeTagsOnPosts) {
                yield put(postSearchByTagRequestAction(postTagId));
            }
        }

        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(postTagRulesDeleteError(new SubmissionError({ _error: applicationError })));
    }
}

function* postTagRulesAddRequest(action) {
    const { payload } = action;
    const {
        name, from, to, profileSelection, postTextValues, postTextConjunction, postTagId, automationEnabled, timezone, postTextExcludeValues, postTextExcludeConjunction
    } = payload;

    const ignoreCheck = yield select(selectPostTagRuleIgnoreCheck);
    const postText = createConjunctionFilter(postTextValues, postTextConjunction);
    const postTextExclude = createConjunctionFilter(postTextExcludeValues, postTextExcludeConjunction);

    const postTagRules = yield select(selectPostTagRulesByPostTagId, postTagId);
    const isDuplicate = isPostTagRuleDuplicate(from, to, timezone, profileSelection, postText, postTextExclude, postTagRules);
    if (isDuplicate) {
        yield put(postTagRuleAddError(new SubmissionError({ _error: { message: 'This rule already exists. To add a new rule, please change something.' } })));
    } else if (!isPostTextExcludeSelected(postTextExclude) && !isPostTextSelected(postText)) {
        yield put(postTagRuleAddError(new SubmissionError({ _error: { message: 'You either need to specify include or exclude keywords in order to proceed.' } })));
    } else {
        const dateSelection = getDateSelectionArray(from, to, timezone);
        const params = getPostTagRuleRequestParams(
            name,
            dateSelection,
            profileSelection,
            postText,
            postTextExclude,
            postTagId
        );
        try {
            let numberOfPostsTobeTagged = 0;
            if (!ignoreCheck) {
                const {
                    response: searchResponse,
                    serverError: searchServerError
                } = yield getNumberOfPostsToBeTagged(dateSelection, profileSelection, postText, postTextExclude, postTagId);
                if (searchResponse) {
                    const { filteredTotalRowCount } = searchResponse.jsonData;
                    numberOfPostsTobeTagged = filteredTotalRowCount;
                }
                if (searchServerError) {
                    throw searchServerError;
                }
            }
            if (numberOfPostsTobeTagged > 0) {
                yield put(modalShowPostTagRuleWarning('add', getHistoricalWarningMessage('add', numberOfPostsTobeTagged)));
                yield put(postTagRuleAddSuccess());
            } else {
                const serverRequest = createServerRequest(params);
                const {
                    response: response2,
                    serverError: serverError2
                } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/create-auto-tag-rule');
                if (response2) {
                    const { postTagRule } = response2.jsonData;
                    yield put(postTagRuleAddSuccess(parsePostTagRule(postTagRule)));
                    yield put(showNotification('The auto-tag rule was successfully created.'));

                    if (!automationEnabled) {
                        // request to turn the automation on
                        yield put(postTagAutomationActivateRequest(postTagId, true, false));
                    } else {
                        yield put(postSearchByTagRequestAction(postTagId, true));
                    }
                }

                if (serverError2) {
                    throw serverError2;
                }
            }
        } catch (applicationError) {
            yield put(postTagRuleAddError(new SubmissionError({ _error: applicationError })));
        }
    }
}

function* postTagRulesUpdateRequest(action) {
    const { payload } = action;
    const {
        postTagRuleId, name, from, to, profileSelection, postTextValues, postTextConjunction, postTagId, timezone, postTextExcludeValues, postTextExcludeConjunction
    } = payload;
    const ignoreCheck = yield select(selectPostTagRuleIgnoreCheck);
    const postText = createConjunctionFilter(postTextValues, postTextConjunction);
    const postTextExclude = createConjunctionFilter(postTextExcludeValues, postTextExcludeConjunction);
    const postTagRules = yield select(selectPostTagRulesByPostTagId, postTagId);
    const isDuplicate = isPostTagRuleDuplicate(from, to, timezone, profileSelection, postText, postTextExclude, postTagRules);
    if (isDuplicate) {
        yield put(postTagRuleUpdateError(new SubmissionError({ _error: { message: 'This rule already exists. To update the rule, please change something.' } })));
    } else if (!isPostTextExcludeSelected(postTextExclude) && !isPostTextSelected(postText)) {
        yield put(postTagRuleUpdateError(new SubmissionError({ _error: { message: 'You either need to specify include or exclude keywords in order to proceed.' } })));
    } else {
        const dateSelection = getDateSelectionArray(from, to, timezone);
        const params = getPostTagRuleRequestParams(
            name,
            dateSelection,
            profileSelection,
            postText,
            postTextExclude,
            postTagId,
            postTagRuleId
        );
        try {
            let numberOfPostsTobeTagged = 0;
            if (!ignoreCheck) {
                const {
                    response: searchResponse,
                    serverError: searchServerError
                } = yield getNumberOfPostsToBeTagged(dateSelection, profileSelection, postText, postTextExclude, postTagId);
                if (searchResponse) {
                    const { filteredTotalRowCount } = searchResponse.jsonData;
                    numberOfPostsTobeTagged = filteredTotalRowCount;
                }
                if (searchServerError) {
                    throw searchServerError;
                }
            }

            if (numberOfPostsTobeTagged > 0) {
                yield put(postTagRuleUpdateSuccess());
                yield put(modalShowPostTagRuleWarning('update', getHistoricalWarningMessage('update', numberOfPostsTobeTagged)));
            } else {
                const serverRequest = createServerRequest(params);
                const {
                    response,
                    serverError
                } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/update-auto-tag-rule');
                if (response) {
                    const { postTagRule } = response.jsonData;
                    yield put(postTagRuleUpdateSuccess(parsePostTagRule(postTagRule)));
                    yield put(showNotification('The auto-tag rule was successfully updated.'));
                    yield put(postSearchByTagRequestAction(postTagId, true));
                }

                if (serverError) {
                    throw serverError;
                }
            }
        } catch (applicationError) {
            yield put(postTagRuleUpdateError(new SubmissionError({ _error: applicationError })));
        }
    }
}

export default function* postTagRulesSagas() {
    yield takeEvery(POST_TAG_RULE_ADD_REQUEST, postTagRulesAddRequest);
    yield takeEvery(POST_TAG_RULE_UPDATE_REQUEST, postTagRulesUpdateRequest);
    yield takeEvery(POST_TAG_RULE_DELETE_REQUEST, postTagRuleDeleteRequest);
    yield takeEvery([
        PROFILE_DELETE_SUCCESS,
        PROFILE_FORCE_DELETE_SUCCESS,
        GROUP_DELETE_SUCCESS,
        GROUP_FORCE_DELETE_SUCCESS,
        POST_TAGS_REMOVE_SUCCESS,
        POST_TAG_FORCE_REMOVE_SUCCESS,
        POST_TAGS_ARCHIVE_SUCCESS,
        POST_TAGS_FORCE_ARCHIVE_SUCCESS
    ], getPostTagRulesRequest);
}
