import React from 'react';
import Sidebar from 'src/components/sidebar/Sidebar';
import Button from 'src/components/buttons/Button';
import styles from 'src/stylesheets/views/postTags/postTagging/postsSidebar.scss';
import withRemoveListItemIcon from 'src/components/withRemoveListItemIcon';
import PostListItem from 'src/components/views/postTags/postTagging/sidebar/PostListItem';
import PropTypes from 'prop-types';
import { makeSelectCheckedValues } from 'src/selectors/lists';
import { connect } from 'react-redux';
import { modalShowAddOrRemovePostTagsFromPosts } from 'src/actions/overlays';
import {
    getResultTotalRowCount, getShouldTagAllPosts, makeSelectPostSearchState, makeSelectPostIdsByCreateTime
} from 'src/selectors/posts';
import IntercomVerticalWhiteSpace from 'src/components/sidebar/IntercomVerticalWhiteSpace';
import EmptyDiv from 'src/components/loadingIndicators/EmptyDiv';
import classnames from 'classnames';
import * as customPropTypes from 'src/customPropTypes';
import SelectedEntityCountWithClearOption from 'src/components/views/SelectedEntityCountWithClearOption';

const PostListItemWithRemoveListItemIcon = withRemoveListItemIcon(PostListItem);

const PostsSidebar = (props) => {
    const {
        listName, checkedPostIds, modalShowAddOrRemovePostTagsFromPostsAction, shouldTagAllPosts, searchTotalCount, postSearchState
    } = props;
    const { isPending } = postSearchState;
    return (
        <Sidebar>
            <div className={classnames(styles.wrapper, { [styles.disabled]: isPending })}>
                <div className={styles.controls}>
                    <Button
                      small
                      stretch
                      className={styles.button}
                      label="Assign tags"
                      onClick={() => { modalShowAddOrRemovePostTagsFromPostsAction(checkedPostIds); }}
                      disabled={checkedPostIds.length === 0}
                    />
                </div>
                <div className={styles.selectionInfo}>
                    <SelectedEntityCountWithClearOption
                      label="Selected posts"
                      listName={listName}
                      count={checkedPostIds.length}
                    />
                    {
                        checkedPostIds.length === 0
                        && (
                            <div>
                                <div className={styles.empty}>
                                    <EmptyDiv width={32} height={32} />
                                    <div className={styles.none}>
                                        No posts selected yet
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className={styles.selectedPosts}>
                    {
                        checkedPostIds.map((id) => (
                            <div className={styles.postListItem} key={id}>
                                <PostListItemWithRemoveListItemIcon
                                  id={id}
                                  listName={listName}
                                  fieldName={id}
                                />
                            </div>
                        ))
                    }
                    {
                        shouldTagAllPosts
                        && (
                            <div className={styles.remainingPostCounts}>
                                {`+ ${searchTotalCount - checkedPostIds.length} more posts`}
                            </div>
                        )
                    }
                    <IntercomVerticalWhiteSpace />
                </div>
            </div>
        </Sidebar>
    );
};

PostsSidebar.propTypes = {
    listName: PropTypes.string.isRequired,
    checkedPostIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    modalShowAddOrRemovePostTagsFromPostsAction: PropTypes.func.isRequired,
    searchTotalCount: PropTypes.number.isRequired,
    shouldTagAllPosts: PropTypes.bool.isRequired,
    postSearchState: customPropTypes.asyncDataLoadingState.isRequired,
};

const makeMapStateToProps = () => {
    const selectCheckedValues = makeSelectCheckedValues();
    const selectPostSearchState = makeSelectPostSearchState();
    const selectPostIdsByCreateTime = makeSelectPostIdsByCreateTime();
    return (state, ownProps) => {
        const postIds = selectCheckedValues(state, ownProps.listName);
        return {
            checkedPostIds: selectPostIdsByCreateTime(state, postIds),
            searchTotalCount: getResultTotalRowCount(state),
            shouldTagAllPosts: getShouldTagAllPosts(state),
            postSearchState: selectPostSearchState(state)
        };
    };
};

export default connect(makeMapStateToProps, {
    modalShowAddOrRemovePostTagsFromPostsAction: modalShowAddOrRemovePostTagsFromPosts,
})(PostsSidebar);
