import React from 'react';
import {
    getTypeAndId,
    dashboardReportType,
    dashboardShareLinkType
} from 'src/utils/reportOverviews';
import ReportStatusLabel from 'src/components/labels/ReportStatusLabel';
import PropTypes from 'prop-types';
import DashboardShareLinkStatusWrapper from 'src/components/views/reports/DashboardShareLinkStatusWrapper';

const ReportOverviewState = (props) => {
    const { reportOverviewId } = props;
    const { id, type } = getTypeAndId(reportOverviewId);

    if (type === dashboardReportType) {
        return <ReportStatusLabel dashboardReportId={id} />;
    }

    if (type === dashboardShareLinkType) {
        return <DashboardShareLinkStatusWrapper dashboardShareLinkId={id} />;
    }
    return null;
};

ReportOverviewState.propTypes = {
    reportOverviewId: PropTypes.string.isRequired
};

export default ReportOverviewState;
