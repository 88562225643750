import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { billingLinkGenerateRequest } from 'src/actions/adminSettings';
import { makeSelectIsBillingLinkGenerating } from 'src/selectors/adminSettings';

const GenerateBillingLinkButton = (props) => {
    const { billingLinkGenerateRequestAction, isBillingLinkGenerating } = props;
    return (
        <Button
          label="Change credit card details"
          onClick={() => { billingLinkGenerateRequestAction(); }}
          action
          loading={isBillingLinkGenerating}
        />
    );
};

GenerateBillingLinkButton.propTypes = {
    billingLinkGenerateRequestAction: PropTypes.func.isRequired,
    isBillingLinkGenerating: PropTypes.bool.isRequired
};

const makeMapStateToProps = () => {
    const selectIsBillingLinkGenerating = makeSelectIsBillingLinkGenerating();
    return (state) => ({
        isBillingLinkGenerating: selectIsBillingLinkGenerating(state)
    });
};

export default connect(makeMapStateToProps, {
    billingLinkGenerateRequestAction: billingLinkGenerateRequest
})(GenerateBillingLinkButton);
