import React from 'react';
import { dashboardReportType, dashboardShareLinkType, getTypeAndId } from 'src/utils/reportOverviews';
import DashboardReportOptionsPopover from 'src/components/popovers/DashboardReportOptionsPopover';
import DashboardShareLinkOptionsPopover from 'src/components/popovers/DashboardShareLinkOptionsPopover';
import PropTypes from 'prop-types';
import _omit from 'lodash/omit';

const ReportOverviewOptionsPopover = (props) => {
    const { reportOverviewId } = props;
    const { id, type } = getTypeAndId(reportOverviewId);

    const cleanedProps = _omit(props, ['id', 'type']);
    if (type === dashboardReportType) {
        return <DashboardReportOptionsPopover dashboardReportId={id} {...cleanedProps} />;
    }

    if (type === dashboardShareLinkType) {
        return <DashboardShareLinkOptionsPopover dashboardShareLinkId={id} {...cleanedProps} />;
    }

    return null;
};

ReportOverviewOptionsPopover.propTypes = {
    reportOverviewId: PropTypes.string.isRequired
};

export default ReportOverviewOptionsPopover;
