import React from 'react';
import IntegrationEmptyState from 'src/components/views/IntegrationEmptyState';
import emptyAPIEmpty from 'src/resources/illustrations/API_empty.png';

const APIEmptyState = () => (
    <IntegrationEmptyState
      title="Access quintly’s extensive and powerful API"
      message="Gain full data flexibility and security with our carefully maintained API. Integrate data from your social platforms with your favourite analytics tools, business intelligence systems or data warehouses. Upgrade to access the API by contacting our customer success team."
      image={emptyAPIEmpty}
    />
);

export default APIEmptyState;
