import DiscoverStart from 'src/components/views/discover/base/DiscoverStart';
import PropTypes from 'prop-types';
import React from 'react';
import withMostUsedNetworks from 'src/components/views/withMostUsedNetworks';

const getRecommendationIdentifiers = (mostUsedNetwork, mostUsedNetworkTagId) => {
    const recommendations = [
        {
            type: 'recommendation',
            identifier: 'newestCustomMetrics',
            title: 'Recently created metrics',
        }
    ];

    if (mostUsedNetwork) {
        recommendations.push({
            type: 'search',
            identifier: `mostUsedCustomMetrics-${mostUsedNetworkTagId}`,
            title: `Most used custom metrics for ${mostUsedNetwork}`,
            searchParams: {
                filterBy: 'customMetrics',
                orderBy: 'mostUsed',
                tagIds: [mostUsedNetworkTagId],
                limit: 6
            }
        });
    }

    return recommendations;
};

const DiscoverStartCustomMetrics = (props) => {
    const { mostUsedNetwork, mostUsedNetworkTagId } = props;
    const recommendationIdentifiers = getRecommendationIdentifiers(mostUsedNetwork, mostUsedNetworkTagId);
    return (
        <DiscoverStart recommendationIdentifiers={recommendationIdentifiers} filterBy="customMetrics" />
    );
};

DiscoverStartCustomMetrics.propTypes = {
    mostUsedNetwork: PropTypes.string.isRequired,
    mostUsedNetworkTagId: PropTypes.string.isRequired
};

export default withMostUsedNetworks(DiscoverStartCustomMetrics);
