import React from 'react';
import DetailText from 'src/components/views/DetailText';
import styles from 'src/stylesheets/views/integrations/dataPushTaskDetailPage.scss';
import * as customPropTypes from 'src/customPropTypes';
import { getDestinationTypeText } from 'src/utils/dataPushTasks';

const AwsS3DataPushTaskDestination = (props) => {
    const { awsS3DataPushTaskDestination } = props;
    const {
        destinationType,
        awsS3Region,
        destinationLocation,
        awsS3FileFormat
    } = awsS3DataPushTaskDestination;
    const destinationTypeText = getDestinationTypeText(destinationType);
    return (
        <div>
            <DetailText label="Type" className={styles.detailText}>{destinationTypeText}</DetailText>
            <DetailText label="Region" className={styles.detailText}>{awsS3Region}</DetailText>
            <DetailText label="Location" className={styles.detailText}>{destinationLocation}</DetailText>
            <DetailText label="File format">{awsS3FileFormat.toUpperCase()}</DetailText>
        </div>
    );
};

AwsS3DataPushTaskDestination.propTypes = {
    awsS3DataPushTaskDestination: customPropTypes.awsS3DataPushTaskDestination.isRequired
};

export default AwsS3DataPushTaskDestination;
