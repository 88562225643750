import classnames from 'classnames';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/navigations/mainNavLabel.scss';

const MainNavLabel = (props) => {
    const { icon, label, className } = props;
    return (
        <div className={classnames(styles.wrapper, className)}>
            <IcomoonIcon icon={icon} className={styles.icon} />
            <div className={styles.label}>
                {label}
            </div>
        </div>
    );
};

MainNavLabel.propTypes = {
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    className: PropTypes.string
};

MainNavLabel.defaultProps = {
    className: ''
};

export default MainNavLabel;
