import React, { memo } from 'react';
import styles from 'src/stylesheets/views/postTags/postTagging/postListItem.scss';
import * as customPropTypes from 'src/customPropTypes';
import NetworkIcon from 'src/components/NetworkIcon';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeSelectPostById } from 'src/selectors/posts';
import { getTruncatedTextWithEllipsis, concatenatePostContentItems } from 'src/utils/string';
import memoizeOne from 'memoize-one';
import { modalShowPostDetailInPostTagging } from 'src/actions/overlays';
import PostImage from 'src/components/views/postTags/postTagging/PostImage';

const memoizedGetTruncatedTextWithEllipsis = memoizeOne(getTruncatedTextWithEllipsis);

const PostListItem = ({ post, modalShowPostDetailAction }) => {
    const {
        id, contentItems, image, platformType
    } = post;

    const content = concatenatePostContentItems(contentItems);

    return (
        <div className={styles.wrapper}>
            <div className={styles.left} onClick={() => { modalShowPostDetailAction(id); }}>
                <div className={styles.imgWrapper}>
                    <PostImage url={image} width={40} height={40} />
                </div>
                <div className={styles.message}>
                    {
                        content
                        && memoizedGetTruncatedTextWithEllipsis(content, 30)
                    }
                    {
                        !content
                        && <span className={styles.noMessage}>This post contains no text</span>
                    }
                </div>
            </div>
            <div className={styles.right}>
                <NetworkIcon platformType={platformType} className={styles.icon} />
            </div>
        </div>
    );
};

PostListItem.propTypes = {
    id: PropTypes.string.isRequired,
    modalShowPostDetailAction: PropTypes.func.isRequired,
    post: customPropTypes.post.isRequired
};

const makeMapStateToProps = () => {
    const selectPostById = makeSelectPostById();
    return (state, ownProps) => ({
        post: selectPostById(state, ownProps.id)
    });
};

export default memo(connect(makeMapStateToProps, {
    modalShowPostDetailAction: modalShowPostDetailInPostTagging
})(PostListItem));
