import { createSelector } from 'reselect';
import _get from 'lodash/get';
import {
    createShallowEqualSelector,
    createDeepEqualSelector,
    defaultLoadingState,
    makePropertyExtractor,
    makeStringSortDirSorter
} from 'src/selectors/utils';
import { makeSelectQueryFromFilterSelectorByContext } from 'src/selectors/filterSelectors';
import { getActivePostTagIds } from 'src/selectors/postTags';
import { makeSelectPostTagRuleById } from 'src/selectors/postTagRules';
import _has from 'lodash/has';

export const getIdsByPostTag = (state) => state.posts.idsByPostTag;
export const getPostsFromStore = (state) => state.posts.byId;
export const getPostsSearchInput = (state) => state.posts.postsSearchByFilter.input;
export const getResultIdsByPage = (state) => state.posts.postsSearchByFilter.result.idsByPage;
export const getCurrentPageNumber = (state) => state.posts.postsSearchByFilter.result.pageNumber;
export const getResultTotalRowCount = (state) => state.posts.postsSearchByFilter.result.totalRowCount;
export const getShouldTagAllPosts = (state) => state.posts.postsSearchByFilter.shouldTagAllPosts;
export const getAsyncStates = (state) => state.posts.asyncStates;
export const getWarnings = (state) => state.posts.postsSearchByFilter.result.warnings;

export const selectPostIdsByCurrentPage = createSelector(
    [
        getResultIdsByPage,
        getCurrentPageNumber,
    ], (idsByPage, pageNumber) => idsByPage[pageNumber] || []
);

export const makeSelectAllPostIdsAcrossPages = () => createSelector(
    [
        getResultIdsByPage
    ],
    (idsByPage) => {
        let allIds = [];
        Object.values(idsByPage).forEach((ids) => {
            allIds = [...allIds, ...ids];
        });
        return allIds;
    }
);

export const makeSelectPostById = () => createSelector(
    [
        getPostsFromStore,
        (_, id) => id,
    ],
    (posts, id) => posts[id] || false
);

export const makeSelectPostByIds = () => createSelector(
    [
        getPostsFromStore,
        (_, ids) => ids,
    ],
    (posts, ids) => {
        const result = [];
        ids.forEach((id) => {
            if (posts[id]) {
                result.push(posts[id]);
            }
        });
        return result;
    }
);

export const makeSelectPostIdsByCreateTime = () => {
    const selectPostByIds = makeSelectPostByIds();
    const idsExtractor = makePropertyExtractor();
    const stringSortDirSorter = makeStringSortDirSorter();

    return createSelector(
        [
            selectPostByIds,
            getPostsSearchInput
        ],
        (posts, searchInput) => {
            const { sortDir } = searchInput.parameters;
            const sorted = stringSortDirSorter(posts, 'time', sortDir);
            return idsExtractor(sorted);
        }
    );
};

export const makeSelectPostSearchState = () => createSelector(
    [
        getAsyncStates
    ],
    (asyncStates) => _get(asyncStates, ['request', 'state'], defaultLoadingState)
);

export const makeSelectPostTaggingRoute = () => {
    const selectQueryFromFilterSelectorByContext = makeSelectQueryFromFilterSelectorByContext();
    return createSelector(
        [
            selectQueryFromFilterSelectorByContext,
            getPostsSearchInput
        ],
        (query, input) => {
            const { parameters } = input;
            const { sortDir } = parameters;
            const newQuery = Object.assign({}, query, { sortDir });
            return Object.assign({}, { pathname: '/posts' }, { query: newQuery });
        }
    );
};

export const makeSelectPostDetailGetState = () => createSelector(
    [
        getAsyncStates,
        (_, postId) => postId
    ],
    (asyncStates, postId) => _get(asyncStates, ['getPostInfo', postId], defaultLoadingState)
);

export const makeSelectPostTagIdsByPostIdsWithIndeterminatedPostTags = () => {
    const selectPostByIds = makeSelectPostByIds();
    return createShallowEqualSelector(
        [
            getActivePostTagIds,
            selectPostByIds,
            (_, __, shouldTagAllPosts) => shouldTagAllPosts,
        ],
        (postTagIds, posts, shouldTagAllPosts) => {
            const postPostTagsPerPost = posts.map((post) => post.postPostTags);
            const selectedPostTagIds = [];
            let indeterminatePostTagIds = [];
            const postTagCounts = {};
            if (shouldTagAllPosts) {
                indeterminatePostTagIds = postTagIds.map((postTagId) => postTagId);
                return {
                    selectedPostTagIds: [],
                    indeterminatePostTagIds
                };
            }
            postPostTagsPerPost.forEach((postPostTags) => {
                Object.keys(postPostTags).forEach((postTagId) => {
                    if (_has(postTagCounts, postTagId)) {
                        postTagCounts[postTagId] += 1;
                    } else {
                        postTagCounts[postTagId] = 1;
                    }
                });
            });

            Object.keys(postTagCounts).forEach((postTagId) => {
                const postTagIdCount = postTagCounts[postTagId];
                if (postTagIdCount === postPostTagsPerPost.length) {
                    selectedPostTagIds.push(postTagId);
                } else {
                    indeterminatePostTagIds.push(postTagId);
                }
            });

            return {
                selectedPostTagIds,
                indeterminatePostTagIds
            };
        }
    );
};

const getPrevAndNextPostId = (postIds, postId) => {
    const prevAndNextPostIds = {
        prevPostId: undefined,
        nextPostId: undefined
    };
    const index = postIds.indexOf(postId);
    if (index < 0) {
        return prevAndNextPostIds;
    }
    if (index > 0) {
        prevAndNextPostIds.prevPostId = postIds[index - 1];
    }
    if (index < postIds.length - 1) {
        prevAndNextPostIds.nextPostId = postIds[index + 1];
    }
    return prevAndNextPostIds;
};

export const makeSelectPostTaggingPrevAndNextPostIds = () => createSelector(
    [
        selectPostIdsByCurrentPage,
        (_, postId) => postId
    ],
    (postIds, postId) => getPrevAndNextPostId(postIds, postId)
);

export const makeSelectDerivedPostPostTagsByPostId = () => {
    const selectPostTagRuleById = makeSelectPostTagRuleById();
    return createDeepEqualSelector(
        [
            (state, postPostTags, postTags) => {
                const newArray = [];
                postTags.forEach(({ id, name }) => {
                    const currentPostPostTag = postPostTags[id];
                    const { tagTime, postTagRuleId, tagUserName } = currentPostPostTag;
                    newArray.push({
                        postTagId: id,
                        postTagName: name,
                        postTagRule: selectPostTagRuleById(state, postTagRuleId),
                        postTagRuleId,
                        tagUserName,
                        tagTime
                    });
                });
                return newArray;
            }
        ],
        (postPostTags) => postPostTags
    );
};

export const selectPostIdsByPostTagId = createSelector(
    [
        getIdsByPostTag,
        (_, postTagId) => postTagId
    ],
    (resultIdsByPostTag, postTagId) => resultIdsByPostTag[postTagId] || []
);

export const makeSelectPostSearchStateByPostTagId = () => createSelector(
    [
        getAsyncStates,
        (_, postTagId) => postTagId
    ],
    (asyncStates, postTagId) => _get(asyncStates, ['search', postTagId], defaultLoadingState)
);

export const makeSelectPostTagManagerPrevAndNextPostIdsByPostTagId = () => createSelector(
    [
        selectPostIdsByPostTagId,
        (_, __, postId) => postId
    ],
    (postIds, postId) => getPrevAndNextPostId(postIds, postId)
);

export const selectAddRemovePostTagsState = createSelector(
    [
        getAsyncStates
    ],
    (asyncStates) => _get(asyncStates, ['addRemovePostTags', 'state'], defaultLoadingState)
);
