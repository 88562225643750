import * as customPropTypes from 'src/customPropTypes';
import Body from 'src/components/Body';
import { connect } from 'react-redux';
import DashboardGrid from 'src/components/DashboardGrid';
import Footer from 'src/components/Footer';
import React from 'react';
import SampleDashboardGridTile from 'src/components/sampleDashboard/SampleDashboardGridTile';
import ScrollView from 'src/components/ScrollView';
import { selectDashboardById } from 'src/selectors/dashboards';
import SimplePageFrame from 'src/components/SimplePageFrame';
import ContentWidthContext from 'src/components/context/ContentWidthContext';

function generateGridTile(dashboardMetric, currentBreakpoint, isAllowedToChangeLayout) {
    const { id } = dashboardMetric;
    return (
        <SampleDashboardGridTile
          key={id}
          dashboardMetricId={id}
          breakpoint={currentBreakpoint}
          isAllowedToChangeLayout={isAllowedToChangeLayout}
        />
    );
}

const SampleDashboard = (props) => {
    const { dashboard } = props;

    let innerContent = null;
    if (!dashboard) {
        innerContent = (
            <SimplePageFrame>
                Dashboard not found
            </SimplePageFrame>
        );
    } else {
        innerContent = (
            <ContentWidthContext.Consumer>
                {
                    (contentWidth) => (
                        <DashboardGrid
                          dashboard={dashboard}
                          width={contentWidth}
                          generateGridTile={generateGridTile}
                          staticGrid
                          isAllowedToChangeLayout={false}
                        />
                    )
                }
            </ContentWidthContext.Consumer>
        );
    }

    return (
        <ScrollView>
            <Body>
                {innerContent}
            </Body>
            <Footer />
        </ScrollView>
    );
};

SampleDashboard.propTypes = {
    dashboard: customPropTypes.dashboard.isRequired
};

const mapStateToProps = (state, ownProps) => {
    const { params } = ownProps;
    const dashboard = selectDashboardById(state, params.dashboardId);
    return {
        dashboard
    };
};

export default connect(mapStateToProps)(SampleDashboard);
