import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import { makeSelectDataPushTaskDestinationById } from 'src/selectors/dataPushTaskDestination';
import { getDestinationTypeText } from 'src/utils/dataPushTasks';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/views/integrations/dataPushTaskTable.scss';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import DataPushTaskPlatformLogo from 'src/components/views/integrations/DataPushTaskPlatformLogo';
import ListTableCell from 'src/components/listTable/ListTableCell';

const DataPushTaskDestinationCells = (props) => {
    const { dataPushTaskDestination, onClick, isHealthy } = props;
    const {
        destinationType,
        destinationLocation
    } = dataPushTaskDestination;

    if (dataPushTaskDestination !== false) {
        const destinationTypeText = getDestinationTypeText(destinationType);
        return (
            <>
                <ListTableCell markUnhealthy={!isHealthy} onClick={onClick}>
                    <div className={styles.flexDiv}>
                        <DataPushTaskPlatformLogo className={styles.platformImg} platformType={destinationType} />
                        <div className={styles.ellipsis}>
                            <DivWithTooltip tooltip={destinationTypeText}>
                                {destinationTypeText}
                            </DivWithTooltip>
                        </div>
                    </div>
                </ListTableCell>
                <ListTableCell onClick={onClick}>
                    {
                        destinationLocation && (
                            <DivWithTooltip tooltip={destinationLocation}>
                                {destinationLocation}
                            </DivWithTooltip>
                        )
                    }
                </ListTableCell>
            </>
        );
    }

    return (
        <>
            <ListTableCell markUnhealthy onClick={onClick}>
                <div>n/a</div>
            </ListTableCell>
            <ListTableCell onClick={onClick}>
                <div>n/a</div>
            </ListTableCell>
        </>
    );
};

DataPushTaskDestinationCells.propTypes = {
    dataPushTaskDestination: customPropTypes.dataPushTaskDestination.isRequired,
    dataPushTaskDestinationId: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isHealthy: PropTypes.bool.isRequired
};

const makeMapStateToProps = () => {
    const selectDataPushTaskDestinationById = makeSelectDataPushTaskDestinationById();
    return (state, ownProps) => {
        const { dataPushTaskDestinationId } = ownProps;
        return {
            dataPushTaskDestination: selectDataPushTaskDestinationById(state, dataPushTaskDestinationId)
        };
    };
};

export default connect(makeMapStateToProps)(DataPushTaskDestinationCells);
