import Column from 'src/components/header/layout/Column';
import Header from 'src/components/header/layout/Header';
import PageHeadline from 'src/components/header/layout/PageHeadline';
import PropTypes from 'prop-types';
import React from 'react';
import TopRow from 'src/components/header/layout/TopRow';

const SettingsHeader = ({ headline }) => (
    <Header>
        <TopRow>
            <Column flexGrow>
                <PageHeadline>{headline}</PageHeadline>
            </Column>
        </TopRow>
    </Header>
);

SettingsHeader.propTypes = {
    headline: PropTypes.string.isRequired
};

export default SettingsHeader;
