import React from 'react';
import TopNav from 'src/components/navigations/TopNav';
import TopNavTextLink from 'src/components/buttons/TopNavTextLink';
import { profilesRoute, authenticationRoute, adAccountsRoute } from 'src/routePaths';

const AccountsNav = () => (
    <TopNav>
        <TopNavTextLink to={profilesRoute}>Profiles</TopNavTextLink>
        <TopNavTextLink to={adAccountsRoute}>Ad accounts</TopNavTextLink>
        <TopNavTextLink to={authenticationRoute}>Authentications</TopNavTextLink>
    </TopNav>
);

export default AccountsNav;
