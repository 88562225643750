import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import styles from 'src/stylesheets/accountList.scss';
import PropTypes from 'prop-types';

const AccountListItem = ({ children }) => <div className={styles.listItem}>{children}</div>;

AccountListItem.propTypes = {
    children: customPropTypes.children.isRequired
};

const LeftColumn = ({ children }) => <div className={styles.left}>{children}</div>;
LeftColumn.propTypes = {
    children: customPropTypes.children.isRequired
};

const RightColumn = ({ children }) => <div className={styles.right}>{children}</div>;
RightColumn.propTypes = {
    children: customPropTypes.children.isRequired
};

const CheckBoxWrapper = ({ children }) => <div className={styles.checkboxWrapper}>{children}</div>;
CheckBoxWrapper.propTypes = {
    children: customPropTypes.children.isRequired
};

const AccountInfoWrapper = ({ children, onClick }) => <div onClick={onClick} className={styles.accountInfoWrapper}>{children}</div>;

AccountInfoWrapper.propTypes = {
    children: customPropTypes.children.isRequired,
    onClick: PropTypes.func.isRequired
};

const Analysis = ({ children }) => <div className={styles.analysis}>{children}</div>;
Analysis.propTypes = {
    children: customPropTypes.children.isRequired
};

const Groups = ({ children }) => <div className={styles.groups}>{children}</div>;
Groups.propTypes = {
    children: customPropTypes.children.isRequired
};

const ButtonWrapper = ({ children }) => <div className={styles.buttonWrapper}>{children}</div>;
ButtonWrapper.propTypes = {
    children: customPropTypes.children.isRequired
};

AccountListItem.LeftColumn = LeftColumn;
AccountListItem.RightColumn = RightColumn;
AccountListItem.CheckBoxWrapper = CheckBoxWrapper;
AccountListItem.AccountInfoWrapper = AccountInfoWrapper;
AccountListItem.Analysis = Analysis;
AccountListItem.Groups = Groups;
AccountListItem.ButtonWrapper = ButtonWrapper;

export default AccountListItem;
