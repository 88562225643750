import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { updateLastMainMenuLocation } from 'src/actions/lastMainMenuLocation';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';
import _omit from 'lodash/omit';
import {
    ANALYSIS, ACCOUNTS, USER_PERSONAL, USER_ADMIN, INTEGRATION, POSTS, DATA_SOURCES, NOTIFICATION
} from 'src/utils/routes';
import { withRouter } from 'react-router';

const withLastMainMenuLocationUpdater = (WrappedComponent) => {
    const WithLastMainMenuLocationUpdater = (props) => {
        const { location, context, updateLastMainMenuLocationAction } = props;
        useEffect(() => {
            updateLastMainMenuLocationAction(context, location);
        }, [location]);
        const cleanedProps = _omit(props, ['context', 'updateLastMainMenuLocationAction', 'location']);
        return <WrappedComponent {...cleanedProps} />;
    };

    WithLastMainMenuLocationUpdater.propTypes = {
        context: PropTypes.oneOf([ANALYSIS, ACCOUNTS, USER_PERSONAL, USER_ADMIN, INTEGRATION, POSTS, DATA_SOURCES, NOTIFICATION]).isRequired,
        location: customPropTypes.location.isRequired,
        updateLastMainMenuLocationAction: PropTypes.func.isRequired
    };

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    WithLastMainMenuLocationUpdater.displayName = `withLastMainMenuLocationUpdater(${wrappedComponentName})`;

    return withRouter(connect(null, {
        updateLastMainMenuLocationAction: updateLastMainMenuLocation
    })(WithLastMainMenuLocationUpdater));
};

export default withLastMainMenuLocationUpdater;
