import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import styles from 'src/stylesheets/views/analysis/visualizationType.scss';
import IcomoonIcon from 'src/components/IcomoonIcon';
import withTooltip from 'src/components/withToolTip';
import _get from 'lodash/get';
import { visualizationTagIconMapping } from 'src/selectors/tags';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const IcomoonIconWithTooltip = withTooltip(IcomoonIcon);

const VisualizationTag = (props) => {
    const { tag, big } = props;
    return (
        <IcomoonIconWithTooltip
          key={tag.id}
          className={classnames({ [styles.small]: !big, [styles.big]: big })}
          icon={_get(visualizationTagIconMapping, tag.id)}
          tooltip={tag.name}
        />
    );
};

VisualizationTag.propTypes = {
    tag: customPropTypes.tag.isRequired,
    big: PropTypes.bool
};

VisualizationTag.defaultProps = {
    big: false
};

export default VisualizationTag;
