import * as customPropTypes from 'src/customPropTypes';
import { createSingleServerRequestForMetric } from 'src/requestHandling/metricDataRequests';
import MetricRequestContext from 'src/components/context/MetricRequestContext';
import PropTypes from 'prop-types';
import React from 'react';
import memoizeOne from 'memoize-one';

const memoizedCreateSingleServerRequestForMetric = memoizeOne(createSingleServerRequestForMetric);

const MetricRequestProvider = (props) => {
    const {
        children, metric, selectedProfilesOrGroups, selectedDate, format, selectedPostText, selectedPostTag, selectedPostTextExclude, selectedAdCampaign
    } = props;
    const identifier = `metric-${metric.id}`;
    const serverRequest = memoizedCreateSingleServerRequestForMetric(
        identifier, selectedProfilesOrGroups, selectedDate, selectedPostText, selectedPostTextExclude, selectedPostTag, selectedAdCampaign, metric, format
    );
    return (
        <MetricRequestContext.Provider value={serverRequest}>
            { children(serverRequest) }
        </MetricRequestContext.Provider>
    );
};

MetricRequestProvider.propTypes = {
    children: PropTypes.func.isRequired,
    metric: customPropTypes.metric.isRequired,
    selectedProfilesOrGroups: customPropTypes.profileFilter.isRequired,
    selectedDate: customPropTypes.selectedDate.isRequired,
    format: customPropTypes.format.isRequired,
    selectedPostText: customPropTypes.postTextFilter.isRequired,
    selectedPostTextExclude: customPropTypes.postTextExcludeFilter.isRequired,
    selectedPostTag: customPropTypes.postTagFilter.isRequired,
    selectedAdCampaign: customPropTypes.adCampaignFilter.isRequired
};

export default MetricRequestProvider;
