import React from 'react';
import styles from 'src/stylesheets/loadingIndicators/emptyMetricBuilderForm.scss';
import ScrollView from 'src/components/ScrollView';
import ContentWithSidebar from 'src/components/views/ContentWithSidebar';
import ToggleableSidebar from 'src/components/sidebar/ToggleableSidebar';

const EmptyMetricBuilderForm = () => {
    const sidebar = (
        <ToggleableSidebar width={400} sideBarName="metricBuilder">
            <div className={styles.sidebar} />
        </ToggleableSidebar>
    );
    return (
        <ContentWithSidebar sidebar={sidebar}>
            <ScrollView dataViewName="metric-builder" flex>
                <div className={styles.wrapper}>
                    <div className={styles.header} />
                    <div className={styles.subHeader} />
                    <div className={styles.body}>
                        <div className={styles.left} />
                        <div className={styles.right} />
                    </div>
                    <div className={styles.footer} />
                </div>
            </ScrollView>
        </ContentWithSidebar>
    );
};

export default EmptyMetricBuilderForm;
