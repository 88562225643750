import {
    EXPORT_FILE_DELETE,
    EXPORT_FILE_ERROR,
    EXPORT_FILE_REQUEST,
    EXPORT_FILE_SUCCESS,
    SET_REPORT_PREVIEW_PRENDING,
    UNSET_REPORT_PREVIEW_PRENDING
} from 'src/actions/exports';
import _includes from 'lodash/includes';
import _omit from 'lodash/omit';
import { combineReducers } from 'redux';

export function blobs(state = {}, action) {
    const { payload, type } = action;
    switch (type) {
        case EXPORT_FILE_SUCCESS: {
            const { blob, fileExportIdentifier } = payload;
            const elementExists = _includes(state, state[fileExportIdentifier]);
            if (elementExists) {
                return Object.assign({}, state, { [fileExportIdentifier]: { isLoading: false, data: blob, error: null } });
            }
            return state;
        }
        case EXPORT_FILE_REQUEST: {
            const { fileExportIdentifier } = payload;
            return Object.assign({}, state, { [fileExportIdentifier]: { isLoading: true, data: null, error: null } });
        }

        case EXPORT_FILE_ERROR: {
            const { error, fileExportIdentifier } = payload;
            const elementExists = _includes(state, state[fileExportIdentifier]);
            if (elementExists) {
                return Object.assign({}, state, { [fileExportIdentifier]: { isLoading: false, data: null, error } });
            }
            return state;
        }
        case EXPORT_FILE_DELETE: {
            const { fileExportIdentifier } = payload;
            return _omit(state, [fileExportIdentifier]);
        }
        default:
            return state;
    }
}

function isReportPreviewPending(state = false, action) {
    const { type } = action;
    switch (type) {
        case SET_REPORT_PREVIEW_PRENDING:
            return true;
        case UNSET_REPORT_PREVIEW_PRENDING:
            return false;
        default:
            return state;
    }
}

const meta = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case EXPORT_FILE_REQUEST: {
            const { fileExportIdentifier, debugInfo } = payload;
            return Object.assign({}, state, { [fileExportIdentifier]: { debugInfo } });
        }
        case EXPORT_FILE_DELETE: {
            const { fileExportIdentifier } = payload;
            return _omit(state, [fileExportIdentifier]);
        }
        default:
            return state;
    }
};

export const exports = combineReducers({
    blobs,
    meta,
    isReportPreviewPending
});
