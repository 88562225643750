import * as customPropTypes from 'src/customPropTypes';
import BottomRow from 'src/components/header/layout/BottomRow';
import Column from 'src/components/header/layout/Column';
import { connect } from 'react-redux';
import HeadlineSelectButton from 'src/components/buttons/HeadlineSelectButton';
import DiscoverFilterPopover from 'src/components/popovers/DiscoverFilterPopover';
import { getTextsByFilter } from 'src/utils/discoverUtiles';
import Header from 'src/components/header/layout/Header';
import PageDetail from 'src/components/header/layout/PageDetail';
import PageHeadline from 'src/components/header/layout/PageHeadline';
import React from 'react';
import { selectMetricSearchFilterBy } from 'src/selectors/metricSearch';
import TopRow from 'src/components/header/layout/TopRow';
import withPopover from 'src/components/withPopover';
import styles from 'src/stylesheets/headers/discoverDashboardAndMetricHeader.scss';

const DiscoverFilterButtonWithPopover = withPopover(HeadlineSelectButton);

const DiscoverHeader = (props) => {
    const {
        filterBy,
    } = props;

    const filterByOption = filterBy || 'dashboards';
    const texts = getTextsByFilter(filterByOption);

    return (
        <Header>
            <TopRow>
                <Column flexGrow className={styles.headline}>
                    <PageHeadline className={styles.discover}>Discover</PageHeadline>
                    <DiscoverFilterButtonWithPopover
                      label={texts.headline}
                      overlay={<DiscoverFilterPopover />}
                      placement="bottom-end"
                    />
                </Column>
            </TopRow>
            <BottomRow>
                <Column flexGrow>
                    <PageDetail detail={texts.description} />
                </Column>
            </BottomRow>
        </Header>
    );
};

DiscoverHeader.propTypes = {
    filterBy: customPropTypes.filterBy.isRequired
};

const mapStateToProps = (state) => ({
    filterBy: selectMetricSearchFilterBy(state)
});

export default connect(mapStateToProps)(DiscoverHeader);
