import ExpandableImage from 'src/components/ExpandableImage';
import PropTypes from 'prop-types';
import React from 'react';

const DashboardDetailScreenShot = (props) => {
    const { name, id } = props;
    return (
        <ExpandableImage
          minHeight={400}
          src={`https://s3.amazonaws.com/quintly-discover-images/dashboards/screenshots/${id}_large.png`}
          alt={name}
        />
    );
};

DashboardDetailScreenShot.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
};

export default DashboardDetailScreenShot;
