import Body from 'src/components/Body';
import DashboardAndMetricLoadingHeader from 'src/components/loadingIndicators/DashboardAndMetricLoadingHeader';
import EmptyDiv from 'src/components/loadingIndicators/EmptyDiv';
import React from 'react';
import ScrollView from 'src/components/ScrollView';
import styles from 'src/stylesheets/views/dashboardAndMetricDetail.scss';

const MetricDetailPageLoading = () => (
    <ScrollView dataViewName="dashboard-detail-page-loading">
        <DashboardAndMetricLoadingHeader isMetricPage />
        <Body>
            <div className={styles.wrapper}>
                <div className={styles.leftSection}>
                    <EmptyDiv height={800} />
                </div>
                <div className={styles.rightSection}>
                    <div className={styles.loadingSection}>
                        <EmptyDiv height={500} />
                    </div>
                    <EmptyDiv height={500} />
                </div>
            </div>
        </Body>
    </ScrollView>
);

export default MetricDetailPageLoading;
