import _includes from 'lodash/includes';
import _findKey from 'lodash/findKey';

const metricFilterRoutes = {
    metrics: 'metrics',
    publicMetrics: 'public-metrics',
    customMetrics: 'custom-metrics',
};

export const metricFilters = Object.keys(metricFilterRoutes);

export const convertFilterByToRoute = (filterBy) => (metricFilterRoutes[filterBy] ? metricFilterRoutes[filterBy] : filterBy);

export const hasMetricRoutes = (route) => _includes(metricFilterRoutes, route);
export const isCustomMetricFilter = (filterBy) => filterBy === metricFilters[2];
export const hasMetricFilters = (filterBy) => _includes(metricFilters, filterBy);
export const convertRouteToFilter = (route) => _findKey(metricFilterRoutes, (key) => key === route) || 'metrics';

export const getTextsByFilter = (filterBy = 'dashboards') => {
    if (filterBy === 'dashboards') {
        return {
            headline: 'dashboards',
            description: 'A dashboard is a personalized overview of all your metrics. It also forms the base of automatic reports.',
            placeholder: 'Search dashboard templates...'
        };
    }
    if (hasMetricFilters(filterBy)) {
        return {
            headline: 'metrics',
            description: 'A metric is used to track a specific performance. You can preview data from a single metric in discover '
                + 'or add multiple metrics to a customized dashboard.',
            placeholder: 'Search metrics...'
        };
    }
    return {};
};
