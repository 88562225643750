import Popover from 'src/components/popovers/layout/Popover';
import React from 'react';
import { connect } from 'react-redux';
import { getClientLimits } from 'src/selectors/client';
import * as customPropTypes from 'src/customPropTypes';
import ProfileLimitsLinks from 'src/components/profileLimits/ProfileLimitsLinks';
import styles from 'src/stylesheets/popovers/clientLimitPopover.scss';

const ClientLimitPopover = (props) => {
    const { clientLimit } = props;
    const { freeLimits } = clientLimit;
    return (
        <Popover id="clientLimitPopover" width={300}>
            <div className={styles.wrapper}>
                <div className={styles.info}>
                    <div>Available limits to be assigned: {freeLimits.profiles}</div>
                </div>
                <ProfileLimitsLinks />
            </div>
        </Popover>
    );
};

ClientLimitPopover.propTypes = {
    clientLimit: customPropTypes.clientLimits.isRequired
};

const mapStateToProps = (state) => ({
    clientLimit: getClientLimits(state)
});

export default connect(mapStateToProps)(ClientLimitPopover);
