import _parseInt from 'lodash/parseInt';
import {
    call, put, takeEvery, all
} from 'redux-saga/effects';
import { ACCOUNT_ENTITIES_GET_REQUEST, accountEntitiesGetError, accountEntitiesGetSuccess } from 'src/actions/accounts';
import { authUserRemoveSuccess } from 'src/actions/authUsers';
import { FACEBOOK_AUTH_USER_CONNECT_SUCCESS, FACEBOOK_AUTH_USER_FORCE_CONNECT_SUCCESS } from 'src/actions/graphAuthUsers';
import { LINKED_IN_AUTH_USER_CONNECT_SUCCESS, LINKED_IN_AUTH_USER_FORCE_CONNECT_SUCCESS } from 'src/actions/linkedInAuthUsers';
import { SNAPCHAT_AUTH_USER_CONNECT_SUCCESS, SNAPCHAT_AUTH_USER_FORCE_CONNECT_SUCCESS } from 'src/actions/snapchatAuthUsers';
import { TIKTOK_AUTH_USER_CONNECT_SUCCESS } from 'src/actions/tiktokAuthUsers';
import { TWITTER_AUTH_USER_CONNECT_SUCCESS } from 'src/actions/twitterAuthUsers';
import { YOUTUBE_AUTH_USER_CONNECT_SUCCESS } from 'src/actions/youtubeAuthUsers';
import {
    parseAccountAuthenticationEntities, parseAdAccounts, parseGroups, parseProfiles
} from 'src/parsers';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import { chunkArray } from 'src/utils/array';
import { reportError } from 'src/utils/reportError';

export function* accountEntitiesGetRequest() {
    try {
        const serverRequest = createServerRequest();
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/get-account-domain-entities');
        if (response) {
            const { accountDomainEntities } = response.jsonData;
            yield put(accountEntitiesGetSuccess(
                parseAccountAuthenticationEntities(accountDomainEntities),
                parseProfiles(accountDomainEntities.profiles),
                parseAdAccounts(accountDomainEntities.adAccounts),
                parseGroups(accountDomainEntities.groups)
            ));
        }

        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(accountEntitiesGetError(applicationError));
    }
}

export default function* accountsSagas() {
    yield takeEvery([
        ACCOUNT_ENTITIES_GET_REQUEST,
        authUserRemoveSuccess.type,
        FACEBOOK_AUTH_USER_CONNECT_SUCCESS,
        FACEBOOK_AUTH_USER_FORCE_CONNECT_SUCCESS,
        TWITTER_AUTH_USER_CONNECT_SUCCESS,
        LINKED_IN_AUTH_USER_CONNECT_SUCCESS,
        LINKED_IN_AUTH_USER_FORCE_CONNECT_SUCCESS,
        SNAPCHAT_AUTH_USER_CONNECT_SUCCESS,
        SNAPCHAT_AUTH_USER_FORCE_CONNECT_SUCCESS,
        YOUTUBE_AUTH_USER_CONNECT_SUCCESS,
        TIKTOK_AUTH_USER_CONNECT_SUCCESS
    ], accountEntitiesGetRequest);
}
export const getDynamicBatchSize = (size) => Math.ceil(size / 10);

function* batchProfileDeleteRequest(accountIds, route) {
    let removedProfileIds = [];
    let notRemovedProfileIds = [];
    let forceRemovableProfilesInfo = [];
    const requestParams = { accountIds: JSON.stringify(accountIds.map((id) => _parseInt(id))) };
    const serverRequest = createServerRequest(requestParams);
    try {
        const {
            response,
            serverError
        } = yield handleAuthorizedServerRequest(serverRequest, route);
        if (response) {
            removedProfileIds = response.jsonData.removedProfileIds;
            notRemovedProfileIds = response.jsonData.notRemovedProfileIds;
            forceRemovableProfilesInfo = response.jsonData.forceRemovableProfilesInfo;
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        notRemovedProfileIds = accountIds;
    }
    return {
        removedProfileIds,
        notRemovedProfileIds,
        forceRemovableProfilesInfo
    };
}

export const createBatchProfileDeleteRequests = (accountIds) => {
    const chunks = chunkArray(accountIds, getDynamicBatchSize(accountIds.length));
    return chunks.map((ids) => call(batchProfileDeleteRequest, ids, '/client-index/delete-profile'));
};

export const createBatchAdAccountDeleteRequests = (accountIds) => {
    const chunks = chunkArray(accountIds, getDynamicBatchSize(accountIds.length));
    return chunks.map((ids) => call(batchProfileDeleteRequest, ids, '/client-index/delete-ad-account'));
};

export const collectBatchAccountDeleteResponses = (responses) => {
    let allRemovedProfileIds = [];
    let allNotRemovedProfileIds = [];
    let allForceRemovableProfilesInfo = [];

    responses.forEach(({ removedProfileIds, notRemovedProfileIds, forceRemovableProfilesInfo }) => {
        allRemovedProfileIds = [...allRemovedProfileIds, ...removedProfileIds];
        allNotRemovedProfileIds = [...allNotRemovedProfileIds, ...notRemovedProfileIds];
        allForceRemovableProfilesInfo = [...allForceRemovableProfilesInfo, ...forceRemovableProfilesInfo];
    });
    return {
        allRemovedProfileIds,
        allNotRemovedProfileIds,
        allForceRemovableProfilesInfo
    };
};

function* requestSingleAccountAddRequest(accountIds, addRequest) {
    const addRequestStates = accountIds.map((id) => call(addRequest, id));
    yield all(addRequestStates);
}

function* successAddAccountRequests(accounts, accountIdsByLink, successRequest) {
    const succeededSearchedaccountLinks = accounts.map(({ link }) => link);
    const successStates = succeededSearchedaccountLinks.map((link) => call(successRequest, accountIdsByLink[link]));
    yield all(successStates);
}

function* failAddAccountRequests(failedSearchedAccountLinks, accountTempIdsByLink, failedRequest) {
    const errorStates = failedSearchedAccountLinks.map((link) => call(failedRequest, accountTempIdsByLink[link]));
    yield all(errorStates);
}

function* batchAccountsAddRequest(searchedAccounts, route, successRequest, failedRequest) {
    let failedSearchedAccountLinks = [];
    let addedAccounts = [];
    const accountTempIdsByLink = {};
    searchedAccounts.forEach(({ id, link }) => {
        accountTempIdsByLink[link] = id;
    });
    try {
        const serverRequest = createServerRequest({ accounts: JSON.stringify(searchedAccounts) });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, route);
        if (response) {
            const { accounts, failedAccountUrlsWithReason } = response.jsonData;
            addedAccounts = accounts;
            failedSearchedAccountLinks = Object.keys(failedAccountUrlsWithReason);
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        reportError(applicationError);
        failedSearchedAccountLinks = Object.keys(accountTempIdsByLink);
    }

    if (addedAccounts.length > 0) {
        yield call(successAddAccountRequests, addedAccounts, accountTempIdsByLink, successRequest);
    }
    if (failedSearchedAccountLinks.length > 0) {
        yield call(failAddAccountRequests, failedSearchedAccountLinks, accountTempIdsByLink, failedRequest);
    }

    return {
        addedAccounts,
        failedSearchedAccountLinks
    };
}

export function* performSequentialBulkAccountAddRequests(searchedAccounts, route, addRequest, successRequest, failedRequest) {
    let allAddedAccounts = [];
    let failedSearchedAccountLinks = [];
    const batchSize = getDynamicBatchSize(searchedAccounts.length);
    const requestChunks = chunkArray(searchedAccounts, batchSize);
    const requests = requestChunks.map((currentSearchedProfiles) => batchAccountsAddRequest(currentSearchedProfiles, route, successRequest, failedRequest));

    // update account adding request state
    yield call(requestSingleAccountAddRequest, searchedAccounts.map(({ id }) => id), addRequest);

    // eslint-disable-next-line no-restricted-syntax
    for (const request of requests) {
        const { addedAccounts, failedSearchedAccountLinks: currentFailedSearchedAccountLinks } = yield* request;
        allAddedAccounts = allAddedAccounts.concat(addedAccounts);
        if (currentFailedSearchedAccountLinks.length > 0) {
            failedSearchedAccountLinks = [...failedSearchedAccountLinks, ...currentFailedSearchedAccountLinks];
        }
    }
    return {
        allAddedAccounts,
        failedSearchedAccountLinks
    };
}
