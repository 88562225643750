import React from 'react';
import ErrorView from 'src/components/errors/ErrorView';

const MissingAdminRoleErrorView = () => (
    <ErrorView
      headline="Only admins can view this page"
      error={{ message: 'You are a read-only user and cannot access this page. To view this page, please ask your administrator for access rights.' }}
      type="warning"
    />
);

export default MissingAdminRoleErrorView;
