import { createSelector } from 'reselect';

export const getSidebarStatesFromStore = (state) => state.sidebars;

export const selectSidebarState = createSelector(
    [
        getSidebarStatesFromStore,
        (_, sidebar) => sidebar
    ],
    (sidebarStates, sidebar) => sidebarStates[sidebar]
);
