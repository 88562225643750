import IntercomVerticalWhiteSpace from 'src/components/sidebar/IntercomVerticalWhiteSpace';
import Sidebar from 'src/components/sidebar/Sidebar';
import React from 'react';
import { makeSelectCheckedValues } from 'src/selectors/lists';
import styles from 'src/stylesheets/views/profiles/profilesSidebar.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const AccountsSidebar = ({ checkedValues, renderButtons, renderCheckedAccounts }) => {
    const accountsSelected = checkedValues.length > 0;
    return (
        <Sidebar>
            <div className={styles.wrapper}>
                <div className={styles.controls}>
                    {
                        renderButtons(accountsSelected, checkedValues)
                    }
                </div>
                <div className={styles.checkedItems}>
                    {
                        renderCheckedAccounts()
                    }
                    <IntercomVerticalWhiteSpace />
                </div>
            </div>
        </Sidebar>
    );
};

AccountsSidebar.propTypes = {
    checkedValues: PropTypes.arrayOf(PropTypes.string).isRequired,
    renderButtons: PropTypes.func.isRequired,
    renderCheckedAccounts: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const selectCheckedValues = makeSelectCheckedValues();
    return (state, ownProps) => {
        const checkedValues = selectCheckedValues(state, ownProps.listName);
        return {
            checkedValues
        };
    };
};

const ConnectedAccountsSidebar = connect(makeMapStateToProps)(AccountsSidebar);

ConnectedAccountsSidebar.propTypes = {
    listName: PropTypes.string.isRequired
};

export default ConnectedAccountsSidebar;
