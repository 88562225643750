import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import Dashboards from './Dashboards';

const SharedDashboards = ({ location }) => <Dashboards currentFolderId="shared" type="shared" location={location} />;

SharedDashboards.propTypes = {
    location: customPropTypes.location.isRequired
};

export default SharedDashboards;
