import { ensurePermission, writePermissions } from 'src/middleware/userPermissionCheck';
import {
    modalsShowAdAccountDelete, modalsShowGroupSelectorAdAccount,
} from 'src/actions/overlays';
import * as customPropTypes from 'src/customPropTypes';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import { makeSelectAdAccountById } from 'src/selectors/adAccounts';
import MenuButton from 'src/components/buttons/MenuButton';
import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
    width: '180px'
};

const AdAccountOptionsPopover = (props) => {
    const {
        adAccount,
        adAccountDeleteAction,
        groupSelectionAction,
        hidePopover
    } = props;
    return (
        <Popover id="profileOptionsPopover">
            <div style={styles}>
                <ButtonGroup vertical>
                    <MenuButton
                      label="Assign groups"
                      onClick={() => {
                          groupSelectionAction([adAccount.id]);
                          hidePopover();
                      }}
                    />
                    <MenuButton
                      label="Delete"
                      onClick={() => {
                          adAccountDeleteAction([adAccount.id]);
                          hidePopover();
                      }}
                    />
                </ButtonGroup>
            </div>
        </Popover>
    );
};

AdAccountOptionsPopover.propTypes = {
    adAccount: customPropTypes.adAccount.isRequired,
    adAccountDeleteAction: PropTypes.func.isRequired,
    groupSelectionAction: PropTypes.func.isRequired,
    hidePopover: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const selectAdAccountById = makeSelectAdAccountById();
    return (state, ownProps) => {
        const { adAccountId } = ownProps;
        const adAccount = selectAdAccountById(state, adAccountId);
        return { adAccount };
    };
};

export default connect(makeMapStateToProps, {
    adAccountDeleteAction: ensurePermission(modalsShowAdAccountDelete, writePermissions.deleteProfile),
    groupSelectionAction: ensurePermission(modalsShowGroupSelectorAdAccount, writePermissions.assignProfileToGroups)
})(AdAccountOptionsPopover);
