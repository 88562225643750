import * as customPropTypes from 'src/customPropTypes';
import ContentBlock from 'src/components/views/ContentBlock';
import { connect } from 'react-redux';
import DashboardMetricList from 'src/components/views/discover/dashboardDetail/DashboardMetricList';
import MetaInfo from 'src/components/views/discover/MetaInfo';
import { metricSearchSetTagUnsetTerm } from 'src/actions/metricSearch';
import PropTypes from 'prop-types';
import React from 'react';
import SectionContent from 'src/components/views/discover/SectionContent';
import styles from 'src/stylesheets/views/dashboardAndMetricDetail.scss';
import TagList from 'src/components/TagList';
import TwoColumnLayout from 'src/components/TwoColumnLayout';

const DashboardDetailAdditionalInfo = (props) => {
    const {
        searchResultDashboardTemplate, metricSearchSetTagUnsetTermAction, searchResultMetrics
    } = props;

    const { description, createTime, usage } = searchResultDashboardTemplate;
    const { tags } = props;
    return (
        <ContentBlock>
            <TwoColumnLayout>
                <div className={styles.leftSection}>
                    <div className={styles.metaInformation}>
                        <MetaInfo usage={usage} createTime={createTime} />
                    </div>
                    <div className={styles.subsections}>
                        <p
                          className={styles.texts}
                          dangerouslySetInnerHTML={{ __html: description !== '' ? description : 'There is no description yet.' }}
                        />
                    </div>
                </div>
                <div className={styles.rightSection}>
                    <div className={styles.subsections}>
                        <SectionContent label="Metrics">
                            <DashboardMetricList searchResultMetrics={searchResultMetrics} />
                        </SectionContent>
                    </div>
                    <SectionContent label="Related tags">
                        <TagList
                          onTagClick={(tag) => { metricSearchSetTagUnsetTermAction(tag, 'dashboards'); }}
                          tags={tags}
                        />
                    </SectionContent>
                </div>
            </TwoColumnLayout>
        </ContentBlock>
    );
};

DashboardDetailAdditionalInfo.propTypes = {
    searchResultDashboardTemplate: customPropTypes.searchResultDashboardTemplateOrFalse.isRequired,
    searchResultMetrics: PropTypes.arrayOf(customPropTypes.searchResultMetricOrFalse).isRequired,
    tags: customPropTypes.tags.isRequired,
    metricSearchSetTagUnsetTermAction: PropTypes.func.isRequired,
};

export default connect(null, {
    metricSearchSetTagUnsetTermAction: metricSearchSetTagUnsetTerm
})(DashboardDetailAdditionalInfo);
