import * as customPropTypes from 'src/customPropTypes';
import React, { useEffect, useRef } from 'react';
import styles from 'src/stylesheets/views/dataSources/dataSourceColumnTable.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import CopyToClipboard from 'src/components/CopyToClipboard';
import { getDataSourceColumnPath, getDeprecationDateSentenceFromNow } from 'src/utils/dataSources';
import { makeSelectDataSourceColumnsByDataSourceId } from 'src/selectors/dataSources';
import withTooltip from 'src/components/withToolTip';
import IcomoonIcon from 'src/components/IcomoonIcon';
import ListTable from 'src/components/listTable/ListTable';
import ListTableHeader from 'src/components/listTable/ListTableHeader';
import ListTableCell from 'src/components/listTable/ListTableCell';
import ListTableRow from 'src/components/listTable/ListTableRow';
import classnames from 'classnames';
import { injectWordBreakOpportunityTags } from 'src/utils/string';

const IcomoonIconWithTooltip = withTooltip(IcomoonIcon);

const headerColumns = [
    {
        id: 'id', content: 'Column name', width: '30%'
    },
    {
        id: 'type', content: 'Type', width: '10%'
    },
    {
        id: 'desc', content: 'Description', width: '60%'
    },
];

const DataSourceColumnTable = (props) => {
    const columnRef = useRef();
    const { columns, hash, dataSourceName } = props;

    useEffect(() => {
        if (columnRef.current) {
            columnRef.current.focus();
        }
    });
    const tableHeader = <ListTableHeader columns={headerColumns} offsetFromPageHeader />;

    return (
        <ListTable header={tableHeader}>
            {
                columns.map(
                    (
                        {
                            id,
                            type,
                            description,
                            isDeprecated,
                            deprecationDate,
                            nestedIntervalSpecificColumns
                        },
                        i
                    ) => (
                        <ListTableRow key={id} tabIndex={i} className={classnames(styles.row, { [styles.deprecatedRow]: isDeprecated })} rowRef={hash === id ? columnRef : null}>
                            <ListTableCell className={styles.column}>
                                <div className={styles.nameAndLink}>
                                    <div className={styles.name}>
                                        <span dangerouslySetInnerHTML={{ __html: injectWordBreakOpportunityTags(id) }} />
                                        {isDeprecated && <span> ({getDeprecationDateSentenceFromNow(deprecationDate)})</span>}
                                    </div>
                                    <div className={styles.linkIcon}>
                                        <CopyToClipboard
                                          link={getDataSourceColumnPath(dataSourceName, id)}
                                          small
                                          label=""
                                          tooltip="Copy page anchor"
                                          icon="anchor"
                                        />
                                    </div>
                                </div>
                            </ListTableCell>
                            <ListTableCell className={styles.column}><a href="https://support.quintly.com/en/articles/6235656-data-source-column-types" target="_blank" rel="noreferrer">{type}</a></ListTableCell>
                            <ListTableCell className={styles.column}>
                                <div className={styles.descriptionAndIconWrapper}>
                                    <div
                                      className={styles.description}
                                      dangerouslySetInnerHTML={{ __html: description || 'No documentation available yet' }}
                                    />
                                    {
                                            nestedIntervalSpecificColumns && nestedIntervalSpecificColumns.length > 0
                                            && (
                                                <IcomoonIconWithTooltip
                                                  className={styles.nestedIntervalSpecificColumnsIcon}
                                                  icon="date-selector"
                                                  tooltip={(
                                                      <div className={styles.nestedIntervalSpecificColumnsTooltip}>
                                                          Additional interval specific columns for this metric exist:<br />
                                                          {nestedIntervalSpecificColumns.map(
                                                              (columnName) => <span key={`nested-column-${columnName}`}>{columnName}<br /></span>
                                                          )}
                                                      </div>
                                                  )}
                                                />
                                            )
                                        }
                                </div>
                            </ListTableCell>
                        </ListTableRow>
                    )
                )
            }
        </ListTable>
    );
};

DataSourceColumnTable.propTypes = {
    columns: customPropTypes.dataSourceColumns.isRequired,
    dataSourceName: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
    const { location, dataSourceName } = ownProps;
    const selectDataSourceColumnsByDataSourceId = makeSelectDataSourceColumnsByDataSourceId();
    return {
        columns: selectDataSourceColumnsByDataSourceId(state, dataSourceName),
        hash: location.hash ? location.hash.replace('#', '') : ''
    };
};

export default withRouter(connect(mapStateToProps)(DataSourceColumnTable));
