import * as routeActions from 'react-router-redux';
import { connect } from 'react-redux';
import Modal from 'src/components/modals/layout/Modal';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';
import * as dataPushTaskLogActions from 'src/actions/dataPushTaskLogs';
import { makeSelectDataPushLogById, makeSelectDataPushLogLoadingState } from 'src/selectors/dataPushTaskLogs';
import Feedback from 'src/components/feedback/Feedback';
import DataPushTaskRawDataLog from 'src/components/views/integrations/DataPushTaskRawDataLog';
import styles from 'src/stylesheets/views/integrations/DataPushTaskLogDetail.scss';
import DetailText from 'src/components/views/DetailText';
import DataPushTaskLogStatus from 'src/components/views/integrations/DataPushTaskLogStatus';
import { getTaskLogDuration } from 'src/utils/dataPushTasks';
import DataPushTaskLogFileDownloadButton from 'src/components/buttons/DataPushTaskLogFileDownloadButton';
import Date from 'src/components/Date';
import Number from 'src/components/Number';
import DataPushTaskPlatformLogo from 'src/components/views/integrations/DataPushTaskPlatformLogo';
import IcomoonIcon from 'src/components/IcomoonIcon';
import withPopover from 'src/components/withPopover';
import DryRunDescriptionPopover from 'src/components/popovers/DryRunDescriptionPopover';
import _get from 'lodash/get';
import DataPushTaskDetailLoading from 'src/components/loadingIndicators/DataPushTaskDetailLoading';
import ErrorView from 'src/components/errors/ErrorView';

const IcomoonIconWithPopover = withPopover(IcomoonIcon);

const DataPushTaskLogDetail = (props) => {
    const { dataPushTask, dataPushTaskLog, loadingState } = props;
    const isPending = _get(loadingState, 'isPending', false);
    const error = _get(loadingState, 'error', null);

    useEffect(() => {
        const { getDataPushTaskLogRequestAction, logId } = props;
        getDataPushTaskLogRequestAction(logId, dataPushTask.id);
    }, []);

    const hideModal = () => {
        const { routePushAction } = props;
        routePushAction(`/data-push-tasks/${dataPushTask.id}`);
    };

    let content = <div />;
    let footer = null;
    let title = (
        <div className={styles.title}>
            <DataPushTaskPlatformLogo platformType={dataPushTask.destinationType} className={styles.titleImg} />
            <div className={styles.tableName}>{dataPushTask.destinationLocation}</div>
        </div>
    );
    if (dataPushTaskLog) {
        const {
            id, scheduleTime, startTime, endTime, overriddenDynamicDate, isDryRun, rowInserted, status, errorMessage
        } = dataPushTaskLog;
        const dryRunMoreInfo = (
            <IcomoonIconWithPopover
              icon="help"
              overlay={<DryRunDescriptionPopover />}
              className={styles.dryRunMoreInfo}
            />
        );
        content = (
            <div className={styles.wrapper}>
                <div className={styles.detail}>
                    <DetailText label="Schedule time" className={styles.item}><Date date={scheduleTime} format="datetime" /></DetailText>
                    <DetailText label="Start time" className={styles.item}><Date date={startTime} format="datetime" /></DetailText>
                    <DetailText label="Duration (seconds)" className={styles.item}>{getTaskLogDuration(startTime, endTime)}</DetailText>
                    <DetailText label="Overridden date" className={styles.item}>{overriddenDynamicDate || 'No'}</DetailText>
                    <DetailText label="Dry run" moreInfo={dryRunMoreInfo} className={styles.item}>{isDryRun ? 'Yes' : 'No'}</DetailText>
                    <DetailText label="Rows inserted" className={styles.item}><Number value={rowInserted} /></DetailText>
                    <DetailText label="Status" className={styles.item}><DataPushTaskLogStatus status={status} /></DetailText>
                </div>
                {
                    status === 'partialFailure'
                    && (
                        <div className={styles.error}>
                            <Feedback content={errorMessage} type="warning" />
                        </div>
                    )
                }
                {
                    status === 'failure'
                    && (
                        <div className={styles.error}>
                            <Feedback content={errorMessage} type="error" />
                        </div>
                    )
                }
                <div className={styles.logSection}>
                    <div className={styles.logTitle}>Data preview (First 10 items)</div>
                    <div className={styles.log}>
                        <DataPushTaskRawDataLog id={dataPushTaskLog.id} className={styles.logEditor} dataPushTaskId={dataPushTask.id} />
                    </div>
                </div>
            </div>
        );
        title = (
            <div className={styles.title}>
                <DataPushTaskPlatformLogo platformType={dataPushTask.destinationType} className={styles.titleImg} />
                <div className={styles.tableName}>{dataPushTask.destinationLocation}</div>
                <Date date={startTime} format="datetime" />
            </div>
        );
        footer = <DataPushTaskLogFileDownloadButton id={id} />;
    } else if (isPending) {
        content = <DataPushTaskDetailLoading />;
    } else if (error) {
        return (
            <ErrorView headline="Log failed to load" error={error} />
        );
    }

    return (
        <Modal onHide={hideModal}>
            <Modal.Dialog fullscreen>
                <Modal.Header onHide={hideModal}>
                    <Modal.Title>
                        {title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {content}
                </Modal.Body>
                <Modal.Footer>
                    {footer}
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

const makeMapStateToProps = () => {
    const selectDataPushLogById = makeSelectDataPushLogById();
    const selectDataPushLogLoadingState = makeSelectDataPushLogLoadingState();
    return (state, ownProps) => {
        const { logId } = ownProps;
        return {
            dataPushTaskLog: selectDataPushLogById(state, logId),
            loadingState: selectDataPushLogLoadingState(state, logId)
        };
    };
};

DataPushTaskLogDetail.propTypes = {
    routePushAction: PropTypes.func.isRequired,
    getDataPushTaskLogRequestAction: PropTypes.func.isRequired,
    loadingState: customPropTypes.asyncDataLoadingState.isRequired,
    dataPushTask: customPropTypes.dataPushTask.isRequired,
    logId: PropTypes.string.isRequired,
    dataPushTaskLog: PropTypes.oneOfType([customPropTypes.dataPushTaskLog, PropTypes.bool]).isRequired
};

export default connect(makeMapStateToProps, {
    routePushAction: routeActions.push,
    getDataPushTaskLogRequestAction: dataPushTaskLogActions.getDataPushTaskLogRequest
})(DataPushTaskLogDetail);
