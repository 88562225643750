import React from 'react';
import withTooltip from 'src/components/withToolTip';
import ImageWithFallback from 'src/components/ImageWithFallback';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/views/integrations/dataPushTaskPlatformLogo.scss';
import { getDataPushTaskPlatformLogoAndLabel, googleBigQueryType, awsS3Type } from 'src/utils/dataPushTasks';
import classnames from 'classnames';
import fallbackSrc from 'src/resources/illustrations/no_image.png';

const ImageWithFallbackWithTooltip = withTooltip(ImageWithFallback);

const DataPushTaskPlatformLogo = (props) => {
    const { platformType, className } = props;
    const { img, label } = getDataPushTaskPlatformLogoAndLabel(platformType);
    return (
        <ImageWithFallbackWithTooltip
          src={img}
          fallbackSrc={fallbackSrc}
          className={classnames(styles.logo, className)}
          tooltip={label}
        />
    );
};

DataPushTaskPlatformLogo.propTypes = {
    platformType: PropTypes.oneOf([googleBigQueryType, awsS3Type]).isRequired,
    className: PropTypes.string
};

DataPushTaskPlatformLogo.defaultProps = {
    className: ''
};

export default DataPushTaskPlatformLogo;
