import * as customPropTypes from 'src/customPropTypes';
import DiscoverMetricTile from 'src/components/metricSearchResultTile/DiscoverMetricTile';
import DiscoverDashboardTemplateTile from 'src/components/metricSearchResultTile/DiscoverDashboardTemplateTile';
import React from 'react';

const DiscoverTile = (props) => {
    const { metricSearchResult } = props;
    switch (metricSearchResult.type) {
        case 'Metric':
            return <DiscoverMetricTile {...props} />;
        case 'DashboardTemplate':
            return <DiscoverDashboardTemplateTile {...props} />;
        default:
            throw new Error(`Unsupported metricSearchResult.type ${metricSearchResult.type}`);
    }
};

DiscoverTile.propTypes = {
    metricSearchResult: customPropTypes.metricSearchResult.isRequired,
    tags: customPropTypes.tags.isRequired
};

export default DiscoverTile;
