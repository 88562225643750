import {
    RESET_ALL_QQL_PARAMS,
    SET_QQL_FORM_VALUES,
    SET_QQL_METRIC_DATA_REQUEST_STATUS,
    SET_QQL_RUN_STATUS
} from 'src/actions/customMetric';

import { combineReducers } from 'redux';

const defaultDataLoadingState = {
    hasData: false,
    loading: false,
    error: null
};

export const runQQLState = (state = false, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_QQL_RUN_STATUS: {
            const { runQQLStatus } = payload;
            return runQQLStatus;
        }
        case RESET_ALL_QQL_PARAMS: {
            return false;
        }
        default:
            return state;
    }
};

export const dataLoadingState = (state = defaultDataLoadingState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_QQL_METRIC_DATA_REQUEST_STATUS: {
            const { hasData, loading, error } = payload;
            const oldState = Object.assign({}, state);
            return Object.assign({}, oldState, { hasData, loading, error });
        }
        case RESET_ALL_QQL_PARAMS: {
            return defaultDataLoadingState;
        }
        default:
            return state;
    }
};

export const qqlFormValues = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_QQL_FORM_VALUES: {
            const { qqlFormValues: qqlFormValuesFromPayload } = payload;
            return Object.assign({}, state, qqlFormValuesFromPayload);
        }
        case RESET_ALL_QQL_PARAMS: {
            return {};
        }
        default:
            return state;
    }
};

export const qqlSelectorValues = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_QQL_FORM_VALUES: {
            const { qqlSelectorValues: qqlSelectorValuesFromPayload } = payload;
            return Object.assign({}, state, qqlSelectorValuesFromPayload);
        }
        case RESET_ALL_QQL_PARAMS: {
            return {};
        }
        default:
            return state;
    }
};

export const customMetric = combineReducers({
    qqlFormValues,
    dataLoadingState,
    qqlSelectorValues,
    runQQLState
});
