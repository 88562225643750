import React from 'react';
import styles from 'src/stylesheets/pageNumbersWithScroller.scss';
import PropTypes from 'prop-types';
import IcomoonIcon from 'src/components/IcomoonIcon';
import withTooltip from 'src/components/withToolTip';
import classnames from 'classnames';

const IcomoonIconWithTooltip = withTooltip(IcomoonIcon);

const PageScroller = (props) => {
    const {
        disabled, icon, tooltip, onClick, className
    } = props;

    return (
        <li className={classnames(className, { [styles.disabled]: disabled })} onClick={onClick}>
            <div className={styles.listItem}>
                <IcomoonIconWithTooltip
                  icon={icon}
                  tooltip={tooltip}
                  className={styles.icon}
                />
            </div>
        </li>
    );
};

PageScroller.propTypes = {
    onClick: PropTypes.func.isRequired,
    tooltip: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    className: PropTypes.string
};

PageScroller.defaultProps = {
    className: ''
};

export default PageScroller;
