import React from 'react';
import ContentBlock from 'src/components/views/ContentBlock';
import TwoColumnLayout from 'src/components/TwoColumnLayout';
import DetailText from 'src/components/views/DetailText';
import styles from 'src/stylesheets/views/integrations/dataPushTaskDetailPage.scss';
import DivWithMultiLineEllipsis from 'src/components/DivWithMultiLineEllipsis';
import DataPushTaskFrequencyIndicator from 'src/components/views/integrations/DataPushTaskFrequencyIndicator';
import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import * as overlayAction from 'src/actions/overlays';
import PropTypes from 'prop-types';
import Button from 'src/components/buttons/Button';
import { dataPushTaskDataSourceType, dataPushTaskQQLType } from 'src/utils/dataPushTasks';

const DataPushTask = (props) => {
    const {
        dataPushTask, modalShowProfileListAction, modalShowAceEditorAction, modalShowDataPushTaskEditAction
    } = props;
    const {
        id,
        dataSource,
        dataSourceColumns,
        dynamicDate,
        interval,
        timezone,
        selectAllAdAccounts,
        spaceAdAccountIds,
        selectAllProfiles,
        profileIds,
        qqlQuery,
        delivery,
        type
    } = dataPushTask;

    const seeAllProfiles = !selectAllProfiles
        ? (
            <a onClick={() => {
                modalShowProfileListAction(profileIds);
            }}
            >view all profiles
            </a>
        )
        : null;

    const seeAllQuery = (
        <a onClick={() => { modalShowAceEditorAction('Query', qqlQuery); }}>View query</a>
    );
    const footerRight = (
        <Button label="Edit" icon="edit" onClick={() => { modalShowDataPushTaskEditAction(id); }} />
    );

    let accountsText = '';
    if (selectAllAdAccounts && selectAllProfiles) {
        accountsText = 'All matching ad accounts and profiles';
    } else if (selectAllAdAccounts && !selectAllProfiles) {
        accountsText = `All matching ad accounts, ${profileIds.length} profiles`;
    } else if (!selectAllAdAccounts && selectAllProfiles) {
        accountsText = `${spaceAdAccountIds.length} ad accounts, all matching profiles`;
    } else {
        accountsText = `${spaceAdAccountIds.length} ad accounts, ${profileIds.length} profiles`;
    }

    return (
        <div>
            <ContentBlock headline="What data to be pushed" footerRight={footerRight}>
                <TwoColumnLayout>
                    <div>
                        <DetailText
                          label="Accounts"
                          className={styles.detailText}
                          moreInfo={seeAllProfiles}
                        >
                            {accountsText}
                        </DetailText>
                        <DetailText label="Dynamic date" className={styles.detailText}>{dynamicDate}</DetailText>
                        {
                            type === dataPushTaskDataSourceType && (
                                <div>
                                    <DetailText
                                      label="Data source"
                                      className={styles.detailText}
                                    >
                                        {dataSource}
                                    </DetailText>
                                </div>
                            )
                        }
                        {
                            type === dataPushTaskQQLType && (
                                <DetailText
                                  className={styles.qqlQuerySection}
                                  label="Query"
                                  moreInfo={seeAllQuery}
                                >
                                    <DivWithMultiLineEllipsis tooltip={qqlQuery} lineClamps={3}>
                                        {qqlQuery}
                                    </DivWithMultiLineEllipsis>
                                </DetailText>
                            )
                        }
                    </div>
                    <div>
                        <DetailText
                          label="Time zone"
                          className={styles.detailText}
                        >{timezone}
                        </DetailText>
                        <DetailText label="Interval" className={styles.detailText}>{interval}</DetailText>
                        {
                            type === dataPushTaskDataSourceType && (
                                <DetailText
                                  label="Columns"
                                  className={styles.detailText}
                                >
                                    <DivWithMultiLineEllipsis tooltip={dataSourceColumns.join(', ')} lineClamps={3}>
                                        {dataSourceColumns.join(', ')}
                                    </DivWithMultiLineEllipsis>
                                </DetailText>
                            )
                        }
                    </div>
                </TwoColumnLayout>
            </ContentBlock>
            <div className={styles.frequencyIndicator}>
                <DataPushTaskFrequencyIndicator delivery={delivery} />
            </div>
        </div>
    );
};

DataPushTask.propTypes = {
    dataPushTask: customPropTypes.dataPushTask.isRequired,
    modalShowProfileListAction: PropTypes.func.isRequired,
    modalShowAceEditorAction: PropTypes.func.isRequired,
    modalShowDataPushTaskEditAction: PropTypes.func.isRequired
};

export default connect(null, {
    modalShowProfileListAction: overlayAction.modalShowProfileList,
    modalShowAceEditorAction: overlayAction.modalShowAceEditor,
    modalShowDataPushTaskEditAction: overlayAction.modalShowDataPushTaskEdit
})(DataPushTask);
