import {
    call, put, takeEvery, delay
} from 'redux-saga/effects';
import {
    CHECK_LATEST_USER_NOTIFICATION,
    checkLatestUserNotificationError,
    checkLatestUserNotificationRequest,
    checkLatestUserNotificationSuccess,
    GET_USER_NOTIFICATIONS_REQUEST,
    getUserNotificationsError,
    getUserNotificationsSuccess,
    MARK_ALL_USER_NOTIFICATION_AS_READ,
    MARK_USER_NOTIFICATION,
    markAllUserNotificationAsReadError,
    markAllUserNotificationAsReadSuccess,
    markUserNotificationError,
    markUserNotificationSuccess,
    UPDATE_USER_NOTIFICATION_LAST_SEEN_TIME,
    UPDATE_USER_NOTIFICATION_SETTINGS_REQUEST,
    updateUserNotificationLastSeenTimeError,
    updateUserNotificationLastSeenTimeSuccess,
    updateUserNotificationSettingsError,
    updateUserNotificationSettingsSuccess
} from 'src/actions/userNotification';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';
import { parseUserNotifications, parseUserNotificationSettings } from 'src/parsers';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import { modalsHideUserNotificationSettings } from 'src/actions/overlays';
import { reportError } from 'src/utils/reportError';
import { SubmissionError } from 'redux-form';

function* getUserNotificationRequest(action) {
    const { lastNotificationCreateTime, numberOfNotifications } = action.payload;
    const serverRequest = createServerRequest({ lastNotificationTime: lastNotificationCreateTime, numberOfNotifications });
    try {
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-user-notification/get-user-notifications');
        if (response) {
            const { userNotifications, next } = response.jsonData;
            const parsedUserNotifications = parseUserNotifications(userNotifications);
            const { sortedIds, parsedNotifications } = parsedUserNotifications;
            let newLastNotificationCreateTime = null;
            if (sortedIds.length >= 1) {
                const lastNotification = parsedNotifications[sortedIds[sortedIds.length - 1]];
                newLastNotificationCreateTime = lastNotification.createTime;
            }
            yield put(getUserNotificationsSuccess(parsedUserNotifications, newLastNotificationCreateTime, next));
        }
        if (serverError) {
            yield put(getUserNotificationsError(serverError.message));
        }
    } catch (applicationError) {
        yield put(getUserNotificationsError('Something went wrong.'));
    }
}

function* checkLatestUserNotification() {
    const serverRequest = createServerRequest();
    try {
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-user-notification/get-latest-user-notification');
        if (response) {
            const { userNotifications, hasNewNotifications } = response.jsonData;
            yield put(checkLatestUserNotificationSuccess(parseUserNotifications(userNotifications), hasNewNotifications));
        }
        if (serverError) {
            yield put(checkLatestUserNotificationError(serverError.message));
        }
    } catch (applicationError) {
        yield put(checkLatestUserNotificationError('Something went wrong'));
    }
}

function* updateUserNotificationLastSeenTime() {
    const serverRequest = createServerRequest({ });
    try {
        yield call(checkLatestUserNotification);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-user-notification/update-user-last-notifications-seen-time');
        if (response) {
            const { hasNewNotifications } = response.jsonData;
            yield put(updateUserNotificationLastSeenTimeSuccess(hasNewNotifications));
        }
        if (serverError) {
            yield put(updateUserNotificationLastSeenTimeError(serverError.message));
        }
    } catch (applicationError) {
        yield put(updateUserNotificationLastSeenTimeError('Something went wrong'));
    }
}

function* markUserNotification(action) {
    const { payload } = action;
    const { userNotificationId, mark } = payload;
    const serverRequest = createServerRequest({ userNotificationId, mark });
    try {
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-user-notification/mark-user-notification');
        if (response) {
            yield put(markUserNotificationSuccess(userNotificationId, mark));
        }
        if (serverError) {
            yield put(markUserNotificationError(serverError.message));
        }
    } catch (applicationError) {
        yield put(markUserNotificationError('Something went wrong'));
    }
}

function* markAllUserNotificationAsRead() {
    const serverRequest = createServerRequest({});
    try {
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-user-notification/mark-all-user-notifications');
        if (response) {
            const { hasNewNotifications } = response.jsonData;
            yield put(markAllUserNotificationAsReadSuccess(hasNewNotifications));
        }
        if (serverError) {
            yield put(markAllUserNotificationAsReadError(serverError.message));
        }
    } catch (applicationError) {
        yield put(markAllUserNotificationAsReadError('Something went wrong'));
    }
}

function* timelyCheckLatestUserNotifications() {
    while (true) {
        yield put(checkLatestUserNotificationRequest());
        yield delay(300000);
    }
}

function* updateUserNotificationSettingRequest(action) {
    const { formValues } = action.payload;
    const serverRequest = createServerRequest({
        sendUserNotificationsViaEmail: formValues.sendUserNotificationsViaEmail
    });
    try {
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-user-notification/update-user-notification-settings');
        if (response) {
            yield put(updateUserNotificationSettingsSuccess(parseUserNotificationSettings(response.jsonData)));
            yield put(modalsHideUserNotificationSettings());
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(updateUserNotificationSettingsError(new SubmissionError({ _error: applicationOrServerError })));
    }
}

export default function* switchToolsSagas() {
    yield takeEvery(GET_USER_NOTIFICATIONS_REQUEST, getUserNotificationRequest);
    yield takeEvery(MARK_USER_NOTIFICATION, markUserNotification);
    yield takeEvery(MARK_ALL_USER_NOTIFICATION_AS_READ, markAllUserNotificationAsRead);
    yield takeEvery(UPDATE_USER_NOTIFICATION_LAST_SEEN_TIME, updateUserNotificationLastSeenTime);
    yield takeEvery(CHECK_LATEST_USER_NOTIFICATION, checkLatestUserNotification);
    yield takeEvery(UPDATE_USER_NOTIFICATION_SETTINGS_REQUEST, updateUserNotificationSettingRequest);
    yield takeEvery(USER_LOGGED_IN, timelyCheckLatestUserNotifications);
}
