export default (objectUrl) => new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.open('GET', objectUrl, true);
    request.responseType = 'blob';
    request.onload = () => {
        resolve(request.response);
    };
    request.onerror = reject;
    request.send();
});
