import * as customPropTypes from 'src/customPropTypes';
import * as metricSearchActions from 'src/actions/metricSearch';
import ContentBlock from 'src/components/views/ContentBlock';
import { connect } from 'react-redux';
import MetricDashboardList from 'src/components/views/discover/metricDetail/MetricDashboardList';
import PropTypes from 'prop-types';
import React from 'react';
import SectionContent from 'src/components/views/discover/SectionContent';
import styles from 'src/stylesheets/views/dashboardAndMetricDetail.scss';
import TagList from 'src/components/TagList';
import classnames from 'classnames';

const DashboardsAndTagsInfo = (props) => {
    const {
        metricId,
        tags,
        useCases,
        platformType,
        metricSearchSetTagUnsetTermAction
    } = props;

    return (
        <ContentBlock>
            <div className={styles.subsections}>
                <SectionContent label="Dashboards">
                    <MetricDashboardList
                      metricId={metricId}
                    />
                </SectionContent>
            </div>
            {
                useCases
                && (
                    <div className={styles.subsections}>
                        <SectionContent label="Use cases">
                            <p
                              className={styles.texts}
                              dangerouslySetInnerHTML={{ __html: useCases }}
                            />
                        </SectionContent>
                    </div>
                )
            }
            <div className={classnames({ [styles.subsections]: platformType === 'youtube' })}>
                <SectionContent label="Related tags">
                    <TagList
                      onTagClick={(tag) => { metricSearchSetTagUnsetTermAction(tag, 'metrics'); }}
                      tags={tags}
                    />
                </SectionContent>
            </div>
        </ContentBlock>
    );
};

DashboardsAndTagsInfo.propTypes = {
    metricId: PropTypes.string.isRequired,
    tags: customPropTypes.tags.isRequired,
    platformType: PropTypes.string.isRequired,
    useCases: PropTypes.string,
    metricSearchSetTagUnsetTermAction: PropTypes.func.isRequired
};

export default connect(null, {
    metricSearchSetTagUnsetTermAction: metricSearchActions.metricSearchSetTagUnsetTerm
})(DashboardsAndTagsInfo);
