import { connect } from 'react-redux';
import DataSourcesListItem from 'src/components/views/dataSources/DataSourceListItem';
import { makeSelectDataSourceIdsWithListFiltersApplied } from 'src/selectors/dataSources';
import PropTypes from 'prop-types';
import React from 'react';
import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import emptySearchImage from 'src/resources/illustrations/discover-no-results.png';
import styles from 'src/stylesheets/views/dataSources/dataSourceListItem.scss';
import classnames from 'classnames';
import { getFormValues } from 'redux-form';
import DataSourceListHeader from 'src/components/views/dataSources/DataSourceListHeader';
import withStickyListHeaderHandling from 'src/components/withStickyListHeaderHandling';
import ListPageWrapper from 'src/components/ListPageWrapper';
import * as customPropTypes from 'src/customPropTypes';
import { formName } from 'src/utils/dataSources';

const DataSourceListHeaderWithStickyListHeaderHandling = withStickyListHeaderHandling(DataSourceListHeader);

const bodyStyle = {
    minWidth: '950px'
};

const DataSourcesList = (props) => {
    const { dataSourceIds } = props;
    const dataSourceLength = dataSourceIds.length;

    return (
        <div style={bodyStyle}>
            <ListPageWrapper>
                <DataSourceListHeaderWithStickyListHeaderHandling offsetTop={68} />
                {
                    dataSourceLength > 0
                        ? (
                            <ul className={styles.list}>
                                {
                                    dataSourceIds.map((id, i) => (
                                        <li
                                          key={id}
                                          data-table-name={id}
                                          className={classnames({ [styles.listItem]: i !== dataSourceLength - 1 })}
                                        >
                                            <DataSourcesListItem id={id} />
                                        </li>
                                    ))
                                }
                            </ul>
                        )
                        : (
                            <EmptyInfoScreen
                              image={emptySearchImage}
                              title="No data sources found"
                            />
                        )
                }
            </ListPageWrapper>
        </div>
    );
};

DataSourcesList.propTypes = {
    allDataSources: customPropTypes.dataSources.isRequired,
    dataSourceIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

const makeMapStateToProps = () => {
    const selectDataSourceIdsWithListFiltersApplied = makeSelectDataSourceIdsWithListFiltersApplied();
    const selectFormValues = getFormValues(formName);
    const defaultDataSourceIds = [];
    return (state, ownProps) => {
        const { allDataSources } = ownProps;
        const allFormValues = selectFormValues(state);
        if (allFormValues) {
            const {
                platformType, statsType, dataType, term, sortBy
            } = allFormValues;
            return {
                dataSourceIds: selectDataSourceIdsWithListFiltersApplied(state, allDataSources, platformType, statsType, dataType, term, sortBy)
            };
        }
        return {
            dataSourceIds: defaultDataSourceIds
        };
    };
};

export default connect(makeMapStateToProps)(DataSourcesList);
