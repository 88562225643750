import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import React from 'react';

const UserNotificationIcon = (props) => {
    const { type, className } = props;
    let icon = 'disconnected';
    if (type === 'space_profile_inaccessibility' || type === 'instagram_account_renamed' || type === 'report_failed') {
        icon = 'alert';
    }
    return (
        <IcomoonIcon icon={icon} className={className} />
    );
};

UserNotificationIcon.propTypes = {
    type: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired
};

export default UserNotificationIcon;
