import * as customPropTypes from 'src/customPropTypes';
import AnalysisTileHeader from 'src/components/metricSearchResultTile/layout/AnalysisTileHeader';
import { connect } from 'react-redux';
import { metricVisualizationImagesUrl } from 'src/config';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import React from 'react';
import styles from 'src/stylesheets/analysisTile.scss';
import withAnalysisQuickLinkFromStore from 'src/components/views/analysis/withAnalysisQuickLinkFromStore';

const DivWithAnalysisClickHandling = withAnalysisQuickLinkFromStore((props) => (
    <div role="button" tabIndex="-1" className={props.className} onClick={() => { props.pushAction(props.to); }}>
        {props.children}
    </div>
));

const AnalysisPublicMetricTile = (props) => {
    const { metricSearchResult, pushAction } = props;
    return (
        <DivWithAnalysisClickHandling pushAction={pushAction} id={metricSearchResult.id} className={styles.tile}>
            <AnalysisTileHeader name={metricSearchResult.name} platformType={metricSearchResult.platformType} />
            <div className={styles.image} style={{ backgroundImage: `url(${metricVisualizationImagesUrl}${metricSearchResult.visualizationId}.svg)` }} />
        </DivWithAnalysisClickHandling>
    );
};

AnalysisPublicMetricTile.propTypes = {
    metricSearchResult: customPropTypes.searchResultMetric.isRequired,
    pushAction: PropTypes.func.isRequired
};

export default connect(null, { pushAction: push })(AnalysisPublicMetricTile);
