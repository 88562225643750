import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import DragHandleButton from 'src/components/buttons/DragHandleButton';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/dragHandle.scss';
import withTooltip from 'src/components/withToolTip';

const DragHandleButtonWithToolTip = withTooltip(DragHandleButton);

const DragHandle = (props) => {
    const {
        className,
        gridDragHandleClassName,
        isAllowedToChangeLayout,
        breakpoint,
        isGridLoading,
        showSpacer
    } = props;

    const smallBreakpoint = breakpoint === 'sm';

    let dragHandleButton = <DragHandleButtonWithToolTip tooltip="Move" />;
    if (!isAllowedToChangeLayout) {
        dragHandleButton = <DragHandleButtonWithToolTip disabled tooltip="Disabled for shared dashboards in read-only mode" />;
    } else if (smallBreakpoint) {
        dragHandleButton = <DragHandleButtonWithToolTip disabled tooltip="Disabled for small viewports" />;
    } else if (isGridLoading) {
        dragHandleButton = <DragHandleButtonWithToolTip disabled tooltip="Loading" />;
    }

    return (
        <div className={classnames(styles.dragHandleWrapper, className)}>
            { showSpacer && <div className={classnames(styles.dragHandleSpacer)} /> }
            <div className={classnames(styles.dragHandleButtonWrapper, { [gridDragHandleClassName]: isAllowedToChangeLayout && !smallBreakpoint })}>
                {dragHandleButton}
            </div>
        </div>
    );
};

DragHandle.propTypes = {
    isAllowedToChangeLayout: PropTypes.bool,
    breakpoint: customPropTypes.gridBreakpoints,
    className: PropTypes.string,
    gridDragHandleClassName: PropTypes.string.isRequired,
    isGridLoading: PropTypes.bool,
    showSpacer: PropTypes.bool
};

DragHandle.defaultProps = {
    isAllowedToChangeLayout: false,
    breakpoint: 'lg',
    className: '',
    isGridLoading: false,
    showSpacer: true
};

export default DragHandle;
