import { createSelector } from 'reselect';

export const getPostTagsPostsInfo = (state) => state.postTagsPostsInfo;

export const makeSelectPostTagsPostsInfo = () => createSelector(
    [
        getPostTagsPostsInfo,
        (_, postTagId) => postTagId
    ],
    (numberOfPostsByPostTag, postTagId) => numberOfPostsByPostTag[postTagId] || { numberOfPosts: 0, postTagId }
);
