import AccountLimitsStatus from 'src/components/AccountLimitsStatus';
import Popover from 'src/components/popovers/layout/Popover';
import React from 'react';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';

const wrapperStyle = {
    width: 300,
    padding: 20
};

const AccountLimitPopover = ({ openPlanInNewTab, identifier }) => {
    let showLimitsFor = ['profiles', 'profileSwaps'];
    if (identifier === 'adAccounts') {
        showLimitsFor = ['adAccounts'];
    }
    return (
        <Popover id="profileLimitPopover">
            <div style={wrapperStyle}>
                <AccountLimitsStatus showLimitsFor={showLimitsFor} openPlanInNewTab={openPlanInNewTab} />
            </div>
        </Popover>
    );
};

AccountLimitPopover.propTypes = {
    openPlanInNewTab: PropTypes.bool,
    identifier: customPropTypes.accountTypes.isRequired
};

AccountLimitPopover.defaultProps = {
    openPlanInNewTab: false
};

export default AccountLimitPopover;
