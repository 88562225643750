import React from 'react';
import Body from 'src/components/Body';
import DataSourceList from 'src/components/views/dataSources/DataSourcesList';
import DataSourcesHeader from 'src/components/header/DataSourcesHeader';
import Footer from 'src/components/Footer';
import ScrollView from 'src/components/ScrollView';
import withScrollPosition from 'src/components/withScrollPosition';
import * as customPropTypes from 'src/customPropTypes';
import DataSourcesLoader from 'src/components/views/dataSources/DataSourcesLoader';
import ListTableSkeleton from 'src/components/loadingIndicators/ListTableSkeleton';

const ScrollViewWithScrollPosition = withScrollPosition(ScrollView, 'dataSources');

const DataSources = (props) => {
    const { location } = props;
    return (
        <ScrollViewWithScrollPosition
          scrollPositionIdentifier="data-sources"
          locationKey={location.key}
          dataViewName="data-sources"
        >
            <DataSourcesHeader />
            <Body>
                <DataSourcesLoader loadingScreen={<ListTableSkeleton rows={10} columns={4} />}>
                    {
                        (allDataSources) => (
                            <DataSourceList allDataSources={allDataSources} />
                        )
                    }
                </DataSourcesLoader>
            </Body>
            <Footer />
        </ScrollViewWithScrollPosition>
    );
};

DataSources.propTypes = {
    location: customPropTypes.location
};

export default DataSources;
