import { combineReducers } from 'redux';
import {
    ALL_DASHBOARD_SHARE_LINKS_GET_SUCCESS,
    ALL_DASHBOARD_SHARE_LINKS_GET_ERROR,
    ALL_DASHBOARD_SHARE_LINKS_GET_REQUEST,
    CREATE_SHAREABLE_LINK_SUCCESS,
    DASHBOARD_SHARE_LINK_REMOVE_SUCCESS,
    DASHBOARD_SHARE_LINK_UPDATE_SUCCESS,
    DASHBOARD_SHARE_LINKS_GET_SUCCESS,
    DASHBOARD_SHARE_LINKS_GET_ERROR,
    DASHBOARD_SHARE_LINKS_GET_REQUEST,
    DASHBOARD_SHARE_LINKS_CLEANUP
} from 'src/actions/dashboardShareLinks';
import { createAsyncStatesReducerForActionsWithErrorAndSuccessStates } from 'src/reducers/utils';
import _omit from 'lodash/omit';
import _uniq from 'lodash/uniq';
import _difference from 'lodash/difference';
import { REPORT_OVER_VIEWS_DELETE_SUCCESS } from 'src/actions/reportOverview';

const byId = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case ALL_DASHBOARD_SHARE_LINKS_GET_SUCCESS:
        case DASHBOARD_SHARE_LINKS_GET_SUCCESS: {
            return Object.assign({}, state, payload.dashboardShareLinks);
        }
        case CREATE_SHAREABLE_LINK_SUCCESS:
        case DASHBOARD_SHARE_LINK_UPDATE_SUCCESS: {
            const { dashboardShareLink } = payload;
            return Object.assign({}, state, { [dashboardShareLink.id]: dashboardShareLink });
        }
        case DASHBOARD_SHARE_LINK_REMOVE_SUCCESS: {
            const { id } = payload;
            return _omit(state, id);
        }
        case REPORT_OVER_VIEWS_DELETE_SUCCESS:
        case DASHBOARD_SHARE_LINKS_CLEANUP: {
            const { dashboardShareLinkIds } = payload;
            let oldState = Object.assign({}, state);
            dashboardShareLinkIds.forEach((dashboardShareLinkId) => {
                oldState = _omit(oldState, dashboardShareLinkId);
            });
            return oldState;
        }
        default:
            return state;
    }
};

const allIds = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case ALL_DASHBOARD_SHARE_LINKS_GET_SUCCESS:
        case DASHBOARD_SHARE_LINKS_GET_SUCCESS: {
            return _uniq([...state, ...Object.keys(payload.dashboardShareLinks)]);
        }
        case CREATE_SHAREABLE_LINK_SUCCESS: {
            const { dashboardShareLink } = payload;
            return [...state, dashboardShareLink.id];
        }
        case DASHBOARD_SHARE_LINK_REMOVE_SUCCESS: {
            const { id } = payload;
            return _difference(state, [id]);
        }
        case REPORT_OVER_VIEWS_DELETE_SUCCESS:
        case DASHBOARD_SHARE_LINKS_CLEANUP: {
            const { dashboardShareLinkIds } = payload;
            return _difference(state, dashboardShareLinkIds);
        }
        default:
            return state;
    }
};

const asyncStates = combineReducers({
    getAll: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        () => 'state',
        [ALL_DASHBOARD_SHARE_LINKS_GET_REQUEST],
        [ALL_DASHBOARD_SHARE_LINKS_GET_SUCCESS],
        [ALL_DASHBOARD_SHARE_LINKS_GET_ERROR]
    ),
    getByDashboard: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        (payload) => payload.dashboardId,
        [DASHBOARD_SHARE_LINKS_GET_REQUEST],
        [DASHBOARD_SHARE_LINKS_GET_SUCCESS],
        [DASHBOARD_SHARE_LINKS_GET_ERROR]
    ),
});

export const dashboardShareLinks = combineReducers({
    allIds,
    byId,
    asyncStates
});
