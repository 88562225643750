import React from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import PaginatorItem from 'src/components/paginator/PaginatorItem';
import { range } from 'src/utils/array';

const memoizedRange = memoizeOne(range);

const PaginatorSiblings = (props) => {
    const {
        count, endPages, currentPage, boundaryCount, siblingCount, onPageClick
    } = props;

    const siblingsStart = Math.max(
        Math.min(
            // Natural start
            currentPage - siblingCount,
            // Lower boundary when page is high
            count - boundaryCount - siblingCount * 2 - 1,
        ),
        // Greater than startPages
        boundaryCount + 2,
    );

    const siblingsEnd = Math.min(
        Math.max(
            // Natural end
            currentPage + siblingCount,
            // Upper boundary when page is low
            boundaryCount + siblingCount * 2 + 2,
        ),
        // Less than endPages
        endPages.length > 0 ? endPages[0] - 2 : count - 1,
    );
    const siblings = memoizedRange(siblingsStart, siblingsEnd);

    return (
        <>
            {
                // eslint-disable-next-line no-nested-ternary
                (siblingsStart > boundaryCount + 2)
                    ? <li>...</li>
                    : (boundaryCount + 1 < count - boundaryCount)
                        ? <PaginatorItem onPageClick={onPageClick} pageNumber={boundaryCount + 1} selected={boundaryCount + 1 === currentPage} />
                        : null
            }
            {
                siblings.map((pageNumber) => (
                    <PaginatorItem
                      key={pageNumber}
                      pageNumber={pageNumber}
                      onPageClick={onPageClick}
                      selected={pageNumber === currentPage}
                    />
                ))
            }
            {
                // eslint-disable-next-line no-nested-ternary
                (siblingsEnd < count - boundaryCount - 1)
                    ? <li>...</li>
                    : (count - boundaryCount > boundaryCount)
                        ? <PaginatorItem onPageClick={onPageClick} pageNumber={count - boundaryCount} selected={count - boundaryCount === currentPage} />
                        : null
            }
        </>
    );
};

PaginatorSiblings.propTypes = {
    count: PropTypes.number.isRequired,
    endPages: PropTypes.array.isRequired,
    currentPage: PropTypes.number.isRequired,
    boundaryCount: PropTypes.number.isRequired,
    siblingCount: PropTypes.number.isRequired,
    onPageClick: PropTypes.func.isRequired
};

export default PaginatorSiblings;
