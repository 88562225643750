import IcomoonIcon from 'src/components/IcomoonIcon';
import NetworkIcon from 'src/components/NetworkIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/analysisMetricListItem.scss';
import VisualizationTag from 'src/components/views/analysis/VisualizationTag';
import * as customPropTypes from 'src/customPropTypes';

const AnalysisMetricListItem = (props) => {
    const {
        isBasedOnPrivateStatistics, name, platformType, isCustomMetric, visualizationTags
    } = props;

    return (
        <div className={styles.wrapper}>
            {
                platformType && <NetworkIcon platformType={platformType} className={styles.networkIcon} />
            }
            <span className={styles.name}>
                {name}
                {
                    isBasedOnPrivateStatistics
                    && (
                    <IcomoonIcon
                      icon="key"
                      className={styles.icon}
                    />
                    )
                }
                {
                    isCustomMetric
                    && (
                    <IcomoonIcon
                      icon="metric-editor"
                      className={styles.icon}
                    />
                    )
                }
            </span>
            {
                visualizationTags.length > 0
                && (
                    <div className={styles.visualization}>
                        <VisualizationTag tag={visualizationTags[0]} big />
                    </div>
                )
            }
        </div>
    );
};

AnalysisMetricListItem.propTypes = {
    isBasedOnPrivateStatistics: PropTypes.bool,
    name: PropTypes.string.isRequired,
    platformType: PropTypes.string.isRequired,
    isCustomMetric: PropTypes.bool,
    visualizationTags: customPropTypes.tags
};

AnalysisMetricListItem.defaultProps = {
    isBasedOnPrivateStatistics: false,
    isCustomMetric: false,
    visualizationTags: []
};

export default AnalysisMetricListItem;
