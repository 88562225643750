import * as customPropTypes from 'src/customPropTypes';
import Body from 'src/components/Body';
import { connect } from 'react-redux';
import Footer from 'src/components/Footer';
import { getPackageStatus, getPackageInfo } from 'src/selectors/adminSettings';
import { selectClientName } from 'src/selectors/client';
import PackageExpiredInfoBody from 'src/components/PackageExpiredInfoBody';
import PackageInfoBody from 'src/components/views/settings/PackageInfoBody';
import React from 'react';
import ScrollView from 'src/components/ScrollView';
import SettingsHeader from 'src/components/header/SettingsHeader';
import PropTypes from 'prop-types';
import AdminSettingsLoader from 'src/components/views/settings/AdminSettingsLoader';

const AdminOverview = (props) => {
    const { packageStatus, clientName } = props;
    const { status } = packageStatus;
    return (
        <ScrollView dataViewName="package-info">
            <SettingsHeader headline={clientName} />
            <Body>
                {
                    status === 'expired'
                        ? <PackageExpiredInfoBody packageStatus={status} />
                        : (
                            <AdminSettingsLoader
                              dataSelector={getPackageInfo}
                              alwaysReload={false}
                            >
                                {(packageInfo) => <PackageInfoBody packageInfo={packageInfo} />}
                            </AdminSettingsLoader>
                        )
                }
            </Body>
            <Footer />
        </ScrollView>
    );
};

AdminOverview.propTypes = {
    packageStatus: customPropTypes.packageStatus.isRequired,
    clientName: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
    packageStatus: getPackageStatus(state),
    clientName: selectClientName(state)
});

export default connect(mapStateToProps)(AdminOverview);
