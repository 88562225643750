import React from 'react';
import { connect } from 'react-redux';
import Button from 'src/components/buttons/Button';
import { searchPostsRequest } from 'src/actions/posts';
import PropTypes from 'prop-types';
import PostSearchRequestContext from 'src/components/context/PostSearchRequestContext';
import withTooltip from 'src/components/withToolTip';

const ButtonWithTooltip = withTooltip(Button);

const PostRefreshButton = (props) => {
    const { disabled } = props;

    const handlePageChange = (searchRequestInput) => {
        const { searchPostsRequestAction } = props;
        searchPostsRequestAction(searchRequestInput);
    };
    return (
        <PostSearchRequestContext.Consumer>
            {
                (searchRequestInput) => (
                    <ButtonWithTooltip tooltip="Refresh posts" icon="refresh" onClick={() => { handlePageChange(searchRequestInput); }} disabled={disabled} />
                )
            }
        </PostSearchRequestContext.Consumer>
    );
};

PostRefreshButton.propTypes = {
    searchPostsRequestAction: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

PostRefreshButton.defaultProps = {
    disabled: false
};

export default connect(null, {
    searchPostsRequestAction: searchPostsRequest
})(PostRefreshButton);
