import {
    put, call, takeEvery, select
} from 'redux-saga/effects';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import {
    modalsHideCreateReport,
    modalsHideEditReport,
    modalHideDashboardReportDelete
} from 'src/actions/overlays';
import { parseDashboardReport } from 'src/parsers';
import { showNotification } from 'src/actions/notifications';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { selectDashboardTypeById } from 'src/selectors/ownership';
import { reportError } from 'src/utils/reportError';
import {
    SubmissionError, startAsyncValidation, stopAsyncValidation, formValueSelector
} from 'redux-form';
import { getTextForActiveReport } from 'src/components/labels/ReportStatusLabel';
import {
    DASHBOARD_REPORT_ADD_REQUEST,
    dashboardReportAddError,
    dashboardReportAddSuccess,
    DASHBOARD_REPORT_ACTIVATE_REQUEST,
    DASHBOARD_REPORT_DEACTIVATE_REQUEST,
    DASHBOARD_REPORT_DELETE_REQUEST,
    DASHBOARD_REPORT_UPDATE_REQUEST,
    dashboardReportActivateError,
    dashboardReportActivateSuccess,
    dashboardReportDeactivateError,
    dashboardReportDeactivateSuccess,
    dashboardReportDeleteError,
    dashboardReportDeleteSuccess,
    dashboardReportUpdateError,
    dashboardReportUpdateSuccess
} from 'src/actions/dashboardReports';
import { getDashboardReportParams } from 'src/utils/dashboardReport';
import { makeSelectDashboardReportById } from 'src/selectors/dashboardReports';

const selectDashboardReportById = makeSelectDashboardReportById();

export function* getDashboardIdAndDashboardType(dashboardReportId) {
    const dashboardReport = yield select(selectDashboardReportById, dashboardReportId);
    const { dashboardId } = dashboardReport;
    const dashboardType = yield select(selectDashboardTypeById, dashboardId);
    return {
        dashboardReport,
        dashboardId,
        dashboardType
    };
}

function* dashboardReportAddRequest(action) {
    const { payload } = action;
    const { formValues } = payload;
    const dashboardType = yield select(selectDashboardTypeById, formValues.dashboardId);
    const params = getDashboardReportParams(formValues, dashboardType);
    try {
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/add-dashboard-report');
        if (response) {
            const { jsonData } = response;
            const parsedDashboardReport = parseDashboardReport(jsonData.dashboardReport);
            yield put(dashboardReportAddSuccess(parsedDashboardReport));
            yield put(modalsHideCreateReport(formValues.dashboardId));
            yield put(showNotification(`Your report settings were successfully updated. Report will be sent ${getTextForActiveReport(parsedDashboardReport)}.`));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        reportError(applicationError);
        yield put(dashboardReportAddError(new SubmissionError({ _error: applicationError })));
    }
}

function* updateDashboardReport(action) {
    const { payload } = action;
    const { formValues } = payload;
    const dashboardType = yield select(selectDashboardTypeById, formValues.dashboardId);
    const params = getDashboardReportParams(formValues, dashboardType);

    try {
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/update-dashboard-report');

        if (response) {
            const { jsonData } = response;
            const parsedDashboardReport = parseDashboardReport(jsonData.dashboardReport);
            yield put(dashboardReportUpdateSuccess(parsedDashboardReport));
            yield put(modalsHideEditReport(formValues.id));
            yield put(showNotification(`Your report settings were successfully updated. Report will be sent ${getTextForActiveReport(parsedDashboardReport)}.`));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        reportError(applicationError);
        yield put(dashboardReportUpdateError(new SubmissionError({ _error: applicationError })));
    }
}

const creatReportFormName = 'createReport';
const fieldName = 'advancedReportSchedule';
const reportFormValueSelector = formValueSelector(creatReportFormName);

function* validationCronExpression(action) {
    const { meta, payload } = action;
    if (meta.form === creatReportFormName && meta.field === fieldName) {
        yield put(startAsyncValidation(creatReportFormName, fieldName));
        try {
            const serverRequest = createServerRequest({ expression: payload.cronExpression });
            const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/validate-dashboard-report-cron-expression');

            // a protection against multiple async validation, drop the rest validations if field value do not match
            const advancedReportSchedule = yield select(reportFormValueSelector, fieldName);
            if (advancedReportSchedule.cronExpression === payload.cronExpression) {
                if (response) {
                    yield put(stopAsyncValidation(creatReportFormName, { }));
                }
                if (serverError) {
                    throw serverError;
                }
            }
        } catch (applicationOrServerError) {
            yield put(stopAsyncValidation(creatReportFormName, { [fieldName]: applicationOrServerError.message }));
        }
    }
}

function* dashboardReportDeactivateRequest(action) {
    const { payload } = action;
    const { dashboardReportId } = payload;
    const { dashboardId, dashboardType } = yield call(getDashboardIdAndDashboardType, dashboardReportId);
    try {
        const serverRequest = createServerRequest({ dashboardReportId, dashboardId, dashboardType });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/deactivate-dashboard-report');
        if (response) {
            const { jsonData } = response;
            const parsedDashboardReport = parseDashboardReport(jsonData.dashboardReport);
            yield put(dashboardReportDeactivateSuccess(parsedDashboardReport));
            yield put(showNotification('Report successfully deactivated.', 'success'));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification(`Report could not be activated because: ${applicationError.message}`), 'error');
        yield put(dashboardReportDeactivateError(applicationError));
    }
}

function* dashboardReportActivateRequest(action) {
    const { payload } = action;
    const { dashboardReportId } = payload;
    const { dashboardId, dashboardType } = yield call(getDashboardIdAndDashboardType, dashboardReportId);
    try {
        const serverRequest = createServerRequest({ dashboardReportId, dashboardId, dashboardType });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/activate-dashboard-report');
        if (response) {
            const { jsonData } = response;
            const parsedDashboardReport = parseDashboardReport(jsonData.dashboardReport);
            yield put(dashboardReportActivateSuccess(parsedDashboardReport));
            yield put(showNotification('Report successfully activated.', 'success'));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification(`Report could not be activated because: ${applicationError.message}`, 'error'));
        yield put(dashboardReportActivateError(applicationError));
    }
}

function* dashboardReportDeleteRequest(action) {
    const { payload } = action;
    const { dashboardReportId } = payload;
    const { dashboardId, dashboardType } = yield call(getDashboardIdAndDashboardType, dashboardReportId);
    try {
        const serverRequest = createServerRequest({ dashboardReportId, dashboardId, dashboardType });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/delete-dashboard-report');
        if (response) {
            yield put(modalHideDashboardReportDelete());
            yield put(modalsHideEditReport(dashboardReportId));
            yield put(dashboardReportDeleteSuccess(dashboardReportId, dashboardId));
            yield put(showNotification('Your report was successfully deleted.'));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification(`Report could not be activated because: ${applicationError.message}`, 'error'));
        yield put(dashboardReportDeleteError(new SubmissionError({ _error: applicationError })));
    }
}

export default function* dashboardReportsSagas() {
    yield takeEvery(DASHBOARD_REPORT_ADD_REQUEST, dashboardReportAddRequest);
    yield takeEvery(DASHBOARD_REPORT_UPDATE_REQUEST, updateDashboardReport);
    yield takeEvery(DASHBOARD_REPORT_DEACTIVATE_REQUEST, dashboardReportDeactivateRequest);
    yield takeEvery(DASHBOARD_REPORT_ACTIVATE_REQUEST, dashboardReportActivateRequest);
    yield takeEvery(DASHBOARD_REPORT_DELETE_REQUEST, dashboardReportDeleteRequest);
    yield takeEvery('@@redux-form/CHANGE', validationCronExpression);
}
