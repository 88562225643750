import * as customPropTypes from 'src/customPropTypes';
import * as exportActions from 'src/actions/exports';
import { connect } from 'react-redux';
import { createSingleServerRequestForExport } from 'src/requestHandling/metricDataRequests';
import { makeSelectPaginationAndSorting } from 'src/selectors/tablePagination';
import PropTypes from 'prop-types';

const DashboardMetricExportActionProvider = (props) => {
    const {
        triggerDashboardMetricExportAction,
        dashboardMetric,
        paginationAndSorting,
        serverRequest,
        renderButton
    } = props;
    const fileName = dashboardMetric.displayName;
    const dashboardMetricExportActionWithSortingParameters = (fileType) => {
        const singleServerRequestForExport = createSingleServerRequestForExport(serverRequest, paginationAndSorting, fileType, { dashboardId: dashboardMetric.dashboardId });
        triggerDashboardMetricExportAction(singleServerRequestForExport, fileName);
    };
    return renderButton(dashboardMetricExportActionWithSortingParameters);
};

DashboardMetricExportActionProvider.propTypes = {
    triggerDashboardMetricExportAction: PropTypes.func.isRequired,
    paginationAndSorting: customPropTypes.paginationAndSorting,
    serverRequest: customPropTypes.serverRequest.isRequired,
    dashboardMetric: customPropTypes.dashboardMetric.isRequired,
    renderButton: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const selectPaginationAndSorting = makeSelectPaginationAndSorting();
    return (state, ownProps) => {
        const { identifier } = ownProps.serverRequest.request;
        return {
            paginationAndSorting: selectPaginationAndSorting(state, identifier)
        };
    };
};

export default connect(makeMapStateToProps, {
    triggerDashboardMetricExportAction: exportActions.triggerDashboardMetricExport,
})(DashboardMetricExportActionProvider);
