import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Modal from 'src/components/modals/layout/Modal';
import * as customPropTypes from 'src/customPropTypes';
import * as routeActions from 'react-router-redux';
import PropTypes from 'prop-types';
import { tagManagerRoute } from 'src/routePaths';
import { makeSelectPostTagById } from 'src/selectors/postTags';
import PostTagEditForm from 'src/components/forms/PostTagEditForm';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Button from 'src/components/buttons/Button';
import { isSubmitting, isPristine, submit } from 'redux-form';
import ErrorView from 'src/components/errors/ErrorView';
import { postTagEditRequestFormAction } from 'src/actions/postTags';
import { postTagEditFormName } from 'src/utils/postTag';
import { ensurePermission, writePermissions } from 'src/middleware/userPermissionCheck';
import * as overlayActions from 'src/actions/overlays';
import PostTagPostSearch from 'src/components/views/postTags/postTagManager/PostTagPostSearch';
import styles from 'src/stylesheets/views/postTags/postTagManager/maximizedEditPostTag.scss';
import { postTagsPostInfoGetRequest } from 'src/actions/postTagsPostInfo';

const MaximizedEditPostTag = (props) => {
    const [isPostTagRuleInEditMode, changePostTagRuleEditMode] = useState(false);

    useEffect(() => {
        const { postTagsPostInfoGetRequestAction } = props;
        postTagsPostInfoGetRequestAction();
    }, []);

    const handlePostTagRuleEditMode = (isEditing) => {
        changePostTagRuleEditMode(isEditing);
    };

    const handleCloseModal = () => {
        const { routePushAction } = props;
        routePushAction({ pathname: tagManagerRoute });
    };

    const {
        postTag, submitting, submitAction, modalShowPostTagsDeleteAction, isFormPristine
    } = props;
    if (postTag === false) {
        return <ErrorView error={{ message: 'The tag you tried to access does not exist.' }} headline="Tag not found" />;
    }

    const initialValues = {
        id: postTag.id,
        name: postTag.name,
        archived: postTag.archived,
        automation: postTag.automation,
        automationInitial: postTag.automation
    };

    const deleteButton = (
        <Button
          onClick={() => { modalShowPostTagsDeleteAction([postTag.id]); }}
          warning
          disabled={isPostTagRuleInEditMode}
          icon="delete"
          label="Delete tag"
        />
    );
    return (
        <Modal dataViewName="maximized-edit-post-tag" onHide={handleCloseModal}>
            <Modal.Dialog fullscreen>
                <Modal.Header onHide={handleCloseModal}>
                    <Modal.Title>Tag details</Modal.Title>
                </Modal.Header>
                <Modal.Body padded={false} className={styles.body}>
                    <div className={styles.wrapper}>
                        <div className={styles.automations}>
                            <PostTagEditForm
                              form={postTagEditFormName}
                              onSubmit={postTagEditRequestFormAction}
                              initialValues={initialValues}
                              postTag={postTag}
                              handlePostTagRuleEditMode={handlePostTagRuleEditMode}
                            />
                        </div>
                        <div className={styles.postSearch}>
                            <PostTagPostSearch postTag={postTag} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer buttonsLeft={deleteButton}>
                    <ButtonGroup>
                        <Button label="Cancel" disabled={isPostTagRuleInEditMode} onClick={handleCloseModal} />
                        <Button
                          action
                          loading={submitting}
                          disabled={isFormPristine || isPostTagRuleInEditMode}
                          onClick={() => { submitAction(postTagEditFormName); }}
                          label="Save"
                        />
                    </ButtonGroup>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>
    );
};

MaximizedEditPostTag.propTypes = {
    postTagId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    postTag: PropTypes.oneOfType([customPropTypes.postTag, PropTypes.bool]).isRequired,
    modalShowPostTagsDeleteAction: PropTypes.func.isRequired,
    routePushAction: PropTypes.func.isRequired,
    submitAction: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    isFormPristine: PropTypes.bool.isRequired,
    postTagsPostInfoGetRequestAction: PropTypes.func.isRequired
};

const mapStatesToProps = () => {
    const selectPostTagById = makeSelectPostTagById();
    return (state, ownProps) => {
        const { postTagId } = ownProps.params;
        return {
            postTagId: postTagId || false,
            postTag: selectPostTagById(state, postTagId),
            submitting: isSubmitting(postTagEditFormName)(state),
            isFormPristine: isPristine(postTagEditFormName)(state)
        };
    };
};

export default connect(mapStatesToProps, {
    routePushAction: routeActions.push,
    submitAction: submit,
    modalShowPostTagsDeleteAction: ensurePermission(overlayActions.modalShowPostTagsDelete, writePermissions.deletePostTag),
    postTagsPostInfoGetRequestAction: postTagsPostInfoGetRequest
})(MaximizedEditPostTag);
