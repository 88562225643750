import ContentBlock from 'src/components/views/ContentBlock';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import { modalsShowSubscriptionModal } from 'src/actions/overlays';
import noAccessImage from 'src/resources/illustrations/no-access-68.png';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/views/settings/settings.scss';
import DeleteAccount from 'src/components/views/settings/DeleteAccount';

const PackageExpiredInfoBody = (props) => {
    const { packageStatus, modalsShowSubscriptionModalAction } = props;
    const footer = (
        <Button
          label="Adjust package"
          action
          onClick={() => { modalsShowSubscriptionModalAction(packageStatus); }}
        />
    );

    return (
        <div className={styles.wrapper}>
            <div className={styles.leftSection}>
                <ContentBlock headline="Plan" footerRight={footer}>
                    <div className={styles.noAccess}>
                        <img src={noAccessImage} alt="empty" />
                    </div>
                </ContentBlock>
                <DeleteAccount />
            </div>
            <div className={styles.rightSection} />
        </div>
    );
};

PackageExpiredInfoBody.propTypes = {
    modalsShowSubscriptionModalAction: PropTypes.func.isRequired,
    packageStatus: PropTypes.string.isRequired
};

export default connect(null, {
    modalsShowSubscriptionModalAction: modalsShowSubscriptionModal
})(PackageExpiredInfoBody);
