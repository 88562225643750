import AccountDetail from 'src/components/views/settings/account/AccountDetail';
import Body from 'src/components/Body';
import Footer from 'src/components/Footer';
import React from 'react';
import ScrollView from 'src/components/ScrollView';
import SettingsHeader from 'src/components/header/SettingsHeader';
import { selectLoggedInUser } from 'src/selectors/users';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import styles from 'src/stylesheets/views/settings/settings.scss';

const Account = (props) => {
    const { user } = props;
    const { firstName, lastName } = user;
    return (
        <ScrollView dataViewName="account">
            <SettingsHeader headline={`Welcome, ${firstName} ${lastName}`} />
            <Body>
                <div className={styles.sectionWrapper}>
                    <div className={styles.leftSection}>
                        <AccountDetail user={user} />
                    </div>
                    <div className={styles.rightSection} />
                </div>
            </Body>
            <Footer />
        </ScrollView>
    );
};

Account.propTypes = {
    user: customPropTypes.user.isRequired
};

const mapStateToProps = (state) => ({
    user: selectLoggedInUser(state)
});

export default connect(mapStateToProps)(Account);
