import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import React from 'react';
import { getLabelAndIconByIsPrivateStatistic } from 'src/utils/dataSources';

const wrapperStyle = {
    width: 400,
    padding: 20,
    fontSize: '13px',
    lineHeight: '1.38'
};

const PrivatePublicLevelDataDescriptionPopover = ({ isPrivateStatistic }) => {
    const { label } = getLabelAndIconByIsPrivateStatistic(isPrivateStatistic);
    return (
        <Popover id="privatePublicLevelDataDescriptionPopover">
            <div style={wrapperStyle}>
                <b>{label}</b><br /><br />
                {
                    isPrivateStatistic
                    && (
                        <div>
                            Private data may only be seen by the account owner or admin of a profile. Ex. The total amount of times an Instagram post has been uniquely viewed, also known as the reach.
                            This data may only be accessed once you have verified that you have the specific permissions to the desired profile.
                        </div>
                    )
                }
                {
                    !isPrivateStatistic
                    && (
                        <div>
                            Public data is publicly available for anyone to view. It is quite often used in competitive benchmarking as you can compare the performance of your profile with others. Ex. The number of followers of an Instagram account.
                        </div>
                    )
                }
            </div>
        </Popover>
    );
};

PrivatePublicLevelDataDescriptionPopover.propTypes = {
    isPrivateStatistic: PropTypes.bool.isRequired
};

export default PrivatePublicLevelDataDescriptionPopover;
