import React from 'react';
import TopNav from 'src/components/navigations/TopNav';
import TopNavTextLink from 'src/components/buttons/TopNavTextLink';
import { dataSourcesRoute, dataRoutes } from 'src/routePaths';

const DataSourcesNav = () => (
    <TopNav>
        <TopNavTextLink to={dataSourcesRoute} alsoActiveOn={dataRoutes}>Data source library</TopNavTextLink>
    </TopNav>
);

export default DataSourcesNav;
