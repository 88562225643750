import React, { useState, useEffect } from 'react';
import AddOrEditPostTagRule from 'src/components/views/postTags/postTagManager/postTagRules/AddOrEditPostTagRule';
import * as customPropTypes from 'src/customPropTypes';
import { makeSelectPostTagRuleByIds } from 'src/selectors/postTagRules';
import { connect } from 'react-redux';
import { ToggleSwitch } from 'src/components/forms/fields';
import { Field } from 'redux-form';
import styles from 'src/stylesheets/views/postTags/postTagManager/postTagRules.scss';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PostTagRuleList from 'src/components/views/postTags/postTagManager/postTagRules/PostTagRuleList';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const PostTagRules = (props) => {
    const {
        postTag, postTagRules, archivedFormValue, handlePostTagRuleEditMode, isPostTagFormSubmitting
    } = props;

    const [showList, changeShowList] = useState(true);
    const [itemIndex, changeItemIndex] = useState(0);
    const [duplicateRule, changeDuplicateRule] = useState(false);

    const init = () => {
        changeShowList(true);
        handlePostTagRuleEditMode(false);
        changeDuplicateRule(false);
    };

    useEffect(() => {
        init();
    }, [postTag, postTagRules]);

    const openAddOrEditPostTagRule = (index) => {
        changeItemIndex(index);
        changeShowList(false);
        handlePostTagRuleEditMode(true);
    };

    const onBackClick = () => {
        init();
    };

    const onNewClick = () => {
        openAddOrEditPostTagRule(-1);
    };

    const onEditClick = (index) => {
        openAddOrEditPostTagRule(index);
    };

    const onCopyClick = (index) => {
        openAddOrEditPostTagRule(index);
        changeDuplicateRule(true);
    };

    const postTagRule = postTagRules[itemIndex] || false;
    return (
        <div className={classnames(styles.wrapper, { [styles.disabled]: archivedFormValue || isPostTagFormSubmitting })}>
            {
                showList && (
                    <div className={styles.mainList}>
                        <div className={styles.mainListHeader}>
                            <div className={styles.mainListTitle}>
                                <IcomoonIcon icon="auto" className={styles.icon} />Automation
                            </div>
                            <Field
                              name="automation"
                              component={ToggleSwitch}
                              disabled={postTagRules.length === 0 || isPostTagFormSubmitting}
                            />
                        </div>
                        {
                            postTagRules.length === 0
                            && (
                                <div className={styles.tagRuleDescription}>Create rule sets with filters that automatically tag your posts.</div>
                            )
                        }
                        {
                            postTagRules.length > 0
                            && (
                                <PostTagRuleList postTagRules={postTagRules} onEditClick={onEditClick} onCopyClick={onCopyClick} />
                            )
                        }
                        <div className={styles.newRule} onClick={onNewClick}>
                            <IcomoonIcon icon="plus" className={styles.icon} />New rule
                        </div>
                    </div>
                )
            }
            {
                !showList && (
                    <AddOrEditPostTagRule
                      postTagRule={postTagRule}
                      postTag={postTag}
                      onBackClick={onBackClick}
                      duplicateRule={duplicateRule}
                    />
                )
            }
        </div>
    );
};

PostTagRules.propTypes = {
    postTag: customPropTypes.postTag.isRequired,
    archivedFormValue: PropTypes.bool.isRequired,
    postTagRules: customPropTypes.postTagRules.isRequired,
    handlePostTagRuleEditMode: PropTypes.func.isRequired,
    isPostTagFormSubmitting: PropTypes.bool.isRequired
};

const makeMapStateToProps = () => {
    const selectPostTagRuleByIds = makeSelectPostTagRuleByIds();
    return (state, ownProps) => {
        const { postTag } = ownProps;
        return {
            postTagRules: selectPostTagRuleByIds(state, postTag.postTagRuleIds)
        };
    };
};

export default connect(makeMapStateToProps)(PostTagRules);
