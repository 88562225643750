import { ensurePermission, writePermissions } from 'src/middleware/userPermissionCheck';
import { modalsShowGroupSelectorProfile, modalsShowProfileDelete } from 'src/actions/overlays';
import * as customPropTypes from 'src/customPropTypes';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import { makeSelectProfileById } from 'src/selectors/profiles';
import MenuButton from 'src/components/buttons/MenuButton';
import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
    width: '180px'
};

const ProfileOptionsPopover = (props) => {
    const {
        profile,
        modalsShowProfileDeleteAction,
        modalsShowGroupSelectorAction,
        hidePopover,
        privateStatsButtonRef
    } = props;
    return (
        <Popover id="profileOptionsPopover">
            <div style={styles}>
                <ButtonGroup vertical>
                    <MenuButton
                      label="Assign groups"
                      onClick={() => {
                          modalsShowGroupSelectorAction([profile.id]);
                          hidePopover();
                      }}
                    />
                    {
                        privateStatsButtonRef.current
                        && (
                            <MenuButton
                              onClick={() => {
                                  privateStatsButtonRef.current.handleToggle();
                                  hidePopover();
                              }}
                              label="Activate private stats"
                            />
                        )
                    }
                    <MenuButton
                      label="Delete"
                      onClick={() => {
                          modalsShowProfileDeleteAction([profile.id]);
                          hidePopover();
                      }}
                    />
                </ButtonGroup>
            </div>
        </Popover>
    );
};

ProfileOptionsPopover.propTypes = {
    profile: customPropTypes.profile.isRequired,
    modalsShowProfileDeleteAction: PropTypes.func.isRequired,
    modalsShowGroupSelectorAction: PropTypes.func.isRequired,
    hidePopover: PropTypes.func.isRequired,
    privateStatsButtonRef: PropTypes.object.isRequired
};

const makeMapStateToProps = () => {
    const selectProfileById = makeSelectProfileById();
    return (state, ownProps) => {
        const { profileId } = ownProps;
        const profile = selectProfileById(state, profileId);
        return { profile };
    };
};

export default connect(makeMapStateToProps, {
    modalsShowProfileDeleteAction: ensurePermission(modalsShowProfileDelete, writePermissions.deleteProfile),
    modalsShowGroupSelectorAction: ensurePermission(modalsShowGroupSelectorProfile, writePermissions.assignProfileToGroups)
})(ProfileOptionsPopover);
