import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import adsEmpty from 'src/resources/illustrations/ads_empty.png';
import noProfileMatch from 'src/resources/illustrations/profiles-filters.png';
import { makeSelectIsOneItemSelected } from 'src/selectors/lists';
import { makeSelectGroupById } from 'src/selectors/groups';
import { formName } from 'src/components/forms/AdAccountFilterBarForm';
import { initialValues } from 'src/components/forms/AccountFilterBarForm';
import AdAccountListHeader from 'src/components/views/adAccounts/AdAccountListHeader';
import withStickyListHeaderHandling from 'src/components/withStickyListHeaderHandling';
import AdAccountsSidebar from 'src/components/views/adAccounts/AdAccountsSidebar';
import ContentWithSidebar from 'src/components/views/ContentWithSidebar';
import React from 'react';
import ScrollView from 'src/components/ScrollView';
import Body from 'src/components/Body';
import AccountList from 'src/components/AccountList';
import { connect } from 'react-redux';
import {
    makeSelectAdAccountIdsWithListFiltersApplied,
    selectAdAccountIds
} from 'src/selectors/adAccounts';
import PropTypes from 'prop-types';
import AdAccountsHeader from 'src/components/header/AdAccountsHeader';
import AdAccountListItemContainer from 'src/components/views/adAccounts/AdAccountListItemContainer';
import withListStateHandling from 'src/components/bulkList/withListStateHandling';
import ListPageWrapper from 'src/components/ListPageWrapper';
import { getFormValues } from 'redux-form';
import AdAccountUseCasesHealthStatus from 'src/components/views/adAccounts/AdAccountUseCasesHealthStatus';
import IntegrationEmptyState from 'src/components/views/IntegrationEmptyState';
import { selectSpaceLimits } from 'src/selectors/spaceLimits';
import { getLimitFromSpaceLimit } from 'src/utils/accounts';
import * as customPropTypes from 'src/customPropTypes';

const listName = 'adAccounts';

const AdAccountListHeaderWithStickyHeaderHandling = withStickyListHeaderHandling(AdAccountListHeader);

const ListPageWrapperWithListStateHandling = withListStateHandling(ListPageWrapper, listName);

const brokenProfileStyle = {
    paddingTop: '24px'
};

const AdAccounts = ({
    allAdAccountIds, filteredAdAccountIds, isOneItemSelected, freeTextFilter, spaceLimits
}) => {
    const totalAdAccountsCount = allAdAccountIds.length;
    const sidebar = <AdAccountsSidebar listName={listName} />;
    const adAccountLimit = getLimitFromSpaceLimit(spaceLimits, 'adAccounts');

    if (adAccountLimit === 0) {
        return (
            <ScrollView dataViewName="adAccounts">
                <Body key="adAccounts">
                    <IntegrationEmptyState
                      image={adsEmpty}
                      title="Gain insights into your ads performance"
                      message="To access comprehensive campaign insights with unmatched customization, upgrade your ad account limit by contacting our customer success team."
                    />
                </Body>
            </ScrollView>
        );
    }
    return (
        <ContentWithSidebar sidebar={sidebar} showSidebar={isOneItemSelected}>
            <ScrollView dataViewName="adAccounts">
                <AdAccountsHeader />
                <Body key="adAccounts">
                    {
                        totalAdAccountsCount > 0 && (
                            <ListPageWrapperWithListStateHandling listItemIds={allAdAccountIds}>
                                <div style={brokenProfileStyle}>
                                    <AdAccountUseCasesHealthStatus />
                                </div>
                                <AdAccountListHeaderWithStickyHeaderHandling
                                  listItemIdsToHandle={filteredAdAccountIds}
                                  allProfileIds={allAdAccountIds}
                                  offsetTop={68}
                                  listName={listName}
                                />
                                {filteredAdAccountIds.length > 0 && (
                                    <AccountList
                                      renderAccountItem={(id) => (
                                          <AdAccountListItemContainer adAccountId={id} listName={listName} highlight={freeTextFilter} />)}
                                      accountIds={filteredAdAccountIds}
                                    />
                                )}
                            </ListPageWrapperWithListStateHandling>
                        )
                    }
                    {
                        totalAdAccountsCount > 0 && filteredAdAccountIds.length < 1
                        && (
                            <EmptyInfoScreen
                              image={noProfileMatch}
                              title="No ad accounts match the applied filters"
                            />
                        )
                    }
                    {
                        totalAdAccountsCount === 0
                        && (
                            <EmptyInfoScreen
                              image={adsEmpty}
                              width={500}
                              title="You have no ad accounts yet"
                              message="Here is where you will be able to manage all your ad accounts."
                            />
                        )
                    }
                </Body>
            </ScrollView>
        </ContentWithSidebar>
    );
};

const makeMapStateToProps = () => {
    const formValueSelector = getFormValues(formName);
    const selectAdAccountIdsWithListFiltersApplied = makeSelectAdAccountIdsWithListFiltersApplied();
    const selectGroupById = makeSelectGroupById();
    const selectIsOneItemSelected = makeSelectIsOneItemSelected();

    return (state) => {
        const {
            freeTextFilter, groupFilter, sortBy
        } = formValueSelector(state) || initialValues;

        const allAdAccountIds = selectAdAccountIds(state);
        const group = selectGroupById(state, groupFilter);
        return {
            allAdAccountIds,
            freeTextFilter,
            filteredAdAccountIds: selectAdAccountIdsWithListFiltersApplied(state, freeTextFilter, group, sortBy),
            isOneItemSelected: selectIsOneItemSelected(state, listName),
            spaceLimits: selectSpaceLimits(state)
        };
    };
};

AdAccounts.propTypes = {
    allAdAccountIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    filteredAdAccountIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    freeTextFilter: PropTypes.string.isRequired,
    isOneItemSelected: PropTypes.bool.isRequired,
    spaceLimits: customPropTypes.spaceLimits.isRequired
};

export default connect(makeMapStateToProps)(AdAccounts);
