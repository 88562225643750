import * as customPropTypes from 'src/customPropTypes';
import BrokenProfileUseCasesWarning from 'src/components/account/BrokenAccountUseCasesWarning';
import Button from 'src/components/buttons/Button';
import { getAuthenticationsWithUnfulfilledUseCasesOpenRoute } from 'src/utils/routes';
import { Link } from 'react-router';
import React from 'react';
import styles from 'src/stylesheets/views/profiles/profileUseCasesHealthStatus.scss';
import { renderAccountType } from 'src/utils/accountType';

const getCountOfBrokenAccounts = (brokenAccountUseCasesByPlatformType) => {
    let numberOfAccounts = 0;
    Object.values(brokenAccountUseCasesByPlatformType).forEach((brokenAccountUseCases) => {
        numberOfAccounts += brokenAccountUseCases.length;
    });
    return numberOfAccounts;
};

const AccountUseCasesHealthStatus = (props) => {
    const { brokenAccountUseCasesByPlatformType, accountType } = props;
    const brokenUseCaseAccountsCount = getCountOfBrokenAccounts(brokenAccountUseCasesByPlatformType);
    const link = getAuthenticationsWithUnfulfilledUseCasesOpenRoute();
    return (
        <div className={styles.wrapper}>
            <div className={styles.left}>
                <BrokenProfileUseCasesWarning failedAccountCount={brokenUseCaseAccountsCount} label={renderAccountType(accountType)} />
            </div>
            <div className={styles.right}>
                {
                    brokenUseCaseAccountsCount > 0
                    && (
                    <Link to={link} className={styles.link}>
                        <Button label="View all issues" stretch small />
                    </Link>
                    )
                }
            </div>
        </div>
    );
};

AccountUseCasesHealthStatus.propTypes = {
    accountType: customPropTypes.accountTypes.isRequired,
    brokenAccountUseCasesByPlatformType: customPropTypes.brokenAccountsUseCasesByPlatformType.isRequired
};

export default AccountUseCasesHealthStatus;
