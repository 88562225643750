import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from 'src/stylesheets/views/postTags/postTagging/postTile.scss';
import classnames from 'classnames';
import { makeSelectFieldValue } from 'src/selectors/lists';

const PostTileBorder = (props) => {
    const {
        isSelected, children
    } = props;
    return (
        <div className={classnames(styles.bodyWrapper, { [styles.selected]: isSelected })}>
            {children}
        </div>
    );
};

PostTileBorder.propTypes = {
    children: PropTypes.node.isRequired,
    postId: PropTypes.string.isRequired,
    listName: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired
};

const makeMapStateToProps = () => {
    const selectFieldValue = makeSelectFieldValue();
    return (state, ownProps) => {
        const { postId, listName } = ownProps;
        return {
            isSelected: selectFieldValue(state, postId, listName)
        };
    };
};

export default connect(makeMapStateToProps)(PostTileBorder);
