import * as customMetricActions from 'src/actions/customMetric';
import * as customPropTypes from 'src/customPropTypes';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import MenuButton from 'src/components/buttons/MenuButton';
import { modalsShowDeleteCustomMetric } from 'src/actions/overlays';
import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import React from 'react';

const buttonStyles = {
    width: '118px'
};

const CustomMetricOptionPopover = (props) => {
    const { customMetric } = props;

    const onCustomMetricDelete = () => {
        const { modalShowCustomMetricDeleteAction, hidePopover } = props;
        modalShowCustomMetricDeleteAction(customMetric.id);
        hidePopover();
    };

    const onCustomMetricDuplicate = () => {
        const { customMetricDuplicationRequestAction, hidePopover } = props;
        customMetricDuplicationRequestAction(customMetric);
        hidePopover();
    };

    return (
        <Popover id="customMetricOptionsPopover">
            <div style={buttonStyles}>
                <ButtonGroup vertical>
                    <MenuButton
                      label="Duplicate"
                      onClick={onCustomMetricDuplicate}
                    />
                    <MenuButton
                      label="Delete"
                      onClick={onCustomMetricDelete}
                    />
                </ButtonGroup>
            </div>
        </Popover>
    );
};

CustomMetricOptionPopover.propTypes = {
    customMetric: customPropTypes.metric.isRequired,
    modalShowCustomMetricDeleteAction: PropTypes.func.isRequired,
    customMetricDuplicationRequestAction: PropTypes.func.isRequired,
    hidePopover: PropTypes.func
};

export default connect(null, {
    modalShowCustomMetricDeleteAction: modalsShowDeleteCustomMetric,
    customMetricDuplicationRequestAction: customMetricActions.customMetricDuplicateRequest
})(CustomMetricOptionPopover);
