import CustomMetricsFilterBarForm from 'src/components/forms/CustomMetricsFilterBarForm';
import React from 'react';
import styles from 'src/stylesheets/listHeader.scss';
import classnames from 'classnames';

const CustomMetricsListHeader = () => (
    <div className={styles.listHeader}>
        <div className={classnames(styles.filterBar, styles.withoutCheckbox)}>
            <CustomMetricsFilterBarForm />
        </div>
    </div>
);

export default CustomMetricsListHeader;
