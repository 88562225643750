import * as customPropTypes from 'src/customPropTypes';
import * as routeActions from 'react-router-redux';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import CustomMetricInfo from 'src/components/views/custom-metrics/CustomMetricInfo';
import CustomMetricPopover from 'src/components/popovers/CustomMetricOptionPopover';
import MetricDataSourceList from 'src/components/views/MetricDataSourceList';
import { modalShowAddMetricToDashboard } from 'src/actions/overlays';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/views/customMetrics/customMetricListItem.scss';
import withPopover from 'src/components/withPopover';

const ButtonWithPopover = withPopover(Button);

const CustomMetricListItem = (props) => {
    const {
        customMetric, loggedInUser, onAddMetricToDashboardClickAction
    } = props;
    const { summary, dataSources, id } = customMetric;
    const customMetricOptionsPopover = (
        <CustomMetricPopover customMetric={customMetric} />
    );

    const onCustomMetricClick = () => {
        const { routePushAction, link } = props;
        routePushAction(link);
    };

    return (
        <div className={styles.listItem}>
            <div className={styles.metricWrapper}>
                <div className={styles.nameAndSummary} onClick={onCustomMetricClick}>
                    <div>
                        <CustomMetricInfo loggedInUser={loggedInUser} customMetric={customMetric} />
                    </div>
                    {
                        summary
                        && (
                            <div className={styles.summary}>
                                {summary}
                            </div>
                        )
                    }
                </div>
                <div className={styles.dataSources}>
                    <MetricDataSourceList dataSources={dataSources} />
                </div>
            </div>
            <div className={styles.buttonWrapper}>
                <ButtonGroup>
                    <Button
                      small
                      label="Add to dashboard"
                      className="list-hover-button-opacity"
                      onClick={() => { onAddMetricToDashboardClickAction(id, customMetric.visualization.type === 'table'); }}
                    />
                    <ButtonWithPopover
                      small
                      icon="more"
                      overlay={customMetricOptionsPopover}
                      placement="bottom-end"
                      className="list-hover-button-opacity"
                    />
                </ButtonGroup>
            </div>
        </div>
    );
};

CustomMetricListItem.propTypes = {
    customMetric: customPropTypes.metric.isRequired,
    link: customPropTypes.linkTo.isRequired,
    onAddMetricToDashboardClickAction: PropTypes.func.isRequired,
    loggedInUser: customPropTypes.user.isRequired,
    routePushAction: PropTypes.func.isRequired
};

export default connect(null, {
    routePushAction: routeActions.push,
    onAddMetricToDashboardClickAction: modalShowAddMetricToDashboard
})(CustomMetricListItem);
