import Checkbox from 'src/components/forms/inputs/Checkbox';
import { connect } from 'react-redux';
import { makeSelectFieldValue } from 'src/selectors/lists';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { toggleListValue } from 'src/actions/lists';

const ListHandlingCheckbox = (props) => {
    const {
        name,
        value,
        onChange,
        listName,
        disabled
    } = props;
    return (
        <Checkbox
          name={name}
          checked={value}
          onChange={() => onChange(listName, name)}
          disabled={disabled}
        />
    );
};

ListHandlingCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    listName: PropTypes.string.isRequired,
    disabled: PropTypes.bool
};

ListHandlingCheckbox.defaultPropTypes = {
    disabled: false
};

const makeMapStateToProps = () => {
    const selectFieldValue = makeSelectFieldValue();

    return (state, ownProps) => ({
        value: selectFieldValue(state, ownProps.name, ownProps.listName)
    });
};

export default memo(connect(makeMapStateToProps, {
    onChange: toggleListValue
})(ListHandlingCheckbox));
