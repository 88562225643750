import Date from 'src/components/Date';
import ContentBlock from 'src/components/views/ContentBlock';
import EnrollMultiFactorAuthenticationButton from 'src/components/views/settings/EnrollMultiFactorAuthenticationButton';
import React from 'react';
import * as customPropTypes from 'src/customPropTypes';

const MultiFactorAuthenticationEnrollmentState = (props) => {
    const { state } = props;

    let content = <div>Multi-factor authentication is not yet enabled for this connection.</div>;
    let button = <EnrollMultiFactorAuthenticationButton />;

    if (state && state.status === 'confirmed') {
        content = <div>Multi-factor authentication is activated for this account since <Date format="datetime" date={state.enrolledAt} dateInTimezone={state.enrolledAtTimeZone} appendTimezone />.</div>;
        button = null;
    }

    return (
        <ContentBlock headline="Multi-factor authentication" footerRight={button}>
            { content }
        </ContentBlock>
    );
};

MultiFactorAuthenticationEnrollmentState.propTypes = {
    state: customPropTypes.multiFactorAuthenticationEnrollmentState
};

export default MultiFactorAuthenticationEnrollmentState;
