import { USER_LOGGED_IN } from 'src/actions/loggedInUser';

export function metricsWithDashboardUsage(state = {}, action) {
    const { type, payload } = action;
    switch (type) {
        case USER_LOGGED_IN: {
            return Object.assign({}, state, payload.metricsWithDashboardUsage);
        }
        default:
            return state;
    }
}
