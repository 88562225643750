import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import {
} from 'src/selectors/filterSelectors';
import { selectAccountOptions } from 'src/selectors/loggedInUser';
import _get from 'lodash/get';
import { getLocalString } from 'src/components/Date';
import PropTypes from 'prop-types';
import { makeSelectPostTagRulesProfileInSentence } from 'src/selectors/postTagRules';
import { getProcessedKeyWords } from 'src/utils/string';

const PostTagRuleInSentence = (props) => {
    const { postTagRule, accountOptions, profileSelectionInSentence } = props;
    const { additionalFilterSelection, dateSelection } = postTagRule;
    const { postText, postTextExclude } = additionalFilterSelection;
    const postTextValues = _get(postText, ['values'], []);
    const postTextExcludeValues = _get(postTextExclude, ['values'], []);
    const { from, to, timezone } = dateSelection;

    const startTimeFormattedString = getLocalString(from, 'date', accountOptions, false, timezone, timezone);
    const endTimeFormattedString = to !== null ? getLocalString(to, 'date', accountOptions, false, timezone, timezone) : 'Forever';

    const filterSummary = postTextValues.length > 0
        ? (
            <>
                {' '}AND includes
                {' '}{postTextValues.length > 1 ? `${postText.conjunction} of` : ''}
                {' '}<b>&lsquo;{postTextValues.map((value) => getProcessedKeyWords(value)).join('’, ‘')}&rsquo;</b>
                {' '}{postTextValues.length > 1 ? 'keywords' : 'keyword'}
            </>
        )
        : '';
    const filterExcludeSummary = postTextExcludeValues.length > 0
        ? (
            <>
                {' '}AND excludes
                {' '}{postTextExcludeValues.length > 1 ? `${postTextExclude.conjunction} of` : ''}
                {' '}<b>&lsquo;{postTextExcludeValues.map((value) => getProcessedKeyWords(value)).join('’, ‘')}&rsquo;</b>
                {' '}{postTextExcludeValues.length > 1 ? 'keywords' : 'keyword'}
            </>
        )
        : '';
    return (
        <div>
            IF post belongs to <b>{profileSelectionInSentence}</b>
            {' '}AND published from <b>{startTimeFormattedString}</b> - <b>{endTimeFormattedString}</b>
            {filterSummary}
            {filterExcludeSummary}.
        </div>
    );
};

PostTagRuleInSentence.propTypes = {
    postTagRule: customPropTypes.postTagRule.isRequired,
    accountOptions: customPropTypes.accountOptions.isRequired,
    profileSelectionInSentence: PropTypes.string.isRequired
};

const makeMapStateToProps = () => {
    const selectPostTagRulesProfileInSentence = makeSelectPostTagRulesProfileInSentence();
    return (state, ownProps) => ({
        accountOptions: selectAccountOptions(state),
        profileSelectionInSentence: selectPostTagRulesProfileInSentence(state, ownProps.postTagRule.profileSelection)
    }
    );
};

export default connect(makeMapStateToProps)(PostTagRuleInSentence);
