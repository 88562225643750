import React from 'react';
import ListTableCell from 'src/components/listTable/ListTableCell';
import ListTableRow from 'src/components/listTable/ListTableRow';
import { makeSelectPostTagById } from 'src/selectors/postTags';
import { makeSelectPostTagsPostsInfo } from 'src/selectors/postTagsPostInfo';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';
import ListHandlingCheckbox from 'src/components/bulkList/ListHandlingCheckbox';
import Button from 'src/components/buttons/Button';
import PostTagActionOptionsPopover from 'src/components/popovers/PostTagActionOptionsPopover';
import styles from 'src/stylesheets/views/viewListTable.scss';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import withPopover from 'src/components/withPopover';
import { makeSelectIsOneItemSelected } from 'src/selectors/lists';
import Date from 'src/components/Date';
import Number from 'src/components/Number';
import * as routeActions from 'react-router-redux';
import { tagManagerRoute } from 'src/routePaths';
import PostTagAutomationState from 'src/components/views/postTags/postTagManager/PostTagAutomationState';

const ButtonWithPopover = withPopover(Button);

const PostTagTableRow = (props) => {
    const {
        listName, postTag, postTagsPostsInfo, isAtleastOneTagSelected
    } = props;
    const {
        id, name, creatorUserName, createTime, archived
    } = postTag;
    const { numberOfPosts } = postTagsPostsInfo;
    const overlay = <PostTagActionOptionsPopover postTag={postTag} />;

    const handleOnPostTagNameClick = () => {
        const { routePushAction } = props;
        routePushAction({ pathname: `${tagManagerRoute}/${postTag.id}` });
    };

    return (
        <ListTableRow key={id} disabled={archived}>
            <ListTableCell className={styles.checkbox}><ListHandlingCheckbox listName={listName} name={id} /></ListTableCell>
            <ListTableCell onClick={handleOnPostTagNameClick}>
                <DivWithTooltip tooltip={name}>
                    {`${name} ${archived ? '(Archived)' : ''}`}
                </DivWithTooltip>
            </ListTableCell>
            <ListTableCell onClick={handleOnPostTagNameClick}><Number value={numberOfPosts} /></ListTableCell>
            <ListTableCell onClick={handleOnPostTagNameClick}>
                <DivWithTooltip tooltip={creatorUserName}>
                    {creatorUserName}
                </DivWithTooltip>
            </ListTableCell>
            <ListTableCell onClick={handleOnPostTagNameClick}><Date date={createTime} /></ListTableCell>
            <ListTableCell rightContent={(
                <ButtonWithPopover
                  small
                  icon="more"
                  overlay={overlay}
                  disabled={isAtleastOneTagSelected}
                  className="list-hover-button-opacity"
                />
            )}
            >
                <PostTagAutomationState postTag={postTag} />
            </ListTableCell>
        </ListTableRow>
    );
};

PostTagTableRow.propTypes = {
    id: PropTypes.string.isRequired,
    postTag: customPropTypes.postTag.isRequired,
    postTagsPostsInfo: customPropTypes.postTagsPostsInfo.isRequired,
    listName: PropTypes.string.isRequired,
    isAtleastOneTagSelected: PropTypes.bool.isRequired,
    routePushAction: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const selectPostTagById = makeSelectPostTagById();
    const selectNumberOfPostsByPostTagId = makeSelectPostTagsPostsInfo();
    const selectIsOneItemSelected = makeSelectIsOneItemSelected();

    return (state, ownProps) => ({
        postTag: selectPostTagById(state, ownProps.id),
        postTagsPostsInfo: selectNumberOfPostsByPostTagId(state, ownProps.id),
        isAtleastOneTagSelected: selectIsOneItemSelected(state, ownProps.listName)
    });
};

export default connect(makeMapStateToProps, {
    routePushAction: routeActions.push,
})(PostTagTableRow);
