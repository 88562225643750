import React, { PureComponent } from 'react';
import AutocompleteListItem from 'src/components/autocomplete/layout/AutocompleteListItem';
import DashboardInModalListItemContainer from 'src/components/views/dashboards/DashboardInModalListItemContainer';
import FolderInModalListItemContainer from 'src/components/views/folders/FolderInModalListItemContainer';
import List from 'src/components/List';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/autocompletePopover.scss';
import classnames from 'classnames';

const renderMenuItemChildren = (item, active, onClick) => {
    let menuItem = null;
    switch (item.type) {
        case 'folder':
            menuItem = (
                <FolderInModalListItemContainer
                  folderId={item.id}
                />
            );
            break;
        case 'dashboard':
            menuItem = (
                <DashboardInModalListItemContainer
                  dashboardId={item.id}
                />
            );
            break;
        default:
            break;
    }

    return (
        <AutocompleteListItem key={`${item.type}-${item.id}`} onClick={onClick} active={active}>
            {menuItem}
        </AutocompleteListItem>
    );
};

class DashboardsAndFolderSearchAutocomplete extends PureComponent {
    render() {
        const {
            filteredDashboardAndFolderIds, activeIndex, onItemClick
        } = this.props;
        return (
            <div className={classnames(styles.wrapper, styles.big)}>
                {
                    filteredDashboardAndFolderIds.length > 0
                    && (
                    <div className={styles.searchResults}>
                        <List border={false}>
                            {
                                filteredDashboardAndFolderIds.map((item, idx) => renderMenuItemChildren(
                                    item,
                                    idx === activeIndex,
                                    () => { onItemClick(item); }
                                ))
                            }
                        </List>
                    </div>
                    )
                }
                {
                    filteredDashboardAndFolderIds.length < 1
                    && (
                    <div className={styles.noSearchResults}>
                        No search results found
                    </div>
                    )
                }
            </div>
        );
    }
}

DashboardsAndFolderSearchAutocomplete.propTypes = {
    activeIndex: PropTypes.number.isRequired,
    onItemClick: PropTypes.func.isRequired,
    filteredDashboardAndFolderIds: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['folder', 'dashboard']).isRequired
    })).isRequired
};

export default DashboardsAndFolderSearchAutocomplete;
