import { selectDashboardById } from 'src/selectors/dashboards';
import * as customPropTypes from 'src/customPropTypes';
import BatchDashboardMetricRequestParamsProvider from 'src/components/requestHandling/BatchDashboardMetricRequestParamsProvider';
import Body from 'src/components/Body';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import DashboardGrid from 'src/components/DashboardGrid';
import DashboardHeader from 'src/components/header/DashboardHeader';
import emptyDashboard from 'src/resources/illustrations/dashboard-empty.png';
import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import ErrorView from 'src/components/errors/ErrorView';
import Footer from 'src/components/Footer';
import GridTileOuterContainer from 'src/components/GridTileOuterContainer';
import { selectDashboardTypeById } from 'src/selectors/ownership';
import { isLoggedInUserAllowedToChangeDashboardLayout } from 'src/selectors/permissions';
import PropTypes from 'prop-types';
import React from 'react';
import ScrollView from 'src/components/ScrollView';
import { selectLinkToDiscoverForMetrics } from 'src/selectors/metricSearch';
import ServerDataBatchRequestDispatcher from 'src/components/ServerDataBatchRequestDispatcher';
import withReactRouterLinkHandling from 'src/components/withReactRouterLinkHandling';
import withScrollPosition from 'src/components/withScrollPosition';
import withStickyHeaderHandling from 'src/components/withStickyHeaderHandling';
import ContentWidthContext from 'src/components/context/ContentWidthContext';
import { getDashboardContext } from 'src/utils/filterSelectors';

const ButtonWithReactRouterLinkHandling = withReactRouterLinkHandling(Button);
const ScrollViewWithScrollPositionAndStickyHeader = withScrollPosition(withStickyHeaderHandling(ScrollView), 'dashboard');
const title = 'This dashboard has no metrics yet';
const message = 'Start by searching for your first metric in our discover section.';
const VIEW_PORT_MIN_WIDTH = 840;

function generateGridTile(dashboardMetric, currentBreakpoint, isAllowedToChangeLayout, isGridLoading) {
    const { id } = dashboardMetric;
    return (
        <GridTileOuterContainer
          key={id}
          dashboardMetricId={id}
          breakpoint={currentBreakpoint}
          isAllowedToChangeLayout={isAllowedToChangeLayout}
          isGridLoading={isGridLoading}
        />
    );
}

const Dashboard = (props) => {
    const {
        dashboard,
        linkToDiscover,
        isAllowedToChangeLayout,
        type
    } = props;

    if (!dashboard) {
        return (
            <ErrorView
              headline="Dashboard not found"
              error={{ message: 'The dashboard you tried to access does not exist.' }}
            />
        );
    }

    const buttons = (
        <ButtonWithReactRouterLinkHandling label="Discover metrics" action to={linkToDiscover} />
    );

    const dashboardEmpty = (!dashboard.dashboardMetricIds || dashboard.dashboardMetricIds.length < 1);
    const dashboardIdentifier = `dashboard-${dashboard.id}`;

    return (
        <BatchDashboardMetricRequestParamsProvider
          dashboard={dashboard}
          format="json"
          batchIdentifier={dashboardIdentifier}
          batchName={dashboard.name}
          filterContext={getDashboardContext(dashboard.id)}
        >
            {
                    (batchServerRequest) => (
                        <ScrollViewWithScrollPositionAndStickyHeader
                          dataViewName="dashboard"
                          scrollPositionIdentifier={dashboardIdentifier}
                          stickyHeaderIdentifier={dashboardIdentifier}
                        >
                            <DashboardHeader
                              linkToDiscover={linkToDiscover}
                              dashboard={dashboard}
                              stickyHeaderIdentifier={dashboardIdentifier}
                              type={type}
                            />
                            <Body>
                                {
                                    !dashboardEmpty && (
                                        <ServerDataBatchRequestDispatcher
                                          batchServerRequest={batchServerRequest}
                                        >
                                            <ContentWidthContext.Consumer>
                                                {
                                                    (contentWidth) => (
                                                        <DashboardGrid
                                                          width={Math.max(VIEW_PORT_MIN_WIDTH, contentWidth)}
                                                          key={dashboard.id}
                                                          dashboard={dashboard}
                                                          generateGridTile={generateGridTile}
                                                          isAllowedToChangeLayout={isAllowedToChangeLayout}
                                                        />
                                                    )
                                                }
                                            </ContentWidthContext.Consumer>
                                        </ServerDataBatchRequestDispatcher>
                                    )
                                }
                                {
                                    dashboardEmpty && (
                                        <EmptyInfoScreen
                                          image={emptyDashboard}
                                          title={title}
                                          message={message}
                                          buttons={buttons}
                                        />
                                    )
                                }
                            </Body>
                            <Footer />
                        </ScrollViewWithScrollPositionAndStickyHeader>
                    )
                }
        </BatchDashboardMetricRequestParamsProvider>
    );
};

Dashboard.propTypes = {
    dashboard: customPropTypes.dashboardOrFalse.isRequired,
    linkToDiscover: customPropTypes.linkToDiscover.isRequired,
    isAllowedToChangeLayout: PropTypes.bool.isRequired,
    type: customPropTypes.ownershipType.isRequired
};

const makeMapStateToProps = () => (state, ownProps) => {
    const { params } = ownProps;
    const dashboard = selectDashboardById(state, params.dashboardId);
    const type = selectDashboardTypeById(state, params.dashboardId);
    return {
        dashboard,
        linkToDiscover: selectLinkToDiscoverForMetrics(state),
        isAllowedToChangeLayout: isLoggedInUserAllowedToChangeDashboardLayout(state, params.dashboardId),
        type
    };
};

export default connect(makeMapStateToProps)(Dashboard);
