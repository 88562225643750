import * as customPropTypes from 'src/customPropTypes';
import _groupBy from 'lodash/groupBy';
import AutocompleteListItem from 'src/components/autocomplete/layout/AutocompleteListItem';
import AutocompleteMenuDivider from 'src/components/autocomplete/layout/AutocompleteMenuDivider';
import AutocompleteMenuHeader from 'src/components/autocomplete/layout/AutocompleteMenuHeader';
import AutocompleteMenuTagItem from 'src/components/autocomplete/AutocompleteMenuTagItem';
import AutocompleteMenuTermItem from 'src/components/autocomplete/AutocompleteMenuTermItem';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/autocomplete/discover.scss';

const DiscoverSearchAutocomplete = (props) => {
    const {
        autocompleteSuggestions,
        activeIndex,
        onItemClick,
        searchTerm
    } = props;
    const grouped = _groupBy(autocompleteSuggestions, (r) => r.item.tagType || 'term');
    let idx = 0;
    const menu = Object.keys(grouped).map((type) => [
        !!idx && <AutocompleteMenuDivider key={`${type}-divider`} />,
        type !== 'term' && <AutocompleteMenuHeader key={`${type}-header`} label={type} />,
        grouped[type].map((result) => {
            let item = null;
            const active = activeIndex === idx;
            if (result.type === 'tag') {
                item = (
                    <AutocompleteMenuTagItem
                      autocompleteSuggestionTag={result}
                      searchTerm={searchTerm}
                      key={result.item.name}
                    />
                );
            } else if (result.type === 'term') {
                item = (
                    <AutocompleteMenuTermItem
                      autocompleteSuggestionTerm={result}
                      key={result.item.term}
                      searchTerm={searchTerm}
                    />
                );
            }
            idx += 1;
            return (
                <AutocompleteListItem
                  key={`${result.type}-${result.item.name || result.item.term}`}
                  onClick={() => { onItemClick(result); }}
                  active={active}
                >
                    {item}
                </AutocompleteListItem>
            );
        })
    ]);

    return (
        <div className={styles.menuWrapper}>
            {menu}
        </div>
    );
};

DiscoverSearchAutocomplete.propTypes = {
    autocompleteSuggestions: customPropTypes.autocompleteSuggestions.isRequired,
    activeIndex: PropTypes.number.isRequired,
    onItemClick: PropTypes.func.isRequired,
    searchTerm: PropTypes.string.isRequired
};

export default DiscoverSearchAutocomplete;
