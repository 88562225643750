import Popover from 'src/components/popovers/layout/Popover';
import React from 'react';

const wrapperStyle = {
    padding: 20,
    fontSize: '13px',
    lineHeight: '1.38'
};

const PostTagFilterDescriptionPopover = () => (
    <Popover id="post-tag-filter-popover" width={400}>
        <div style={wrapperStyle}>
            <b>Post tags</b><br /><br />
            <div>
                This data source is compatible with the &apos;Post tags&apos; filter.
            </div>
        </div>
    </Popover>
);

export default PostTagFilterDescriptionPopover;
