import Divider from 'src/components/Divider';
import * as customPropTypes from 'src/customPropTypes';
import AnalysisFavoriteMetrics from 'src/components/views/analysis/AnalysisFavoriteMetrics';
import AnalysisQuickAccessList from 'src/components/views/analysis/AnalysisQuickAccessList';
import AnalysisQuickAccessSelect from 'src/components/views/analysis/AnalysisQuickAccessSelect';
import AnalysisSearchInput from 'src/components/views/analysis/AnalysisSearchInput';
import { analysisUpdateFilter } from 'src/actions/analysis';
import { connect } from 'react-redux';
import { makeSelectEntriesForQuickAnalysisSelect } from 'src/selectors/tags';
import PropTypes from 'prop-types';
import React from 'react';
import { selectAnalysisFilter } from 'src/selectors/analysis';
import styles from 'src/stylesheets/views/analysis/analysisSidebar.scss';
import ToggleableSidebar from 'src/components/sidebar/ToggleableSidebar';
import IntercomVerticalWhiteSpace from 'src/components/sidebar/IntercomVerticalWhiteSpace';

const AnalysisSidebar = (props) => {
    const {
        quickAccessSelectEntries, analysisUpdateFilterAction, analysisFilter
    } = props;
    const sectionHeadline = analysisFilter === 'customMetrics' ? 'All custom metrics' : 'Top 10';

    return (
        <ToggleableSidebar sideBarName="analysis">
            <div className={styles.wrapper}>
                <div className={styles.search}>
                    <AnalysisSearchInput />
                </div>
                <div className={styles.quickAccessWrapper}>
                    <div className={styles.quickAccess}>
                        <AnalysisQuickAccessSelect
                          value={analysisFilter}
                          onChange={analysisUpdateFilterAction}
                          options={quickAccessSelectEntries}
                        />
                    </div>
                    <Divider />
                    <div>
                        <div className={styles.sectionHeadline}>Favorites</div>
                        <AnalysisFavoriteMetrics analysisFilter={analysisFilter} />
                    </div>
                    <Divider />
                    <div className={styles.quickAccessLinks}>
                        <div className={styles.sectionHeadline}>{sectionHeadline}</div>
                        <AnalysisQuickAccessList analysisFilter={analysisFilter} />
                    </div>
                    <IntercomVerticalWhiteSpace />
                </div>
            </div>
        </ToggleableSidebar>
    );
};

AnalysisSidebar.propTypes = {
    quickAccessSelectEntries: customPropTypes.quickAccessSelectEntries.isRequired,
    analysisUpdateFilterAction: PropTypes.func.isRequired,
    analysisFilter: PropTypes.string.isRequired
};

const makeMapStateToProps = () => {
    const selectEntriesForQuickAnalysisSelect = makeSelectEntriesForQuickAnalysisSelect();
    return (state) => ({
        quickAccessSelectEntries: selectEntriesForQuickAnalysisSelect(state),
        analysisFilter: selectAnalysisFilter(state)
    });
};

export default connect(makeMapStateToProps, {
    analysisUpdateFilterAction: analysisUpdateFilter
})(AnalysisSidebar);
