import * as customPropTypes from 'src/customPropTypes';
import ContentBlock from 'src/components/views/ContentBlock';
import MetricDataSourcesHints from 'src/components/views/MetricDataSourcesHints';
import MetaInfo from 'src/components/views/discover/MetaInfo';
import PropTypes from 'prop-types';
import React from 'react';
import SectionContent from 'src/components/views/discover/SectionContent';
import styles from 'src/stylesheets/views/dashboardAndMetricDetail.scss';
import MetricDataSourceList from 'src/components/views/MetricDataSourceList';

const MetricDetailAdditionalInfo = (props) => {
    const {
        summary, description, dataSourceHints, usage, createTime, dataSources
    } = props;
    return (
        <ContentBlock>
            <div className={styles.subsections}>
                <div className={styles.metaInformation}>
                    <MetaInfo usage={usage} createTime={createTime} />
                </div>
                <p className={styles.texts}>{summary}</p>
                <div className={styles.dataSources}>
                    <MetricDataSourceList dataSources={dataSources} />
                </div>
            </div>
            {
                description !== '' && (
                    <div className={styles.subsections}>
                        <SectionContent label="Metric description">
                            <p
                              className={styles.texts}
                              dangerouslySetInnerHTML={{ __html: description !== '' ? description : summary }}
                            />
                        </SectionContent>
                    </div>
                )
            }
            {
                dataSourceHints && dataSourceHints.length > 0 && (
                    <SectionContent label="Metric hints">
                        <MetricDataSourcesHints dataSourceHints={dataSourceHints} />
                    </SectionContent>
                )
            }
        </ContentBlock>
    );
};

MetricDetailAdditionalInfo.propTypes = {
    usage: PropTypes.number.isRequired,
    createTime: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    dataSourceHints: customPropTypes.metricDataSourcesHints.isRequired,
    dataSources: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default MetricDetailAdditionalInfo;
