export const ANALYSIS_UPDATE_FILTER = 'ANALYSIS_UPDATE_FILTER';
export const ANALYSIS_QUICK_ACCESS_LINKS_REQUEST = 'ANALYSIS_QUICK_ACCESS_LINKS_REQUEST';
export const ANALYSIS_QUICK_ACCESS_LINKS_SUCCESS = 'ANALYSIS_QUICK_ACCESS_LINKS_SUCCESS';
export const ANALYSIS_QUICK_ACCESS_LINKS_ERROR = 'ANALYSIS_QUICK_ACCESS_LINKS_ERROR';
export const ANALYSIS_AUTOCOMPLETE_REQUEST = 'ANALYSIS_AUTOCOMPLETE_REQUEST';
export const ANALYSIS_AUTOCOMPLETE_SUCCESS = 'ANALYSIS_AUTOCOMPLETE_SUCCESS';
export const ANALYSIS_AUTOCOMPLETE_ERROR = 'ANALYSIS_AUTOCOMPLETE_ERROR';
export const ANALYSIS_TOGGLE_FAV_REQUEST = 'ANALYSIS_TOGGLE_FAV_REQUEST';
export const ANALYSIS_TOGGLE_FAV_SUCCESS = 'ANALYSIS_TOGGLE_FAV_SUCCESS';
export const ANALYSIS_TOGGLE_FAV_ERROR = 'ANALYSIS_TOGGLE_FAV_ERROR';
export const ANALYSIS_LAST_VISITED_METRIC_ID = 'ANALYSIS_LAST_VISITED_METRIC_ID';

export const analysisUpdateFilter = (value) => ({
    type: ANALYSIS_UPDATE_FILTER,
    payload: { value }
});

export const analysisQuickAccessLinksRequest = (identifier) => ({
    type: ANALYSIS_QUICK_ACCESS_LINKS_REQUEST,
    payload: {
        identifier
    }
});

export const analysisQuickAccessLinksSuccess = (identifier, results, resultsForEntities) => ({
    type: ANALYSIS_QUICK_ACCESS_LINKS_SUCCESS,
    payload: {
        identifier,
        results,
        resultsForEntities
    }
});
export const analysisQuickAccessLinksError = (identifier, error) => ({
    type: ANALYSIS_QUICK_ACCESS_LINKS_ERROR,
    payload: {
        identifier,
        error
    }
});

export const analysisAutocompleteRequest = (identifier) => ({
    type: ANALYSIS_AUTOCOMPLETE_REQUEST,
    payload: {
        identifier
    }
});

export const analysisAutocompleteSuccess = (identifier, results, resultsForEntities) => ({
    type: ANALYSIS_AUTOCOMPLETE_SUCCESS,
    payload: {
        identifier,
        results,
        resultsForEntities
    }
});

export const analysisAutocompleteError = (identifier, error) => ({
    type: ANALYSIS_AUTOCOMPLETE_ERROR,
    payload: {
        identifier,
        error
    }
});

export const analysisToggleFavRequest = (metricId, active) => ({
    type: ANALYSIS_TOGGLE_FAV_REQUEST,
    payload: {
        metricId,
        active
    }
});

export const analysisToggleFavSuccess = (metricId, active) => ({
    type: ANALYSIS_TOGGLE_FAV_SUCCESS,
    payload: {
        metricId,
        active
    }
});

export const analysisToggleFavError = (metricId, active, error) => ({
    type: ANALYSIS_TOGGLE_FAV_ERROR,
    payload: {
        metricId,
        active,
        error
    }
});

export const analysisLastVisitedMetricId = (metricId) => ({
    type: ANALYSIS_LAST_VISITED_METRIC_ID,
    payload: {
        metricId
    }
});
