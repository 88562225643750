import {
    METRIC_SEARCH_ALL_INPUTS_UPDATE,
    METRIC_SEARCH_FILTER_BY_UPDATE,
    METRIC_SEARCH_FILTER_BY_UPDATE_RESET_REST,
    METRIC_SEARCH_SEARCH_ERROR,
    METRIC_SEARCH_SEARCH_REQUEST,
    METRIC_SEARCH_SEARCH_SUCCESS,
} from 'src/actions/metricSearch';

import { combineReducers } from 'redux';

import { setIn } from 'src/reducers/utils';

export const metricSearchInputInitialState = {
    orderBy: 'mostUsed',
    filterBy: '',
    selectedTags: [],
    term: ''
};

function orderBy(state = metricSearchInputInitialState.orderBy, action) {
    const { payload, type } = action;
    switch (type) {
        case METRIC_SEARCH_ALL_INPUTS_UPDATE: {
            return payload.orderBy;
        }
        case METRIC_SEARCH_FILTER_BY_UPDATE_RESET_REST: {
            return metricSearchInputInitialState.orderBy;
        }
        default:
            return state;
    }
}

function results(state = {}, action) {
    const { type, payload } = action;

    switch (type) {
        case METRIC_SEARCH_SEARCH_REQUEST: {
            const identifier = JSON.stringify(payload.metricSearchInput);
            return setIn(state, identifier, { loading: true, data: null, error: null });
        }
        case METRIC_SEARCH_SEARCH_SUCCESS: {
            const identifier = JSON.stringify(payload.metricSearchInput);
            const { results: searchResults } = payload;
            const resultIds = searchResults.map((searchResult) => ({ type: searchResult.type, id: searchResult.id }));
            return setIn(state, identifier, { loading: false, data: resultIds, error: null });
        }
        case METRIC_SEARCH_SEARCH_ERROR: {
            const identifier = JSON.stringify(payload.metricSearchInput);
            const { error } = payload;
            return setIn(state, identifier, { loading: false, data: null, error });
        }
        default:
            return state;
    }
}

const selectedTags = (state = metricSearchInputInitialState.selectedTags, action) => {
    const { type, payload } = action;
    switch (type) {
        case METRIC_SEARCH_FILTER_BY_UPDATE_RESET_REST:
            return metricSearchInputInitialState.selectedTags;
        case METRIC_SEARCH_ALL_INPUTS_UPDATE:
            return payload.selectedTags;
        default:
            return state;
    }
};

const filterBy = (state = metricSearchInputInitialState.filterBy, action) => {
    const { payload, type } = action;
    switch (type) {
        case METRIC_SEARCH_FILTER_BY_UPDATE_RESET_REST:
        case METRIC_SEARCH_ALL_INPUTS_UPDATE:
        case METRIC_SEARCH_FILTER_BY_UPDATE: {
            return payload.filterBy;
        }
        default:
            return state;
    }
};

const term = (state = metricSearchInputInitialState.term, action) => {
    const { payload, type } = action;
    switch (type) {
        case METRIC_SEARCH_FILTER_BY_UPDATE_RESET_REST:
            return metricSearchInputInitialState.term;
        case METRIC_SEARCH_ALL_INPUTS_UPDATE:
            return payload.term;
        default:
            return state;
    }
};

const input = combineReducers({
    orderBy,
    filterBy,
    selectedTags,
    term
});

export const metricSearch = combineReducers({
    input,
    results
});
