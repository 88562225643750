import React from 'react';
import TopNav from 'src/components/navigations/TopNav';
import TopNavTextLink from 'src/components/buttons/TopNavTextLink';
import {
    planRoute, usersRoute, spacesRoute, billingRoute
} from 'src/routePaths';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import { getClientSetting } from 'src/selectors/client';

const AdminSettingsNav = ({ clientSetting }) => (
    <TopNav>
        <TopNavTextLink to={planRoute}>Admin overview</TopNavTextLink>
        <TopNavTextLink to={usersRoute}>Users</TopNavTextLink>
        <TopNavTextLink to={spacesRoute}>Spaces</TopNavTextLink>
        {
            !clientSetting.hideInvoices
            && <TopNavTextLink to={billingRoute}>Billing</TopNavTextLink>
        }
    </TopNav>
);

AdminSettingsNav.propTypes = {
    clientSetting: customPropTypes.clientSettingTool.isRequired
};

const mapStateToProps = (state) => ({
    clientSetting: getClientSetting(state)
});

export default connect(mapStateToProps)(AdminSettingsNav);
