import { put, takeEvery } from 'redux-saga/effects';
import { SAMPLE_SERVER_DATA_REQUEST, sampleServerDataError, sampleSeverDataSuccess } from 'src/actions/sampleServerData';
import _get from 'lodash/get';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import { reportError } from 'src/utils/reportError';

function* sampleServerDataRequest(action) {
    const { metricId, visualizationId } = action.payload;
    try {
        const serverRequest = createServerRequest({ metricId, visualizationId });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-sample-data/get-data');
        if (response) {
            const { jsonData } = response;

            if (_get(jsonData, 'image-creation.success') === true) {
                yield put(sampleSeverDataSuccess(metricId, visualizationId, jsonData['image-creation']));
            } else {
                const error = new Error('Could not find sample data for this metric.');
                error.serverError = true;
                throw error;
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (serverOrApplicationError) {
        reportError(serverOrApplicationError);
        yield put(sampleServerDataError(metricId, visualizationId, {
            errorType: 'sampleDataFailed',
            message: 'Could not find sample data for this metric.'
        }));
    }
}

export default function* serverDataSagas() {
    yield takeEvery(SAMPLE_SERVER_DATA_REQUEST, sampleServerDataRequest);
}
