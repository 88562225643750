import React from 'react';
import styles from 'src/stylesheets/views/integrations/DataPushTaskLogDetail.scss';
import EmptyDiv from 'src/components/loadingIndicators/EmptyDiv';

const generateDetail = (w1, w2) => (
    <div>
        <div style={{ paddingBottom: '12px' }}>
            <EmptyDiv height={18} width={w1} />
        </div>
        <EmptyDiv height={18} width={w2} />
    </div>
);

const DataPushTaskDetailLoading = () => (
    <div className={styles.wrapper}>
        <div className={styles.detail}>
            {generateDetail(64, 120)}
            {generateDetail(120, 30)}
            {generateDetail(90, 30)}
            {generateDetail(130, 40)}
            {generateDetail(64, 120)}
        </div>
        <div className={styles.logSection}>
            <div className={styles.logTitle}>
                <EmptyDiv height={18} width={100} />
            </div>
            <div className={styles.logEditor}>
                <EmptyDiv />
            </div>
        </div>
    </div>
);

export default DataPushTaskDetailLoading;
