import ExportMenuPopover from 'src/components/popovers/ExportMenuPopover';
import PropTypes from 'prop-types';
import React from 'react';
import withPopover from 'src/components/withPopover';

const withExportMenuPopover = (WrappedButton) => {
    const WithExportMenuPopover = (props) => {
        const {
            additionalButton, exportAction
        } = props;
        const ButtonWithPopover = withPopover(WrappedButton);

        const onExportItemClick = (type) => {
            exportAction(type);
        };

        const popover = (
            <ExportMenuPopover
              onExportItemClick={onExportItemClick}
              additionalButton={additionalButton}
            />
        );

        return (
            <ButtonWithPopover
              overlay={popover}
              {...props}
            />
        );
    };

    WithExportMenuPopover.propTypes = {
        exportAction: PropTypes.func.isRequired,
        additionalButton: PropTypes.node,
    };

    const wrappedComponentName = WrappedButton.displayName || WrappedButton.name || 'Component';
    WithExportMenuPopover.displayName = `withExportAction(${wrappedComponentName})`;

    return WithExportMenuPopover;
};

export default withExportMenuPopover;
