import AdAccountFilterBarForm from 'src/components/forms/AdAccountFilterBarForm';
import CheckBox from 'src/components/forms/inputs/Checkbox';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/listHeader.scss';
import withBulkCheckboxHandling from 'src/components/bulkList/withBulkCheckboxHandling';

const AdAccountListBulkCheckbox = withBulkCheckboxHandling(CheckBox, true);

const AdAccountListHeader = (props) => {
    const { listItemIdsToHandle, allProfileIds, listName } = props;
    return (
        <div className={styles.listHeader}>
            <div className={styles.checkOption}>
                <AdAccountListBulkCheckbox listName={listName} listItemIdsToHandle={listItemIdsToHandle} />
                <div className={styles.listNumber}>{listItemIdsToHandle.length} of {allProfileIds.length} ad accounts</div>
            </div>
            <div className={styles.filterBar}>
                <AdAccountFilterBarForm />
            </div>
        </div>
    );
};

AdAccountListHeader.propTypes = {
    listItemIdsToHandle: PropTypes.arrayOf(PropTypes.string).isRequired,
    allProfileIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    listName: PropTypes.string.isRequired
};

export default AdAccountListHeader;
