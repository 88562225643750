import PropTypes from 'prop-types';

const SharedDashboardContext = (props) => {
    const { content } = props;
    return content;
};

SharedDashboardContext.propTypes = {
    content: PropTypes.node.isRequired
};

export default SharedDashboardContext;
