import PostTagsNav from 'src/components/navigations/subNavs/PostTagsNav';
import PropTypes from 'prop-types';
import React from 'react';
import View from 'src/components/views/View';
import withLastMainMenuLocationUpdater from 'src/components/views/withLastMainMenuLocationUpdater';
import { POSTS } from 'src/utils/routes';
import * as customPropTypes from 'src/customPropTypes';

const ViewWithLastMainMenuLocationUpdater = withLastMainMenuLocationUpdater(View);

const PostTagsView = (props) => {
    const { content, location } = props;
    return (
        <ViewWithLastMainMenuLocationUpdater
          context={POSTS}
          location={location}
          topNav={<PostTagsNav />}
          body={content}
        />
    );
};

PostTagsView.propTypes = {
    location: customPropTypes.location.isRequired,
    content: PropTypes.node.isRequired
};

export default PostTagsView;
