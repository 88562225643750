import React, { PureComponent } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/recommendedMetrics.scss';

class RecommendationsHeader extends PureComponent {
    render() {
        const { title, showAllLink } = this.props;
        return (
            <div className={styles.header}>
                <div className={styles.title}>{title}</div>
                {
                    showAllLink
                    && (
                    <div className={styles.showAllLinkWrapper}>
                        <Link to={showAllLink} className={styles.showAllLink}>Show more results</Link>
                    </div>
                    )
                }
            </div>
        );
    }
}

RecommendationsHeader.propTypes = {
    title: PropTypes.string.isRequired,
    showAllLink: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
};

RecommendationsHeader.defaultProps = {
    showAllLink: false
};

export default RecommendationsHeader;
