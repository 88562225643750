import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import MenuButton from 'src/components/buttons/MenuButton';
import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import React from 'react';
import { modalShowDashboardShareLinks, modalsShowCreateReport, modalsShowEditReport } from 'src/actions/overlays';
import { makeSelectDashboardById } from 'src/selectors/dashboards';
import * as customPropTypes from 'src/customPropTypes';
import { makeSelectDashboardReportsByDashboardId } from 'src/selectors/dashboardReports';

const DashboardShareOptionsPopover = (props) => {
    const { hidePopover, dashboard, dashboardReports } = props;
    const { id } = dashboard;
    const onDashboardReportEdit = () => {
        const { modalsShowCreateReportAction, modalsShowEditReportAction } = props;
        if (dashboardReports.length > 0) {
            modalsShowEditReportAction(dashboardReports[0].id);
        } else {
            modalsShowCreateReportAction(id);
        }
        hidePopover();
    };

    const handleOnLinkExportClick = () => {
        const { modalShowDashboardShareLinksAction } = props;
        modalShowDashboardShareLinksAction(id);
        hidePopover();
    };

    return (
        <Popover id="dashboardReportOptionsPopover" width={180}>
            <ButtonGroup vertical>
                <MenuButton
                  label="Interactive report"
                  onClick={handleOnLinkExportClick}
                />
                <MenuButton
                  label="Scheduled report"
                  onClick={onDashboardReportEdit}
                />
            </ButtonGroup>
        </Popover>
    );
};

DashboardShareOptionsPopover.propTypes = {
    dashboardId: PropTypes.string.isRequired,
    dashboard: customPropTypes.dashboard.isRequired,
    dashboardReports: customPropTypes.dashboardReports.isRequired,
    modalShowDashboardShareLinksAction: PropTypes.func.isRequired,
    modalsShowCreateReportAction: PropTypes.func.isRequired,
    modalsShowEditReportAction: PropTypes.func.isRequired,
    hidePopover: PropTypes.func
};

const makeMapStateToProps = () => {
    const selectDashboardById = makeSelectDashboardById();
    const selectDashboardReportsByDashboardId = makeSelectDashboardReportsByDashboardId();
    return (state, { dashboardId }) => ({
        dashboard: selectDashboardById(state, dashboardId),
        dashboardReports: selectDashboardReportsByDashboardId(state, dashboardId)
    });
};

export default connect(makeMapStateToProps, {
    modalShowDashboardShareLinksAction: modalShowDashboardShareLinks,
    modalsShowCreateReportAction: modalsShowCreateReport,
    modalsShowEditReportAction: modalsShowEditReport
})(DashboardShareOptionsPopover);
