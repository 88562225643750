import React from 'react';
import PropTypes from 'prop-types';
import FloatingList from 'src/components/floatingList/FloatingList';
import PostFloatingListItem from 'src/components/views/postTags/postTagging/PostFloatingListItem';
import PostTile from 'src/components/views/postTags/postTagging/tile/PostTile';

const PostFloatingListTiles = (props) => {
    const { postIds, listName, disabled } = props;
    return (
        <FloatingList>
            {
                postIds.map((id) => (
                    <PostFloatingListItem key={id}>
                        <PostTile postId={id} listName={listName} disabled={disabled} />
                    </PostFloatingListItem>
                ))
            }
        </FloatingList>
    );
};

PostFloatingListTiles.propTypes = {
    postIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    listName: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired
};

export default PostFloatingListTiles;
