import * as overLayActions from 'src/actions/overlays';
import * as userNotificationActions from 'src/actions/userNotification';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Column from 'src/components/header/layout/Column';
import { connect } from 'react-redux';
import Header from 'src/components/header/layout/Header';
import PageHeadline from 'src/components/header/layout/PageHeadline';
import PropTypes from 'prop-types';
import React from 'react';
import TopRow from 'src/components/header/layout/TopRow';

const UserNotificationsHeader = (props) => {
    const { markAllUserNotificationAsReadAction, notificationSettingsClickAction, userNotificationLength } = props;
    return (
        <Header>
            <TopRow>
                <Column flexGrow>
                    <PageHeadline>Notifications</PageHeadline>
                </Column>
                <Column>
                    <ButtonGroup>
                        <Button
                          onClick={() => markAllUserNotificationAsReadAction()}
                          label="Mark all as read"
                          disabled={userNotificationLength === 0}
                        />
                        <Button
                          onClick={() => notificationSettingsClickAction()}
                          icon="settings"
                          label="Notification settings"
                        />
                    </ButtonGroup>
                </Column>
            </TopRow>
        </Header>
    );
};

UserNotificationsHeader.propTypes = {
    markAllUserNotificationAsReadAction: PropTypes.func.isRequired,
    notificationSettingsClickAction: PropTypes.func.isRequired,
    userNotificationLength: PropTypes.number.isRequired
};

export default connect(null, {
    markAllUserNotificationAsReadAction: userNotificationActions.markAllUserNotificationAsRead,
    notificationSettingsClickAction: overLayActions.modalsShowUserNotificationSettings
})(UserNotificationsHeader);
