import { makeSelectDashboardIdsByMetricId, makeSelectDashboardsByIds } from 'src/selectors/dashboards';
import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import { connect } from 'react-redux';
import DashboardList from 'src/components/DashboardList';
import { getMetricsWithDashboardUsageByMetricId } from 'src/selectors/metricDashboardUsages';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/views/metricDashboardList.scss';

const MetricDashboardList = (props) => {
    const { dashboards, numberOfDashboards } = props;
    return (
        <div>
            { (dashboards.length === 0 && numberOfDashboards === 0)
            && (
            <p className={styles.noInfoText}>
                This metric has not been added to any dashboards yet.
            </p>
            )}
            { (dashboards.length > 0 || numberOfDashboards > 0)
                && (
                <div className={classnames(styles.texts, styles.bottomPadding)}>
                    This metric has been added to the following dashboards:
                </div>
                )}
            <DashboardList dashboards={dashboards} numberOfDashboards={numberOfDashboards} />
        </div>
    );
};

MetricDashboardList.propTypes = {
    metricId: PropTypes.string.isRequired,
    dashboards: customPropTypes.dashboards.isRequired,
    numberOfDashboards: PropTypes.number
};

MetricDashboardList.defaultProps = {
    numberOfDashboards: 0
};

const makeMapStateToProps = () => {
    const selectDashboardIdsByMetricId = makeSelectDashboardIdsByMetricId();
    const selectDashboardsByIds = makeSelectDashboardsByIds();

    return (state, ownProps) => {
        const { metricId } = ownProps;
        const dashboardIds = selectDashboardIdsByMetricId(state, metricId);
        const dashboards = selectDashboardsByIds(state, dashboardIds);
        const numberOfDashboards = getMetricsWithDashboardUsageByMetricId(state, metricId);
        return {
            dashboards,
            numberOfDashboards
        };
    };
};

export default connect(makeMapStateToProps)(MetricDashboardList);
