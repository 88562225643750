import React from 'react';
import ListFilters from 'src/components/ListFilters';
import { Select, TextWithResetIcon } from 'src/components/forms/fields';
import { Field } from 'redux-form';
import ListOptionsBar from 'src/components/ListOptionsBar';
import withReduxForm from 'src/components/forms/withReduxForm';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';
import { datasourceFilterOptions } from 'src/utils/profiles';
import { formName } from 'src/utils/dataSources';

const sortingOptions = [
    { value: 'alphabetically', label: 'Name (A-Z)' }
];

const statsTypes = [
    { value: 'all', label: 'Public and private data' },
    { value: 'private', label: 'Private data' },
    { value: 'public', label: 'Public data' }
];

const dataTypes = [
    { value: 'all', label: 'All data types' },
    { value: 'timeSeries', label: 'Time-series data' },
    { value: 'itemLevel', label: 'Item-level data' }
];

const DataSourceFilterBarForm = ({ change }) => (
    <ListOptionsBar
      filters={(
          <ListFilters>
              <Field
                name="platformType"
                layout="listFilter"
                component={Select}
              >
                  {
                      datasourceFilterOptions.map((platformType) => (
                          <option key={`env_${platformType.value}`} value={platformType.value}>
                              {platformType.label}
                          </option>
                      ))
                  }
              </Field>
              <Field
                name="statsType"
                layout="listFilter"
                component={Select}
              >
                  {
                    statsTypes.map((statsType) => (
                        <option key={statsType.value} value={statsType.value}>
                            {statsType.label}
                        </option>
                    ))
                  }
              </Field>
              <Field
                name="dataType"
                layout="listFilter"
                component={Select}
              >
                  {
                    dataTypes.map((dataType) => (
                        <option key={dataType.value} value={dataType.value}>
                            {dataType.label}
                        </option>
                    ))
                  }
              </Field>
              <Field
                name="term"
                placeholder="Search data sources"
                component={TextWithResetIcon}
                layout="listFilter"
                autoFocus
                onResetClick={() => { change('term', ''); }}
              />
          </ListFilters>
      )}
      sorting={(
          <Field layout="listFilter" component={Select} name="sortBy">
              {
                sortingOptions.map((option) => (
                    <option
                      key={`select-option-${option.value}`}
                      value={option.value}
                    >
                        {option.label}
                    </option>
                ))
              }
          </Field>
      )}
    />
);

DataSourceFilterBarForm.propTypes = {
    change: PropTypes.func.isRequired,
    location: customPropTypes.dataSourceSearchParamsLocation.isRequired
};

const makeMapStateToProps = () => (state, ownProps) => ({
    initialValues: {
        platformType: _get(ownProps, 'location.query.platformType', datasourceFilterOptions[0].value),
        statsType: _get(ownProps, 'location.query.statsType', statsTypes[0].value),
        dataType: _get(ownProps, 'location.query.dataType', dataTypes[0].value),
        term: _get(ownProps, 'location.query.term', ''),
        sortBy: _get(ownProps, 'location.query.sortBy', sortingOptions[0].value)
    }
});

export default connect(makeMapStateToProps)(withReduxForm(DataSourceFilterBarForm, {
    form: formName,
    destroyOnUnmount: false
}));
