import Column from 'src/components/header/layout/Column';
import Header from 'src/components/header/layout/Header';
import PageHeadline from 'src/components/header/layout/PageHeadline';
import React from 'react';
import TopRow from 'src/components/header/layout/TopRow';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as overlayActions from 'src/actions/overlays';
import { ensurePermission, writePermissions } from 'src/middleware/userPermissionCheck';
import { selectHasAtleastOneArchivedPostTag, getListTableConfig } from 'src/selectors/postTags';
import * as postTagActions from 'src/actions/postTags';
import * as customPropTypes from 'src/customPropTypes';

const TagManagerHeader = (props) => {
    const {
        postTagAddRequestAction, hasAtleastOneArchivedPostTag, listTableConfig, toggleArchivedPostTagsFilterAction
    } = props;
    const { showArchived } = listTableConfig;
    const icon = showArchived ? 'hide' : 'show';
    const label = showArchived ? 'Hide archived tags' : 'Show archived tags';
    return (
        <Header>
            <TopRow>
                <Column flexGrow>
                    <PageHeadline>Tag manager</PageHeadline>
                </Column>
                {
                    hasAtleastOneArchivedPostTag
                    && (
                        <Column style={{ paddingRight: '20px' }}>
                            <Button label={label} icon={icon} onClick={() => { toggleArchivedPostTagsFilterAction(); }} />
                        </Column>
                    )
                }
                <Column>
                    <Button label="Add tag" icon="plus" action onClick={() => { postTagAddRequestAction(); }} />
                </Column>
            </TopRow>
        </Header>
    );
};

TagManagerHeader.propTypes = {
    postTagAddRequestAction: PropTypes.func.isRequired,
    hasAtleastOneArchivedPostTag: PropTypes.bool.isRequired,
    toggleArchivedPostTagsFilterAction: PropTypes.func.isRequired,
    listTableConfig: customPropTypes.listTableConfig.isRequired
};

const mapStateToProps = (state) => ({
    hasAtleastOneArchivedPostTag: selectHasAtleastOneArchivedPostTag(state),
    listTableConfig: getListTableConfig(state)
});

export default connect(mapStateToProps, {
    postTagAddRequestAction: ensurePermission(overlayActions.modalShowPostTagAdd, writePermissions.addPostTag),
    toggleArchivedPostTagsFilterAction: postTagActions.toggleArchivedPostTagsFilter
})(TagManagerHeader);
