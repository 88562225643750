import React, { useRef } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import ListHandlingCheckbox from 'src/components/bulkList/ListHandlingCheckbox';
import ProfileGroupItemList from 'src/components/views/profiles/ProfileGroupItemList';
import ProfileInfoListItem from 'src/components/views/profiles/ProfileInfoListItem';
import ProfileOptionsPopover from 'src/components/popovers/ProfileOptionsPopover';
import AccountUseCasePopover from 'src/components/popovers/AccountUseCasePopover';
import PropTypes from 'prop-types';
import withPopover from 'src/components/withPopover';
import AccountPrivateStats from 'src/components/AccountPrivateStats';
import ProfileHealthBar from 'src/components/views/profiles/ProfileHealthBar';
import AccountListItem from 'src/components/account/layout/AccountListItem';
import AccountTypeContext from 'src/components/context/AccountTypeContext';

const AccountPrivateStatsWithPopover = withPopover(AccountPrivateStats);
const ButtonWithPopover = withPopover(Button);

const contextValues = { accountType: 'profiles', isDetailPage: false };

const ProfileListItem = (props) => {
    const privateStatsButtonRef = useRef();
    const {
        profile,
        buttonsDisabled,
        modalsShowProfileDetailsAction,
        highlight,
        listName
    } = props;

    const { id } = profile;
    const profileUseCasesPopover = <AccountUseCasePopover account={profile} accountType="profiles" />;

    return (
        <AccountListItem>
            <AccountListItem.LeftColumn>
                <ProfileHealthBar profileId={profile.id} />
                <AccountListItem.CheckBoxWrapper>
                    <ListHandlingCheckbox
                      name={profile.id}
                      listName={listName}
                    />
                </AccountListItem.CheckBoxWrapper>
                <AccountListItem.AccountInfoWrapper onClick={() => { modalsShowProfileDetailsAction(profile.id); }}>
                    <ProfileInfoListItem highlight={highlight} profile={profile} />
                </AccountListItem.AccountInfoWrapper>
            </AccountListItem.LeftColumn>
            <AccountListItem.RightColumn>
                <AccountListItem.Analysis>
                    <AccountTypeContext.Provider value={contextValues}>
                        <AccountPrivateStatsWithPopover
                          overlay={profileUseCasesPopover}
                          account={profile}
                          ref={privateStatsButtonRef}
                        />
                    </AccountTypeContext.Provider>
                </AccountListItem.Analysis>
                <AccountListItem.Groups>
                    <ProfileGroupItemList profileId={id} disabled={buttonsDisabled} />
                </AccountListItem.Groups>
                <AccountListItem.ButtonWrapper>
                    <ButtonWithPopover
                      small
                      icon="more"
                      overlay={<ProfileOptionsPopover profileId={profile.id} privateStatsButtonRef={privateStatsButtonRef} />}
                      disabled={buttonsDisabled}
                      placement="bottom-end"
                      className="list-hover-button-opacity"
                    />
                </AccountListItem.ButtonWrapper>
            </AccountListItem.RightColumn>
        </AccountListItem>
    );
};

ProfileListItem.propTypes = {
    profile: customPropTypes.profile.isRequired,
    buttonsDisabled: PropTypes.bool.isRequired,
    modalsShowProfileDetailsAction: PropTypes.func.isRequired,
    listName: PropTypes.string.isRequired,
    highlight: PropTypes.string,
};

ProfileListItem.defaultProps = {
    highlight: ''
};

export default ProfileListItem;
