import React from 'react';
import ToggleSwitch from 'src/components/forms/inputs/ToggleSwitch';
import * as customPropTypes from 'src/customPropTypes';
import { postTagAutomationActivateRequest, postTagAutomationDeactivateRequest } from 'src/actions/postTags';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ensurePermission, writePermissions } from 'src/middleware/userPermissionCheck';
import { selectPostTagAutomationActivatingState } from 'src/selectors/postTags';

const PostTagAutomationStateControl = (props) => {
    const { postTag, state } = props;
    const { isPending } = state;
    const { id, archived, automation } = postTag;

    const handleOnChange = (e) => {
        const { postTagAutomationActivateRequestAction, postTagAutomationDeactivateRequestAction } = props;
        const value = e.target.checked;
        if (value) {
            postTagAutomationActivateRequestAction(id);
        } else {
            postTagAutomationDeactivateRequestAction(id);
        }
    };

    return (
        <ToggleSwitch checked={automation} onChange={handleOnChange} disabled={archived || isPending} />
    );
};

PostTagAutomationStateControl.propTypes = {
    postTag: customPropTypes.postTag.isRequired,
    postTagAutomationActivateRequestAction: PropTypes.func.isRequired,
    postTagAutomationDeactivateRequestAction: PropTypes.func.isRequired,
    state: customPropTypes.asyncDataLoadingState.isRequired
};

const mapStateToProps = (state, ownProps) => ({
    state: selectPostTagAutomationActivatingState(state, ownProps.postTag.id)
});

export default connect(mapStateToProps, {
    postTagAutomationActivateRequestAction: ensurePermission(postTagAutomationActivateRequest, writePermissions.changePostTagAutomationState),
    postTagAutomationDeactivateRequestAction: ensurePermission(postTagAutomationDeactivateRequest, writePermissions.changePostTagAutomationState)
})(PostTagAutomationStateControl);
