import React, { Component } from 'react';
import * as stickyHeaderActions from 'src/actions/stickyHeader';
import _omit from 'lodash/omit';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const withStickyHeaderHandling = (WrappedComponent) => {
    class WithStickyHeaderHandling extends Component {
        constructor(props) {
            super(props);
            this.showSticky = false;
            this.handleScroll = this.handleScroll.bind(this);
            this.handleRef = this.handleRef.bind(this);
        }

        componentDidMount() {
            this.bodyNode.addEventListener('scroll', this.handleScroll);
        }

        componentWillUnmount() {
            const { cleanUpStickyHeaderAction, stickyHeaderIdentifier } = this.props;
            this.bodyNode.removeEventListener('scroll', this.handleScroll);
            cleanUpStickyHeaderAction(stickyHeaderIdentifier);
        }

        handleRef(bodyNode) {
            this.bodyNode = bodyNode;
        }

        handleScroll(event) {
            const { showSticky } = this;
            const { threshold, updateStickyHeaderAction, stickyHeaderIdentifier } = this.props;
            const { scrollTop } = event.target;
            const showStickHeader = scrollTop >= threshold;
            if (showStickHeader !== showSticky) {
                updateStickyHeaderAction(stickyHeaderIdentifier, showStickHeader);
                this.showSticky = showStickHeader;
            }
        }

        render() {
            const cleanedProps = _omit(this.props, ['threshold', 'stickyHeaderIdentifier']);
            return <WrappedComponent {...cleanedProps} bodyRefForStickyHeader={this.handleRef} />;
        }
    }

    WithStickyHeaderHandling.propTypes = {
        threshold: PropTypes.number,
        stickyHeaderIdentifier: PropTypes.string.isRequired,
        updateStickyHeaderAction: PropTypes.func.isRequired,
        cleanUpStickyHeaderAction: PropTypes.func.isRequired
    };

    WithStickyHeaderHandling.defaultProps = {
        threshold: 100
    };

    return connect(null, {
        updateStickyHeaderAction: stickyHeaderActions.updateStickyHeader,
        cleanUpStickyHeaderAction: stickyHeaderActions.cleanUpStickyHeader
    })(WithStickyHeaderHandling);
};

export default withStickyHeaderHandling;
