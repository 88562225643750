import React from 'react';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeSelectIsDataPushTaskDryRunning } from 'src/selectors/dataPushTasks';
import { modalShowDataPushTaskDryRun } from 'src/actions/overlays';

const DataPushTaskDryRunButton = (props) => {
    const { isRunning } = props;
    const handleOnClick = () => {
        const { dataPushTaskId, modalShowDataPushTaskDryRunAction } = props;
        modalShowDataPushTaskDryRunAction(dataPushTaskId);
    };

    return (
        <Button label="Perform dry run" loading={isRunning} onClick={handleOnClick} />
    );
};

DataPushTaskDryRunButton.propTypes = {
    isRunning: PropTypes.bool.isRequired,
    dataPushTaskId: PropTypes.string.isRequired,
    modalShowDataPushTaskDryRunAction: PropTypes.func.isRequired
};

const mapStateToProps = () => {
    const selectIsDataPushTaskDryRunning = makeSelectIsDataPushTaskDryRunning();
    return (state, ownProps) => ({
        isRunning: selectIsDataPushTaskDryRunning(state, ownProps.dataPushTaskId)
    });
};

export default connect(mapStateToProps, {
    modalShowDataPushTaskDryRunAction: modalShowDataPushTaskDryRun
})(DataPushTaskDryRunButton);
