import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import { makeSelectBrokenAdAccountUseCasesByPlatformType } from 'src/selectors/authUsers';
import React from 'react';
import AccountUseCasesHealthStatus from 'src/components/account/AccountUseCasesHealthStatus';

const AdAccountUseCasesHealthStatus = (props) => {
    const { brokenAdAccountUseCasesByPlatformType } = props;
    return (
        <AccountUseCasesHealthStatus
          brokenAccountUseCasesByPlatformType={brokenAdAccountUseCasesByPlatformType}
          accountType="adAccounts"
        />
    );
};

AdAccountUseCasesHealthStatus.propTypes = {
    brokenAdAccountUseCasesByPlatformType: customPropTypes.brokenAdAccountsUseCasesByPlatformType.isRequired
};

const makeMapStateToProps = () => {
    const selectBrokenAdAccountUseCasesByPlatformType = makeSelectBrokenAdAccountUseCasesByPlatformType();
    return (state) => ({
        brokenAdAccountUseCasesByPlatformType: selectBrokenAdAccountUseCasesByPlatformType(state)
    });
};

export default connect(makeMapStateToProps)(AdAccountUseCasesHealthStatus);
