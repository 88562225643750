import CheckBox from 'src/components/forms/inputs/Checkbox';
import ProfileFilterBarForm from 'src/components/forms/ProfileFilterBarForm';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/listHeader.scss';
import withBulkCheckboxHandling from 'src/components/bulkList/withBulkCheckboxHandling';

const ProfileListBulkCheckbox = withBulkCheckboxHandling(CheckBox, true);

const ProfileListHeader = (props) => {
    const { listItemIdsToHandle, allProfileIds, listName } = props;
    return (
        <div className={styles.listHeader}>
            <div className={styles.checkOption}>
                <ProfileListBulkCheckbox listName={listName} listItemIdsToHandle={listItemIdsToHandle} />
                <div className={styles.listNumber}>{listItemIdsToHandle.length} of {allProfileIds.length} profiles</div>
            </div>
            <div className={styles.filterBar}>
                <ProfileFilterBarForm />
            </div>
        </div>
    );
};

ProfileListHeader.propTypes = {
    listItemIdsToHandle: PropTypes.arrayOf(PropTypes.string).isRequired,
    allProfileIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    listName: PropTypes.string.isRequired
};

export default ProfileListHeader;
