import * as customPropTypes from 'src/customPropTypes';
import ContentBlock from 'src/components/views/ContentBlock';
import { connect } from 'react-redux';
import React from 'react';
import SampleMetricTile from 'src/components/metricTiles/SampleMetricTile';
import { selectAccountOptions } from 'src/selectors/loggedInUser';
import styles from 'src/stylesheets/views/dashboardAndMetricDetail.scss';

const MetricSamplePreview = (props) => {
    const { accountOptions, searchResultMetric } = props;
    return (
        <ContentBlock className={styles.tile}>
            <div className={styles.sampleTile}>
                <SampleMetricTile
                  accountOptions={accountOptions}
                  metric={searchResultMetric}
                />
            </div>
        </ContentBlock>
    );
};

MetricSamplePreview.propTypes = {
    searchResultMetric: customPropTypes.searchResultMetricOrFalse.isRequired,
    accountOptions: customPropTypes.accountOptions.isRequired
};

const mapStateToProps = (state) => ({
    accountOptions: selectAccountOptions(state)
});

export default connect(mapStateToProps)(MetricSamplePreview);
