import _get from 'lodash/get';
import { defaultLoadingState } from 'src/selectors/utils';

const getUserSecurityOverviewFromStore = (state) => state.userSecurity.overview;
const getUserSecurityEnrollmentFromStore = (state) => state.userSecurity.enrollment;

export const selectUserSecurityAsyncStates = (state, userId) => _get(getUserSecurityOverviewFromStore(state), [userId], defaultLoadingState);

export const selectUserSecurityData = (state, userId) => _get(getUserSecurityOverviewFromStore(state), [userId, 'data']);

export const selectMultiFactorAuthenticationEnrollmentLoading = (state) => _get(getUserSecurityEnrollmentFromStore(state), ['enrollment', 'isPending'], false);
