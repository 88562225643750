import IntegrationsNav from 'src/components/navigations/subNavs/IntegrationsNav';
import PropTypes from 'prop-types';
import React from 'react';
import View from 'src/components/views/View';
import withLastMainMenuLocationUpdater from 'src/components/views/withLastMainMenuLocationUpdater';
import { INTEGRATION } from 'src/utils/routes';
import * as customPropTypes from 'src/customPropTypes';

const ViewWithLastMainMenuLocationUpdater = withLastMainMenuLocationUpdater(View);

const IntegrationsView = (props) => {
    const { content, location } = props;
    return (
        <ViewWithLastMainMenuLocationUpdater
          topNav={<IntegrationsNav />}
          body={content}
          location={location}
          context={INTEGRATION}
        />
    );
};

IntegrationsView.propTypes = {
    location: customPropTypes.location.isRequired,
    content: PropTypes.node.isRequired
};

export default IntegrationsView;
