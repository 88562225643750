import ContentBlock from 'src/components/views/ContentBlock';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import Date from 'src/components/Date';
import DetailText from 'src/components/views/DetailText';
import { modalsShowSubscriptionModal } from 'src/actions/overlays';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/views/settings/settings.scss';
import DeleteAccount from 'src/components/views/settings/DeleteAccount';
import ClientLimitProgressBars from 'src/components/views/settings/ClientLimitProgressBars';
import * as customPropTypes from 'src/customPropTypes';
import Feedback from 'src/components/feedback/Feedback';
import classnames from 'classnames';

export const getPackageValid = (packageValid) => {
    if (packageValid === 'myAccountPackageValidUnlimited') {
        return 'Unlimited';
    } if (packageValid === 'myAccountPackageExpired') {
        return 'Expired';
    }
    return <Date date={packageValid} />;
};

export const getPackageStatus = (packageStatus) => {
    if (packageStatus === 'myAccountPackageStatusAutomaticExtension') {
        return '(extends automatically)';
    }
    return '';
};

const PackageInfoBody = (props) => {
    const { packageInfo, modalsShowSubscriptionModalAction } = props;
    const {
        packageType, packageValid, packageStartDate, packageStatus, canceled
    } = packageInfo;

    const footer = (
        <Button
          label="Adjust package"
          action
          onClick={() => { modalsShowSubscriptionModalAction(packageStatus); }}
        />
    );

    return (
        <div className={styles.sectionWrapper}>
            <div className={styles.leftSection}>
                <ContentBlock headline="Plan" footerRight={footer}>
                    <div className={classnames(styles.plan, { [styles.canceled]: canceled, [styles.notCanceled]: !canceled })}>
                        <DetailText label="Type">{packageType}</DetailText>
                        <DetailText label="Start date">{getPackageValid(packageStartDate)}</DetailText>
                        <DetailText label="End date">{getPackageValid(packageValid)}</DetailText>
                    </div>
                    {
                        canceled && <Feedback content={<div>Your plan will cancel on <Date date={packageValid} />.</div>} type="warning" />
                    }
                </ContentBlock>
                <DeleteAccount />
            </div>
            <div className={styles.rightSection}>
                <ClientLimitProgressBars />
            </div>
        </div>
    );
};

PackageInfoBody.propTypes = {
    packageInfo: customPropTypes.packageInfo.isRequired,
    modalsShowSubscriptionModalAction: PropTypes.func.isRequired
};

export default connect(null, {
    modalsShowSubscriptionModalAction: modalsShowSubscriptionModal
})(PackageInfoBody);
