import React from 'react';
import ListTable from 'src/components/listTable/ListTable';
import ListTableHeader from 'src/components/listTable/ListTableHeader';
import * as customPropTypes from 'src/customPropTypes';
import SpaceListTableRow from 'src/components/views/settings/space/SpaceListTableRow';

const SpaceListTable = (props) => {
    const { spaces } = props;
    const columns = [
        {
            id: 'name', content: 'Name', width: '25%'
        },
        {
            id: 'id', content: 'Id', width: '10%'
        },
        {
            id: 'customLogo', content: 'Logo', width: '20%'
        },
        {
            id: 'users', content: 'Users', width: '10%'
        },
        {
            id: 'profiles', content: 'Profiles', width: '10%'
        },
        {
            id: 'adAccounts', content: 'Ad accounts', width: '25%'
        }
    ];

    const tableHeader = <ListTableHeader columns={columns} />;

    return (
        <ListTable header={tableHeader}>
            {
                spaces.map((space) => (
                    <SpaceListTableRow space={space} key={space.id} />
                ))
            }
        </ListTable>
    );
};

SpaceListTable.propTypes = {
    spaces: customPropTypes.spacesForManagement.isRequired
};

export default SpaceListTable;
