import {
    initializeList, updateListValues
} from 'src/actions/lists';
import React, { useEffect } from 'react';
import _omit from 'lodash/omit';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { selectIsListInitialized } from 'src/selectors/lists';

const withDynamicListNameListHandling = (WrappedComponent) => {
    const WithDynamicListNameListHandling = (props) => {
        const {
            listName, listItemIds, isListInitialized, updateListValuesActions
        } = props;
        const handleInitializeList = () => {
            const { initializeListAction } = props;
            initializeListAction(listName, listItemIds);
        };

        useEffect(() => {
            if (!isListInitialized) {
                handleInitializeList();
            } else {
                updateListValuesActions(listName, listItemIds);
            }
        }, [listName, listItemIds]);

        const cleanedProps = _omit(props, [
            'listItemIds',
            'initializeListAction'
        ]);
        return <WrappedComponent {...cleanedProps} />;
    };

    WithDynamicListNameListHandling.propTypes = {
        listItemIds: PropTypes.arrayOf(PropTypes.string).isRequired,
        initializeListAction: PropTypes.func.isRequired,
        listName: PropTypes.string.isRequired,
        isListInitialized: PropTypes.bool.isRequired,
        updateListValuesActions: PropTypes.func.isRequired,
    };

    const mapStateToProps = (state, ownProps) => ({
        isListInitialized: selectIsListInitialized(state, ownProps.listName)
    });

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    WithDynamicListNameListHandling.displayName = `withDynamicListNameListHandling(${wrappedComponentName})`;

    return connect(mapStateToProps, {
        initializeListAction: initializeList,
        updateListValuesActions: updateListValues
    })(WithDynamicListNameListHandling);
};

export default withDynamicListNameListHandling;
