import { ensurePermission, writePermissions } from 'src/middleware/userPermissionCheck';
import * as overlayActions from 'src/actions/overlays';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/views/profiles/profilesSidebar.scss';
import AccountsSidebar from 'src/components/account/layout/AccountsSidebar';
import { makeSelectProfilesByIds } from 'src/selectors/profiles';
import { makeSelectCheckedValues } from 'src/selectors/lists';
import { makeOrderBySorter } from 'src/selectors/accounts';
import { makePropertyExtractor } from 'src/selectors/utils';
import CheckedAccounts from 'src/components/account/layout/CheckedAccounts';
import withRemoveListItemIcon from 'src/components/withRemoveListItemIcon';
import ProfileInfoListItemContainer from 'src/components/views/profiles/ProfileInfoListItemContainer';

const ProfileInfoListItemWithRemoveListItemIcon = withRemoveListItemIcon(ProfileInfoListItemContainer);

const ProfilesSidebar = (props) => {
    const {
        listName,
        modalsShowGroupSelectorAction,
        modalsShowProfileDeleteAction,
        modalShowActivateBulkProfileUseCasesAction,
        profileIds
    } = props;

    return (
        <AccountsSidebar
          listName={listName}
          renderButtons={(profilesSelected, checkedValues) => (
              <>
                  <Button
                    small
                    stretch
                    disabled={!profilesSelected}
                    className={styles.button}
                    onClick={() => modalsShowGroupSelectorAction(checkedValues)}
                    label="Assign groups"
                  />
                  <Button
                    small
                    stretch
                    disabled={!profilesSelected}
                    className={styles.button}
                    onClick={() => modalShowActivateBulkProfileUseCasesAction(checkedValues)}
                    label="Activate private stats use cases"
                  />
                  <Button
                    small
                    stretch
                    disabled={!profilesSelected}
                    onClick={() => modalsShowProfileDeleteAction(checkedValues)}
                    label="Delete"
                  />
              </>
          )}
          renderCheckedAccounts={() => (
              <CheckedAccounts
                checkedValues={profileIds}
                listName={listName}
                label="Selected profiles"
                renderListItem={(accountId) => (
                    <ProfileInfoListItemWithRemoveListItemIcon
                      listName={listName}
                      fieldName={accountId}
                      profileId={accountId}
                    />
                )}
              />
          )}
        />
    );
};

ProfilesSidebar.propTypes = {
    modalsShowProfileDeleteAction: PropTypes.func.isRequired,
    modalsShowGroupSelectorAction: PropTypes.func.isRequired,
    modalShowActivateBulkProfileUseCasesAction: PropTypes.func.isRequired,
    listName: PropTypes.string.isRequired,
    profileIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

const makeMapStateToProps = () => {
    const selectProfilesByIds = makeSelectProfilesByIds();
    const selectCheckedValues = makeSelectCheckedValues();
    const sorter = makeOrderBySorter();
    const idExtractor = makePropertyExtractor('id');

    return (state, ownProps) => {
        const profileIds = selectCheckedValues(state, ownProps.listName);
        const profiles = selectProfilesByIds(state, profileIds);
        const sortedProfileIds = idExtractor(sorter(profiles, 'alphabetically'));
        return {
            profileIds: sortedProfileIds
        };
    };
};

export default connect(makeMapStateToProps, {
    modalsShowProfileDeleteAction: ensurePermission(overlayActions.modalsShowProfileDelete, writePermissions.deleteProfile),
    modalsShowGroupSelectorAction: ensurePermission(overlayActions.modalsShowGroupSelectorProfile, writePermissions.assignProfileToGroups),
    modalShowActivateBulkProfileUseCasesAction: ensurePermission(overlayActions.modalShowActivateBulkProfileUseCases, writePermissions.assignProfileToGroups)
})(ProfilesSidebar);
