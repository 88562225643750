import List from 'src/components/List';
import PropTypes from 'prop-types';
import React from 'react';
import UserListItemContainer from 'src/components/views/settings/users/UserListItemContainer';

const UsersList = (props) => {
    const { userIds } = props;
    return (
        <List>
            {
                userIds.map((userId) => (
                    <UserListItemContainer userId={userId} key={`dashboard-${userId}`} />
                ))
            }
        </List>
    );
};

UsersList.propTypes = {
    userIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default UsersList;
