import { makeSelectAuthUserById } from 'src/selectors/authUsers';
import AuthUserListItem from 'src/components/views/authentications/authUser/AuthUserItem';
import { connect } from 'react-redux';
import { makeSelectAuthUserAuthProfileUseCasesByAuthUserId } from 'src/selectors/authUsersAuthProfileUseCases';
import { makeSelectProfileUseCasesAuthUsersByAuthUserId } from 'src/selectors/profileUseCasesAuthUsers';
import { makeSelectAdAccountUseCasesAuthUsersByAuthUserId } from 'src/selectors/adAccountUseCaseAuthUsers';
import { makeSelectAuthUsersAuthAdAccountUseCasesByAdAuthUserId } from 'src/selectors/authUserAuthAdAccountUseCases';
import { getProfileIdsFromUseCases, getAdAccountIdsFromUseCases } from 'src/utils/accountInsights';
import { makeSelectProfilesByIds } from 'src/selectors/profiles';
import { defaultNameStringOrderWithExcludedPrefix } from 'src/selectors/accounts';
import { makeSelectAdAccountsByIds } from 'src/selectors/adAccounts';

const makeMapStateToProps = () => {
    const selectAuthUserById = makeSelectAuthUserById();
    const selectAuthUserAuthProfileUseCasesByAuthUserId = makeSelectAuthUserAuthProfileUseCasesByAuthUserId();
    const selectProfileUseCasesAuthUsersByAuthUserId = makeSelectProfileUseCasesAuthUsersByAuthUserId();
    const selectAdAccountUseCasesAuthUsersByAuthUserId = makeSelectAdAccountUseCasesAuthUsersByAuthUserId();
    const selectAuthUsersAuthAdAccountUseCasesByAdAuthUserId = makeSelectAuthUsersAuthAdAccountUseCasesByAdAuthUserId();
    const selectProfilesByIds = makeSelectProfilesByIds();
    const selectAdAccountsByIds = makeSelectAdAccountsByIds();

    return (state, ownProps) => {
        const { id } = ownProps;
        const authUser = selectAuthUserById(state, id);
        const authUserAuthProfileUseCases = selectAuthUserAuthProfileUseCasesByAuthUserId(state, id);
        const profileUseCasesAuthUsers = selectProfileUseCasesAuthUsersByAuthUserId(state, id);
        const profileIds = getProfileIdsFromUseCases(authUserAuthProfileUseCases, profileUseCasesAuthUsers);
        const profiles = selectProfilesByIds(state, profileIds).sort(defaultNameStringOrderWithExcludedPrefix());

        const adAccountUseCasesAuthUsers = selectAdAccountUseCasesAuthUsersByAuthUserId(state, id);
        const authUsersAuthAdAccountUseCases = selectAuthUsersAuthAdAccountUseCasesByAdAuthUserId(state, id);
        const adAccountIds = getAdAccountIdsFromUseCases(adAccountUseCasesAuthUsers, authUsersAuthAdAccountUseCases);
        const adAccounts = selectAdAccountsByIds(state, adAccountIds);
        return {
            authUser,
            profiles,
            adAccounts
        };
    };
};

export default connect(makeMapStateToProps)(AuthUserListItem);
