import React, { Component } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import Feedback from 'src/components/feedback/Feedback';
import IcomoonIcon from 'src/components/IcomoonIcon';
import LoadingScreen from 'src/components/LoadingScreen';
import NoUserNotificationView from 'src/components/userNotificationMenu/NoUserNotificationView';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/userNotificationMenu/userNotificationMenuContainer.scss';
import UserNotificationMenuListItem from 'src/components/userNotificationMenu/UserNotificationMenuListItem';
import { userNotificationsRoute } from 'src/routePaths';

class UserNotificationMenuView extends Component {
    constructor(props) {
        super(props);
        this.handleOnSettingsClick = this.handleOnSettingsClick.bind(this);
        this.handleOnSeeAllClickButton = this.handleOnSeeAllClickButton.bind(this);
        this.handleOnNotificationItemClick = this.handleOnNotificationItemClick.bind(this);
    }

    handleOnSettingsClick() {
        const { hidePopover, modalsShowUserNotificationSettingsAction } = this.props;
        hidePopover();
        modalsShowUserNotificationSettingsAction();
    }

    handleOnNotificationItemClick(userNotificationItem) {
        const { onNotificationClick, hidePopover } = this.props;
        hidePopover();
        onNotificationClick(userNotificationItem);
    }

    handleOnSeeAllClickButton() {
        const { routerPushAction, hidePopover, getUserNotificationsRequestAction } = this.props;
        hidePopover();
        getUserNotificationsRequestAction(0, null, true);
        routerPushAction({ pathname: userNotificationsRoute });
    }

    render() {
        const { markAllUserNotificationAsReadAction, userNotificationItems, userMenuNotificationRequestState } = this.props;
        const userNotificationLength = userNotificationItems.length;
        const { loading, error } = userMenuNotificationRequestState;

        return (
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <div className={styles.left}>Notifications</div>
                    <div className={styles.right}>
                        <a
                          className={classnames(styles.markAsRead, { [styles.disabled]: userNotificationLength === 0 })}
                          onClick={() => { markAllUserNotificationAsReadAction(); }}
                        >
                            Mark all as read
                        </a>
                        <IcomoonIcon
                          onClick={this.handleOnSettingsClick}
                          icon="settings"
                          className={styles.setting}
                        />
                    </div>
                </div>
                <div className={styles.body}>
                    {
                        (error && !loading)
                        && (
                            <div className={styles.loadingAndEmpty}>
                                <Feedback
                                  type="error"
                                  content={error}
                                  responsive={false}
                                />
                            </div>
                        )
                    }
                    {
                        (!error && userNotificationLength > 0)
                        && userNotificationItems.map((userNotificationItem, i) => (
                            <div
                              key={userNotificationItem.id}
                              className={classnames(styles.notificationItem, { [styles.lastNotification]: i === userNotificationLength - 1 })}
                            >
                                <UserNotificationMenuListItem
                                  onNotificationItemClick={this.handleOnNotificationItemClick}
                                  userNotificationItem={userNotificationItem}
                                />
                            </div>
                        ))
                    }
                    {
                        (loading && userNotificationLength === 0)
                        && (
                            <div className={styles.loadingAndEmpty}>
                                <LoadingScreen />
                            </div>
                        )
                    }
                    {
                        (!error && !loading && userNotificationLength === 0)
                        && (
                            <div className={styles.loadingAndEmpty}>
                                <NoUserNotificationView />
                            </div>
                        )
                    }
                </div>
                <a className={styles.footer} onClick={this.handleOnSeeAllClickButton}>See all</a>
            </div>
        );
    }
}

UserNotificationMenuView.propTypes = {
    onNotificationClick: PropTypes.func.isRequired,
    hidePopover: PropTypes.func.isRequired,
    markAllUserNotificationAsReadAction: PropTypes.func.isRequired,
    userNotificationItems: customPropTypes.userNotificationItems.isRequired,
    routerPushAction: PropTypes.func.isRequired,
    userMenuNotificationRequestState: customPropTypes.userMenuNotificationRequestState.isRequired,
    getUserNotificationsRequestAction: PropTypes.func.isRequired,
    modalsShowUserNotificationSettingsAction: PropTypes.func.isRequired
};

export default UserNotificationMenuView;
