import {
    SAMPLE_SERVER_DATA_ERROR,
    SAMPLE_SERVER_DATA_REQUEST,
    SAMPLE_SERVER_DATA_SUCCESS
} from 'src/actions/sampleServerData';
import { setIn } from 'src/reducers/utils';

const getPath = (metricId, visualizationId) => `${metricId}-${visualizationId}`;

export const sampleServerData = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case SAMPLE_SERVER_DATA_REQUEST: {
            const { metricId, visualizationId } = payload;
            const path = getPath(metricId, visualizationId);
            return setIn(state, path, { loading: true, data: null, error: null });
        }
        case SAMPLE_SERVER_DATA_SUCCESS: {
            const { metricId, visualizationId, data } = payload;
            const path = getPath(metricId, visualizationId);
            return setIn(state, path, { loading: false, data, error: null });
        }
        case SAMPLE_SERVER_DATA_ERROR: {
            const { metricId, visualizationId, error } = payload;
            const path = getPath(metricId, visualizationId);
            return setIn(state, path, { loading: false, data: null, error });
        }

        default:
            return state;
    }
};
