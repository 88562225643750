import * as customPropTypes from 'src/customPropTypes';
import React, { useContext } from 'react';
import AccountUseCasesStatusTableColumns from 'src/components/views/authentications/table/AccountUseCasesStatusTableColumns';
import AccountInfoListItemByAccountType from 'src/components/AccountInfoListItemByAccountType';
import AccountTypeContext from 'src/components/context/AccountTypeContext';
import PropTypes from 'prop-types';
import ListTableRow from 'src/components/listTable/ListTableRow';
import ListTableCell from 'src/components/listTable/ListTableCell';

const getUseCasesByAuthUserId = (accountUseCasesAuthUsers, authUserId) => accountUseCasesAuthUsers.filter(({ userId }) => userId === authUserId);

const AccountInsightsByAuthUserRow = (props) => {
    const { account, authUserId, platformUseCases } = props;
    const { accountUseCasesAuthUsers, authUsersAuthAccountUseCases } = account;
    const { accountType } = useContext(AccountTypeContext);
    return (
        <ListTableRow>
            <ListTableCell padded={false}>
                <AccountInfoListItemByAccountType account={account} accountType={accountType} />
            </ListTableCell>
            <AccountUseCasesStatusTableColumns
              accountUseCaseAuthUsers={getUseCasesByAuthUserId(accountUseCasesAuthUsers, authUserId)}
              authUserAuthAccountUseCases={getUseCasesByAuthUserId(authUsersAuthAccountUseCases, authUserId)}
              platformUseCases={platformUseCases}
            />
        </ListTableRow>
    );
};

AccountInsightsByAuthUserRow.propTypes = {
    account: customPropTypes.account.isRequired,
    authUserId: PropTypes.string.isRequired,
    platformUseCases: customPropTypes.useCases.isRequired
};

export default AccountInsightsByAuthUserRow;
