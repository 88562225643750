import { createSelector } from 'reselect';
import { initialState } from 'src/reducers/recommendations';

const getRecommendations = (state) => state.recommendations;

export const makeSelectRecommendationsByIdentifiers = () => createSelector(
    [
        getRecommendations,
        (_, identifier) => identifier
    ],
    (recommendations, identifier) => recommendations[identifier] || initialState
);
