import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import React from 'react';
import styles from 'src/stylesheets/floatingListItem.scss';

const DiscoverFloatingListItem = ({ children }) => (
    <li className={classnames(styles.discover, styles.floatingListItem)}>
        {children}
    </li>
);

DiscoverFloatingListItem.propTypes = {
    children: customPropTypes.children
};

export default DiscoverFloatingListItem;
