import React, { PureComponent } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/discoverTileBody.scss';
import TagList from 'src/components/TagList';
import withLazyLoad from 'src/components/lazyLoad/withLazyLoad';
import withTagClickBehavior from 'src/components/withTagClickBehavior';

const TagListWithTagClickBehavior = withTagClickBehavior(TagList);

const cutText = (text, maxCharacters) => {
    const words = text.split(' ');
    let newText = '';
    let textWasCut = false;
    for (let i = 0, l = words.length; i < l; i++) {
        if (newText.length + words[i].length > maxCharacters) {
            textWasCut = true;
            break;
        }

        newText += `${words[i]} `;
    }

    return `${newText.slice(0, -1)}${textWasCut ? '...' : ''}`;
};

const ImageWithLazyLoad = withLazyLoad((props) => <div className={classnames(styles.image, props.className)} style={{ backgroundImage: `url(${props.imageUrl}), url(${props.placeholderImage})` }} />);

class DiscoverTileBody extends PureComponent {
    render() {
        const {
            imageUrl, summary, tags, lazyLoad, placeholderImage, className
        } = this.props;
        const maxSummaryLength = 115;

        return (
            <div className={styles.body}>
                <ImageWithLazyLoad placeholderImage={placeholderImage} imageUrl={imageUrl} height={170} lazyLoad={lazyLoad} className={className} />
                <div className={styles.description}>
                    { cutText(summary, maxSummaryLength) }
                </div>
                <div className={styles.tags} onClick={(e) => { e.stopPropagation(); }}>
                    <TagListWithTagClickBehavior tags={tags} />
                </div>
            </div>
        );
    }
}

DiscoverTileBody.propTypes = {
    imageUrl: PropTypes.string.isRequired,
    placeholderImage: PropTypes.string,
    summary: PropTypes.string.isRequired,
    tags: customPropTypes.tags.isRequired,
    lazyLoad: PropTypes.bool,
    className: PropTypes.string
};

DiscoverTileBody.defaultProps = {
    lazyLoad: true,
    placeholderImage: '',
    className: ''
};

export default DiscoverTileBody;
