import AccountsSidebar from 'src/components/account/layout/AccountsSidebar';
import { ensurePermission, writePermissions } from 'src/middleware/userPermissionCheck';
import * as overlayActions from 'src/actions/overlays';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/views/profiles/profilesSidebar.scss';
import { makeSelectAdAccountsByIds } from 'src/selectors/adAccounts';
import { makeSelectCheckedValues } from 'src/selectors/lists';
import { makeOrderBySorter } from 'src/selectors/accounts';
import { makePropertyExtractor } from 'src/selectors/utils';
import CheckedAccounts from 'src/components/account/layout/CheckedAccounts';
import withRemoveListItemIcon from 'src/components/withRemoveListItemIcon';
import AdAccountInfoListItemContainer from 'src/components/views/adAccounts/AdAccountInfoListItemContainer';

const AdAccountInfoListItemWithRemoveListItemIcon = withRemoveListItemIcon(AdAccountInfoListItemContainer);

const AdAccountsSidebar = (props) => {
    const {
        modalsShowGroupSelectorAction,
        listName,
        adAccountIds,
        modalsShowAdAccountDeleteAction,
        modalShowActivateBulkAdAccountUseCasesAction
    } = props;

    return (
        <AccountsSidebar
          listName={listName}
          renderButtons={(profilesSelected, checkedValues) => (
              <>
                  <Button
                    small
                    stretch
                    disabled={!profilesSelected}
                    className={styles.button}
                    onClick={() => modalsShowGroupSelectorAction(checkedValues)}
                    label="Assign groups"
                  />
                  <Button
                    small
                    stretch
                    disabled={!profilesSelected}
                    className={styles.button}
                    onClick={() => modalShowActivateBulkAdAccountUseCasesAction(checkedValues)}
                    label="Activate private stats use cases"
                  />
                  <Button
                    small
                    stretch
                    disabled={!profilesSelected}
                    onClick={() => modalsShowAdAccountDeleteAction(checkedValues)}
                    label="Delete"
                  />
              </>
          )}
          renderCheckedAccounts={() => (
              <CheckedAccounts
                listName={listName}
                checkedValues={adAccountIds}
                label="Selected ad accounts"
                renderListItem={(accountId) => (
                    <AdAccountInfoListItemWithRemoveListItemIcon
                      listName={listName}
                      fieldName={accountId}
                      adAccountId={accountId}
                      showThumbnail={false}
                    />
                )}
              />
          )}
        />
    );
};

AdAccountsSidebar.propTypes = {
    modalsShowAdAccountDeleteAction: PropTypes.func.isRequired,
    modalsShowGroupSelectorAction: PropTypes.func.isRequired,
    listName: PropTypes.string.isRequired,
    modalShowActivateBulkAdAccountUseCasesAction: PropTypes.func.isRequired,
    adAccountIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

const makeMapStateToProps = () => {
    const selectAdAccountsByIds = makeSelectAdAccountsByIds();
    const selectCheckedValues = makeSelectCheckedValues();
    const sorter = makeOrderBySorter();
    const idExtractor = makePropertyExtractor('id');

    return (state, ownProps) => {
        const checkedValues = selectCheckedValues(state, ownProps.listName);
        const adAccounts = selectAdAccountsByIds(state, checkedValues);
        const sortedAdAccountIds = idExtractor(sorter(adAccounts, 'alphabetically'));
        return {
            adAccountIds: sortedAdAccountIds
        };
    };
};

export default connect(makeMapStateToProps, {
    modalsShowAdAccountDeleteAction: ensurePermission(overlayActions.modalsShowAdAccountDelete, writePermissions.deleteProfile),
    modalsShowGroupSelectorAction: ensurePermission(overlayActions.modalsShowGroupSelectorAdAccount, writePermissions.assignProfileToGroups),
    modalShowActivateBulkAdAccountUseCasesAction: ensurePermission(overlayActions.modalShowActivateBulkAdAccountUseCases, writePermissions.updateProfileUseCases),
})(AdAccountsSidebar);
