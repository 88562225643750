import Checkbox from 'src/components/forms/inputs/Checkbox';
import { connect } from 'react-redux';
import DashboardsFilterBarForm from 'src/components/views/dashboards/DashboardsFilterBarForm';
import { makeSelectIsAtLeastOneItemAvailable } from 'src/selectors/lists';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/listHeader.scss';
import withBulkCheckboxHandling from 'src/components/bulkList/withBulkCheckboxHandling';

const DashboardListBulkCheckbox = withBulkCheckboxHandling(Checkbox, true);

const DashboardsListHeader = (props) => {
    const { isAtLeastOneItemAvailable, listName } = props;
    return (
        <div className={styles.listHeader}>
            <div className={styles.checkOption}>
                <DashboardListBulkCheckbox listName={listName} disabled={!isAtLeastOneItemAvailable} />
            </div>
            <div className={styles.filterBar}>
                <DashboardsFilterBarForm />
            </div>
        </div>
    );
};

DashboardsListHeader.propTypes = {
    isAtLeastOneItemAvailable: PropTypes.bool.isRequired,
    listName: PropTypes.string.isRequired
};

const makeMapStateToProps = () => {
    const selectIsAtLeastOneItemAvailable = makeSelectIsAtLeastOneItemAvailable();
    return (state, ownProps) => ({
        isAtLeastOneItemAvailable: selectIsAtLeastOneItemAvailable(state, ownProps.listName)
    });
};

export default connect(makeMapStateToProps, {})(DashboardsListHeader);
