import React, { PureComponent } from 'react';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import NetworkIcon from 'src/components/NetworkIcon';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/analysisTileHeader.scss';

class AnalysisTileHeader extends PureComponent {
    render() {
        const {
            platformType, name
        } = this.props;
        return (
            <div className={styles.header}>
                {
                    platformType
                        && (
                        <NetworkIcon
                          platformType={platformType}
                          className={styles.networkIcon}
                        />
                        )
                }
                <div className={styles.nameWrapper}>
                    <DivWithTooltip tooltip={name} className={styles.name}>{name}</DivWithTooltip>
                </div>
            </div>
        );
    }
}

AnalysisTileHeader.propTypes = {
    platformType: PropTypes.string,
    name: PropTypes.string.isRequired
};

export default AnalysisTileHeader;
