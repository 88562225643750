import * as customPropTypes from 'src/customPropTypes';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import MenuButton from 'src/components/buttons/MenuButton';
import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import React from 'react';
import { makeSelectDashboardReportById } from 'src/selectors/dashboardReports';
import { push } from 'react-router-redux';
import { dashboardRoute } from 'src/routePaths';
import { modalsShowEditReport, modalShowDashboardReportDelete } from 'src/actions/overlays';
import { dashboardReportActivateRequest, dashboardReportDeactivateRequest } from 'src/actions/dashboardReports';

const DashboardReportOptionsPopover = (props) => {
    const { hidePopover, dashboardReport } = props;
    const { id, dashboardId, isActive } = dashboardReport;
    const handelOnGoToDashboard = () => {
        const { pushAction } = props;
        pushAction(`${dashboardRoute}/${dashboardId}`);
        hidePopover();
    };

    const onDashboardReportEdit = () => {
        const { modalsShowEditReportAction } = props;
        modalsShowEditReportAction(id);
        hidePopover();
    };

    const onDashboardReportDeactivate = () => {
        const { dashboardReportDeactivateRequestAction } = props;
        dashboardReportDeactivateRequestAction(id);
        hidePopover();
    };

    const onDashboardReportActivate = () => {
        const { dashboardReportActivateRequestAction } = props;
        dashboardReportActivateRequestAction(id);
        hidePopover();
    };

    const onDashboardReportDelete = () => {
        const { modalShowDashboardReportDeleteAction } = props;
        modalShowDashboardReportDeleteAction(id);
        hidePopover();
    };

    return (
        <Popover id="dashboardReportOptionsPopover" width={240}>
            <ButtonGroup vertical>
                {
                    isActive && (
                        <MenuButton
                          label="Turn off schedule"
                          onClick={onDashboardReportDeactivate}
                        />
                    )
                }
                {
                    !isActive && (
                        <MenuButton
                          label="Turn on schedule"
                          onClick={onDashboardReportActivate}
                        />
                    )
                }
                <MenuButton
                  label="Manage report settings"
                  onClick={onDashboardReportEdit}
                />
                <MenuButton
                  label="Go to dashboard"
                  onClick={handelOnGoToDashboard}
                />
                <MenuButton
                  label="Delete"
                  onClick={onDashboardReportDelete}
                />
            </ButtonGroup>
        </Popover>
    );
};

DashboardReportOptionsPopover.propTypes = {
    dashboardReportId: PropTypes.string.isRequired,
    dashboardReport: customPropTypes.dashboardReport.isRequired,
    dashboardReportDeactivateRequestAction: PropTypes.func.isRequired,
    dashboardReportActivateRequestAction: PropTypes.func.isRequired,
    modalShowDashboardReportDeleteAction: PropTypes.func.isRequired,
    modalsShowEditReportAction: PropTypes.func.isRequired,
    pushAction: PropTypes.func.isRequired,
    hidePopover: PropTypes.func
};

const makeMapStateToProps = () => {
    const selectDashboardReportById = makeSelectDashboardReportById();
    return (state, ownProps) => ({
        dashboardReport: selectDashboardReportById(state, ownProps.dashboardReportId)
    });
};

export default connect(makeMapStateToProps, {
    pushAction: push,
    modalsShowEditReportAction: modalsShowEditReport,
    dashboardReportDeactivateRequestAction: dashboardReportDeactivateRequest,
    dashboardReportActivateRequestAction: dashboardReportActivateRequest,
    modalShowDashboardReportDeleteAction: modalShowDashboardReportDelete
})(DashboardReportOptionsPopover);
