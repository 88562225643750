import { DASHBOARDS_GET_SUCCESS, DASHBOARD_EDIT_SUCCESS } from 'src/actions/dashboard';
import _omit from 'lodash/omit';
import { combineReducers } from 'redux';
import { REPORT_OVER_VIEWS_DELETE_SUCCESS } from 'src/actions/reportOverview';
import {
    DASHBOARD_REPORT_ACTIVATE_SUCCESS,
    DASHBOARD_REPORT_DEACTIVATE_SUCCESS,
    DASHBOARD_REPORT_DELETE_SUCCESS,
    DASHBOARD_REPORT_UPDATE_SUCCESS,
    DASHBOARD_REPORT_ADD_SUCCESS,
    DASHBOARD_REPORTS_CLEANUP
} from 'src/actions/dashboardReports';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';
import _includes from 'lodash/includes';
import _difference from 'lodash/difference';

function allIds(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case USER_LOGGED_IN:
        case DASHBOARDS_GET_SUCCESS: {
            const { dashboardReports: dashboardReportsFromPayload } = payload;
            return Object.keys(dashboardReportsFromPayload);
        }
        case DASHBOARD_REPORT_ADD_SUCCESS: {
            const { dashboardReport } = payload;
            return [...state, dashboardReport.id];
        }
        case DASHBOARD_REPORT_DELETE_SUCCESS: {
            const { dashboardReportId } = payload;
            return state.filter((value) => value !== dashboardReportId);
        }
        case REPORT_OVER_VIEWS_DELETE_SUCCESS: {
            const { successDashboardReportIds } = payload;
            return state.filter((value) => !_includes(successDashboardReportIds, value));
        }
        case DASHBOARD_REPORTS_CLEANUP: {
            const { dashboardReportIds } = payload;
            return _difference(state, dashboardReportIds);
        }
        default:
            return state;
    }
}

export function byId(state = {}, action) {
    const { type, payload } = action;
    switch (type) {
        case USER_LOGGED_IN:
        case DASHBOARDS_GET_SUCCESS:
        case DASHBOARD_EDIT_SUCCESS: {
            const { dashboardReports: dashboardReportsFromPayload } = payload;
            return Object.assign({}, state, dashboardReportsFromPayload);
        }
        case DASHBOARD_REPORT_ADD_SUCCESS:
        case DASHBOARD_REPORT_UPDATE_SUCCESS:
        case DASHBOARD_REPORT_ACTIVATE_SUCCESS:
        case DASHBOARD_REPORT_DEACTIVATE_SUCCESS: {
            const { dashboardReport } = payload;
            return Object.assign({}, state, { [dashboardReport.id]: dashboardReport });
        }
        case REPORT_OVER_VIEWS_DELETE_SUCCESS: {
            const { successDashboardReportIds } = payload;
            let newState = Object.assign({}, state);
            successDashboardReportIds.forEach((dashboardReportId) => {
                newState = _omit(newState, dashboardReportId);
            });
            return newState;
        }
        case DASHBOARD_REPORTS_CLEANUP: {
            const { dashboardReportIds } = payload;
            let oldState = Object.assign({}, state);
            dashboardReportIds.forEach((dashboardReportId) => {
                oldState = _omit(oldState, dashboardReportId);
            });
            return oldState;
        }
        case DASHBOARD_REPORT_DELETE_SUCCESS: {
            const { dashboardReportId } = payload;
            return _omit(state, dashboardReportId);
        }
        default:
            return state;
    }
}

export const dashboardReports = combineReducers({
    allIds,
    byId
});
