import React from 'react';
import styles from 'src/stylesheets/views/integrations/integrationAdminOnlyErrorView.scss';
import ScrollView from 'src/components/ScrollView';
import Body from 'src/components/Body';
import PropTypes from 'prop-types';
import HeaderWithHeadline from 'src/components/header/HeaderWithHeadline';

const IntegrationMissingAdminRoleErrorView = ({
    headline, title, description, image
}) => (
    <ScrollView dataViewName="integration-missing-admin-role">
        <HeaderWithHeadline headline={headline} />
        <Body>
            <div className={styles.wrapper}>
                <div className={styles.imageSection}>
                    <img src={image} className={styles.img} />
                </div>
                <div className={styles.title}>{title}</div>
                <div className={styles.message}>{description}</div>
            </div>
        </Body>
    </ScrollView>
);

IntegrationMissingAdminRoleErrorView.propTypes = {
    headline: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired
};

export default IntegrationMissingAdminRoleErrorView;
