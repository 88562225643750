import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/buttons/discoverFilterButton.scss';

const HeadlineSelectButton = (props) => {
    const { label, onClick } = props;
    return (
        <div className={styles.wrapper} onClick={onClick}>
            <div>{label}</div>
            <IcomoonIcon icon="chevron-big-down" />
        </div>
    );
};

HeadlineSelectButton.propTypes = {
    label: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired
};

export default HeadlineSelectButton;
