import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/views/sectionContent.scss';

const SectionContent = (props) => {
    const { label, children } = props;
    return (
        <div className={styles.wrapper}>
            <div className={styles.label}>{label}</div>
            <div className={styles.children}>{children}</div>
        </div>
    );
};

SectionContent.propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};

export default SectionContent;
