import {
    initializeList, registerListItems, unregisterList, unregisterListItems
} from 'src/actions/lists';
import React, { Component } from 'react';
import _difference from 'lodash/difference';
import _omit from 'lodash/omit';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// If you apply a filter to the list, please use the filter criteria as key in order to initialize a new list (DashboardList)
const withListStateHandling = (WrappedComponent, listName) => {
    class WithListStateHandling extends Component {
        componentDidMount() {
            const {
                initializeListAction, listItemIds
            } = this.props;
            initializeListAction(listName, listItemIds);
        }

        componentDidUpdate(prevProps) {
            const { listItemIds } = prevProps;
            const { listItemIds: listItemIdsNextProps, unregisterListItemsAction, registerListItemsAction } = this.props;
            if (listItemIdsNextProps !== listItemIds) {
                const deleted = _difference(listItemIds, listItemIdsNextProps);
                if (deleted.length > 0) {
                    unregisterListItemsAction(listName, deleted);
                }
                const added = _difference(listItemIdsNextProps, listItemIds);
                if (added.length > 0) {
                    registerListItemsAction(listName, added);
                }
            }
        }

        componentWillUnmount() {
            const { unregisterListAction } = this.props;
            unregisterListAction(listName);
        }

        render() {
            const cleanedProps = _omit(this.props, [
                'listItemIds',
                'initializeListAction',
                'unregisterListAction',
                'unregisterListItemsAction',
                'registerListItemsAction'
            ]);
            return <WrappedComponent {...cleanedProps} />;
        }
    }

    WithListStateHandling.propTypes = {
        listItemIds: PropTypes.arrayOf(PropTypes.string).isRequired,
        initializeListAction: PropTypes.func.isRequired,
        unregisterListAction: PropTypes.func.isRequired,
        unregisterListItemsAction: PropTypes.func.isRequired,
        registerListItemsAction: PropTypes.func.isRequired
    };

    return connect(null, {
        initializeListAction: initializeList,
        unregisterListAction: unregisterList,
        unregisterListItemsAction: unregisterListItems,
        registerListItemsAction: registerListItems
    })(WithListStateHandling);
};

export default withListStateHandling;
