import { ensurePermission, writePermissions } from 'src/middleware/userPermissionCheck';
import { modalsShowGroupEdit } from 'src/actions/overlays';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Column from 'src/components/header/layout/Column';
import { connect } from 'react-redux';
import Header from 'src/components/header/layout/Header';
import PageHeadline from 'src/components/header/layout/PageHeadline';
import AccountLimit from 'src/components/AccountLimit';
import PropTypes from 'prop-types';
import React from 'react';
import Separator from 'src/components/header/layout/Separator';
import TopRow from 'src/components/header/layout/TopRow';
import AddProfilesButton from 'src/components/buttons/AddProfilesButton';

const ProfilesHeader = (props) => {
    const { modalsShowGroupEditAction } = props;

    return (
        <Header>
            <TopRow>
                <Column flexGrow>
                    <PageHeadline>
                        Profiles
                    </PageHeadline>
                </Column>
                <Column>
                    <AccountLimit label="Profile" identifier="profiles" />
                </Column>
                <Column>
                    <Separator />
                </Column>
                <Column>
                    <div>
                        <ButtonGroup>
                            <AddProfilesButton />
                            <Button onClick={modalsShowGroupEditAction} label="Manage groups" />
                        </ButtonGroup>
                    </div>
                </Column>
            </TopRow>
        </Header>
    );
};

ProfilesHeader.propTypes = {
    modalsShowGroupEditAction: PropTypes.func.isRequired
};

export default connect(null, {
    modalsShowGroupEditAction: ensurePermission(modalsShowGroupEdit, writePermissions.editGroup)
})(ProfilesHeader);
