import DataPushTaskLimitStatus from 'src/components/DataPushTaskLimitStatus';
import Popover from 'src/components/popovers/layout/Popover';
import React from 'react';

const wrapperStyle = {
    width: 300,
    padding: 20
};

const DataPushTaskLimitPopover = () => (
    <Popover id="data-push-task-limitPopover">
        <div style={wrapperStyle}>
            <DataPushTaskLimitStatus />
        </div>
    </Popover>
);

export default DataPushTaskLimitPopover;
