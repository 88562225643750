import React, { Component } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/spaceLimitFutureSwaps.scss';

class SpaceLimitFutureSwaps extends Component {
    constructor(props) {
        super(props);
        this.toggleList = this.toggleList.bind(this);
        this.state = {
            toggleList: false
        };
    }

    toggleList() {
        const { toggleList } = this.state;
        this.setState({
            toggleList: !toggleList
        });
    }

    render() {
        const { futureSwaps, horizontal } = this.props;
        const { toggleList } = this.state;
        return (
            <div className={classnames(styles.futureSwaps, { [styles.horizontal]: horizontal })}>
                {
                    toggleList
                        && (
                        <div className={styles.innerContainer}>
                            {
                                futureSwaps.map((futureSwap) => {
                                    const swapWording = futureSwap.new > 1 ? 'swaps' : 'swap';
                                    return (
                                        <div className={classnames(styles.row, { [styles.horizontal]: horizontal })} key={`futureSwaps-${futureSwap.day}`}>
                                            <div>{futureSwap.day}</div>
                                            <div> +{futureSwap.new} available {swapWording}</div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        )
                }
                <a onClick={this.toggleList}>{ !toggleList ? 'Show' : 'Hide' } all swaps</a>
            </div>
        );
    }
}

SpaceLimitFutureSwaps.propTypes = {
    futureSwaps: customPropTypes.futureSwaps.isRequired,
    horizontal: PropTypes.bool
};

SpaceLimitFutureSwaps.defaultProps = {
    horizontal: false
};

export default SpaceLimitFutureSwaps;
