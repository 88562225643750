import BottomRow from 'src/components/header/layout/BottomRow';
import Column from 'src/components/header/layout/Column';
import DiscoverBackButton from 'src/components/views/discover/DiscoverBackButton';
import Header from 'src/components/header/layout/Header';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PageHeadline from 'src/components/header/layout/PageHeadline';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/headers/discoverDashboardAndMetricHeader.scss';
import TopRow from 'src/components/header/layout/TopRow';
import Error from 'src/components/errors/Error';

const DashboardAndMetricNotFoundHeader = (props) => {
    const { title, detail } = props;
    return (
        <Header>
            <TopRow>
                <Column flexGrow>
                    <div className={styles.headline}>
                        <div className={styles.backButton}>
                            <DiscoverBackButton />
                        </div>
                        <IcomoonIcon icon="warning" className={styles.warningIcon} />
                        <PageHeadline>{title}</PageHeadline>
                    </div>
                </Column>
            </TopRow>
            <BottomRow>
                <Column flexGrow>
                    <Error error={{ message: detail }} />
                </Column>
            </BottomRow>
        </Header>
    );
};

DashboardAndMetricNotFoundHeader.propTypes = {
    title: PropTypes.string.isRequired,
    detail: PropTypes.string.isRequired
};

export default DashboardAndMetricNotFoundHeader;
