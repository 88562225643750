import * as customPropTypes from 'src/customPropTypes';
import React from 'react';
import styles from 'src/stylesheets/simplePageFrame.scss';

const SimplePageFrame = ({ children }) => (
    <div className={styles.wrapper}>{children}</div>
);

SimplePageFrame.propTypes = {
    children: customPropTypes.children
};

export default SimplePageFrame;
