import React, { useEffect } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import * as metricSearchActions from 'src/actions/metricSearch';
import Body from 'src/components/Body';
import { connect } from 'react-redux';
import DiscoverFilter from 'src/components/views/discover/DiscoverFilter';
import DiscoverFloatingListItem from 'src/components/floatingList/DiscoverFloatingListItem';
import DiscoverHeader from 'src/components/header/DiscoverHeader';
import DiscoverSearchInput from 'src/components/views/discover/DiscoverSearchInput';
import discoverStyles from 'src/stylesheets/views/discover/discover.scss';
import DiscoverTile from 'src/components/metricSearchResultTile/DiscoverTile';
import FloatingList from 'src/components/floatingList/FloatingList';
import Footer from 'src/components/Footer';
import { makeSelectSearchResultsByInput } from 'src/selectors/metricSearch';
import NoResultsView from 'src/components/views/discover/NoResultsView';
import PropTypes from 'prop-types';
import ScrollView from 'src/components/ScrollView';
import SearchSummaryText from 'src/components/SearchSummaryText';
import styles from 'src/stylesheets/views/discover/discoverResult.scss';
import withScrollPosition from 'src/components/withScrollPosition';
import withStoreConnection from 'src/components/metricSearchResultTile/withStoreConnection';

const ScrollViewWithScrollPosition = withScrollPosition(ScrollView, 'discoverResult');
const ConnectedMetricTile = withStoreConnection(DiscoverTile);

const getScrollpositionIdentifier = (input) => btoa(encodeURIComponent(JSON.stringify(input)));

const DiscoverResults = (props) => {
    const { metricSearchSearchResult, metricSearchInput, metricSearchSearchRequestAction } = props;
    useEffect(() => {
        const { loading, error, data } = metricSearchSearchResult;
        if (!loading && !error && !data) {
            metricSearchSearchRequestAction(metricSearchInput);
        }
    }, [metricSearchSearchResult]);

    const { data } = metricSearchSearchResult;
    // This needs to be base64 encoded cause the identifier comes from url and could have symbols that get misinterpreded by _get()
    const identifier = getScrollpositionIdentifier(metricSearchInput);

    return (
        <ScrollViewWithScrollPosition
          dataViewName="discover-results"
          scrollPositionIdentifier={identifier}
          resetAfterRead
        >
            <DiscoverHeader />
            <Body>
                <DiscoverSearchInput layout="discover" />
                <div className={discoverStyles.body}>
                    <div className={discoverStyles.sidebar}>
                        <DiscoverFilter />
                    </div>
                    <div className={discoverStyles.resultBody}>
                        <div className={discoverStyles.results}>
                            <div className={discoverStyles.searchSummaryText}>
                                <SearchSummaryText
                                  metricSearchSearchResult={metricSearchSearchResult}
                                  metricSearchInput={metricSearchInput}
                                />
                            </div>
                            {
                                    data && data.length > 0
                                    && (
                                        <FloatingList className={styles.resultList}>
                                            {
                                                data.map((metricSearchResult) => (
                                                    <DiscoverFloatingListItem
                                                      key={`floatingListItem-${metricSearchResult.type}-${metricSearchResult.id}`}
                                                    >
                                                        <ConnectedMetricTile
                                                          type={metricSearchResult.type}
                                                          id={metricSearchResult.id}
                                                        />
                                                    </DiscoverFloatingListItem>
                                                ))
                                            }
                                        </FloatingList>
                                    )
                                }
                            {
                                    data && data.length < 1
                                    && <NoResultsView />
                                }
                        </div>
                    </div>
                </div>
            </Body>
            <Footer />
        </ScrollViewWithScrollPosition>
    );
};

DiscoverResults.propTypes = {
    metricSearchSearchResult: customPropTypes.metricSearchSearchResult.isRequired,
    metricSearchInput: customPropTypes.metricSearchInput.isRequired,
    metricSearchSearchRequestAction: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const selectSearchResultsByInput = makeSelectSearchResultsByInput();
    return (state, ownProps) => ({
        metricSearchSearchResult: selectSearchResultsByInput(state, ownProps.metricSearchInput)
    });
};

export default connect(makeMapStateToProps, {
    metricSearchSearchRequestAction: metricSearchActions.metricSearchSearchRequest
})(DiscoverResults);
