import {
    dashboardsRoute,
    customMetricsRoute,
    dashboardRoutes,
    metricBuilderRoutes,
    discoverRoutes,
    analysisRoute,
    reportRoutes,
    reportRoute
} from 'src/routePaths';
import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import { makeSelectLinkToLastAnalysis } from 'src/selectors/analysis';
import React from 'react';
import { selectTopLevelLinkToDiscover } from 'src/selectors/metricSearch';
import TopNav from 'src/components/navigations/TopNav';
import TopNavTextLink from 'src/components/buttons/TopNavTextLink';

const AnalysisNav = (props) => {
    const { linkToAnalysis, linkToDiscover } = props;
    return (
        <TopNav>
            <TopNavTextLink alsoActiveOn={[analysisRoute]} to={linkToAnalysis}>Quick analysis</TopNavTextLink>
            <TopNavTextLink alsoActiveOn={dashboardRoutes} to={dashboardsRoute}>Dashboards</TopNavTextLink>
            <TopNavTextLink alsoActiveOn={reportRoutes} to={reportRoute}>Reports</TopNavTextLink>
            <TopNavTextLink alsoActiveOn={discoverRoutes} to={linkToDiscover}>Discover</TopNavTextLink>
            <TopNavTextLink alsoActiveOn={metricBuilderRoutes} to={customMetricsRoute}>Custom metrics</TopNavTextLink>
        </TopNav>
    );
};

AnalysisNav.propTypes = {
    linkToDiscover: customPropTypes.linkTo.isRequired,
    linkToAnalysis: customPropTypes.linkTo.isRequired,
};

const makeMapStateToProps = () => {
    const selectLinkToLastAnalysis = makeSelectLinkToLastAnalysis();
    return (state) => ({
        linkToAnalysis: selectLinkToLastAnalysis(state),
        linkToDiscover: selectTopLevelLinkToDiscover(state),
    });
};

export default connect(makeMapStateToProps)(AnalysisNav);
