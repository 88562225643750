import React from 'react';
import styles from 'src/stylesheets/views/contentWithSidebar.scss';
import PropTypes from 'prop-types';

const ContentWithSidebar = (props) => {
    const {
        children,
        sidebar,
        showSidebar
    } = props;
    return (
        <div className={styles.wrapper}>
            <div className={styles.body}>
                {children}
            </div>
            {
                showSidebar
                && (
                    <div className={styles.sidebar}>
                        {sidebar}
                    </div>
                )
            }
        </div>
    );
};

ContentWithSidebar.propTypes = {
    children: PropTypes.node.isRequired,
    sidebar: PropTypes.node.isRequired,
    showSidebar: PropTypes.bool
};

ContentWithSidebar.defaultProps = {
    showSidebar: true
};

export default ContentWithSidebar;
