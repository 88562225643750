import DataWarningButton from 'src/components/buttons/DataWarningButton';
import IcomoonIcon from 'src/components/IcomoonIcon';
import MetricPersonalNotes from 'src/components/MetricPersonalNotes';
import {
    makeSelectDateByContext,
    makeSelectDashboardLink,
    makeAdditionalFilterSelectorsByContext,
    makeSelectProfileSelectorSelectionByContext
} from 'src/selectors/filterSelectors';
import * as customPropTypes from 'src/customPropTypes';
import * as routeActions from 'react-router-redux';
import AdditionalSettingAwareSelectors from 'src/components/views/dashboards/AdditionalSettingAwareSelectors';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import DashboardMetricExportButton from 'src/components/exportButtons/DashboardMetricExportActionProvider';
import DashboardMetricRequestProvider from 'src/components/requestHandling/DashboardMetricRequestProvider';
import MetricDataSourcesHints from 'src/components/views/MetricDataSourcesHints';
import Date from 'src/components/Date';
import ErrorView from 'src/components/errors/ErrorView';
import ExportButton from 'src/components/exportButtons/ExportButton';
import { makeSelectDashboardMetricById } from 'src/selectors/dashboardMetrics';
import MaximizedDashboardMetricScroller from 'src/components/views/dashboards/MaximizedDashboardMetricScroller';
import MaximizedMetricTile from 'src/components/metricTiles/MaximizedMetricTile';
import MetricNameHeaderWithIcons from 'src/components/MetricNameHeaderWithIcons';
import DashboardMetricOpenInPopoverContainer from 'src/components/popovers/DashboardMetricOpenInPopoverContainer';
import MetricRefreshButton from 'src/components/buttons/MetricRefreshButton';
import Modal from 'src/components/modals/layout/Modal';
import { modalShowDashboardMetricNoteModal, modalShowMetricSettingsTile } from 'src/actions/overlays';
import Number from 'src/components/Number';
import { parseExternalLinks } from 'src/parsers';
import PropTypes from 'prop-types';
import React from 'react';
import { selectAccountOptions } from 'src/selectors/loggedInUser';
import { selectDashboardById } from 'src/selectors/dashboards';
import ServerDataSingleRequestDispatcher from 'src/components/ServerDataSingleRequestDispatcher';
import styles from 'src/stylesheets/maximizedMetric.scss';
import withPopover from 'src/components/withPopover';
import withTooltip from 'src/components/withToolTip';
import withFilterContextConsumer from 'src/components/withFilterContextConsumer';
import MetricWarningProvider from 'src/components/MetricWarningProvider';
import { getNotesEditToolTip } from 'src/components/metricTiles/layout/utils';
import _get from 'lodash/get';
import { DASHBOARD } from 'src/utils/filterSelectors';

const ButtonWithPopover = withPopover(Button);
const ButtonWithTooltip = withTooltip(Button);

const MaximizedDashboardMetric = (props) => {
    const {
        dashboardMetric,
        dashboard,
        selectedProfilesOrGroups,
        selectedDate,
        accountOptions,
        routePushAction,
        linkToDashboard,
        selectedPostText,
        selectedPostTag,
        filterContext,
        selectedPostTextExclude,
        selectedAdCampaign,
        modalShowDashboardMetricNoteModalAction
    } = props;

    if (!dashboard) {
        return (
            <ErrorView
              headline="Dashboard not found"
              error={{ message: 'The dashboard you tried to access does not exist.' }}
            />
        );
    }

    if (!dashboardMetric) {
        return (
            <ErrorView
              headline="Metric not found"
              error={{ message: 'There is no such metric on this dashboard.' }}
            />
        );
    }

    if (!dashboardMetric || dashboard.dashboardMetricIds.indexOf(dashboardMetric.id) < 0) {
        return (
            <div>
                <h1>Metric does not exist</h1>
                <p>The requested metric does not exist on this dashboard</p>
            </div>
        );
    }
    const { metric, settings } = dashboardMetric;
    const {
        summary, dataSourceHints, description, useCases, usage, createTime
    } = metric;

    const notes = _get(settings, 'notes', '');

    const hideModal = () => {
        routePushAction(linkToDashboard);
    };

    const showMetricSettingsModal = () => {
        const { modalShowMetricSettingsTileAction } = props;
        modalShowMetricSettingsTileAction(dashboardMetric.id);
    };

    const dashboardMetricScroller = (
        <MaximizedDashboardMetricScroller
          dashboardId={dashboard.id}
          dashboardMetricId={dashboardMetric.id}
          filterContext={filterContext}
        />
    );
    const metricOpenInPopover = <DashboardMetricOpenInPopoverContainer context={DASHBOARD} dashboardMetric={dashboardMetric} />;
    return (
        <Modal
          key={dashboardMetric.id}
          onHide={hideModal}
        >
            <Modal.Dialog fullscreen>
                <Modal.Header onHide={hideModal} leftContent={dashboardMetricScroller}>
                    <Modal.Title>
                        <div className={styles.titleWrapper}>
                            <MetricNameHeaderWithIcons
                              name={dashboardMetric.displayName}
                              platformType={metric.platformType}
                              isCustomMetric={metric.isCustomMetric}
                              isBasedOnPrivateStatistics={metric.isBasedOnPrivateStatistics}
                              size="medium"
                              centre
                              tooltipPlacement="right"
                            />
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <DashboardMetricRequestProvider
                  dashboardMetric={dashboardMetric}
                  selectedProfilesOrGroups={selectedProfilesOrGroups}
                  selectedDate={selectedDate}
                  selectedPostText={selectedPostText}
                  selectedPostTextExclude={selectedPostTextExclude}
                  selectedPostTag={selectedPostTag}
                  selectedAdCampaign={selectedAdCampaign}
                  format="json"
                >
                    {
                        (serverRequest) => (
                            <Modal.Body>
                                <div className={styles.wrapper}>
                                    <div className={styles.header}>
                                        <div className={styles.headerLeft}>
                                            <AdditionalSettingAwareSelectors
                                              settings={dashboardMetric.settings}
                                              selectedProfilesOrGroups={selectedProfilesOrGroups}
                                              selectedDate={selectedDate}
                                              selectedPostTag={selectedPostTag}
                                              selectedPostText={selectedPostText}
                                            />
                                        </div>
                                        <div className={styles.headerRight}>
                                            <ButtonGroup>
                                                <MetricWarningProvider requestHash={serverRequest.request.hash}>
                                                    {(warnings) => (
                                                        <DataWarningButton
                                                          warnings={warnings}
                                                        />
                                                    )}
                                                </MetricWarningProvider>
                                                <ButtonWithTooltip
                                                  tooltip="Edit metric settings"
                                                  icon="settings"
                                                  onClick={showMetricSettingsModal}
                                                />
                                                <ButtonWithTooltip
                                                  tooltip={getNotesEditToolTip(notes)}
                                                  icon="note"
                                                  onClick={() => modalShowDashboardMetricNoteModalAction(dashboardMetric.id, notes.length === 0)}
                                                />
                                                <DashboardMetricExportButton
                                                  dashboardMetric={dashboardMetric}
                                                  serverRequest={serverRequest}
                                                  renderButton={(exportAction) => (
                                                      <ExportButton
                                                        multiAction
                                                        label="Export"
                                                        exportAction={exportAction}
                                                      />
                                                  )}
                                                />
                                                <ButtonWithPopover
                                                  id={dashboardMetric.id}
                                                  label="Open in..."
                                                  overlay={metricOpenInPopover}
                                                  placement="bottom-end"
                                                  multiAction
                                                />
                                                <MetricRefreshButton
                                                  serverDataHash={serverRequest.request.hash}
                                                  metricRequestIdentifier={serverRequest.request.identifier}
                                                />
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                    <div className={styles.metric}>
                                        <ServerDataSingleRequestDispatcher
                                          serverRequest={serverRequest}
                                          loadingIdentifier={`dashboard-metric-${dashboardMetric.id}`}
                                        >
                                            <MaximizedMetricTile
                                              selectedProfilesOrGroups={selectedProfilesOrGroups}
                                              selectedDate={selectedDate}
                                              accountOptions={accountOptions}
                                              metric={metric}
                                              settings={settings}
                                              requestHash={serverRequest.request.hash}
                                            />
                                        </ServerDataSingleRequestDispatcher>
                                    </div>
                                    <div className={styles.metaData}>
                                        <div className={styles.metaDataLeft}>
                                            <div className={styles.section}>
                                                <h2 className={styles.sectionHeadline}>Metric summary</h2>
                                                {
                                                    summary !== ''
                                                        ? <p className={styles.texts}>{summary}</p>
                                                        : <p className={styles.texts}>There is no summary yet.</p>
                                                }
                                                <div className={styles.meta}>
                                                    {
                                                        (usage !== null)
                                                        && (
                                                        <span>
                                                            Used <Number value={usage} /> times<br />
                                                        </span>
                                                        )
                                                    }
                                                    Added on <Date date={createTime} format="date" />
                                                </div>
                                            </div>
                                            <div className={styles.section}>
                                                <h2 className={styles.sectionHeadline}>Metric description</h2>
                                                <p
                                                  className={styles.texts}
                                                  dangerouslySetInnerHTML={{ __html: description !== '' ? parseExternalLinks(description) : 'There is no description yet.' }}
                                                />
                                            </div>
                                            {
                                                useCases
                                                    && (
                                                    <div className={styles.section}>
                                                        <h2 className={styles.sectionHeadline}>Use cases</h2>
                                                        <p
                                                          className={styles.texts}
                                                          dangerouslySetInnerHTML={{ __html: useCases }}
                                                        />
                                                    </div>
                                                    )
                                            }
                                        </div>
                                        <div className={styles.metaDataRight}>
                                            {
                                                settings.notes && (
                                                    <div className={styles.section}>
                                                        <h2 className={styles.sectionHeadline}>
                                                            Custom notes <IcomoonIcon className={styles.headlineIcon} icon="note" />
                                                        </h2>
                                                        <MetricPersonalNotes notes={settings.notes} />
                                                    </div>
                                                )
                                            }
                                            {
                                                dataSourceHints && dataSourceHints.length > 0
                                                && (
                                                <div className={styles.section}>
                                                    <h2 className={styles.sectionHeadline}>Metric hints</h2>
                                                    <MetricDataSourcesHints dataSourceHints={dataSourceHints} />
                                                </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        )
                    }
                </DashboardMetricRequestProvider>
            </Modal.Dialog>
        </Modal>
    );
};

MaximizedDashboardMetric.propTypes = {
    accountOptions: customPropTypes.accountOptions.isRequired,
    dashboardMetric: customPropTypes.dashboardMetricOrFalse.isRequired,
    selectedProfilesOrGroups: customPropTypes.profileFilter.isRequired,
    selectedDate: customPropTypes.selectedDate.isRequired,
    dashboard: customPropTypes.dashboardOrFalse.isRequired,
    modalShowMetricSettingsTileAction: PropTypes.func.isRequired,
    routePushAction: PropTypes.func.isRequired,
    linkToDashboard: customPropTypes.linkTo.isRequired,
    selectedPostText: customPropTypes.postTextFilter.isRequired,
    selectedPostTextExclude: customPropTypes.postTextExcludeFilter.isRequired,
    selectedPostTag: customPropTypes.postTagFilter.isRequired,
    selectedAdCampaign: customPropTypes.adCampaignFilter.isRequired,
    filterContext: PropTypes.string.isRequired,
    modalShowDashboardMetricNoteModalAction: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const selectDashboardMetricById = makeSelectDashboardMetricById();
    const selectDashboardLink = makeSelectDashboardLink();
    const selectDateByContext = makeSelectDateByContext();
    const {
        selectPostTagByContext, selectPostTextByContext, selectPostTextExcludeByContext, selectAdCampaignByContext
    } = makeAdditionalFilterSelectorsByContext();

    const selectProfileSelectorSelectionByContext = makeSelectProfileSelectorSelectionByContext();
    return (state, ownProps) => {
        const { params, filterContext } = ownProps;
        const dashboardMetric = selectDashboardMetricById(state, params.dashboardMetricId);
        const dashboard = selectDashboardById(state, params.dashboardId);
        return {
            dashboardMetric,
            dashboard,
            accountOptions: selectAccountOptions(state),
            selectedProfilesOrGroups: selectProfileSelectorSelectionByContext(state, filterContext),
            selectedDate: selectDateByContext(state, filterContext),
            linkToDashboard: selectDashboardLink(state, filterContext, params.dashboardId),
            selectedPostText: selectPostTextByContext(state, filterContext),
            selectedPostTextExclude: selectPostTextExcludeByContext(state, filterContext),
            selectedPostTag: selectPostTagByContext(state, filterContext),
            selectedAdCampaign: selectAdCampaignByContext(state, filterContext),
        };
    };
};

export default withFilterContextConsumer(connect(makeMapStateToProps, {
    modalShowMetricSettingsTileAction: modalShowMetricSettingsTile,
    modalShowDashboardMetricNoteModalAction: modalShowDashboardMetricNoteModal,
    routePushAction: routeActions.push
})(MaximizedDashboardMetric));
