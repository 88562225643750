import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import MenuButton from 'src/components/buttons/MenuButton';
import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import React from 'react';
import { makeSelectDashboardShareLinkById } from 'src/selectors/dashboardShareLinks';
import * as customPropTypes from 'src/customPropTypes';
import { dashboardRoute } from 'src/routePaths';
import { modalShowEditShareableLink, modalShowDashboardShareLinkDelete } from 'src/actions/overlays';

import withCopyToClipboard from 'src/components/withCopyToClipboard';

const MenuButtonWithCopyToClipboard = withCopyToClipboard(MenuButton);

const DashboardShareLinkOptionsPopover = (props) => {
    const { hidePopover, dashboardShareLink } = props;
    const { id, dashboardId, link } = dashboardShareLink;

    const onDashboardShareLinkEdit = () => {
        const { modalShowEditShareableLinkAction } = props;
        modalShowEditShareableLinkAction(id);
        hidePopover();
    };

    const onDashboardShareLinkDelete = () => {
        const { modalShowDashboardShareLinkDeleteAction } = props;
        modalShowDashboardShareLinkDeleteAction(id);
        hidePopover();
    };

    return (
        <Popover id="dashboardReportOptionsPopover" width={220}>
            <ButtonGroup vertical>
                <MenuButton
                  label="Manage report settings"
                  onClick={onDashboardShareLinkEdit}
                />
                <MenuButton
                  label="Visit report link"
                  to={link}
                  onClick={() => { hidePopover(); }}
                />
                <MenuButtonWithCopyToClipboard link={link} label="Copy report link" onClick={() => { hidePopover(); }} />
                <MenuButton
                  label="Go to dashboard"
                  to={`${dashboardRoute}/${dashboardId}`}
                />
                <MenuButton
                  label="Delete"
                  onClick={onDashboardShareLinkDelete}
                />
            </ButtonGroup>
        </Popover>
    );
};

DashboardShareLinkOptionsPopover.propTypes = {
    dashboardShareLinkId: PropTypes.string.isRequired,
    dashboardShareLink: customPropTypes.dashboardShareLink.isRequired,
    modalShowEditShareableLinkAction: PropTypes.func.isRequired,
    modalShowDashboardShareLinkDeleteAction: PropTypes.func.isRequired,
    hidePopover: PropTypes.func
};

const makeMapStateToProps = () => {
    const selectDashboardShareLinkById = makeSelectDashboardShareLinkById();
    return (state, ownProps) => ({
        dashboardShareLink: selectDashboardShareLinkById(state, ownProps.dashboardShareLinkId)
    });
};

export default connect(makeMapStateToProps, {
    modalShowDashboardShareLinkDeleteAction: modalShowDashboardShareLinkDelete,
    modalShowEditShareableLinkAction: modalShowEditShareableLink
})(DashboardShareLinkOptionsPopover);
