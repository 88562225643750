import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/sidebar/sidebarToggleButton.scss';
import withTooltip from 'src/components/withToolTip';

const IcomoonIconWithTooltip = withTooltip(IcomoonIcon);

const SidebarToggleButton = (props) => {
    const { onClick, isOpen } = props;
    const iconDirection = isOpen ? 'right' : 'left';

    return (
        <div className={styles.wrapper}>
            <IcomoonIconWithTooltip
              onClick={onClick}
              referenceWrapperClassName={styles.referenceWrapperClassName}
              tooltipPlacement="right"
              tooltip={isOpen ? 'Hide sidebar' : 'Show sidebar'}
              className={styles.toggleButtonIcon}
              icon={`chevron-big-${iconDirection}`}
            />
        </div>
    );
};

SidebarToggleButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired
};

export default SidebarToggleButton;
