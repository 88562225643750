import React, { useState } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import DashboardMetricOptionsPopover from 'src/components/popovers/DashboardMetricOptionsPopover';
import DashboardMetricOpenInPopoverContainer from 'src/components/popovers/DashboardMetricOpenInPopoverContainer';
import PropTypes from 'prop-types';
import { DASHBOARD } from 'src/utils/filterSelectors';

const DashboardMetricMenuWithMetricOpenInPopover = (props) => {
    const { dashboardMetricLink, dashboardMetric, hidePopover } = props;
    const [metricOpenInState, changeMetricOpenInState] = useState(false);

    const showMetricOpenInStateAction = () => {
        changeMetricOpenInState(true);
    };

    const hideMetricOpenInStateAction = () => {
        changeMetricOpenInState(false);
    };

    if (metricOpenInState) {
        return (
            <DashboardMetricOpenInPopoverContainer
              dashboardMetric={dashboardMetric}
              context={DASHBOARD}
              showBackButton
              onBackClick={hideMetricOpenInStateAction}
              hidePopover={hidePopover}
            />
        );
    }
    return (
        <DashboardMetricOptionsPopover
          dashboardMetricLink={dashboardMetricLink}
          dashboardMetric={dashboardMetric}
          onMetricOpenInClick={showMetricOpenInStateAction}
          hidePopover={hidePopover}
        />
    );
};

DashboardMetricMenuWithMetricOpenInPopover.propTypes = {
    dashboardMetric: customPropTypes.dashboardMetric.isRequired,
    hidePopover: PropTypes.func,
    dashboardMetricLink: customPropTypes.linkTo.isRequired
};

export default DashboardMetricMenuWithMetricOpenInPopover;
