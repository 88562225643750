import { makeSelectDataPushLogAsyncStateByDataPushTaskId } from 'src/selectors/dataPushTasks';
import React, { useEffect } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import * as dataPushTaskLogActions from 'src/actions/dataPushTaskLogs';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import DataPushTaskLogsTable from 'src/components/views/integrations/DataPushTaskLogsTable';
import { makeSelectDataPushTaskLogsByDataPushTaskId } from 'src/selectors/dataPushTaskLogs';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/views/integrations/dataPushTaskLogList.scss';

const DataPushTaskLogsContainer = (props) => {
    const {
        dataPushTaskLogs,
        getDataPushTaskLogsRequestAction,
        dataPushTask,
        isLoading
    } = props;

    useEffect(() => {
        if (dataPushTask) {
            getDataPushTaskLogsRequestAction(dataPushTask.id);
        }
    }, []);

    return (
        <div>
            <DataPushTaskLogsTable dataPushTaskLogs={dataPushTaskLogs} dataPushTaskId={dataPushTask.id} />
            <div className={styles.button}>
                <Button
                  label="Load more"
                  action
                  loading={isLoading}
                  onClick={() => { getDataPushTaskLogsRequestAction(dataPushTask.id, dataPushTaskLogs.length); }}
                />
            </div>
        </div>
    );
};

DataPushTaskLogsContainer.propTypes = {
    dataPushTask: PropTypes.oneOfType([customPropTypes.dataPushTask, PropTypes.bool]).isRequired,
    dataPushTaskLogs: customPropTypes.dataPushTaskLogs.isRequired,
    getDataPushTaskLogsRequestAction: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired
};

const makeMapStateToProps = () => {
    const selectDataPushTaskLogsByDataPushTaskId = makeSelectDataPushTaskLogsByDataPushTaskId();
    const selectDataPushLogAsyncStateByDataPushTaskId = makeSelectDataPushLogAsyncStateByDataPushTaskId();
    return (state, ownProps) => {
        const { dataPushTask } = ownProps;
        const dataPushTaskLogs = selectDataPushTaskLogsByDataPushTaskId(state, dataPushTask.id);
        const isLoading = selectDataPushLogAsyncStateByDataPushTaskId(state, dataPushTask.id);
        return {
            dataPushTaskLogs,
            isLoading
        };
    };
};

export default connect(makeMapStateToProps, {
    getDataPushTaskLogsRequestAction: dataPushTaskLogActions.getDataPushTaskLogsRequest
})(DataPushTaskLogsContainer);
