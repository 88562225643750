import AnalysisMetricListItem from 'src/components/views/analysis/AnalysisMetricListItem';
import CustomSelect from 'src/components/forms/inputs/CustomSelect';
import CustomSelectListItem from 'src/components/forms/inputs/layout/CustomSelectListItem';
import NetworkIcon from 'src/components/NetworkIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/views/analysis/analysis.scss';

const renderValue = (option) => (
    <div className={styles.quickAccessValue}>
        <NetworkIcon
          className={styles.icon}
          platformType={option.platformType}
        />
        <span className={styles.text}>{option.name}</span>
    </div>
);

const renderOption = (option, index, active, onChange) => (
    <CustomSelectListItem
      onClick={() => {
          onChange(option.id);
      }}
      active={active}
    >
        <AnalysisMetricListItem
          name={option.name}
          platformType={option.platformType}
        />
    </CustomSelectListItem>
);

const AnalysisQuickAccessSelect = (props) => {
    const { onChange, value, options } = props;
    return (
        <CustomSelect
          value={value}
          onChange={onChange}
          options={options}
          renderValue={renderValue}
          renderOption={renderOption}
          layout="analysis"
        />
    );
};

AnalysisQuickAccessSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    options: PropTypes.any.isRequired
};

export default AnalysisQuickAccessSelect;
