import Date from 'src/components/Date';
import React from 'react';
import styles from 'src/stylesheets/views/postTags/postTagging/postTilesByCreateTime.scss';
import PropTypes from 'prop-types';
import PostFloatingListTiles from 'src/components/views/postTags/postTagging/PostFloatingListTiles';

const PostTilesByCreateTime = (props) => {
    const {
        createTime, postIds, listName, disabled, timezone
    } = props;
    return (
        <div className={styles.wrapper}>
            <div className={styles.timeWrapper}>
                <div className={styles.horizontalLine} />
                <div className={styles.createTime}><Date date={createTime} dateToTimezone={timezone} dateInTimezone={timezone} /></div>
                <div className={styles.horizontalLine} />
            </div>
            <PostFloatingListTiles postIds={postIds} listName={listName} disabled={disabled} />
        </div>
    );
};

PostTilesByCreateTime.propTypes = {
    createTime: PropTypes.string.isRequired,
    postIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    listName: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    timezone: PropTypes.string.isRequired
};

export default PostTilesByCreateTime;
