import Body from 'src/components/Body';
import DashboardAndMetricNotFoundHeader from 'src/components/header/DashboardAndMetricNotFoundHeader';
import pageNotFoundImage from 'src/resources/illustrations/dashboardNotFound.png';
import React from 'react';
import ScrollView from 'src/components/ScrollView';
import styles from 'src/stylesheets/views/discover/metricDashboardNotFound.scss';

const DashboardNotFoundPage = () => (
    <ScrollView dataViewName="dashboard-page-not-found">
        <DashboardAndMetricNotFoundHeader title="Dashboard not found" detail="This dashboard does not exist or has been removed." />
        <Body>
            <div className={styles.wrapper}>
                <img
                  src={pageNotFoundImage}
                  alt="empty"
                  className={styles.image}
                />
                <div className={styles.expander} />
            </div>
        </Body>
    </ScrollView>
);

export default DashboardNotFoundPage;
