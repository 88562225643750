import {
    CREATE_SPACE_REQUEST,
    DELETE_SPACE_REQUEST,
    UPDATE_SPACE_REQUEST,
    createSpaceError,
    createSpaceSuccess,
    deleteSpaceError,
    deleteSpaceSuccess,
    updateSpaceError,
    updateSpaceSuccess
} from 'src/actions/spaces';
import {
    call, put, select, takeEvery
} from 'redux-saga/effects';
import { modalsHideCreateSpace, modalsHideDeleteSpace, modalsHideEditSpace } from 'src/actions/overlays';
import { parseSpace } from 'src/parsers';
import { selectLoggedInSpace, selectLoggedInUserSpaces } from 'src/selectors/spaces';
import createServerRequest from 'src/requestHandling/createServerRequest';
import createFormDataServerRequest from 'src/requestHandling/createFormDataServerRequest';

import { getFileBlob, handleAuthorizedServerRequest } from 'src/sagas/utils';
import { reportError } from 'src/utils/reportError';
import { selectLoggedInUser } from 'src/selectors/users';
import { showNotification } from 'src/actions/notifications';
import { SubmissionError } from 'redux-form';
import { userSwitchSpaceRequest } from 'src/actions/loggedInUser';
import objectUrlFileRequest from 'src/requestHandling/objectUrlFileRequest';

function prepareValidationErrorPayload(serverError) {
    const { name, pageLimit, adAccountLimit } = serverError.payload;
    const submissionErrorPayload = { };

    if (name) {
        Object.assign(submissionErrorPayload, { name });
    }
    if (pageLimit) {
        Object.assign(submissionErrorPayload, { pageLimit });
    }
    if (adAccountLimit) {
        Object.assign(submissionErrorPayload, { adAccountLimit });
    }
    return submissionErrorPayload;
}

function* spaceTriggerSpaceSwitch(modifiedSpaceId, url, deleting = false) {
    const activeSpace = yield select(selectLoggedInSpace);

    /* If we update selected space */
    if (activeSpace.id === modifiedSpaceId) {
        yield put(userSwitchSpaceRequest(activeSpace.id, url));
    }

    /* If we are deleting space from our space list */
    if (deleting) {
        const usersSpaces = yield select(selectLoggedInUserSpaces);
        if (usersSpaces[modifiedSpaceId] !== undefined) {
            yield put(userSwitchSpaceRequest(activeSpace.id, url));
        }
    }
}

function* createSpaceRequest(action) {
    const { payload } = action;
    const {
        name, pageLimit, customLogo, adAccountLimit
    } = payload;
    const activeUser = yield select(selectLoggedInUser);
    try {
        const params = { name, pageLimit, adAccountLimit };
        if (customLogo.url !== '') {
            const blob = yield getFileBlob(objectUrlFileRequest, customLogo.url);
            params.customLogo = blob;
        }
        const serverRequest = createFormDataServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/create-space');
        if (response) {
            const { space } = response.jsonData;
            const parsedSpace = parseSpace(space);
            yield put(modalsHideCreateSpace());
            yield put(createSpaceSuccess(parsedSpace, activeUser.id));
            yield put(showNotification(`Space '${space.name}' (${space.id}) was successfully created.`));
        }
        if (serverError) {
            if (serverError.errorType === 'validationError') {
                const submissionErrorPayload = prepareValidationErrorPayload(serverError);
                yield put(createSpaceError(new SubmissionError(submissionErrorPayload)));
            } else {
                yield put(createSpaceError(new SubmissionError({ _error: serverError })));
            }
        }
    } catch (applicationError) {
        reportError(applicationError);
        yield put(createSpaceError(new SubmissionError({ _error: applicationError })));
    }
}

function* updateSpaceRequest(action) {
    const { payload } = action;
    const {
        id, name, pageLimit, customLogo, adAccountLimit
    } = payload;
    try {
        const params = {
            id, name, pageLimit, updateCustomLogo: true, adAccountLimit
        };
        if (customLogo.url !== '') {
            const blob = yield getFileBlob(objectUrlFileRequest, customLogo.url);
            params.customLogo = blob;
            params.updateCustomLogo = blob !== null;
        }
        const serverRequest = createFormDataServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/update-space');
        if (response) {
            yield put(modalsHideEditSpace(id));
            const { space } = response.jsonData;
            const parsedSpace = parseSpace(space);
            yield put(updateSpaceSuccess(parsedSpace));
            yield put(showNotification(`Space '${name}' was successfully updated.`));
            yield call(spaceTriggerSpaceSwitch, id, '/spaces');
        }
        if (serverError) {
            if (serverError.errorType === 'validationError') {
                const submissionErrorPayload = prepareValidationErrorPayload(serverError);
                yield put(updateSpaceError(new SubmissionError(submissionErrorPayload)));
            } else {
                yield put(updateSpaceError(new SubmissionError({ _error: serverError })));
            }
        }
    } catch (applicationError) {
        reportError(applicationError);
        yield put(updateSpaceError(new SubmissionError({ _error: applicationError })));
    }
}

function* deleteSpaceRequest(action) {
    const { spaceId } = action.payload;
    try {
        const params = { spaceId };
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/remove-space');
        if (response) {
            yield put(deleteSpaceSuccess(spaceId));
            yield put(showNotification(`Space with id ${spaceId} was successfully deleted.`));
            yield put(modalsHideDeleteSpace(spaceId));
            yield call(spaceTriggerSpaceSwitch, spaceId, '/spaces', true);
        }

        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(deleteSpaceError(new SubmissionError({
            _error: applicationOrServerError
        })));
    }
}

export default function* spacesSaga() {
    yield takeEvery(CREATE_SPACE_REQUEST, createSpaceRequest);
    yield takeEvery(UPDATE_SPACE_REQUEST, updateSpaceRequest);
    yield takeEvery(DELETE_SPACE_REQUEST, deleteSpaceRequest);
}
