import Body from 'src/components/Body';
import FeedbackComponent from 'src/components/feedback/FeedbackComponent';
import Footer from 'src/components/Footer';
import PaymentMethod from 'src/components/views/settings/billing/PaymentMethod';
import React from 'react';
import ScrollView from 'src/components/ScrollView';
import SettingsHeader from 'src/components/header/SettingsHeader';
import { connect } from 'react-redux';
import { selectBillingAndInvoiceLoadingState } from 'src/selectors/adminSettings';
import EmptyInfoLoadingScreen from 'src/components/EmptyInfoLoadingScreen';
import * as customPropTypes from 'src/customPropTypes';

const Billing = (props) => {
    const { billingAndInvoiceLoadingState } = props;
    const { isAccountDataLoading } = billingAndInvoiceLoadingState;
    return (
        <ScrollView dataViewName="invoices">
            <SettingsHeader headline="Billing" />
            <Body>
                {
                    (!isAccountDataLoading)
                    && (
                        <>
                            <div style={{ marginBottom: '24px' }}>
                                <FeedbackComponent
                                  content={<>Due to the merging of the quintly/Facelift billing systems, we currently cannot show any invoices. Please check the email inbox of the addresses defined as billing contact for the corresponding invoice or get in touch with our support.</>}
                                  type="warning"
                                />
                            </div>
                            <PaymentMethod />
                        </>
                    )
                }
                {
                    (isAccountDataLoading) && <EmptyInfoLoadingScreen />
                }
            </Body>
            <Footer />
        </ScrollView>
    );
};

Billing.propTypes = {
    billingAndInvoiceLoadingState: customPropTypes.billingAndInvoiceLoadingState.isRequired
};

const mapStateToProps = (state) => ({
    billingAndInvoiceLoadingState: selectBillingAndInvoiceLoadingState(state)
});

export default connect(mapStateToProps)(Billing);
