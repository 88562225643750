import * as customPropTypes from 'src/customPropTypes';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import MenuButton from 'src/components/buttons/MenuButton';
import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/metricOpenInPopover.scss';
import BackButton from 'src/components/popovers/layout/BackButton';
import { QUICK_ANALYSIS, DISCOVER_PREVIEW, METRIC_BUILDER } from 'src/utils/filterSelectors';

const MetricOpenInPopover = (props) => {
    const {
        metric, context, editMetricLink, analysisMetricLink, onBackClick, showBackButton
    } = props;

    return (
        <Popover id="metric-open-in">
            <div className={styles.wrapper}>
                {
                    showBackButton
                    && <BackButton onBackClick={onBackClick} />
                }
                <ButtonGroup vertical>
                    {
                        context !== QUICK_ANALYSIS
                        && <MenuButton label="Analysis" to={analysisMetricLink} />
                    }
                    {
                        context !== METRIC_BUILDER
                        && <MenuButton label="Metric builder" to={editMetricLink} />
                    }
                    {
                        context !== DISCOVER_PREVIEW
                        && <MenuButton label="Discover" to={`/discover/metric/${metric.id}`} />
                    }
                </ButtonGroup>
            </div>
        </Popover>
    );
};

MetricOpenInPopover.propTypes = {
    context: customPropTypes.filterContext.isRequired,
    editMetricLink: customPropTypes.linkTo.isRequired,
    metric: customPropTypes.metric.isRequired,
    analysisMetricLink: customPropTypes.linkTo.isRequired,
    showBackButton: PropTypes.bool,
    onBackClick: PropTypes.func
};

MetricOpenInPopover.defaultProps = {
    showBackButton: false,
    onBackClick: () => {}
};

export default MetricOpenInPopover;
