import DataPushTaskLogStatus from 'src/components/views/integrations/DataPushTaskLogStatus';
import React from 'react';
import styles from 'src/stylesheets/views/integrations/combinedDataPushTaskStatus.scss';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import DataPushTaskStatus from 'src/components/views/integrations/DataPushTaskStatus';

const CombinedDataPushTaskAndLogStatus = (props) => {
    const { dataPushTask, dataPushTaskLog } = props;
    const { isActive } = dataPushTask;
    if (isActive) {
        if (dataPushTaskLog) {
            const { status, startTime } = dataPushTaskLog;
            return <DataPushTaskLogStatus status={status} startTime={startTime} />;
        }
        return (
            <div className={styles.wrapper}>
                <div className={styles.noAvailable} />
                <div>n/a</div>
            </div>
        );
    }

    return (
        <DataPushTaskStatus isActive={isActive} isTableContext />
    );
};

CombinedDataPushTaskAndLogStatus.propTypes = {
    dataPushTask: customPropTypes.dataPushTask.isRequired,
    showStartTime: PropTypes.bool,
    dataPushTaskLog: PropTypes.oneOfType([customPropTypes.dataPushTaskLog, PropTypes.bool]).isRequired
};

export default CombinedDataPushTaskAndLogStatus;
