import React from 'react';
import emptyAPI from 'src/resources/illustrations/API_empty.png';
import IntegrationMissingAdminRoleErrorView from 'src/components/errors/IntegrationMissingAdminRoleErrorView';

const APIMissingAdminRoleErrorView = () => (
    <IntegrationMissingAdminRoleErrorView
      headline="API"
      title="Access quintly’s extensive and powerful API"
      description="API may only be seen by admin users. Contact an admin user to give you permission."
      image={emptyAPI}
    />
);

export default APIMissingAdminRoleErrorView;
