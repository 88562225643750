import {
    CRON_EXPRESSION_TRANSLATION_REQUEST,
    CRON_EXPRESSION_NEXT_RUN_DATE_REQUEST,
    STANDARD_SCHEDULE_NEXT_RUN_DATE_REQUEST,
    standardScheduleNextRunDateError,
    standardScheduleNextRunDateSuccess,
    cronExpressionNextRunDateError,
    cronExpressionNextRunDateSuccess,
    cronExpressionTranslationError,
    cronExpressionTranslationSuccess
} from 'src/actions/dashboardReportSchedule';
import {
    put,
    takeEvery
} from 'redux-saga/effects';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import { getIntervalDetailByIntervalTypeOrNull } from 'src/utils/dashboardFolders';

function* cronExpressionTranslationRequest(action) {
    const { expression } = action.payload;
    try {
        const serverRequest = createServerRequest({ expression });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/translate-cron-expression');

        if (response) {
            const { translation } = response.jsonData;
            yield put(cronExpressionTranslationSuccess(expression, translation));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(cronExpressionTranslationError(expression, applicationError));
    }
}

function* cronExpressionNextRunDateRequest(action) {
    const { expression, timezone, identifier } = action.payload;
    try {
        const serverRequest = createServerRequest({ expression, timezone });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/get-cron-expression-next-run-date');

        if (response) {
            const { date } = response.jsonData;
            yield put(cronExpressionNextRunDateSuccess(identifier, date));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(cronExpressionNextRunDateError(identifier, applicationError));
    }
}

function* standardScheduleNextRunDateRequest(action) {
    const {
        intervalType, timezone, intervalDetailWeekly, intervalDetailMonthly, reportTime, identifier
    } = action.payload;
    try {
        const intervalDetail = getIntervalDetailByIntervalTypeOrNull(intervalType, intervalDetailWeekly, intervalDetailMonthly);
        const serverRequest = createServerRequest({
            intervalType, timezone, intervalDetail, reportTime
        });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/get-standard-schedule-next-run-date');

        if (response) {
            const { date } = response.jsonData;
            yield put(standardScheduleNextRunDateSuccess(identifier, date));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(standardScheduleNextRunDateError(identifier, applicationError));
    }
}

export default function* dashboardReportScheduleSagas() {
    yield takeEvery(CRON_EXPRESSION_TRANSLATION_REQUEST, cronExpressionTranslationRequest);
    yield takeEvery(CRON_EXPRESSION_NEXT_RUN_DATE_REQUEST, cronExpressionNextRunDateRequest);
    yield takeEvery(STANDARD_SCHEDULE_NEXT_RUN_DATE_REQUEST, standardScheduleNextRunDateRequest);
}
