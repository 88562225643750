import { userSecurityLoadRequest } from 'src/actions/userSecurity';
import AsyncDataLoader from 'src/components/AsyncDataLoader';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { selectUserSecurityAsyncStates, selectUserSecurityData } from 'src/selectors/userSecurity';
import { selectLoggedInUserId } from 'src/selectors/users';

const UserSecurityLoader = ({
    requestAction, userId, children
}) => (
    <AsyncDataLoader
      requestAction={requestAction}
      identifier={userId}
      dataSelector={selectUserSecurityData}
      asyncStateSelector={selectUserSecurityAsyncStates}
      alwaysReload
      isListTable={false}
    >
        {children}
    </AsyncDataLoader>
);

UserSecurityLoader.propTypes = {
    requestAction: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
    userId: selectLoggedInUserId(state)
});

export default connect(mapStateToProps, { requestAction: userSecurityLoadRequest })(UserSecurityLoader);
