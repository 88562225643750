import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import { makeSelectEditMetricLink } from 'src/selectors/filterSelectors';
import PropTypes from 'prop-types';
import React from 'react';
import MetricOpenInPopover from 'src/components/popovers/MetricOpenInPopover';
import { makeSelectAnalysisMetricLink } from 'src/selectors/analysis';
import withFilterContextConsumer from 'src/components/withFilterContextConsumer';

const MetricOpenInPopoverContainer = (props) => {
    const {
        metric, context, editMetricLink, analysisMetricLink, onBackClick, showBackButton
    } = props;
    return (
        <MetricOpenInPopover
          context={context}
          metric={metric}
          editMetricLink={editMetricLink}
          analysisMetricLink={analysisMetricLink}
          onBackClick={onBackClick}
          showBackButton={showBackButton}
        />
    );
};

MetricOpenInPopoverContainer.propTypes = {
    context: customPropTypes.filterContext.isRequired,
    editMetricLink: customPropTypes.linkTo.isRequired,
    analysisMetricLink: customPropTypes.linkTo.isRequired,
    metric: customPropTypes.metric.isRequired,
    showBackButton: PropTypes.bool,
    onBackClick: PropTypes.func
};

const makeMapStateToProps = () => {
    const selectEditMetricLink = makeSelectEditMetricLink();
    const selectAnalysisMetricLink = makeSelectAnalysisMetricLink();
    return (state, ownProps) => {
        const { metric, filterContext } = ownProps;
        const editMetricLink = selectEditMetricLink(state, filterContext, ownProps.metric.id);
        const analysisMetricLink = selectAnalysisMetricLink(state, filterContext, metric.id);
        return {
            editMetricLink,
            analysisMetricLink
        };
    };
};

export default withFilterContextConsumer(connect(makeMapStateToProps)(MetricOpenInPopoverContainer));
