import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import FolderInfo from 'src/components/views/folders/FolderInfo';
import ListHandlingCheckbox from 'src/components/bulkList/ListHandlingCheckbox';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/folderListItem.scss';

const FolderListItem = (props) => {
    const {
        folder, parentFolder, link, onEditClickAction, onMoveClickAction, loggedInUser, actionsDisabled, dashboardCount, listName, label, folderCount, labelIcon
    } = props;

    const buttons = (
        <ButtonGroup>
            <Button
              small
              label="Move"
              onClick={() => { onMoveClickAction([folder.id], [], parentFolder.id, parentFolder.name); }}
              disabled={actionsDisabled}
              className="list-hover-button-opacity"
            />
            <Button
              small
              label="Edit"
              onClick={() => { onEditClickAction(folder.id, parentFolder.id); }}
              disabled={actionsDisabled}
              className="list-hover-button-opacity"
            />
        </ButtonGroup>
    );

    const onFolderClick = () => {
        const { routePushAction } = props;
        routePushAction(link);
    };

    return (
        <div className={styles.listItem}>
            <div className={styles.checkboxWrapper}>
                <ListHandlingCheckbox
                  name={`folderListItem-${folder.id}`}
                  listName={listName}
                />
            </div>
            <div className={styles.folderInfoWrapper} onClick={onFolderClick}>
                <FolderInfo
                  folder={folder}
                  loggedInUser={loggedInUser}
                  dashboardCount={dashboardCount}
                  label={label}
                  folderCount={folderCount}
                  labelIcon={labelIcon}
                />
            </div>
            <div className={styles.buttonWrapper}>
                {buttons}
            </div>
        </div>
    );
};

FolderListItem.propTypes = {
    folder: customPropTypes.folder,
    link: customPropTypes.linkTo.isRequired,
    onEditClickAction: PropTypes.func.isRequired,
    routePushAction: PropTypes.func.isRequired,
    onMoveClickAction: PropTypes.func.isRequired,
    loggedInUser: customPropTypes.user.isRequired,
    parentFolder: customPropTypes.folder,
    actionsDisabled: PropTypes.bool.isRequired,
    dashboardCount: PropTypes.number.isRequired,
    listName: PropTypes.string.isRequired,
    folderCount: PropTypes.number,
    label: PropTypes.string,
    labelIcon: PropTypes.string
};

export default FolderListItem;
