import {
    USER_INVOICES_ERROR,
    USER_INVOICES_REQUEST,
    USER_INVOICES_SUCCESS
} from 'src/actions/invoices';
import { combineReducers } from 'redux';
import { createAsyncStatesReducerForActionsWithErrorAndSuccessStates } from 'src/reducers/utils';

const allIds = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case USER_INVOICES_SUCCESS: {
            const { invoices } = payload;
            return Object.keys(invoices);
        }
        default:
            return state;
    }
};

const byId = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case USER_INVOICES_SUCCESS: {
            const { invoices } = payload;
            return Object.assign({}, state, invoices);
        }
        default:
            return state;
    }
};

export const asyncStates = combineReducers({
    invoices: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        () => 'state',
        [USER_INVOICES_REQUEST],
        [USER_INVOICES_SUCCESS],
        [USER_INVOICES_ERROR]
    )
});

export const invoices = combineReducers({
    allIds,
    byId,
    asyncStates
});
