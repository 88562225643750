import React, { memo } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import BatchToSingleMetricRequestTranslator from 'src/components/context/BatchToSingleMetricRequestTranslator';
import GridMetricTile from 'src/components/metricTiles/GridMetricTile';
import PropTypes from 'prop-types';

const GridTileInnerContainer = memo(({
    dashboardMetricId, breakpoint, isAllowedToChangeLayout, isGridLoading
}) => (
    <BatchToSingleMetricRequestTranslator dashboardMetricId={dashboardMetricId}>
        {
            (serverRequest) => (
                <GridMetricTile
                  dashboardMetricId={dashboardMetricId}
                  breakpoint={breakpoint}
                  isAllowedToChangeLayout={isAllowedToChangeLayout}
                  requestHash={serverRequest.request.hash}
                  isGridLoading={isGridLoading}
                />
            )
        }
    </BatchToSingleMetricRequestTranslator>
));

GridTileInnerContainer.propTypes = {
    dashboardMetricId: PropTypes.string.isRequired,
    breakpoint: customPropTypes.gridBreakpoints.isRequired,
    isAllowedToChangeLayout: PropTypes.bool,
    isGridLoading: PropTypes.bool
};

GridTileInnerContainer.defaultProps = {
    isAllowedToChangeLayout: true,
    isGridLoading: false
};

export default GridTileInnerContainer;
