import {
    modalShowMetricSettingsTile, modalsShowDashboardMetricDelete, modalShowCloneDashboardMetric, modalShowMoveDashboardMetric
} from 'src/actions/overlays';
import * as customPropTypes from 'src/customPropTypes';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import MenuButton from 'src/components/buttons/MenuButton';
import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import React from 'react';

const wrapperStyle = {
    minWidth: 180
};

const DashboardMetricOptionsPopover = (props) => {
    const {
        dashboardMetric,
        modalsShowDashboardMetricDeleteAction,
        modalShowMetricSettingsTileAction,
        modalShowCloneDashboardMetricAction,
        modalShowMoveDashboardMetricAction,
        hidePopover,
        dashboardMetricLink,
        onMetricOpenInClick,
        additionalButtons
    } = props;

    const showDashboardMetricDeleteModal = () => {
        modalsShowDashboardMetricDeleteAction(dashboardMetric.id, dashboardMetric.dashboardId, dashboardMetric.metricId);
        hidePopover();
    };

    const showMetricSettingsTileModal = () => {
        modalShowMetricSettingsTileAction(dashboardMetric.id);
        hidePopover();
    };

    const showCloneDashboardMetricModal = () => {
        modalShowCloneDashboardMetricAction(dashboardMetric.id);
        hidePopover();
    };

    const showMoveDashboardMetricModal = () => {
        modalShowMoveDashboardMetricAction(dashboardMetric.id);
        hidePopover();
    };

    return (
        <Popover id="dashboardMetricOptionsPopover">
            <div style={wrapperStyle}>
                <ButtonGroup vertical>
                    <MenuButton label="Maximize" to={dashboardMetricLink} />
                    <MenuButton label="Open in" multi onClick={onMetricOpenInClick} />
                    <MenuButton label="Edit settings" onClick={showMetricSettingsTileModal} />
                    <MenuButton label="Duplicate" onClick={showCloneDashboardMetricModal} />
                    <MenuButton label="Move" onClick={showMoveDashboardMetricModal} />
                    <MenuButton label="Delete" onClick={showDashboardMetricDeleteModal} />
                    {
                        additionalButtons || null
                    }
                </ButtonGroup>
            </div>
        </Popover>
    );
};

DashboardMetricOptionsPopover.propTypes = {
    dashboardMetric: customPropTypes.dashboardMetric.isRequired,
    modalsShowDashboardMetricDeleteAction: PropTypes.func.isRequired,
    modalShowMetricSettingsTileAction: PropTypes.func.isRequired,
    hidePopover: PropTypes.func,
    dashboardMetricLink: customPropTypes.linkTo.isRequired,
    onMetricOpenInClick: PropTypes.func.isRequired,
    additionalButtons: PropTypes.arrayOf(PropTypes.node),
    modalShowCloneDashboardMetricAction: PropTypes.func.isRequired,
    modalShowMoveDashboardMetricAction: PropTypes.func.isRequired
};

export default connect(null, {
    modalsShowDashboardMetricDeleteAction: modalsShowDashboardMetricDelete,
    modalShowMetricSettingsTileAction: modalShowMetricSettingsTile,
    modalShowCloneDashboardMetricAction: modalShowCloneDashboardMetric,
    modalShowMoveDashboardMetricAction: modalShowMoveDashboardMetric
})(DashboardMetricOptionsPopover);
