import { getPageUserNotificationIdsFromStore, getPageUserNotificationsRequestStateFromStore } from 'src/selectors/userNotification';
import React, { Component } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import * as userNotificationActions from 'src/actions/userNotification';
import Body from 'src/components/Body';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import Feedback from 'src/components/feedback/Feedback';
import Footer from 'src/components/Footer';
import EmptyInfoLoadingScreen from 'src/components/EmptyInfoLoadingScreen';
import NoUserNotificationView from 'src/components/views/userNotifications/NoUserNotificationPage';
import PropTypes from 'prop-types';
import ScrollView from 'src/components/ScrollView';
import styles from 'src/stylesheets/views/userNotifications/UserNotifications.scss';
import UserNotificationContainer from 'src/components/views/userNotifications/UserNotificationContainer';
import UserNotificationsHeader from 'src/components/header/UserNotificationsHeader';

class UserNotifications extends Component {
    componentDidMount() {
        const { getUserNotificationsRequestAction, updateUserNotificationsLastSeenTimeAction, userNotificationItemIds } = this.props;
        getUserNotificationsRequestAction(userNotificationItemIds.length);
        updateUserNotificationsLastSeenTimeAction();
    }

    render() {
        const { userNotificationItemIds, userNotificationRequestState, getUserNotificationsRequestAction } = this.props;
        const {
            loading, error, next, lastNotificationCreateTime
        } = userNotificationRequestState;
        const userNotificationLength = userNotificationItemIds.length;
        return (
            <ScrollView dataViewName="user-notifications">
                <UserNotificationsHeader userNotificationLength={userNotificationLength} />
                <Body>
                    {
                        (error && !loading)
                        && (
                        <Feedback
                          type="error"
                          content={error}
                        />
                        )
                    }
                    {
                        loading && userNotificationLength === 0
                        && <EmptyInfoLoadingScreen />
                    }
                    {
                        userNotificationItemIds.map((userNotificationItemId) => (
                            <div
                              key={userNotificationItemId}
                              className={styles.notificationItem}
                            >
                                <UserNotificationContainer
                                  id={userNotificationItemId}
                                />
                            </div>
                        ))
                    }
                    {
                    next
                    && (
                    <div className={styles.emptyAndLoadMore}>
                        <Button
                          label="Load more notifications"
                          loading={loading}
                          onClick={() => { getUserNotificationsRequestAction(userNotificationLength, lastNotificationCreateTime); }}
                        />
                    </div>
                    )
                    }
                    {
                        !loading && !error && userNotificationLength === 0
                        && <NoUserNotificationView />
                    }
                </Body>
                <Footer />
            </ScrollView>
        );
    }
}

UserNotifications.propTypes = {
    userNotificationItemIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    getUserNotificationsRequestAction: PropTypes.func.isRequired,
    userNotificationRequestState: customPropTypes.userPageNotificationRequestState.isRequired,
    updateUserNotificationsLastSeenTimeAction: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    userNotificationItemIds: getPageUserNotificationIdsFromStore(state),
    userNotificationRequestState: getPageUserNotificationsRequestStateFromStore(state)
});

export default connect(mapStateToProps, {
    getUserNotificationsRequestAction: userNotificationActions.getUserNotificationsRequest,
    updateUserNotificationsLastSeenTimeAction: userNotificationActions.updateUserNotificationsLastSeenTime
})(UserNotifications);
