import Column from 'src/components/header/layout/Column';
import Header from 'src/components/header/layout/Header';
import PageHeadline from 'src/components/header/layout/PageHeadline';
import React from 'react';
import TopRow from 'src/components/header/layout/TopRow';
import Button from 'src/components/buttons/Button';
import styles from 'src/stylesheets/headers/apiHeader.scss';
import { getApiAccess } from 'src/selectors/adminSettings';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';

const APIHeader = ({ apiAccess }) => (
    <Header>
        <TopRow>
            <Column flexGrow>
                <PageHeadline>API</PageHeadline>
            </Column>
            {
                apiAccess.enabled && (
                    <Column>
                        <a className={styles.link} href="https://api.quintly.com/" target="_blank" rel="noopener noreferrer">
                            <Button label="How to use our API" icon="info" />
                        </a>
                    </Column>
                )
            }
        </TopRow>
    </Header>
);

APIHeader.propTypes = {
    apiAccess: customPropTypes.apiAccess.isRequired
};

const mapStateToProps = (state) => ({
    apiAccess: getApiAccess(state)
});

export default connect(mapStateToProps)(APIHeader);
