import { put, takeEvery } from 'redux-saga/effects';
import {
    METRIC_USAGE_COUNT_UPDATE_REQUEST,
    METRIC_GET_REQUEST,
    metricUsageCountUpdateError,
    metricUsageCountUpdateSuccess,
    metricGetError,
    metricGetSuccess
} from 'src/actions/metric';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import { parseMetric } from 'src/parsers';

function* metricUsageCountUpdateRequest(action) {
    const { metricIds } = action.payload;
    const params = {
        metricIds: JSON.stringify(metricIds)
    };

    try {
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/update-metric-usage-count');
        if (response) {
            const { metricUsageUpdates } = response.jsonData;
            yield put(metricUsageCountUpdateSuccess(metricUsageUpdates));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        yield put(metricUsageCountUpdateError(applicationOrServerError));
    }
}

function* metricGetRequest(action) {
    const { metricId } = action.payload;

    try {
        const serverRequest = createServerRequest({ metricId });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/get-metric');
        if (response) {
            const { metric } = response.jsonData;
            yield put(metricGetSuccess(metricId, parseMetric(metric)));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        yield put(metricGetError(metricId, applicationOrServerError));
    }
}

export default function* metricSagas() {
    yield takeEvery(METRIC_USAGE_COUNT_UPDATE_REQUEST, metricUsageCountUpdateRequest);
    yield takeEvery(METRIC_GET_REQUEST, metricGetRequest);
}
