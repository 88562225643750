import React from 'react';
import PropTypes from 'prop-types';
import FloatingList from 'src/components/floatingList/FloatingList';
import PostFloatingListItem from 'src/components/views/postTags/postTagging/PostFloatingListItem';
import EmptyDiv from 'src/components/loadingIndicators/EmptyDiv';
import _range from 'lodash/range';

const FloatingListTilesSkeleton = (props) => {
    const { numberOfItems, listItemClassName, emptyDivClassName } = props;
    return (
        <FloatingList>
            {
                _range(numberOfItems).map((id) => (
                    <PostFloatingListItem key={id} className={listItemClassName}>
                        <EmptyDiv height={220} classNames={emptyDivClassName} />
                    </PostFloatingListItem>
                ))
            }
        </FloatingList>
    );
};

FloatingListTilesSkeleton.propTypes = {
    numberOfItems: PropTypes.number.isRequired,
    emptyDivClassName: PropTypes.string.isRequired,
    listItemClassName: PropTypes.string
};

FloatingListTilesSkeleton.defaultProps = {
    listItemClassName: ''
};

export default FloatingListTilesSkeleton;
