import { destroy, getFormValues } from 'redux-form';
import { formName } from 'src/components/forms/ProfileFilterBarForm';
import { initialValues } from 'src/components/forms/AccountFilterBarForm';
import {
    makeSelectProfileIdsWithListFiltersApplied,
    selectProfileIds
} from 'src/selectors/profiles';
import React, { useEffect, useRef } from 'react';
import Body from 'src/components/Body';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import ContentWithSidebar from 'src/components/views/ContentWithSidebar';
import Footer from 'src/components/Footer';
import ListPageWrapper from 'src/components/ListPageWrapper';
import { makeSelectGroupById } from 'src/selectors/groups';
import { makeSelectIsOneItemSelected } from 'src/selectors/lists';
import noProfileMatch from 'src/resources/illustrations/profiles-filters.png';
import AccountList from 'src/components/AccountList';
import ProfileListHeader from 'src/components/views/profiles/ProfileListHeader';
import ProfileListItemContainer from 'src/components/views/profiles/ProfileListItemContainer';
import profilesEmpty from 'src/resources/illustrations/profiles-empty.png';
import ProfilesHeader from 'src/components/header/ProfilesHeader';
import ProfilesSidebar from 'src/components/views/profiles/ProfilesSidebar';
import ProfileUseCasesHealthStatus from 'src/components/views/profiles/ProfileUseCasesHealthStatus';
import PropTypes from 'prop-types';
import ScrollView from 'src/components/ScrollView';
import withListStateHandling from 'src/components/bulkList/withListStateHandling';
import withStickyListHeaderHandling from 'src/components/withStickyListHeaderHandling';
import { accountEntitiesGetRequest } from 'src/actions/accounts';
import AddProfilesButton from 'src/components/buttons/AddProfilesButton';

const brokenProfileStyle = {
    paddingTop: '24px'
};

const listName = 'profiles';
const ProfileListHeaderWithStickyHeaderHandling = withStickyListHeaderHandling(ProfileListHeader);
const ListPageWrapperWithListStateHandling = withListStateHandling(ListPageWrapper, listName);

const Profiles = (props) => {
    const { destroyFilterFormAction, accountEntitiesGetRequestAction, filteredProfileIds } = props;

    const scrollRef = useRef();

    useEffect(() => {
        accountEntitiesGetRequestAction();
        return () => {
            destroyFilterFormAction(formName);
        };
    }, []);

    useEffect(() => {
        if (scrollRef.current && scrollRef.current.scrollTop > 0) {
            scrollRef.current.scrollTop = 0;
        }
    }, [filteredProfileIds]);

    const {
        totalProfilesCount,
        freeTextFilter,
        allProfileIds,
        isOneItemSelected
    } = props;

    const addProfileButton = (
        <ButtonGroup>
            <AddProfilesButton />
        </ButtonGroup>
    );
    const sidebar = <ProfilesSidebar listName={listName} />;
    return (
        <ContentWithSidebar sidebar={sidebar} showSidebar={isOneItemSelected}>
            <ScrollView bodyRefForScrollPosition={(node) => { scrollRef.current = node; }} dataViewName="profiles">
                <ProfilesHeader />
                <Body key="profiles">
                    <ListPageWrapperWithListStateHandling listItemIds={allProfileIds}>
                        <div style={brokenProfileStyle}>
                            <ProfileUseCasesHealthStatus />
                        </div>
                        <ProfileListHeaderWithStickyHeaderHandling
                          listItemIdsToHandle={filteredProfileIds}
                          allProfileIds={allProfileIds}
                          offsetTop={68}
                          listName={listName}
                        />
                        {
                                filteredProfileIds.length > 0 && (
                                    <AccountList
                                      accountIds={filteredProfileIds}
                                      renderAccountItem={(profileId) => (
                                          <ProfileListItemContainer profileId={profileId} highlight={freeTextFilter} listName={listName} />
                                      )}
                                    />
                                )
                            }
                    </ListPageWrapperWithListStateHandling>
                    {
                            totalProfilesCount > 0 && filteredProfileIds.length < 1
                            && (
                                <EmptyInfoScreen
                                  image={noProfileMatch}
                                  title="No profiles match the applied filters"
                                />
                            )
                        }
                    {
                            totalProfilesCount < 1
                            && (
                                <EmptyInfoScreen
                                  image={profilesEmpty}
                                  title="You have no profiles yet"
                                  message={'Here is where you will be able to manage all of your added\n'
                                    + ' social media profiles.'}
                                  buttons={addProfileButton}
                                />
                            )
                        }
                </Body>
                <Footer />
            </ScrollView>
        </ContentWithSidebar>
    );
};

Profiles.propTypes = {
    totalProfilesCount: PropTypes.number.isRequired,
    filteredProfileIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    destroyFilterFormAction: PropTypes.func.isRequired,
    freeTextFilter: PropTypes.string.isRequired,
    allProfileIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    isOneItemSelected: PropTypes.bool.isRequired,
    accountEntitiesGetRequestAction: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const formValueSelector = getFormValues(formName);
    const selectProfileIdsWithListFiltersApplied = makeSelectProfileIdsWithListFiltersApplied();
    const selectGroupById = makeSelectGroupById();
    const selectIsOneItemSelected = makeSelectIsOneItemSelected();
    return (state) => {
        const allProfileIds = selectProfileIds(state);
        const totalProfilesCount = allProfileIds.length;
        const {
            freeTextFilter, networkFilter, groupFilter, sortBy
        } = formValueSelector(state) || initialValues;
        const group = selectGroupById(state, groupFilter);

        return {
            totalProfilesCount,
            freeTextFilter,
            allProfileIds,
            filteredProfileIds: selectProfileIdsWithListFiltersApplied(state, freeTextFilter, networkFilter, group, sortBy),
            isOneItemSelected: selectIsOneItemSelected(state, listName)
        };
    };
};

export default connect(makeMapStateToProps, {
    destroyFilterFormAction: destroy,
    accountEntitiesGetRequestAction: accountEntitiesGetRequest
})(Profiles);
