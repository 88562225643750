import EmptyAutocompleteListSkeleton from 'src/components/loadingIndicators/EmptyAutocompleteListSkeleton';
import React from 'react';
import styles from 'src/stylesheets/autocompleteLoadingList.scss';

const AutocompleteLoadingList = () => (
    <div>
        <div className={styles.skeletonList}>
            <EmptyAutocompleteListSkeleton itemCount={4} />
        </div>
        <div className={styles.loadingText}>Searching...</div>
    </div>
);

export default AutocompleteLoadingList;
