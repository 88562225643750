import _get from 'lodash/get';
import AuthenticationsHeader from 'src/components/header/AuthenticationsHeader';
import AuthTransactionListSidebar from 'src/components/views/authentications/AuthTransactionListSidebar';
import AuthUsers from 'src/components/views/authentications/authUser/AuthUsers';
import Body from 'src/components/Body';
import { connect } from 'react-redux';
import ContentWithSidebar from 'src/components/views/ContentWithSidebar';
import Footer from 'src/components/Footer';
import ListPageWrapper from 'src/components/ListPageWrapper';
import ProfilesWithBrokenUseCases from 'src/components/views/authentications/BrokenProfilesUseCasesList';
import PropTypes from 'prop-types';
import React from 'react';
import ScrollView from 'src/components/ScrollView';

const profilesWithBrokenUseCaseStyle = {
    paddingTop: '24px'
};

const Authentications = (props) => {
    const { openBrokenProfileUseCaseMenu } = props;
    const sidebar = <AuthTransactionListSidebar />;
    return (
        <ContentWithSidebar sidebar={sidebar}>
            <ScrollView dataViewName="authentications">
                <AuthenticationsHeader />
                <Body key="authentications">
                    <ListPageWrapper>
                        <div style={profilesWithBrokenUseCaseStyle}>
                            <ProfilesWithBrokenUseCases isMenuOpen={openBrokenProfileUseCaseMenu} />
                        </div>
                        <AuthUsers />
                    </ListPageWrapper>
                </Body>
                <Footer />
            </ScrollView>
        </ContentWithSidebar>
    );
};

Authentications.propTypes = {
    openBrokenProfileUseCaseMenu: PropTypes.bool
};

Authentications.defaultProps = {
    openBrokenProfileUseCaseMenu: false
};

const mapStateToProps = (state, ownProps) => ({
    openBrokenProfileUseCaseMenu: _get(ownProps.location, 'state.openBrokenProfileUseCaseMenu', false)
});

export default connect(mapStateToProps)(Authentications);
