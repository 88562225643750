import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import IconButton from 'src/components/buttons/IconButton';
import React from 'react';
import { getClientLimits } from 'src/selectors/client';
import ClientLimitPopover from 'src/components/popovers/ClientLimitPopover';
import withPopover from 'src/components/withPopover';
import withTooltip from 'src/components/withToolTip';
import styles from 'src/stylesheets/limit.scss';

const IconButtonWithPopoverAndTooltip = withPopover(withTooltip(IconButton));

const ClientLimits = (props) => {
    const { clientLimits } = props;
    const { freeLimits, limits } = clientLimits;
    const usedProfileLimits = limits.profiles - freeLimits.profiles;
    return (
        <div className={styles.wrapper}>
            <div className={styles.text}>
                Profile limits: {`${usedProfileLimits}/${limits.profiles}`}
            </div>
            <IconButtonWithPopoverAndTooltip
              icon="info"
              className={styles.icon}
              overlay={<ClientLimitPopover />}
              tooltip="View details"
              tooltipPlacement="top"
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    clientLimits: getClientLimits(state),
});

ClientLimits.propTypes = {
    clientLimits: customPropTypes.clientLimits.isRequired
};

export default connect(mapStateToProps)(ClientLimits);
