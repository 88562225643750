import { appFullUrl } from 'src/config';
import moment from 'moment';

export const formName = 'dataSourceFilterForm';

export const getDataSourcePath = (dataSource) => ({ pathname: `/data-sources/${dataSource}` });
export const getDataSourceColumnPath = (dataSource, column) => `${appFullUrl}/data-sources/${dataSource}#${column}`;

export const getLabelAndIconByIsTimeSeries = (isTimeSeries) => ({
    icon: isTimeSeries ? 'in-progress' : 'list',
    label: isTimeSeries ? 'Time series' : 'Item level'
});

export const getLabelAndIconByIsPrivateStatistic = (isPrivateStatistic) => ({
    icon: isPrivateStatistic ? 'key' : 'public',
    label: isPrivateStatistic ? 'Private data' : 'Public data'
});

export const getOptionalFiltersInfo = (optionalFilters) => {
    let hasPostTextFilter = false;
    let hasPostTagFilter = false;
    if (optionalFilters.length > 0) {
        hasPostTextFilter = optionalFilters.indexOf('postText') > -1;
        hasPostTagFilter = optionalFilters.indexOf('postTag') > -1;
    }
    return { hasPostTextFilter, hasPostTagFilter };
};

export const getDeprecationDateString = (deprecationDate) => moment.utc(deprecationDate).format('MMMM D, YYYY');

export const isDeprecateDatePassed = (deprecationDate, againstDate) => moment.utc(deprecationDate).unix() < moment.utc(againstDate).unix();

export const getDeprecationDateSentence = (deprecationDate, againstDate) => {
    const deprecationDateString = getDeprecationDateString(deprecationDate);
    return isDeprecateDatePassed(deprecationDate, againstDate) ? `Deprecated on ${deprecationDateString}` : `Deprecates on ${deprecationDateString}`;
};

export const getDeprecationDateSentenceFromNow = (deprecationDate) => getDeprecationDateSentence(deprecationDate, moment().format('YYYY-MM-DD'));
export const isDeprecateDatePassedFromNow = (deprecationDate) => isDeprecateDatePassed(deprecationDate, moment().format('YYYY-MM-DD'));
