import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { getClientLimits } from 'src/selectors/client';
import { parseCamelCaseToSentenceCase } from 'src/parsers';
import ProgressBarWithLabels from 'src/components/ProgressBarWithLabels';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/spaceLimitProgressBars.scss';
import ContentBlock from 'src/components/views/ContentBlock';

const getLabelFromLimit = (limit) => parseCamelCaseToSentenceCase(limit);

const ClientLimitProgressBars = (props) => {
    const { clientLimits, showLimitsFor, horizontal } = props;
    const { limits, usage } = clientLimits;
    const limitCount = showLimitsFor.length;

    const progressBars = showLimitsFor.map((type, i) => (
        <div className={classnames({ [styles.progressBarWrapper]: i !== (limitCount - 1) })} key={`clientLimitProgressBarWrapper-${type}`}>
            <ProgressBarWithLabels
              key={`clientLimitProgressBar-${type}`}
              limit={limits[type]}
              usage={usage[type]}
              label={getLabelFromLimit(type)}
            />
        </div>
    ));

    return (
        <ContentBlock headline="Limits">
            <div className={classnames(styles.wrapper, { [styles.horizontal]: horizontal })}>
                {progressBars}
            </div>
        </ContentBlock>
    );
};

ClientLimitProgressBars.propTypes = {
    horizontal: PropTypes.bool,
    showLimitsFor: PropTypes.arrayOf(PropTypes.string),
    clientLimits: customPropTypes.clientLimits.isRequired
};

ClientLimitProgressBars.defaultProps = {
    showLimitsFor: ['profiles', 'profileSwaps', 'adAccounts', 'users', 'dataPushTasks'],
    horizontal: false
};

const mapStateToProps = (state) => ({
    clientLimits: getClientLimits(state),
});

export default connect(mapStateToProps)(ClientLimitProgressBars);
