import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { makeSelectIsOneItemSelected } from 'src/selectors/lists';
import _omit from 'lodash/omit';

const withDisabledStateIfOneItemIsSelectedInList = (WrappedComponent) => {
    const WithDisabledStateIfOneItemIsSelectedInList = (props) => {
        const { disabled } = props;
        const cleanedProps = _omit(props, ['disabled']);
        return <WrappedComponent disabled={disabled} {...cleanedProps} />;
    };

    WithDisabledStateIfOneItemIsSelectedInList.propTypes = {
        listName: PropTypes.string.isRequired,
        disabled: PropTypes.bool.isRequired
    };

    const makeMapStateToProps = () => {
        const selectIsOneItemSelected = makeSelectIsOneItemSelected();
        return (state, ownProps) => ({
            disabled: selectIsOneItemSelected(state, ownProps.listName)
        });
    };

    return connect(makeMapStateToProps)(WithDisabledStateIfOneItemIsSelectedInList);
};

export default withDisabledStateIfOneItemIsSelectedInList;
