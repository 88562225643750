import _get from 'lodash/get';
import _has from 'lodash/has';
import _isArray from 'lodash/isArray';
import _lowerCase from 'lodash/lowerCase';
import _omit from 'lodash/omit';
import _pick from 'lodash/pick';
import _upperFirst from 'lodash/upperFirst';
import _capitalize from 'lodash/capitalize';
import moment from 'moment-timezone';
import { convertSettingDateSelection } from 'src/utils/filterSelectors';
import { getReadableOwnershipType } from 'src/utils/ownership';
import { getPostSnippetBasedOnPostTextKeywords } from 'src/utils/string';
import { generateAccountTempId } from 'src/utils/accountSearch';
import { getUserGravatars } from 'src/utils/user';
import { googleBigQueryType } from 'src/utils/dataPushTasks';

export const parseProfilePicture = (platformType, url) => {
    // TODO: this could be done on server-side
    if (url) {
        if (platformType === 'twitter') {
            return url.replace('_normal.jpg', '.jpg');
        }
    }

    return url;
};

export const parseProfileThumbnail = (platformType, url) => url;

// toDo: test this function thoroughly
export function parseExternalLinks(htmlText) {
    const elem = document.createElement('div');
    elem.innerHTML = htmlText;
    const htmlTagCollections = elem.getElementsByTagName('a');
    const tagArray = Array.prototype.slice.call(htmlTagCollections, 0);
    tagArray.forEach((item) => {
        item.setAttribute('target', '_blank');
        item.setAttribute('rel', 'noopener noreferrer');
    });
    return elem.innerHTML;
}

export function parseProfile(profile) {
    return {
        id: `${profile.id}`,
        name: profile.name,
        defaultName: profile.defaultName, // is different based on network types
        link: profile.link,
        platformType: profile.platformType,
        platformId: profile.platformId,
        platformProfileType: profile.platformProfileType,
        platformUsername: profile.platformUsername,
        picture: parseProfilePicture(profile.platformType, profile.picture),
        thumbnail: parseProfileThumbnail(profile.platformType, profile.picture),
        likes: profile.likes,
        verified: profile.verified,
        favorite: profile.favorite === true || profile.favorite === 'Y' || profile.favorite === 1,
        addedTime: profile.addedTime,
        addedUserFirstname: profile.addedUserFirstname,
        addedUserLastname: profile.addedUserLastname,
        createTime: profile.createTime,
        audienceDataSinceTime: profile.audienceDataSinceTime,
        interactionDataSinceTime: profile.interactionDataSinceTime,
        color: profile.color,
        appendix: profile.appendix
    };
}

const getPlatformPermissionsByUseCaseId = (useCasePlatformPermissions, useCaseId) => {
    const platformPermissionIds = [];
    useCasePlatformPermissions.forEach((useCasePlatformPermission) => {
        if (useCasePlatformPermission.useCaseId === useCaseId) {
            platformPermissionIds.push(`${useCasePlatformPermission.platformPermissionId}`);
        }
    });
    return platformPermissionIds;
};

export const parseUseCase = (useCase, useCasePlatformPermissions) => ({
    id: `${useCase.id}`,
    name: useCase.name,
    description: useCase.description,
    platformType: useCase.platformType,
    platformPermissionIds: getPlatformPermissionsByUseCaseId(useCasePlatformPermissions, useCase.id)
});

export const parseUseCases = (useCases, useCasePlatformPermissions) => {
    const parsedUseCases = {};
    useCases.forEach((useCase) => {
        const parsedUseCase = parseUseCase(useCase, useCasePlatformPermissions);
        Object.assign(parsedUseCases, { [parsedUseCase.id]: parsedUseCase });
    });

    return parsedUseCases;
};

export const parseAuthUser = (authUser) => ({
    id: `${authUser.id}`,
    name: authUser.name ? authUser.name : `user_${authUser.id}`,
    environment: authUser.environment,
    platformUserId: authUser.platformUserId,
    lastUpdatedTime: authUser.lastUpdatedTime,
    valid: authUser.valid,
    deprecated: authUser.deprecated
});

export const parseAuthUsers = (authUsers) => {
    const parsedAuthUsers = {};
    authUsers.forEach((authUser) => {
        const parsedAuthUser = parseAuthUser(authUser);
        Object.assign(parsedAuthUsers, { [parsedAuthUser.id]: parsedAuthUser });
    });

    return parsedAuthUsers;
};

export const parseAccountUseCasesAuthUser = (accountUseCaseAuthUser, accountType) => ({
    userId: `${accountUseCaseAuthUser.userId}`,
    [`${accountType}Id`]: accountType === 'profile' ? `${accountUseCaseAuthUser.spaceAccount.account.id}` : `${accountUseCaseAuthUser.spaceAccount.id}`,
    useCaseId: `${accountUseCaseAuthUser.useCaseId}`,
    valid: accountUseCaseAuthUser.valid,
    invalidReason: accountUseCaseAuthUser.invalidReason
});

export const parseAccountUseCasesAuthUsers = (accountUseCasesAuthUsers, accountType) => accountUseCasesAuthUsers.map((accountUseCaseAuthUser) => parseAccountUseCasesAuthUser(accountUseCaseAuthUser, accountType));

export const parseAuthUserAuthAccountUseCase = (authUserAuthAccountUseCase, accountType) => ({
    userId: `${authUserAuthAccountUseCase.userId}`,
    [`${accountType}Id`]: `${authUserAuthAccountUseCase.accountId}`,
    useCaseId: `${authUserAuthAccountUseCase.useCaseId}`
});

export const parseAuthUsersAuthAccountUseCases = (authUserAuthAccountUseCases, accountType) => authUserAuthAccountUseCases.map((authUserAuthAccountUseCase) => parseAuthUserAuthAccountUseCase(authUserAuthAccountUseCase, accountType));

export function parseProfiles(profiles) {
    const parsedProfiles = {};
    profiles.forEach((profile) => {
        const parsedProfile = parseProfile(profile);
        Object.assign(parsedProfiles, { [parsedProfile.id]: parsedProfile });
    });
    return parsedProfiles;
}

export const parseAuthUserAuthProfileUseCase = (authUserAuthProfileUseCase) => ({
    authProfileId: `${authUserAuthProfileUseCase.authAccountId}`,
    authUserId: `${authUserAuthProfileUseCase.authUserId}`,
    useCaseId: `${authUserAuthProfileUseCase.useCaseId}`
});

export const parseAuthUserAuthProfileUseCases = (authUserAuthProfileUseCases) => authUserAuthProfileUseCases.map((authUserAuthProfileUseCase) => parseAuthUserAuthProfileUseCase(authUserAuthProfileUseCase));

export const parseAuthProfile = (authProfile) => ({
    id: `${authProfile.id}`,
    name: authProfile.name,
    defaultName: authProfile.defaultName,
    platformType: authProfile.platformType,
    platformId: authProfile.platformId,
    platformUsername: authProfile.platformUsername,
    link: authProfile.link,
    picture: parseProfilePicture(authProfile.platformType, authProfile.picture),
    thumbnail: parseProfileThumbnail(authProfile.platformType, authProfile.picture),
    likes: authProfile.likes,
    verified: authProfile.verified
});

export const parseAuthProfiles = (authProfiles) => {
    const parsedAuthProfiles = {};
    authProfiles.forEach((authProfile) => {
        const parsedAuthProfile = parseAuthProfile(authProfile);
        Object.assign(parsedAuthProfiles, { [parsedAuthProfile.id]: parsedAuthProfile });
    });
    return parsedAuthProfiles;
};

export const parseAuthUserAuthAdAccountUseCase = (authUserAuthAdAccountUseCase) => ({
    authAdAccountId: `${authUserAuthAdAccountUseCase.authAccountId}`,
    authUserId: `${authUserAuthAdAccountUseCase.authUserId}`,
    useCaseId: `${authUserAuthAdAccountUseCase.useCaseId}`
});

export const parseAuthUserAuthAdAccountUseCases = (authUserAuthAdAccountUseCases) => authUserAuthAdAccountUseCases.map((authUserAuthAdAccountUseCase) => parseAuthUserAuthAdAccountUseCase(authUserAuthAdAccountUseCase));

export const parseAuthAdAccount = (authAdAccount) => ({
    id: `${authAdAccount.id}`,
    name: authAdAccount.name,
    defaultName: authAdAccount.defaultName,
    platformType: authAdAccount.platformType,
    platformId: authAdAccount.platformId,
    link: authAdAccount.link,
    appendix: authAdAccount.appendix
});

export const parseAuthAdAccounts = (authAdAccounts) => {
    const parsedAuthAdAccounts = {};
    authAdAccounts.forEach((authAdAccount) => {
        const parsedAuthAdAccount = parseAuthAdAccount(authAdAccount);
        Object.assign(parsedAuthAdAccounts, { [parsedAuthAdAccount.id]: parsedAuthAdAccount });
    });
    return parsedAuthAdAccounts;
};

export const parseAccountUseCases = (accountsUseCases, accountType) => {
    const parsedAccountUseCases = {};
    accountsUseCases.forEach((accountUseCase) => {
        const { spaceAccount, useCaseId } = accountUseCase;
        const idToUtilize = accountType === 'profile' ? spaceAccount.account.id : spaceAccount.id;
        if (_has(parsedAccountUseCases, idToUtilize)) {
            parsedAccountUseCases[idToUtilize].push(`${useCaseId}`);
        } else {
            Object.assign(parsedAccountUseCases, { [idToUtilize]: [`${useCaseId}`] });
        }
    });

    return parsedAccountUseCases;
};

export const parsePlatformPermission = (platformPermission) => ({
    id: `${platformPermission.id}`,
    name: `${platformPermission.name}`,
    environment: platformPermission.environment,
    scope: platformPermission.scope,
    documentationLink: platformPermission.documentationLink
});

export const parsePlatformPermissions = (platformPermissions) => {
    const parsedPlatformPermissions = {};
    platformPermissions.forEach((platformPermission) => {
        const parsedPlatformPermission = parsePlatformPermission(platformPermission);
        Object.assign(parsedPlatformPermissions, { [parsedPlatformPermission.id]: parsedPlatformPermission });
    });

    return parsedPlatformPermissions;
};

export const parseAuthTransactionRequirement = (requirement, accountType) => ({
    id: `${requirement.id}`,
    authTransactionId: `${requirement.authTransactionId}`,
    useCaseId: `${requirement.useCaseId}`,
    [`${accountType}Id`]: `${requirement[`${accountType}Id`]}`,
    isFulfilled: requirement.isFulfilled,
    isPending: requirement.isPending
});

export function parseAuthTransactionsRequirements(response) {
    const { profileAuthTransactionRequirements, adAccountAuthTransactionRequirements } = response;
    const parsedProfileAuthTransactionRequirements = {};
    const parsedAdAccountAuthTransactionRequirements = {};

    profileAuthTransactionRequirements.forEach((authTransactionRequirement) => {
        const parsedAuthTransactionRequirement = parseAuthTransactionRequirement(authTransactionRequirement, 'profile');
        Object.assign(parsedProfileAuthTransactionRequirements, { [parsedAuthTransactionRequirement.id]: parsedAuthTransactionRequirement });
    });

    adAccountAuthTransactionRequirements.forEach((authTransactionRequirement) => {
        const parsedAuthTransactionRequirement = parseAuthTransactionRequirement(authTransactionRequirement, 'adAccount');
        Object.assign(parsedAdAccountAuthTransactionRequirements, { [parsedAuthTransactionRequirement.id]: parsedAuthTransactionRequirement });
    });
    return {
        profileAuthTransactionRequirements: parsedProfileAuthTransactionRequirements,
        adAccountAuthTransactionRequirements: parsedAdAccountAuthTransactionRequirements
    };
}

export function parseAuthTransactionsAuthTransactionRequirements(authTransactions) {
    const profileAuthTransactionRequirements = {};
    const adAccountAuthTransactionRequirements = {};
    authTransactions.forEach((authTransaction) => {
        const authTransactionsRequirements = parseAuthTransactionsRequirements(authTransaction);
        Object.assign(profileAuthTransactionRequirements, authTransactionsRequirements.profileAuthTransactionRequirements);
        Object.assign(adAccountAuthTransactionRequirements, authTransactionsRequirements.adAccountAuthTransactionRequirements);
    });
    return {
        profileAuthTransactionRequirements,
        adAccountAuthTransactionRequirements
    };
}

export function parseAuthTransaction(response) {
    const { authTransaction, profileAuthTransactionRequirements, adAccountAuthTransactionRequirements } = response;
    const {
        id,
        name,
        createTime,
        closeTime,
        validUntil,
        createdByUserId,
        createdByUserName,
        isInProgress,
        isFulfilled,
        isOpen,
        hash
    } = authTransaction;
    return {
        id: `${id}`,
        name,
        createdByUserId: `${createdByUserId}`,
        createdByUserName,
        createTime,
        closeTime,
        validUntil,
        isInProgress,
        isFulfilled,
        isOpen,
        hash,
        profileAuthTransactionRequirementIds: profileAuthTransactionRequirements.map((authTransactionRequirement) => `${authTransactionRequirement.id}`),
        adAccountAuthTransactionRequirementIds: adAccountAuthTransactionRequirements.map((authTransactionRequirement) => `${authTransactionRequirement.id}`)
    };
}

export function parseAuthTransactions(authTransactions) {
    const parsedAuthTransactions = {};
    authTransactions.forEach((authTransaction) => {
        const parsedAuthTransaction = parseAuthTransaction(authTransaction);
        Object.assign(parsedAuthTransactions, { [parsedAuthTransaction.id]: parsedAuthTransaction });
    });

    return parsedAuthTransactions;
}

export const parseGroup = (group) => {
    const id = `${group.id}`;
    const profileIds = group.profileIds.map((profileId) => `${profileId}`);
    const adAccountIds = group.adAccountIds.map((adAccountId) => `${adAccountId}`);
    return {
        id,
        name: group.name,
        profileIds,
        adAccountIds
    };
};

export const parseGroups = (groups) => {
    const parsedGroups = {};
    groups.forEach((group) => {
        const parsedGroup = parseGroup(group);
        Object.assign(parsedGroups, { [parsedGroup.id]: parsedGroup });
    });
    return parsedGroups;
};

export const parseAdAccountCampaign = (adAccountCampaign) => ({
    id: `${adAccountCampaign.id}`,
    adAccountId: `${adAccountCampaign.spaceAdAccountId}`,
    name: adAccountCampaign.name,
    platformId: adAccountCampaign.platformId,
    status: adAccountCampaign.status,
    endTime: adAccountCampaign.endTime,
    startTime: adAccountCampaign.startTime
});

export const parseAdAccountCampaigns = (adAccountCampaigns) => {
    const parsedAdAccountCampaigns = {};
    adAccountCampaigns.forEach((adAccountCampaign) => {
        const parsedAdAccountCampaign = parseAdAccountCampaign(adAccountCampaign);
        Object.assign(parsedAdAccountCampaigns, { [parsedAdAccountCampaign.id]: parsedAdAccountCampaign });
    });
    return parsedAdAccountCampaigns;
};

export function parseAccountAuthenticationEntities(response) {
    const {
        authUsers,
        useCases,
        platformPermissions,
        useCasePlatformPermissions,
        profilesUseCases,
        profileUseCasesAuthUsers,
        authUsersAuthProfileUseCases,
        adAccountUseCases,
        adAccountUseCasesAuthUsers,
        authUsersAuthAdAccountUseCases,
        adAccountCampaigns
    } = response;
    return {
        authUsers: parseAuthUsers(authUsers),
        useCases: parseUseCases(useCases, useCasePlatformPermissions),
        platformPermissions: parsePlatformPermissions(platformPermissions),
        profilesUseCases: parseAccountUseCases(profilesUseCases, 'profile'),
        profileUseCasesAuthUsers: parseAccountUseCasesAuthUsers(profileUseCasesAuthUsers, 'profile'),
        authUsersAuthProfileUseCases: parseAuthUsersAuthAccountUseCases(authUsersAuthProfileUseCases, 'profile'),
        adAccountUseCases: parseAccountUseCases(adAccountUseCases, 'adAccount'),
        adAccountUseCasesAuthUsers: parseAccountUseCasesAuthUsers(adAccountUseCasesAuthUsers, 'adAccount'),
        authUsersAuthAdAccountUseCases: parseAuthUsersAuthAccountUseCases(authUsersAuthAdAccountUseCases, 'adAccount'),
        adAccountCampaigns: parseAdAccountCampaigns(adAccountCampaigns)
    };
}

export const parseAuthTransactionEntities = (response) => {
    const {
        profileAuthTransactionRequirements,
        adAccountAuthTransactionRequirements
    } = parseAuthTransactionsAuthTransactionRequirements(response.authTransactions);
    return {
        authTransactions: parseAuthTransactions(response.authTransactions),
        profileAuthTransactionRequirements,
        adAccountAuthTransactionRequirements
    };
};

export function parseTags(response) {
    const tagTypes = {};
    response.tagTypes.forEach((tagType) => {
        const id = `${tagType.id}`;
        tagTypes[id] = Object.assign({}, tagType, { id });
    });

    const tags = {};
    response.tags.forEach((tag) => {
        const id = `${tag.id}`;
        const tagTypeId = `${tag.tagTypeId}`;
        tags[id] = Object.assign({}, tag, { id, tagTypeId });
    });

    return {
        tags,
        tagTypes
    };
}

export function parseTagIds(tags) {
    return tags.map((tag) => `${tag}`);
}

export function parseMetric(metric) {
    const id = `${metric.id}`;
    const createdByUserId = metric.createdByUserId ? `${metric.createdByUserId}` : null;
    const tagIds = parseTagIds(metric.tagIds);
    const parsedMetric = _pick(metric, [
        'name',
        'config',
        'summary',
        'description',
        'useCases',
        'usage',
        'createTime',
        'platformType',
        'isBasedOnPrivateStatistics',
        'lastUpdateTime',
        'isCustomMetric',
        'dataSources',
        'createdByUserName',
        'dataSourceHints'
    ]);
    if (metric.parameters) {
        // if the metrics parameters are an array (often seen as empty array) convert it to object
        if (_isArray(metric.parameters)) {
            parsedMetric.parameters = {};
        } else {
            parsedMetric.parameters = metric.parameters;
        }
    }
    if (metric.fixedInterval) {
        parsedMetric.fixedInterval = metric.fixedInterval;
    }
    return Object.assign(parsedMetric, {
        id,
        createdByUserId,
        visualizationId: `${_get(metric, 'visualization.id', 0)}`,
        tagIds
    });
}

const isMetricValid = (metric) => {
    if (_has(metric, 'config')) {
        return metric.config !== null;
    }
    return false;
};

export function parseMetrics(response) {
    const metrics = {};
    response.metrics.forEach((metric) => {
        const parsedMetric = parseMetric(metric);
        if (isMetricValid(parsedMetric)) {
            metrics[parsedMetric.id] = parsedMetric;
        }
    });
    return metrics;
}

export function parseMetricsWithDashboardUsage(response) {
    const usages = [];
    response.metricsWithDashboardUsage.forEach((metric) => {
        const metricId = _get(metric, 'metricId', null);
        const count = parseInt(_get(metric, 'usageCount', '0'), 10);
        if (metricId) {
            usages[metricId] = count;
        }
    });

    return usages;
}

function parseSpaceGroup(group) {
    const id = `${group.id}`;
    return { id, name: group.name, selected: group.selected || false };
}

export function parseUsersAvatar(response) {
    const userGravators = getUserGravatars(response.email);
    return Object.assign({}, response, { ...userGravators });
}

export function parseUserForManagement(user) {
    return parseUsersAvatar({
        id: `${user.id}`,
        admin: user.admin,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        displayName: user.onboarded ? `${user.firstName} ${user.lastName}` : user.email,
        lastActiveTime: user.lastActiveTime,
        timezone: user.timezone,
        onboarded: user.onboarded,
        invitationPending: user.invitationPending,
        invitationCode: user.invitationCode || '',
        spaces: user.spaces.map((space) => ({
            id: `${space.id}`,
            name: space.name,
            readOnly: space.readOnly
        })),
        groupRestrictions: user.groupRestrictions.map((groupRestriction) => ({
            id: `${groupRestriction.id}`,
            name: groupRestriction.name,
            spaceId: `${groupRestriction.spaceId}`
        }))
    });
}

export function parseSpace(space) {
    const parsedSpace = {
        id: `${space.id}`,
        name: space.name,
        customLogo: space.customLogo,
        users: [],
        profiles: [],
        adAccounts: [],
    };
    if (_has(space, 'groups')) {
        parsedSpace.groups = space.groups.map((group) => parseSpaceGroup(group));
    }
    if (_has(space, 'pageLimit')) {
        parsedSpace.pageLimit = space.pageLimit;
    }

    if (_has(space, 'adAccountLimit')) {
        parsedSpace.adAccountLimit = space.adAccountLimit;
    }

    if (_has(space, 'adAccounts')) {
        parsedSpace.adAccounts = space.adAccounts;
    }
    if (_has(space, 'profiles')) {
        parsedSpace.profiles = space.profiles;
    }
    if (_has(space, 'users')) {
        parsedSpace.users = space.users.map((user) => parseUserForManagement(user));
    }
    return parsedSpace;
}

export function parseSpaces(spaces) {
    const spacesObject = {};
    spaces.forEach((space) => {
        spacesObject[space.id] = parseSpace(space);
    });
    return spacesObject;
}

export function parseUsersForManagement(results) {
    const usersObject = {};
    results.forEach((user) => {
        usersObject[user.id] = parseUserForManagement(user);
    });
    return usersObject;
}

export function parseClientLimit(clientLimits) {
    const { limits, usage } = clientLimits;
    const result = {
        limits: {
            users: parseInt(limits.users, 10),
            profiles: parseInt(limits.profiles, 10),
            adAccounts: parseInt(limits.adAccounts, 10),
            profileSwaps: parseInt(limits.swaps, 10),
            twitterAnalyticsProfiles: parseInt(limits.twitterAnalyticsProfiles, 10),
            dataPushTasks: parseInt(limits.dataPushTasks, 10),
            dataPushTaskNetworks: limits.dataPushTaskNetworks
        },
        usage: {
            users: parseInt(usage.users, 10),
            profiles: parseInt(usage.profiles, 10),
            adAccounts: parseInt(usage.adAccounts, 10),
            profileSwaps: parseInt(usage.swaps, 10),
            twitterAnalyticsProfiles: parseInt(usage.twitterAnalyticsProfiles, 10),
            dataPushTasks: parseInt(usage.dataPushTasks, 10)
        }
    };
    if (_has(clientLimits, 'freeLimits')) {
        const { freeLimits } = clientLimits;
        return Object.assign({}, result, {
            freeLimits: {
                profiles: parseInt(freeLimits.pages, 10),
                adAccounts: parseInt(freeLimits.adAccounts, 10),
            }
        });
    }
    return result;
}

export function parseSpaceLimit(spaceLimits) {
    const { limits, usage } = spaceLimits;
    return {
        limits: {
            profiles: parseInt(limits.profiles, 10),
            profileSwaps: parseInt(limits.profileSwaps, 10),
            adAccounts: parseInt(limits.adAccounts, 10)
        },
        usage: {
            profiles: parseInt(usage.profiles, 10),
            adAccounts: parseInt(usage.adAccounts, 10),
            profileSwaps: parseInt(usage.profileSwaps, 10),
            futureSwaps: usage.futureSwaps
        }
    };
}

export function parseSpaceLimits(response) {
    const { spaceLimits } = response;
    return parseSpaceLimit(spaceLimits);
}

export function parseAccountOptions(accountOptions) {
    return {
        timezone: accountOptions.timezone,
        csvSeparator: accountOptions.csvSeparator,
        thousandSeparator: accountOptions.thousandSeparator,
        decimalSeparator: accountOptions.decimalSeparator,
        exportEncoding: accountOptions.exportEncoding,
        dateFormat: accountOptions.dateFormat,
        weekDefinition: accountOptions.weekDefinition
    };
}

export function parseLoggedInUser(response, onBehalf) {
    const {
        userSpace: userSpaceFromResposne,
        client: clientFromResponse,
        session: sessionFromResponse
    } = response;

    const userFromResponse = _get(userSpaceFromResposne, 'user');
    const spaceFromResponse = _get(userSpaceFromResposne, 'space');
    const clientPackageFromResponse = _get(clientFromResponse, 'package');

    if (!(
        userSpaceFromResposne
            && clientFromResponse
            && userFromResponse
            && spaceFromResponse
            && sessionFromResponse
            && clientPackageFromResponse
    )) {
        throw new Error('Missing Resources');
    }
    const parsedSpace = parseSpace(spaceFromResponse);
    const parsedSpaces = parseSpaces(userSpaceFromResposne.availableSpaces);

    const user = parseUserForManagement({
        id: `${userFromResponse.id}`,
        firstName: userFromResponse.firstName,
        lastName: userFromResponse.lastName,
        email: userFromResponse.email,
        admin: userFromResponse.isAdmin,
        spaces: [],
        groupRestrictions: [],
    });
    return {
        user,
        accountOptions: parseAccountOptions(userFromResponse.accountOptions),
        spaces: parsedSpaces,
        userId: user.id,
        userSpaceIds: Object.keys(parsedSpaces),
        loggedInSpaceId: parsedSpace.id,
        isReadOnly: userSpaceFromResposne.isReadOnly,
        isAdmin: userFromResponse.isAdmin,
        email: userFromResponse.email,
        isAgent: onBehalf,
        firstAppStartedTime: _get(userFromResponse, 'firstAppStartedTime', null),
        intercomHash: sessionFromResponse.userIntercomHash
    };
}

// dashboard setting Parsers
function convertSettingAdditionalFilterSelection(settingAdditionalFilterSelection) {
    if (_isArray(settingAdditionalFilterSelection)) {
        return {};
    }
    return settingAdditionalFilterSelection;
}

const parseProfileFilter = (profileFilter) => {
    const parsedProfileFilter = {
        profileIds: [],
        groupIds: [],
        adAccountIds: []
    };
    if (_isArray(profileFilter.groupIds)) {
        const parsedGroupIds = profileFilter.groupIds.map((value) => `${value}`);
        Object.assign(parsedProfileFilter, { groupIds: parsedGroupIds });
    }
    if (_isArray(profileFilter.profileIds)) {
        const parsedProfileIds = profileFilter.profileIds.map((value) => `${value}`);
        Object.assign(parsedProfileFilter, { profileIds: parsedProfileIds });
    }
    if (_isArray(profileFilter.adAccountIds)) {
        const parsedAdAccountIds = profileFilter.adAccountIds.map((value) => `${value}`);
        Object.assign(parsedProfileFilter, { adAccountIds: parsedAdAccountIds });
    }

    return parsedProfileFilter;
};

function parseReportProfileSelection(reportProfileSelection) {
    if (reportProfileSelection) {
        return parseProfileFilter(reportProfileSelection);
    }
    return {
        profileIds: [],
        groupIds: [],
        adAccountIds: []
    };
}

export const parseDashboardReport = (dashboardReport) => ({
    id: `${dashboardReport.id}`,
    dashboardId: `${dashboardReport.dashboardId}`,
    dashboardName: dashboardReport.dashboardName,
    isActive: dashboardReport.isActive,
    createTime: dashboardReport.createTime,
    lastUpdateTime: dashboardReport.lastUpdateTime,
    format: dashboardReport.format,
    recipients: dashboardReport.recipients,
    notes: dashboardReport.notes,
    showMetricDesc: dashboardReport.showMetricDesc,
    emailMessage: dashboardReport.emailMessage,
    attachShareLink: dashboardReport.attachShareLink,
    profileSelection: parseReportProfileSelection(dashboardReport.profileSelection),
    dateSelection: convertSettingDateSelection(dashboardReport.dateSelection),
    additionalFilterSelection: dashboardReport.additionalFilterSelection !== null ? convertSettingAdditionalFilterSelection(dashboardReport.additionalFilterSelection) : null,
    scheduleTimezone: dashboardReport.scheduleTimezone,
    scheduleIntervalType: dashboardReport.scheduleIntervalType,
    scheduleIntervalDetail: dashboardReport.scheduleIntervalDetail,
    scheduleTime: dashboardReport.scheduleTime,
    isCronScheduleEnabled: dashboardReport.isCronScheduleEnabled,
    cronScheduleExpression: dashboardReport.cronScheduleExpression,
    scheduleCronTranslation: dashboardReport.scheduleCronTranslation,
    createdByUserName: dashboardReport.createdByUserName,
});

export function parseDashboardMetricSettings(dashboardMetric) {
    // remove alias from addditionalSettings and treat it as a flat setting:
    const parsedDashboardMetricAdditionalSettings = dashboardMetric.settingAdditional;
    const settingAdditional = _omit(parsedDashboardMetricAdditionalSettings, ['alias', 'visualizationId']);
    const alias = _get(parsedDashboardMetricAdditionalSettings, 'alias', '');
    const visualizationId = _get(parsedDashboardMetricAdditionalSettings, 'visualizationId', null);
    return {
        // additionalSettings
        settingAdditional,
        alias,
        visualizationId,
        settingDateSelection: convertSettingDateSelection(dashboardMetric.settingDateSelection),
        inheritTimezone: dashboardMetric.inheritTimezone,
        settingProfileSelection: dashboardMetric.settingProfileSelection ? parseProfileFilter(dashboardMetric.settingProfileSelection) : null,
        settingAdditionalFilterSelection: dashboardMetric.settingAdditionalFilterSelection !== null ? convertSettingAdditionalFilterSelection(dashboardMetric.settingAdditionalFilterSelection) : null,
        isCustomDateSelected: !dashboardMetric.bindedToDateSelector,
        isCustomProfileSelected: !dashboardMetric.bindedToProfileSelector,
        isCustomAdditionalFilterSelected: !dashboardMetric.bindedToAdditionalFilterSelector,
        notes: dashboardMetric.notes
    };
}

export function parseDashboardMetric(dashboardMetric) {
    return {
        entity: {
            id: `${dashboardMetric.id}`,
            metricId: `${dashboardMetric.metricId}`,
            dashboardId: `${dashboardMetric.dashboardId}`
        },
        layout: {
            // layout settings
            id: `${dashboardMetric.id}`,
            width: parseInt(dashboardMetric.width, 10),
            height: parseInt(dashboardMetric.height, 10),
            // -1 here from server
            row: parseInt(dashboardMetric.row, 10) - 1,
            col: parseInt(dashboardMetric.col, 10) - 1,
        },
        settings: parseDashboardMetricSettings(dashboardMetric)
    };
}

export function parseDashboardMetrics(response) {
    const dashboardMetrics = {};
    response.dashboardMetrics.forEach((dashboardMetric) => {
        const id = `${dashboardMetric.id}`;
        const parsedProps = parseDashboardMetric(dashboardMetric);

        dashboardMetrics[id] = Object.assign({}, parsedProps);
    });
    return dashboardMetrics;
}

export function parseExportDashboardMetrics(response) {
    return parseDashboardMetrics(response);
}

export function parseDashboardMetricsAdditionalSettings($updatedDashboardMetrics) {
    const dashboardMetricSettings = [];
    Object.keys($updatedDashboardMetrics).forEach((dashboardMetric) => {
        dashboardMetricSettings[dashboardMetric] = $updatedDashboardMetrics[dashboardMetric];
    });
    return dashboardMetricSettings;
}

export function parseDashboardsDashboardMetrics(dashboards) {
    const dashboardMetrics = {};
    dashboards.forEach((dashboard) => {
        Object.assign(dashboardMetrics, parseDashboardMetrics(dashboard));
    });
    return dashboardMetrics;
}

export function parseDashboard(response) {
    const { dashboard, dashboardMetrics } = response;
    const dashboardMetricIds = dashboardMetrics.map((dashboardMetric) => dashboardMetric.id);
    const dashboardObj = {
        id: `${dashboard.id}`,
        name: dashboard.name,
        customLogo: dashboard.customLogo,
        userId: `${dashboard.userId}`,
        spaceId: `${dashboard.spaceId}`,
        folderId: dashboard.folderId !== null ? `${dashboard.folderId}` : 'root',
        createTime: dashboard.createTime,
        lastUpdateTime: dashboard.lastUpdateTime,
        dashboardMetricIds,
        defaultTimezone: dashboard.defaultTimezone
    };

    if (dashboard.createdByUserId !== null) {
        dashboardObj.createdByUserId = `${dashboard.createdByUserId}`;
        dashboardObj.createdByUserName = dashboard.createdByUserName;
    }
    return dashboardObj;
}

export function parseDashboards(response) {
    const dashboards = {};
    response.dashboards.forEach((dashboard) => {
        const parsedDashboard = parseDashboard(dashboard);
        Object.assign(dashboards, { [parsedDashboard.id]: parsedDashboard });
    });
    return dashboards;
}

export function parseDashboardMetricIds(dashboardMetricIds) {
    const metricIds = [];
    dashboardMetricIds.forEach((dashboardMetricId) => {
        metricIds.push(`${dashboardMetricId}`);
    });
    return metricIds;
}

export const parseDashboardReports = (dashboard) => {
    const parsedDashboardReports = {};
    dashboard.dashboardReports.forEach((dashboardReport) => {
        const parsedDashboardReport = parseDashboardReport(dashboardReport);
        Object.assign(parsedDashboardReports, { [parsedDashboardReport.id]: parsedDashboardReport });
    });
    return parsedDashboardReports;
};

export const parseDashboardsDashboardReports = (dashboards) => {
    const parsedDashboardReports = {};
    dashboards.forEach((dashboard) => {
        Object.assign(parsedDashboardReports, parseDashboardReports(dashboard));
    });
    return parsedDashboardReports;
};

// metricSearch Parsers
export const parseTag = (tag) => ({
    id: `${tag.id}`,
    name: tag.name,
    tagType: tag.tagType.name,
    tagTypeId: `${tag.tagType.id}`
});

export const parseMetricSearch = (metric) => ({
    config: metric.data.config,
    visualizationId: `${metric.data.visualization.id}`,
    isBasedOnPrivateStatistics: metric.data.isBasedOnPrivateStatistics,
    dataSources: metric.data.dataSources,
    dataSourceHints: metric.data.dataSourceHints,
    isCustomMetric: metric.data.isCustomMetric,
    lastUpdateTime: metric.data.lastUpdateTime,
    createdByUserName: metric.data.createdByUserName,
    createdByUserId: metric.data.createdByUserId ? `${metric.data.createdByUserId}` : null
});

export const parseDashboardTemplate = (dashboardTemplate) => ({
    metricIds: dashboardTemplate.data.dashboardMetrics.map((dashboardMetric) => dashboardMetric.metric.id.toString())
});

export const parseResult = (result) => {
    const sharedProps = {
        id: `${result.id}`,
        type: result.type,
        usage: result.usage || 0,
        createTime: result.data.createTime,
        tagIds: result.data.tagIds.map((id) => `${id}`),
        summary: result.data.summary,
        description: result.data.description,
        useCases: result.data.useCases,
        name: result.data.name,
        platformType: result.data.platformType
    };
    if (result.type === 'Metric') {
        return Object.assign({}, sharedProps, parseMetricSearch(result));
    }
    return Object.assign({}, sharedProps, parseDashboardTemplate(result));
};

export const parseResults = (results) => results
    .filter((result) => result.type === 'Metric' || result.type === 'DashboardTemplate')
    .map((result) => parseResult(result));

export const parseResultsForEntities = (results) => {
    const dashboardsToParse = [];
    let metricsToParse = [];
    results.forEach((result) => {
        if (result.type === 'Metric') {
            metricsToParse.push(result);
        } else if (result.type === 'DashboardTemplate') {
            dashboardsToParse.push(result);
            metricsToParse = [...metricsToParse, ...result.data.dashboardMetrics.map((dashboardMetric) => dashboardMetric.metric)];
        }
    });
    const resultsToParse = [...dashboardsToParse, ...metricsToParse];
    return resultsToParse.map((result) => parseResult(result));
};

export const parseSearchedProfile = (profile) => ({
    id: generateAccountTempId(profile),
    platformType: profile.platformType,
    platformId: `${profile.platformId}`,
    appendix: `${profile.appendix}`,
    platformProfileType: profile.platformProfileType,
    platformUsername: profile.platformUsername,
    name: profile.name,
    defaultName: profile.defaultName,
    picture: parseProfilePicture(profile.platformType, profile.picture),
    thumbnail: parseProfileThumbnail(profile.platformType, profile.picture),
    likes: profile.audienceSize,
    hiddenAudienceSize: profile.hiddenAudienceSize,
    link: profile.url,
    category: profile.category,
    verified: profile.verified,
    hash: profile.hash,
    authUserName: profile.authUserName
});

export const parseSearchedProfiles = (searchedProfiles) => {
    const parsedSearchProfiles = {};
    searchedProfiles.forEach((searchedProfile) => {
        const parsedSearchedProfile = parseSearchedProfile(searchedProfile);
        Object.assign(parsedSearchProfiles, { [parsedSearchedProfile.id]: parsedSearchedProfile });
    });
    return parsedSearchProfiles;
};

export const parseBatchedSearchedProfiles = (searchedProfiles) => {
    const parsedProfiles = {};
    const linksByTempIds = {};
    Object.keys(searchedProfiles).forEach((link) => {
        const parsedSearchedProfile = parseSearchedProfile(searchedProfiles[link]);
        Object.assign(parsedProfiles, { [parsedSearchedProfile.id]: parsedSearchedProfile });
        Object.assign(linksByTempIds, { [parsedSearchedProfile.id]: link });
    });
    return {
        parsedProfiles,
        linksByTempIds
    };
};

export function parseDataTypes(response) {
    const dataTypes = {};
    response.dataTypes.forEach((dataType) => {
        const id = `${dataType.id}`;
        dataTypes[id] = Object.assign({}, dataType, { id });
    });
    return dataTypes;
}

export function parseCamelCaseToSentenceCase(camelCaseText) {
    return _upperFirst(_lowerCase(camelCaseText));
}

export function parseVisualizations(response) {
    const visualizations = {};
    response.visualizations.forEach((visualization) => {
        const id = `${visualization.id}`;
        const title = parseCamelCaseToSentenceCase(visualization.type);
        const supportedDataTypeId = `${visualization.supportedDataTypeId}`;
        const pos = visualization.pos !== undefined ? parseInt(visualization.pos, 10) : 999;
        visualizations[id] = Object.assign({}, visualization, {
            id, title, supportedDataTypeId, pos
        });
    });
    return visualizations;
}

export function parseSharedEntity(sharedEntity, entityType) {
    return {
        id: `${sharedEntity.id}`,
        [`${entityType}Id`]: `${sharedEntity[`${entityType}Id`]}`
    };
}

export function parseUserEntity(userEntity, entityType) {
    return {
        id: `${userEntity.id}`,
        [`${entityType}Id`]: `${userEntity[`${entityType}Id`]}`
    };
}

export function parseFolder(folder) {
    const folderObj = {
        id: `${folder.id}`,
        name: folder.name,
        createTime: folder.createTime,
        lastUpdateTime: folder.lastUpdateTime,
        createdByUserId: folder.createdByUserId !== null ? `${folder.createdByUserId}` : null,
        createdByUserName: folder.createdByUserName,
        parentId: folder.parent !== null ? `${folder.parent}` : 'root'
    };

    if (folder.createdByUserId !== null) {
        folderObj.createdByUserId = `${folder.createdByUserId}`;
        folderObj.createdByUserName = folder.createdByUserName;
    }

    return folderObj;
}

export function parseOwnershipDashboard(response) {
    const dashboard = parseDashboard(response.dashboard);
    const user = response.user ? parseUserEntity(response.user, 'dashboard') : undefined;
    const shared = response.shared ? parseSharedEntity(response.shared, 'dashboard') : undefined;
    return {
        dashboard,
        user,
        shared
    };
}

export function parseOwnershipDashboards(response) {
    const dashboards = {};
    const user = {};
    const shared = {};
    response.user.forEach((userDashboard) => {
        const parsedUserDashboard = parseUserEntity(userDashboard, 'dashboard');
        Object.assign(user, { [parsedUserDashboard.id]: parsedUserDashboard });
    });

    response.shared.forEach((sharedDashboard) => {
        const parsedSharedDashboard = parseSharedEntity(sharedDashboard, 'dashboard');
        Object.assign(shared, { [parsedSharedDashboard.id]: parsedSharedDashboard });
    });

    response.dashboard.forEach((dashboard) => {
        const parsedDashboard = parseDashboard(dashboard);
        Object.assign(dashboards, { [parsedDashboard.id]: parsedDashboard });
    });
    return {
        dashboards,
        user,
        shared
    };
}

export function parseOwnershipFolder(response) {
    const folder = parseFolder(response.folder);
    const user = response.user ? parseUserEntity(response.user, 'folder') : undefined;
    const shared = response.shared ? parseSharedEntity(response.shared, 'folder') : undefined;
    return {
        folder,
        user,
        shared
    };
}

export function parseOwnershipFolders(response) {
    const folders = {};
    const user = {};
    const shared = {};
    response.user.forEach((userEntity) => {
        const parsedUserEntity = {
            id: `${userEntity.id}`,
            folderId: `${userEntity.folderId}`,
        };
        Object.assign(user, { [parsedUserEntity.id]: parsedUserEntity });
    });

    response.shared.forEach((sharedEntity) => {
        const parsedSharedEntity = {
            id: `${sharedEntity.id}`,
            folderId: `${sharedEntity.folderId}`,
        };
        Object.assign(shared, { [parsedSharedEntity.id]: parsedSharedEntity });
    });

    response.folder.forEach((folder) => {
        const parsedFolder = parseFolder(folder);
        const id = `${folder.id}`;
        Object.assign(folders, { [id]: parsedFolder });
    });
    return { folders, user, shared };
}

export function parseOwnershipFoldersWithVirtualFolders(response) {
    const virtualFolder = {
        createTime: '',
        lastUpdateTime: '',
        createdByUserId: '',
        createdByUserName: ''
    };
    const rootShared = Object.assign({}, virtualFolder, {
        id: 'shared', name: `${_capitalize(getReadableOwnershipType('shared'))} Dashboards`,
    });
    const rootUser = Object.assign({}, virtualFolder, { id: 'user', name: `${_capitalize(getReadableOwnershipType('user'))} Dashboards` });
    const { folders, user, shared } = parseOwnershipFolders(response.folders);

    Object.assign(folders, {
        [rootUser.id]: rootUser,
        [rootShared.id]: rootShared,
    });
    return { folders, user, shared };
}

export function parseBillingAddress(data) {
    return {
        address: data.billingAddress,
        city: data.billingCity,
        country: data.billingCountry,
        name: data.billingName,
        person: data.billingPerson,
        vatId: data.billingVatId,
        zip: data.billingZip
    };
}

export function parseCreditCard(data) {
    if (data) {
        return {
            expMonth: data.exp_month.toString(),
            expYear: data.exp_year.toString(),
            last4: data.last4.toString(),
            expired: data.expired
        };
    }

    return {
        expMonth: '',
        expYear: '',
        expired: false,
        last4: ''
    };
}

export function parsePackageStatus(data) {
    return {
        blocked: data.client.package.blocked,
        status: data.client.package.status,
        packageValid: data.client.package.packageValid
    };
}

export const parsePackageInfo = (packageInfo) => ({
    packageId: packageInfo.packageId,
    packageType: packageInfo.packageType,
    packageStartDate: packageInfo.packageStartDate,
    packageValid: packageInfo.packageValid,
    packageStatus: packageInfo.packageStatus,
    canceled: packageInfo.canceled,
});

export const parseApiAccess = (apiAccess) => ({
    enabled: apiAccess.enabled,
    secret: apiAccess.secret
});

export function parseClientSetting(client) {
    const {
        id, name, registerTime, billedByReseller, onboarded
    } = client;
    return {
        clientId: `${id}`,
        clientName: name,
        registerTime,
        hideInvoices: billedByReseller,
        onboarded
    };
}

export function parseClientSettingForSharedDashboard(data) {
    return {
        dynamicDate: _get(data, 'filter.date.dynamicDate', null),
        sharedDashboardValidUntil: data.validUntil,
        sharedDashboardHasExpiry: data.hasExpiry
    };
}

export function parseClientSettingsForExport(client) {
    const clientId = `${client.id}`;
    return {
        clientId,
        clientName: client.name
    };
}

export const getOnboardingTimezone = (response) => {
    // this can be default value or already set value,
    const userTimezone = response.userSpace.user.accountOptions.timezone;
    // we only take the guess if not been onboarded.
    const isOnboarded = response.onboardingClient.onboarded;
    return isOnboarded ? userTimezone : moment.tz.guess();
};

export function parseOnboardingUserInfoFormValues(response) {
    return {
        firstName: response.userSpace.user.firstName || null,
        lastName: response.userSpace.user.lastName || null,
        timezone: getOnboardingTimezone(response),
        acceptedTermsAndConditions: response.onboardingClient.acceptedTermsAndConditions || false
    };
}

export function parseOnboardingCompanyInfoValues(response) {
    return {
        companyName: response.onboardingClient.name || null,
        companyType: response.onboardingClient.companyType || null
    };
}

export function parseOnboardingFormsValues(response) {
    const userInfoFormValues = parseOnboardingUserInfoFormValues(response);
    const companyInfoFormValues = parseOnboardingCompanyInfoValues(response);
    return {
        userInfoFormValues,
        companyInfoFormValues
    };
}

export function parseExternalProfileAuthenticationProfile(profile) {
    return {
        id: `${profile.id}`,
        name: profile.name,
        link: profile.link,
        platformType: profile.platformType,
        platformId: profile.platformId,
        picture: parseProfilePicture(profile.platformType, profile.picture),
        thumbnail: parseProfileThumbnail(profile.platformType, profile.picture),
        likes: profile.likes,
        verified: profile.verified === true || profile.verified === 'Y'
    };
}

export function parseFavoriteMetrics(response) {
    const { favoriteMetrics, metricSearchResults } = response;
    const favoriteMetricIds = [];

    favoriteMetrics.forEach((favoriteMetric) => {
        favoriteMetricIds.push(`${favoriteMetric.metricId}`);
    });
    const resultsForEntities = parseResultsForEntities(metricSearchResults);

    return {
        favoriteMetricIds,
        resultsForEntities
    };
}

export function convertArrayElementsToString(array) {
    return array.map((element) => `${element}`);
}

export function parsedDashboardMetricIdsFromDashboards(dashboards) {
    const allDashboardMetricIds = [];
    const dashboardsWithParsedDashboardMetricIds = [];
    Object.keys(dashboards).forEach((dashboardId) => {
        const dashboardMetricIds = convertArrayElementsToString(dashboards[dashboardId]);
        allDashboardMetricIds.push(...dashboardMetricIds);
        dashboardsWithParsedDashboardMetricIds[dashboardId] = [...dashboardMetricIds];
    });
    return { allDashboardMetricIds, dashboardsWithParsedDashboardMetricIds };
}

const parseDateFilter = (dateFilter) => Object.assign({}, dateFilter);

const parsePostTextFilter = (postTextFilter) => {
    const parsedValues = postTextFilter.values.map((value) => `${value}`);
    return Object.assign({}, postTextFilter, { values: parsedValues });
};

const parsePostTextExcludeFilter = (postTextExcludeFilter) => {
    const parsedValues = postTextExcludeFilter.values.map((value) => `${value}`);
    return Object.assign({}, postTextExcludeFilter, { values: parsedValues });
};

const parsePostTagFilter = (postTagFilter) => {
    const parsedValues = postTagFilter.values.map((value) => `${value}`);
    return Object.assign({}, postTagFilter, { values: parsedValues });
};

const parseAdCampaignFilter = (adCampaignFilter) => {
    const parsedValues = adCampaignFilter.values.map((value) => `${value}`);
    return Object.assign({}, adCampaignFilter, { values: parsedValues });
};

export function parseFilters(filter) {
    const parsedFilter = {};

    if (_has(filter, 'date')) {
        Object.assign(parsedFilter, { date: parseDateFilter(filter.date) });
    }
    if (_has(filter, 'profile')) {
        Object.assign(parsedFilter, { profile: parseProfileFilter(filter.profile) });
    }
    if (_has(filter, 'postTag')) {
        Object.assign(parsedFilter, { postTag: parsePostTagFilter(filter.postTag) });
    }
    if (_has(filter, 'postText')) {
        Object.assign(parsedFilter, { postText: parsePostTextFilter(filter.postText) });
    }
    if (_has(filter, 'postTextExclude')) {
        Object.assign(parsedFilter, { postTextExclude: parsePostTextExcludeFilter(filter.postTextExclude) });
    }
    if (_has(filter, 'adCampaign')) {
        Object.assign(parsedFilter, { adCampaign: parseAdCampaignFilter(filter.adCampaign) });
    }
    return parsedFilter;
}

export function parseParameters(parameters) {
    return Object.assign({}, parameters);
}

const parseAppliedFilters = (appliedFilters) => ({
    fullyApplied: parseFilters(appliedFilters.fullyApplied),
    notApplied: parseFilters(appliedFilters.notApplied),
    partiallyApplied: parseFilters(appliedFilters.partiallyApplied)
});

export const parseRequestInfo = (requestInfo) => ({
    filters: parseAppliedFilters(requestInfo.filters),
    parameters: parseParameters(requestInfo.parameters)
});

export function parseExportContext(response) {
    const { context } = response;
    return context;
}

export function parseExportMetricRequests(response) {
    const parsedMetricRequests = {};
    const { metricRequests } = response;
    Object.keys(metricRequests).forEach((requestIdentifier) => {
        const metricRequest = metricRequests[requestIdentifier];
        const { parameters } = metricRequest;
        const { filter } = metricRequest;
        const parsedFilter = parseFilters(filter);
        const parsedParameters = parseParameters(parameters);
        Object.assign(parsedMetricRequests, {
            [requestIdentifier]: Object.assign({}, metricRequest, {
                parameters: parsedParameters,
                filter: parsedFilter,
                dependencyId: `${metricRequest.dependencyId}`,
                visualizationId: `${metricRequest.visualizationId}`
            })
        });
    });
    return parsedMetricRequests;
}

export const parseExportMetricResponse = (metricResponse) => {
    const parsedMetricResponse = Object.assign({}, metricResponse);
    if (_has(metricResponse, 'requestInfo')) {
        Object.assign(parsedMetricResponse, { requestInfo: parseRequestInfo(metricResponse.requestInfo) });
    }
    return parsedMetricResponse;
};

export function parseExportMetricResponses(response) {
    const parsedResponses = {};
    Object.keys(response.metricResponses).forEach((key) => {
        Object.assign(parsedResponses, { [key]: parseExportMetricResponse(response.metricResponses[key]) });
    });
    return parsedResponses;
}

export function parseUserNotification(notification) {
    return {
        id: `${notification.id}`,
        wasRead: notification.wasRead,
        createTime: notification.createTime,
        content: notification.content
    };
}

export function parseUserNotificationSettings(userNotificationSettings) {
    return {
        sendUserNotificationsViaEmail: userNotificationSettings.sendUserNotificationsViaEmail
    };
}

export function parseUserNotifications(notifications) {
    const parsedNotifications = {};
    const sortedIds = [];
    notifications.forEach((notification) => {
        const parsedNotification = parseUserNotification(notification);
        const { id } = parsedNotification;
        sortedIds.push(id);
        Object.assign(parsedNotifications, { [id]: parsedNotification });
    });
    return { parsedNotifications, sortedIds };
}

export const parseDataSourceColumn = (column) => ({
    id: `${column.id}`,
    type: column.type,
    description: column.description,
    isDeprecated: column.isDeprecated,
    deprecationDate: column.deprecationDate !== null ? column.deprecationDate : undefined,
    nestedIntervalSpecificColumns: column.nestedIntervalSpecificColumns
});

export const parseDataSourceColumns = (columns) => columns.map((column) => parseDataSourceColumn(column));

export const parseDataSource = (dataSource) => ({
    name: dataSource.tableName,
    allowedPlatformType: dataSource.allowedPlatformType ? dataSource.allowedPlatformType : 'general',
    description: dataSource.description,
    hints: dataSource.hints,
    isPrivateStatistic: dataSource.isPrivateStatistic,
    isDeprecated: dataSource.isDeprecated,
    deprecationDate: dataSource.deprecationDate !== null ? dataSource.deprecationDate : undefined,
    isTimeSeries: dataSource.isTimeSeries,
    isApiAccessSupported: dataSource.isApiAccessSupported,
    optionalFilters: dataSource.optionalFilters,
    postTextFilterAttributes: dataSource.postTextFilterAttributes !== null ? dataSource.postTextFilterAttributes : undefined
});

export const parseDataSources = (dataSources) => {
    const parsedDataSources = {};
    const parsedDataSourceColumns = {};
    dataSources.forEach((dataSource) => {
        const parsedDataSource = parseDataSource(dataSource);
        const parsedColumns = parseDataSourceColumns(dataSource.columns);
        Object.assign(parsedDataSources, { [parsedDataSource.name]: parsedDataSource });
        Object.assign(parsedDataSourceColumns, { [parsedDataSource.name]: parsedColumns });
    });
    return { parsedDataSources, parsedDataSourceColumns };
};

export const parseDataPushTaskLog = (dataPushTaskLog) => {
    const mappedDataPushTaskLog = {
        id: `${dataPushTaskLog.id}`,
        dataPushTaskId: `${dataPushTaskLog.dataPushTaskId}`,
        scheduleTime: dataPushTaskLog.scheduleTime,
        startTime: dataPushTaskLog.startTime,
        endTime: dataPushTaskLog.endTime,
        overriddenDynamicDate: dataPushTaskLog.overriddenDynamicDate,
        isDryRun: dataPushTaskLog.isDryRun,
        rowInserted: dataPushTaskLog.rowInserted,
        errorMessage: dataPushTaskLog.errorMessage ? dataPushTaskLog.errorMessage : '',
        status: dataPushTaskLog.status,
        rawDataType: dataPushTaskLog.rawDataType
    };

    if (dataPushTaskLog.rawDataSourceUri !== null) {
        mappedDataPushTaskLog.rawDataSourceUri = dataPushTaskLog.rawDataSourceUri;
    }
    if (dataPushTaskLog.rawDataSourceUri !== null) {
        mappedDataPushTaskLog.rawDataDestinationUri = dataPushTaskLog.rawDataDestinationUri;
    }

    return mappedDataPushTaskLog;
};

export const parseDataPushTaskLogs = (dataPushTaskLogs) => {
    const parsedDataPushTaskLogs = {};
    const sortedIds = [];
    dataPushTaskLogs.forEach((dataPushTaskLog) => {
        const parsedDataPushTaskLog = parseDataPushTaskLog(dataPushTaskLog);
        Object.assign(parsedDataPushTaskLogs, { [parsedDataPushTaskLog.id]: parsedDataPushTaskLog });
        sortedIds.push(parsedDataPushTaskLog.id);
    });

    return {
        parsedDataPushTaskLogs,
        sortedIds
    };
};

const parseGoogleBigQueryDataPushTaskDestination = (dataPushTaskId, destination) => ({
    id: `${destination.id}`,
    dataPushTaskId: `${dataPushTaskId}`,
    destinationType: destination.destinationType,
    destinationLocation: destination.destinationLocation,
    googleBigQueryProjectId: destination.googleBigQueryProjectId,
    googleBigQueryDatasetId: destination.googleBigQueryDatasetId,
    googleBigQueryTableId: destination.googleBigQueryTableId,
    googleBigQueryTableSchema: destination.googleBigQueryTableSchema,
    googleBigQueryTimePartitioningType: destination.googleBigQueryTimePartitioningType,
    googleBigQueryTimePartitioningField: destination.googleBigQueryTimePartitioningField,
});

const parseAwsS3DataPushTaskDestination = (dataPushTaskId, destination) => ({
    id: `${destination.id}`,
    dataPushTaskId: `${dataPushTaskId}`,
    destinationType: destination.destinationType,
    destinationLocation: destination.destinationLocation,
    awsS3AccessKeyId: destination.awsS3AccessKeyId,
    awsS3SecretAccessKey: destination.awsS3SecretAccessKey,
    awsS3Region: destination.awsS3Region,
    awsS3Bucket: destination.awsS3Bucket,
    awsS3FilePrefix: destination.awsS3FilePrefix,
    awsS3FileFormat: destination.awsS3FileFormat
});

const parseDataPushTaskDestination = (dataPushTask) => {
    const { id, destination } = dataPushTask;
    const { destinationType } = destination;
    if (destinationType === googleBigQueryType) {
        return parseGoogleBigQueryDataPushTaskDestination(id, destination);
    }
    return parseAwsS3DataPushTaskDestination(id, destination);
};

export const parseDataPushTask = (dataPushTask) => ({
    id: `${dataPushTask.id}`,
    isActive: dataPushTask.isActive,
    dataSource: dataPushTask.dataSource,
    dataSourceColumns: dataPushTask.dataSourceColumns,
    dataPushTimeColumnName: dataPushTask.dataPushTimeColumnName,
    dynamicDate: dataPushTask.dynamicDate,
    interval: dataPushTask.interval,
    timezone: dataPushTask.timezone,
    selectAllAdAccounts: dataPushTask.selectAllAdAccounts,
    spaceAdAccountIds: dataPushTask.spaceAdAccountIds.map((id) => id.toString()),
    selectAllProfiles: dataPushTask.selectAllProfiles,
    profileIds: dataPushTask.profileIds.map((id) => id.toString()),
    qqlQuery: dataPushTask.qqlQuery,
    delivery: dataPushTask.delivery,
    deliveryCronString: dataPushTask.deliveryCronString,
    destinationId: `${dataPushTask.destination.id}`,
    destinationType: dataPushTask.destination.destinationType,
    type: dataPushTask.type
});

export const parseDataPushTaskWithItsDestination = (dataPushTask) => ({
    parsedDataPushTask: parseDataPushTask(dataPushTask),
    parsedDataPushTaskDestination: dataPushTask.destination !== null ? parseDataPushTaskDestination(dataPushTask) : null
});

export const parseDataPushTasksWithDestinations = (dataPushTasks) => {
    const parsedDataPushTasks = {};
    const parsedDataPushTaskDestinations = {};
    dataPushTasks.forEach((dataPushTask) => {
        const { parsedDataPushTask, parsedDataPushTaskDestination } = parseDataPushTaskWithItsDestination(dataPushTask);
        Object.assign(parsedDataPushTasks, { [parsedDataPushTask.id]: parsedDataPushTask });
        if (parsedDataPushTaskDestination !== null) {
            Object.assign(parsedDataPushTaskDestinations, { [parsedDataPushTaskDestination.id]: parsedDataPushTaskDestination });
        }
    });
    return {
        parsedDataPushTasks,
        parsedDataPushTaskDestinations
    };
};

export const parseInvoice = (invoice) => ({
    id: `${invoice.id}`,
    invoiceDate: invoice.invoiceDate,
    invoiceNumber: invoice.invoiceNumber,
    currency: invoice.currency,
    status: invoice.status,
    amount: invoice.amount
});

export const parseInvoices = (invoices) => {
    const parsedInvoices = {};
    invoices.forEach((invoice) => {
        const parsedInvoice = parseInvoice(invoice);
        Object.assign(parsedInvoices, { [parsedInvoice.id]: parsedInvoice });
    });
    return parsedInvoices;
};

export const parseProfileEvent = (profileEvent) => ({
    id: `${profileEvent.id}`,
    profileId: `${profileEvent.profileId}`,
    createdTime: profileEvent.createdTime,
    state: profileEvent.state,
    content: profileEvent.content
});

export const parseProfileEvents = (profileEvents) => {
    const parsedProfileEvents = {};

    profileEvents.forEach((profileEvent) => {
        const parsedProfileEvent = parseProfileEvent(profileEvent);
        Object.assign(parsedProfileEvents, { [parsedProfileEvent.id]: parsedProfileEvent });
    });
    return parsedProfileEvents;
};

export const parseProfileHealthStatus = (profileHealth) => ({
    profileId: `${profileHealth.profileId}`,
    isHealthy: profileHealth.isHealthy,
    unHealthyType: profileHealth.unHealthyType,
    metaData: profileHealth.metaData
});

export const parseProfilesHealthStatus = (profileOverallHealth) => {
    const parsedProfilesHealthStatus = {};
    profileOverallHealth.forEach((profileHealth) => {
        const parsedProfileHealthStatus = parseProfileHealthStatus(profileHealth);
        Object.assign(parsedProfilesHealthStatus, { [parsedProfileHealthStatus.profileId]: parsedProfileHealthStatus });
    });
    return parsedProfilesHealthStatus;
};

export const parsePostTag = (result) => {
    const { postTag, postTagRules } = result;
    const postTagRuleIds = postTagRules.map(({ id }) => `${id}`);
    return {
        id: `${postTag.id}`,
        name: postTag.name,
        creatorUserName: postTag.creatorUserName,
        createTime: postTag.createTime,
        archived: postTag.archived,
        automation: postTag.automation,
        postTagRuleIds
    };
};

export const parsePostTagsWithArchivedAndActiveTagIds = (postTags) => {
    const tagsById = {};
    const archivedTagIds = [];
    const activeTagIds = [];
    postTags.forEach((postType) => {
        const parsedPostTag = parsePostTag(postType);
        Object.assign(tagsById, { [parsedPostTag.id]: parsedPostTag });
        if (parsedPostTag.archived === true) {
            archivedTagIds.push(parsedPostTag.id);
        } else {
            activeTagIds.push(parsedPostTag.id);
        }
    });
    return {
        tagsById,
        archivedTagIds,
        activeTagIds
    };
};

export const parsePostTags = (postTags) => {
    const parsedPostTags = {};
    postTags.forEach((postType) => {
        const parsedPostTag = parsePostTag(postType);
        Object.assign(parsedPostTags, { [parsedPostTag.id]: parsedPostTag });
    });
    return parsedPostTags;
};

export const parsePostTagPostsInfo = (postTagsPostInfo) => ({
    postTagId: `${postTagsPostInfo.postTagId}`,
    numberOfPosts: postTagsPostInfo.numberOfPosts
});

export const parsePostTagsPostsInfo = (postTagsPostsInfo) => {
    const parsedPostTagsPostsInfo = {};
    postTagsPostsInfo.forEach((postTagPostsInfo) => {
        const parsedPostTagPostsInfo = parsePostTagPostsInfo(postTagPostsInfo);
        Object.assign(parsedPostTagsPostsInfo, { [parsedPostTagPostsInfo.postTagId]: parsedPostTagPostsInfo });
    });
    return parsedPostTagsPostsInfo;
};

const parseContentItems = (contentItems) => contentItems.map((item) => ({
    id: item.id,
    content: item.content !== null ? item.content : ''
}));

export const parsePostPostTag = (postPostTag) => ({
    postTagId: `${postPostTag.id}`,
    tagUserName: postPostTag.tagUserName,
    postTagRuleId: postPostTag.postTagRuleId ? `${postPostTag.postTagRuleId}` : null,
    tagTime: postPostTag.tagTime
});

export const parsePostPostTags = (postPostTags) => {
    const byPostTagId = {};
    postPostTags.forEach((postPostTag) => {
        const parsedPostPostTag = parsePostPostTag(postPostTag);
        Object.assign(byPostTagId, { [parsedPostPostTag.postTagId]: parsedPostPostTag });
    });
    return byPostTagId;
};

export const parsePost = (post, postTextKeywords) => {
    const { contentItems, postPostTags } = post;
    const snippet = getPostSnippetBasedOnPostTextKeywords(contentItems, postTextKeywords, 120);
    return {
        id: `${post.id}`,
        profileId: `${post.profileId}`,
        postTypeName: `${post.postTypeName}`,
        time: post.time,
        timezone: post.timezone,
        platformType: post.platformType,
        contentItems: parseContentItems(contentItems),
        snippet,
        image: post.image ? post.image : '',
        link: post.link ? post.link : '',
        kpis: post.kpis ? post.kpis : [],
        postPostTags: parsePostPostTags(postPostTags)
    };
};

export const parsePosts = (posts, postTextKeywords = []) => {
    const parsedPosts = {};
    const postsPostTags = {};
    posts.forEach((post) => {
        const parsedPost = parsePost(post, postTextKeywords);
        Object.assign(parsedPosts, { [parsedPost.id]: parsedPost });
        Object.assign(postsPostTags, { [parsedPost.id]: parsePostPostTags(post.postPostTags) });
    });
    return {
        posts: parsedPosts,
        postsPostTags
    };
};

export const getForceOperationalEntityIds = (forceOperationalEntitiesInfo) => forceOperationalEntitiesInfo.map(({ id }) => id.toString());

export const getForceOperationalEntityInfos = (entities, forceOperationalEntityInfos) => {
    const result = [];
    forceOperationalEntityInfos.forEach(({
        id, dashboardNames, postTagNames, hasBindingsToOtherUserDashboards, hasBindingsToDashboardShareLinks
    }) => {
        const entityId = id.toString();
        const entity = entities.find((e) => e.id === entityId);
        if (entity) {
            result.push({
                id: entityId,
                name: entity.name,
                dashboardNames,
                postTagNames,
                hasBindingsToOtherUserDashboards,
                hasBindingsToDashboardShareLinks,
            });
        }
    });
    return result;
};

export const parsePostTagRule = (postTagRule) => ({
    id: `${postTagRule.id}`,
    dateSelection: postTagRule.dateSelection,
    profileSelection: parseProfileFilter(postTagRule.profileSelection),
    additionalFilterSelection: convertSettingAdditionalFilterSelection(postTagRule.additionalFilterSelection),
    createdByUser: postTagRule.createdByUser,
    createTime: postTagRule.createTime,
    lastUpdatedTime: postTagRule.lastUpdatedTime,
    lastUpdatedUserName: postTagRule.lastUpdatedUserName,
    postTagId: `${postTagRule.postTagId}`
});

export const parsePostTagRules = (postTagRules) => {
    const parsedPostTagRules = {};
    postTagRules.forEach((postTagRule) => {
        const parsedPostTagRule = parsePostTagRule(postTagRule);
        Object.assign(parsedPostTagRules, { [parsedPostTagRule.id]: parsedPostTagRule });
    });
    return parsedPostTagRules;
};

export const parsePostTagPostTagRules = (postTags) => {
    const postTagRules = {};
    postTags.forEach((postTag) => {
        Object.assign(postTagRules, parsePostTagRules(postTag.postTagRules));
    });
    return postTagRules;
};

export const parseInvitation = (invitation) => ({
    id: `${invitation.id}`,
    invitationUrl: invitation.invitationUrl,
    createdAt: invitation.createdAt,
    createdAtTimeZone: invitation.createdAtTimeZone,
    expiresAt: invitation.expiresAt,
    expiresAtTimeZone: invitation.expiresAtTimeZone,
    inviter: invitation.inviter
});

export const parseUserIdentities = ({ userIdentities }) => {
    const parsedUserIdentities = [];

    userIdentities.forEach((userIdentity) => {
        parsedUserIdentities.push(userIdentity);
    });
    return parsedUserIdentities;
};

export const parseMultiFactorAuthenticationEnrollmentState = (enrollmentState) => enrollmentState;

export const parseSecurity = (securities) => securities.map((security) => ({
    name: security.name,
    userIdentities: parseUserIdentities(security.userIdentities),
    multiFactorAuthenticationEnrollmentState: parseMultiFactorAuthenticationEnrollmentState(security.multiFactorAuthenticationEnrollmentState)
}));

export const parseProfileAudienceData = (profileAudienceData) => ({
    profileId: `${profileAudienceData.profileId}`,
    category: profileAudienceData.category,
    since: profileAudienceData.since,
    numberOfAvailableDays: profileAudienceData.numberOfAvailableDays
});

export const parseDashboardShareLink = (dashboardShareLink) => ({
    id: `${dashboardShareLink.id}`,
    createdByUserName: dashboardShareLink.createdByUserName,
    dashboardId: `${dashboardShareLink.dashboardId}`,
    dashboardName: dashboardShareLink.dashboardName,
    createTime: dashboardShareLink.createTime,
    profileSelection: parseReportProfileSelection(dashboardShareLink.profileSelection),
    dateSelection: convertSettingDateSelection(dashboardShareLink.dateSelection),
    additionalFilterSelection: dashboardShareLink.additionalFilterSelection !== null ? convertSettingAdditionalFilterSelection(dashboardShareLink.additionalFilterSelection) : null,
    validUntil: dashboardShareLink.validUntil,
    hasExpiry: dashboardShareLink.hasExpiry,
    isPasswordProtected: dashboardShareLink.isPasswordProtected,
    link: dashboardShareLink.link,
    numberOfVisits: dashboardShareLink.numberOfVisits,
    lastVisitTime: dashboardShareLink.lastVisitTime,
});

export const parseDashboardShareLinks = (dashboardExportLinks) => {
    const parsedDashboardExportLinks = {};
    dashboardExportLinks.forEach((dashboardExportLink) => {
        const parsedDashboardExportLink = parseDashboardShareLink(dashboardExportLink);
        Object.assign(parsedDashboardExportLinks, { [parsedDashboardExportLink.id]: parsedDashboardExportLink });
    });
    return parsedDashboardExportLinks;
};

export const parseAdAccount = (adAccount) => ({
    id: `${adAccount.id}`,
    platformId: adAccount.platformId,
    platformType: adAccount.platformType,
    link: adAccount.link,
    addedTime: adAccount.addedTime,
    addedUserFirstname: adAccount.addedUserFirstname,
    addedUserLastname: adAccount.addedUserLastname,
    appendix: adAccount.appendix,
    color: adAccount.color,
    name: adAccount.name,
    defaultName: adAccount.defaultName,
    favorite: adAccount.favorite
});

export const parseAdAccounts = (adAccounts) => {
    const parsedAdAccounts = {};
    adAccounts.forEach((adAccount) => {
        const parsedAdAccount = parseAdAccount(adAccount);
        Object.assign(parsedAdAccounts, { [parsedAdAccount.id]: parsedAdAccount });
    });
    return parsedAdAccounts;
};

export const parseSearchedAdAccount = (searchedAdAccount) => ({
    id: generateAccountTempId(searchedAdAccount),
    platformType: searchedAdAccount.platformType,
    platformId: `${searchedAdAccount.platformId}`,
    adAccountTimezoneId: `${searchedAdAccount.adAccountTimezoneId}`,
    defaultName: searchedAdAccount.defaultName,
    appendix: searchedAdAccount.appendix,
    link: searchedAdAccount.link,
    currency: searchedAdAccount.currency,
    hash: searchedAdAccount.hash,
    authUserName: searchedAdAccount.authUserName
});

export const parseSearchedAdAccounts = (searchedAdAccounts) => {
    const parsedSearchAdAccounts = {};
    searchedAdAccounts.forEach((searchedAdAccount) => {
        const parsedSearchAdAccount = parseSearchedAdAccount(searchedAdAccount);
        Object.assign(parsedSearchAdAccounts, { [parsedSearchAdAccount.id]: parsedSearchAdAccount });
    });

    return parsedSearchAdAccounts;
};
