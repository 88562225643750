import React, { useState } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import DashboardMetricOptionsPopover from 'src/components/popovers/DashboardMetricOptionsPopover';
import DashboardMetricOpenInPopoverContainer from 'src/components/popovers/DashboardMetricOpenInPopoverContainer';
import PropTypes from 'prop-types';
import DashboardMetricExportActionProvider from 'src/components/exportButtons/DashboardMetricExportActionProvider';
import ExportMenuPopover from 'src/components/popovers/ExportMenuPopover';
import MenuButton from 'src/components/buttons/MenuButton';
import MetricRefreshButtonProvider from 'src/components/buttons/MetricRefreshButtonProvider';
import { DASHBOARD } from 'src/utils/filterSelectors';

const DashboardMetricMenuWithMetricOpenAndExportInPopover = (props) => {
    const {
        dashboardMetricLink, dashboardMetric, hidePopover, serverRequest
    } = props;
    const [metricOpenInState, changeMetricOpenInState] = useState(false);
    const [exportState, changeExportState] = useState(false);
    const showMetricOpenInStateAction = () => {
        changeMetricOpenInState(true);
    };

    const hideMetricOpenInStateAction = () => {
        changeMetricOpenInState(false);
    };

    const showExportStateAction = () => {
        changeExportState(true);
    };

    const hideExportStateAction = () => {
        changeExportState(false);
    };

    if (metricOpenInState) {
        return (
            <DashboardMetricOpenInPopoverContainer
              dashboardMetric={dashboardMetric}
              context={DASHBOARD}
              showBackButton
              onBackClick={hideMetricOpenInStateAction}
              hidePopover={hidePopover}
            />
        );
    } if (exportState) {
        return (
            <DashboardMetricExportActionProvider
              dashboardMetric={dashboardMetric}
              serverRequest={serverRequest}
              renderButton={(exportAction) => (
                  <ExportMenuPopover
                    onExportItemClick={exportAction}
                    onBackClick={hideExportStateAction}
                    hidePopover={hidePopover}
                    showBackButton
                  />
              )}
            />
        );
    }

    const refreshButton = (
        <MetricRefreshButtonProvider
          metricRequestIdentifier={serverRequest.request.identifier}
          serverDataHash={serverRequest.request.hash}
          renderButton={(onClick, isLoading) => (
              <MenuButton
                label="Refresh"
                onClick={() => { if (!isLoading) { onClick(); } hidePopover(); }}
              />
          )}
        />
    );

    const additionalButtons = [
        refreshButton,
        <MenuButton label="Export" multi onClick={showExportStateAction} />
    ];

    return (
        <DashboardMetricOptionsPopover
          dashboardMetricLink={dashboardMetricLink}
          dashboardMetric={dashboardMetric}
          onMetricOpenInClick={showMetricOpenInStateAction}
          hidePopover={hidePopover}
          additionalButtons={additionalButtons}
        />
    );
};

DashboardMetricMenuWithMetricOpenAndExportInPopover.propTypes = {
    dashboardMetric: customPropTypes.dashboardMetric.isRequired,
    hidePopover: PropTypes.func,
    dashboardMetricLink: customPropTypes.linkTo.isRequired,
    serverRequest: customPropTypes.serverRequest.isRequired
};

export default DashboardMetricMenuWithMetricOpenAndExportInPopover;
