import classnames from 'classnames';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/dragHandleButton.scss';

const DragHandleButton = (props) => {
    const {
        onClick,
        onMouseOver,
        onMouseOut,
        onFocus,
        onBlur,
        disabled,
        active,
        className
    } = props;

    return (
        <div
          className={classnames(
              styles.button,
              className,
              { [styles.active]: active },
              { [styles.disabled]: disabled }
          )}
          onClick={onClick}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          type="button"
        >
            <IcomoonIcon icon="move" className={styles.icon} />
        </div>
    );
};

DragHandleButton.propTypes = {
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    className: PropTypes.string
};

DragHandleButton.defaultProps = {
    onClick: null,
    onMouseOver: null,
    onMouseOut: null,
    onFocus: null,
    onBlur: null,
    disabled: false,
    active: false,
    className: ''
};

export default DragHandleButton;
