import * as customPropTypes from 'src/customPropTypes';
import IcomoonIcon from 'src/components/IcomoonIcon';
import moment from 'moment';
import React from 'react';
import RelativeTime from 'src/components/RelativeTime';
import styles from 'src/stylesheets/views/discover/customMetricInfo.scss';

const DiscoverCustomMetricInfo = (props) => {
    const { loggedInUser, customMetric } = props;
    const {
        createTime, lastUpdateTime, createdByUserId, createdByUserName
    } = customMetric;
    return (
        <div className={styles.wrapper}>
            <div className={styles.iconWrapper}>
                <IcomoonIcon icon="metric-editor" className={styles.icon} />
            </div>
            <div className={styles.info}>
                <div className={styles.createdByInfo}>
                    {
                        createdByUserId
                            ? <span>Created by {loggedInUser.id === createdByUserId ? 'you' : createdByUserName} </span>
                            : <span>Creator unknown </span>
                    }
                    &nbsp;
                    {
                        moment(createTime).isValid()
                            ? <span className={styles.dateInfo}><RelativeTime date={createTime} thresholdTimestamp={365 * 86400} /></span>
                            : <span>Creation date unknown</span>
                    }
                </div>
                <div className={styles.modifiedDate}>
                    {
                        moment(lastUpdateTime).isValid()
                            ? <span className={styles.dateInfo}>Modified&nbsp;<RelativeTime date={lastUpdateTime} thresholdTimestamp={365 * 86400} /> </span>
                            : <span>Modification date unknown</span>
                    }
                </div>
            </div>
        </div>
    );
};

DiscoverCustomMetricInfo.propTypes = {
    loggedInUser: customPropTypes.user.isRequired,
    customMetric: customPropTypes.metric.isRequired
};

export default DiscoverCustomMetricInfo;
