import Popover from 'src/components/popovers/layout/Popover';
import React from 'react';

const wrapperStyle = {
    padding: 20,
    fontSize: '13px',
    lineHeight: '1.38'
};

const ApiAccessDescriptionPopover = () => (
    <Popover id="api-access-popover" width={400}>
        <div style={wrapperStyle}>
            <b>API access</b><br /><br />
            <div>
                This data source is accessible through our API.
            </div>
        </div>
    </Popover>
);

export default ApiAccessDescriptionPopover;
