import {
    call, put, select, takeEvery
} from 'redux-saga/effects';
import {
    exportFileError,
    exportFileRequest,
    exportFileSuccess,
    setReportPreviewPending,
    TRIGGER_DASHBOARD_EXPORT,
    TRIGGER_DASHBOARD_METRIC_EXPORT,
    TRIGGER_INVOICE_DOWNLOAD,
    TRIGGER_DATA_PUSH_TASK_LOG_DOWNLOAD,
    TRIGGER_METRIC_EXPORT,
    TRIGGER_REPORT_PREVIEW,
    unsetReportPreviewPending
} from 'src/actions/exports';
import { setNotificationToError, showFileExportSuccessOrErrorNotification } from 'src/actions/notifications';
import { getFormValues } from 'redux-form'; // ES6
import {
    handleExport, handleAuthorizedServerRequest
} from 'src/sagas/utils';
import _uniqueId from 'lodash/uniqueId';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { selectDashboardTypeById } from 'src/selectors/ownership';
import { reportError } from 'src/utils/reportError';
import { getDashboardReportParams } from 'src/utils/dashboardReport';

export function convertBlobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
        };
        reader.onerror = () => {
            reject(Error('unable to process this blob'));
        };
        reader.readAsDataURL(blob);
    });
}

const extractFileName = (disposition) => {
    const filenameRegex = /filename[^;=\n]*\*=UTF-8''((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
        return decodeURIComponent(matches[1].replace(/['"]/g, ''));
    }

    return false;
};

// Every export gets its unique id. If you want to only allow one export per metric, remove the unique id and check loading states
const createFileNameIdentifier = (identifier) => `${identifier}.${_uniqueId()}`;

function* exportFileRequestHandler(fileExportRequest) {
    const {
        identifier,
        serverRequestParams,
        to,
        format,
        fileName
    } = fileExportRequest;
    const fileExportIdentifier = createFileNameIdentifier(identifier);
    yield put(exportFileRequest(fileExportIdentifier, fileExportRequest));
    yield put(showFileExportSuccessOrErrorNotification(fileExportIdentifier, fileName, format));
    try {
        const executableServerRequest = createServerRequest(serverRequestParams, 'blob');
        const { response, serverError } = yield handleAuthorizedServerRequest(executableServerRequest, to);
        if (response) {
            const disposition = response.headers.get('content-disposition');
            if (disposition) {
                const { blobData } = response;
                const { base64Blob, conversionError } = yield handleExport(convertBlobToBase64, blobData);
                if (base64Blob) {
                    const realFileName = extractFileName(disposition);
                    yield put(exportFileSuccess(fileExportIdentifier, {
                        blob: base64Blob,
                        fileName: realFileName
                    }, format, realFileName));
                    return { success: true };
                }
                if (conversionError) {
                    throw new Error('Unable to process file');
                }
            } else {
                throw new Error('No Dispostion Header available');
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError, { debugInfo: fileExportRequest });
        yield put(setNotificationToError(fileExportIdentifier));
        yield put(exportFileError(fileExportIdentifier, applicationOrServerError));
    }
    return { error: true };
}

const fileExportRequestForReportPreview = (dashboardId, dashboardType, formValues) => {
    const params = getDashboardReportParams(formValues, dashboardType);
    return {
        identifier: 'report',
        serverRequestParams: params,
        to: '/client-index/preview-dashboard-pdf',
        format: 'pdf',
        fileName: 'Report'
    };
};

const fileExportRequestFromBatchServerRequest = (batchServerRequest, fileName) => {
    const identifier = batchServerRequest.batchIdentifier;
    const { format, to } = batchServerRequest.endpointInfo;
    const params = [];
    batchServerRequest.requests.forEach((requestToProcess) => {
        params.push({
            id: requestToProcess.identifier,
            parameters: requestToProcess.params,
            filter: requestToProcess.filter,
            dependency: requestToProcess.dependency
        });
    });
    const serverRequestParams = {
        format,
        metricRequests: JSON.stringify(params),
        requestVariables: JSON.stringify(batchServerRequest.requestVariables)
    };
    return {
        identifier,
        serverRequestParams,
        to,
        format,
        fileName
    };
};

const fileExportRequestFromSingleServerRequest = (singleServerRequest, fileName) => {
    const { request, endpointInfo, requestVariables } = singleServerRequest;
    const { identifier } = request;
    const { format, to } = endpointInfo;
    const params = [{
        id: identifier,
        parameters: request.params,
        filter: request.filter,
        dependency: request.dependency
    }];
    const serverRequestParams = {
        format,
        metricRequests: JSON.stringify(params),
        requestVariables: JSON.stringify(requestVariables)
    };
    return {
        identifier,
        serverRequestParams,
        to,
        format,
        fileName
    };
};

function* triggerReportPreview(action) {
    yield put(setReportPreviewPending());
    const { dashboardId, formName } = action.payload;
    const formValueSelector = getFormValues(formName);
    const formValues = yield select(formValueSelector);
    const dashboardType = yield select(selectDashboardTypeById, dashboardId);

    const fileExportRequest = fileExportRequestForReportPreview(dashboardId, dashboardType, formValues);
    yield call(exportFileRequestHandler, fileExportRequest);
    yield put(unsetReportPreviewPending());
}

function* triggerDashboardExport(action) {
    const { batchServerRequest, fileName } = action.payload;
    const fileExportRequest = fileExportRequestFromBatchServerRequest(batchServerRequest, fileName);
    yield call(exportFileRequestHandler, fileExportRequest);
}

function* triggerDashboardMetricExport(action) {
    const {
        serverRequest,
        fileName
    } = action.payload;
    const fileExportRequest = fileExportRequestFromSingleServerRequest(serverRequest, fileName);
    yield call(exportFileRequestHandler, fileExportRequest);
}

function* triggerMetricExport(action) {
    const {
        serverRequest,
        fileName
    } = action.payload;
    const fileExportRequest = fileExportRequestFromSingleServerRequest(serverRequest, fileName);
    yield call(exportFileRequestHandler, fileExportRequest);
}

function* triggerInvoiceDownloadRequest(action) {
    const { id, invoiceNumber } = action.payload;
    yield call(exportFileRequestHandler, {
        identifier: `${invoiceNumber}_${id}_${_uniqueId()}`,
        serverRequestParams: { id, invoiceNumber },
        to: '/client-index/download-invoice',
        format: 'pdf',
        fileName: `quintly_invoice_${invoiceNumber}.pdf`
    });
}

function* triggerDataPushTaskLogDownloadRequest(action) {
    const { dataPushTaskId, id, rawDataType } = action.payload;
    yield call(exportFileRequestHandler, {
        identifier: `${dataPushTaskId}_${id}_${_uniqueId()}`,
        serverRequestParams: { dataPushTaskId, id },
        to: '/client-index/download-data-push-task-log-file',
        format: rawDataType,
        fileName: `data_push_task_log_${dataPushTaskId}_${id}.${rawDataType}`
    });
}

export default function* exportsSagas() {
    yield takeEvery(TRIGGER_REPORT_PREVIEW, triggerReportPreview);
    yield takeEvery(TRIGGER_DASHBOARD_EXPORT, triggerDashboardExport);
    yield takeEvery(TRIGGER_DASHBOARD_METRIC_EXPORT, triggerDashboardMetricExport);
    yield takeEvery(TRIGGER_METRIC_EXPORT, triggerMetricExport);
    yield takeEvery(TRIGGER_INVOICE_DOWNLOAD, triggerInvoiceDownloadRequest);
    yield takeEvery(TRIGGER_DATA_PUSH_TASK_LOG_DOWNLOAD, triggerDataPushTaskLogDownloadRequest);
}
