import React from 'react';
import PropTypes from 'prop-types';
import withBulkCheckboxHandling from 'src/components/bulkList/withBulkCheckboxHandling';
import CheckBox from 'src/components/forms/inputs/Checkbox';
import { makeSelectIsAllItemSelected, makeSelectCheckedValues, makeSelectTotalListItemsLength } from 'src/selectors/lists';
import { connect } from 'react-redux';
import styles from 'src/stylesheets/views/postTags/postTagging/postsMasterCheckbox.scss';
import * as postActions from 'src/actions/posts';
import { uncheckAllListValues } from 'src/actions/lists';
import { getResultTotalRowCount, getShouldTagAllPosts, getCurrentPageNumber } from 'src/selectors/posts';
import Number from 'src/components/Number';

const PostsBulkCheckbox = withBulkCheckboxHandling(CheckBox, true);

const PostsMasterCheckbox = (props) => {
    const {
        listName, isAllItemsSelected, totalListItemsLength, checkedPostIds, shouldTagAllPosts, tagAllPostsAction, totalPostCount, uncheckAllListValuesAction, pageNumber, disabled
    } = props;
    const checkedPostsCount = checkedPostIds.length;

    const start = (pageNumber > 0 && totalListItemsLength > 0) ? (((pageNumber - 1) * 50) + 1) : 0;
    const end = start > 0 ? start + totalListItemsLength - 1 : 0;
    const postCountDetail = (
        <span>
            {
                `${start} - ${end} of `
            }
            <Number value={totalPostCount} />
            {
                `${totalPostCount > 1 ? ' posts' : ' post'}`
            }
        </span>
    );
    let label = <div className={styles.label}>{postCountDetail}</div>;

    if (checkedPostsCount > 0 && checkedPostsCount < totalPostCount && isAllItemsSelected) {
        if (!shouldTagAllPosts) {
            label = (
                <div className={styles.label}>
                    <span className={styles.detail}>{postCountDetail}</span><wbr />
                    <a onClick={() => { tagAllPostsAction(); }}>
                        {`Select all ${totalPostCount} posts in search result`}
                    </a>
                </div>
            );
        } else {
            label = (
                <div className={styles.label}>
                    <span className={styles.detail}>{`All ${totalPostCount} posts in the search result are selected`}</span>
                    <a onClick={() => { uncheckAllListValuesAction(listName); }}>Clear selection</a>
                </div>
            );
        }
    }
    return (
        <div className={styles.wrapper}>
            <div className={styles.checkbox}>
                <PostsBulkCheckbox listName={listName} disabled={disabled} />
            </div>
            {label}
        </div>
    );
};

PostsMasterCheckbox.propTypes = {
    totalListItemsLength: PropTypes.number.isRequired,
    checkedPostIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    listName: PropTypes.string.isRequired,
    tagAllPostsAction: PropTypes.func.isRequired,
    uncheckAllListValuesAction: PropTypes.func.isRequired,
    shouldTagAllPosts: PropTypes.bool.isRequired,
    isAllItemsSelected: PropTypes.bool.isRequired,
    totalPostCount: PropTypes.number.isRequired,
    pageNumber: PropTypes.number.isRequired,
    disabled: PropTypes.bool
};

PostsMasterCheckbox.defaultProps = {
    disabled: false
};

const makeMapStateToProps = () => {
    const selectIsAllItemSelected = makeSelectIsAllItemSelected();
    const selectCheckedValues = makeSelectCheckedValues();
    const selectTotalListItemsLength = makeSelectTotalListItemsLength();
    return (state, ownProps) => ({
        shouldTagAllPosts: getShouldTagAllPosts(state),
        checkedPostIds: selectCheckedValues(state, ownProps.listName),
        isAllItemsSelected: selectIsAllItemSelected(state, ownProps.listName),
        totalPostCount: getResultTotalRowCount(state),
        totalListItemsLength: selectTotalListItemsLength(state, ownProps.listName),
        pageNumber: getCurrentPageNumber(state)
    });
};

export default connect(makeMapStateToProps, {
    tagAllPostsAction: postActions.tagAllPosts,
    uncheckAllListValuesAction: uncheckAllListValues
})(PostsMasterCheckbox);
