import _find from 'lodash/find';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _includes from 'lodash/includes';
import { createSelector } from 'reselect';

export const getTagsByIdFormStore = (state) => state.entities.tags.tagsById;
export const getTagIdsFormStore = (state) => state.entities.tags.tagIds;
export const getTagTypesFormStore = (state) => state.entities.tags.tagTypes;

// tag types
const networkTagTypeId = '1';
const useCaseTagTypeId = '2';
const visualizationTagTypeId = '3';

// tags
export const tagIdPrivateStatistics = '14';
const tagsWithPublicStats = ['1', '2', '3', '5', '6', '17', '22'];
const tagIdsWithPrivateStatistics = {
    1: 'Insights',
    2: 'Analytics',
    3: 'Analytics',
    5: 'Analytics',
    6: 'Insights',
    19: 'Analytics',
    37: 'Ad analytics',
    39: 'Analytics'
};

const tagMap = {
    1: 'facebook',
    2: 'twitter',
    3: 'youtube',
    5: 'linkedIn',
    6: 'instagram',
    17: 'overall',
    19: 'snapchatShow',
    22: 'tiktok',
    37: 'meta',
    39: 'threads',
};

const quickAnalysisSortedNetworks = ['1', '6', '2', '3', '5', '19', '22', '17', '37', '39'];

export const visualizationTagIconMapping = {
    9: 'table',
    23: 'line',
    24: 'bar',
    25: 'column',
    26: 'bignumber',
    27: 'gauge',
    28: 'area',
    29: 'bubble',
    30: 'map',
    31: 'scatter',
    32: 'radar',
    33: 'funnel',
    34: 'pyramid',
    35: 'pie',
    36: 'waterfall',
    38: 'grid'
};

export const visualizationTagIds = Object.keys(visualizationTagIconMapping);

export const selectTags = createSelector(
    [
        getTagIdsFormStore,
        getTagsByIdFormStore,
        getTagTypesFormStore
    ],
    (tagIds, tagsById, tagTypes) => tagIds.map((tagId) => {
        const tag = tagsById[tagId];
        const tagType = tagTypes[tag.tagTypeId];
        return {
            id: tag.id,
            name: tag.name,
            tagType: tagType.name,
            tagTypeId: tagType.id
        };
    })
);

export const selectTagsClusteredByType = createSelector(
    [
        getTagTypesFormStore,
        selectTags,
    ],
    (tagTypes, tags) => {
        const derivedTagTypes = {};
        Object.keys(tagTypes).forEach((key) => { derivedTagTypes[key] = Object.assign({}, tagTypes[key], { tags: [] }); });
        tags.forEach((tag) => {
            derivedTagTypes[tag.tagTypeId].tags.push(tag);
        });
        return Object.keys(derivedTagTypes).map((derivedTagTypeId) => derivedTagTypes[derivedTagTypeId]);
    }
);

export const makeSelectTagsByTypeId = () => createSelector(
    [
        selectTagsClusteredByType,
        (_, tagTypeId) => tagTypeId
    ],
    (tagsTagsClusteredByType, tagTypeId) => {
        const tagsByType = _find(tagsTagsClusteredByType, { id: tagTypeId });
        return tagsByType || [];
    }
);

export const makeSelectTagsByUseCaseType = () => {
    const selectTagsByTypeId = makeSelectTagsByTypeId();
    return createSelector(
        [
            (state) => selectTagsByTypeId(state, useCaseTagTypeId),
        ],
        (useCaseTags) => useCaseTags.tags.filter((tag) => tag.id !== '14')
    );
};

export const makeSelectTagsByVisualizationType = () => {
    const selectTagsByTypeId = makeSelectTagsByTypeId();
    return createSelector(
        [
            (state) => selectTagsByTypeId(state, visualizationTagTypeId),
        ],
        (visualizationTags) => visualizationTags.tags
    );
};

export const makeSelectTagsById = () => createSelector(
    [
        (_, tagIds) => tagIds,
        selectTags
    ],
    (tagIds, tags) => tags.filter((tag) => tagIds.indexOf(tag.id) >= 0)
);

export const makeSelectEntriesForQuickAnalysisSelect = () => {
    const selectTagsByTypeId = makeSelectTagsByTypeId();

    return createSelector(
        [
            (state) => selectTagsByTypeId(state, networkTagTypeId)
        ],
        (networkTags) => {
            const { tags } = networkTags;
            const networkTagsWithMergedPrivateStatistics = [];
            networkTagsWithMergedPrivateStatistics.push({
                id: 'customMetrics',
                name: 'Custom metrics',
                isBasedOnPrivateStatistics: false,
                platformType: 'metric-editor'
            });

            quickAnalysisSortedNetworks.forEach((tagId) => {
                const tag = _find(tags, { id: tagId });
                if (tag) {
                    const { name, id } = tag;
                    const platformType = _get(tagMap, id, 'metric-editor');
                    if (_includes(tagsWithPublicStats, id)) {
                        networkTagsWithMergedPrivateStatistics.push({
                            id,
                            name,
                            isBasedOnPrivateStatistics: false,
                            platformType
                        });
                    }
                    if (_has(tagIdsWithPrivateStatistics, tag.id)) {
                        networkTagsWithMergedPrivateStatistics.push({
                            id: `${id},${tagIdPrivateStatistics}`,
                            name: `${name} ${tagIdsWithPrivateStatistics[tag.id]}`,
                            isBasedOnPrivateStatistics: true,
                            platformType
                        });
                    }
                }
            });
            return networkTagsWithMergedPrivateStatistics;
        }
    );
};

export const selectIsBasedOnPrivateStatisticsByTagIds = createSelector(
    [
        (_, tagIds) => tagIds
    ],
    (tags) => _includes(tags, tagIdPrivateStatistics)
);

export const selectTagIdForNetwork = (network) => {
    const tagId = Object.keys(tagMap).find((key) => tagMap[key] === network);
    if (tagId === false) {
        const error = `cannot resolve tagId for ${network}`;
        throw new Error(error);
    }
    return tagId;
};

export const makeSelectVisualizationTagsByIds = () => {
    const selectTagsByVisualizationType = makeSelectTagsByVisualizationType();
    return createSelector(
        [
            selectTagsByVisualizationType,
            (_, tagIds) => tagIds
        ],
        (visualizationTags, tagIds) => visualizationTags.filter((visualizationTag) => _includes(tagIds, visualizationTag.id))
    );
};
