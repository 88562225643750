import { formName, initialValues } from 'src/components/forms/CustomMetricsFilterBarForm';
import { makeSelectCustomMetricIdsWithListFiltersApplied, selectCustomMetricsCount } from 'src/selectors/metrics';
import * as customPropTypes from 'src/customPropTypes';
import Body from 'src/components/Body';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import CustomMetricListItemContainer from 'src/components/views/custom-metrics/CustomMetricListItemContainer';
import customMetricsEmpty from 'src/resources/illustrations/custom-metrics-empty.png';
import CustomMetricsHeader from 'src/components/header/CustomMetricsHeader';
import CustomMetricsListHeader from 'src/components/views/custom-metrics/CustomMetricsListHeader';
import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import Footer from 'src/components/Footer';
import { getFormValues } from 'redux-form';
import List from 'src/components/List';
import ListPageWrapper from 'src/components/ListPageWrapper';
import metricEmptySearchResult from 'src/resources/illustrations/discover-no-results.png';
import PropTypes from 'prop-types';
import React from 'react';
import ScrollView from 'src/components/ScrollView';
import withReactRouterLinkHandling from 'src/components/withReactRouterLinkHandling';
import withScrollPosition from 'src/components/withScrollPosition';
import withStickyListHeaderHandling from 'src/components/withStickyListHeaderHandling';

const ButtonWithReactRouterLinkHandling = withReactRouterLinkHandling(Button);
const CustomMetricsListHeaderWithStickyHeaderHandling = withStickyListHeaderHandling(CustomMetricsListHeader);
const ScrollViewWithScrollPosition = withScrollPosition(ScrollView, 'custom-metrics');

const CustomMetrics = (props) => {
    const { filteredcustomMetricIds, totalCustomMetricsCount, location } = props;
    return (
        <ScrollViewWithScrollPosition
          scrollPositionIdentifier="custom-metrics"
          dataViewName="custom-metric"
          locationKey={location.key}
        >
            <CustomMetricsHeader />
            <Body>
                <ListPageWrapper>
                    <CustomMetricsListHeaderWithStickyHeaderHandling offsetTop={68} />
                    {
                        filteredcustomMetricIds.length > 0 && (
                            <List>
                                {
                                    filteredcustomMetricIds.map((metricId) => (
                                        <CustomMetricListItemContainer
                                          customMetricId={metricId}
                                          key={`custom-metric-${metricId}`}
                                        />
                                    ))
                                }
                            </List>
                        )
                    }
                </ListPageWrapper>
                {
                    totalCustomMetricsCount > 0 && filteredcustomMetricIds.length < 1
                    && (
                    <EmptyInfoScreen
                      image={metricEmptySearchResult}
                      title="No metrics found"
                      message="No results were found that match your search."
                    />
                    )
                }
                {
                    totalCustomMetricsCount < 1
                    && (
                    <EmptyInfoScreen
                      image={customMetricsEmpty}
                      title="You have no custom metrics yet"
                      message="Here is where you can manage your custom metrics."
                      buttons={<ButtonWithReactRouterLinkHandling to="/metric-builder" label="Build new metric" />}
                    />
                    )
                }
            </Body>
            <Footer />
        </ScrollViewWithScrollPosition>
    );
};

CustomMetrics.propTypes = {
    filteredcustomMetricIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    totalCustomMetricsCount: PropTypes.number.isRequired,
    location: customPropTypes.location
};

const makeMapStateToProps = () => {
    const formValueSelector = getFormValues(formName);
    const selectCustomMetricIdsWithListFiltersApplied = makeSelectCustomMetricIdsWithListFiltersApplied();
    return (state) => {
        const { freeTextFilter, sortBy } = formValueSelector(state) || initialValues;
        return {
            filteredcustomMetricIds: selectCustomMetricIdsWithListFiltersApplied(state, freeTextFilter, sortBy),
            totalCustomMetricsCount: selectCustomMetricsCount(state)
        };
    };
};

export default connect(makeMapStateToProps)(CustomMetrics);
