import * as customPropTypes from 'src/customPropTypes';
import Column from 'src/components/header/layout/Column';
import Header from 'src/components/header/layout/Header';
import React from 'react';
import TopRow from 'src/components/header/layout/TopRow';
import { connect } from 'react-redux';
import styles from 'src/stylesheets/headers/dataSourceDetailHeader.scss';
import BackLink from 'src/components/BackLink';
import { selectHasStickyHeader } from 'src/selectors/stickyHeader';
import PropTypes from 'prop-types';
import { makeSelectLinkToDataSources } from 'src/selectors/dataSources';
import DataSourceNameHeader from 'src/components/DataSourceNameHeader';

const placeholderIfFixedStyle = {
    height: 70
};

const DataSourceDetailHeader = (props) => {
    const { dataSource, sticky, linkToDataSources } = props;
    const {
        name,
        allowedPlatformType,
        isDeprecated,
        deprecationDate
    } = dataSource;

    const backButton = (
        <div className={styles.backButton}>
            <BackLink
              to={linkToDataSources}
              icon="back"
              tooltip="Back to data source library"
            />
        </div>
    );

    if (sticky) {
        return (
            <div>
                <div style={placeholderIfFixedStyle} />
                <Header fixed className={styles.sticky}>
                    <TopRow>
                        <Column flexGrow className={styles.wrapper}>
                            {backButton}
                            <DataSourceNameHeader
                              name={name}
                              platformType={allowedPlatformType}
                              isDeprecated={isDeprecated}
                              deprecationDate={deprecationDate}
                              size="small"
                            />
                        </Column>
                    </TopRow>
                </Header>
            </div>
        );
    }
    return (
        <Header>
            <TopRow>
                <Column flexGrow>
                    <div className={styles.wrapper}>
                        {backButton}
                        <DataSourceNameHeader
                          name={name}
                          platformType={allowedPlatformType}
                          isDeprecated={isDeprecated}
                          deprecationDate={deprecationDate}
                          size="large"
                        />
                    </div>
                </Column>
            </TopRow>
        </Header>
    );
};

DataSourceDetailHeader.propTypes = {
    dataSource: customPropTypes.dataSource.isRequired,
    stickyIdentifier: PropTypes.string.isRequired,
    sticky: PropTypes.bool.isRequired,
    linkToDataSources: customPropTypes.linkToDataSource.isRequired
};

const mapStateToProps = () => {
    const selectLinkToDataSources = makeSelectLinkToDataSources();
    return (state, ownProps) => ({
        sticky: selectHasStickyHeader(state, ownProps.stickyIdentifier),
        linkToDataSources: selectLinkToDataSources(state)
    });
};

export default connect(mapStateToProps)(DataSourceDetailHeader);
