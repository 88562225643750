import {
    DASHBOARD_ADD_REQUEST,
    DASHBOARD_CLONE_DASHBOARD_TEMPLATE_REQUEST,
    DASHBOARD_CLONE_REQUEST,
    DASHBOARD_EDIT_REQUEST,
    dashboardAddError,
    dashboardAddSuccess,
    dashboardCloneError,
    dashboardCloneDashboardTemplateError,
    dashboardCloneDashboardTemplateSuccess,
    dashboardCloneSuccess,
    dashboardEditError,
    dashboardEditSuccess,
    dashboardsGetError,
    dashboardsGetSuccess
} from 'src/actions/dashboard';
import {
    put, takeEvery, select
} from 'redux-saga/effects';
import {
    getMetriIdsFromDashboardMetrics,
    getFileBlob, handleAuthorizedServerRequest
} from 'src/sagas/utils';
import {
    GROUP_DELETE_SUCCESS,
    GROUP_FORCE_DELETE_SUCCESS
} from 'src/actions/groups';
import {
    POST_TAGS_REMOVE_SUCCESS, POST_TAG_FORCE_REMOVE_SUCCESS, POST_TAGS_ARCHIVE_SUCCESS, POST_TAGS_FORCE_ARCHIVE_SUCCESS
} from 'src/actions/postTags';
import objectUrlFileRequest from 'src/requestHandling/objectUrlFileRequest';
import {
    modalsHideCloneDashboard,
    modalsHideCreateDashboard,
    modalsHideSaveDashboard,
    modaslHideEditDashboard
} from 'src/actions/overlays';
import {
    parseDashboard,
    parseDashboardMetrics,
    parseDashboardsDashboardMetrics,
    parseOwnershipDashboard,
    parseOwnershipDashboards,
    parseMetrics,
    parseDashboardsDashboardReports,
    parseDashboardReports,
} from 'src/parsers';
import {
    PROFILE_DELETE_SUCCESS,
    PROFILE_FORCE_DELETE_SUCCESS
} from 'src/actions/profiles';
import { showDashboardSuccessNotification, showNotification } from 'src/actions/notifications';
import * as routeActions from 'react-router-redux';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { metricUsageCountUpdateRequest } from 'src/actions/metric';
import { selectDashboardTypeById, selectFolderTypeById } from 'src/selectors/ownership';
import { reportError } from 'src/utils/reportError';
import {
    SubmissionError, startAsyncValidation, stopAsyncValidation, formValueSelector
} from 'redux-form';
import createFormDataServerRequest from 'src/requestHandling/createFormDataServerRequest';

function* dashboardAddRequest(action) {
    const {
        name, customLogo, folderId, jumpToDashboard
    } = action.payload;
    const folderType = yield select(selectFolderTypeById, folderId);
    try {
        const params = {
            name,
            folderId: folderId === folderType ? null : folderId,
            folderType
        };
        if (customLogo.url !== '') {
            const blob = yield getFileBlob(objectUrlFileRequest, customLogo.url);
            params.customLogo = blob;
        }
        const serverRequest = createFormDataServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/save-dashboard');
        if (response) {
            const { jsonData } = response;
            const { dashboard, shared, user } = parseOwnershipDashboard(jsonData);
            yield put(dashboardAddSuccess(dashboard, parseDashboardMetrics(jsonData.dashboard), shared, user));
            yield put(modalsHideCreateDashboard());
            if (jumpToDashboard) {
                yield put(routeActions.push({
                    pathname: `/dashboard/${dashboard.id}`
                }));
            } else {
                yield put(showDashboardSuccessNotification(dashboard.id, dashboard.name));
            }
        }
        if (serverError) {
            yield put(dashboardAddError(new SubmissionError({ _error: serverError })));
        }
    } catch (applicationError) {
        reportError(applicationError);
        yield put(dashboardAddError(new SubmissionError({ _error: applicationError })));
    }
}

function* dashboardEditRequest(action) {
    const { payload } = action;
    const { id, name, customLogo } = payload;
    const dashboardType = yield select(selectDashboardTypeById, id);
    try {
        const params = {
            dashboardId: id, name, dashboardType, updateCustomLogo: true
        };
        if (customLogo.url !== '') {
            const blob = yield getFileBlob(objectUrlFileRequest, customLogo.url);
            params.customLogo = blob;
            params.updateCustomLogo = blob !== null;
        }
        const serverRequest = createFormDataServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/edit-dashboard');
        if (response) {
            const { jsonData } = response;
            const parsedDashboardReports = parseDashboardReports(jsonData.dashboard);
            yield put(dashboardEditSuccess(parseDashboard(jsonData.dashboard), parsedDashboardReports));
            yield put(modaslHideEditDashboard(id));
            yield put(showNotification('Your dashboard was successfully updated.'));
        }
        if (serverError) {
            yield put(dashboardEditError(new SubmissionError({ _error: serverError })));
        }
    } catch (applicationError) {
        reportError(applicationError);
        yield put(dashboardEditError(new SubmissionError({ _error: applicationError })));
    }
}

function* dashboardCloneRequest(action) {
    const {
        cloneDashboardId, folderId
    } = action.payload;

    const cloneDashboardType = yield select(selectDashboardTypeById, cloneDashboardId);
    const folderType = yield select(selectFolderTypeById, folderId);

    try {
        const serverRequest = createServerRequest({
            cloneDashboardId, cloneDashboardType, folderId: folderId === folderType ? null : folderId, folderType
        });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/clone-dashboard');
        if (response) {
            const { jsonData } = response;

            const { dashboard, shared, user } = parseOwnershipDashboard(jsonData);
            const parsedDashboardMetrics = parseDashboardMetrics(jsonData.dashboard);
            yield put(dashboardCloneSuccess(cloneDashboardId, dashboard, parsedDashboardMetrics, shared, user));
            yield put(modalsHideCloneDashboard());
            // update the usage count for the metrics used in this new dashboard
            const metricIds = getMetriIdsFromDashboardMetrics(parsedDashboardMetrics);
            yield put(metricUsageCountUpdateRequest(metricIds));

            yield put(showDashboardSuccessNotification(dashboard.id, dashboard.name));
        }
        if (serverError) {
            yield put(dashboardCloneError(cloneDashboardId, serverError));
        }
    } catch (applicationError) {
        reportError(applicationError);
        yield put(dashboardCloneError(cloneDashboardId, applicationError));
    }
}

function* dashboardCloneDashboardTemplateRequest(action) {
    const { dashboardTemplateId, folderId } = action.payload;

    const folderType = yield select(selectFolderTypeById, folderId);

    const params = {
        dashboardTemplateId,
        folderId: folderId === folderType ? null : folderId,
        folderType
    };

    try {
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/clone-from-public-dashboard');
        if (response) {
            const { jsonData } = response;
            const { ownershipDashboard } = jsonData;
            const parsedOwnershipDashboard = parseOwnershipDashboard(ownershipDashboard);
            const parsedMetrics = parseMetrics(jsonData);
            const parsedDashboardMetrics = parseDashboardMetrics(ownershipDashboard.dashboard);

            const { dashboard, user, shared } = parsedOwnershipDashboard;

            // update the usage count for the metrics used in this new dashboard
            const metricIds = getMetriIdsFromDashboardMetrics(parsedDashboardMetrics);
            yield put(metricUsageCountUpdateRequest(metricIds));
            yield put(dashboardCloneDashboardTemplateSuccess(dashboardTemplateId, dashboard, parsedDashboardMetrics, shared, user, parsedMetrics));
            yield put(showDashboardSuccessNotification(dashboard.id, dashboard.name));
            yield put(modalsHideSaveDashboard(dashboardTemplateId));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(modalsHideSaveDashboard(dashboardTemplateId));
        yield put(dashboardCloneDashboardTemplateError(dashboardTemplateId, applicationOrServerError));
        yield put(showNotification(applicationOrServerError.message, 'error'));
    }
}

function* getDashboardsRequest() {
    try {
        const serverRequest = createServerRequest();
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/get-dashboards');
        if (response) {
            const ownershipDashboards = parseOwnershipDashboards(response.jsonData);
            yield put(
                dashboardsGetSuccess(
                    ownershipDashboards,
                    parseDashboardsDashboardReports(response.jsonData.dashboard),
                    parseDashboardsDashboardMetrics(response.jsonData.dashboard)
                )
            );
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(dashboardsGetError(applicationOrServerError));
        yield put(showNotification('Updating your dashboards failed. Please reload the tool to see the latest changes', 'error'));
    }
}

const creatReportFormName = 'createReport';
const fieldName = 'advancedReportSchedule';
const reportFormValueSelector = formValueSelector(creatReportFormName);

function* validationCronExpression(action) {
    const { meta, payload } = action;
    if (meta.form === creatReportFormName && meta.field === fieldName) {
        yield put(startAsyncValidation(creatReportFormName, fieldName));
        try {
            const serverRequest = createServerRequest({ expression: payload.cronExpression });
            const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/validate-dashboard-report-cron-expression');

            // a protection against multiple async validation, drop the rest validations if field value do not match
            const advancedReportSchedule = yield select(reportFormValueSelector, fieldName);
            if (advancedReportSchedule.cronExpression === payload.cronExpression) {
                if (response) {
                    yield put(stopAsyncValidation(creatReportFormName, { }));
                }
                if (serverError) {
                    throw serverError;
                }
            }
        } catch (applicationOrServerError) {
            yield put(stopAsyncValidation(creatReportFormName, { [fieldName]: applicationOrServerError.message }));
        }
    }
}

export default function* dashboardsSagas() {
    yield takeEvery(DASHBOARD_ADD_REQUEST, dashboardAddRequest);
    yield takeEvery(DASHBOARD_EDIT_REQUEST, dashboardEditRequest);
    yield takeEvery(DASHBOARD_CLONE_REQUEST, dashboardCloneRequest);
    yield takeEvery(DASHBOARD_CLONE_DASHBOARD_TEMPLATE_REQUEST, dashboardCloneDashboardTemplateRequest);
    yield takeEvery([
        PROFILE_DELETE_SUCCESS,
        PROFILE_FORCE_DELETE_SUCCESS,
        GROUP_DELETE_SUCCESS,
        GROUP_FORCE_DELETE_SUCCESS,
        POST_TAGS_REMOVE_SUCCESS,
        POST_TAG_FORCE_REMOVE_SUCCESS,
        POST_TAGS_ARCHIVE_SUCCESS,
        POST_TAGS_FORCE_ARCHIVE_SUCCESS
    ], getDashboardsRequest);
    yield takeEvery('@@redux-form/CHANGE', validationCronExpression);
}
