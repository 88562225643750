import ErrorView from 'src/components/errors/ErrorView';
import React from 'react';

const ReadOnlyErrorView = () => (
    <ErrorView
      headline="Insufficient privileges"
      error={{ message: 'As a read only user you are not allowed to access this page.' }}
    />
);

export default ReadOnlyErrorView;
