import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/views/contentBlock.scss';

const ContentBlock = (props) => {
    const {
        headline, children, footerRight, footerLeft, className
    } = props;
    return (
        <div className={classnames(styles.section, className)}>
            {
                headline
                && (
                <div className={styles.headline}>
                    {headline}
                </div>
                )
            }
            <div className={classnames({ [styles.sectionBody]: footerLeft || footerRight })}>
                {children}
            </div>
            {
                (footerLeft || footerRight)
                && (
                <div className={styles.footer}>
                    {
                        footerLeft
                        && (
                        <div className={styles.footerLeft}>
                            {footerLeft}
                        </div>
                        )
                    }
                    {
                        footerRight
                        && (
                        <div className={styles.footerRight}>
                            {footerRight}
                        </div>
                        )
                    }
                </div>
                )
            }
        </div>
    );
};

ContentBlock.propTypes = {
    headline: PropTypes.string,
    children: customPropTypes.children.isRequired,
    footerRight: PropTypes.node,
    footerLeft: PropTypes.node,
    className: PropTypes.string
};

export default ContentBlock;
