import * as sideBarActions from 'src/actions/sidebars';
import classnames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { selectSidebarState } from 'src/selectors/sidebars';
import SidebarToggleButton from 'src/components/sidebar/SidebarToggleButton';
import Sidebar from 'src/components/sidebar/Sidebar';
import styles from 'src/stylesheets/sidebar/sidebar.scss';

const ToggleableSidebar = (props) => {
    const {
        sidebarState, sideBarName, children, toggleSideBarAction, width
    } = props;

    return (
        <div className={classnames(styles.wrapper)}>
            <SidebarToggleButton isOpen={sidebarState} onClick={() => { toggleSideBarAction(sideBarName); }} />
            {
                sidebarState
                && (
                    <Sidebar width={width}>
                        {children}
                    </Sidebar>
                )
            }
        </div>
    );
};

const mapStateToProps = (state, ownProps) => ({
    sidebarState: selectSidebarState(state, ownProps.sideBarName)
});

ToggleableSidebar.propTypes = {
    children: PropTypes.node.isRequired,
    toggleSideBarAction: PropTypes.func.isRequired,
    sideBarName: PropTypes.string.isRequired,
    sidebarState: PropTypes.bool.isRequired,
    width: PropTypes.number
};

export default connect(mapStateToProps, {
    toggleSideBarAction: sideBarActions.toggleSideBar
})(ToggleableSidebar);
