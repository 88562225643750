import { createAsyncStatesReducerForAction, createAsyncStatesReducerForActionsWithErrorAndSuccessStates } from 'src/reducers/utils';
import {
    GET_DATA_PUSH_TASK_LOGS_ERROR,
    GET_DATA_PUSH_TASK_LOGS_REQUEST,
    GET_DATA_PUSH_TASK_LOGS_SUCCESS
} from 'src/actions/dataPushTaskLogs';
import {
    GET_DATA_PUSH_TASK_SUCCESS,
    GET_DATA_PUSH_TASK_REQUEST,
    GET_DATA_PUSH_TASK_ERROR,
    GET_DATA_PUSH_TASKS_ERROR,
    GET_DATA_PUSH_TASKS_REQUEST,
    GET_DATA_PUSH_TASKS_SUCCESS,
    SORT_DATA_PUSH_TASK,
    DATA_PUSH_TASK_DELETE_SUCCESS,
    DATA_PUSH_TASK_ACTIVATE_ERROR,
    DATA_PUSH_TASK_ACTIVATE_REQUEST,
    DATA_PUSH_TASK_ACTIVATE_SUCCESS,
    DATA_PUSH_TASK_DEACTIVATE_SUCCESS,
    DATA_PUSH_TASK_DEACTIVATE_ERROR,
    DATA_PUSH_TASK_DEACTIVATE_REQUEST,
    DATA_PUSH_TASK_UPDATE_SUCCESS,
    DATA_PUSH_TASK_CREATE_SUCCESS,
    DATA_PUSH_TASK_DESTINATION_UPDATE_SUCCESS,
    DATA_PUSH_TASK_DRY_RUN_SUCCESS,
    DATA_PUSH_TASK_DRY_RUN_ERROR,
    DATA_PUSH_TASK_DRY_RUN_REQUEST
} from 'src/actions/dataPushTasks';
import _uniq from 'lodash/uniq';
import { combineReducers } from 'redux';
import _difference from 'lodash/difference';
import _omit from 'lodash/omit';

const initialSortDir = 'asc';
const secondSortDir = 'desc';

const defaultState = {
    sortBy: 'destinationType',
    sortDir: initialSortDir
};

const listTableConfig = (state = defaultState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SORT_DATA_PUSH_TASK: {
            const { sortBy } = payload;
            let newSortOrder = initialSortDir;
            if (state.sortBy === sortBy && state.sortDir === initialSortDir) {
                newSortOrder = secondSortDir;
            }
            return Object.assign({}, { sortBy, sortDir: newSortOrder });
        }
        default:
            return state;
    }
};

const allIds = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_DATA_PUSH_TASKS_SUCCESS: {
            const { dataPushTasks } = payload;
            return Object.keys(dataPushTasks);
        }
        case GET_DATA_PUSH_TASK_SUCCESS:
        case DATA_PUSH_TASK_ACTIVATE_SUCCESS:
        case DATA_PUSH_TASK_DEACTIVATE_SUCCESS:
        case DATA_PUSH_TASK_UPDATE_SUCCESS:
        case DATA_PUSH_TASK_CREATE_SUCCESS:
        case DATA_PUSH_TASK_DESTINATION_UPDATE_SUCCESS: {
            const { dataPushTask } = payload;
            return _uniq([...state, dataPushTask.id]);
        }
        case DATA_PUSH_TASK_DELETE_SUCCESS: {
            const { dataPushTaskId } = payload;
            return _difference(state, [dataPushTaskId]);
        }
        default:
            return state;
    }
};

const byIds = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_DATA_PUSH_TASKS_SUCCESS: {
            const { dataPushTasks } = payload;
            return Object.assign({}, state, dataPushTasks);
        }
        case GET_DATA_PUSH_TASK_SUCCESS:
        case DATA_PUSH_TASK_ACTIVATE_SUCCESS:
        case DATA_PUSH_TASK_DEACTIVATE_SUCCESS:
        case DATA_PUSH_TASK_UPDATE_SUCCESS:
        case DATA_PUSH_TASK_CREATE_SUCCESS:
        case DATA_PUSH_TASK_DESTINATION_UPDATE_SUCCESS: {
            const { dataPushTask } = payload;
            return Object.assign({}, state, { [dataPushTask.id]: dataPushTask });
        }
        case DATA_PUSH_TASK_DELETE_SUCCESS: {
            const { dataPushTaskId } = payload;
            return _omit(state, dataPushTaskId);
        }
        default:
            return state;
    }
};

const dataPushTaskLatestLogIds = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_DATA_PUSH_TASKS_SUCCESS: {
            const { latestDataPushTaskLogs } = payload;
            const organizedByDataPushTaskIds = {};
            Object.keys(latestDataPushTaskLogs).forEach((latestDataPushTaskLogId) => {
                const latestDataPushTaskLog = latestDataPushTaskLogs[latestDataPushTaskLogId];
                const { id, dataPushTaskId } = latestDataPushTaskLog;
                Object.assign(organizedByDataPushTaskIds, { [dataPushTaskId]: id });
            });
            return Object.assign({}, state, organizedByDataPushTaskIds);
        }
        case GET_DATA_PUSH_TASK_SUCCESS: {
            const { dataPushTaskLog } = payload;
            if (dataPushTaskLog) {
                const { id, dataPushTaskId } = dataPushTaskLog;
                return Object.assign({}, state, { [dataPushTaskId]: id });
            }
            return state;
        }
        case DATA_PUSH_TASK_DELETE_SUCCESS: {
            const { dataPushTaskId } = payload;
            return _omit(state, dataPushTaskId);
        }
        default:
            return state;
    }
};

const asyncStates = combineReducers({
    dataPushTaskLogs: createAsyncStatesReducerForAction(
        (payload) => payload.dataPushTaskId,
        [GET_DATA_PUSH_TASK_LOGS_REQUEST],
        [GET_DATA_PUSH_TASK_LOGS_SUCCESS, GET_DATA_PUSH_TASK_LOGS_ERROR]
    ),
    getAll: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        () => 'dataPushTasks',
        [GET_DATA_PUSH_TASKS_REQUEST],
        [GET_DATA_PUSH_TASKS_SUCCESS],
        [GET_DATA_PUSH_TASKS_ERROR]
    ),
    get: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        (payload) => payload.id,
        [GET_DATA_PUSH_TASK_REQUEST],
        [GET_DATA_PUSH_TASK_SUCCESS],
        [GET_DATA_PUSH_TASK_ERROR]
    ),
    activate: createAsyncStatesReducerForAction(
        (payload) => payload.dataPushTaskId,
        [DATA_PUSH_TASK_ACTIVATE_REQUEST],
        [DATA_PUSH_TASK_ACTIVATE_SUCCESS, DATA_PUSH_TASK_ACTIVATE_ERROR],
    ),
    deactivate: createAsyncStatesReducerForAction(
        (payload) => payload.dataPushTaskId,
        [DATA_PUSH_TASK_DEACTIVATE_REQUEST],
        [DATA_PUSH_TASK_DEACTIVATE_SUCCESS, DATA_PUSH_TASK_DEACTIVATE_ERROR]
    ),
    dryRun: createAsyncStatesReducerForAction(
        (payload) => payload.dataPushTaskId,
        [DATA_PUSH_TASK_DRY_RUN_REQUEST],
        [DATA_PUSH_TASK_DRY_RUN_SUCCESS, DATA_PUSH_TASK_DRY_RUN_ERROR]
    )
});

export const dataPushTasks = combineReducers({
    allIds,
    byIds,
    asyncStates,
    dataPushTaskLatestLogIds,
    listTableConfig
});
