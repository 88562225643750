import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import Dashboards from './Dashboards';

const UserDashboards = ({ location }) => <Dashboards currentFolderId="user" type="user" location={location} />;

UserDashboards.propTypes = {
    location: customPropTypes.location.isRequired
};

export default UserDashboards;
