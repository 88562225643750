import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Column from 'src/components/header/layout/Column';
import { connect } from 'react-redux';
import DiscoverBackButton from 'src/components/views/discover/DiscoverBackButton';
import Header from 'src/components/header/layout/Header';
import { makeSelectMetricPreviewLink } from 'src/selectors/filterSelectors';
import MetricNameHeaderWithIcons from 'src/components/MetricNameHeaderWithIcons';
import MetricOpenInPopoverContainer from 'src/components/popovers/MetricOpenInPopoverContainer';
import { modalShowAddMetricToDashboard } from 'src/actions/overlays';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/headers/discoverDashboardAndMetricHeader.scss';
import TopRow from 'src/components/header/layout/TopRow';
import withPopover from 'src/components/withPopover';
import withReactRouterLinkHandling from 'src/components/withReactRouterLinkHandling';
import { DISCOVER_PREVIEW } from 'src/utils/filterSelectors';

const ButtonWithPopover = withPopover(Button);
const ButtonWithReactRouterLinkHandling = withReactRouterLinkHandling(Button);

const MetricDetailHeader = (props) => {
    const {
        searchResultMetric, onAddMetricToDashboardClickAction, linkToMetricPreview
    } = props;
    const {
        id, name, platformType, isBasedOnPrivateStatistics, isCustomMetric, visualization
    } = searchResultMetric;
    const metricOpenInPopover = <MetricOpenInPopoverContainer context={DISCOVER_PREVIEW} metric={searchResultMetric} />;
    return (
        <Header>
            <TopRow>
                <Column flexGrow>
                    <div className={styles.headline}>
                        <div className={styles.backButton}>
                            <DiscoverBackButton />
                        </div>
                        <MetricNameHeaderWithIcons
                          name={name}
                          platformType={platformType}
                          isBasedOnPrivateStatistics={isBasedOnPrivateStatistics}
                          size="large"
                          isCustomMetric={isCustomMetric}
                        />
                    </div>
                </Column>
                <Column>
                    <ButtonGroup>
                        <Button
                          action
                          label="Add to dashboard"
                          icon="plus"
                          onClick={() => { onAddMetricToDashboardClickAction(id, visualization.type === 'table'); }}
                        />
                        <ButtonWithReactRouterLinkHandling to={linkToMetricPreview} icon="show" label="Preview" />
                        <ButtonWithPopover
                          id={id}
                          overlay={metricOpenInPopover}
                          label="Open in..."
                          placement="bottom-end"
                          multiAction
                        />
                    </ButtonGroup>
                </Column>
            </TopRow>
        </Header>
    );
};

MetricDetailHeader.propTypes = {
    searchResultMetric: customPropTypes.searchResultMetricOrFalse.isRequired,
    onAddMetricToDashboardClickAction: PropTypes.func.isRequired,
    metricId: PropTypes.string.isRequired,
    linkToMetricPreview: customPropTypes.linkTo.isRequired,
};

const makeMapStateToProps = () => {
    const selectMetricPreviewLink = makeSelectMetricPreviewLink();
    return (state, ownProps) => ({
        linkToMetricPreview: selectMetricPreviewLink(state, DISCOVER_PREVIEW, ownProps.metricId)
    });
};

export default connect(makeMapStateToProps, {
    onAddMetricToDashboardClickAction: modalShowAddMetricToDashboard
})(MetricDetailHeader);
