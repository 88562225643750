import { ensurePermission, writePermissions } from 'src/middleware/userPermissionCheck';
import * as overlayActions from 'src/actions/overlays';
import Button from 'src/components/buttons/Button';
import Column from 'src/components/header/layout/Column';
import { connect } from 'react-redux';
import Header from 'src/components/header/layout/Header';
import PageHeadline from 'src/components/header/layout/PageHeadline';
import PropTypes from 'prop-types';
import React from 'react';
import TopRow from 'src/components/header/layout/TopRow';

const AuthenticationsHeader = (props) => {
    const { modalsShowCreateAuthTransactionSelectProfilesAction } = props;
    return (
        <Header>
            <TopRow>
                <Column flexGrow>
                    <PageHeadline>
                        Authentications
                    </PageHeadline>
                </Column>
                <Column>
                    <Button
                      action
                      icon="plus"
                      label="New authentication"
                      onClick={() => { modalsShowCreateAuthTransactionSelectProfilesAction(); }}
                    />
                </Column>
            </TopRow>
        </Header>
    );
};

AuthenticationsHeader.propTypes = {
    modalsShowCreateAuthTransactionSelectProfilesAction: PropTypes.func.isRequired
};

export default connect(null, {
    modalsShowCreateAuthTransactionSelectProfilesAction: ensurePermission(overlayActions.modalsShowCreateAuthTransactionSelectProfiles, writePermissions.createAuthTransaction)
})(AuthenticationsHeader);
