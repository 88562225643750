import Modal from 'src/components/modals/layout/Modal';
import React from 'react';
import styles from 'src/stylesheets/views/integrations/DataPushTaskLogDetail.scss';
import EmptyDiv from 'src/components/loadingIndicators/EmptyDiv';
import DataPushTaskDetailLoading from 'src/components/loadingIndicators/DataPushTaskDetailLoading';

const DataPushTaskDetailModalLoading = () => (
    <Modal>
        <Modal.Dialog fullscreen>
            <Modal.Header>
                <Modal.Title>
                    <div className={styles.title}>
                        <div className={styles.titleImg}>
                            <EmptyDiv height={24} width={24} />
                        </div>
                        <div className={styles.tableName}>
                            <EmptyDiv height={24} width={200} />
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DataPushTaskDetailLoading />
            </Modal.Body>
            <Modal.Footer>
                <EmptyDiv width={164} height={40} />
            </Modal.Footer>
        </Modal.Dialog>
    </Modal>
);

export default DataPushTaskDetailModalLoading;
