import {
    makeSelectMetricSearchResultMetric,
    selectHasSearchResultError,
    selectIsSearchResultLoading
} from 'src/selectors/metricSearch';
import React, { useEffect } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import Body from 'src/components/Body';
import { connect } from 'react-redux';
import DashboardsAndTagsInfo from 'src/components/views/discover/metricDetail/DashboardsAndTagsInfo';
import Footer from 'src/components/Footer';
import { makeSelectTagsById } from 'src/selectors/tags';
import MetricDetailAdditionalInfo from 'src/components/views/discover/metricDetail/MetricDetailAdditionalInfo';
import MetricDetailHeader from 'src/components/header/MetricDetailHeader';
import MetricDetailPageLoading from 'src/components/loadingIndicators/MetricDetailPageLoading';
import MetricSamplePreview from 'src/components/views/discover/metricDetail/MetricSamplePreview';
import MetricPageNotFound from 'src/components/views/discover/metricDetail/MetricNotFoundPage';
import { metricSearchResultRequest } from 'src/actions/metricSearch';
import PropTypes from 'prop-types';
import ScrollView from 'src/components/ScrollView';
import styles from 'src/stylesheets/views/dashboardAndMetricDetail.scss';

const type = 'Metric';

const MetricDetail = (props) => {
    useEffect(() => {
        const {
            metricSearchResultRequestAction, searchResultMetric, error, metricId
        } = props;

        if (!searchResultMetric && !error) {
            metricSearchResultRequestAction(type, metricId);
        }
    }, []);

    const {
        searchResultMetric,
        isLoading,
        error,
        tags,
        metricId
    } = props;

    if (isLoading) {
        return <MetricDetailPageLoading />;
    }
    if (error) {
        return <MetricPageNotFound />;
    }
    if (!isLoading && searchResultMetric !== false) {
        const {
            summary, description, dataSourceHints, usage, createTime, dataSources, platformType, useCases
        } = searchResultMetric;
        return (
            <ScrollView dataViewName="metric-detail">
                <MetricDetailHeader
                  searchResultMetric={searchResultMetric}
                  metricId={metricId}
                />
                <Body>
                    <div className={styles.wrapper}>
                        <div className={styles.leftSection}>
                            <MetricDetailAdditionalInfo
                              summary={summary}
                              description={description}
                              dataSourceHints={dataSourceHints}
                              usage={usage}
                              createTime={createTime}
                              dataSources={dataSources}
                            />
                        </div>
                        <div className={styles.rightSection}>
                            <MetricSamplePreview
                              searchResultMetric={searchResultMetric}
                              isLoading={isLoading}
                            />
                            <DashboardsAndTagsInfo
                              tags={tags}
                              metricId={metricId}
                              platformType={platformType}
                              useCases={useCases}
                            />
                        </div>
                    </div>
                </Body>
                <Footer />
            </ScrollView>
        );
    }

    return null;
};

MetricDetail.propTypes = {
    searchResultMetric: customPropTypes.searchResultMetricOrFalse.isRequired,
    metricSearchResultRequestAction: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([customPropTypes.dataLoadingError, PropTypes.bool]).isRequired,
    metricId: PropTypes.string.isRequired,
    tags: customPropTypes.tags.isRequired
};

const makeMapStateToProps = () => {
    const selectTagsById = makeSelectTagsById();
    const selectMetricSearchResultMetric = makeSelectMetricSearchResultMetric();
    return (state, ownProps) => {
        const { metricId } = ownProps.params;
        const searchResultMetric = selectMetricSearchResultMetric(state, metricId);
        const tags = searchResultMetric ? selectTagsById(state, searchResultMetric.tagIds) : [];
        return {
            searchResultMetric,
            tags,
            isLoading: selectIsSearchResultLoading(state, type, metricId),
            error: selectHasSearchResultError(state, type, metricId),
            metricId
        };
    };
};

export default connect(makeMapStateToProps, {
    metricSearchResultRequestAction: metricSearchResultRequest
})(MetricDetail);
