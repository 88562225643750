import {
    ANALYSIS_AUTOCOMPLETE_SUCCESS,
    ANALYSIS_QUICK_ACCESS_LINKS_SUCCESS
} from 'src/actions/analysis';
import {
    METRIC_SEARCH_RESULT_ERROR,
    METRIC_SEARCH_RESULT_REQUEST,
    METRIC_SEARCH_RESULT_SUCCESS,
    METRIC_SEARCH_SEARCH_SUCCESS
} from 'src/actions/metricSearch';
import {
    RECOMMENDATIONS_LOAD_SUCCESS,
    RECOMMENDATIONS_SEARCH_LOAD_SUCCESS
} from 'src/actions/recommendations';
import _uniq from 'lodash/uniq';
import { combineReducers } from 'redux';
import { createAsyncStatesReducerForAction } from 'src/reducers/utils';
import { METRIC_USAGE_COUNT_UPDATE_SUCCESS } from 'src/actions/metric';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';

const createPublicResultReducer = (forType) => (state = {}, action) => {
    const { payload, type } = action;
    switch (type) {
        case USER_LOGGED_IN:
        case ANALYSIS_QUICK_ACCESS_LINKS_SUCCESS:
        case ANALYSIS_AUTOCOMPLETE_SUCCESS:
        case METRIC_SEARCH_RESULT_SUCCESS:
        case RECOMMENDATIONS_SEARCH_LOAD_SUCCESS:
        case RECOMMENDATIONS_LOAD_SUCCESS:
        case METRIC_SEARCH_SEARCH_SUCCESS: {
            const { resultsForEntities: searchResults } = payload;
            const publicMetricResults = {};
            searchResults.forEach((searchResult) => {
                if (searchResult.type === forType) {
                    publicMetricResults[searchResult.id] = searchResult;
                }
            });
            if (Object.keys(publicMetricResults).length > 0) {
                return Object.assign({}, state, publicMetricResults);
            }
            return state;
        }
        case METRIC_USAGE_COUNT_UPDATE_SUCCESS: {
            if (forType === 'Metric') {
                const { metricUsageUpdates } = payload;
                let newState = Object.assign({}, state);
                Object.keys(metricUsageUpdates).forEach((id) => {
                    const usage = metricUsageUpdates[id];
                    const currentMetric = Object.assign({}, newState[`${id}`]);
                    const updatedMetric = Object.assign({}, currentMetric, { usage });
                    newState = Object.assign({}, newState, { [`${id}`]: updatedMetric });
                });
                return Object.assign({}, state, newState);
            }
            return state;
        }
        default:
            return state;
    }
};

const createPublicAllIdsReducer = (forType) => (state = [], action) => {
    const { payload, type } = action;
    switch (type) {
        case USER_LOGGED_IN:
        case ANALYSIS_QUICK_ACCESS_LINKS_SUCCESS:
        case ANALYSIS_AUTOCOMPLETE_SUCCESS:
        case METRIC_SEARCH_RESULT_SUCCESS:
        case RECOMMENDATIONS_SEARCH_LOAD_SUCCESS:
        case RECOMMENDATIONS_LOAD_SUCCESS:
        case METRIC_SEARCH_SEARCH_SUCCESS: {
            const { resultsForEntities: searchResults } = payload;
            const newIds = [];
            searchResults.forEach((result) => {
                if (result.type === forType) {
                    newIds.push(result.id);
                }
            });
            return _uniq([...state, ...newIds]);
        }
        default:
            return state;
    }
};

const dashboardTemplates = combineReducers({
    byId: createPublicResultReducer('DashboardTemplate'),
    allIds: createPublicAllIdsReducer('DashboardTemplate'),
});

const metrics = combineReducers({
    byId: createPublicResultReducer('Metric'),
    allIds: createPublicAllIdsReducer('Metric'),
});

function errors(state = {}, action) {
    const { type, payload } = action;
    switch (type) {
        case METRIC_SEARCH_RESULT_ERROR: {
            const identifier = `${payload.type}_${payload.id}`;
            return Object.assign({}, state, { [identifier]: payload.error });
        }
        default:
            return state;
    }
}

export const metricSearchResults = combineReducers({
    dashboardTemplates,
    metrics,
    isLoading: createAsyncStatesReducerForAction(
        (payload) => `${payload.type}_${payload.id}`,
        [METRIC_SEARCH_RESULT_REQUEST],
        [METRIC_SEARCH_RESULT_SUCCESS, METRIC_SEARCH_RESULT_ERROR]
    ),
    errors
});
