import React from 'react';
import { modalShowGoogleBigQueryDestinationCreateTable } from 'src/actions/overlays';
import Button from 'src/components/buttons/Button';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';

const GoogleBigQueryDestinationCreateTableButton = (props) => {
    const handleOnClick = () => {
        const { dataPushTaskDestination, modalShowGoogleBigQueryDestinationCreateTableAction } = props;
        const { id, dataPushTaskId } = dataPushTaskDestination;
        modalShowGoogleBigQueryDestinationCreateTableAction(dataPushTaskId, id);
    };

    return (
        <Button
          label="Create table"
          onClick={handleOnClick}
        />
    );
};

GoogleBigQueryDestinationCreateTableButton.propTypes = {
    dataPushTaskDestination: customPropTypes.googleBigQueryDataPushTaskDestination.isRequired,
    modalShowGoogleBigQueryDestinationCreateTableAction: PropTypes.func.isRequired
};

export default connect(null, {
    modalShowGoogleBigQueryDestinationCreateTableAction: modalShowGoogleBigQueryDestinationCreateTable
})(GoogleBigQueryDestinationCreateTableButton);
