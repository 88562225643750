import {
    analysisRoute,
    apiRoute,
    dataSourcesRoute,
    postsRoute,
    profilesRoute,
    userAccountRoute,
    userNotificationsRoute,
    planRoute
} from 'src/routePaths';
import {
    ANALYSIS,
    ACCOUNTS,
    DATA_SOURCES,
    POSTS,
    INTEGRATION,
    NOTIFICATION,
    USER_PERSONAL,
    USER_ADMIN,
} from 'src/utils/routes';
import { UPDATE_LAST_MAIN_MENU_LOCATION } from 'src/actions/lastMainMenuLocation';

export const mainNavPathRoutes = {
    [ANALYSIS]: { pathname: analysisRoute },
    [ACCOUNTS]: { pathname: profilesRoute },
    [DATA_SOURCES]: { pathname: dataSourcesRoute },
    [POSTS]: { pathname: postsRoute },
    [INTEGRATION]: { pathname: apiRoute },
    [NOTIFICATION]: { pathname: userNotificationsRoute },
    [USER_PERSONAL]: { pathname: userAccountRoute },
    [USER_ADMIN]: { pathname: planRoute }
};

export const lastMainMenuLocations = (state = mainNavPathRoutes, actions) => {
    const { type, payload } = actions;
    switch (type) {
        case UPDATE_LAST_MAIN_MENU_LOCATION: {
            const { context, location } = payload;
            return Object.assign({}, state, {
                [context]: location
            });
        }
        default: {
            return state;
        }
    }
};
