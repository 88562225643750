import React from 'react';
import { selectFolderTypeById } from 'src/selectors/ownership';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';
import Dashboards from './Dashboards';

const FolderDashboards = ({ params, location, type }) => <Dashboards currentFolderId={params.folderId} type={type} location={location} />;

FolderDashboards.propTypes = {
    params: PropTypes.shape({ folderId: PropTypes.string.isRequired }).isRequired,
    type: PropTypes.string.isRequired,
    location: customPropTypes.location.isRequired
};

const mapStateToProps = (state, ownProps) => {
    const { folderId } = ownProps.params;
    const type = selectFolderTypeById(state, folderId);
    return {
        type
    };
};

export default connect(mapStateToProps)(FolderDashboards);
