import React from 'react';
import styles from 'src/stylesheets/loadingIndicators/emptyAnalysisPublicMetricTileSkeleton.scss';

const EmptyAnalysisPublicMetricTileSkeleton = () => (
    <div className={styles.tile}>
        <div className={styles.header}>
            <div className={styles.icon} />
            <div className={styles.text} />
        </div>
        <div className={styles.content} />
    </div>
);

export default EmptyAnalysisPublicMetricTileSkeleton;
