import { makeSelectAdminSettingsState } from 'src/selectors/adminSettings';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Column from 'src/components/header/layout/Column';
import { connect } from 'react-redux';
import Header from 'src/components/header/layout/Header';
import { modalShowSpaceCreate } from 'src/actions/overlays';
import PageHeadline from 'src/components/header/layout/PageHeadline';
import PropTypes from 'prop-types';
import React from 'react';
import TopRow from 'src/components/header/layout/TopRow';
import * as customPropTypes from 'src/customPropTypes';
import ClientLimits from 'src/components/views/settings/ClientLimits';
import Separator from 'src/components/header/layout/Separator';

const SpaceHeader = (props) => {
    const { loadingState, modalShowSpaceCreateAction } = props;
    const { success, isPending, error } = loadingState;
    return (
        <Header>
            <TopRow>
                <Column flexGrow>
                    <PageHeadline>Space management</PageHeadline>
                </Column>
                {
                    success
                    && (
                        <>
                            <Column>
                                <ClientLimits />
                            </Column>
                            <Column>
                                <Separator />
                            </Column>
                        </>
                    )
                }
                <Column>
                    <ButtonGroup>
                        <Button
                          onClick={() => modalShowSpaceCreateAction()}
                          label="Add a space"
                          action
                          icon="plus"
                          disabled={isPending || !!error}
                        />
                    </ButtonGroup>
                </Column>
            </TopRow>
        </Header>
    );
};

SpaceHeader.propTypes = {
    modalShowSpaceCreateAction: PropTypes.func.isRequired,
    loadingState: customPropTypes.asyncDataLoadingState.isRequired
};

const makeMapStateToProps = () => {
    const selectAdminSettingsState = makeSelectAdminSettingsState();
    return (state) => ({
        loadingState: selectAdminSettingsState(state)
    });
};

export default connect(makeMapStateToProps, {
    modalShowSpaceCreateAction: modalShowSpaceCreate
})(SpaceHeader);
