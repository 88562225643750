import HeaderWithHeadline from 'src/components/header/HeaderWithHeadline';
import AnalysisRecommendations from 'src/components/views/analysis/AnalysisRecommendations';
import Body from 'src/components/Body';
import PropTypes from 'prop-types';
import React from 'react';
import ScrollView from 'src/components/ScrollView';
import styles from 'src/stylesheets/views/analysis/analysis.scss';
import withMostUsedNetworks from 'src/components/views/withMostUsedNetworks';
import withRecommendationsLoading from 'src/components/recommendation/withRecommendationsLoading';
import { getNetworkName } from 'src/utils/profiles';

const AnalysisRecommendationsWithRecommendationsLoading = withRecommendationsLoading(AnalysisRecommendations, 'search');

const AnalysisStart = (props) => {
    const { mostUsedNetwork, mostUsedNetworkTagId } = props;

    const recommendationIdentifier = `analysisStartFor${mostUsedNetwork}`;
    const niceNetworkName = getNetworkName(mostUsedNetwork);
    const title = `Select a metric in the sidebar for a quick analysis or try one of our most popular ${niceNetworkName} metrics`;

    const searchParams = {
        filterBy: 'publicMetrics',
        orderBy: 'mostUsed',
        tagIds: [mostUsedNetworkTagId],
        limit: 3
    };

    return (
        <ScrollView dataViewName="analysis-start">
            <HeaderWithHeadline headline="Quick analysis" />
            <Body className={styles.flexBody}>
                <div className={styles.analysisStartContent}>
                    <div className={styles.analysisStartHeadline}>
                        {title}
                    </div>
                    <AnalysisRecommendationsWithRecommendationsLoading
                      identifier={recommendationIdentifier}
                      searchParams={searchParams}
                      title=""
                    />
                </div>
            </Body>
        </ScrollView>
    );
};

AnalysisStart.propTypes = {
    mostUsedNetwork: PropTypes.string.isRequired,
    mostUsedNetworkTagId: PropTypes.string.isRequired
};

export default withMostUsedNetworks(AnalysisStart);
