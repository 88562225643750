import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import classnames from 'classnames';
import DragHandle from 'src/components/metricTiles/layout/DragHandle';
import NetworkIcon from 'src/components/NetworkIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/metricHeader.scss';

const SampleDashboardDataHeader = (props) => {
    const {
        platformType,
        name,
        breakpoint,
        isAllowedToChangeLayout,
        dragHandleClassName
    } = props;

    return (
        <div className={styles.metricHeader}>
            <div className={classnames(styles.topRow, styles.grid)}>
                <div className={styles.left}>
                    <NetworkIcon
                      platformType={platformType}
                      className={styles.networkIcon}
                    />
                    <span className={classnames(styles.metricName)}>{name}</span>
                </div>
                <div className={styles.right}>
                    <ButtonGroup>
                        <Button
                          className="grid-hover-button-opacity"
                          small
                          icon="export"
                        />
                        <Button
                          className="grid-hover-button-opacity"
                          small
                          icon="more"
                        />
                        <DragHandle
                          breakpoint={breakpoint}
                          isAllowedToChangeLayout={isAllowedToChangeLayout}
                          className="grid-hover-button-opacity"
                          gridDragHandleClassName={dragHandleClassName}
                        />
                    </ButtonGroup>
                </div>
            </div>
        </div>
    );
};

SampleDashboardDataHeader.propTypes = {
    name: PropTypes.string.isRequired,
    dragHandleClassName: PropTypes.string.isRequired,
    platformType: PropTypes.string.isRequired,
    breakpoint: customPropTypes.gridBreakpoints.isRequired,
    isBasedOnPrivateStatistics: PropTypes.bool.isRequired,
    isAllowedToChangeLayout: PropTypes.bool.isRequired
};

export default SampleDashboardDataHeader;
