import React from 'react';
import styles from 'src/stylesheets/views/integrations/integrationAdminOnlyErrorView.scss';
import Button from 'src/components/buttons/Button';
import PropTypes from 'prop-types';

const IntegrationEmptyState = ({ title, message, image }) => (
    <div className={styles.wrapper}>
        <div className={styles.imageSection}>
            <img src={image} className={styles.img} />
        </div>
        <div className={styles.title}>{title}</div>
        <div className={styles.message}>
            {message}
        </div>
        <div className={styles.buttons}>
            <Button
              label="Contact customer success"
              action
              onClick={() => { window.open('mailto:support@facelift-bbt.com'); }}
            />
        </div>
    </div>
);

IntegrationEmptyState.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired
};

export default IntegrationEmptyState;
