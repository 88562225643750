import React from 'react';
import PropTypes from 'prop-types';
import EditButton from 'src/components/buttons/EditButton';
import PostPostTagList from 'src/components/views/postTags/postPostTagList/PostPostTagList';
import { connect } from 'react-redux';
import withDisabledStateIfOneItemIsSelectedInList from 'src/components/withDisabledStateIfOneItemIsSelectedInList';
import * as customPropTypes from 'src/customPropTypes';
import { serverDataClearAllCache } from 'src/actions/serverData';
import withToolTip from 'src/components/withToolTip';
import withPopover from 'src/components/withPopover';
import ManageTagsOfPostPopover from 'src/components/popovers/ManageTagsOfPostPopover';

const EditButtonWithPopover = withToolTip(withPopover(EditButton));
const EditButtonWithDisabledState = withDisabledStateIfOneItemIsSelectedInList(EditButtonWithPopover);

const PostPostTagListInPostTile = (props) => {
    const { post, listName } = props;

    const handleOnApplyClick = () => {
        const { serverDataClearAllCacheAction } = props;
        serverDataClearAllCacheAction();
    };

    return (
        <PostPostTagList
          post={post}
          editButton={(
              <EditButtonWithDisabledState
                listName={listName}
                tooltip="Assign tags"
                className="hover-button-opacity"
                overlay={(
                    <ManageTagsOfPostPopover
                      postId={post.id}
                      onApplyClick={handleOnApplyClick}
                      performPostSearch
                    />
                )}
              />
          )}
        />
    );
};

PostPostTagListInPostTile.propTypes = {
    post: customPropTypes.post.isRequired,
    listName: PropTypes.string.isRequired,
    serverDataClearAllCacheAction: PropTypes.func.isRequired
};

export default connect(null, {
    serverDataClearAllCacheAction: serverDataClearAllCache
})(PostPostTagListInPostTile);
