import Body from 'src/components/Body';
import DashboardAndMetricLoadingHeader from 'src/components/loadingIndicators/DashboardAndMetricLoadingHeader';
import EmptyDiv from 'src/components/loadingIndicators/EmptyDiv';
import React from 'react';
import ScrollView from 'src/components/ScrollView';
import styles from 'src/stylesheets/views/dashboardAndMetricDetail.scss';

const DashboardDetailPageLoading = () => (
    <ScrollView dataViewName="dashboard-detail-page-loading">
        <DashboardAndMetricLoadingHeader />
        <Body>
            <div className={styles.loadingSection}>
                <EmptyDiv height={464} />
            </div>
            <EmptyDiv height={516} />
        </Body>
    </ScrollView>
);

export default DashboardDetailPageLoading;
