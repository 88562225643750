import Body from 'src/components/Body';
import Footer from 'src/components/Footer';
import MultiFactorAuthenticationEnrollmentState from 'src/components/views/settings/MultiFactorAuthenticationEnrollmentState';
import UserSecurityLoader from 'src/components/views/settings/UserSecurityLoader';
import UserIdentity from 'src/components/views/settings/UserIdentity';
import React from 'react';
import ScrollView from 'src/components/ScrollView';
import SettingsHeader from 'src/components/header/SettingsHeader';
import styles from 'src/stylesheets/views/settings/settings.scss';
import classnames from 'classnames';
import _capitalize from 'lodash/capitalize';

const Security = () => (
    <ScrollView dataViewName="security">
        <SettingsHeader headline="Security" />
        <Body>
            <UserSecurityLoader>
                { (identities) => (identities.map(({ userIdentities, multiFactorAuthenticationEnrollmentState, name }) => (
                    <div>
                        <div className={styles.headline}>{_capitalize(name)}</div>
                        <div key={name} className={styles.sectionWrapper}>
                            <div className={styles.leftSection}>
                                <MultiFactorAuthenticationEnrollmentState state={multiFactorAuthenticationEnrollmentState} />
                            </div>
                            {userIdentities.map((userIdentity, j) => (
                                <div key={userIdentity.provider} className={classnames({ [styles.leftSection]: (j + 1) % 2 === 0, [styles.rightSection]: (j + 1) % 2 === 1 })}>
                                    <UserIdentity userIdentity={userIdentity} key={userIdentity.provider} />
                                </div>
                            )) }
                        </div>
                    </div>
                )))}
            </UserSecurityLoader>
        </Body>
        <Footer />
    </ScrollView>
);

export default Security;
