import classnames from 'classnames';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/views/dashboards/dashboardInfoListItem.scss';

const DashboardInfoListItem = (props) => {
    const { name, icon } = props;
    return (
        <div className={styles.wrapper}>
            <div className={styles.left}>
                <IcomoonIcon
                  icon={icon}
                  className={styles.icon}
                />
            </div>
            <div className={styles.right}>
                <div className={styles.ellipsisWrapper}>
                    <DivWithTooltip
                      tooltip={name}
                      className={classnames(styles.name)}
                    >
                        {name}
                    </DivWithTooltip>
                </div>
            </div>
        </div>
    );
};

DashboardInfoListItem.propTypes = {
    icon: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
};

export default DashboardInfoListItem;
