import { connect } from 'react-redux';
import discoverNoResults from 'src/resources/illustrations/discover-no-results.png';
import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import PropTypes from 'prop-types';
import React from 'react';
import { selectMetricSearchFilterBy } from 'src/selectors/metricSearch';

const filters = {
    customMetrics: 'custom metrics',
    publicMetrics: 'public metrics',
    metrics: 'metrics',
    dashboards: 'dashboards',
};

const NoResultsView = (props) => {
    const {
        filterBy
    } = props;

    return (
        <EmptyInfoScreen
          image={discoverNoResults}
          title={`No ${filters[filterBy]} found`}
          message="Try selecting different tags, or reset your search."
        />
    );
};

NoResultsView.propTypes = {
    filterBy: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
    filterBy: selectMetricSearchFilterBy(state)
});

export default connect(mapStateToProps)(NoResultsView);
