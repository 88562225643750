import ContentBlock from 'src/components/views/ContentBlock';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import { modalShowDeleteAccount } from 'src/actions/overlays';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/views/settings/settings.scss';

const DeleteAccount = (props) => {
    const { modalShowDeleteAccountAction } = props;
    const buttons = (
        <ButtonGroup>
            <Button label="Contact us" onClick={() => { window.open('mailto:support@facelift-bbt.com'); }} />
            <Button warning icon="delete" label="Delete account" onClick={modalShowDeleteAccountAction} />
        </ButtonGroup>
    );
    return (
        <ContentBlock
          headline="Delete account"
          footerRight={buttons}
        >
            <span className={styles.text}>
                It would be a shame to see you go. If we can do anything to make you stay or help you with any issues you have,
                just tell us. We will do our best to make you a satisfied customer.
            </span>
        </ContentBlock>
    );
};

DeleteAccount.propTypes = {
    modalShowDeleteAccountAction: PropTypes.func.isRequired
};

export default connect(null, {
    modalShowDeleteAccountAction: modalShowDeleteAccount
})(DeleteAccount);
