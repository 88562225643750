import {
    makeSelectDateByContext,
    makeAdditionalFilterSelectorsByContext,
    makeSelectProfileSelectorSelectionByContext
} from 'src/selectors/filterSelectors';
import {
    makeSelectMetricSearchResultMetric,
    selectHasSearchResultError,
    selectIsSearchResultLoading
} from 'src/selectors/metricSearch';
import React, { useEffect } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import AnalysisContentHeader from 'src/components/header/AnalysisContentHeader';
import { analysisLastVisitedMetricId } from 'src/actions/analysis';
import AnalysisMetricTile from 'src/components/metricTiles/AnalysisMetricTile';
import Body from 'src/components/Body';
import { connect } from 'react-redux';
import EmptyMetricSkeleton from 'src/components/loadingIndicators/EmptyMetricSkeleton';
import Feedback from 'src/components/feedback/Feedback';
import { makeSelectCustomMetricById } from 'src/selectors/metrics';
import MetricRequestProvider from 'src/components/requestHandling/MetricRequestProvider';
import { metricSearchResultRequest } from 'src/actions/metricSearch';
import PropTypes from 'prop-types';
import ScrollView from 'src/components/ScrollView';
import { selectAccountOptions } from 'src/selectors/loggedInUser';
import ServerDataSingleRequestDispatcher from 'src/components/ServerDataSingleRequestDispatcher';
import styles from 'src/stylesheets/views/analysis/analysis.scss';
import { QUICK_ANALYSIS } from 'src/utils/filterSelectors';
import FilterContextProvider from 'src/components/views/FilterContextProvider';

const settings = {};
const type = 'Metric';

const AnalysisContent = (props) => {
    const {
        metricSearchResultRequestAction,
        searchResultMetric,
        error,
        metricId,
        analysisLastVisitedMetricIdAction,
        selectedProfilesOrGroups,
        selectedDate,
        isLoading,
        accountOptions,
        selectedPostText,
        selectedPostTextExclude,
        selectedPostTag,
        selectedAdCampaign
    } = props;

    useEffect(() => {
        if (!searchResultMetric && !error) {
            metricSearchResultRequestAction(type, metricId);
        }
    }, [metricId, searchResultMetric, error]);

    useEffect(() => {
        analysisLastVisitedMetricIdAction(metricId);
    }, [metricId]);

    let body = '';
    if (isLoading) {
        body = <EmptyMetricSkeleton />;
    } else if (error) {
        body = (
            <div>
                <Feedback content="This metric couldn't be loaded. Please try again later or contact our support team." type="error" />
            </div>
        );
    } else if (searchResultMetric) {
        body = (
            <MetricRequestProvider
              selectedProfilesOrGroups={selectedProfilesOrGroups}
              selectedDate={selectedDate}
              selectedPostText={selectedPostText}
              selectedPostTextExclude={selectedPostTextExclude}
              selectedPostTag={selectedPostTag}
              selectedAdCampaign={selectedAdCampaign}
              metric={searchResultMetric}
              format="json"
            >
                {
                        (serverRequest) => (
                            <ServerDataSingleRequestDispatcher
                              serverRequest={serverRequest}
                              loadingIdentifier={`analysis-${searchResultMetric.id}`}
                            >
                                <div
                                  className={styles.tile}
                                >
                                    <AnalysisMetricTile
                                      requestHash={serverRequest.request.hash}
                                      selectedProfilesOrGroups={selectedProfilesOrGroups}
                                      selectedDate={selectedDate}
                                      accountOptions={accountOptions}
                                      metric={searchResultMetric}
                                      settings={settings}
                                    />
                                </div>
                            </ServerDataSingleRequestDispatcher>
                        )
                    }
            </MetricRequestProvider>
        );
    }
    return (
        <ScrollView key={metricId} dataViewName="analysis-content">
            <FilterContextProvider context={QUICK_ANALYSIS}>
                <AnalysisContentHeader />
                <Body className={styles.flexBody}>
                    {body}
                </Body>
            </FilterContextProvider>
        </ScrollView>
    );
};

AnalysisContent.propTypes = {
    selectedProfilesOrGroups: customPropTypes.profileFilter.isRequired,
    selectedDate: customPropTypes.selectedDate.isRequired,
    accountOptions: customPropTypes.accountOptions.isRequired,
    isLoading: PropTypes.bool.isRequired,
    metricSearchResultRequestAction: PropTypes.func.isRequired,
    searchResultMetric: customPropTypes.metricOrFalse.isRequired,
    error: PropTypes.oneOfType([customPropTypes.dataLoadingError, PropTypes.bool]).isRequired,
    metricId: PropTypes.string.isRequired,
    analysisLastVisitedMetricIdAction: PropTypes.func.isRequired,
    selectedPostText: customPropTypes.postTextFilter.isRequired,
    selectedPostTextExclude: customPropTypes.postTextExcludeFilter.isRequired,
    selectedPostTag: customPropTypes.postTagFilter.isRequired,
    selectedAdCampaign: customPropTypes.adCampaignFilter.isRequired
};

const makeMapStateToProps = () => {
    const selectMetricSearchResultMetric = makeSelectMetricSearchResultMetric();
    const selectCustomMetricById = makeSelectCustomMetricById();
    const selectDateByContext = makeSelectDateByContext();
    const selectProfileSelectorSelectionByContext = makeSelectProfileSelectorSelectionByContext();
    const {
        selectPostTagByContext, selectPostTextByContext, selectPostTextExcludeByContext, selectAdCampaignByContext
    } = makeAdditionalFilterSelectorsByContext();
    return (state, ownProps) => {
        const { metricId } = ownProps.params;
        let searchResultMetric = false;
        let error = false;
        let isLoading = false;
        const customMetric = selectCustomMetricById(state, metricId);
        if (customMetric) {
            searchResultMetric = customMetric;
        } else {
            searchResultMetric = selectMetricSearchResultMetric(state, metricId);
            isLoading = selectIsSearchResultLoading(state, type, metricId);
            error = selectHasSearchResultError(state, type, metricId);
        }
        return {
            searchResultMetric,
            selectedProfilesOrGroups: selectProfileSelectorSelectionByContext(state, QUICK_ANALYSIS),
            selectedDate: selectDateByContext(state, QUICK_ANALYSIS),
            selectedPostText: selectPostTextByContext(state, QUICK_ANALYSIS),
            selectedPostTextExclude: selectPostTextExcludeByContext(state, QUICK_ANALYSIS),
            selectedPostTag: selectPostTagByContext(state, QUICK_ANALYSIS),
            selectedAdCampaign: selectAdCampaignByContext(state, QUICK_ANALYSIS),
            metricId,
            isLoading,
            error,
            accountOptions: selectAccountOptions(state),
        };
    };
};

export default connect(makeMapStateToProps, {
    metricSearchResultRequestAction: metricSearchResultRequest,
    analysisLastVisitedMetricIdAction: analysisLastVisitedMetricId
})(AnalysisContent);
