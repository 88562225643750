import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/views/view.scss';

const View = (props) => {
    const { topNav, body } = props;
    return (
        <div className={styles.wrapper}>
            <div className={styles.topNav}>
                {topNav}
            </div>
            <div className={styles.body}>
                {
                    body
                }
            </div>
        </div>
    );
};

View.propTypes = {
    topNav: PropTypes.node.isRequired,
    body: PropTypes.node.isRequired,
};

export default View;
