import { connect } from 'react-redux';
import { makeSelectDashboardAndFolderIdForSearch } from 'src/selectors/folders';

const withDashboardAndFolderAutocomplete = (WrappedComponent) => {
    const makeMapStateToProps = () => {
        const selectDashboardAndFolderIdsForSearch = makeSelectDashboardAndFolderIdForSearch();
        return (state, ownProps) => ({
            autocompleteSuggestions: selectDashboardAndFolderIdsForSearch(state, ownProps.searchTerm),
        });
    };
    return connect(makeMapStateToProps)(WrappedComponent);
};

export default withDashboardAndFolderAutocomplete;
