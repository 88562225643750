import * as routeActions from 'react-router-redux';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

const redirectTo = '/settings/plan';

const TrialPeriodReminderButton = (props) => {
    const { routePushAction, packageValid } = props;
    const remainingDays = Math.max(moment(packageValid).diff(moment().startOf('day'), 'days'), 0);
    const label = `Trial - ${remainingDays} ${remainingDays > 1 ? 'days' : 'day'} remaining`;
    return (
        <Button
          label={label}
          small
          stretch
          onClick={() => { routePushAction(redirectTo); }}
          reminder
        />
    );
};

TrialPeriodReminderButton.propTypes = {
    packageValid: PropTypes.string.isRequired,
    routePushAction: PropTypes.func.isRequired
};

export default connect(null, {
    routePushAction: routeActions.push
})(TrialPeriodReminderButton);
