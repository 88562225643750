import DiscoverStart from 'src/components/views/discover/base/DiscoverStart';
import React from 'react';

const recommendationIdentifiers = [
    {
        type: 'recommendation',
        identifier: 'newestPublicMetrics',
        title: 'Recently added metrics',
    },
    {
        type: 'recommendation',
        identifier: 'featuredMetrics',
        title: 'Featured metrics',
        payload: '11,170,2,102,77,181'
    },
    {
        type: 'search',
        identifier: 'facebookAdsMetrics',
        title: 'Discover Facebook Ad metrics',
        searchParams: {
            filterBy: 'publicMetrics',
            orderBy: 'mostUsed',
            tagIds: ['1', '18'],
            limit: 6
        }
    }
];

const DiscoverStartPublicMetrics = (props) => <DiscoverStart {...props} recommendationIdentifiers={recommendationIdentifiers} filterBy="publicMetrics" />;

export default DiscoverStartPublicMetrics;
