import {
    BULK_PROFILES_USE_CASES_UPDATE_ERROR,
    BULK_PROFILES_USE_CASES_UPDATE_REQUEST,
    BULK_PROFILES_USE_CASES_UPDATE_SUCCESS
} from 'src/actions/profileUseCases';
import {
    bulkAdAccountsUseCasesUpdateSuccess,
    bulkAdAccountsUseCasesUpdateError,
    bulkAdAccountsUseCasesUpdateRequest
} from 'src/actions/adAccountUseCases';
import { combineReducers } from 'redux';
import { createAsyncStatesReducerForAction } from 'src/reducers/utils';

export const accountUseCasesAsyncStates = combineReducers({
    bulkUseCasesUpdate: createAsyncStatesReducerForAction(
        () => 'bulkUpdate',
        [
            BULK_PROFILES_USE_CASES_UPDATE_REQUEST,
            bulkAdAccountsUseCasesUpdateRequest.type
        ],
        [
            BULK_PROFILES_USE_CASES_UPDATE_SUCCESS,
            BULK_PROFILES_USE_CASES_UPDATE_ERROR,
            bulkAdAccountsUseCasesUpdateError.type,
            bulkAdAccountsUseCasesUpdateSuccess.type
        ]
    )
});
