import classnames from 'classnames';
import { connect } from 'react-redux';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import PropTypes from 'prop-types';
import React from 'react';
import { selectIsAnyUseCaseEnabled } from 'src/selectors/accountUseCases';
import styles from 'src/stylesheets/views/profiles/brokenProfileUseCasesWarning.scss';

const BrokenAccountUseCasesWarning = (props) => {
    const {
        failedAccountCount,
        isAnyUseCaseEnabled,
        label
    } = props;

    let message = 'You haven’t activated any private stats use cases yet.';
    let tooltip = message;
    let error = false;
    if (isAnyUseCaseEnabled) {
        if (failedAccountCount > 0) {
            error = true;
            const profileHasHave = failedAccountCount > 1 ? `${label}s have` : `${label} has`;
            message = ` of your ${profileHasHave} private stats use cases selected that are not covered by your accounts.`;
            tooltip = `${failedAccountCount} ${message}`;
        } else {
            message = ' All of your active private stats use cases are covered, but you can always authenticate more accounts for redundancy.';
            tooltip = message;
        }
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.indicatorSection}>
                <div className={classnames(styles.indicator, { [styles.error]: error })}>
                    {failedAccountCount}
                </div>
            </div>
            <div className={styles.message}>
                <DivWithTooltip tooltip={tooltip}>
                    {message}
                </DivWithTooltip>
            </div>
        </div>
    );
};

BrokenAccountUseCasesWarning.propTypes = {
    failedAccountCount: PropTypes.number.isRequired,
    isAnyUseCaseEnabled: PropTypes.bool.isRequired,
    label: PropTypes.string
};

BrokenAccountUseCasesWarning.defaultProps = {
    label: 'account'
};

const mapStateToProps = (state) => ({
    isAnyUseCaseEnabled: selectIsAnyUseCaseEnabled(state)
});

export default connect(mapStateToProps)(BrokenAccountUseCasesWarning);
