import React from 'react';
import { networkFilterOptions } from 'src/utils/profiles';
import AccountFilterBarForm from 'src/components/forms/AccountFilterBarForm';

export const formName = 'profileFilterForm';

const ProfileFilterBarForm = () => (
    <AccountFilterBarForm
      form={formName}
      networkFilterOptions={networkFilterOptions}
      freeSearchPlaceholder="Search my profiles"
    />
);

export default ProfileFilterBarForm;
