import React, { Component } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import RelativeTime from 'src/components/RelativeTime';
import styles from 'src/stylesheets/views/userNotifications/userNotificationListItem.scss';
import UserNotificationIcon from 'src/components/UserNotificationIcon';
import UserNotificationItemMarker from 'src/components/UserNotificationItemMarker';
import withTooltip from 'src/components/withToolTip';

const UserNotificationItemMarkerWithTooltip = withTooltip(UserNotificationItemMarker);

class UserNotificationListItem extends Component {
    render() {
        const { userNotificationItem, onNotificationClick } = this.props;
        const {
            id, content, wasRead, createTime
        } = userNotificationItem;
        const { type, message } = content;
        const toolTip = wasRead ? 'Mark as Unread' : 'Mark as read';

        return (
            <div className={classnames(styles.wrapper, { [styles.unRead]: !wasRead })}>
                <div
                  className={styles.left}
                  onClick={() => { onNotificationClick(userNotificationItem); }}
                >
                    <div className={styles.content}>
                        <div className={styles.type}>
                            <UserNotificationIcon type={type} className={styles.icon} />
                        </div>
                        <div className={styles.message} dangerouslySetInnerHTML={{ __html: message }} />
                    </div>
                    <div className={styles.createTime}>
                        <RelativeTime date={createTime} thresholdTimestamp={365 * 86400} />
                    </div>
                </div>
                <div className={styles.markAction}>
                    <UserNotificationItemMarkerWithTooltip
                      wasRead={wasRead}
                      id={id}
                      tooltip={toolTip}
                    />
                </div>
            </div>
        );
    }
}

UserNotificationListItem.propTypes = {
    onNotificationClick: PropTypes.func.isRequired,
    userNotificationItem: customPropTypes.userNotificationItem.isRequired
};

export default UserNotificationListItem;
