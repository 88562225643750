import { connect } from 'react-redux';
import { makeSelectDashboardMetricById } from 'src/selectors/dashboardMetrics';
import PropTypes from 'prop-types';
import SampleDashboardMetricDataTile from 'src/components/sampleDashboard/SampleDashboardMetricDataTile';
import { selectAccountOptions } from 'src/selectors/loggedInUser';

import withSampleServerData from 'src/components/withSampleServerData';

const SampleMetricDataTileWithSampleServerData = withSampleServerData(SampleDashboardMetricDataTile);

const makeMapStateToProps = () => {
    const selectDashboardMetricById = makeSelectDashboardMetricById();

    return (state, ownProps) => {
        const dashboardMetric = selectDashboardMetricById(state, ownProps.dashboardMetricId);
        return {
            dashboardMetric,
            accountOptions: selectAccountOptions(state),
            metric: dashboardMetric.metric
        };
    };
};

const ConnectedGridTile = connect(makeMapStateToProps)(SampleMetricDataTileWithSampleServerData);

ConnectedGridTile.propTypes = {
    dashboardMetricId: PropTypes.string.isRequired
};

export default ConnectedGridTile;
