import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import IconButton from 'src/components/buttons/IconButton';
import AccountLimitPopover from 'src/components/popovers/AccountLimitPopover';
import React from 'react';
import { selectSpaceLimits } from 'src/selectors/spaceLimits';
import styles from 'src/stylesheets/limit.scss';
import withPopover from 'src/components/withPopover';
import withTooltip from 'src/components/withToolTip';
import PropTypes from 'prop-types';
import { getUsageFromSpaceLimit, getLimitFromSpaceLimit } from 'src/utils/accounts';

const IconButtonWithPopover = withPopover(withTooltip(IconButton));

const AccountLimit = (props) => {
    const { spaceLimits, label, identifier } = props;
    const accountLimitPopover = <AccountLimitPopover identifier={identifier} />;

    const usage = getUsageFromSpaceLimit(spaceLimits, identifier);
    const limits = getLimitFromSpaceLimit(spaceLimits, identifier);

    return (
        <div className={styles.wrapper}>
            <div className={styles.text}>
                {label} limit: {usage}/{limits}
            </div>
            <IconButtonWithPopover
              icon="info"
              className={styles.icon}
              overlay={accountLimitPopover}
              tooltip="View details"
              tooltipPlacement="bottom"
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    spaceLimits: selectSpaceLimits(state)
});

AccountLimit.propTypes = {
    spaceLimits: customPropTypes.spaceLimits.isRequired,
    label: PropTypes.string.isRequired,
    identifier: customPropTypes.accountTypes.isRequired
};

export default connect(mapStateToProps)(AccountLimit);
