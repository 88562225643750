import React from 'react';
import { makeSelectReportOverviewsByIds } from 'src/selectors/reportOverView';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withRemoveListItemIcon from 'src/components/withRemoveListItemIcon';
import ReportOverviewSidebarItem from 'src/components/views/reports/sidebar/ReportOverviewSidebarItem';
import { listName } from 'src/utils/reportOverviews';
import * as customPropTypes from 'src/customPropTypes';
import styles from 'src/stylesheets/views/reports/reportOverviewSidebarItem.scss';

const ReportOverviewItemWithRemoveListItemIcon = withRemoveListItemIcon(ReportOverviewSidebarItem);

const ReportsOverviewList = (props) => {
    const { reportOverviews } = props;
    return (
        <div>
            {
                reportOverviews.map((reportOverview) => (
                    <div key={reportOverview.id} className={styles.item}>
                        <ReportOverviewItemWithRemoveListItemIcon
                          listName={listName}
                          fieldName={reportOverview.id}
                          reportOverview={reportOverview}
                        />
                    </div>
                ))
            }
        </div>
    );
};

ReportsOverviewList.propTypes = {
    reportOverviewIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    reportOverviews: customPropTypes.reportOverviews.isRequired
};

const makeMapStateToProps = () => {
    const selectReportOverviewsByIds = makeSelectReportOverviewsByIds();
    return (state, ownProps) => ({
        reportOverviews: selectReportOverviewsByIds(state, ownProps.reportOverviewIds)
    });
};

export default connect(makeMapStateToProps)(ReportsOverviewList);
