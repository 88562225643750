import React, { Component } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import * as routeActions from 'react-router-redux';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import classnames from 'classnames';
import { connect } from 'react-redux';
import DiscoverCustomMetricInfo from 'src/components/views/discover/DiscoverCustomMetricInfo';
import DiscoverTileBody from 'src/components/metricSearchResultTile/layout/DiscoverTileBody';
import DiscoverTileFooter from 'src/components/metricSearchResultTile/layout/DiscoverTileFooter';
import DiscoverTileHeader from 'src/components/metricSearchResultTile/layout/DiscoverTileHeader';
import { metricVisualizationImagesUrl } from 'src/config';
import { modalShowAddMetricToDashboard } from 'src/actions/overlays';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/discoverTile.scss';

class DiscoverMetricTile extends Component {
    constructor(props) {
        super(props);
        this.onMetricTileClick = this.onMetricTileClick.bind(this);
    }

    onMetricTileClick() {
        const { routePushAction, metricSearchResult } = this.props;
        const { id } = metricSearchResult;

        routePushAction(`/discover/metric/${id}`);
    }

    render() {
        const {
            metricSearchResult, className, tags, lazyLoad, onAddMetricToDashboardClickAction, loggedInUser
        } = this.props;
        const {
            platformType, isBasedOnPrivateStatistics, name, summary, usage, id, visualizationId, isCustomMetric, visualization
        } = metricSearchResult;

        const buttons = (
            <ButtonGroup className={styles.buttons}>
                <Button small icon="plus" label="Add to dashboard" onClick={() => { onAddMetricToDashboardClickAction(id, visualization.type === 'table'); }} />
            </ButtonGroup>
        );

        return (
            <div className={classnames(styles.tile, className, 'globalTileForHover')} onClick={this.onMetricTileClick}>
                <DiscoverTileHeader
                  platformType={platformType}
                  isBasedOnPrivateStatistics={isBasedOnPrivateStatistics}
                  name={name}
                />
                <DiscoverTileBody
                  imageUrl={`${metricVisualizationImagesUrl}${visualizationId}.svg`}
                  summary={summary}
                  tags={tags}
                  lazyLoad={lazyLoad}
                />
                {
                    isCustomMetric
                    && (
                    <div>
                        <DiscoverCustomMetricInfo loggedInUser={loggedInUser} customMetric={metricSearchResult} />
                    </div>
                    )
                }
                <DiscoverTileFooter
                  usage={usage}
                  buttons={buttons}
                />
            </div>
        );
    }
}

DiscoverMetricTile.contextTypes = {
    router: PropTypes.object.isRequired
};

DiscoverMetricTile.propTypes = {
    routePushAction: PropTypes.func.isRequired,
    onAddMetricToDashboardClickAction: PropTypes.func.isRequired,
    metricSearchResult: customPropTypes.searchResultMetric.isRequired,
    tags: customPropTypes.tags.isRequired,
    className: PropTypes.string,
    lazyLoad: PropTypes.bool,
    loggedInUser: customPropTypes.user.isRequired
};

DiscoverMetricTile.defaultProps = {
    className: '',
    lazyLoad: true
};

export default connect(null, {
    routePushAction: routeActions.push,
    onAddMetricToDashboardClickAction: modalShowAddMetricToDashboard
})(DiscoverMetricTile);
