import AdminSettingsNav from 'src/components/navigations/subNavs/AdminSettingsNav';
import PropTypes from 'prop-types';
import React from 'react';
import View from 'src/components/views/View';
import withLastMainMenuLocationUpdater from 'src/components/views/withLastMainMenuLocationUpdater';
import { USER_ADMIN } from 'src/utils/routes';
import * as customPropTypes from 'src/customPropTypes';

const ViewWithLastMainMenuLocationUpdater = withLastMainMenuLocationUpdater(View);

const AdminSettingsView = (props) => {
    const { content, location } = props;
    return (
        <ViewWithLastMainMenuLocationUpdater
          topNav={<AdminSettingsNav />}
          context={USER_ADMIN}
          location={location}
          body={content}
        />
    );
};

AdminSettingsView.propTypes = {
    location: customPropTypes.location.isRequired,
    content: PropTypes.node.isRequired
};

export default AdminSettingsView;
