import React from 'react';
import DetailText from 'src/components/views/DetailText';
import styles from 'src/stylesheets/views/integrations/dataPushTaskDetailPage.scss';
import * as customPropTypes from 'src/customPropTypes';
import { getDestinationTypeText } from 'src/utils/dataPushTasks';
import { connect } from 'react-redux';
import * as overlayAction from 'src/actions/overlays';
import PropTypes from 'prop-types';

const GoogleBigQueryDataPushTaskDestination = (props) => {
    const { googleBigQueryDataPushTaskDestination, modalShowAceEditorAction } = props;
    const { destinationLocation, googleBigQueryTableSchema, destinationType } = googleBigQueryDataPushTaskDestination;
    const destinationTypeText = getDestinationTypeText(destinationType);

    const schema = googleBigQueryTableSchema
        ? <a onClick={() => { modalShowAceEditorAction('Table schema', googleBigQueryTableSchema, 'json'); }}>View schema</a>
        : null;

    return (
        <div>
            <DetailText label="Type" className={styles.detailText}>{destinationTypeText}</DetailText>
            <DetailText label="Table ID" className={styles.detailText}>{destinationLocation}</DetailText>
            <DetailText label="Schema" moreInfo={schema}>{googleBigQueryTableSchema ? 'Custom schema' : '1:1 schema mapping'}</DetailText>
        </div>
    );
};

GoogleBigQueryDataPushTaskDestination.propTypes = {
    googleBigQueryDataPushTaskDestination: customPropTypes.googleBigQueryDataPushTaskDestination.isRequired,
    modalShowAceEditorAction: PropTypes.func.isRequired
};

export default connect(null, {
    modalShowAceEditorAction: overlayAction.modalShowAceEditor
})(GoogleBigQueryDataPushTaskDestination);
