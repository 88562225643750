import React, { useEffect } from 'react';
import { makeSelectMetricById, makeSelectGetMetricLoadingState } from 'src/selectors/metrics';
import { connect } from 'react-redux';
import * as metricActions from 'src/actions/metric';
import ErrorView from 'src/components/errors/ErrorView';
import EmptyMetricBuilderForm from 'src/components/loadingIndicators/EmptyMetricBuilderForm';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';

const withMetricLoader = (WrappedComponent) => {
    const WithMetricLoader = (props) => {
        const {
            metric,
            metricId,
            metricGetRequestAction,
            metricLoadingState
        } = props;
        useEffect(() => {
            if (metricId && !metric) {
                metricGetRequestAction(metricId);
            }
        }, []);

        const { isPending, error } = metricLoadingState;

        if (error) {
            return <ErrorView error={error} headline="Metric not found" />;
        }

        if (isPending) {
            return <EmptyMetricBuilderForm />;
        }

        return <WrappedComponent {...props} />;
    };

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    WithMetricLoader.displayName = `withMetricLoader(${wrappedComponentName})`;

    WithMetricLoader.propTypes = {
        metric: PropTypes.oneOfType([customPropTypes.metric, PropTypes.bool]).isRequired,
        metricId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
        metricGetRequestAction: PropTypes.func.isRequired,
        metricLoadingState: customPropTypes.asyncDataLoadingState.isRequired
    };

    const makeMapStateToProps = () => {
        const selectMetricById = makeSelectMetricById();
        const selectGetMetricLoadingState = makeSelectGetMetricLoadingState();
        return (state, ownProps) => {
            const { metricId } = ownProps.params;
            return {
                metric: selectMetricById(state, metricId),
                metricId: metricId || false,
                metricLoadingState: selectGetMetricLoadingState(state, metricId)
            };
        };
    };

    return connect(makeMapStateToProps, {
        metricGetRequestAction: metricActions.metricGetRequest
    })(WithMetricLoader);
};

export default withMetricLoader;
