import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeSelectIsProfileHealthy } from 'src/selectors/profileHealth';
import styles from 'src/stylesheets/views/profiles/profileHealthBar.scss';
import classnames from 'classnames';

const ProfileHealthBar = ({ isHealthy }) => <div className={classnames(styles.wrapper, { [styles.error]: !isHealthy })} />;

ProfileHealthBar.propTypes = {
    profileId: PropTypes.string.isRequired,
    isHealthy: PropTypes.bool.isRequired
};

const makeStateToProps = () => {
    const selectIsProfileHealthy = makeSelectIsProfileHealthy();
    return (state, ownProps) => ({
        isHealthy: selectIsProfileHealthy(state, ownProps.profileId)
    });
};

export default connect(makeStateToProps)(ProfileHealthBar);
