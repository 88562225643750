import * as customPropTypes from 'src/customPropTypes';
import _isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { selectSpaceLimits } from 'src/selectors/spaceLimits';
import SpaceLimitFutureSwaps from 'src/components/SpaceLimitFutureSwaps';
import SpaceLimitProgressBars from 'src/components/SpaceLimitProgressBars';
import styles from 'src/stylesheets/limitStatus.scss';
import ProfileLimitsLinks from 'src/components/profileLimits/ProfileLimitsLinks';

const AccountLimitsStatus = (props) => {
    const { spaceLimits, showLimitsFor, openPlanInNewTab } = props;
    const { usage } = spaceLimits;

    return (
        <div>
            <SpaceLimitProgressBars spaceLimits={spaceLimits} showLimitsFor={showLimitsFor} />
            {
                showLimitsFor.indexOf('profileSwaps') >= 0 && !_isEmpty(usage.futureSwaps)
                && (
                <div className={styles.futureSwaps}>
                    <SpaceLimitFutureSwaps horizontal futureSwaps={usage.futureSwaps} />
                </div>
                )
            }
            <ProfileLimitsLinks openPlanInNewTab={openPlanInNewTab} />
        </div>
    );
};

const mapStateToProps = (state) => ({
    spaceLimits: selectSpaceLimits(state)
});

AccountLimitsStatus.propTypes = {
    showLimitsFor: PropTypes.arrayOf(PropTypes.string).isRequired,
    spaceLimits: customPropTypes.spaceLimits.isRequired,
    openPlanInNewTab: PropTypes.bool
};

AccountLimitsStatus.defaultProps = {
    openPlanInNewTab: false
};

export default connect(mapStateToProps)(AccountLimitsStatus);
