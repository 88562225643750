import ButtonGroup from 'src/components/buttons/ButtonGroup';
import DataWarningButton from 'src/components/buttons/DataWarningButton';
import OpenAllPostsInAnalysisButton from 'src/components/buttons/OpenAllPostsInAnalysisButton';
import Feedback from 'src/components/feedback/Feedback';
import ContextAwareAdditionalFilterSelector from 'src/components/filterSelectors/ContextAwareAdditionalFilterSelector';
import BottomRow from 'src/components/header/layout/BottomRow';
import Column from 'src/components/header/layout/Column';
import PostTagWarningProvider from 'src/components/PostTagWarningProvider';
import { connect } from 'react-redux';
import ContextAwareDateSelector from 'src/components/filterSelectors/ContextAwareDateSelector';
import ContextAwareProfileSelector from 'src/components/filterSelectors/ContextAwareProfileSelector';
import Header from 'src/components/header/layout/Header';
import PropTypes from 'prop-types';
import React from 'react';
import { selectHasStickyHeader } from 'src/selectors/stickyHeader';
import SelectorButtonGroup from 'src/components/buttons/SelectorButtonGroup';
import TopRow from 'src/components/header/layout/TopRow';
import PageHeadline from 'src/components/header/layout/PageHeadline';
import PostsMasterCheckbox from 'src/components/views/postTags/postTagging/PostsMasterCheckbox';
import { makeSelectAllPostIdsAcrossPages, makeSelectPostSearchState } from 'src/selectors/posts';
import * as customPropTypes from 'src/customPropTypes';
import PostSortOrderWithPageScroller from 'src/components/views/postTags/postTagging/PostSortOrderWithPageScroller';
import styles from 'src/stylesheets/headers/postsHeader.scss';
import classnames from 'classnames';
import PostRefreshButton from 'src/components/buttons/PostRefreshButton';
import { POST_TAGGING } from 'src/utils/filterSelectors';
import _get from 'lodash/get';

const placeholderIfFixedStyle = {
    height: 190
};

const PostsHeader = (props) => {
    const {
        sticky, listName, postsSearchState, searchRequestInput, resultPostIds
    } = props;
    const { isPending } = postsSearchState;
    if (sticky && resultPostIds.length > 0) {
        return (
            <div>
                <div style={placeholderIfFixedStyle} />
                <Header fixed withSidebarOpen>
                    <TopRow>
                        <div className={styles.row}>
                            <div className={styles.globalCheckbox}>
                                <PostsMasterCheckbox listName={listName} disabled={isPending} />
                            </div>
                            <div className={styles.orderBy}>
                                <PostSortOrderWithPageScroller disabled={isPending} searchRequestInput={searchRequestInput} />
                            </div>
                        </div>
                    </TopRow>
                </Header>
            </div>
        );
    }

    const adAccountIds = _get(searchRequestInput, 'filter.profile.adAccountIds', []);
    const adAccountsSelected = adAccountIds.length > 0;

    return (
        <Header>
            <TopRow>
                <Column flexGrow>
                    <PageHeadline>Post tags</PageHeadline>
                </Column>
            </TopRow>
            <BottomRow>
                <Column flexGrow>
                    <div className={styles.globalSelectors}>
                        <div className={styles.filter}>
                            <SelectorButtonGroup
                              dateSelector={<ContextAwareDateSelector disabled={isPending} showInterval={false} />}
                              profileSelector={<ContextAwareProfileSelector disabled={isPending} />}
                              filterSelector={<ContextAwareAdditionalFilterSelector disabled={isPending} />}
                            />
                        </div>
                        <ButtonGroup>
                            <PostTagWarningProvider>
                                {
                                    (warnings) => <DataWarningButton warnings={warnings} disabled={isPending} />
                                }
                            </PostTagWarningProvider>
                            <PostRefreshButton disabled={isPending} />
                            <OpenAllPostsInAnalysisButton filterContext={POST_TAGGING} />
                        </ButtonGroup>
                    </div>
                    {
                        adAccountsSelected
                        && (
                            <div className={classnames({ [styles.feedback]: resultPostIds.length > 0 })}>
                                <Feedback
                                  content="You have selected at least one ad account. Tagging ads is not yet available."
                                  type="info"
                                />
                            </div>
                        )
                    }
                    <div className={classnames(styles.row, { [styles.hidden]: resultPostIds.length === 0 })}>
                        <div className={styles.globalCheckbox}>
                            <PostsMasterCheckbox listName={listName} disabled={isPending} />
                        </div>
                        <div className={styles.orderBy}>
                            <PostSortOrderWithPageScroller disabled={isPending} searchRequestInput={searchRequestInput} />
                        </div>
                    </div>
                </Column>
            </BottomRow>
        </Header>
    );
};

PostsHeader.propTypes = {
    sticky: PropTypes.bool,
    stickyHeaderIdentifier: PropTypes.string.isRequired,
    resultPostIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    listName: PropTypes.string.isRequired,
    postsSearchState: customPropTypes.asyncDataLoadingState.isRequired,
    searchRequestInput: customPropTypes.postSearchRequestInput.isRequired
};

PostsHeader.defaultProps = {
    sticky: false
};

const makeMapStateToProps = () => {
    const selectPostSearchState = makeSelectPostSearchState();
    const selectAllPostIdsAcrossPages = makeSelectAllPostIdsAcrossPages();
    return (state, ownProps) => ({
        postsSearchState: selectPostSearchState(state),
        sticky: selectHasStickyHeader(state, ownProps.stickyHeaderIdentifier),
        resultPostIds: selectAllPostIdsAcrossPages(state),
    });
};

export default connect(makeMapStateToProps)(PostsHeader);
