import { connect } from 'react-redux';
import { makeSelectIsOneItemSelected } from 'src/selectors/lists';
import { modalsShowAdAccountDetails } from 'src/actions/overlays';
import PropTypes from 'prop-types';
import { makeSelectAdAccountById } from 'src/selectors/adAccounts';
import AdAccountListItem from 'src/components/views/adAccounts/AdAccountListItem';

const makeMapStateToProps = () => {
    const selectIsOneItemSelected = makeSelectIsOneItemSelected();
    const selectAdAccountById = makeSelectAdAccountById();
    return (state, ownProps) => {
        const { adAccountId, listName } = ownProps;
        const adAccount = selectAdAccountById(state, adAccountId);
        return {
            adAccount,
            listName,
            buttonsDisabled: selectIsOneItemSelected(state, listName)
        };
    };
};

const AdAccountListItemContainer = connect(makeMapStateToProps, {
    modalsShowAdAccountDetailsAction: modalsShowAdAccountDetails
})(AdAccountListItem);

AdAccountListItemContainer.propTypes = {
    adAccountId: PropTypes.string.isRequired
};

export default AdAccountListItemContainer;
