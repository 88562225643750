import React from 'react';
import _has from 'lodash/has';
import { getNotificationLinkFromNotificationContent } from 'src/utils/routes';
import * as userNotificationActions from 'src/actions/userNotification';
import {
    modalsShowProfileDetails,
    modalsShowAuthTransactionDetail,
    modalsShowProfileSearch,
    modalShowAdAccountAdd
} from 'src/actions/overlays';
import { connect } from 'react-redux';
import * as routerActions from 'react-router-redux';
import PropTypes from 'prop-types';
import _omit from 'lodash/omit';

const withUserNotificationClickHandling = (WrappedComponent) => {
    const WithUserNotificationClickHandling = (props) => {
        const handleOnNotificationItemClick = (userNotificationItem) => {
            const { id, content, wasRead } = userNotificationItem;
            const {
                routerPushAction,
                markUserNotificationAction,
                modalsShowProfileDetailsAction,
                modalsShowAuthTransactionDetailAction,
                modalsShowProfileSearchAction,
                modalShowAdAccountAddAction
            } = props;
            if (!wasRead) {
                markUserNotificationAction(id);
            }
            if (_has(content, 'metaData.profileId')) {
                modalsShowProfileDetailsAction(content.metaData.profileId);
            } else if (_has(content, 'metaData.authTransactionId')) {
                modalsShowAuthTransactionDetailAction(content.metaData.authTransactionId);
            } else if (_has(content, 'metaData.addNewAuthAccounts') && content.metaData.addNewAuthAccounts === true) {
                if (content.metaData.accountType === 'authProfile') {
                    modalsShowProfileSearchAction();
                } else {
                    modalShowAdAccountAddAction();
                }
            } else {
                const link = getNotificationLinkFromNotificationContent(content);
                routerPushAction(link);
            }
        };

        const cleanedProps = _omit(props, 'modalShowAdAccountAddAction', 'modalsShowProfileSearchAction', 'modalsShowAuthTransactionDetailAction', 'modalsShowProfileDetailsAction', 'markUserNotificationAction');

        return <WrappedComponent onNotificationClick={handleOnNotificationItemClick} {...cleanedProps} />;
    };

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    WithUserNotificationClickHandling.displayName = `withTagClickBehavior(${wrappedComponentName})`;

    WithUserNotificationClickHandling.propTypes = {
        routerPushAction: PropTypes.func.isRequired,
        markUserNotificationAction: PropTypes.func.isRequired,
        modalsShowProfileDetailsAction: PropTypes.func.isRequired,
        modalsShowAuthTransactionDetailAction: PropTypes.func.isRequired,
        modalsShowProfileSearchAction: PropTypes.func.isRequired,
        modalShowAdAccountAddAction: PropTypes.func.isRequired
    };

    return connect(null, {
        routerPushAction: routerActions.push,
        markUserNotificationAction: userNotificationActions.markUserNotification,
        modalsShowProfileDetailsAction: modalsShowProfileDetails,
        modalsShowAuthTransactionDetailAction: modalsShowAuthTransactionDetail,
        modalsShowProfileSearchAction: modalsShowProfileSearch,
        modalShowAdAccountAddAction: modalShowAdAccountAdd
    })(WithUserNotificationClickHandling);
};

export default withUserNotificationClickHandling;
