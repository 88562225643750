import React, { Component } from 'react';
import { recommendationsLoadRequest, recommendationsSearchLoadRequest } from 'src/actions/recommendations';
import * as customPropTypes from 'src/customPropTypes';
import _get from 'lodash/get';
import _set from 'lodash/set';
import { connect } from 'react-redux';
import { convertFilterByToRoute } from 'src/utils/discoverUtiles';
import { makeSelectRecommendationsByIdentifiers } from 'src/selectors/recommandations';
import PropTypes from 'prop-types';

const generateLinkToSearch = (searchParams) => {
    if (searchParams) {
        const tagIds = _get(searchParams, 'tagIds', []);
        const orderBy = _get(searchParams, 'orderBy', false);
        const filterBy = _get(searchParams, 'filterBy', false);
        const link = { pathname: `/discover/${convertFilterByToRoute(filterBy)}/search` };

        if (tagIds) {
            _set(link, 'query.search', tagIds.map((tagId) => `id-${tagId}`));
        }
        if (orderBy !== false) {
            _set(link, 'query.orderBy', orderBy);
        }
        return link;
    }
    return false;
};

const withRecommendationsLoading = (WrappedComponent, type = 'recommendation') => {
    class WithRecommendationsLoading extends Component {
        componentDidMount() {
            const {
                recommendations,
                identifier,
                recommendationsLoadRequestAction,
                searchParams,
                payload
            } = this.props;
            if (!recommendations.data && !recommendations.error) {
                if (type === 'search') {
                    recommendationsLoadRequestAction(identifier, searchParams);
                } else {
                    recommendationsLoadRequestAction(identifier, payload);
                }
            }
        }

        render() {
            const {
                title,
                recommendations,
                autoPlay,
                searchParams
            } = this.props;

            const sharedProps = {
                isLoading: recommendations.loading,
                error: recommendations.error,
                title,
                autoPlay,
                results: recommendations.data
            };
            if (type === 'search') {
                sharedProps.showAllLink = generateLinkToSearch(searchParams);
            }

            return <WrappedComponent {...sharedProps} />;
        }
    }

    const propTypes = {
        identifier: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        recommendations: customPropTypes.recommendations.isRequired,
        recommendationsLoadRequestAction: PropTypes.func.isRequired,
        autoPlay: PropTypes.bool,
        searchParams: PropTypes.shape({
            tagIds: PropTypes.arrayOf(PropTypes.string).isRequired,
            freeTextTags: PropTypes.arrayOf(PropTypes.string),
            filterBy: PropTypes.string,
            orderBy: PropTypes.string,
            limit: PropTypes.number
        }),
        payload: PropTypes.string
    };

    WithRecommendationsLoading.propTypes = propTypes;
    WithRecommendationsLoading.defaultProps = {
        autoPlay: false
    };

    const makeMapStateToProps = () => {
        const selectRecommendationsByIdentifiers = makeSelectRecommendationsByIdentifiers();

        return function mapStateToProps(state, ownProps) {
            const recommendations = selectRecommendationsByIdentifiers(state, ownProps.identifier);
            return {
                recommendations
            };
        };
    };

    const dispatchToProps = {
        recommendationsLoadRequestAction: type === 'search' ? recommendationsSearchLoadRequest : recommendationsLoadRequest
    };

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    WithRecommendationsLoading.displayName = `withRecommendationsLoading(${type})(${wrappedComponentName})`;

    return connect(makeMapStateToProps, dispatchToProps)(WithRecommendationsLoading);
};

export default withRecommendationsLoading;
