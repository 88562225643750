import { createSelector } from 'reselect';
import { createShallowEqualSelector } from './utils';

const getPaginationFromStore = (state) => state.tablePagination;

export const makeSelectPaginationAndSorting = () => createSelector(
    [
        getPaginationFromStore,
        (_, identifier) => identifier
    ],
    (pagination, identifier) => pagination[identifier]
);

export const makeSelectPaginationAndSortingForMultipleIdentifier = () => createShallowEqualSelector(
    [
        (state, identifiers) => {
            const paginationAndSortingByIdentifier = {};
            identifiers.forEach((identifier) => {
                const paginationAndSortingForIdentifier = getPaginationFromStore(state)[identifier];
                if (paginationAndSortingForIdentifier) {
                    Object.assign(paginationAndSortingByIdentifier, { [identifier]: paginationAndSortingForIdentifier });
                }
            });

            return paginationAndSortingByIdentifier;
        }
    ],
    (paginationAndSortingSlice) => paginationAndSortingSlice
);
