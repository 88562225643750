import BillingAddress from 'src/components/BillingAddress';
import CreditCard from 'src/components/views/settings/billing/CreditCard';
import React from 'react';
import TwoColumnLayout from 'src/components/TwoColumnLayout';
import * as customPropTypes from 'src/customPropTypes';

const PaymentMethodBody = (props) => {
    const { creditCard, billingAddress } = props;
    return (
        <TwoColumnLayout>
            <CreditCard creditCard={creditCard} />
            <BillingAddress billingAddress={billingAddress} />
        </TwoColumnLayout>
    );
};

PaymentMethodBody.propTypes = {
    creditCard: customPropTypes.creditCard.isRequired,
    billingAddress: customPropTypes.billingAddress.isRequired
};

export default PaymentMethodBody;
