import { GET_DATA_SOURCES_SUCCESS, TOGGLE_DATA_SOURCE, TOGGLE_DATA_SOURCE_COLUMN_DESCRIPTION } from 'src/actions/dataSources';
import _includes from 'lodash/includes';

export function dataSourceListStatus(state = {}, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_DATA_SOURCES_SUCCESS: {
            const { dataSources } = payload;
            const dataSourcesStatus = {};
            Object.keys(dataSources).forEach((id) => {
                const dataSource = dataSources[id];
                dataSourcesStatus[dataSource.name] = { visible: false, visibleColumns: [] };
            });
            return Object.assign({}, state, dataSourcesStatus);
        }
        case TOGGLE_DATA_SOURCE: {
            const { name } = payload;
            const oldDataSource = Object.assign({}, state[name]);
            const newDataSource = Object.assign({}, oldDataSource, { visible: !oldDataSource.visible });
            return Object.assign({}, state, { [name]: newDataSource });
        }
        case TOGGLE_DATA_SOURCE_COLUMN_DESCRIPTION: {
            const { name, column } = payload;
            const oldDataSource = Object.assign({}, state[name]);
            let visibleColumns = [...oldDataSource.visibleColumns];
            if (!_includes(visibleColumns, column)) {
                visibleColumns = [...visibleColumns, column];
            } else {
                visibleColumns.splice(visibleColumns.indexOf(column), 1);
            }
            const newDataSource = Object.assign({}, oldDataSource, { visibleColumns });
            return Object.assign({}, state, { [name]: newDataSource });
        }
        default:
            return state;
    }
}
