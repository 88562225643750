import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getWarnings } from 'src/selectors/posts';

const PostTagWarningProvider = ({ children, warnings }) => children(warnings);

PostTagWarningProvider.propTypes = {
    children: PropTypes.func.isRequired,
    warnings: customPropTypes.postSearchResponseWarnings.isRequired
};

const mapStateToProps = (state) => ({
    warnings: getWarnings(state)
});

export default connect(mapStateToProps)(PostTagWarningProvider);
