import PropTypes from 'prop-types';
import React from 'react';
import RecommendationsCarousel from 'src/components/recommendation/RecommendationsCarousel';

import withRecommendationsLoading from 'src/components/recommendation/withRecommendationsLoading';

const RecommendationsCarouselWithRecommendationsLoading = withRecommendationsLoading(RecommendationsCarousel);
const RecommendationsCarouselWithRecommendationsSearchLoading = withRecommendationsLoading(RecommendationsCarousel, 'search');

const RecommendationsHandler = ({ identifiers }) => (
    <div>
        {
            identifiers.map((identifier) => {
                const autoPlay = identifier.autoPlay || false;
                if (identifier.type === 'recommendation') {
                    return (
                        <RecommendationsCarouselWithRecommendationsLoading
                          key={identifier.identifier}
                          identifier={identifier.identifier}
                          payload={identifier.payload || ''}
                          title={identifier.title}
                          autoPlay={autoPlay}
                        />
                    );
                } if (identifier.type === 'search') {
                    return (
                        <RecommendationsCarouselWithRecommendationsSearchLoading
                          key={identifier.identifier}
                          identifier={identifier.identifier}
                          title={identifier.title}
                          autoPlay={autoPlay}
                          searchParams={identifier.searchParams}
                        />
                    );
                }
                return null;
            })
        }
    </div>
);

RecommendationsHandler.propTypes = {
    identifiers: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default RecommendationsHandler;
