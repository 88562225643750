import * as customPropTypes from 'src/customPropTypes';
import Body from 'src/components/Body';
import { connect } from 'react-redux';
import DataPushTaskHeader from 'src/components/header/DataPushTaskHeader';
import DataPushTaskLogsContainer from 'src/components/views/integrations/DataPushTaskLogsContainer';
import Footer from 'src/components/Footer';
import React from 'react';
import ScrollView from 'src/components/ScrollView';
import styles from 'src/stylesheets/views/integrations/dataPushTaskDetailPage.scss';
import withStickyHeaderHandling from 'src/components/withStickyHeaderHandling';
import withScrollPosition from 'src/components/withScrollPosition';
import PropTypes from 'prop-types';
import DataPushTaskLoader from 'src/components/views/integrations/DataPushTaskLoader';
import DataPushTaskLoading from 'src/components/loadingIndicators/DataPushTaskLoading';
import DataPushTaskDestinationContainer from 'src/components/views/integrations/dataPushTasks/dataPushTaskDetail/dataPushTaskDestination/DataPushTaskDestinationContainer';
import DataPushTask from 'src/components/views/integrations/dataPushTasks/dataPushTaskDetail/DataPushTask';

const ScrollViewWithStickyHeaderAndScrollPosition = withStickyHeaderHandling(withScrollPosition(ScrollView, 'dataPushTaskDetail'));

const DataPushTaskDetail = (props) => {
    const { dataPushTaskId, location } = props;
    return (
        <DataPushTaskLoader loadingScreen={<DataPushTaskLoading />} dataPushTaskId={dataPushTaskId}>
            {
                (dataPushTask) => {
                    const { id } = dataPushTask;
                    const stickyIdentifier = `data-push-${id}`;
                    return (
                        <ScrollViewWithStickyHeaderAndScrollPosition
                          scrollPositionIdentifier={stickyIdentifier}
                          locationKey={location.key}
                          dataViewName="data-push-task"
                          stickyHeaderIdentifier={stickyIdentifier}
                        >
                            <DataPushTaskHeader
                              dataPushTask={dataPushTask}
                              stickyHeaderIdentifier={stickyIdentifier}
                            />
                            <Body>
                                <div className={styles.wrapper}>
                                    <div className={styles.left}>
                                        <DataPushTask dataPushTask={dataPushTask} />
                                    </div>
                                    <div className={styles.right}>
                                        {
                                            dataPushTask.destinationId !== null && (
                                                <DataPushTaskDestinationContainer destinationId={dataPushTask.destinationId} />
                                            )
                                        }
                                        {
                                            dataPushTask.destinationId === null && (
                                                <div>Destination is not setup</div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className={styles.logs}>
                                    <DataPushTaskLogsContainer dataPushTask={dataPushTask} />
                                </div>
                            </Body>
                            <Footer />
                        </ScrollViewWithStickyHeaderAndScrollPosition>
                    );
                }
            }
        </DataPushTaskLoader>
    );
};

DataPushTaskDetail.propTypes = {
    location: customPropTypes.location.isRequired,
    dataPushTaskId: PropTypes.string.isRequired
};

const mapStateToProps = (state, ownProps) => {
    const { dataPushTaskId } = ownProps.params;
    return {
        dataPushTaskId
    };
};

export default connect(mapStateToProps)(DataPushTaskDetail);
