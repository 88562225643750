import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/tokenSearch/tag.scss';

const Tag = (props) => {
    const {
        tag, onClick, onMetaKeyClick, onCloseClick
    } = props;
    const { name } = tag;

    const handleTagClick = (e) => {
        if (!!onMetaKeyClick && e.metaKey) {
            onMetaKeyClick([tag]);
        } else if (onClick) {
            onClick(tag);
        }
    };

    const handleCloseClick = () => {
        if (onCloseClick) {
            onCloseClick(tag);
        }
    };

    const withCloseButton = !!onCloseClick;
    const withClickBehaviour = !!onClick || !!onMetaKeyClick;

    return (
        <button
          className={classnames(styles.tag, {
              [styles.withCloseButton]: withCloseButton,
              [styles.withClickBehaviour]: withClickBehaviour
          })}
          onClick={handleTagClick}
          type="button"
        >
            {name}
            {
                withCloseButton
                    && (
                    <span
                      className={styles.closeButton}
                      onClick={handleCloseClick}
                      role="button"
                    >
                        &times;
                    </span>
                    )
            }
        </button>
    );
};

Tag.propTypes = {
    tag: customPropTypes.tagOrCustomTag.isRequired,
    onClick: PropTypes.func,
    onMetaKeyClick: PropTypes.func,
    onCloseClick: PropTypes.func
};

export default Tag;
