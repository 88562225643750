export const RECOMMENDATIONS_LOAD_REQUEST = 'RECOMMENDATIONS_LOAD_REQUEST';
export const RECOMMENDATIONS_LOAD_SUCCESS = 'RECOMMENDATIONS_LOAD_SUCCESS';
export const RECOMMENDATIONS_LOAD_ERROR = 'RECOMMENDATIONS_LOAD_ERROR';
export const RECOMMENDATIONS_SEARCH_LOAD_REQUEST = 'RECOMMENDATIONS_SEARCH_LOAD_REQUEST';
export const RECOMMENDATIONS_SEARCH_LOAD_SUCCESS = 'RECOMMENDATIONS_SEARCH_LOAD_SUCCESS';
export const RECOMMENDATIONS_SEARCH_LOAD_ERROR = 'RECOMMENDATIONS_SEARCH_LOAD_ERROR';

export const recommendationsLoadRequest = (identifier, payload) => ({
    type: RECOMMENDATIONS_LOAD_REQUEST,
    payload: { identifier, payload }
});

export const recommendationsLoadSuccess = (identifier, results, resultsForEntities) => ({
    type: RECOMMENDATIONS_LOAD_SUCCESS,
    payload: { identifier, results, resultsForEntities }
});

export const recommendationsLoadError = (identifier, error) => ({
    type: RECOMMENDATIONS_LOAD_ERROR,
    payload: { identifier, error }
});

export const recommendationsSearchLoadRequest = (identifier, searchParams) => ({
    type: RECOMMENDATIONS_SEARCH_LOAD_REQUEST,
    payload: { identifier, searchParams }
});

export const recommendationsSearchLoadSuccess = (identifier, results, resultsForEntities) => ({
    type: RECOMMENDATIONS_SEARCH_LOAD_SUCCESS,
    payload: { identifier, results, resultsForEntities }
});

export const recommendationsSearchLoadError = (identifier, error) => ({
    type: RECOMMENDATIONS_SEARCH_LOAD_ERROR,
    payload: { identifier, error }
});
