import DataWarningButton from 'src/components/buttons/DataWarningButton';
import MetricWarningProvider from 'src/components/MetricWarningProvider';
import * as customPropTypes from 'src/customPropTypes';
import * as modalActions from 'src/actions/overlays';
import AnalysisFavButton from 'src/components/views/analysis/AnalysisFavButton';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import classnames from 'classnames';
import { connect } from 'react-redux';
import MetricExportButton from 'src/components/exportButtons/MetricExportButton';
import MetricNameHeaderWithIcons from 'src/components/MetricNameHeaderWithIcons';
import MetricOpenInPopoverContainer from 'src/components/popovers/MetricOpenInPopoverContainer';
import MetricRefreshButton from 'src/components/buttons/MetricRefreshButton';
import MetricRequestContext from 'src/components/context/MetricRequestContext';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/metricHeader.scss';
import withPopover from 'src/components/withPopover';
import withTooltip from 'src/components/withToolTip';
import { QUICK_ANALYSIS } from 'src/utils/filterSelectors';

const ButtonWithTooltip = withTooltip(Button);
const ButtonWithPopoverAndTooltip = withPopover(ButtonWithTooltip);

const AnalysisMetricDataHeader = (props) => {
    const { metric, onAddMetricToDashboardClickAction } = props;
    const metricOpenInPopover = <MetricOpenInPopoverContainer metric={metric} context={QUICK_ANALYSIS} />;
    return (
        <div className={styles.metricHeader}>
            <div className={classnames(styles.topRow, styles.analysis)}>
                <div className={styles.left}>
                    <MetricNameHeaderWithIcons
                      name={metric.name}
                      platformType={metric.platformType}
                      isCustomMetric={metric.isCustomMetric}
                      isBasedOnPrivateStatistics={metric.isBasedOnPrivateStatistics}
                    />
                </div>
                <div className={classnames(styles.right)}>
                    <MetricRequestContext.Consumer>
                        {
                            (serverRequest) => (
                                <ButtonGroup>
                                    <MetricWarningProvider requestHash={serverRequest.request.hash}>
                                        {(warnings) => (
                                            <DataWarningButton
                                              warnings={warnings}
                                              small
                                            />
                                        )}
                                    </MetricWarningProvider>
                                    <MetricRefreshButton
                                      small
                                      serverDataHash={serverRequest.request.hash}
                                      metricRequestIdentifier={serverRequest.request.identifier}
                                    />
                                    <AnalysisFavButton
                                      metricId={metric.id}
                                      tooltip="check as favorite"
                                    />
                                    <ButtonWithTooltip
                                      icon="plus"
                                      small
                                      tooltip="Add to dashboard"
                                      onClick={() => { onAddMetricToDashboardClickAction(metric.id, metric.visualization.type === 'table'); }}
                                    />
                                    <MetricExportButton
                                      small
                                      tooltip="Export"
                                      icon="export"
                                      fileName={metric.name}
                                      serverRequest={serverRequest}
                                    />
                                    <ButtonWithPopoverAndTooltip
                                      small
                                      icon="vist-page"
                                      overlay={metricOpenInPopover}
                                      placement="bottom-end"
                                      tooltip="Open in…"
                                    />
                                </ButtonGroup>
                            )
                        }
                    </MetricRequestContext.Consumer>
                </div>
            </div>
        </div>
    );
};

AnalysisMetricDataHeader.propTypes = {
    metric: customPropTypes.metric.isRequired,
    onAddMetricToDashboardClickAction: PropTypes.func.isRequired
};

export default connect(null, {
    onAddMetricToDashboardClickAction: modalActions.modalShowAddMetricToDashboard
})(AnalysisMetricDataHeader);
