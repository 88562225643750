import ListFilters from 'src/components/ListFilters';
import ListOptionsBar from 'src/components/ListOptionsBar';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'src/components/forms/inputs/Select';
import styles from 'src/stylesheets/headers/authUserHeader.scss';
import TextWithResetIcon from 'src/components/forms/inputs/TextWithResetIcon';

const AuthUserHeader = (props) => {
    const {
        handleOnEnvironmentChange,
        handleOnFilterByChange,
        handleOnFilterByReset,
        handleOnOrderByChange,
        filterBy,
        orderBy,
        environments,
        sortingOptions
    } = props;
    return (
        <div className={styles.wrapper}>
            <div className={styles.left} />
            <div className={styles.right}>
                <ListOptionsBar
                  filters={(
                      <ListFilters>
                          <Select
                            name="envFilter"
                            layout="listFilter"
                            onChange={handleOnEnvironmentChange}
                          >
                              {
                                  environments.map((environment) => (
                                      <option key={`env_${environment.value}`} value={environment.value}>
                                          {environment.label}
                                      </option>
                                  ))
                              }
                          </Select>
                          <TextWithResetIcon
                            placeholder="Search accounts"
                            onChange={handleOnFilterByChange}
                            value={filterBy}
                            onResetClick={handleOnFilterByReset}
                            layout="listFilter"
                          />
                      </ListFilters>
                  )}
                  sorting={
                      (
                          <Select layout="listFilter" defaultValue={orderBy} onChange={handleOnOrderByChange}>
                              {
                                  sortingOptions.map((option) => (
                                      <option
                                        key={`select-option-${option.value}`}
                                        value={option.value}
                                      >
                                          {option.label}
                                      </option>
                                  ))
                              }
                          </Select>
                  )
                  }
                />
            </div>
        </div>
    );
};

AuthUserHeader.propTypes = {
    handleOnEnvironmentChange: PropTypes.func.isRequired,
    handleOnFilterByChange: PropTypes.func.isRequired,
    handleOnFilterByReset: PropTypes.func.isRequired,
    handleOnOrderByChange: PropTypes.func.isRequired,
    filterBy: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    environments: PropTypes.arrayOf(PropTypes.object).isRequired,
    sortingOptions: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default AuthUserHeader;
