import React, { Component } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import Select from 'src/components/forms/inputs/Select';

class OrderBy extends Component {
    constructor(props) {
        super(props);
        this.handleOrderChange = this.handleOrderChange.bind(this);
    }

    handleOrderChange(e) {
        const { value } = e.target;
        const { onSelectionChange } = this.props;
        onSelectionChange(value);
    }

    render() {
        const { options, orderBy, disabled } = this.props;

        return (
            <Select layout="light" disabled={disabled} onChange={this.handleOrderChange} value={orderBy}>
                {
                    options.map((option) => <option key={option.value} value={option.value}>{option.label}</option>)
                }
            </Select>
        );
    }
}

OrderBy.propTypes = {
    orderBy: customPropTypes.orderBy.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
    })).isRequired,
    onSelectionChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

OrderBy.defaultProps = {
    disabled: false
};

export default OrderBy;
