import React, { Component } from 'react';
import _get from 'lodash/get';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    makeSelectDateByContext,
    makeAdditionalFilterSelectorsByContext,
    makeSelectProfileSelectorSelectionByContext
} from 'src/selectors/filterSelectors';
import { createFreshPostServerRequest } from 'src/requestHandling/datasourceDataRequests';
import * as customPropTypes from 'src/customPropTypes';
import memoizeOne from 'memoize-one';
import * as postsAction from 'src/actions/posts';
import { uncheckAllListValues } from 'src/actions/lists';
import { makeSelectPostSearchState, getPostsSearchInput, getResultTotalRowCount } from 'src/selectors/posts';
import _isEqual from 'lodash/isEqual';
import PostSearchRequestContext from 'src/components/context/PostSearchRequestContext';
import { getListNamesByNumberOfPages } from 'src/utils/postSearch';
import withFilterContextConsumer from 'src/components/withFilterContextConsumer';

const memoizedCreateFreshPostServerRequest = memoizeOne(createFreshPostServerRequest);

const LIMIT = 50;

class ParsedPostSearchParamsProvider extends Component {
    constructor(props) {
        super(props);
        this.handleFreshServerRequest = this.handleFreshServerRequest.bind(this);
    }

    componentDidMount() {
        const { searchInputFromStore, postSearchRequestInput } = this.props;
        const { hash } = searchInputFromStore;
        if (!_isEqual(hash, postSearchRequestInput.hash)) {
            this.handleFreshServerRequest();
        }
    }

    componentDidUpdate(prevProps) {
        const {
            searchLoadingState, postSearchRequestInput, sortDir, searchInputFromStore
        } = this.props;
        if (searchLoadingState.isPending === false && (sortDir !== prevProps.sortDir || searchInputFromStore.hash !== postSearchRequestInput.hash)) {
            this.handleFreshServerRequest();
            this.handleUnCheckListValues();
        }
    }

    handleFreshServerRequest() {
        const {
            searchPostsRequestAction,
            postSearchRequestInput
        } = this.props;
        searchPostsRequestAction(postSearchRequestInput);
    }

    handleUnCheckListValues() {
        const { totalRowCount, uncheckAllListValuesAction } = this.props;
        const listNames = getListNamesByNumberOfPages(totalRowCount, LIMIT);
        listNames.forEach((listName) => {
            uncheckAllListValuesAction(listName);
        });
    }

    render() {
        const { searchInputFromStore, children } = this.props;
        return (
            <PostSearchRequestContext.Provider value={searchInputFromStore}>
                {children(searchInputFromStore)}
            </PostSearchRequestContext.Provider>
        );
    }
}

const makeMapStateToProps = () => {
    const selectPostSearchState = makeSelectPostSearchState();
    const selectDateByContext = makeSelectDateByContext();
    const {
        selectPostTagByContext, selectPostTextByContext, selectPostTextExcludeByContext, selectAdCampaignByContext
    } = makeAdditionalFilterSelectorsByContext();
    const selectProfileSelectorSelectionByContext = makeSelectProfileSelectorSelectionByContext();
    return (state, ownProps) => {
        const { filterContext, location } = ownProps;
        const sortDir = _get(location, 'query.sortDir', 'desc');
        const searchInputFromStore = getPostsSearchInput(state);
        const selectedDate = selectDateByContext(state, filterContext);
        const selectedProfilesOrGroups = selectProfileSelectorSelectionByContext(state, filterContext);
        const postText = selectPostTextByContext(state, filterContext);
        const postTag = selectPostTagByContext(state, filterContext);
        const postTextExclude = selectPostTextExcludeByContext(state, filterContext);
        const adCampaign = selectAdCampaignByContext(state, filterContext);

        const { request: postSearchRequestInput } = memoizedCreateFreshPostServerRequest(selectedProfilesOrGroups, selectedDate, postText, postTextExclude, postTag, adCampaign, sortDir, LIMIT);
        return {
            sortDir,
            postSearchRequestInput,
            searchInputFromStore,
            searchLoadingState: selectPostSearchState(state),
            totalRowCount: getResultTotalRowCount(state)
        };
    };
};

ParsedPostSearchParamsProvider.propTypes = {
    children: PropTypes.func.isRequired,
    sortDir: PropTypes.string.isRequired,
    searchLoadingState: customPropTypes.asyncDataLoadingState.isRequired,
    postSearchRequestInput: customPropTypes.postSearchRequestInput.isRequired,
    searchInputFromStore: customPropTypes.postSearchRequestInput.isRequired,
    searchPostsRequestAction: PropTypes.func.isRequired,
    uncheckAllListValuesAction: PropTypes.func.isRequired,
    totalRowCount: PropTypes.number.isRequired
};

export default withFilterContextConsumer(connect(makeMapStateToProps, {
    searchPostsRequestAction: postsAction.searchPostsRequest,
    uncheckAllListValuesAction: uncheckAllListValues,
})(ParsedPostSearchParamsProvider));
