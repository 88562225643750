import Button from 'src/components/buttons/Button';
import withTooltip from 'src/components/withToolTip';
import React from 'react';
import { connect } from 'react-redux';
import { makeSelectAnalysisMetricLink } from 'src/selectors/analysis';
import * as customPropTypes from 'src/customPropTypes';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';

const ButtonWithTooltip = withTooltip(Button);

const allPostsMetricId = '15262';

const OpenAllPostsInAnalysisButton = ({ analysisMetricLink, routePushAction }) => (
    <ButtonWithTooltip icon="vist-page" tooltip="Open in Analysis" onClick={() => { routePushAction(analysisMetricLink); }} />
);

OpenAllPostsInAnalysisButton.propTypes = {
    analysisMetricLink: customPropTypes.linkTo.isRequired,
    routePushAction: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const selectAnalysisMetricLink = makeSelectAnalysisMetricLink();
    return (state, ownProps) => ({
        analysisMetricLink: selectAnalysisMetricLink(state, ownProps.filterContext, allPostsMetricId)
    });
};

const ConnectedOpenAllPostsInAnalysisButton = connect(makeMapStateToProps, { routePushAction: push })(OpenAllPostsInAnalysisButton);

ConnectedOpenAllPostsInAnalysisButton.propTypes = {
    filterContext: customPropTypes.filterContext.isRequired
};

export default ConnectedOpenAllPostsInAnalysisButton;
