import * as customPropTypes from 'src/customPropTypes';
import { Field, formValueSelector } from 'redux-form';
import FormError from 'src/components/forms/feedback/FormError';
import PropTypes from 'prop-types';
import React from 'react';
import { Text, Checkbox } from 'src/components/forms/fields';
import withReduxForm from 'src/components/forms/withReduxForm';
import { required, makeLengthValidator } from 'src/components/forms/validators';
import PostTagRules from 'src/components/views/postTags/postTagManager/postTagRules/PostTagRules';
import { connect } from 'react-redux';
import Feedback from 'src/components/feedback/Feedback';
import withUnsavedConfirmationDialog from 'src/components/forms/withUnsavedConfirmationDialog';
import styles from 'src/stylesheets/forms/postTagEdditForm.scss';
import { selectAccountOptions } from 'src/selectors/loggedInUser';
import { getLocalString } from 'src/components/Date';
import { makeSelectPostTagsPostsInfo } from 'src/selectors/postTagsPostInfo';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import Number from 'src/components/Number';

const postTagNameLenghtValidator = makeLengthValidator(1, 30);
const warningStyle = {
    paddingBottom: '24px'
};

const PostTagEditForm = (props) => {
    const {
        handleSubmit, error, postTag, archivedFormValue, initialValues, handlePostTagRuleEditMode, submitting, accountOptions, postTagsPostsInfo
    } = props;
    const { createTime, creatorUserName } = postTag;
    const handleOnArchiveChange = (e) => {
        const { change } = props;
        const { checked } = e.target;
        if (checked) {
            change('automation', false);
        } else {
            change('automation', initialValues.automation);
        }
    };
    const { numberOfPosts } = postTagsPostsInfo;
    const infoText = `Created ${getLocalString(createTime, 'date', accountOptions)} by ${creatorUserName}`;

    return (
        <form onSubmit={handleSubmit}>
            {
                error && <FormError message={error.message} />
            }
            <Field
              autoFocus
              name="name"
              label="Tag name"
              component={Text}
              disabled={submitting}
              validate={[required, postTagNameLenghtValidator]}
            />
            <div className={styles.info}>
                <div className={styles.archive}>
                    <Field
                      name="archived"
                      text="Archive tag"
                      component={Checkbox}
                      disabled={submitting}
                      onChange={handleOnArchiveChange}
                    />
                </div>
                <div className={styles.info}>
                    <div className={styles.divWithTooltip}>
                        <DivWithTooltip tooltip={infoText}>
                            {infoText}
                        </DivWithTooltip>
                    </div>
                    <span className={styles.numberOfPosts}>-&nbsp;<Number value={numberOfPosts} />&nbsp;<span>{numberOfPosts > 1 ? 'posts' : 'post'}</span></span>
                </div>
            </div>
            {
                archivedFormValue
                && (
                    <div style={warningStyle}>
                        <Feedback type="warning" content="This archived tag will be hidden from tag selection menus. Automation will be deactivated whilst the tag is archived." />
                    </div>
                )
            }
            <PostTagRules
              postTag={postTag}
              archivedFormValue={archivedFormValue}
              handlePostTagRuleEditMode={handlePostTagRuleEditMode}
              isPostTagFormSubmitting={submitting}
            />
        </form>
    );
};

PostTagEditForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: customPropTypes.dataLoadingError,
    archivedFormValue: PropTypes.bool.isRequired,
    postTag: customPropTypes.postTag.isRequired,
    change: PropTypes.func.isRequired,
    initialValues: customPropTypes.postTagInitialValues.isRequired,
    handlePostTagRuleEditMode: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    accountOptions: customPropTypes.accountOptions.isRequired,
    postTagsPostsInfo: customPropTypes.postTagsPostsInfo.isRequired
};

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector(ownProps.form);
    const selectNumberOfPostsByPostTagId = makeSelectPostTagsPostsInfo();
    return {
        archivedFormValue: !!selector(state, 'archived'),
        accountOptions: selectAccountOptions(state),
        postTagsPostsInfo: selectNumberOfPostsByPostTagId(state, ownProps.postTag.id),
    };
};

export default connect(mapStateToProps)(withReduxForm(withUnsavedConfirmationDialog(PostTagEditForm), {
    enableReinitialize: true
}));
