import { connect } from 'react-redux';
import { makeSelectCustomMetricById } from 'src/selectors/metrics';
import { makeSelectMetricSearchResultByTypeAndId } from 'src/selectors/metricSearch';
import { makeSelectVisualizationTagsByIds } from 'src/selectors/tags';

const withMetricFromSearchResults = (WrappedComponent) => {
    const makeMapStateToProps = () => {
        const selectMetricSearchResultByTypeAndId = makeSelectMetricSearchResultByTypeAndId();
        const selectCustomMetricById = makeSelectCustomMetricById();
        const selectVisualizationTagsByIds = makeSelectVisualizationTagsByIds();
        return (state, ownProps) => {
            const customMetric = selectCustomMetricById(state, ownProps.id);
            if (customMetric) {
                return {
                    name: customMetric.name,
                    isBasedOnPrivateStatistics: customMetric.isBasedOnPrivateStatistics,
                    platformType: customMetric.platformType,
                    isCustomMetric: true,
                    visualizationId: customMetric.visualizationId,
                    visualizationTags: selectVisualizationTagsByIds(state, customMetric.tagIds)
                };
            }
            const metricSearchResult = selectMetricSearchResultByTypeAndId(state, 'Metric', ownProps.id);
            return {
                name: metricSearchResult.name,
                isBasedOnPrivateStatistics: metricSearchResult.isBasedOnPrivateStatistics,
                platformType: metricSearchResult.platformType,
                isCustomMetric: false,
                visualizationId: metricSearchResult.visualizationId,
                visualizationTags: selectVisualizationTagsByIds(state, metricSearchResult.tagIds)
            };
        };
    };

    const WithStoreConnection = connect(makeMapStateToProps)(WrappedComponent);

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    WithStoreConnection.displayName = `withMetricFromSearchResults(${wrappedComponentName})`;

    return WithStoreConnection;
};

export default withMetricFromSearchResults;
