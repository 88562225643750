import { makeSelectMetricById, selectInitialValuesForMetricBuilder } from 'src/selectors/metrics';
import React, { Suspense } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import { customMetricSaveAsFormAction } from 'src/actions/customMetric';
import EmptyMetricBuilderForm from 'src/components/loadingIndicators/EmptyMetricBuilderForm';
import ErrorView from 'src/components/errors/ErrorView';
import ErrorBoundarySuspense from 'src/components/errors/ErrorBoundarySuspense';
import { makeSelectTagsByUseCaseType } from 'src/selectors/tags';
import PropTypes from 'prop-types';
import { selectVisualizations } from 'src/selectors/visualizations';
import withMetricLoader from 'src/components/views/custom-metrics/withMetricLoader';
import FilterContextProvider from 'src/components/views/FilterContextProvider';
import { METRIC_BUILDER } from 'src/utils/filterSelectors';
import { formName } from 'src/utils/metricBuilder';

const LazyMetricBuilderForm = React.lazy(() => import(/* webpackChunkName: "metricBuilder" */ 'src/components/forms/MetricBuilderForm'));

const MetricBuilder = (props) => {
    const {
        metricBuilderInitialValues,
        visualizations,
        useCaseTags
    } = props;

    return (
        <ErrorBoundarySuspense renderErrorBody={(error, sentryEventId) => <ErrorView headline="Error" error={error} sentryEventId={sentryEventId} />}>
            <Suspense fallback={<EmptyMetricBuilderForm />}>
                <FilterContextProvider context={METRIC_BUILDER}>
                    <LazyMetricBuilderForm
                      form={formName}
                      initialValues={metricBuilderInitialValues}
                      visualizationTypeList={visualizations}
                      onSubmit={customMetricSaveAsFormAction}
                      useCaseTags={useCaseTags}
                    />
                </FilterContextProvider>
            </Suspense>
        </ErrorBoundarySuspense>
    );
};

MetricBuilder.propTypes = {
    metricBuilderInitialValues: customPropTypes.metricBuilderInitialValues.isRequired,
    visualizations: customPropTypes.visualizationTypeList.isRequired,
    metricId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
    metric: customPropTypes.metricOrFalse.isRequired,
    useCaseTags: customPropTypes.tags.isRequired
};

const makeMapStateToProps = () => {
    const selectMetricById = makeSelectMetricById();
    const selectTagsByUseCaseType = makeSelectTagsByUseCaseType();
    return (state, ownProps) => {
        const { metricId } = ownProps.params;
        const metric = selectMetricById(state, metricId);
        const useCaseTags = selectTagsByUseCaseType(state);
        const metricBuilderInitialValues = selectInitialValuesForMetricBuilder(metric, useCaseTags);
        return {
            metric,
            metricBuilderInitialValues,
            visualizations: selectVisualizations(state),
            useCaseTags
        };
    };
};

export default withMetricLoader(connect(makeMapStateToProps)(MetricBuilder));
