import { createAsyncStatesReducerForAction } from 'src/reducers/utils';
import {
    DASHBOARD_CLONE_DASHBOARD_TEMPLATE_ERROR,
    DASHBOARD_CLONE_DASHBOARD_TEMPLATE_REQUEST,
    DASHBOARD_CLONE_DASHBOARD_TEMPLATE_SUCCESS
} from 'src/actions/dashboard';
import {
    CHANGE_MODAL_FOLDER_LOCATION,
    FOLDER_ADD_SUCCESS,
    FOLDER_EDIT_SUCCESS,
    FOLDERS_AND_DASHBOARDS_DELETE_SUCCESS,
    FOLDERS_AND_DASHBOARDS_MOVE_TO_FOLDER_ERROR,
    FOLDERS_AND_DASHBOARDS_MOVE_TO_FOLDER_REQUEST,
    FOLDERS_AND_DASHBOARDS_MOVE_TO_FOLDER_SUCCESS
} from 'src/actions/folders';
import _omit from 'lodash/omit';
import { combineReducers } from 'redux';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';
import { SUPER_ROOT } from 'src/utils/dashboardFolders';

const allIds = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case USER_LOGGED_IN:
        case FOLDER_ADD_SUCCESS: {
            const { folders } = payload.folders;
            return [...state, ...Object.keys(folders)];
        }
        case FOLDERS_AND_DASHBOARDS_DELETE_SUCCESS: {
            const { folderIds } = payload;
            const newState = Object.values(Object.assign({}, state));
            folderIds.forEach((folderId) => {
                newState.splice(newState.indexOf(folderId), 1);
            });
            return newState;
        }
        default:
            return state;
    }
};

export function byId(state = {}, action) {
    const { payload, type } = action;
    switch (type) {
        case USER_LOGGED_IN:
        case FOLDER_ADD_SUCCESS: {
            const { folders } = payload.folders;
            return Object.assign({}, state, folders);
        }
        case FOLDERS_AND_DASHBOARDS_DELETE_SUCCESS: {
            const { folderIds } = payload;
            let newState = Object.assign({}, state);
            folderIds.forEach((folderId) => {
                newState = _omit(newState, folderId);
            });
            return newState;
        }
        case FOLDER_EDIT_SUCCESS: {
            const { updatedFolder } = payload;
            return Object.assign({}, state, {
                [updatedFolder.id]: Object.assign({}, state[updatedFolder.id], updatedFolder)
            });
        }
        case FOLDERS_AND_DASHBOARDS_MOVE_TO_FOLDER_SUCCESS: {
            const { foldersResponses, } = payload;

            if (foldersResponses.length > 0) {
                const newState = Object.assign({}, state);
                foldersResponses.forEach((folderResponse) => {
                    const { folder } = folderResponse.movedFolder;
                    Object.assign(newState, { [folder.id]: folder });
                });
                return newState;
            }
            return state;
        }
        default:
            return state;
    }
}

export const asyncStatesByAction = combineReducers({
    move: createAsyncStatesReducerForAction(
        (payload) => payload.fromFolderId,
        [FOLDERS_AND_DASHBOARDS_MOVE_TO_FOLDER_REQUEST],
        [FOLDERS_AND_DASHBOARDS_MOVE_TO_FOLDER_SUCCESS, FOLDERS_AND_DASHBOARDS_MOVE_TO_FOLDER_ERROR]
    ),
    save: createAsyncStatesReducerForAction(
        (payload) => payload.dashboardTemplateId,
        [DASHBOARD_CLONE_DASHBOARD_TEMPLATE_REQUEST],
        [DASHBOARD_CLONE_DASHBOARD_TEMPLATE_SUCCESS, DASHBOARD_CLONE_DASHBOARD_TEMPLATE_ERROR]
    ),
});

const initialState = { toFolderId: SUPER_ROOT };

export const modalFolderLocation = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case CHANGE_MODAL_FOLDER_LOCATION: {
            const { toFolderId } = payload;
            return Object.assign({}, state, { toFolderId });
        }
        default:
            return state;
    }
};

export const folders = combineReducers({
    allIds, byId, asyncStatesByAction, modalFolderLocation
});
