import { connect } from 'react-redux';
import customMetricsEmpty from 'src/resources/illustrations/custom-metrics-empty.png';
import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';
import { selectCustomMetricsCount } from 'src/selectors/metrics';

const style = {
    marginTop: '-40px'
};

const withCustomMetricCheck = (WrappedComponent) => {
    const WithCustomMetricCheck = (props) => {
        const { customMetricCount } = props;
        if (customMetricCount === 0) {
            const message = (
                <span>Create your own metrics in the <Link to="/custom-metrics">custom metrics</Link> section or check out our <a href="https://support.quintly.com/en/collections/2529291-building-metrics" target="_new">support page </a> on creating metrics.</span>
            );
            return (
                <div style={style}>
                    <EmptyInfoScreen
                      image={customMetricsEmpty}
                      title="You have no custom metrics yet"
                      message={message}
                    />
                </div>
            );
        }

        const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
        WithCustomMetricCheck.displayName = `withCustomMetricCheck(${wrappedComponentName})`;

        return <WrappedComponent {...props} />;
    };

    WithCustomMetricCheck.propTypes = {
        customMetricCount: PropTypes.number.isRequired
    };

    const mapStateToProps = (state) => ({
        customMetricCount: selectCustomMetricsCount(state)
    });

    return connect(mapStateToProps)(WithCustomMetricCheck);
};

export default withCustomMetricCheck;
