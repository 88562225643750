import React, { useEffect } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import * as routeActions from 'react-router-redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeSelectQueryFromFilterSelectorByContext } from 'src/selectors/filterSelectors';
import { getMissingParams } from 'src/utils/filterSelectors';

const withEnforcedSelectorParameters = (WrappedComponent, filterContext) => {
    const WithEnforcedSelectorParameters = (props) => {
        const { location, defaultQueryParams, routeReplaceAction } = props;

        useEffect(() => {
            const missingParams = getMissingParams(location.query, defaultQueryParams);
            if (Object.keys(missingParams).length > 0) {
                const newQuery = Object.assign({}, location.query, missingParams);
                routeReplaceAction({ pathname: location.pathname, query: newQuery });
            }
        }, []);
        return <WrappedComponent {...props} />;
    };

    WithEnforcedSelectorParameters.propTypes = {
        location: customPropTypes.location.isRequired,
        defaultQueryParams: PropTypes.oneOfType([PropTypes.shape({
            from: PropTypes.string.isRequired,
            to: PropTypes.string.isRequired,
            interval: PropTypes.string.isRequired,
            profile: PropTypes.string.isRequired
        }),
        PropTypes.shape({
            dynamicDate: PropTypes.string.isRequired,
            interval: PropTypes.string.isRequired,
            profile: PropTypes.string.isRequired
        })
        ]).isRequired,
        routeReplaceAction: PropTypes.func.isRequired
    };

    const makeMapStateToProps = () => {
        const selectQueryFromFilterSelectorByContext = makeSelectQueryFromFilterSelectorByContext();
        return (state) => ({
            defaultQueryParams: selectQueryFromFilterSelectorByContext(state, filterContext)
        });
    };

    return connect(makeMapStateToProps, {
        routeReplaceAction: routeActions.replace
    })(WithEnforcedSelectorParameters);
};

export default withEnforcedSelectorParameters;
