import { passwordChangeRequest } from 'src/actions/loggedInUser';
import { modalShowEmailChange } from 'src/actions/overlays';
import Button from 'src/components/buttons/Button';
import ContentBlock from 'src/components/views/ContentBlock';
import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const getName = (connection) => {
    switch (connection) {
        case 'google-oauth2':
            return 'Google';
        case 'windowslive':
            return 'Microsoft Account';
        default:
            return connection;
    }
};

const getContent = (userIdentity, passwordChangeRequestAction, modalShowEmailChangeAction) => {
    switch (userIdentity.connection) {
        case 'quintly': {
            const footer = (
                <Button
                  action
                  onClick={() => {
                      passwordChangeRequestAction(userIdentity.userId);
                  }}
                  label="Request Password Change Email"
                />
            );

            const footerEmail = (
                <Button
                  action
                  onClick={() => {
                      modalShowEmailChangeAction(userIdentity.connection, userIdentity.userId);
                  }}
                  label="Change Email"
                />
            );

            return (
                <>
                    <ContentBlock headline="Password" footerRight={footer}>
                        <div>To change your password, please send an email request.</div>
                    </ContentBlock>
                    <ContentBlock headline="Email" footerRight={footerEmail}>
                        <div>You can change the email address associated with your account here. Simply click the &quot;Change Email&quot; button below, enter your new email address.</div>
                    </ContentBlock>
                </>
            );
        }
        default:
            return (
                <ContentBlock headline={`${userIdentity.isSocial ? 'Social connection' : 'Connection'}`}>
                    <div>You are logged in with {getName(userIdentity.connection)} and do not have a Facelift Data Studio password.</div>
                </ContentBlock>
            );
    }
};

const UserIdentity = ({ userIdentity, passwordChangeRequestAction, modalShowEmailChangeAction }) => getContent(userIdentity, passwordChangeRequestAction, modalShowEmailChangeAction);

UserIdentity.propTypes = {
    userIdentity: customPropTypes.userIdentity.isRequired,
    passwordChangeRequestAction: PropTypes.func.isRequired,
    modalShowEmailChangeAction: PropTypes.func.isRequired
};

export default connect(null, {
    passwordChangeRequestAction: passwordChangeRequest,
    modalShowEmailChangeAction: modalShowEmailChange
})(UserIdentity);
