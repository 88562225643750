import {
    metricBuilderFormPlugin,
    postTagRuleAddFormPlugin,
    postTagEditFormPlugin
} from 'src/reducers/utils';
import { analysis } from 'src/reducers/analysis';
import { combineReducers } from 'redux';
import { customMetric } from 'src/reducers/customMetric';
import { dataSourceListStatus } from 'src/reducers/dataSourceListStatus';
import { entities } from 'src/reducers/entities.tool';
import { exports } from 'src/reducers/exports';
import { adminSettings } from 'src/reducers/adminSettings';
import { reducer as formReducer } from 'redux-form';
import { lastScrolledPosition } from 'src/reducers/lastScrolledPosition';
import { lastVisited } from 'src/reducers/lastVisited';
import { lists } from 'src/reducers/lists';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';
import { loggedInUser } from 'src/reducers/loggedInUser';
import { metricSearch } from 'src/reducers/metricSearch';
import { metricsWithDashboardUsage } from 'src/reducers/metricsWithDashboardUsage';
import { notifications } from 'src/reducers/notifications';
import { overlays } from 'src/reducers/overlays';
import { profileSearch } from 'src/reducers/profileSearch';
import { recommendations } from 'src/reducers/recommendations';
import { routerReducer as routing } from 'react-router-redux';
import { sampleServerData } from 'src/reducers/sampleServerData';
import { serverData } from 'src/reducers/serverData';
import { sidebars } from 'src/reducers/sidebars';
import { stickyHeader } from 'src/reducers/stickyHeader';
import { tablePagination } from 'src/reducers/tablePagination';
import { userNotification } from 'src/reducers/userNotification';
import { showInactiveDataPushTasks } from 'src/reducers/showInactiveDataPushTasks';
import { ownership } from 'src/reducers/ownership';
import { client } from 'src/reducers/client';
import { spaceLimits } from 'src/reducers/spaceLimits';
import { profileHealth } from 'src/reducers/profileHealth';
import { posts } from 'src/reducers/posts';
import { lastMainMenuLocations } from 'src/reducers/lastMainMenuLocations';
import { filterSelectors } from 'src/reducers/filterSelectors';
import { postTagRuleAddFormName } from 'src/utils/postTagRule';
import { postTagEditFormName } from 'src/utils/postTag';
import { postTagsPostsInfo } from 'src/reducers/postTagsPostsInfo';
import { dashboardReportSchedule } from 'src/reducers/dashboardReportSchedule';
import { profileAudienceData } from 'src/reducers/profileAudienceData';
import { adAccountSearch } from 'src/reducers/adAccountSearch';
import { userSecurity } from 'src/reducers/userSecurity';
import dashboardMetricNoteEdit from 'src/reducers/slices/dashboardMetricNoteEdit';
import { accountUseCasesAsyncStates } from 'src/reducers/accountUseCasesAsyncStates';

const form = formReducer.plugin({
    metricBuilderForm: metricBuilderFormPlugin,
    [postTagRuleAddFormName]: postTagRuleAddFormPlugin,
    [postTagEditFormName]: postTagEditFormPlugin
});

const quintlyApp = combineReducers({
    adminSettings,
    client,
    dataSourceListStatus,
    loggedInUser,
    lastScrolledPosition,
    entities,
    exports,
    form,
    filterSelectors,
    lastVisited,
    loadingBar,
    metricSearch,
    metricsWithDashboardUsage,
    notifications,
    overlays,
    profileSearch,
    customMetric,
    recommendations,
    routing,
    stickyHeader,
    sampleServerData,
    serverData,
    sidebars,
    tablePagination,
    lists,
    analysis,
    userNotification,
    spaceLimits,
    showInactiveDataPushTasks,
    ownership,
    profileHealth,
    posts,
    lastMainMenuLocations,
    postTagsPostsInfo,
    dashboardReportSchedule,
    userSecurity,
    profileAudienceData,
    dashboardMetricNoteEdit,
    adAccountSearch,
    accountUseCasesAsyncStates
});

export default quintlyApp;
