import * as customPropTypes from 'src/customPropTypes';
import Column from 'src/components/header/layout/Column';
import Header from 'src/components/header/layout/Header';
import PageHeadline from 'src/components/header/layout/PageHeadline';
import React from 'react';
import TopRow from 'src/components/header/layout/TopRow';
import BackLink from 'src/components/BackLink';
import styles from 'src/stylesheets/headers/dataPushTaskHeader.scss';
import { selectHasStickyHeader } from 'src/selectors/stickyHeader';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DataPushTaskPlatformLogo from 'src/components/views/integrations/DataPushTaskPlatformLogo';
import DataPushTaskStatus from 'src/components/views/integrations/DataPushTaskStatus';
import { getDestinationTypeText } from 'src/utils/dataPushTasks';
import DataPushTaskDryRunButton from 'src/components/buttons/DataPushTaskDryRunButton';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import ReloadDataPushTaskLogsButton from 'src/components/buttons/ReloadDataPushTaskLogsButton';
import { modalShowDataPushTaskScheduleNow } from 'src/actions/overlays';

const DataPushTaskHeader = (props) => {
    const { dataPushTask, isSticky, modalShowDataPushTaskScheduleNowAction } = props;
    const { id, isActive, destinationType } = dataPushTask;
    const backButton = (
        <div className={styles.backButton}>
            <BackLink
              to="/data-push-tasks"
              icon="back"
              tooltip="Back to data push tasks"
            />
        </div>
    );
    const img = <DataPushTaskPlatformLogo platformType={destinationType} className={styles.destinationTypeLogo} />;
    const dataPushStatus = <DataPushTaskStatus isActive={isActive} className={styles.dataPushTaskStatus} />;
    const destinationTypeText = getDestinationTypeText(destinationType);

    const actionButtons = (
        <ButtonGroup>
            <ReloadDataPushTaskLogsButton dataPushTaskId={id} />
            <DataPushTaskDryRunButton dataPushTaskId={id} />
            <Button
              label="Schedule task"
              disabled={!isActive}
              onClick={() => { modalShowDataPushTaskScheduleNowAction(id); }}
              action
            />
        </ButtonGroup>
    );

    if (isSticky) {
        return (
            <div>
                <div className={styles.placeholderIfFixedStyle} />
                <Header fixed className={styles.sticky}>
                    <TopRow>
                        <Column flexGrow className={styles.wrapper}>
                            {backButton}
                            {img}
                            <PageHeadline>{destinationTypeText}</PageHeadline>
                            {dataPushStatus}
                        </Column>
                        <Column>
                            {actionButtons}
                        </Column>
                    </TopRow>
                </Header>
            </div>
        );
    }
    return (
        <Header>
            <TopRow>
                <Column flexGrow className={styles.wrapper}>
                    {backButton}
                    {img}
                    <PageHeadline>{destinationTypeText}</PageHeadline>
                    {dataPushStatus}
                </Column>
                <Column>
                    {actionButtons}
                </Column>
            </TopRow>
        </Header>
    );
};

DataPushTaskHeader.propTypes = {
    dataPushTask: customPropTypes.dataPushTask.isRequired,
    stickyHeaderIdentifier: PropTypes.string.isRequired,
    isSticky: PropTypes.bool.isRequired,
    modalShowDataPushTaskScheduleNowAction: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => ({
    isSticky: selectHasStickyHeader(state, ownProps.stickyHeaderIdentifier)
});

export default connect(mapStateToProps, {
    modalShowDataPushTaskScheduleNowAction: modalShowDataPushTaskScheduleNow
})(DataPushTaskHeader);
