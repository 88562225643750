import React from 'react';
import DataPushTaskRow from 'src/components/views/integrations/dataPushTasks/dataPushTaskTable/DataPushTaskRow';
import * as customPropTypes from 'src/customPropTypes';
import ListTable from 'src/components/listTable/ListTable';
import ListTableHeader from 'src/components/listTable/ListTableHeader';
import { connect } from 'react-redux';
import * as dataPushTaskActions from 'src/actions/dataPushTasks';
import PropTypes from 'prop-types';
import { getListTableConfig, makeSelectDataPushTasksBasedOnShowInactiveDataPushTasksFlag } from 'src/selectors/dataPushTasks';
import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import emptySearchImage from 'src/resources/illustrations/discover-no-results.png';

const columns = [
    {
        id: 'destinationType', content: 'Destination type', isSortable: true, width: '15%'
    },
    {
        id: 'destinationLocation', content: 'Location', isSortable: true, width: '35%'
    },
    {
        id: 'deliveryTime', content: 'Delivery time', width: '20%'
    },
    {
        id: 'startTime', content: 'Last push', width: '20%', isSortable: true
    },
    {
        id: 'rowInserted', content: 'Rows pushed', align: 'right', isSortable: true, width: '10%'
    },
];

const DataPushTasksTable = (props) => {
    const { dataPushTasks, sortDataPushTaskAction, listTableConfig } = props;
    const { sortBy, sortDir } = listTableConfig;
    const tableHeader = <ListTableHeader columns={columns} sortBy={sortBy} sortDir={sortDir} sortByAction={sortDataPushTaskAction} />;
    if (dataPushTasks.length === 0) {
        return (
            <EmptyInfoScreen
              image={emptySearchImage}
              title="No tasks found"
            />
        );
    }
    return (
        <ListTable header={tableHeader}>
            {
                dataPushTasks.map((dataPushTask) => (
                    <DataPushTaskRow key={dataPushTask.id} dataPushTask={dataPushTask} />
                ))
            }
        </ListTable>
    );
};

DataPushTasksTable.propTypes = {
    dataPushTasks: customPropTypes.dataPushTasks.isRequired,
    listTableConfig: customPropTypes.listTableConfig.isRequired,
    sortDataPushTaskAction: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const selectDataPushTasksBasedOnShowInactiveDataPushTasksFlag = makeSelectDataPushTasksBasedOnShowInactiveDataPushTasksFlag();
    return (state) => ({
        dataPushTasks: selectDataPushTasksBasedOnShowInactiveDataPushTasksFlag(state),
        listTableConfig: getListTableConfig(state)
    });
};

export default connect(makeMapStateToProps, {
    sortDataPushTaskAction: dataPushTaskActions.sortDataPushTask
})(DataPushTasksTable);
