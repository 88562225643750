import PageHeadline from 'src/components/header/layout/PageHeadline';
import Column from 'src/components/header/layout/Column';
import Header from 'src/components/header/layout/Header';
import React from 'react';
import TopRow from 'src/components/header/layout/TopRow';

const AllDashboardsHeader = () => (
    <Header>
        <TopRow>
            <Column flexGrow>
                <PageHeadline>Dashboards</PageHeadline>
            </Column>
        </TopRow>
    </Header>
);

export default AllDashboardsHeader;
