import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/floatingList.scss';

const FloatingList = ({ style, className, children }) => (
    <ul style={style} className={classnames(styles.floatingList, className)}>
        {children}
    </ul>
);

FloatingList.propTypes = {
    children: customPropTypes.children,
    className: PropTypes.string,
    style: PropTypes.object
};

export default FloatingList;
