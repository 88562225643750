import Popover from 'src/components/popovers/layout/Popover';
import React from 'react';
import PropTypes from 'prop-types';

const wrapperStyle = {
    padding: 20,
    fontSize: '13px',
    lineHeight: '1.38'
};

const listStyle = {
    paddingTop: '8px'
};

const PostTextFilterDescriptionPopover = (props) => {
    const { filterAttributes } = props;

    return (
        <Popover id="post-text-filter-popover" width={400}>
            <div style={wrapperStyle}>
                <b>Keywords</b>
                <br /><br />
                This data source is compatible with the &apos;Keywords&apos; filter and includes the following columns
                for search:
                <ul style={listStyle}>
                    {
                        filterAttributes.map((filterAttribute) => <li>{filterAttribute}</li>)
                    }
                </ul>
            </div>
        </Popover>
    );
};

PostTextFilterDescriptionPopover.propTypes = {
    filterAttributes: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default PostTextFilterDescriptionPopover;
