import * as customPropTypes from 'src/customPropTypes';
import * as routeActions from 'react-router-redux';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import DashboardInfo from 'src/components/views/dashboards/DashboardInfo';
import ListHandlingCheckbox from 'src/components/bulkList/ListHandlingCheckbox';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/dashboardListItem.scss';

const DashboardListItem = (props) => {
    const {
        parentFolder,
        dashboard,
        onEditClickAction,
        onMoveClickAction,
        onCloneClickAction,
        loggedInUser,
        actionsDisabled,
        listName,
        labelIcon
    } = props;

    const buttons = (
        <ButtonGroup>
            <Button
              small
              onClick={() => { onCloneClickAction(dashboard.id, parentFolder.id); }}
              label="Clone"
              disabled={actionsDisabled}
              className="list-hover-button-opacity"
            />
            <Button
              small
              onClick={() => { onMoveClickAction([], [dashboard.id], parentFolder.id, parentFolder.name); }}
              disabled={actionsDisabled}
              label="Move"
              className="list-hover-button-opacity"
            />
            <Button
              small
              onClick={() => { onEditClickAction(dashboard.id); }}
              label="Edit"
              disabled={actionsDisabled}
              className="list-hover-button-opacity"
            />
        </ButtonGroup>
    );

    const onDashboardClick = () => {
        const { routePushAction, link } = props;
        routePushAction(link);
    };

    return (
        <div className={styles.listItem}>
            <div className={styles.checkboxWrapper}>
                <ListHandlingCheckbox
                  name={`dashboardListItem-${dashboard.id}`}
                  listName={listName}
                />
            </div>
            <div className={styles.dashboardInfoWrapper} onClick={onDashboardClick}>
                <DashboardInfo
                  dashboard={dashboard}
                  loggedInUser={loggedInUser}
                  labelIcon={labelIcon}
                />
            </div>
            <div className={styles.buttonWrapper}>
                {buttons}
            </div>
        </div>
    );
};

DashboardListItem.propTypes = {
    dashboard: customPropTypes.dashboard.isRequired,
    parentFolder: customPropTypes.folder.isRequired,
    link: customPropTypes.linkTo.isRequired,
    onEditClickAction: PropTypes.func.isRequired,
    onMoveClickAction: PropTypes.func.isRequired,
    onCloneClickAction: PropTypes.func.isRequired,
    routePushAction: PropTypes.func.isRequired,
    loggedInUser: customPropTypes.user.isRequired,
    parentId: PropTypes.string,
    actionsDisabled: PropTypes.bool.isRequired,
    listName: PropTypes.string.isRequired,
    labelIcon: PropTypes.string
};

export default connect(null, {
    routePushAction: routeActions.push
})(DashboardListItem);
