import {
    call, put, takeEvery, select
} from 'redux-saga/effects';
import {
    SOCIAL_NETWORK_AD_ACCOUNT_SHOW_LIST_REQUEST,
    socialNetworkAdAccountShowListError,
    socialNetworkAdAccountShowListSuccess,
    adAccountSearchGraphAuthUserForceConnectRequest,
    adAccountSearchByAuthAdAccountRequest,
    adAccountSearchByAuthAdAccountError,
    adAccountSearchByAuthAdAccountSuccess
} from 'src/actions/adAccountSearch';
import { graphAuthUserAuthenticationRequest } from 'src/sagas/authUsers';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import { modalsShowFacebookAuthUserInAnotherSocialNetworkSearchWarning } from 'src/actions/overlays';
import { parseSearchedAdAccounts } from 'src/parsers';
import { selectUseCasesByPlatform } from 'src/selectors/useCases';
import { reportError } from 'src/utils/reportError';

function* performSocialNetworkAdAccountShowListSuccessOrThrowError(response = null) {
    let parsedSearchedAdAccounts = {};
    if (response !== null) {
        const { searchedAdAccounts } = response.jsonData;
        parsedSearchedAdAccounts = parseSearchedAdAccounts(searchedAdAccounts);
    }
    yield put(socialNetworkAdAccountShowListSuccess(parsedSearchedAdAccounts));
}

function* facebookPagesShowListRequest(useCaseIds) {
    try {
        const { response, serverError } = yield call(graphAuthUserAuthenticationRequest, useCaseIds);
        if (response) {
            const { authResponse } = response;
            const { accessToken } = authResponse;
            const serverRequest = createServerRequest({ shortLiveAccessToken: accessToken });
            const { response: authorizedResponse, serverError: authorizedServerError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/find-meta-ad-accounts');
            if (authorizedResponse) {
                const { isAuthUserInAnotherSpace } = authorizedResponse.jsonData;
                if (isAuthUserInAnotherSpace) {
                    yield put(modalsShowFacebookAuthUserInAnotherSocialNetworkSearchWarning(accessToken, 'adAccounts'));
                } else {
                    yield call(performSocialNetworkAdAccountShowListSuccessOrThrowError, authorizedResponse);
                }
            }
            if (authorizedServerError) {
                throw authorizedServerError;
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(socialNetworkAdAccountShowListError(applicationError));
    }
}

function* getUseCaseIdsByNetworkType(network) {
    const useCases = yield select(selectUseCasesByPlatform, network);
    return useCases.map((useCase) => useCase.id);
}

function* adAccountSearchGraphAuthUserForceConnectAction(action) {
    const { payload } = action;
    const { accessToken } = payload;
    try {
        const serverRequest = createServerRequest({ shortLiveAccessToken: accessToken, isAllowedInNewSpace: true });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/find-meta-ad-accounts');
        if (response) {
            yield call(performSocialNetworkAdAccountShowListSuccessOrThrowError, response);
        }
        if (serverError) {
            throw response;
        }
    } catch (applicationError) {
        yield put(socialNetworkAdAccountShowListError(applicationError));
    }
}

function* socialNetworkAdAccountShowListRequest(action) {
    const { network } = action.payload;
    if (network === 'meta') {
        const useCaseIds = yield call(getUseCaseIdsByNetworkType, network);
        yield call(facebookPagesShowListRequest, useCaseIds);
    }
}

function* adAccountSearchByAuthAdAccountRequestAction() {
    try {
        const serverRequest = createServerRequest();
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/find-ad-accounts-by-auth-ad-accounts');
        if (response) {
            const { searchedAdAccounts } = response.jsonData;
            const parsedAdAccounts = parseSearchedAdAccounts(searchedAdAccounts);
            yield put(adAccountSearchByAuthAdAccountSuccess(parsedAdAccounts));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(adAccountSearchByAuthAdAccountError(applicationOrServerError));
    }
}

export default function* socialNetworkAdAccountSearchSagas() {
    yield takeEvery(SOCIAL_NETWORK_AD_ACCOUNT_SHOW_LIST_REQUEST, socialNetworkAdAccountShowListRequest);
    yield takeEvery(adAccountSearchGraphAuthUserForceConnectRequest.type, adAccountSearchGraphAuthUserForceConnectAction);
    yield takeEvery(adAccountSearchByAuthAdAccountRequest.type, adAccountSearchByAuthAdAccountRequestAction);
}
