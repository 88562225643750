import React, { useEffect } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import { analysisQuickAccessLinksRequest } from 'src/actions/analysis';
import AnalysisQuickLink from 'src/components/buttons/AnalysisQuickLink';
import classnames from 'classnames';
import { connect } from 'react-redux';
import EmptyListSkeleton from 'src/components/loadingIndicators/EmptyListSkeleton';
import List from 'src/components/List';
import { makeSelectAnalysisQuickAccessLinksByFilter } from 'src/selectors/analysis';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/views/analysis/analysis.scss';
import withAnalysisQuickLinkFromStore from 'src/components/views/analysis/withAnalysisQuickLinkFromStore';

const AnalysisQuickLinkWithAnalysisQuickLinkFromStore = withAnalysisQuickLinkFromStore(AnalysisQuickLink);

const AnalysisQuickAccessList = (props) => {
    const { quickAccessLinks, analysisQuickAccessLinksRequestAction, analysisFilter } = props;
    useEffect(() => {
        if (!quickAccessLinks.data && !quickAccessLinks.error) {
            analysisQuickAccessLinksRequestAction(analysisFilter);
        }
    }, [quickAccessLinks]);

    const { loading, error, data } = quickAccessLinks;

    let content = null;

    if (loading) {
        content = (
            <div className={styles.quickAccessListPadding}>
                <EmptyListSkeleton />
            </div>
        );
    } else if (error) {
        content = (
            <div className={classnames(styles.quickAccessListPadding, styles.loadingError)}>
                The metric list couldn{'\''}t be loaded.<br />
                Please try again later or contact our support team.
            </div>
        );
    } else if (data) {
        content = (
            <List border={false}>
                {
                    data.map((metricId) => (
                        <AnalysisQuickLinkWithAnalysisQuickLinkFromStore key={metricId} id={metricId} />
                    ))
                }
            </List>
        );
    }

    return (
        <div>{content}</div>
    );
};

AnalysisQuickAccessList.propTypes = {
    quickAccessLinks: customPropTypes.quickAccessLinks.isRequired,
    analysisFilter: PropTypes.string.isRequired,
    analysisQuickAccessLinksRequestAction: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const selectAnalysisQuickAccessLinksByFilter = makeSelectAnalysisQuickAccessLinksByFilter();
    return (state, ownProps) => ({
        quickAccessLinks: selectAnalysisQuickAccessLinksByFilter(state, ownProps.analysisFilter)
    });
};

export default connect(makeMapStateToProps, {
    analysisQuickAccessLinksRequestAction: analysisQuickAccessLinksRequest
})(AnalysisQuickAccessList);
