import {
    CUSTOM_METRIC_DELETE_REQUEST,
    CUSTOM_METRIC_DUPLICATE_REQUEST,
    CUSTOM_METRIC_SAVE_AS_REQUEST,
    customMetricDeleteError,
    customMetricDeleteSuccess,
    customMetricDuplicateError,
    customMetricDuplicateSuccess,
    customMetricSaveAsError,
    customMetricSaveAsSuccess,
} from 'src/actions/customMetric';
import {
    put, select, takeEvery
} from 'redux-saga/effects';
import { modalHideSaveAsCustomMetric, modalsHideDeleteCustomMetric, modalHideSaveCustomMetric } from 'src/actions/overlays';
import { parseDashboardMetricsAdditionalSettings, parsedDashboardMetricIdsFromDashboards, parseMetric } from 'src/parsers';
import * as routeActions from 'react-router-redux';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { dashboardMetricIdsCleanUpAfterCustomMetricDelete } from 'src/actions/dashboard';
import { dashboardMetricsCleanupAfterDashboardDelete } from 'src/actions/dashboardMetrics';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import { reportError } from 'src/utils/reportError';
import { selectMetricBuilderFormButtonClicked } from 'src/selectors/forms';
import { showNotification } from 'src/actions/notifications';
import { SubmissionError } from 'redux-form';
import _parseInt from 'lodash/parseInt';

function* customMetricDuplicateRequest(action) {
    const { metric } = action.payload;
    const param = {
        id: `${metric.id}`
    };
    try {
        const serverRequest = createServerRequest(param);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/duplicate-custom-metric');
        if (response) {
            const { metric: metricFromResponse } = response.jsonData;
            yield put(customMetricDuplicateSuccess(parseMetric(metricFromResponse)));
            yield put(showNotification('Your custom metric was successfully duplicated.'));
        }
        if (serverError) {
            yield put(modalHideSaveAsCustomMetric());
            yield put(customMetricDuplicateError(new SubmissionError({})));
            yield put(showNotification(serverError.message, 'error'));
        }
    } catch (applicationError) {
        reportError(applicationError);
        yield put(modalHideSaveAsCustomMetric());
        yield put(customMetricDuplicateError(new SubmissionError({ _error: applicationError })));
    }
}

function* customMetricSaveAsRequest(action) {
    const { metric } = action.payload;
    const buttonClicked = yield select(selectMetricBuilderFormButtonClicked);
    const isNewMetric = buttonClicked !== 'update';
    const requestUrl = isNewMetric ? '/client-widget/create-custom-metric' : '/client-widget/update-custom-metric';

    const parsedConfig = JSON.parse(metric.qqlConfig);
    const config = {
        config: parsedConfig.config,
    };
    if (parsedConfig.parameters) {
        config.parameters = parsedConfig.parameters;
    }
    const param = {
        id: buttonClicked === 'update' ? metric.metricId : null,
        name: metric.metricName,
        summary: metric.metricSummary,
        description: metric.description,
        useCases: metric.useCases,
        visualizationId: metric.visualizationType,
        config: JSON.stringify(config),
        useCaseTagIds: JSON.stringify(metric.tags.map((tag) => _parseInt(tag.id)))
    };
    try {
        const serverRequest = createServerRequest(param);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, requestUrl);

        if (response) {
            if (isNewMetric) {
                yield put(modalHideSaveAsCustomMetric());
            } else {
                yield put(modalHideSaveCustomMetric());
            }
            const { metric: metricFromResponse, dashboardMetricSettings = {} } = response.jsonData;
            metricFromResponse.isCustomMetric = true;
            yield put(customMetricSaveAsSuccess(parseMetric(metricFromResponse), parseDashboardMetricsAdditionalSettings(dashboardMetricSettings), isNewMetric));
            yield put(routeActions.replace({
                pathname: `/metric-builder/${metricFromResponse.id}`
            }));
            yield put(showNotification(isNewMetric
                ? 'Your custom metric was successfully saved.'
                : 'Your custom metric was successfully updated.'));
        }
        if (serverError) {
            yield put(modalHideSaveAsCustomMetric());
            // we dispatch empty error here, cause we don't want the form to show it cause the notification covers the error
            yield put(customMetricSaveAsError(new SubmissionError({})));
            yield put(showNotification(serverError.message, 'error'));
        }
    } catch (applicationError) {
        reportError(applicationError);
        yield put(modalHideSaveAsCustomMetric());
        yield put(customMetricSaveAsError(new SubmissionError({ _error: applicationError })));
    }
}

function* customMetricDeleteRequest(action) {
    const { id } = action.payload;
    const param = {
        id
    };
    try {
        const serverRequest = createServerRequest(param);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/delete-custom-metric');
        if (response) {
            const { deletedDashboardMetricIds } = response.jsonData;
            const {
                allDashboardMetricIds,
                dashboardsWithParsedDashboardMetricIds
            } = parsedDashboardMetricIdsFromDashboards(deletedDashboardMetricIds);

            yield put(customMetricDeleteSuccess(id));
            yield put(routeActions.replace({
                pathname: '/custom-metrics'
            }));

            yield put(modalsHideDeleteCustomMetric(id));

            // clean up dashboardmetricIds in each aftected dashboards
            yield put(dashboardMetricIdsCleanUpAfterCustomMetricDelete(dashboardsWithParsedDashboardMetricIds));

            // Clean up dashboardMetrics
            if (allDashboardMetricIds.length > 0) {
                yield put(dashboardMetricsCleanupAfterDashboardDelete(allDashboardMetricIds));
            }

            yield put(showNotification('Your custom metric was successfully deleted.'));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(modalsHideDeleteCustomMetric(id));
        yield put(showNotification(applicationOrServerError.message, 'error'));
        yield put(customMetricDeleteError(applicationOrServerError, id));
    }
}

export default function* customMetricSaga() {
    yield takeEvery(CUSTOM_METRIC_SAVE_AS_REQUEST, customMetricSaveAsRequest);
    yield takeEvery(CUSTOM_METRIC_DELETE_REQUEST, customMetricDeleteRequest);
    yield takeEvery(CUSTOM_METRIC_DUPLICATE_REQUEST, customMetricDuplicateRequest);
}
