import React from 'react';
import authUserEmpty from 'src/resources/illustrations/discover-no-results.png';
import AuthUserListItemContainer from 'src/components/views/authentications/authUser/AuthUserListItemContainer';
import { connect } from 'react-redux';
import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import List from 'src/components/List';
import { makeSelectAuthUserIdsWithListFiltersApplied } from 'src/selectors/authUsers';
import PropTypes from 'prop-types';

const AuthUsersList = (props) => {
    const { authUserIds } = props;
    return (
        <>
            {
                authUserIds.length > 0
                    ? (
                        <List>
                            {
                            authUserIds.map((authUserId) => (
                                <AuthUserListItemContainer key={authUserId} id={authUserId} />
                            ))
                        }
                        </List>
                    )
                    : (
                        <EmptyInfoScreen
                          image={authUserEmpty}
                          title="No users found"
                          message="No results were found that match your search."
                        />
                    )
            }
        </>

    );
};

const makeMapStateToProps = () => {
    const selectAuthUserIdsWithListFiltersApplied = makeSelectAuthUserIdsWithListFiltersApplied();
    return (state, ownProps) => {
        const { filterBy, orderBy, environmentType } = ownProps;
        const authUserIds = selectAuthUserIdsWithListFiltersApplied(state, environmentType, filterBy, orderBy);
        return {
            authUserIds
        };
    };
};

AuthUsersList.propTypes = {
    authUserIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    environmentType: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    filterBy: PropTypes.string.isRequired
};

export default connect(makeMapStateToProps)(AuthUsersList);
