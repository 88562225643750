import { createFormAction } from 'redux-form-saga';

export const USER_SWITCH_SPACE_REQUEST = 'USER_SWITCH_SPACE_REQUEST';
export const USER_SWITCH_SPACE_ERROR = 'USER_SWITCH_SPACE_ERROR';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';

export const DELETE_ACCOUNT_REQUEST = 'DELETE_ACCOUNT_REQUEST';
export const DELETE_ACCOUNT_SUCCESS = 'DELETE_ACCOUNT_SUCCESS';
export const DELETE_ACCOUNT_ERROR = 'DELETE_ACCOUNT_ERROR';

export const ADD_FIRST_APP_STARTED_TIME_REQUEST = 'ADD_FIRST_APP_STARTED_TIME_REQUEST';
export const ADD_FIRST_APP_STARTED_TIME_SUCCESS = 'ADD_FIRST_APP_STARTED_TIME_SUCCESS';
export const ADD_FIRST_APP_STARTED_TIME_ERROR = 'ADD_FIRST_APP_STARTED_TIME_ERROR';

export const PASSWORD_CHANGE_REQUEST = 'PASSWORD_CHANGE_REQUEST';
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS';
export const PASSWORD_CHANGE_ERROR = 'PASSWORD_CHANGE_ERROR';

export const ENROLL_MULTI_FACTOR_AUTHENTICATION_REQUEST = 'ENROLL_MULTI_FACTOR_AUTHENTICATION_REQUEST';
export const ENROLL_MULTI_FACTOR_AUTHENTICATION_SUCCESS = 'ENROLL_MULTI_FACTOR_AUTHENTICATION_SUCCESS';
export const ENROLL_MULTI_FACTOR_AUTHENTICATION_ERROR = 'ENROLL_MULTI_FACTOR_AUTHENTICATION_ERROR';

export const ACCOUNT_OPTIONS_SAVE_REQUEST = 'ACCOUNT_OPTIONS_SAVE_REQUEST';
export const ACCOUNT_OPTIONS_SAVE_SUCCESS = 'ACCOUNT_OPTIONS_SAVE_SUCCESS';
export const ACCOUNT_OPTIONS_SAVE_ERROR = 'ACCOUNT_OPTIONS_SAVE_ERROR';

export const UPDATE_USER_NAME_REQUEST = 'UPDATE_USER_NAME_REQUEST';
export const UPDATE_USER_NAME_SUCCESS = 'UPDATE_USER_NAME_SUCCESS';
export const UPDATE_USER_NAME_ERROR = 'UPDATE_USER_NAME_ERROR';

export const CHANGE_EMAIL_REQUEST = 'CHANGE_EMAIL_REQUEST';
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS';
export const CHANGE_EMAIL_ERROR = 'CHANGE_EMAIL_ERROR';

export const userLoggedIn = (
    dashboards,
    profiles,
    adAccounts,
    accountAuthenticationEntities,
    folders,
    groups,
    metrics,
    metricsWithDashboardUsage,
    loggedInUser,
    dashboardMetrics,
    dashboardReports,
    tags,
    dataTypes,
    visualizations,
    spaceLimits,
    packageStatus,
    clientSetting,
    onboardingFormsValues = null,
    profilesHealthStatus,
    { resultsForEntities, favoriteMetricIds } = { resultsForEntities: [], favoriteMetricIds: [] },
    userNotificationSettings,
    postTags = {},
    postTagRules = {}
) => ({
    type: USER_LOGGED_IN,
    payload: {
        dashboards,
        profiles,
        adAccounts,
        accountAuthenticationEntities,
        folders,
        groups,
        metrics,
        metricsWithDashboardUsage,
        loggedInUser,
        dashboardMetrics,
        dashboardReports,
        tags,
        dataTypes,
        visualizations,
        spaceLimits,
        packageStatus,
        clientSetting,
        onboardingFormsValues,
        profilesHealthStatus,
        resultsForEntities,
        favoriteMetricIds,
        userNotificationSettings,
        postTags,
        postTagRules
    }
});

export const userSwitchSpaceRequest = (selectedSpaceId, url) => ({
    type: USER_SWITCH_SPACE_REQUEST,
    payload: {
        selectedSpaceId,
        url
    }
});

export const userSwitchSpaceError = (error) => ({
    type: USER_SWITCH_SPACE_ERROR,
    payload: {
        error
    }
});

export const deleteAccountRequest = ({ userId }) => ({
    type: DELETE_ACCOUNT_REQUEST,
    payload: { userId }
});

export const deleteAccountSuccess = (userId) => ({
    type: DELETE_ACCOUNT_SUCCESS,
    payload: { userId }
});

export const deleteAccountError = (error) => ({
    type: DELETE_ACCOUNT_ERROR,
    payload: error
});

export const deleteAccountFormAction = createFormAction(deleteAccountRequest, [DELETE_ACCOUNT_SUCCESS, DELETE_ACCOUNT_ERROR]);

export const changeEmailRequest = ({ newEmail, connection, userId }) => ({
    type: CHANGE_EMAIL_REQUEST,
    payload: { email: newEmail, connection, userId }
});

export const changeEmailSuccess = (email) => ({
    type: CHANGE_EMAIL_SUCCESS,
    payload: { email }
});

export const changeEmailError = (error) => ({
    type: CHANGE_EMAIL_ERROR,
    payload: error
});

export const changeEmailFormAction = createFormAction(changeEmailRequest, [CHANGE_EMAIL_SUCCESS, CHANGE_EMAIL_ERROR]);

export const addFirstAppStartedTimeRequest = () => ({
    type: ADD_FIRST_APP_STARTED_TIME_REQUEST
});

export const addFirstAppStartedTimeSuccess = (firstAppStartedTime) => ({
    type: ADD_FIRST_APP_STARTED_TIME_SUCCESS,
    payload: { firstAppStartedTime }
});

export const addFirstAppStartedTimeError = (error) => ({
    type: ADD_FIRST_APP_STARTED_TIME_ERROR,
    payload: { error }
});

export const passwordChangeRequest = (identifier) => ({
    type: PASSWORD_CHANGE_REQUEST,
    payload: { identifier }
});

export const passwordChangeSuccess = () => ({
    type: PASSWORD_CHANGE_SUCCESS,
});

export const passwordChangeError = (error) => ({
    type: PASSWORD_CHANGE_ERROR,
    payload: error
});

export const accountOptionsSaveRequest = (accountOptions) => ({
    type: ACCOUNT_OPTIONS_SAVE_REQUEST,
    payload: { accountOptions }
});

export const accountOptionsSaveSuccess = (accountOptions) => ({
    type: ACCOUNT_OPTIONS_SAVE_SUCCESS,
    payload: { accountOptions }
});

export const accountOptionsSaveError = (error) => ({
    type: ACCOUNT_OPTIONS_SAVE_ERROR,
    payload: error
});

export const localizationFormAction = createFormAction(
    accountOptionsSaveRequest,
    [ACCOUNT_OPTIONS_SAVE_SUCCESS, ACCOUNT_OPTIONS_SAVE_ERROR]
);

const updateUserNameRequest = (type, name) => ({
    type: UPDATE_USER_NAME_REQUEST,
    payload: { type, name }
});

export const updateUserNameSuccess = (type, user) => ({
    type: UPDATE_USER_NAME_SUCCESS,
    payload: { type, user }
});

export const updateUserNameError = (type, error) => ({
    type: UPDATE_USER_NAME_ERROR,
    payload: { type, error }
});

export const updateUserLastNameRequest = (name) => updateUserNameRequest('last', name);

export const updateUserFirstNameRequest = (name) => updateUserNameRequest('first', name);

export const enrollMultiFactorAuthenticationRequest = () => ({
    type: ENROLL_MULTI_FACTOR_AUTHENTICATION_REQUEST,
    payload: {}
});

export const enrollMultiFactorAuthenticationSuccess = () => ({
    type: ENROLL_MULTI_FACTOR_AUTHENTICATION_SUCCESS,
    payload: {}
});

export const enrollMultiFactorAuthenticationError = () => ({
    type: ENROLL_MULTI_FACTOR_AUTHENTICATION_ERROR,
    payload: {}
});
