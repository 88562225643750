import Body from 'src/components/Body';
import EmptyDiv from 'src/components/loadingIndicators/EmptyDiv';
import React from 'react';
import ScrollView from 'src/components/ScrollView';
import Footer from 'src/components/Footer';
import DataPushTaskLoadingHeader from 'src/components/loadingIndicators/DataPushTaskLoadingHeader';
import styles from 'src/stylesheets/views/integrations/dataPushTaskDetailPage.scss';

const tableRowStyle = {
    paddingBottom: '4px'
};

const DataPushTaskLoading = () => (
    <ScrollView>
        <DataPushTaskLoadingHeader height={56} />
        <Body>
            <div className={styles.wrapper}>
                <div className={styles.left}>
                    <EmptyDiv height={370} />
                </div>
                <div className={styles.right}>
                    <EmptyDiv height={370} />
                </div>
            </div>
            <div className={styles.logs}>
                <div style={tableRowStyle}>
                    <EmptyDiv height={100} />
                </div>
                <div style={tableRowStyle}>
                    <EmptyDiv height={52} />
                </div>
                <div style={tableRowStyle}>
                    <EmptyDiv height={52} />
                </div>
                <EmptyDiv height={52} />
            </div>
        </Body>
        <Footer />
    </ScrollView>
);

export default DataPushTaskLoading;
