import React from 'react';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import { ensurePermission, writePermissions } from 'src/middleware/userPermissionCheck';
import { modalsShowProfileSearch } from 'src/actions/overlays';
import PropTypes from 'prop-types';

const AddProfilesButton = (props) => {
    const { modalsShowProfileSearchAction } = props;
    return (
        <Button
          onClick={modalsShowProfileSearchAction}
          action
          icon="plus"
          label="New profiles"
        />
    );
};

AddProfilesButton.propTypes = {
    modalsShowProfileSearchAction: PropTypes.func.isRequired
};

export default connect(null, {
    modalsShowProfileSearchAction: ensurePermission(modalsShowProfileSearch, writePermissions.addProfile)
})(AddProfilesButton);
