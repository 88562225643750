import { getMenuUserNotificationsRequestStateFromStore, selectMenuNotifications } from 'src/selectors/userNotification';
import * as routerActions from 'react-router-redux';
import * as userNotificationActions from 'src/actions/userNotification';
import { connect } from 'react-redux';
import { modalsShowUserNotificationSettings } from 'src/actions/overlays';
import UserNotificationMenuView from 'src/components/userNotificationMenu/UserNotificationMenuView';
import withUserNotificationClickHandling from 'src/components/withUserNotificationClickHandling';

const UserNotificationMenuViewWithNotificationClickHandling = withUserNotificationClickHandling(UserNotificationMenuView);

const makeMapStateToProps = () => (state) => ({
    userNotificationItems: selectMenuNotifications(state),
    userMenuNotificationRequestState: getMenuUserNotificationsRequestStateFromStore(state)
});

const UserNotificationMenuContainer = connect(makeMapStateToProps, {
    markAllUserNotificationAsReadAction: userNotificationActions.markAllUserNotificationAsRead,
    getUserNotificationsRequestAction: userNotificationActions.getUserNotificationsRequest,
    routerPushAction: routerActions.push,
    modalsShowUserNotificationSettingsAction: modalsShowUserNotificationSettings
})(UserNotificationMenuViewWithNotificationClickHandling);

export default UserNotificationMenuContainer;
