import { connect } from 'react-redux';
import { makeSelectNetworkOfMostProfiles } from 'src/selectors/profiles';
import PropTypes from 'prop-types';
import React from 'react';
import { selectTagIdForNetwork } from 'src/selectors/tags';

const withMostUsedNetworks = (WrappedComponent) => {
    const WithMostUsedNetworks = (props) => (
        <WrappedComponent {...props} />
    );

    WithMostUsedNetworks.propTypes = {
        mostUsedNetwork: PropTypes.string.isRequired,
        mostUsedNetworkTagId: PropTypes.string.isRequired
    };

    const makeMapStateToProps = () => {
        const selectNetworkOfMostProfiles = makeSelectNetworkOfMostProfiles('facebook');

        return (state) => {
            const mostUsedNetwork = selectNetworkOfMostProfiles(state);
            return {
                mostUsedNetwork,
                mostUsedNetworkTagId: selectTagIdForNetwork(mostUsedNetwork)
            };
        };
    };

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    WithMostUsedNetworks.displayName = `withMostUsedNetworks(${wrappedComponentName})`;

    return connect(makeMapStateToProps)(WithMostUsedNetworks);
};

export default withMostUsedNetworks;
