import {
    ACCOUNT_GET_DATA_REQUEST,
    ADMIN_SETTINGS_GET_SETTINGS_REQUEST,
    adminSettingsGetSettingsError,
    adminSettingsGetSettingsSuccess,
    accountGetDataError,
    accountGetDataSuccess,
    BILLING_LINK_GENERATE_REQUEST,
    billingLinkGenerateError,
    billingLinkGenerateSuccess
} from 'src/actions/adminSettings';
import { put, takeEvery, select } from 'redux-saga/effects';
import {
    parseBillingAddress, parsePackageInfo, parseApiAccess,
    parseClientLimit, parseCreditCard, parseSpaces, parseUsersForManagement
} from 'src/parsers';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import { reportError } from 'src/utils/reportError';
import { getClientSetting } from 'src/selectors/client';
import { showNotification } from 'src/actions/notifications';

function* adminSettingsGetSettingsRequest() {
    try {
        const serverRequest = createServerRequest();
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/get-settings');
        if (response) {
            const { settings } = response.jsonData;
            const {
                spaces, users, limits, accountData, apiAccess
            } = settings;
            const parsedSpaces = parseSpaces(spaces);
            const parsedUsers = parseUsersForManagement(users);
            const parsedLimit = parseClientLimit(limits);
            const parsedPackageInfo = parsePackageInfo(accountData);
            const parsedApiAccess = parseApiAccess(apiAccess);

            yield put(adminSettingsGetSettingsSuccess(parsedSpaces, parsedUsers, parsedLimit, parsedPackageInfo, parsedApiAccess));
        }
        if (serverError) {
            yield put(adminSettingsGetSettingsError(serverError));
        }
    } catch (applicationError) {
        reportError(applicationError);
        yield put(adminSettingsGetSettingsError(applicationError));
    }
}

function* accountGetDataRequest() {
    try {
        const serverRequest = createServerRequest();
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/get-account-data');
        if (response) {
            const { jsonData } = response;
            const { data } = jsonData;
            const parsedBillingAddress = parseBillingAddress(data);
            const parsedCreditCard = parseCreditCard(data.creditCard);
            yield put(accountGetDataSuccess({
                billingAddress: parsedBillingAddress,
                creditCard: parsedCreditCard
            }));
        }
        if (serverError) {
            yield put(accountGetDataError(serverError));
        }
    } catch (applicationError) {
        reportError(applicationError);
        yield put(accountGetDataError(applicationError));
    }
}

function* billingLinkGenerateRequest() {
    try {
        const clientSettings = yield select(getClientSetting);
        const { clientId } = clientSettings;
        const params = { clientId };

        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/generate-billing-link');
        if (response) {
            const { jsonData } = response;
            const { billingLink } = jsonData;
            yield put(billingLinkGenerateSuccess());
            window.open(billingLink, '_blank');
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification(`Generating billing link failed. Reason: ${applicationError.message}`, 'error'));
        yield put(billingLinkGenerateError(applicationError));
    }
}

export default function* adminSettingsSagas() {
    yield takeEvery(ADMIN_SETTINGS_GET_SETTINGS_REQUEST, adminSettingsGetSettingsRequest);
    yield takeEvery(ACCOUNT_GET_DATA_REQUEST, accountGetDataRequest);
    yield takeEvery(BILLING_LINK_GENERATE_REQUEST, billingLinkGenerateRequest);
}
