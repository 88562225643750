import {
    DASHBOARD_ADD_SUCCESS,
    DASHBOARD_CLONE_DASHBOARD_TEMPLATE_SUCCESS,
    DASHBOARD_CLONE_SUCCESS,
    DASHBOARDS_GET_SUCCESS
} from 'src/actions/dashboard';
import { FOLDER_ADD_SUCCESS, FOLDERS_AND_DASHBOARDS_DELETE_SUCCESS, FOLDERS_AND_DASHBOARDS_MOVE_TO_FOLDER_SUCCESS } from 'src/actions/folders';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';
import _omit from 'lodash/omit';
import { combineReducers } from 'redux';
import _difference from 'lodash/difference';
import _union from 'lodash/union';
import _without from 'lodash/without';

const addOrRemove = (state, toAdd, toRemove) => _union(_without(state, ...toRemove), toAdd);

const getIdsFromOwnershipEntity = (ownershipEntity, type) => {
    const sharedIds = [];
    const userIds = [];

    const { shared, user } = ownershipEntity;
    if (shared) {
        sharedIds.push(shared[`${type}Id`]);
    }
    if (user) {
        userIds.push(user[`${type}Id`]);
    }
    return { sharedIds, userIds };
};

const getIdsFromOwnershipEntities = (ownershipEntities, type) => {
    const sharedIds = [];
    const userIds = [];

    const { shared, user } = ownershipEntities;

    Object.keys(shared).forEach((id) => {
        const sharedEntity = shared[id];
        sharedIds.push(sharedEntity[`${type}Id`]);
    });

    Object.keys(user).forEach((id) => {
        const userEntity = user[id];
        userIds.push(userEntity[`${type}Id`]);
    });

    return { sharedIds, userIds };
};

export const ownership = combineReducers({
    folder: combineReducers({
        user: combineReducers({
            // byId: (state = {}, action) => state,
            // allIds: (state = [], action) => state ,
            allEntityIds: (state = [], action) => {
                const { type, payload } = action;
                switch (type) {
                    case USER_LOGGED_IN: {
                        const { user: entities } = payload.folders;
                        const folderIds = Object.keys(entities).map((key) => entities[key].folderId);
                        // needs to move somewhere else
                        folderIds.push('user');
                        return folderIds;
                    }
                    case FOLDER_ADD_SUCCESS: {
                        const { user: entities } = payload.folders;
                        const folderIds = Object.keys(entities).map((key) => entities[key].folderId);
                        if (folderIds.length > 0) {
                            return [...state, ...folderIds];
                        }
                        return state;
                    }
                    case FOLDERS_AND_DASHBOARDS_DELETE_SUCCESS: {
                        const { folderIds } = payload;
                        if (folderIds.length > 0) {
                            return _difference(state, folderIds);
                        }
                        return state;
                    }

                    case FOLDERS_AND_DASHBOARDS_MOVE_TO_FOLDER_SUCCESS: {
                        const {
                            foldersResponses
                        } = payload;

                        if (foldersResponses.length > 0) {
                            let newState = state;
                            foldersResponses.forEach((folderResponse) => {
                                const { movedFolder, affectedFolders } = folderResponse;
                                const entityIds = getIdsFromOwnershipEntity(movedFolder, 'folder');
                                const entitiesIds = getIdsFromOwnershipEntities(affectedFolders, 'folder');
                                newState = addOrRemove(
                                    newState,
                                    [...entityIds.userIds, ...entitiesIds.userIds],
                                    [...entityIds.sharedIds, ...entitiesIds.sharedIds]
                                );
                            });
                            return newState;
                        }

                        return state;
                    }
                    default:
                        return state;
                }
            }
        }),
        shared: combineReducers({
            // byId: (state = {}, action) => state,
            // allIds: (state = [], action) => state ,
            allEntityIds: (state = [], action) => {
                const { type, payload } = action;
                switch (type) {
                    case USER_LOGGED_IN: {
                        const { shared: entities } = payload.folders;
                        const folderIds = Object.keys(entities).map((key) => entities[key].folderId);
                        // needs to move somewhere else
                        folderIds.push('shared');
                        return folderIds;
                    }
                    case FOLDER_ADD_SUCCESS: {
                        const { shared: entities } = payload.folders;
                        const folderIds = Object.keys(entities).map((key) => entities[key].folderId);
                        if (folderIds.length > 0) {
                            return [...state, ...folderIds];
                        }
                        return state;
                    }
                    case FOLDERS_AND_DASHBOARDS_DELETE_SUCCESS: {
                        const { folderIds } = payload;
                        if (folderIds.length > 0) {
                            return _difference(state, folderIds);
                        }
                        return state;
                    }

                    case FOLDERS_AND_DASHBOARDS_MOVE_TO_FOLDER_SUCCESS: {
                        const {
                            foldersResponses
                        } = payload;

                        if (foldersResponses.length > 0) {
                            let newState = state;
                            foldersResponses.forEach((folderResponse) => {
                                const { movedFolder, affectedFolders } = folderResponse;
                                const entityIds = getIdsFromOwnershipEntity(movedFolder, 'folder');
                                const entitiesIds = getIdsFromOwnershipEntities(affectedFolders, 'folder');
                                newState = addOrRemove(
                                    newState,
                                    [...entitiesIds.sharedIds, ...entityIds.sharedIds],
                                    [...entitiesIds.userIds, ...entityIds.userIds]
                                );
                            });
                            return newState;
                        }
                        return state;
                    }
                    default:
                        return state;
                }
            }
        }),
        lookup: (state = {}, action) => {
            const { type, payload } = action;
            switch (type) {
                case USER_LOGGED_IN: {
                    const { shared, user } = payload.folders;
                    const newState = Object.assign({}, state);
                    Object.keys(shared).forEach((key) => {
                        Object.assign(newState, { [shared[key].folderId]: 'shared' });
                    });
                    Object.keys(user).forEach((key) => {
                        Object.assign(newState, { [user[key].folderId]: 'user' });
                    });

                    // move out to initial
                    Object.assign(newState, { user: 'user', shared: 'shared' });
                    return newState;
                }

                case FOLDER_ADD_SUCCESS: {
                    const { shared, user } = payload.folders;
                    const newState = Object.assign({}, state);
                    Object.keys(shared).forEach((key) => {
                        Object.assign(newState, { [shared[key].folderId]: 'shared' });
                    });
                    Object.keys(user).forEach((key) => {
                        Object.assign(newState, { [user[key].folderId]: 'user' });
                    });
                    return newState;
                }
                case FOLDERS_AND_DASHBOARDS_DELETE_SUCCESS: {
                    const { folderIds } = payload;
                    if (folderIds.length > 0) {
                        let newState = Object.assign({}, state);
                        folderIds.forEach((folderId) => {
                            newState = _omit(newState, folderId);
                        });
                        return newState;
                    }
                    return state;
                }

                case FOLDERS_AND_DASHBOARDS_MOVE_TO_FOLDER_SUCCESS: {
                    const {
                        foldersResponses
                    } = payload;

                    if (foldersResponses.length > 0) {
                        const newState = Object.assign({}, state);
                        foldersResponses.forEach((folderResponse) => {
                            const { movedFolder, affectedFolders } = folderResponse;
                            const entityIds = getIdsFromOwnershipEntity(movedFolder, 'folder');
                            const entitiesIds = getIdsFromOwnershipEntities(affectedFolders, 'folder');

                            [...entitiesIds.sharedIds, ...entityIds.sharedIds].forEach((id) => {
                                Object.assign(newState, { [id]: 'shared' });
                            });
                            [...entitiesIds.userIds, ...entityIds.userIds].forEach((id) => {
                                Object.assign(newState, { [id]: 'user' });
                            });
                        });
                        return newState;
                    }
                    return state;
                }
                default:
                    return state;
            }
        }
    }),
    dashboard: combineReducers({
        user: combineReducers({
            // byId: (state = {}, action) => state,
            // allIds: (state = [], action) => state ,
            allEntityIds: (state = [], action) => {
                const { type, payload } = action;
                switch (type) {
                    case USER_LOGGED_IN:
                    case DASHBOARDS_GET_SUCCESS: {
                        const { user: entities } = payload.dashboards;
                        const dashboardIds = Object.keys(entities).map((key) => entities[key].dashboardId);
                        return dashboardIds;
                    }
                    case DASHBOARD_ADD_SUCCESS:
                    case DASHBOARD_CLONE_DASHBOARD_TEMPLATE_SUCCESS:
                    case DASHBOARD_CLONE_SUCCESS: {
                        const { userDashboard: entity } = payload;
                        if (entity) {
                            return [...state, entity.dashboardId];
                        }
                        return state;
                    }
                    case FOLDERS_AND_DASHBOARDS_DELETE_SUCCESS: {
                        const { dashboardIds } = payload;
                        if (dashboardIds.length > 0) {
                            return _difference(state, dashboardIds);
                        }
                        return state;
                    }

                    case FOLDERS_AND_DASHBOARDS_MOVE_TO_FOLDER_SUCCESS: {
                        const {
                            foldersResponses, dashboardsResponses
                        } = payload;
                        if (foldersResponses.length < 1 && dashboardsResponses.length < 1) {
                            return state;
                        }
                        let newState = state;
                        foldersResponses.forEach((folderResponse) => {
                            const { affectedDashboards } = folderResponse;
                            const { sharedIds, userIds } = getIdsFromOwnershipEntities(affectedDashboards, 'dashboard');
                            newState = addOrRemove(
                                newState,
                                userIds,
                                sharedIds
                            );
                        });

                        dashboardsResponses.forEach((dashboardResponse) => {
                            const { movedDashboard } = dashboardResponse;
                            const { sharedIds, userIds } = getIdsFromOwnershipEntity(movedDashboard, 'dashboard');
                            newState = addOrRemove(
                                newState,
                                userIds,
                                sharedIds
                            );
                        });

                        return newState;
                    }

                    default:
                        return state;
                }
            }
        }),
        shared: combineReducers({
            // byId: (state = {}, action) => state,
            // allIds: (state = [], action) => state ,
            allEntityIds: (state = [], action) => {
                const { type, payload } = action;
                switch (type) {
                    case USER_LOGGED_IN:
                    case DASHBOARDS_GET_SUCCESS: {
                        const { shared: entities } = payload.dashboards;
                        const dashboardIds = Object.keys(entities).map((key) => entities[key].dashboardId);
                        return dashboardIds;
                    }
                    case DASHBOARD_ADD_SUCCESS:
                    case DASHBOARD_CLONE_DASHBOARD_TEMPLATE_SUCCESS:
                    case DASHBOARD_CLONE_SUCCESS: {
                        const { sharedDashboard: entity } = payload;
                        if (entity) {
                            return [...state, entity.dashboardId];
                        }
                        return state;
                    }
                    case FOLDERS_AND_DASHBOARDS_DELETE_SUCCESS: {
                        const { dashboardIds } = payload;
                        if (dashboardIds.length > 0) {
                            return _difference(state, dashboardIds);
                        }
                        return state;
                    }

                    case FOLDERS_AND_DASHBOARDS_MOVE_TO_FOLDER_SUCCESS: {
                        const {
                            foldersResponses, dashboardsResponses
                        } = payload;

                        if (foldersResponses.length < 1 && dashboardsResponses.length < 1) {
                            return state;
                        }

                        let newState = state;
                        foldersResponses.forEach((folderResponse) => {
                            const { affectedDashboards } = folderResponse;
                            const { sharedIds, userIds } = getIdsFromOwnershipEntities(affectedDashboards, 'dashboard');
                            newState = addOrRemove(
                                newState,
                                sharedIds,
                                userIds
                            );
                        });

                        dashboardsResponses.forEach((dashboardResponse) => {
                            const { movedDashboard } = dashboardResponse;
                            const { sharedIds, userIds } = getIdsFromOwnershipEntity(movedDashboard, 'dashboard');
                            newState = addOrRemove(
                                newState,
                                sharedIds,
                                userIds
                            );
                        });
                        return newState;
                    }
                    default:
                        return state;
                }
            }
        }),
        lookup: (state = {}, action) => {
            const { type, payload } = action;
            switch (type) {
                case USER_LOGGED_IN:
                case DASHBOARDS_GET_SUCCESS: {
                    const { shared, user } = payload.dashboards;
                    const newState = {};
                    Object.keys(shared).forEach((key) => {
                        Object.assign(newState, { [shared[key].dashboardId]: 'shared' });
                    });
                    Object.keys(user).forEach((key) => {
                        Object.assign(newState, { [user[key].dashboardId]: 'user' });
                    });
                    return newState;
                }
                case DASHBOARD_ADD_SUCCESS:
                case DASHBOARD_CLONE_DASHBOARD_TEMPLATE_SUCCESS:
                case DASHBOARD_CLONE_SUCCESS: {
                    const { sharedDashboard, userDashboard } = payload;
                    if (sharedDashboard) {
                        return Object.assign({}, state, { [sharedDashboard.dashboardId]: 'shared' });
                    }
                    if (userDashboard) {
                        return Object.assign({}, state, { [userDashboard.dashboardId]: 'user' });
                    }
                    return state;
                }
                case FOLDERS_AND_DASHBOARDS_DELETE_SUCCESS: {
                    const { dashboardIds } = payload;
                    if (dashboardIds.length > 0) {
                        let newState = Object.assign({}, state);
                        dashboardIds.forEach((dashboardId) => {
                            newState = _omit(newState, dashboardId);
                        });
                        return newState;
                    }
                    return state;
                }

                case FOLDERS_AND_DASHBOARDS_MOVE_TO_FOLDER_SUCCESS: {
                    const {
                        foldersResponses, dashboardsResponses
                    } = payload;
                    if (foldersResponses.length < 1 && dashboardsResponses < 1) {
                        return state;
                    }
                    const newState = Object.assign({}, state);

                    foldersResponses.forEach((folderResponse) => {
                        const { affectedDashboards } = folderResponse;
                        const { sharedIds, userIds } = getIdsFromOwnershipEntities(affectedDashboards, 'dashboard');
                        sharedIds.forEach((id) => {
                            Object.assign(newState, { [id]: 'shared' });
                        });
                        userIds.forEach((id) => {
                            Object.assign(newState, { [id]: 'user' });
                        });
                    });

                    dashboardsResponses.forEach((dashboardsResponse) => {
                        const { movedDashboard } = dashboardsResponse;
                        const { sharedIds, userIds } = getIdsFromOwnershipEntity(movedDashboard, 'dashboard');
                        sharedIds.forEach((id) => {
                            Object.assign(newState, { [id]: 'shared' });
                        });
                        userIds.forEach((id) => {
                            Object.assign(newState, { [id]: 'user' });
                        });
                    });
                    return newState;
                }
                default:
                    return state;
            }
        }
    }),
});
