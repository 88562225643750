import * as customPropTypes from 'src/customPropTypes';
import _range from 'lodash/range';
import AnalysisPublicMetricTile from 'src/components/metricSearchResultTile/AnalysisPublicMetricTile';
import EmptyAnalysisPublicMetricTileSkeleton from 'src/components/loadingIndicators/EmptyAnalysisPublicMetricTileSkeleton';
import Feedback from 'src/components/feedback/Feedback';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/views/analysis/analysisRecommendations.scss';
import withStoreConnection from 'src/components/metricSearchResultTile/withStoreConnection';

const AnalysisPublicMetricTileWithStoreConnection = withStoreConnection(AnalysisPublicMetricTile);

const AnalysisRecommendations = (props) => {
    const { isLoading, error, results } = props;
    if (isLoading) {
        const skeletons = _range(3);
        return (
            <div className={styles.container}>
                {
                    skeletons.map((id) => (
                        <div key={`loading-${id}`}>
                            <EmptyAnalysisPublicMetricTileSkeleton />
                        </div>
                    ))
                }
            </div>
        );
    } if (error) {
        return <Feedback type="error" content="The recommendations couldn't be loaded. Please try again later or contact our support team." />;
    } if (results) {
        if (results.length > 0) {
            return (
                <div className={styles.container}>
                    {
                        results.map((result) => (
                            <div key={`recommendationItem-${result.type}-${result.id}`}>
                                <AnalysisPublicMetricTileWithStoreConnection type={result.type} id={result.id} />
                            </div>
                        ))
                    }
                </div>
            );
        }
        return (
            <Feedback type="warning" content="We could not find any recommendations for you. Please try again later or contact our support team." />
        );
    }
    return null;
};

AnalysisRecommendations.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    results: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired
    })),
    error: customPropTypes.dataLoadingError
};

export default AnalysisRecommendations;
