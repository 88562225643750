import RequestInfoSummary from 'src/components/filter/RequestInfoSummary';
import React, { Component } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import * as routeActions from 'react-router-redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/metricTile.scss';
import classnames from 'classnames';
import { getRequestInfoFromDataOrError } from 'src/utils/serverData';
import NotesBox from 'src/components/metricTiles/layout/NotesBox';
import _get from 'lodash/get';
import GridDataHeader from './GridDataHeader';
import MetricTile from './MetricTile';
import DataBody from './DataBody';
import { getFiltersWithCustomBindingFromDashboardMetricSettings } from './utils';

class GridDataTile extends Component {
    constructor(props) {
        super(props);
        this.showMetricDetails = this.showMetricDetails.bind(this);
    }

    showMetricDetails() {
        const { routePushAction, dashboardMetricLink } = this.props;
        routePushAction(dashboardMetricLink);
    }

    render() {
        const {
            dashboardMetric,
            selectedDate,
            selectedProfilesOrGroups,
            accountOptions,
            serverData,
            breakpoint,
            isAllowedToChangeLayout,
            dashboardMetricLink,
            lastUpdatedTime,
            isGridLoading
        } = this.props;
        const { settings, metric } = dashboardMetric;
        const { data, loading, error } = serverData;

        const requestInfo = getRequestInfoFromDataOrError(data, error);
        const filtersWithCustomBinding = getFiltersWithCustomBindingFromDashboardMetricSettings(settings);

        return (
            <MetricTile>
                <GridDataHeader
                  dragHandleClassName="dragHandle"
                  metric={metric}
                  dashboardMetric={dashboardMetric}
                  breakpoint={breakpoint}
                  isAllowedToChangeLayout={isAllowedToChangeLayout}
                  isGridLoading={isGridLoading}
                  showMetricDetailsAction={this.showMetricDetails}
                  dashboardMetricLink={dashboardMetricLink}
                  loading={loading}
                  lastUpdatedTime={lastUpdatedTime}
                />
                {
                    requestInfo
                    && (
                        <div className={classnames(styles.filterWrapper, styles.gridFilterWrapper)}>
                            <RequestInfoSummary requestInfo={requestInfo} filtersWithCustomBinding={filtersWithCustomBinding} />
                        </div>
                    )
                }
                <div className={styles.metricBodyWrapper}>
                    <DataBody
                      selectedDate={selectedDate}
                      selectedProfilesOrGroups={selectedProfilesOrGroups}
                      accountOptions={accountOptions}
                      metric={metric}
                      settings={settings}
                      data={data}
                      loading={loading}
                      error={error}
                      lazyLoadChart
                    />
                </div>
                <div className={classnames(styles.filterWrapper, styles.notesWrapper, styles.notesHover)}>
                    <NotesBox notes={_get(dashboardMetric, 'settings.notes')} dashboardMetricId={dashboardMetric.id} />
                </div>
            </MetricTile>
        );
    }
}

GridDataTile.propTypes = {
    selectedProfilesOrGroups: customPropTypes.profileFilter.isRequired,
    selectedDate: customPropTypes.selectedDate.isRequired,
    accountOptions: customPropTypes.accountOptions.isRequired,
    dashboardMetric: customPropTypes.dashboardMetric.isRequired,
    routePushAction: PropTypes.func.isRequired,
    breakpoint: customPropTypes.gridBreakpoints.isRequired,
    isAllowedToChangeLayout: PropTypes.bool,
    isGridLoading: PropTypes.bool,
    dashboardMetricLink: customPropTypes.linkTo.isRequired,
    lastUpdatedTime: PropTypes.number,
    serverData: PropTypes.object.isRequired
};

GridDataTile.defaultProps = {
    isAllowedToChangeLayout: true,
    isGridLoading: false
};

export default connect(null, {
    routePushAction: routeActions.push,
})(GridDataTile);
