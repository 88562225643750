import {
    POST_TAGS_POSTS_GET_REQUEST,
    postTagsPostInfoGetError,
    postTagsPostInfGetSuccess
} from 'src/actions/postTagsPostInfo';
import {
    POST_SEARCH_BY_TAG_SUCCESS,
    POST_TAG_ADD_REQUEST,
    POST_TAGS_REMOVE_SUCCESS,
    POST_TAGS_ARCHIVE_SUCCESS,
    POST_TAGS_FORCE_ARCHIVE_SUCCESS,
    POST_TAG_AUTOMATION_ACTIVATE_SUCCESS,
    POST_TAG_FORCE_REMOVE_SUCCESS
} from 'src/actions/postTags';
import { POST_TAG_RULE_ADD_SUCCESS, POST_TAG_RULE_UPDATE_SUCCESS, POST_TAG_RULE_DELETE_SUCCESS } from 'src/actions/postTagRule';
import {
    ADD_REMOVE_POST_TAGS_FROM_POSTS_SUCCESS,
    ADD_REMOVE_POST_TAGS_FROM_POST_SUCCESS,
    ADD_REMOVE_POST_TAGS_FROM_POSTS_BY_FILTER_SUCCESS
} from 'src/actions/posts';
import { put, takeEvery } from 'redux-saga/effects';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import { parsePostTagsPostsInfo } from 'src/parsers';

function* numberOfPostsByPostTagGetRequest() {
    try {
        const serverRequest = createServerRequest();
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/get-post-tags-posts-info');
        if (response) {
            const { postTagsPostsInfo } = response.jsonData;
            yield put(postTagsPostInfGetSuccess(parsePostTagsPostsInfo(postTagsPostsInfo)));
        }

        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(postTagsPostInfoGetError(applicationError));
    }
}

export default function* postTagsPostInfoSagas() {
    yield takeEvery(
        [
            POST_TAGS_POSTS_GET_REQUEST,
            ADD_REMOVE_POST_TAGS_FROM_POSTS_SUCCESS,
            ADD_REMOVE_POST_TAGS_FROM_POST_SUCCESS,
            ADD_REMOVE_POST_TAGS_FROM_POSTS_BY_FILTER_SUCCESS,
            POST_TAG_ADD_REQUEST,
            POST_TAGS_REMOVE_SUCCESS,
            POST_TAG_FORCE_REMOVE_SUCCESS,
            POST_TAGS_ARCHIVE_SUCCESS,
            POST_TAGS_FORCE_ARCHIVE_SUCCESS,
            POST_TAG_AUTOMATION_ACTIVATE_SUCCESS,
            POST_TAG_RULE_ADD_SUCCESS,
            POST_TAG_RULE_UPDATE_SUCCESS,
            POST_TAG_RULE_DELETE_SUCCESS,
            POST_SEARCH_BY_TAG_SUCCESS
        ],
        numberOfPostsByPostTagGetRequest
    );
}
