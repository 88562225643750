import DashboardsFilterBarForm from 'src/components/views/dashboards/DashboardsFilterBarForm';
import React from 'react';
import styles from 'src/stylesheets/listHeader.scss';
import classnames from 'classnames';

const AllDashboardsListHeader = () => (
    <div className={styles.listHeader}>
        <div className={classnames(styles.filterBar, styles.withoutCheckbox)}>
            <DashboardsFilterBarForm />
        </div>
    </div>
);

export default AllDashboardsListHeader;
