import BottomMenu from 'src/components/navigations/mainNav/BottomMenu';
import React from 'react';
import styles from 'src/stylesheets/navigations/sidebarNav.scss';
import TopMenu from 'src/components/navigations/mainNav/TopMenu';

const SidebarNav = () => (
    <div className={styles.sidebarNav}>
        <div className={styles.topMenus}>
            <TopMenu />
        </div>
        <div className={styles.bottomMenu}>
            <BottomMenu />
        </div>
    </div>
);

export default SidebarNav;
