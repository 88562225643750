import React from 'react';
import { Link } from 'react-router';
import styles from 'src/stylesheets/limitStatus.scss';
import PropTypes from 'prop-types';
import { selectIsAdmin } from 'src/selectors/loggedInUser';
import { connect } from 'react-redux';

const ProfileLimitsLinks = ({ isAdmin, openPlanInNewTab }) => (
    <div className={styles.more}>
        <div className={styles.moreLink}>
            <a
              href="https://support.quintly.com/en/articles/4412201-controlling-your-profile-swap-and-report-limit"
              target="_blank"
              rel="noopener noreferrer"
            >
                Learn more
            </a>
        </div>
        {isAdmin
            && (
                <div className={styles.moreLink}>
                    <Link to="/settings/plan" target={openPlanInNewTab ? '_blank' : undefined}>View plan</Link>
                </div>
            )}
    </div>
);

ProfileLimitsLinks.propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    openPlanInNewTab: PropTypes.bool
};

ProfileLimitsLinks.defaultProps = {
    openPlanInNewTab: false
};

const mapStateToProps = (state) => ({
    isAdmin: selectIsAdmin(state)
});
export default connect(mapStateToProps)(ProfileLimitsLinks);
