import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';
import { googleBigQueryType } from 'src/utils/dataPushTasks';
import { makeSelectDataPushTaskDestinationById } from 'src/selectors/dataPushTaskDestination';
import AwsS3DataPushTaskDestination from 'src/components/views/integrations/dataPushTasks/dataPushTaskDetail/dataPushTaskDestination/AwsS3DataPushTaskDestination';
import GoogleBigQueryDataPushTaskDestination from 'src/components/views/integrations/dataPushTasks/dataPushTaskDetail/dataPushTaskDestination/GoogleBigQueryDataPushTaskDestination';
import ContentBlock from 'src/components/views/ContentBlock';
import DataPushTaskDestinationTestButton from 'src/components/buttons/DataPushTaskDestinationTestButton';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import GoogleBigQueryDestinationCreateTableButton from 'src/components/buttons/GoogleBigQueryDestinationCreateTableButton';
import Button from 'src/components/buttons/Button';
import * as overlayAction from 'src/actions/overlays';

const DataPushTaskDestinationContainer = (props) => {
    const { dataPushTaskDestination, modalShowDataPushTaskDestinationEditAction } = props;
    const { destinationType } = dataPushTaskDestination;
    const footerRight = (
        <ButtonGroup>
            <Button label="Edit" icon="edit" onClick={() => { modalShowDataPushTaskDestinationEditAction(dataPushTaskDestination.id); }} />
            <DataPushTaskDestinationTestButton dataPushTaskDestination={dataPushTaskDestination} />
            {
                destinationType === googleBigQueryType && (
                    <GoogleBigQueryDestinationCreateTableButton dataPushTaskDestination={dataPushTaskDestination} />
                )
            }
        </ButtonGroup>
    );
    return (
        <ContentBlock headline="Where to be pushed" footerRight={footerRight}>
            {
                destinationType === googleBigQueryType
                    ? <GoogleBigQueryDataPushTaskDestination googleBigQueryDataPushTaskDestination={dataPushTaskDestination} />
                    : <AwsS3DataPushTaskDestination awsS3DataPushTaskDestination={dataPushTaskDestination} />
            }
        </ContentBlock>
    );
};

DataPushTaskDestinationContainer.propTypes = {
    destinationId: PropTypes.number.isRequired,
    dataPushTaskDestination: customPropTypes.dataPushTaskDestination.isRequired,
    modalShowDataPushTaskDestinationEditAction: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const selectDataPushTaskDestinationById = makeSelectDataPushTaskDestinationById();
    return (state, ownProps) => ({
        dataPushTaskDestination: selectDataPushTaskDestinationById(state, ownProps.destinationId)
    });
};

export default connect(makeMapStateToProps, {
    modalShowDataPushTaskDestinationEditAction: overlayAction.modalShowDataPushTaskDestinationEdit
})(DataPushTaskDestinationContainer);
