import {
    GET_DATA_SOURCES_ERROR,
    GET_DATA_SOURCES_REQUEST,
    GET_DATA_SOURCES_SUCCESS
} from 'src/actions/dataSources';
import { combineReducers } from 'redux';
import { createAsyncStatesReducerForActionsWithErrorAndSuccessStates } from 'src/reducers/utils';

const allIds = (state = [], action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_DATA_SOURCES_SUCCESS: {
            const { dataSources } = payload;
            return Object.keys(dataSources);
        }
        default:
            return state;
    }
};

const byIds = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_DATA_SOURCES_SUCCESS: {
            const { dataSources } = payload;
            return Object.assign({}, state, dataSources);
        }
        default:
            return state;
    }
};

const columnsByDataSourceId = (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_DATA_SOURCES_SUCCESS: {
            const { dataSourceColumns } = payload;
            return Object.assign({}, state, dataSourceColumns);
        }
        default:
            return state;
    }
};

const asyncStatesByAction = combineReducers({
    getData: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        () => 'dataSources',
        [GET_DATA_SOURCES_REQUEST],
        [GET_DATA_SOURCES_SUCCESS],
        [GET_DATA_SOURCES_ERROR]
    )
});

export const dataSources = combineReducers({
    allIds,
    byIds,
    columnsByDataSourceId,
    asyncStatesByAction
});
