import Body from 'src/components/Body';
import APIHeader from 'src/components/header/APIHeader';
import React from 'react';
import ScrollView from 'src/components/ScrollView';
import Footer from 'src/components/Footer';
import * as customPropTypes from 'src/customPropTypes';
import withScrollPosition from 'src/components/withScrollPosition';
import AdminSettingsLoader from 'src/components/views/settings/AdminSettingsLoader';
import { getApiAccess } from 'src/selectors/adminSettings';
import APIInfo from 'src/components/views/integrations/api/APIInfo';
import APIEmptyState from 'src/components/views/integrations/api/APIEmptyState';

const ScrollViewWithScrollPosition = withScrollPosition(ScrollView, 'dataPushTasks');

const API = (props) => {
    const { location } = props;
    return (
        <ScrollViewWithScrollPosition
          scrollPositionIdentifier="dataPushTasks"
          locationKey={location.key}
          dataViewName="data-push-tasks"
        >
            <APIHeader />
            <Body>
                <AdminSettingsLoader dataSelector={getApiAccess} alwaysReload={false}>
                    {
                        (apiAccess) => (
                            apiAccess.enabled
                                ? <APIInfo apiAccess={apiAccess} />
                                : <APIEmptyState />
                        )
                    }
                </AdminSettingsLoader>
            </Body>
            <Footer />
        </ScrollViewWithScrollPosition>
    );
};

API.propTypes = {
    location: customPropTypes.location.isRequired
};

export default API;
