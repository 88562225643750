import { updateUserFirstNameRequest, updateUserLastNameRequest } from 'src/actions/loggedInUser';
import IconButton from 'src/components/buttons/IconButton';
import { getTimezoneStringWithAbbrev } from 'src/components/dateSelector/utils';
import EditableContent from 'src/components/EditableContent';
import { makeLengthValidator } from 'src/components/forms/validators';
import * as customPropTypes from 'src/customPropTypes';
import ContentBlock from 'src/components/views/ContentBlock';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import DetailText from 'src/components/views/DetailText';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { selectAccountOptions, selectIsAdmin } from 'src/selectors/loggedInUser';
import { selectIsUpdateUserFirstNamePending, selectIsUpdateUserLastNamePending } from 'src/selectors/users';
import styles from 'src/stylesheets/views/settings/accountDetail.scss';
import UserIconWithGravatarInfo from 'src/components/views/settings/UserIconWithGravatarInfo';
import withTooltip from 'src/components/withToolTip';

const UserIconWithGravatarInfoWithTooltip = withTooltip(UserIconWithGravatarInfo);

const lengthValidator = makeLengthValidator(1, 40);

const AccountDetail = (props) => {
    const {
        user, accountOptions, updateFirstNameAction, updateLastNameAction, isUpdateUserFirstNamePending, isUpdateUserLastNamePending
    } = props;
    const { firstName, lastName, email } = user;

    const [isFirstNameEditMode, setFirstNameEditMode] = useState(false);
    const [isLastNameEditMode, setLastNameEditMode] = useState(false);

    const timezoneWithAbbrev = getTimezoneStringWithAbbrev(accountOptions.timezone, moment());
    return (
        <ContentBlock headline="User information">
            <div className={styles.wrapper}>
                <div>
                    <div className={styles.userInfo}>
                        <DetailText label="First name">
                            <EditableContent
                              loading={isUpdateUserFirstNamePending}
                              validate={lengthValidator}
                              onLeave={() => {
                                  setFirstNameEditMode(false);
                              }}
                              onChange={(value) => {
                                  updateFirstNameAction(value);
                              }}
                              editMode={isFirstNameEditMode}
                              onDoubleClick={() => {
                                  setFirstNameEditMode(true);
                              }}
                              value={firstName}
                            >
                                <div className={styles.editableWrapper}>
                                    <div>{firstName}</div>
                                    <div className={styles.editButton}><IconButton icon="edit" onClick={() => { setFirstNameEditMode(true); }} /></div>
                                </div>
                            </EditableContent>
                        </DetailText>
                    </div>
                    <DetailText label="Email">{email}</DetailText>
                </div>
                <div>
                    <div className={styles.userInfo}>
                        <DetailText label="Last name">
                            <EditableContent
                              loading={isUpdateUserLastNamePending}
                              validate={lengthValidator}
                              onLeave={() => {
                                  setLastNameEditMode(false);
                              }}
                              onChange={(value) => {
                                  updateLastNameAction(value);
                              }}
                              editMode={isLastNameEditMode}
                              onDoubleClick={() => {
                                  setLastNameEditMode(true);
                              }}
                              value={lastName}
                            >
                                <div className={styles.editableWrapper}>
                                    <div>{lastName}</div>
                                    <div className={styles.editButton}><IconButton icon="edit" onClick={() => { setLastNameEditMode(true); }} /></div>
                                </div>
                            </EditableContent>
                        </DetailText>
                    </div>
                    <DetailText label="Timezone">{timezoneWithAbbrev}</DetailText>
                </div>
                <DetailText label="">
                    <UserIconWithGravatarInfoWithTooltip
                      user={user}
                      tooltip="We are using the picture related to your email on Gravatar."
                    />
                </DetailText>
            </div>
        </ContentBlock>
    );
};

AccountDetail.propTypes = {
    user: customPropTypes.user.isRequired,
    accountOptions: customPropTypes.accountOptions.isRequired,
    updateFirstNameAction: PropTypes.func.isRequired,
    updateLastNameAction: PropTypes.func.isRequired,
    isUpdateUserFirstNamePending: PropTypes.bool.isRequired,
    isUpdateUserLastNamePending: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
    isAdmin: selectIsAdmin(state),
    accountOptions: selectAccountOptions(state),
    isUpdateUserFirstNamePending: selectIsUpdateUserFirstNamePending(state),
    isUpdateUserLastNamePending: selectIsUpdateUserLastNamePending(state)
});

export default connect(mapStateToProps, {
    updateFirstNameAction: updateUserFirstNameRequest,
    updateLastNameAction: updateUserLastNameRequest
})(AccountDetail);
