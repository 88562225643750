import React from 'react';
import Sidebar from 'src/components/sidebar/Sidebar';
import { connect } from 'react-redux';
import styles from 'src/stylesheets/views/reports/reportsSidebar.scss';
import Button from 'src/components/buttons/Button';
import PropTypes from 'prop-types';
import IntercomVerticalWhiteSpace from 'src/components/sidebar/IntercomVerticalWhiteSpace';
import SelectedEntityCountWithClearOption from 'src/components/views/SelectedEntityCountWithClearOption';
import { listName } from 'src/utils/reportOverviews';
import ReportsOverviewList from 'src/components/views/reports/sidebar/ReportsOverviewList';
import { modalShowReportOverviewDelete } from 'src/actions/overlays';
import { makeSelectCheckedValues } from 'src/selectors/lists';

const ReportsOverviewSidebar = (props) => {
    const { modalShowReportOverviewDeleteAction, reportOverviewIds } = props;
    return (
        <Sidebar>
            <div className={styles.wrapper}>
                <div className={styles.controls}>
                    <Button
                      small
                      stretch
                      label="Delete"
                      className={styles.button}
                      onClick={() => { modalShowReportOverviewDeleteAction(reportOverviewIds); }}
                    />
                </div>
                <div className={styles.selectionInfo}>
                    <SelectedEntityCountWithClearOption label="Selected reports" count={reportOverviewIds.length} listName={listName} />
                </div>
                <div className={styles.reportsOverviews}>
                    <ReportsOverviewList reportOverviewIds={reportOverviewIds} />
                    <IntercomVerticalWhiteSpace />
                </div>
            </div>
        </Sidebar>
    );
};

ReportsOverviewSidebar.propTypes = {
    reportOverviewIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    modalShowReportOverviewDeleteAction: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const selectCheckedValues = makeSelectCheckedValues();
    return (state) => ({
        reportOverviewIds: selectCheckedValues(state, listName),
    });
};

export default connect(makeMapStateToProps, {
    modalShowReportOverviewDeleteAction: modalShowReportOverviewDelete
})(ReportsOverviewSidebar);
