import React, { PureComponent } from 'react';
import IcomoonIcon from 'src/components/IcomoonIcon';
import NetworkIcon from 'src/components/NetworkIcon';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/discoverTileHeader.scss';
import withTooltip from 'src/components/withToolTip';

const IcomoonIconWithTooltip = withTooltip(IcomoonIcon);

class DiscoverTileHeader extends PureComponent {
    render() {
        const {
            platformType, isBasedOnPrivateStatistics, name
        } = this.props;
        return (
            <div className={styles.header}>
                {
                    platformType
                    && (
                    <NetworkIcon
                      platformType={platformType}
                      className={styles.networkIcon}
                    />
                    )
                }
                <span className={styles.name}>{name}</span>
                {
                    isBasedOnPrivateStatistics
                    && (
                    <IcomoonIconWithTooltip
                      icon="key"
                      tooltip="Private statistics analysis"
                      className={styles.icon}
                    />
                    )
                }
            </div>
        );
    }
}

DiscoverTileHeader.propTypes = {
    platformType: PropTypes.string,
    isBasedOnPrivateStatistics: PropTypes.bool,
    name: PropTypes.string.isRequired
};

DiscoverTileHeader.defaultProps = {
    isBasedOnPrivateStatistics: false
};

export default DiscoverTileHeader;
