import React from 'react';
import PostTagsTable from 'src/components/views/postTags/postTagManager/PostTagsTable';
import PropTypes from 'prop-types';
import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import emptyImage from 'src/resources/illustrations/tags_empty.png';
import Button from 'src/components/buttons/Button';
import * as overlayAction from 'src/actions/overlays';
import { connect } from 'react-redux';
import { ensurePermission, writePermissions } from 'src/middleware/userPermissionCheck';
import styles from 'src/stylesheets/views/postTags/postTagManager/postTag.scss';

const PostTags = (props) => {
    const { postTagIds, listName, modalShowPostTagAddAction } = props;
    if (postTagIds.length > 0) {
        return <PostTagsTable listName={listName} />;
    }

    return (
        <div className={styles.wrapper}>
            <EmptyInfoScreen
              image={emptyImage}
              title="Welcome to Tag manager"
              message="Here you can create and manage all of your post tags. Get started by creating your first tag."
              imageClassName={styles.emptyTag}
              buttons={<Button label="Add tag" icon="plus" action onClick={() => { modalShowPostTagAddAction(); }} />}
            />
        </div>
    );
};

PostTags.propTypes = {
    listName: PropTypes.string.isRequired,
    postTagIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    modalShowPostTagAddAction: PropTypes.func.isRequired
};

export default connect(null, {
    modalShowPostTagAddAction: ensurePermission(overlayAction.modalShowPostTagAdd, writePermissions.addPostTag)
})(PostTags);
