import * as customPropTypes from 'src/customPropTypes';
import { Highlighter } from 'react-bootstrap-typeahead';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/autocompleteMenuItem.scss';

const AutocompleteMenuTermItem = (props) => {
    const {
        autocompleteSuggestionTerm, searchTerm
    } = props;
    const { item } = autocompleteSuggestionTerm;
    return (
        <div
          className={styles.item}
        >
            Full-text search for: <Highlighter search={searchTerm}>{item.term}</Highlighter>
        </div>
    );
};

AutocompleteMenuTermItem.propTypes = {
    autocompleteSuggestionTerm: customPropTypes.autocompleteSuggestionTerm.isRequired,
    searchTerm: PropTypes.string.isRequired
};

export default AutocompleteMenuTermItem;
