import classnames from 'classnames';
import MainNavLabel from 'src/components/navigations/mainNav/MainNavLabel';
import MainNavTextLink from 'src/components/buttons/MainNavTextLink';
import { notificationRoutes } from 'src/routePaths';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/userNotificationMenu/userNotificationIndicator.scss';

const UserNotificationIndicator = (props) => {
    const { hasNotification, onClick, active } = props;
    return (
        <MainNavTextLink onClick={onClick} alsoActiveOn={notificationRoutes} active={active}>
            <MainNavLabel
              label="Notifications"
              icon={hasNotification ? 'notification-yes' : 'notification-no'}
              className={classnames({ [styles.hasNotification]: hasNotification, [styles.active]: active })}
            />
        </MainNavTextLink>
    );
};

UserNotificationIndicator.propTypes = {
    onClick: PropTypes.func.isRequired,
    hasNotification: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired
};

export default UserNotificationIndicator;
