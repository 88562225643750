import * as metricSearchActionCreators from 'src/actions/metricSearch';
import _omit from 'lodash/omit';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

const withTagClickBehavior = (WrappedComponent) => {
    const WithTagClickBehavior = (props) => {
        const { metricSearchSelectionChangedAction, metricSearchSelectionReplaceAction, filterBy } = props;
        const cleanedProps = _omit(props, 'filterBy');
        return (
            <WrappedComponent
              {...cleanedProps}
              onTagClick={(tag) => { metricSearchSelectionReplaceAction(tag, filterBy); }}
              onTagMetaKeyClick={(tag) => { metricSearchSelectionChangedAction(tag, filterBy); }}
            />
        );
    };

    WithTagClickBehavior.propTypes = {
        metricSearchSelectionChangedAction: PropTypes.func.isRequired,
        metricSearchSelectionReplaceAction: PropTypes.func.isRequired,
        filterBy: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
    };

    WithTagClickBehavior.defaultProps = {
        filterBy: false
    };

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    WithTagClickBehavior.displayName = `withTagClickBehavior(${wrappedComponentName})`;

    return connect(null, {
        metricSearchSelectionChangedAction: metricSearchActionCreators.metricSearchSelectionChanged,
        metricSearchSelectionReplaceAction: metricSearchActionCreators.metricSearchSelectionReplace,
    })(WithTagClickBehavior);
};

export default withTagClickBehavior;
