import React from 'react';
import TopNav from 'src/components/navigations/TopNav';
import TopNavTextLink from 'src/components/buttons/TopNavTextLink';
import { connect } from 'react-redux';
import * as routeActions from 'react-router-redux';
import { makeSelectPostTaggingRoute } from 'src/selectors/posts';
import * as customPropTypes from 'src/customPropTypes';
import { tagManagerRoute, postsRoute } from 'src/routePaths';
import { POST_TAGGING } from 'src/utils/filterSelectors';

const PostTagsNav = ({ link }) => (
    <TopNav>
        <TopNavTextLink to={link} alsoActiveOn={[postsRoute]}>Post tags</TopNavTextLink>
        <TopNavTextLink to={tagManagerRoute}>Tag manager</TopNavTextLink>
    </TopNav>
);

const makeStateToProps = () => {
    const selectPostTaggingRoute = makeSelectPostTaggingRoute();
    return (state) => ({
        link: selectPostTaggingRoute(state, POST_TAGGING)
    });
};

PostTagsNav.propTypes = {
    link: customPropTypes.linkTo.isRequired
};

export default connect(makeStateToProps, {
    routePushAction: routeActions.push
})(PostTagsNav);
