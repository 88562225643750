import { call, put, takeEvery } from 'redux-saga/effects';
import { makeSelectAdAccountById } from 'src/selectors/adAccounts';
import { showNotification } from 'src/actions/notifications';
import { accountUseCasesSetRequest, bulkAccountUseCasesUpdateRequest } from 'src/sagas/accountUseCases';
import {
    adAccountUseCaseSetSuccess,
    adAccountUseCaseSetRequest,
    adAccountUseCaseSetError,
    bulkAdAccountsUseCasesUpdateRequest,
    bulkAdAccountsUseCasesUpdateSuccess,
    bulkAdAccountsUseCasesUpdateError,
    adAccountUseCaseRemoveError,
    adAccountUseCaseRemoveRequest,
    adAccountUseCaseRemoveSuccess
} from 'src/actions/adAccountUseCases';
import { SubmissionError } from 'redux-form';
import { modalHideActivateBulkAdAccountUseCases } from 'src/actions/overlays';

const selectAdAccountById = makeSelectAdAccountById();

const updateAdAccountUseCaseEndpoint = '/client-index/update-ad-account-use-cases';

function* adAccountUseCasesSetRequest(action) {
    const { formValues } = action.payload;
    const { accountId } = formValues;
    try {
        const { response, serverError } = yield call(accountUseCasesSetRequest, formValues, updateAdAccountUseCaseEndpoint, selectAdAccountById);
        if (response) {
            yield put(adAccountUseCaseSetSuccess(accountId));
            yield put(showNotification('The use cases for the selected ad account was successfully updated.'));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification(applicationError.message, 'error'));
        yield put(adAccountUseCaseSetError(new SubmissionError({ _error: applicationError })));
    }
}

function* bulkAdAccountsUseCasesUpdateRequestAction(action) {
    const { payload } = action;
    const { checkedAndUncheckedUseCasesPerAdAccount } = payload;
    try {
        yield call(bulkAccountUseCasesUpdateRequest, checkedAndUncheckedUseCasesPerAdAccount, updateAdAccountUseCaseEndpoint);
        yield put(bulkAdAccountsUseCasesUpdateSuccess());
        yield put(modalHideActivateBulkAdAccountUseCases());
        yield put(showNotification('The use cases for the selected ad accounts were successfully updated.', 'success'));
    } catch (applicationError) {
        yield put(showNotification(applicationError.message, 'error'));
        yield put(bulkAdAccountsUseCasesUpdateError(applicationError.message));
    }
}

function* removeAdAccountUseCaseRequestAction(action) {
    const { payload } = action;
    const { accountId, useCaseId } = payload;
    const params = {
        accountId,
        [`${useCaseId}_useCase`]: false
    };
    try {
        const { response, serverError } = yield call(accountUseCasesSetRequest, params, updateAdAccountUseCaseEndpoint, selectAdAccountById);
        if (response) {
            yield put(adAccountUseCaseRemoveSuccess(accountId, useCaseId));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification(applicationError.message, 'error'));
        yield put(adAccountUseCaseRemoveError(accountId, useCaseId, applicationError.message));
    }
}

export default function* accountUseCasesSaga() {
    yield takeEvery(adAccountUseCaseSetRequest.type, adAccountUseCasesSetRequest);
    yield takeEvery(adAccountUseCaseRemoveRequest.type, removeAdAccountUseCaseRequestAction);
    yield takeEvery(bulkAdAccountsUseCasesUpdateRequest.type, bulkAdAccountsUseCasesUpdateRequestAction);
}
