import * as customPropTypes from 'src/customPropTypes';
import React from 'react';
import styles from 'src/stylesheets/twoColumnLayout.scss';

const TwoColumnLayout = (props) => {
    const { children } = props;
    return (
        <div className={styles.wrapper}>
            {children}
        </div>
    );
};

TwoColumnLayout.propTypes = {
    children: customPropTypes.children
};

export default TwoColumnLayout;
