import React from 'react';
import TopNav from 'src/components/navigations/TopNav';
import TopNavTextLink from 'src/components/buttons/TopNavTextLink';
import { userNotificationsRoute } from 'src/routePaths';

const NotificationsNav = () => (
    <TopNav>
        <TopNavTextLink to={userNotificationsRoute}>Notifications</TopNavTextLink>
    </TopNav>
);

export default NotificationsNav;
