import React, { Children } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import _omit from 'lodash/omit';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import SampleDashboardGridMetricTile from 'src/components/sampleDashboard/SampleDashboardGridMetricTile';
import styles from 'src/stylesheets/reactGridTile.scss';

const SampleDashboardGridTile = (props) => {
    const {
        className,
        dashboardMetricId,
        breakpoint,
        isAllowedToChangeLayout,
        children
    } = props;
    const divProps = _omit(props, [
        'children',
        'className',
        'dashboardMetricId',
        'breakpoint',
        'isAllowedToChangeLayout'
    ]);
    return (
        <div {...divProps} className={classnames(styles.reactGridTile, className, `break-${breakpoint}`, { readOnly: !isAllowedToChangeLayout })}>
            <SampleDashboardGridMetricTile
              dashboardMetricId={dashboardMetricId}
              breakpoint={breakpoint}
              isAllowedToChangeLayout={isAllowedToChangeLayout}
            />
            {Children.map(children, (child) => child)}
        </div>
    );
};

SampleDashboardGridTile.propTypes = {
    className: PropTypes.string,
    children: customPropTypes.children,
    dashboardMetricId: PropTypes.string.isRequired,
    breakpoint: customPropTypes.gridBreakpoints.isRequired,
    isAllowedToChangeLayout: PropTypes.bool
};

SampleDashboardGridTile.defaultProps = {
    className: '',
    isAllowedToChangeLayout: true
};

export default SampleDashboardGridTile;
