import React from 'react';
import MetricRequestContext from 'src/components/context/MetricRequestContext';
import PropTypes from 'prop-types';
import MetricMenuLarge from 'src/components/metricTiles/layout/MetricMenuLarge';
import MetricMenuMedium from 'src/components/metricTiles/layout/MetricMenuMedium';
import * as customPropTypes from 'src/customPropTypes';

const ResponsiveMetricMenu = (props) => {
    const {
        dashboardMetric,
        dashboardGridBreakpoint,
        isAllowedToChangeLayout,
        gridDragHandleClassName,
        isGridLoading,
        dashboardMetricLink,
        breakpoint
    } = props;

    return (
        <MetricRequestContext.Consumer>
            {
                (serverRequest) => {
                    switch (breakpoint) {
                        case 'small':
                        case 'medium':
                            return (
                                <MetricMenuMedium
                                  serverRequest={serverRequest}
                                  dashboardMetricLink={dashboardMetricLink}
                                  dashboardMetric={dashboardMetric}
                                  gridDragHandleClassName={gridDragHandleClassName}
                                  isGridLoading={isGridLoading}
                                  isAllowedToChangeLayout={isAllowedToChangeLayout}
                                  dashboardGridBreakpoint={dashboardGridBreakpoint}
                                />
                            );
                        default:
                        case 'large':
                            return (
                                <MetricMenuLarge
                                  serverRequest={serverRequest}
                                  dashboardMetricLink={dashboardMetricLink}
                                  dashboardMetric={dashboardMetric}
                                  gridDragHandleClassName={gridDragHandleClassName}
                                  isGridLoading={isGridLoading}
                                  isAllowedToChangeLayout={isAllowedToChangeLayout}
                                  dashboardGridBreakpoint={dashboardGridBreakpoint}
                                />
                            );
                    }
                }
            }
        </MetricRequestContext.Consumer>
    );
};

ResponsiveMetricMenu.propTypes = {
    breakpoint: PropTypes.oneOf(['large', 'medium', 'small']),
    dashboardMetric: customPropTypes.dashboardMetric.isRequired,
    dashboardGridBreakpoint: PropTypes.string.isRequired,
    isAllowedToChangeLayout: PropTypes.bool.isRequired,
    gridDragHandleClassName: PropTypes.string,
    isGridLoading: PropTypes.bool.isRequired,
    dashboardMetricLink: customPropTypes.linkTo
};

ResponsiveMetricMenu.defaultProps = {
    breakpoint: 'large'
};

export default ResponsiveMetricMenu;
