import {
    dashboardsRoute, accountRoutes, analysisRoutes, dataRoutes, integrationRoutes, tagsRoutes
} from 'src/routePaths';
import {
    ANALYSIS, ACCOUNTS, POSTS, DATA_SOURCES, INTEGRATION
} from 'src/utils/routes';
import * as customPropTypes from 'src/customPropTypes';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import MainNavLabel from 'src/components/navigations/mainNav/MainNavLabel';
import MainNavTextLink from 'src/components/buttons/MainNavTextLink';
import React from 'react';
import NavLogo from 'src/components/navigations/mainNav/NavLogo';
import { makeSelectLastLocationByMenuName, makeSelectPostTaggingRoute, makeSelectAnalysisRoute } from 'src/selectors/lastMainMenuLocations';

const TopMenu = (props) => {
    const {
        linkToAnalysis, linkToAccounts, linkToPostSearch, linkToDataSources, linkToIntegration
    } = props;

    return (
        <ButtonGroup vertical>
            <NavLogo to={dashboardsRoute} />
            <MainNavTextLink alsoActiveOn={analysisRoutes} to={linkToAnalysis}><MainNavLabel icon="metrics" label="Analysis" /></MainNavTextLink>
            <MainNavTextLink alsoActiveOn={accountRoutes} to={linkToAccounts}><MainNavLabel icon="profiles" label="Accounts" /></MainNavTextLink>
            <MainNavTextLink alsoActiveOn={tagsRoutes} to={linkToPostSearch}><MainNavLabel icon="tag" label="Tags" /></MainNavTextLink>
            <MainNavTextLink alsoActiveOn={dataRoutes} to={linkToDataSources}><MainNavLabel icon="data" label="Data sources" /></MainNavTextLink>
            <MainNavTextLink alsoActiveOn={integrationRoutes} to={linkToIntegration}><MainNavLabel icon="integrations" label="Integrations" /></MainNavTextLink>
        </ButtonGroup>
    );
};

TopMenu.propTypes = {
    linkToAnalysis: customPropTypes.linkTo.isRequired,
    linkToAccounts: customPropTypes.linkTo.isRequired,
    linkToDataSources: customPropTypes.linkTo.isRequired,
    linkToPostSearch: customPropTypes.linkTo.isRequired,
    linkToIntegration: customPropTypes.linkTo.isRequired
};

const makeMapStateToProps = () => {
    const selectPostTaggingRoute = makeSelectPostTaggingRoute();
    const selectLastLocationByMenuName = makeSelectLastLocationByMenuName();
    const selectAnalysisRoute = makeSelectAnalysisRoute();
    return (state) => ({
        linkToAnalysis: selectAnalysisRoute(state, ANALYSIS),
        linkToAccounts: selectLastLocationByMenuName(state, ACCOUNTS),
        linkToDataSources: selectLastLocationByMenuName(state, DATA_SOURCES),
        linkToPostSearch: selectPostTaggingRoute(state, POSTS),
        linkToIntegration: selectLastLocationByMenuName(state, INTEGRATION)
    });
};

export default connect(makeMapStateToProps)(TopMenu);
