import _get from 'lodash/get';
import memoizeOne from 'memoize-one';
import objectHash from 'object-hash';
import {
    createFilterParams,
    createServerParamsForMetric,
    overwriteServerRequestParametersForExport,
    overwriteServerRequestParametersForPagination
} from './createServerParams';

const getHash = (params, filter, dependency) => `${objectHash(params)}-${objectHash(filter)}-${objectHash(dependency)}`;

export const createSingleServerRequestForMetric = (
    identifier,
    globalSelectedProfilesOrGroups,
    globalSelectedDate,
    selectedPostText,
    selectedPostTextExclude,
    selectedPostTag,
    selectedAdCampaign,
    metric,
    format,
    paginationAndSorting = {},
) => {
    const params = createServerParamsForMetric(metric, {}, paginationAndSorting);
    const filter = createFilterParams(globalSelectedProfilesOrGroups, globalSelectedDate, selectedPostText, selectedPostTextExclude, selectedPostTag, selectedAdCampaign);
    const dependency = {
        metricId: metric.id
    };
    const request = {
        filter,
        hash: getHash(params, filter, dependency),
        params,
        identifier,
        dependency,
        dataSources: metric.dataSources
    };
    const endpointInfo = {
        to: '/client-widget/load-metrics',
        format
    };
    return {
        request,
        endpointInfo
    };
};

export const createSingleServerRequestForQQLMetric = (
    identifier,
    globalSelectedProfilesOrGroups,
    globalSelectedDate,
    selectedPostText,
    selectedPostTextExclude,
    selectedPostTag,
    selectedAdCampaign,
    qqlMetric,
    format,
    paginationAndSorting = {}
) => {
    const params = createServerParamsForMetric(qqlMetric, {}, paginationAndSorting);

    const filter = createFilterParams(globalSelectedProfilesOrGroups, globalSelectedDate, selectedPostText, selectedPostTextExclude, selectedPostTag, selectedAdCampaign);
    const dependency = {
        metricConfig: qqlMetric.config
    };
    if (qqlMetric.parameters) {
        Object.assign(dependency, { parameters: qqlMetric.parameters });
    }
    const request = {
        hash: getHash(params, filter, dependency),
        params,
        filter,
        identifier,
        dependency,
        dataSources: []
    };
    const endpointInfo = {
        to: '/client-widget/load-qql-metrics',
        format
    };
    return {
        request,
        endpointInfo
    };
};

const createRequestObjectForDashboardMetric = (
    identifier,
    globalSelectedProfilesOrGroups,
    globalSelectedDate,
    optionalSelectedPostText,
    optionalSelectedPostTextExclude,
    optionalSelectedPostTags,
    optionalSelectedAdCampaign,
    dashboardMetric,
    paginationAndSorting,
) => {
    const params = createServerParamsForMetric(
        dashboardMetric.metric,
        dashboardMetric.settings,
        paginationAndSorting
    );
    const filter = createFilterParams(
        globalSelectedProfilesOrGroups,
        globalSelectedDate,
        optionalSelectedPostText,
        optionalSelectedPostTextExclude,
        optionalSelectedPostTags,
        optionalSelectedAdCampaign,
        dashboardMetric.settings
    );

    const dependency = {
        dashboardMetricId: dashboardMetric.id,
        metricId: dashboardMetric.metric.id,
        dashboardId: dashboardMetric.dashboardId
    };

    return {
        hash: getHash(params, filter, dependency),
        params,
        filter,
        identifier,
        dependency,
        dataSources: dashboardMetric.metric.dataSources
    };
};

const getEndpointInfoForDashboardMetrics = (format, isSharedDashboardContext) => ({
    to: isSharedDashboardContext ? '/client-widget/load-shared-dashboard-metrics' : '/client-widget/load-dashboard-metrics',
    format,
});

export const createSingleServerRequestForDashboardMetric = (
    identifier,
    globalSelectedProfilesOrGroups,
    globalSelectedDate,
    optionalSelectedPostText,
    optionalSelectedPostTextExclude,
    optionalSelectedPostTags,
    optionalSelectedAdCampaign,
    dashboardMetric,
    format,
    paginationAndSorting = {},
    isSharedDashboardContext = false
) => {
    const request = createRequestObjectForDashboardMetric(
        identifier,
        globalSelectedProfilesOrGroups,
        globalSelectedDate,
        optionalSelectedPostText,
        optionalSelectedPostTextExclude,
        optionalSelectedPostTags,
        optionalSelectedAdCampaign,
        dashboardMetric,
        paginationAndSorting
    );
    const endpointInfo = getEndpointInfoForDashboardMetrics(format, isSharedDashboardContext);
    return {
        request,
        endpointInfo
    };
};

export const makeCreateBatchServerRequestForDashboardMetrics = () => {
    const selectorCache = {};
    const getSelector = (identifier) => {
        const selector = _get(selectorCache, identifier);
        if (selector) { return selector; }
        Object.assign(selectorCache, { [identifier]: memoizeOne(createRequestObjectForDashboardMetric) });
        return selectorCache[identifier];
    };
    const getMemoizedEndpointInfo = memoizeOne(getEndpointInfoForDashboardMetrics);
    const getMemoizedBatchVariables = memoizeOne((batchName) => ({
        batchName
    }));
    const defaultPaginationAndSorting = {};
    return (
        batchIdentifier,
        batchName,
        identifierPrefix,
        globalSelectedProfilesOrGroups,
        globalSelectedDate,
        optionalSelectedPostText,
        optionalSelectedPostTextExclude,
        optionalSelectedPostTags,
        optionalSelectedAdCampaign,
        dashboardMetrics,
        format,
        paginationAndSortingByDashboardMetricId = {},
        isSharedDashboardContext = false
    ) => {
        const requests = [];
        const entityIdToRequestMapping = {};
        dashboardMetrics.forEach((dashboardMetric, idx) => {
            const identifier = `${identifierPrefix}-${dashboardMetric.id}`;
            const paginationAndSorting = _get(paginationAndSortingByDashboardMetricId, dashboardMetric.id, defaultPaginationAndSorting);
            const request = getSelector(identifier)(identifier, globalSelectedProfilesOrGroups, globalSelectedDate, optionalSelectedPostText, optionalSelectedPostTextExclude, optionalSelectedPostTags, optionalSelectedAdCampaign, dashboardMetric, paginationAndSorting);
            requests.push(request);
            Object.assign(entityIdToRequestMapping, { [dashboardMetric.id]: idx });
        });
        const endpointInfo = getMemoizedEndpointInfo(format, isSharedDashboardContext);
        const requestVariables = getMemoizedBatchVariables(batchName);
        return {
            batchIdentifier,
            requestVariables,
            requests,
            endpointInfo,
            entityIdToRequestMapping
        };
    };
};

export const createSingleServerRequestForPagination = (
    originalServerRequest,
    paginationAndSorting
) => {
    // Manipulate Request for pagination/sorting for pagination. Only JSON supported
    const params = overwriteServerRequestParametersForPagination(originalServerRequest.request, paginationAndSorting);
    const hash = getHash(params, originalServerRequest.request.filter, originalServerRequest.request.dependency);
    const request = Object.assign({}, originalServerRequest.request, { hash, params });
    const endpointInfo = Object.assign({}, originalServerRequest.endpointInfo, { format: 'json' });
    return {
        request,
        endpointInfo
    };
};

export const createSingleServerRequestForExport = (
    originalServerRequest,
    paginationAndSorting,
    format,
    requestVariables
) => {
    // Manipulate Request for sortDir and Format
    const params = overwriteServerRequestParametersForExport(originalServerRequest.request, paginationAndSorting);
    const hash = getHash(params, originalServerRequest.request.filter, originalServerRequest.request.dependency);
    const request = Object.assign({}, originalServerRequest.request, { hash, params });
    const endpointInfo = Object.assign({}, originalServerRequest.endpointInfo, { format });
    return {
        request,
        endpointInfo,
        requestVariables
    };
};

export const createBatchServerRequestForExport = (
    originalBatchServerRequest,
    batchPaginationAndSorting,
    format,
    requestVariables
) => {
    const { requests } = originalBatchServerRequest;
    const modifiedRequests = [];
    requests.forEach((request) => {
        const params = overwriteServerRequestParametersForExport(request, _get(batchPaginationAndSorting, request.identifier, {}));
        const hash = getHash(params, request.filter, request.dependency);
        const modifiedRequest = Object.assign({}, request, { hash, params });
        modifiedRequests.push(modifiedRequest);
    });
    const endpointInfo = Object.assign({}, originalBatchServerRequest.endpointInfo, { format });
    const modifiedRequestVariables = Object.assign({}, originalBatchServerRequest.requestVariables, requestVariables);
    return Object.assign({}, originalBatchServerRequest, {
        requests: modifiedRequests,
        endpointInfo,
        requestVariables: modifiedRequestVariables
    });
};
