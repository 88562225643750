import {
    put, takeEvery, call, all
} from 'redux-saga/effects';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import { modalHideReportOverviewDelete } from 'src/actions/overlays';
import {
    REPORT_OVER_VIEWS_DELETE_REQUEST,
    reportOverviewDeleteSuccess,
    reportOverviewDeleteError
} from 'src/actions/reportOverview';
import { showNotification } from 'src/actions/notifications';
import { getDashboardAndShareLinkIds, dashboardShareLinkType, dashboardReportType } from 'src/utils/reportOverviews';
import { getDashboardIdAndDashboardType } from 'src/sagas/dashboardReports';

function* deleteDashboardShareLink(id) {
    let success = false;
    try {
        const serverRequest = createServerRequest({ id });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/remove-dashboard-share-link');
        if (response) {
            success = true;
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        success = false;
    }
    return {
        type: dashboardShareLinkType,
        success,
        id,
    };
}

function* deleteDashboardReport(id) {
    let success = false;
    try {
        const { dashboardReport, dashboardId, dashboardType } = yield call(getDashboardIdAndDashboardType, id);
        const serverRequest = createServerRequest({ dashboardReportId: dashboardReport.id, dashboardId, dashboardType });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/delete-dashboard-report');
        if (response) {
            success = true;
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        success = false;
    }
    return {
        type: dashboardReportType,
        success,
        id
    };
}

const createBatchDeleteDashboardShareLinkAndReportRequests = (reportOverviewIds) => {
    const { dashboardReportIds, dashboardShareLinkIds } = getDashboardAndShareLinkIds(reportOverviewIds);
    const requests = dashboardShareLinkIds.map((dashboardShareLinkId) => call(deleteDashboardShareLink, dashboardShareLinkId));
    return requests.concat(dashboardReportIds.map((dashboardReportId) => call(deleteDashboardReport, dashboardReportId)));
};

const gatherResponses = (responses) => {
    const failedDashboardReportIds = [];
    const successDashboardReportIds = [];
    const failedDashboardShareLinkIds = [];
    const successDashboardShareLinkIds = [];
    responses.forEach((response) => {
        if (response.type === dashboardReportType) {
            const { success, id } = response;
            if (success) {
                successDashboardReportIds.push(id);
            }
            if (!success) {
                failedDashboardReportIds.push(id);
            }
        }

        if (response.type === dashboardShareLinkType) {
            const { success, id } = response;
            if (success) {
                successDashboardShareLinkIds.push(id);
            }
            if (!success) {
                failedDashboardShareLinkIds.push(id);
            }
        }
    });

    return {
        failedDashboardReportIds,
        successDashboardReportIds,
        failedDashboardShareLinkIds,
        successDashboardShareLinkIds
    };
};

function* reportOverviewsDeleteRequest(action) {
    const { payload } = action;
    const { reportOverviewIds } = payload;
    try {
        const requests = createBatchDeleteDashboardShareLinkAndReportRequests(reportOverviewIds);
        const responses = yield all(requests);
        const {
            failedDashboardReportIds,
            successDashboardReportIds,
            failedDashboardShareLinkIds,
            successDashboardShareLinkIds
        } = gatherResponses(responses);
        yield put(modalHideReportOverviewDelete());
        yield put(reportOverviewDeleteSuccess(successDashboardReportIds, successDashboardShareLinkIds));
        if (failedDashboardReportIds.length > 0 || failedDashboardShareLinkIds.length > 0) {
            yield put(showNotification('Some of the reports failed to remove', 'error'));
        }
    } catch (applicationError) {
        yield put(reportOverviewDeleteError(applicationError));
    }
}

export default function* reportOverviewSagas() {
    yield takeEvery(REPORT_OVER_VIEWS_DELETE_REQUEST, reportOverviewsDeleteRequest);
}
