import React, { useState } from 'react';
import authUserEmpty from 'src/resources/illustrations/discover-no-results.png';
import AuthUserHeader from 'src/components/header/AuthUserHeader';
import AuthUsersList from 'src/components/views/authentications/authUser/AuthUsersList';
import { connect } from 'react-redux';
import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import PropTypes from 'prop-types';
import { selectAuthUserCount } from 'src/selectors/authUsers';
import withStickyListHeaderHandling from 'src/components/withStickyListHeaderHandling';

const AuthUserHeaderWithStickyListHeaderHandling = withStickyListHeaderHandling(AuthUserHeader);

const sortingOptions = [
    { value: 'alphabetically', label: 'Name (A-Z)' },
    { value: 'recentlyUpdated', label: 'Recently updated' }
];

const environments = [
    { value: 'all', label: 'All social networks' },
    { value: 'graph', label: 'Meta' },
    { value: 'twitter', label: 'X' },
    { value: 'youtube', label: 'Youtube' },
    { value: 'linkedIn', label: 'LinkedIn' },
    { value: 'snapchat', label: 'Snapchat' },
    { value: 'tiktok', label: 'TikTok' },
    { value: 'threads', label: 'Threads' }
];

const AuthUsers = (props) => {
    const { authUserCount } = props;
    const [filterBy, changeFilterBy] = useState('');
    const [orderBy, changeOrderBy] = useState(sortingOptions[0].value);
    const [environmentType, changeEnvironmentType] = useState(environments[0].value);

    const handleOnFilterByChange = (e) => {
        const { value } = e.target;
        changeFilterBy(value);
    };

    const handleOnFilterByReset = () => {
        changeFilterBy('');
    };

    const handleOnOrderByChange = (e) => {
        const { value } = e.target;
        changeOrderBy(value);
    };

    const handleOnEnvironmentChange = (e) => {
        const { value } = e.target;
        changeEnvironmentType(value);
    };

    return (
        <div>
            <AuthUserHeaderWithStickyListHeaderHandling
              offsetTop={68}
              filterBy={filterBy}
              orderBy={orderBy}
              environments={environments}
              sortingOptions={sortingOptions}
              handleOnEnvironmentChange={handleOnEnvironmentChange}
              handleOnFilterByChange={handleOnFilterByChange}
              handleOnFilterByReset={handleOnFilterByReset}
              handleOnOrderByChange={handleOnOrderByChange}
            />
            {
                authUserCount > 0
                && (
                <AuthUsersList
                  orderBy={orderBy}
                  filterBy={filterBy}
                  environmentType={environmentType}
                />
                )
            }
            {
                authUserCount === 0
                && (
                <EmptyInfoScreen
                  image={authUserEmpty}
                  title="Welcome to the Authentications hub"
                  message="Here you can easily manage all of your private statistics connections across every social network."
                />
                )
            }
        </div>
    );
};

const mapStateToProps = (state) => ({
    authUserCount: selectAuthUserCount(state)
});

AuthUsers.propTypes = {
    authUserCount: PropTypes.number.isRequired
};

export default connect(mapStateToProps)(AuthUsers);
