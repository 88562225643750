export const USER_INVOICES_REQUEST = 'USER_INVOICES_REQUEST';
export const USER_INVOICES_SUCCESS = 'USER_INVOICES_SUCCESS';
export const USER_INVOICES_ERROR = 'USER_INVOICES_ERROR';

export const userInvoicesRequest = () => ({
    type: USER_INVOICES_REQUEST
});

export const userInvoicesSuccess = (invoices) => ({
    type: USER_INVOICES_SUCCESS,
    payload: { invoices }
});

export const userInvoicesError = (error) => ({
    type: USER_INVOICES_ERROR,
    payload: { error }
});
