import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/views/dashboardAndMetricDetail.scss';

const DashboardMetricList = (props) => {
    const { searchResultMetrics } = props;
    return (
        <div>
            {
                searchResultMetrics.map((metric, i) => (
                    <div
                      key={`publicMetric-${metric.id}`}
                      className={classnames({ [styles.metricItemPadding]: searchResultMetrics.length - 1 > i })}
                    >
                        <Link
                          className={styles.metricItem}
                          to={{ pathname: `/discover/metric/${metric.id}` }}
                        >
                            {metric.name}
                        </Link>
                    </div>
                ))
            }
        </div>
    );
};

DashboardMetricList.propTypes = {
    searchResultMetrics: PropTypes.arrayOf(customPropTypes.searchResultMetricOrFalse).isRequired
};

export default DashboardMetricList;
