import React, { Component } from 'react';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import MenuButton from 'src/components/buttons/MenuButton';
import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import BackButton from './layout/BackButton';

const wrapperStyle = {
    width: 180
};

const exportListOptions = [
    { type: 'jpg', name: 'JPG' },
    { type: 'png', name: 'PNG' },
    { type: 'pdf', name: 'PDF' },
    { type: 'xlsx', name: 'XLSX' },
    { type: 'pptx', name: 'PPTX' },
    { type: 'csv', name: 'CSV' }
];

class ExportMenuPopover extends Component {
    constructor(props) {
        super(props);
        this.handleOnMenuItemClick = this.handleOnMenuItemClick.bind(this);
    }

    handleOnMenuItemClick(type) {
        const { onExportItemClick, hidePopover } = this.props;
        onExportItemClick(type);
        hidePopover();
    }

    render() {
        const {
            hidePopover, additionalButton, showBackButton, onBackClick
        } = this.props;
        return (
            <Popover id="exportFileMenuList">
                <div style={wrapperStyle}>
                    {
                        showBackButton
                        && <BackButton onBackClick={onBackClick} />
                    }
                    <ButtonGroup vertical>
                        {
                            exportListOptions.map((option) => (
                                <MenuButton onClick={() => this.handleOnMenuItemClick(option.type)} label={option.name} key={option.type} />
                            ))
                        }
                        {
                            additionalButton && React.cloneElement(additionalButton, { hidePopover })
                        }
                    </ButtonGroup>
                </div>
            </Popover>
        );
    }
}

ExportMenuPopover.propTypes = {
    hidePopover: PropTypes.func,
    onExportItemClick: PropTypes.func.isRequired,
    additionalButton: PropTypes.node,
    showBackButton: PropTypes.bool,
    onBackClick: PropTypes.func
};

ExportMenuPopover.defaultProps = {
    showBackButton: false,
    onBackClick: () => {}
};

export default ExportMenuPopover;
