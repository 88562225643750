import { selectIsReadOnly, getLoggedInUserId } from 'src/selectors/loggedInUser';
import { getDashboardByIdFromStore } from 'src/selectors/dashboards';

export const isLoggedInUserOwnerOfDashboard = (state, dashboardId) => {
    const dashboard = getDashboardByIdFromStore(state, dashboardId);
    const loggedInUserId = getLoggedInUserId(state);
    return dashboard.createdByUserId === loggedInUserId;
};

export const isLoggedInUserAllowedToChangeDashboardLayout = (state, dashboardId) => {
    const isUserOwnerOfDashboard = isLoggedInUserOwnerOfDashboard(state, dashboardId);
    const isUserReadOnly = selectIsReadOnly(state);

    // read only users are not allowed to change dashboards that they don't own
    return !(isUserReadOnly && !isUserOwnerOfDashboard);
};
