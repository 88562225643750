import DataWarningButton from 'src/components/buttons/DataWarningButton';
import MetricWarningProvider from 'src/components/MetricWarningProvider';
import {
    makeSelectDateByContext,
    makeSelectMetricDetailLink, makeAdditionalFilterSelectorsByContext, makeSelectProfileSelectorSelectionByContext
} from 'src/selectors/filterSelectors';
import {
    makeSelectMetricSearchResultMetric,
    selectHasSearchResultError,
    selectIsSearchResultLoading,
} from 'src/selectors/metricSearch';
import React, { Component } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import * as routeActions from 'react-router-redux';
import AdditionalSettingAwareSelectors from 'src/components/views/dashboards/AdditionalSettingAwareSelectors';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import { makeSelectTagsById } from 'src/selectors/tags';
import MaximizedMetricTile from 'src/components/metricTiles/MaximizedMetricTile';
import MetricExportButton from 'src/components/exportButtons/MetricExportButton';
import MetricNameHeaderWithIcons from 'src/components/MetricNameHeaderWithIcons';
import MetricOpenInPopoverContainer from 'src/components/popovers/MetricOpenInPopoverContainer';
import MetricRefreshButton from 'src/components/buttons/MetricRefreshButton';
import MetricRequestProvider from 'src/components/requestHandling/MetricRequestProvider';
import { metricSearchResultRequest } from 'src/actions/metricSearch';
import Modal from 'src/components/modals/layout/Modal';
import { modalShowAddMetricToDashboard } from 'src/actions/overlays';
import PropTypes from 'prop-types';
import { selectAccountOptions } from 'src/selectors/loggedInUser';
import ServerDataSingleRequestDispatcher from 'src/components/ServerDataSingleRequestDispatcher';
import styles from 'src/stylesheets/metricPreview.scss';
import withPopover from 'src/components/withPopover';
import { DISCOVER_PREVIEW } from 'src/utils/filterSelectors';
import FilterContextProvider from 'src/components/views/FilterContextProvider';

const ButtonWithPopover = withPopover(Button);
const settings = {};
const type = 'Metric';

class MetricPreview extends Component {
    componentDidMount() {
        const {
            metricSearchResultRequestAction, searchResultMetric, error, metricId
        } = this.props;

        if (!searchResultMetric && !error) {
            metricSearchResultRequestAction(type, metricId);
        }
    }

    render() {
        const {
            searchResultMetric,
            isLoading,
            error,
            linkToMetricDetail,
            selectedProfilesOrGroups,
            selectedDate,
            routePushAction,
            accountOptions,
            onAddMetricToDashboardClickAction,
            selectedPostTag,
            selectedPostText,
            selectedPostTextExclude,
            selectedAdCampaign
        } = this.props;

        let title = '';
        let body = '';

        if (isLoading) {
            title = 'Loading';
            body = (
                <div>Loading...</div>
            );
        } else if (error) {
            title = 'Error';
            body = (
                <div>The metric you tried to access does not exist.</div>
            );
        } else if (searchResultMetric) {
            const {
                platformType,
                name,
                id,
                isBasedOnPrivateStatistics,
                isCustomMetric
            } = searchResultMetric;

            const dataLoadingProps = {
                selectedProfilesOrGroups,
                selectedDate,
                accountOptions,
                metric: searchResultMetric,
                settings,
            };

            const metricOpenInPopover = <MetricOpenInPopoverContainer context={DISCOVER_PREVIEW} metric={searchResultMetric} />;
            title = (
                <div className={styles.titleWrapper}>
                    <MetricNameHeaderWithIcons
                      platformType={platformType}
                      name={name}
                      isBasedOnPrivateStatistics={isBasedOnPrivateStatistics}
                      className={styles.networkIcon}
                      size="medium"
                      isCustomMetric={isCustomMetric}
                    />
                </div>
            );

            body = (
                <MetricRequestProvider
                  metric={searchResultMetric}
                  selectedProfilesOrGroups={selectedProfilesOrGroups}
                  selectedDate={selectedDate}
                  selectedPostText={selectedPostText}
                  selectedPostTextExclude={selectedPostTextExclude}
                  selectedPostTag={selectedPostTag}
                  selectedAdCampaign={selectedAdCampaign}
                  format="json"
                >
                    {
                        (serverRequest) => (
                            <div className={styles.wrapper}>
                                <div className={styles.header}>
                                    <div className={styles.headerLeft}>
                                        <AdditionalSettingAwareSelectors
                                          settings={{}}
                                          selectedProfilesOrGroups={selectedProfilesOrGroups}
                                          selectedDate={selectedDate}
                                          selectedPostTag={selectedPostTag}
                                          selectedPostText={selectedPostText}
                                        />
                                    </div>
                                    <div className={styles.headerRight}>
                                        <ButtonGroup>
                                            <MetricWarningProvider requestHash={serverRequest.request.hash}>
                                                {(warnings) => (
                                                    <DataWarningButton
                                                      warnings={warnings}
                                                    />
                                                )}
                                            </MetricWarningProvider>
                                            <Button
                                              action
                                              label="Add to dashboard"
                                              icon="plus"
                                              onClick={() => {
                                                  onAddMetricToDashboardClickAction(id, searchResultMetric.visualization === 'table');
                                              }}
                                            />
                                            <MetricExportButton
                                              label="Export"
                                              multiAction
                                              fileName={name}
                                              serverRequest={serverRequest}
                                            />
                                            <ButtonWithPopover
                                              id={id}
                                              overlay={metricOpenInPopover}
                                              label="Open in..."
                                              placement="bottom-end"
                                              multiAction
                                            />
                                            <MetricRefreshButton
                                              metricRequestIdentifier={serverRequest.request.identifier}
                                              serverDataHash={serverRequest.request.hash}
                                            />
                                        </ButtonGroup>
                                    </div>
                                </div>
                                <div className={styles.metric}>
                                    <ServerDataSingleRequestDispatcher
                                      loadingIdentifier={`metric-preview-${searchResultMetric.id}`}
                                      serverRequest={serverRequest}
                                    >
                                        <MaximizedMetricTile
                                          {...dataLoadingProps}
                                          requestHash={serverRequest.request.hash}
                                        />
                                    </ServerDataSingleRequestDispatcher>
                                </div>
                            </div>
                        )
                    }
                </MetricRequestProvider>
            );
        }

        const hideModal = () => {
            routePushAction(linkToMetricDetail);
        };

        return (
            <Modal onHide={hideModal}>
                <Modal.Dialog fullscreen>
                    <FilterContextProvider context={DISCOVER_PREVIEW}>
                        <Modal.Header onHide={hideModal}>
                            <Modal.Title>
                                {title}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {body}
                        </Modal.Body>
                    </FilterContextProvider>
                </Modal.Dialog>
            </Modal>
        );
    }
}

MetricPreview.propTypes = {
    searchResultMetric: customPropTypes.searchResultMetricOrFalse.isRequired,
    metricSearchResultRequestAction: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.oneOfType([customPropTypes.dataLoadingError, PropTypes.bool]).isRequired,
    linkToMetricDetail: customPropTypes.linkTo.isRequired,
    metricId: PropTypes.string.isRequired,
    tags: customPropTypes.tags.isRequired,
    selectedProfilesOrGroups: customPropTypes.profileFilter.isRequired,
    selectedDate: customPropTypes.selectedDate.isRequired,
    accountOptions: customPropTypes.accountOptions.isRequired,
    routePushAction: PropTypes.func.isRequired,
    onAddMetricToDashboardClickAction: PropTypes.func.isRequired,
    selectedPostTag: customPropTypes.postTagFilter.isRequired,
    selectedPostText: customPropTypes.postTextFilter.isRequired,
    selectedPostTextExclude: customPropTypes.postTextExcludeFilter.isRequired,
    selectedAdCampaign: customPropTypes.adCampaignFilter.isRequired
};

const makeMapStateToProps = () => {
    const selectTagsById = makeSelectTagsById();
    const selectMetricSearchResultMetric = makeSelectMetricSearchResultMetric();
    const selectMetricDetailLink = makeSelectMetricDetailLink();
    const selectDateByContext = makeSelectDateByContext();
    const selectProfileSelectorSelectionByContext = makeSelectProfileSelectorSelectionByContext();

    const {
        selectPostTagByContext, selectPostTextByContext, selectPostTextExcludeByContext, selectAdCampaignByContext
    } = makeAdditionalFilterSelectorsByContext();
    return (state, ownProps) => {
        const { params } = ownProps;
        const { metricId } = params;
        const searchResultMetric = selectMetricSearchResultMetric(state, metricId);
        const tags = searchResultMetric ? selectTagsById(state, searchResultMetric.tagIds) : [];

        return {
            metricId,
            searchResultMetric,
            tags,
            isLoading: selectIsSearchResultLoading(state, type, metricId),
            error: selectHasSearchResultError(state, type, metricId),
            linkToMetricDetail: selectMetricDetailLink(state, metricId),
            selectedProfilesOrGroups: selectProfileSelectorSelectionByContext(state, DISCOVER_PREVIEW),
            selectedDate: selectDateByContext(state, DISCOVER_PREVIEW),
            accountOptions: selectAccountOptions(state),
            selectedPostTag: selectPostTagByContext(state, DISCOVER_PREVIEW),
            selectedPostText: selectPostTextByContext(state, DISCOVER_PREVIEW),
            selectedPostTextExclude: selectPostTextExcludeByContext(state, DISCOVER_PREVIEW),
            selectedAdCampaign: selectAdCampaignByContext(state, DISCOVER_PREVIEW)
        };
    };
};

export default connect(makeMapStateToProps, {
    metricSearchResultRequestAction: metricSearchResultRequest,
    onAddMetricToDashboardClickAction: modalShowAddMetricToDashboard,
    routePushAction: routeActions.push
})(MetricPreview);
