import { Select, Text, TimezoneSelect } from 'src/components/forms/fields';
import * as customPropTypes from 'src/customPropTypes';
import ContentBlock from 'src/components/views/ContentBlock';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import FormError from 'src/components/forms/feedback/FormError';
import PropTypes from 'prop-types';
import React from 'react';
import { selectLocalizationFormInitialValues } from 'src/selectors/loggedInUser';
import styles from 'src/stylesheets/views/settings/settings.scss';
import withReduxForm from 'src/components/forms/withReduxForm';

function validate(data) {
    const errors = {};
    if (!data.timezone) {
        errors.timezone = 'Please choose a timezone';
    }
    if (!data.csvSeparator) {
        errors.csvSeparator = 'Please enter a character';
    } else if (data.csvSeparator.length > 1) {
        errors.csvSeparator = 'Please enter only one character';
    } else if (data.csvSeparator.length < 1) {
        errors.csvSeparator = 'Please enter only one character';
    }
    if (!data.exportEncoding) {
        errors.exportEncoding = 'Please choose an encoding';
    }
    if (!data.dateFormat) {
        errors.dateFormat = 'Please choose a date format';
    }
    if (!data.weekDefinition) {
        errors.weekDefinition = 'Please choose the definition of the week';
    }
    return errors;
}

const LocalizationForm = (props) => {
    const {
        handleSubmit, submitting, error
    } = props;

    return (
        <div>
            {
                error && <FormError message={error.message} />
            }
            <form onSubmit={handleSubmit} className={styles.sectionWrapper}>
                <div className={styles.leftSection}>
                    <ContentBlock headline="Time and date">
                        <Field
                          name="timezone"
                          label="Timezone"
                          info="This timezone will be used throughout the tool to properly shift time points."
                          component={TimezoneSelect}
                          disabled={submitting}
                          hideQuickSelect
                          shortcutOnly={false}
                        />
                        <Field
                          name="dateFormat"
                          label="Date format"
                          component={Select}
                          disabled={submitting}
                        >
                            <option value="MM/DD/YYYY">MM/DD/YYYY</option>
                            <option value="DD.MM.YYYY">DD.MM.YYYY</option>
                            <option value="DD/MM/YYYY">DD/MM/YYYY</option>
                        </Field>
                        <Field
                          name="weekDefinition"
                          label="Week definition"
                          info="Metrics making use of weekly aggregation or analysis per weekday will take this setting into account."
                          component={Select}
                          disabled={submitting}
                        >
                            <option value="MoSu">Monday - Sunday</option>
                            <option value="SuSa">Sunday - Saturday</option>
                        </Field>
                    </ContentBlock>
                </div>
                <div className={styles.rightSection}>
                    <ContentBlock headline="Formatting">
                        <Field
                          name="numberFormat"
                          label="Number format"
                          component={Select}
                          disabled={submitting}
                        >
                            <option value=",.">#,###,###.##</option>
                            <option value=".,">#.###.###,##</option>
                            <option value="'.">#&apos;###&apos;###.##</option>
                            <option value="',">#&apos;###&apos;###,##</option>
                        </Field>
                        <Field
                          name="csvSeparator"
                          label="CSV separator"
                          info="The field separator used in all CSV exports."
                          component={Text}
                          disabled={submitting}
                        />
                        <Field
                          name="exportEncoding"
                          label="CSV export file encoding"
                          info="CSV files will be encoded according to this setting."
                          component={Select}
                          disabled={submitting}
                        >
                            <option value="UTF-8">UTF-8</option>
                            <option value="UTF-7">UTF-7</option>
                            <option value="ASCII">ASCII</option>
                            <option value="JIS">JIS</option>
                            <option value="ISO-2022-JP">ISO-2022-JP</option>
                            <option value="ISO-8859-1">ISO-8859-1</option>
                            <option value="ISO-8859-2">ISO-8859-2</option>
                            <option value="Windows-1252">Windows-1252</option>
                            <option value="Windows-1251">Windows-1251</option>
                        </Field>
                    </ContentBlock>
                </div>
            </form>
        </div>
    );
};

LocalizationForm.propTypes = {
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    error: customPropTypes.dataLoadingError,
    submit: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        initialValues: selectLocalizationFormInitialValues(state)
    };
}

export default connect(mapStateToProps)(withReduxForm(LocalizationForm, {
    enableReinitialize: true,
    validate
}));
