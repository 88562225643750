import React from 'react';
import ListTableCell from 'src/components/listTable/ListTableCell';
import ListTableRow from 'src/components/listTable/ListTableRow';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import ListHandlingCheckbox from 'src/components/bulkList/ListHandlingCheckbox';
import Button from 'src/components/buttons/Button';
import styles from 'src/stylesheets/views/viewListTable.scss';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import withPopover from 'src/components/withPopover';
import Date from 'src/components/Date';
import { getRelativeTimeString } from 'src/components/RelativeTime';
import * as routeActions from 'react-router-redux';
import {
    listName, getTypeAndId, dashboardShareLinkType, dashboardReportType
} from 'src/utils/reportOverviews';
import ReportOverviewOptionsPopover from 'src/components/popovers/ReportOverviewOptionsPopover';
import PropTypes from 'prop-types';
import ReportOverviewIcon from 'src/components/reportOverview/ReportOverviewIcon';
import ReportOverviewState from 'src/components/views/reports/ReportOverviewState';
import { modalShowEditShareableLink, modalsShowEditReport } from 'src/actions/overlays';
import classnames from 'classnames';
import { makeSelectIsOneItemSelected } from 'src/selectors/lists';
import { number } from 'src/components/Number';
import { selectAccountOptions } from 'src/selectors/loggedInUser';

const ButtonWithPopover = withPopover(Button);

const ReportsOverviewTableRow = (props) => {
    const { reportOverview, isOnItemSelected, accountOptions } = props;
    const {
        id, name, creator, createTime, numberOfVisits, lastVisitTime, isActive
    } = reportOverview;

    const { id: entityId, type } = getTypeAndId(id);

    let formattedLastVisitTime = '';
    let formattedNumberOfVisit = '';

    if (type === dashboardShareLinkType) {
        formattedLastVisitTime = lastVisitTime !== null ? getRelativeTimeString(lastVisitTime, 30 * 86400, 'datetime', accountOptions) : 'n/a';
        formattedNumberOfVisit = number(numberOfVisits, accountOptions.numberFormat);
    }

    const handleOnReportClick = () => {
        const { modalsShowEditReportAction, modalShowEditShareableLinkAction } = props;
        if (type === dashboardShareLinkType) {
            modalShowEditShareableLinkAction(entityId);
        }

        if (type === dashboardReportType) {
            modalsShowEditReportAction(entityId);
        }
    };

    return (
        <ListTableRow>
            <ListTableCell className={styles.checkbox}><ListHandlingCheckbox listName={listName} name={id} /></ListTableCell>
            <ListTableCell onClick={handleOnReportClick} disabled={!isActive}>
                <ReportOverviewIcon reportOverviewId={id} className={styles.icon} />
            </ListTableCell>
            <ListTableCell onClick={handleOnReportClick} disabled={!isActive}>
                <DivWithTooltip tooltip={name}>
                    {name}
                </DivWithTooltip>
            </ListTableCell>
            <ListTableCell onClick={handleOnReportClick} disabled={!isActive}><Date date={createTime} /></ListTableCell>
            <ListTableCell onClick={handleOnReportClick} disabled={!isActive}>
                <DivWithTooltip tooltip={creator}>
                    {creator}
                </DivWithTooltip>
            </ListTableCell>
            <ListTableCell onClick={handleOnReportClick} disabled={!isActive}>
                <DivWithTooltip tooltip={formattedNumberOfVisit}>
                    {formattedNumberOfVisit}
                </DivWithTooltip>
            </ListTableCell>
            <ListTableCell onClick={handleOnReportClick} disabled={!isActive}>
                <DivWithTooltip tooltip={formattedLastVisitTime}>
                    {formattedLastVisitTime}
                </DivWithTooltip>
            </ListTableCell>
            <ListTableCell
              rightContent={(
                  <ButtonWithPopover
                    small
                    icon="more"
                    overlay={<ReportOverviewOptionsPopover reportOverviewId={id} />}
                    disabled={isOnItemSelected}
                    className="list-hover-button-opacity"
                  />
              )}
            >
                <div className={classnames({ [styles.disabled]: !isActive })}>
                    <ReportOverviewState reportOverviewId={id} />
                </div>
            </ListTableCell>
        </ListTableRow>
    );
};

ReportsOverviewTableRow.propTypes = {
    reportOverview: customPropTypes.reportOverview.isRequired,
    accountOptions: customPropTypes.accountOptions.isRequired,
    modalsShowEditReportAction: PropTypes.func.isRequired,
    modalShowEditShareableLinkAction: PropTypes.func.isRequired,
    isOnItemSelected: PropTypes.bool.isRequired
};

const mapStateToProps = () => {
    const selectIsOneItemSelected = makeSelectIsOneItemSelected();
    return (state) => ({
        isOnItemSelected: selectIsOneItemSelected(state, listName),
        accountOptions: selectAccountOptions(state)
    });
};

export default connect(mapStateToProps, {
    routePushAction: routeActions.push,
    modalsShowEditReportAction: modalsShowEditReport,
    modalShowEditShareableLinkAction: modalShowEditShareableLink
})(ReportsOverviewTableRow);
