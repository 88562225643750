import { ensurePermission, writePermissions } from 'src/middleware/userPermissionCheck';
import { modalsShowGroupEdit, modalShowAdAccountAdd } from 'src/actions/overlays';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Column from 'src/components/header/layout/Column';
import { connect } from 'react-redux';
import Header from 'src/components/header/layout/Header';
import PageHeadline from 'src/components/header/layout/PageHeadline';
import AccountLimit from 'src/components/AccountLimit';
import PropTypes from 'prop-types';
import React from 'react';
import Separator from 'src/components/header/layout/Separator';
import TopRow from 'src/components/header/layout/TopRow';

const AdAccountsHeader = (props) => {
    const { modalsShowGroupEditAction, modalShowAddAdAccountAction } = props;

    return (
        <Header>
            <TopRow>
                <Column flexGrow>
                    <PageHeadline>
                        Ad accounts
                    </PageHeadline>
                </Column>
                <Column>
                    <AccountLimit label="Ad account" identifier="adAccounts" />
                </Column>
                <Column>
                    <Separator />
                </Column>
                <Column>
                    <div>
                        <ButtonGroup>
                            <Button
                              action
                              onClick={modalShowAddAdAccountAction}
                              label="New ad account"
                            />
                            <Button
                              onClick={modalsShowGroupEditAction}
                              label="Manage groups"
                            />
                        </ButtonGroup>
                    </div>
                </Column>
            </TopRow>
        </Header>
    );
};

AdAccountsHeader.propTypes = {
    modalsShowGroupEditAction: PropTypes.func.isRequired,
    modalShowAddAdAccountAction: PropTypes.func.isRequired
};

export default connect(null, {
    modalsShowGroupEditAction: ensurePermission(modalsShowGroupEdit, writePermissions.editGroup),
    modalShowAddAdAccountAction: ensurePermission(modalShowAdAccountAdd, writePermissions.addAdAccount)
})(AdAccountsHeader);
