import * as customPropTypes from 'src/customPropTypes';
import * as routeActions from 'react-router-redux';
import { connect } from 'react-redux';
import IcomoonIcon from 'src/components/IcomoonIcon';
import NetworkIcon from 'src/components/NetworkIcon';
import PropTypes from 'prop-types';
import React from 'react';
import { selectDataSourceById } from 'src/selectors/dataSources';
import styles from 'src/stylesheets/views/dataSources/dataSourceListItem.scss';
import {
    getDataSourcePath, getLabelAndIconByIsTimeSeries, getLabelAndIconByIsPrivateStatistic, getDeprecationDateSentenceFromNow
} from 'src/utils/dataSources';
import withTooltip from 'src/components/withToolTip';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';

const IcomoonIconWithTooltip = withTooltip(IcomoonIcon);

const DataSourceListItem = (props) => {
    const { dataSource, routePushAction } = props;
    const {
        name, isPrivateStatistic, allowedPlatformType, isTimeSeries, isDeprecated, deprecationDate
    } = dataSource;
    const privateStatisticIconAndLabel = getLabelAndIconByIsPrivateStatistic(isPrivateStatistic);
    const timeSeriesIconAndLabel = getLabelAndIconByIsTimeSeries(isTimeSeries);

    const dataSourceNameWithDeprecationText = `${name}${isDeprecated ? ` (${getDeprecationDateSentenceFromNow(deprecationDate)})` : ''}`;
    return (
        <div className={styles.wrapper} onClick={() => { routePushAction(getDataSourcePath(name)); }}>
            <div className={styles.left}>
                <div className={styles.network}>
                    <NetworkIcon platformType={allowedPlatformType} className={styles.networkIcon} />
                </div>
                <div className={styles.dataSourceNameWrapper}>
                    <DivWithTooltip tooltip={dataSourceNameWithDeprecationText}>
                        <span className={styles.dataSourceName}>{dataSourceNameWithDeprecationText}</span>
                    </DivWithTooltip>
                </div>
            </div>
            <div className={styles.right}>
                <div className={styles.privateStatistic}>
                    <IcomoonIconWithTooltip
                      icon={privateStatisticIconAndLabel.icon}
                      className={styles.icon}
                      tooltip={privateStatisticIconAndLabel.label}
                    />
                </div>
                <IcomoonIconWithTooltip
                  icon={timeSeriesIconAndLabel.icon}
                  className={styles.icon}
                  tooltip={timeSeriesIconAndLabel.label}
                />
            </div>
        </div>
    );
};

DataSourceListItem.propTypes = {
    dataSource: customPropTypes.dataSource.isRequired,
    routePushAction: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => ({
    dataSource: selectDataSourceById(state, ownProps.id)
});

export default connect(mapStateToProps, {
    routePushAction: routeActions.push
})(DataSourceListItem);
