import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { selectNotificationById } from 'src/selectors/userNotification';
import UserNotificationListItem from 'src/components/views/userNotifications/UserNotificationListItem';
import withUserNotificationClickHandling from 'src/components/withUserNotificationClickHandling';

const UserNotificationListItemWithUserNotificationClickHandling = withUserNotificationClickHandling(UserNotificationListItem);

const makeMapStateToProps = () => (state, ownProps) => ({
    userNotificationItem: selectNotificationById(state, ownProps.id)
});

const UserNotificationContainer = connect(makeMapStateToProps)(UserNotificationListItemWithUserNotificationClickHandling);

UserNotificationContainer.propTypes = {
    id: PropTypes.string.isRequired
};

export default UserNotificationContainer;
