import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import React from 'react';
import { makeSelectDashboardShareLinkById } from 'src/selectors/dashboardShareLinks';
import { connect } from 'react-redux';
import DashboardShareLinkStatusLabel from 'src/components/labels/DashboardShareLinkStatusLabel';

const DashboardShareLinkStatusWrapper = (props) => {
    const { dashboardShareLink } = props;
    const { hasExpiry, validUntil } = dashboardShareLink;
    return <DashboardShareLinkStatusLabel hasExpiry={hasExpiry} validUntil={validUntil} />;
};

DashboardShareLinkStatusWrapper.propTypes = {
    dashboardShareLinkId: PropTypes.string.isRequired,
    dashboardShareLink: customPropTypes.dashboardShareLink.isRequired,
};

const makeMapStateToProps = () => {
    const selectDashboardShareLinkById = makeSelectDashboardShareLinkById();
    return (state, ownProps) => {
        const { dashboardShareLinkId } = ownProps;
        return {
            dashboardShareLink: selectDashboardShareLinkById(state, dashboardShareLinkId)
        };
    };
};

export default connect(makeMapStateToProps)(DashboardShareLinkStatusWrapper);
