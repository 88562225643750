import { Link, withRouter } from 'react-router';
import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import { isAlsoActiveOnRoutes } from 'src/utils/routes';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/buttons/topNavTextLink.scss';

const TopNavTextLink = (props) => {
    const {
        to,
        alsoActiveOn,
        location,
        onClick,
        children
    } = props;

    const url = to && to.pathname ? to.pathname : to;

    const className = classnames(styles.button, { [styles.activeTab]: isAlsoActiveOnRoutes(alsoActiveOn, location) });
    const linkProps = {
        activeClassName: to ? styles.activeTab : null,
        className
    };
    if (url) {
        linkProps.to = props.to;
    }
    if (onClick) {
        linkProps.onClick = (e) => { onClick(e); };
    }

    return (
        <Link {...linkProps}>
            {children}
        </Link>
    );
};

TopNavTextLink.propTypes = {
    onClick: PropTypes.func,
    to: customPropTypes.linkTo,
    children: customPropTypes.children,
    alsoActiveOn: PropTypes.arrayOf(PropTypes.string),
    location: customPropTypes.location.isRequired,
    className: PropTypes.string,
};

export default withRouter(TopNavTextLink);
