import {
    call, put, select, takeEvery
} from 'redux-saga/effects';
import {
    generateParams, getValidDashboardSearchParams, selectMetricSearchInput, selectMetricSearchQueryParams, selectMetricSearchSelectedTags, selectTopLevelLinkToDiscover
} from 'src/selectors/metricSearch';
import {
    METRIC_SEARCH_EMPTY_SELECTION,
    METRIC_SEARCH_FILTER_BY_UPDATE,
    METRIC_SEARCH_ORDER_BY_UPDATE,
    METRIC_SEARCH_RESULT_REQUEST,
    METRIC_SEARCH_SEARCH_REQUEST,
    METRIC_SEARCH_SELECTION_CHANGED,
    METRIC_SEARCH_SELECTION_REMOVE,
    METRIC_SEARCH_SELECTION_REPLACE,
    METRIC_SEARCH_SET_TAG_REMOVE_TERM,
    METRIC_SEARCH_TERM_CHANGED,
    metricSearchResultError,
    metricSearchResultSuccess,
    metricSearchSearchError,
    metricSearchSearchSuccess,
    metricSearchSelectionChanged as metricSearchSelectionChangedAction
} from 'src/actions/metricSearch';
import { parseResults, parseResultsForEntities } from 'src/parsers';
import * as routeActions from 'react-router-redux';
import _has from 'lodash/has';
import _omit from 'lodash/omit';
import { convertFilterByToRoute } from 'src/utils/discoverUtiles';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import { reportError } from 'src/utils/reportError';

function* metricSearchRedirectToStart() {
    const discoverPath = yield select(selectTopLevelLinkToDiscover);
    yield put(routeActions.push({ pathname: discoverPath }));
}

function* metricSearchSearchRequest(action) {
    const { metricSearchInput } = action.payload;
    try {
        const tagIds = [];
        metricSearchInput.selectedTags.forEach((tag) => {
            if (tag.id) {
                tagIds.push(tag.id);
            }
        });
        const params = {
            tagIds: tagIds.join(','),
            orderBy: metricSearchInput.orderBy,
            filterBy: metricSearchInput.filterBy
        };

        if (metricSearchInput.term !== '') {
            params.freeTextTags = metricSearchInput.term;
        }
        const serverRequest = createServerRequest(params);

        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-discover/search');
        if (response) {
            const { results } = response.jsonData;
            yield put(metricSearchSearchSuccess(metricSearchInput, parseResults(results), parseResultsForEntities(results)));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(metricSearchSearchError(metricSearchInput, applicationOrServerError));
    }
}

const generatePathnameAndQueryParameters = (filterBy, oldQueryParams, changes = {}, omit = false) => {
    let pathname = `/discover/${convertFilterByToRoute(filterBy)}/search`;
    let query = Object.assign({}, oldQueryParams, changes);
    if (filterBy === 'dashboards' && _has(query, 'search')) {
        const search = getValidDashboardSearchParams(query.search);
        if (search.length > 0) {
            query.search = search;
        } else {
            pathname = `/discover/${convertFilterByToRoute(filterBy)}`;
            query = _omit(query, 'search');
        }
    }
    if (omit) {
        query = _omit(query, omit);
    }

    return {
        pathname,
        query
    };
};

function* metricSearchTermChanged(action) {
    // if both are empty soft reset;
    const { term } = action.payload;
    const metricSearchInput = yield select(selectMetricSearchInput);
    const metricSearchQueryParams = yield select(selectMetricSearchQueryParams);
    const { filterBy } = metricSearchInput;
    if (term === '') {
        if (metricSearchInput.selectedTags.length < 1) {
            yield call(metricSearchRedirectToStart);
        } else {
            const to = generatePathnameAndQueryParameters(filterBy, metricSearchQueryParams, {}, 'term');
            yield put(routeActions.push(to));
        }
    } else {
        const to = generatePathnameAndQueryParameters(filterBy, metricSearchQueryParams, { term });
        yield put(routeActions.push(to));
    }
}

function* metricSearchSelectionChanged(action) {
    // if both are empty soft reset;
    const { payload } = action;
    const { selectedTags } = payload;
    const search = generateParams(selectedTags);
    const metricSearchInput = yield select(selectMetricSearchInput);
    const metricSearchQueryParams = yield select(selectMetricSearchQueryParams);

    const { filterBy, term } = metricSearchInput;
    if (search.length < 1) {
        if (term === '') {
            yield call(metricSearchRedirectToStart);
        } else {
            const to = generatePathnameAndQueryParameters(filterBy, metricSearchQueryParams, {}, 'search');
            yield put(routeActions.push(to));
        }
    } else {
        const to = generatePathnameAndQueryParameters(filterBy, metricSearchQueryParams, { search });
        yield put(routeActions.push(to));
    }
}

function* metricSearchOrderByChanged(action) {
    const { orderBy } = action.payload;
    const metricSearchInput = yield select(selectMetricSearchInput);
    const metricSearchQueryParams = yield select(selectMetricSearchQueryParams);
    const { filterBy } = metricSearchInput;
    const to = generatePathnameAndQueryParameters(filterBy, metricSearchQueryParams, { orderBy });
    yield put(routeActions.push(to));
}

function* metricSearchFilterByChanged(action) {
    const { payload } = action;
    const { filterBy } = payload;
    const metricSearchQueryParams = yield select(selectMetricSearchQueryParams);
    // if no search params are specified we switch context of start page
    if (!_has(metricSearchQueryParams, 'search') && !_has(metricSearchQueryParams, 'term')) {
        const pathname = `/discover/${convertFilterByToRoute(filterBy)}`;
        yield put(routeActions.push({ pathname }));
    } else {
        const to = generatePathnameAndQueryParameters(filterBy, metricSearchQueryParams);
        yield put(routeActions.push(to));
    }
}

function* metricSearchResultRequest(action) {
    const { payload } = action;

    const { type, id } = payload;

    let params = { metricId: id };
    let url = '/client-discover/get-metric';

    if (type === 'DashboardTemplate') {
        params = { dashboardId: id };
        url = '/client-discover/get-public-dashboard';
    }

    try {
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, url);
        if (response) {
            const result = response.jsonData;
            yield put(metricSearchResultSuccess(type, id, parseResultsForEntities([result])));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(metricSearchResultError(type, id, applicationOrServerError));
    }
}

function isTagAlreadyInSelection(alreadySelectedTags, selectedTag) {
    return alreadySelectedTags.some((alreadySelectedTag) => {
        if (selectedTag.id === alreadySelectedTag.id) {
            return true;
        }
        return false;
    });
}

function getNumberOfSameTagTypes(alreadySelectedTags, selectedTag) {
    let count = 0;
    alreadySelectedTags.forEach((alreadySelectedTag) => {
        if (alreadySelectedTag.tagTypeId === selectedTag.tagTypeId) {
            count += 1;
        }
    });
    return count;
}

function findPostionOfFirstSameTagType(alreadySelectedTags, selectedTag) {
    return alreadySelectedTags.findIndex((alreadySelectedTag) => alreadySelectedTag.tagTypeId === selectedTag.tagTypeId);
}

function* metricSearchSelectionReplace(action) {
    const { selectedTag } = action.payload;
    const alreadySelectedTags = yield select(selectMetricSearchSelectedTags);

    if (alreadySelectedTags.length < 1) {
        yield put(metricSearchSelectionChangedAction([selectedTag]));
    } else if (!(isTagAlreadyInSelection(alreadySelectedTags, selectedTag) && getNumberOfSameTagTypes(alreadySelectedTags, selectedTag) === 1)) {
        const positionInArray = findPostionOfFirstSameTagType(alreadySelectedTags, selectedTag);
        if (positionInArray < 0) {
            yield put(metricSearchSelectionChangedAction([...alreadySelectedTags, selectedTag]));
        } else {
            const tagsWithoutSameTyp = alreadySelectedTags.filter((alreadySelectedTag) => alreadySelectedTag.tagTypeId !== selectedTag.tagTypeId);
            tagsWithoutSameTyp.splice(positionInArray, 0, selectedTag);
            yield put(metricSearchSelectionChangedAction(tagsWithoutSameTyp));
        }
    }
}

function* metricSearchSelectionRemove(action) {
    const { selectedTag } = action.payload;
    const alreadySelectedTags = yield select(selectMetricSearchSelectedTags);
    if (isTagAlreadyInSelection(alreadySelectedTags, selectedTag)) {
        const tagsWithoutSelection = alreadySelectedTags.filter((alreadySelectedTag) => alreadySelectedTag.id !== selectedTag.id);
        yield put(metricSearchSelectionChangedAction(tagsWithoutSelection));
    }
}

function* metricSearchSetTagRemoveTerm(action) {
    const { selectedTag, filterBy } = action.payload;
    const search = generateParams([selectedTag]);
    const metricSearchQueryParams = yield select(selectMetricSearchQueryParams);
    const to = generatePathnameAndQueryParameters(filterBy, metricSearchQueryParams, { search }, 'term');
    yield put(routeActions.push(to));
}

export default function* metricSearchSagas() {
    yield takeEvery(METRIC_SEARCH_SELECTION_CHANGED, metricSearchSelectionChanged);
    yield takeEvery(METRIC_SEARCH_SELECTION_REPLACE, metricSearchSelectionReplace);
    yield takeEvery(METRIC_SEARCH_ORDER_BY_UPDATE, metricSearchOrderByChanged);
    yield takeEvery(METRIC_SEARCH_FILTER_BY_UPDATE, metricSearchFilterByChanged);
    yield takeEvery(METRIC_SEARCH_EMPTY_SELECTION, metricSearchRedirectToStart);
    yield takeEvery(METRIC_SEARCH_SEARCH_REQUEST, metricSearchSearchRequest);
    yield takeEvery(METRIC_SEARCH_RESULT_REQUEST, metricSearchResultRequest);
    yield takeEvery(METRIC_SEARCH_TERM_CHANGED, metricSearchTermChanged);
    yield takeEvery(METRIC_SEARCH_SELECTION_REMOVE, metricSearchSelectionRemove);
    yield takeEvery(METRIC_SEARCH_SET_TAG_REMOVE_TERM, metricSearchSetTagRemoveTerm);
}
