import * as customPropTypes from 'src/customPropTypes';
import Carousel from 'src/components/Carousel';
import DiscoverTile from 'src/components/metricSearchResultTile/DiscoverTile';
import PropTypes from 'prop-types';
import React from 'react';
import RecommendationsHeader from 'src/components/recommendation/RecommendationsHeader';
import styles from 'src/stylesheets/recommendedMetrics.scss';
import withStoreConnection from 'src/components/metricSearchResultTile/withStoreConnection';

const ConnectedMetricTile = withStoreConnection(DiscoverTile);

const RecommendationsCarousel = (props) => {
    const {
        isLoading, error, autoPlay, showAllLink, title, results
    } = props;
    let contentToRender = null;
    if (isLoading) {
        contentToRender = (<div className={styles.loadingWrapper}><span className={styles.loadingText}>Loading...</span></div>);
    } else if (error) {
        contentToRender = (<div>{error.message}</div>);
    } else if (results) {
        if (results.length > 0) {
            contentToRender = (
                <Carousel
                  dots
                  autoplay={autoPlay}
                  responsive={[
                      { breakpoint: 1000, settings: { slidesToShow: 1, slidesToScroll: 1 } },
                      { breakpoint: 1500, settings: { slidesToShow: 2, slidesToScroll: 2 } },
                      { breakpoint: 2000, settings: { slidesToShow: 3, slidesToScroll: 3 } },
                      { breakpoint: 999999, settings: { slidesToShow: 4, slidesToScroll: 4 } }
                  ]}
                >
                    {
                        results.map((result) => (
                            <div key={`recommendationItem-${result.type}-${result.id}`}>
                                <div className={styles.sliderItem}>
                                    <ConnectedMetricTile type={result.type} id={result.id} lazyLoad={false} />
                                </div>
                            </div>
                        ))
                    }
                </Carousel>
            );
        } else {
            contentToRender = <div>No results to show</div>;
        }
    }

    return (
        <div className={styles.container}>
            <RecommendationsHeader title={title} showAllLink={showAllLink} />
            {contentToRender}
        </div>
    );
};

RecommendationsCarousel.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    results: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired
    })),
    title: PropTypes.string.isRequired,
    error: customPropTypes.dataLoadingError,
    autoPlay: PropTypes.bool,
    showAllLink: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
};

RecommendationsCarousel.defaultProps = {
    showAllLink: false,
    autoPlay: false
};

export default RecommendationsCarousel;
