import {
    usePopper
} from 'react-popper';
import React, { Component } from 'react';
import _max from 'lodash/max';
import _min from 'lodash/min';
import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/popper.scss';
import TextWithResetIcon from 'src/components/forms/inputs/TextWithResetIcon';

const UP = 38;
const DOWN = 40;
const ESC = 27;
const RETURN = 13;

const getOptions = (placement) => ({
    placement,
    modifiers: [
        {
            name: 'offset',
            options: {
                offset: [0, 10],
            }
        },
    ]
});

const PopperComponent = ({
    overlay, show, renderFunction, placement
}) => {
    const [referenceElement, setReferenceElement] = React.useState(null);
    const [popperElement, setPopperElement] = React.useState(null);
    const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, getOptions(placement));

    return (
        <>
            <div ref={setReferenceElement}>
                { renderFunction() }
            </div>
            {
                show
                && (
                    <div
                      className={styles.popper}
                      ref={setPopperElement}
                      style={popperStyles.popper}
                      {...attributes.popper}
                    >
                        {overlay}
                    </div>
                )
            }
        </>
    );
};

PopperComponent.propTypes = {
    overlay: PropTypes.node.isRequired,
    show: PropTypes.bool.isRequired,
    renderFunction: PropTypes.func.isRequired,
    placement: PropTypes.string.isRequired
};

class TextWithAutocomplete extends Component {
    constructor(props) {
        super(props);

        this.handleOnChange = this.handleOnChange.bind(this);
        this.onResetIconClick = this.onResetIconClick.bind(this);
        this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleHide = this.handleHide.bind(this);
        this.handleOnItemSelect = this.handleOnItemSelect.bind(this);
        this.reset = this.reset.bind(this);

        this.state = {
            activeIndex: 0,
            show: false,
        };

        this.mounted = false;
    }

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    handleOnKeyDown(e) {
        const { show, activeIndex } = this.state;
        const {
            autocompleteSuggestions,
            loading,
            blurAfterSubmit
        } = this.props;
        if (!show || loading) {
            return false;
        }
        // if no results only listen for ESC
        if (!autocompleteSuggestions || (autocompleteSuggestions.length < 1 && e.keyCode !== ESC)) {
            return false;
        }

        switch (e.keyCode) {
            case ESC:
                this.handleHide();
                return true;
            case DOWN:
                this.setState({ activeIndex: _min([activeIndex + 1, autocompleteSuggestions.length - 1]) });
                e.preventDefault();
                return true;
            case UP:
                this.setState({ activeIndex: _max([activeIndex - 1, 0]) });
                e.preventDefault();
                return true;
            case RETURN:
                this.handleOnItemSelect(autocompleteSuggestions[activeIndex]);
                if (blurAfterSubmit) {
                    e.target.blur();
                }
                return true;
            default:
                return false;
        }
    }

    handleHide() {
        if (this.mounted) {
            this.setState({ show: false });
        }
    }

    handleOnItemSelect(item) {
        const { onItemSelect, resetAfterSelect } = this.props;
        onItemSelect(item);

        if (resetAfterSelect) {
            this.reset();
        }
    }

    handleShow() {
        if (this.mounted) {
            this.setState({ show: true });
        }
    }

    handleOnChange(e) {
        const { onChange, minLength } = this.props;
        const newValue = e.target.value;
        onChange(newValue);
        this.setState({ activeIndex: 0 });

        if (newValue.length >= minLength) {
            this.handleShow();
        }
        if (newValue.length < minLength) {
            this.handleHide();
        }
    }

    onClick() {
        const { show } = this.state;
        const { minLength, searchTerm } = this.props;

        if (!show && searchTerm.length >= minLength) {
            this.handleShow();
        }
    }

    onFocus() {
        const { show } = this.state;
        const { minLength, searchTerm } = this.props;

        if (!show && searchTerm.length >= minLength) {
            this.handleShow();
        }
    }

    onBlur() {
        setTimeout(() => this.handleHide(), 250);
    }

    onResetIconClick() {
        this.reset();
    }

    reset() {
        const { onChange } = this.props;
        onChange('');
        this.handleHide();
    }

    render() {
        const { show, activeIndex } = this.state;

        const {
            searchTerm,
            autocompleteSuggestions,
            loading,
            renderAutocompleteMenu,
            autoFocus,
            layout,
            icon,
            placeholder,
            menuPlacement
        } = this.props;

        const popover = (
            <Popover id="autoCompletePopover">
                {
                                    renderAutocompleteMenu(
                                        autocompleteSuggestions,
                                        activeIndex,
                                        this.handleHide,
                                        this.handleOnItemSelect,
                                        searchTerm,
                                        loading
                                    )
                                }
            </Popover>
        );

        return (
            <PopperComponent
              renderFunction={() => (
                  <TextWithResetIcon
                    onResetClick={this.onResetIconClick}
                    onChange={this.handleOnChange}
                    value={searchTerm}
                    onFocus={this.onFocus}
                    onClick={this.onClick}
                    onBlur={this.onBlur}
                    onKeyDown={this.handleOnKeyDown}
                    active={(searchTerm.length > 0)}
                    placeholder={placeholder}
                    icon={icon}
                    layout={layout}
                    autoFocus={autoFocus}
                  />
              )}
              overlay={popover}
              show={show}
              placement={menuPlacement}
            />
        );
    }
}

TextWithAutocomplete.propTypes = {
    autocompleteSuggestions: PropTypes.arrayOf(PropTypes.any).isRequired,
    onItemSelect: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool,
    layout: PropTypes.oneOf(['default', 'listFilter', 'large', 'discover']),
    icon: PropTypes.string,
    placeholder: PropTypes.string,
    renderAutocompleteMenu: PropTypes.func.isRequired,
    searchTerm: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    resetAfterSelect: PropTypes.bool,
    blurAfterSubmit: PropTypes.bool,
    minLength: PropTypes.number,
    menuPlacement: PropTypes.string
};

TextWithAutocomplete.defaultProps = {
    autoFocus: false,
    layout: 'default',
    icon: '',
    placeholder: '',
    loading: false,
    resetAfterSelect: false,
    blurAfterSubmit: false,
    minLength: 0,
    menuPlacement: 'bottom-start'
};

export default TextWithAutocomplete;
