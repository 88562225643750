import React from 'react';
import PostTagRuleItem from 'src/components/views/postTags/postTagManager/postTagRules/PostTagRuleItem';
import styles from 'src/stylesheets/views/postTags/postTagManager/postTagRuleList.scss';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';

const PostTagRuleList = (props) => {
    const { postTagRules, onEditClick, onCopyClick } = props;
    return (
        <div className={styles.wrapper}>
            {
                postTagRules.map((currentPostTagRule, i) => (
                    <div className={styles.item} key={currentPostTagRule.id}>
                        <PostTagRuleItem postTagRule={currentPostTagRule} onEditClick={() => { onEditClick(i); }} onCopyClick={() => { onCopyClick(i); }} />
                    </div>
                ))
            }
        </div>
    );
};

PostTagRuleList.propTypes = {
    postTagRules: customPropTypes.postTagRules.isRequired,
    onEditClick: PropTypes.func.isRequired,
    onCopyClick: PropTypes.func.isRequired
};

export default PostTagRuleList;
