import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as postsAction from 'src/actions/posts';
import {
    getCurrentPageNumber,
    getResultTotalRowCount,
    selectPostIdsByCurrentPage,
    makeSelectPostSearchState
} from 'src/selectors/posts';
import styles from 'src/stylesheets/views/postTags/postTagging/paginatedPostsData.scss';
import Error from 'src/components/errors/Error';
import * as customPropTypes from 'src/customPropTypes';
import PaginationScroller from 'src/components/paginator/PageNumbersWithScroller';
import { getNumberOfPages } from 'src/utils/postSearch';
import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import emptyImage from 'src/resources/illustrations/posts_empty.png';
import { getPaginationServerRequest } from 'src/requestHandling/datasourceDataRequests';
import PostTaggingLoadingSkeleton from 'src/components/loadingIndicators/PostTaggingLoadingSkeleton';

const PaginatedPostsData = (props) => {
    const {
        children,
        postIds,
        currentPageNumber,
        searchRequestInput,
        paginatePostsRequestAction,
        searchLoadingState,
        totalCount
    } = props;
    const { isPending, error } = searchLoadingState;
    const { parameters } = searchRequestInput;
    const numberOfPages = getNumberOfPages(totalCount, parameters.limit);

    const handlePaginateRequest = (pageNumber) => {
        const manipulatedServerRequest = getPaginationServerRequest(searchRequestInput, pageNumber, parameters.limit);
        paginatePostsRequestAction(pageNumber, manipulatedServerRequest);
    };
    return (
        <div className={styles.wrapper}>
            {
                postIds.length > 0
                && children(postIds, isPending)
            }
            {
                (isPending && postIds.length === 0)
                && <PostTaggingLoadingSkeleton />
            }
            {
                (!isPending && postIds.length === 0 && !error)
                && <EmptyInfoScreen title="No posts found" message="Try selecting different filters" image={emptyImage} imageClassName={styles.emptyImage} />
            }
            {
                (!isPending && error)
                && <Error error={error} />
            }
            {
                (!error && postIds.length > 0)
                && (
                <div className={styles.navigationScroller}>
                    <PaginationScroller
                      count={numberOfPages}
                      currentPage={currentPageNumber}
                      onPageClick={handlePaginateRequest}
                      disabled={isPending}
                    />
                </div>
                )
            }
        </div>
    );
};

PaginatedPostsData.propTypes = {
    totalCount: PropTypes.number.isRequired,
    currentPageNumber: PropTypes.number.isRequired,
    postIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    searchLoadingState: customPropTypes.asyncDataLoadingState.isRequired,
    children: PropTypes.func.isRequired,
    paginatePostsRequestAction: PropTypes.func.isRequired,
    searchRequestInput: customPropTypes.postSearchRequestInput.isRequired,
};

const makeMapStateToProps = () => {
    const selectPostSearchState = makeSelectPostSearchState();
    return (state) => ({
        totalCount: getResultTotalRowCount(state),
        currentPageNumber: getCurrentPageNumber(state),
        postIds: selectPostIdsByCurrentPage(state),
        searchLoadingState: selectPostSearchState(state),
    });
};

export default connect(makeMapStateToProps, {
    paginatePostsRequestAction: postsAction.paginatePostsRequest
})(PaginatedPostsData);
