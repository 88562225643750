import React, { useState } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import ReportsOverviewTable from 'src/components/views/reports/ReportsOverviewTable';

const ascendingSortDir = 'asc';
const descendingSortDir = 'desc';

const ReportsOverview = () => {
    const [sortBy, changeSortBy] = useState('type');
    const [sortDir, changeSortDir] = useState(descendingSortDir);

    const handleOnSortBy = (sortByValue) => {
        if (sortBy !== sortByValue) {
            changeSortBy(sortByValue);
        }
        let newSortOrder = descendingSortDir;
        if (sortBy === sortByValue && sortDir === descendingSortDir) {
            newSortOrder = ascendingSortDir;
        }
        changeSortDir(newSortOrder);
    };

    return <ReportsOverviewTable handleOnSortByAction={handleOnSortBy} sortBy={sortBy} sortDir={sortDir} />;
};

ReportsOverview.propTypes = {
    location: customPropTypes.location
};

export default ReportsOverview;
