import { formName, getSortDirection, initialValues } from 'src/components/views/dashboards/DashboardsFilterBarForm';
import { getFormValues } from 'redux-form';
import { makeSelectListItemIdsForFoldersAndDashboards } from 'src/selectors/dashboards';
import * as customPropTypes from 'src/customPropTypes';
import Body from 'src/components/Body';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import ContentWithSidebar from 'src/components/views/ContentWithSidebar';
import dashboardListEmpty from 'src/resources/illustrations/dashboard-list-empty.png';
import DashboardListHeader from 'src/components/views/dashboards/DashboardsListHeader';
import DashboardListItemContainer from 'src/components/views/dashboards/DashboardListItemContainer';
import DashboardsHeader from 'src/components/header/DashboardsHeader';
import DashboardsSidebar from 'src/components/views/dashboards/DashboardsSidebar';
import emptyFolder from 'src/resources/illustrations/empty-folder.png';
import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import ErrorView from 'src/components/errors/ErrorView';
import FolderListItemContainer from 'src/components/views/folders/FolderListItemContainer';
import Footer from 'src/components/Footer';
import List from 'src/components/List';
import ListPageWrapper from 'src/components/ListPageWrapper';
import { makeSelectIsOneItemSelected } from 'src/selectors/lists';
import { modalsShowCreateDashboard } from 'src/actions/overlays';
import PropTypes from 'prop-types';
import React from 'react';
import ScrollView from 'src/components/ScrollView';
import withListStateHandling from 'src/components/bulkList/withListStateHandling';
import withReactRouterLinkHandling from 'src/components/withReactRouterLinkHandling';
import withScrollPosition from 'src/components/withScrollPosition';
import withStickyListHeaderHandling from 'src/components/withStickyListHeaderHandling';
import { getFolderIconByOwnersipType, getReadableOwnershipType } from 'src/utils/ownership';

import {
    makeSelectFolderByFolderIdAndType,
    makeSelectFolderIdsByParentIdAndType,
    makeSelectDashboardIdsByFolderIdAndType
} from 'src/selectors/ownership';

const listName = 'dashboards';
const ButtonWithReactRouterLinkHandling = withReactRouterLinkHandling(Button);
const ListWithListStateHandling = withListStateHandling(List, listName);
const DashboardListHeaderWithStickyHandling = withStickyListHeaderHandling(DashboardListHeader);
const ScrollViewWithScrollPosition = withScrollPosition(ScrollView, 'dashboards');

const Dashboards = (props) => {
    const {
        currentFolder, dashboardIds, folderIds, listItemIds, modalsShowCreateDashboardAction, location, isOneItemSelected, type
    } = props;
    const { id } = currentFolder;

    if (!currentFolder) {
        return (
            <ErrorView
              headline="Folder not found"
              error={{ message: 'The folder you tried to access does not exist.' }}
            />
        );
    }

    const folders = folderIds.map((folderId) => (
        <FolderListItemContainer
          type={type}
          label="Folder"
          folderId={folderId}
          key={`folder-${folderId}`}
          listName={listName}
          labelIcon={getFolderIconByOwnersipType(type)}
        />
    ));

    const dashboards = dashboardIds.map((dashboardId) => (
        <DashboardListItemContainer
          dashboardId={dashboardId}
          key={`dashboard-${dashboardId}`}
          type={type}
          listName={listName}
          labelIcon={getFolderIconByOwnersipType(type)}
        />
    ));
    const foldersAndDashboards = folders.concat(dashboards);
    const itemCount = foldersAndDashboards.length;

    const emptyDashboardListButtons = (
        <ButtonGroup>
            <ButtonWithReactRouterLinkHandling to="/discover/dashboards" label="Find a Template" action />
            <Button label="Create your own" onClick={() => { modalsShowCreateDashboardAction(id, true); }} />
        </ButtonGroup>
    );

    const sidebar = <DashboardsSidebar currentFolderId={id} listName={listName} />;

    return (
        <ContentWithSidebar sidebar={sidebar} showSidebar={isOneItemSelected}>
            <ScrollViewWithScrollPosition
              scrollPositionIdentifier={`folder-${id}`}
              locationKey={location.key}
              dataViewName={`dashboards-folder-${id}`}
            >
                <DashboardsHeader folder={currentFolder} type={type} />
                <Body>
                    <ListPageWrapper>
                        <DashboardListHeaderWithStickyHandling offsetTop={68} listName={listName} />
                        {
                                // we want a new list per folder. Therefore we set the folderId as key
                                itemCount > 0 && (
                                    <ListWithListStateHandling key={id} listItemIds={listItemIds}>
                                        {foldersAndDashboards}
                                    </ListWithListStateHandling>
                                )
                            }
                    </ListPageWrapper>
                    {
                            itemCount < 1 && (id === 'user' || id === 'shared') && (
                                <EmptyInfoScreen
                                  image={dashboardListEmpty}
                                  title={`Welcome to your ${getReadableOwnershipType(type)} dashboard list`}
                                  message={`You haven’t created any ${getReadableOwnershipType(type)} dashboards yet. You can start by creating your own dashboard or
                                      selecting a dashboard template in our discover section.`}
                                  buttons={emptyDashboardListButtons}
                                />
                            )
                        }
                    {
                            itemCount < 1 && !(id === 'user' || id === 'shared') && (
                                <EmptyInfoScreen image={emptyFolder} title="This folder is empty" />
                            )
                        }
                </Body>
                <Footer />
            </ScrollViewWithScrollPosition>
        </ContentWithSidebar>
    );
};

Dashboards.propTypes = {
    currentFolderId: PropTypes.string.isRequired,
    folderIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    dashboardIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    listItemIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    currentFolder: customPropTypes.folderOrFalse.isRequired,
    modalsShowCreateDashboardAction: PropTypes.func.isRequired,
    location: customPropTypes.location,
    isOneItemSelected: PropTypes.bool.isRequired,
    type: PropTypes.oneOf(['shared', 'user']),
};

const makeMapStateToProps = () => {
    const formValueSelector = getFormValues(formName);
    const selectFolderIdsByParentIdAndType = makeSelectFolderIdsByParentIdAndType();
    const selectDashboardIdsByFolderId = makeSelectDashboardIdsByFolderIdAndType();
    const selectListItemIdsForFoldersAndDashboards = makeSelectListItemIdsForFoldersAndDashboards();
    const selectFolderByType = makeSelectFolderByFolderIdAndType();
    const selectIsOneItemSelected = makeSelectIsOneItemSelected();
    return (state, ownProps) => {
        const { currentFolderId, type } = ownProps;
        const { sortBy } = formValueSelector(state) || initialValues;
        const sortDir = getSortDirection(sortBy);
        const currentFolder = selectFolderByType(state, type, currentFolderId);
        const folderIds = currentFolder ? selectFolderIdsByParentIdAndType(state, type, currentFolder.id, sortBy, sortDir) : [];
        const dashboardIds = currentFolder ? selectDashboardIdsByFolderId(state, currentFolder.id, type, sortBy, sortDir) : [];
        return {
            currentFolder,
            folderIds,
            dashboardIds,
            currentFolderId,
            listItemIds: selectListItemIdsForFoldersAndDashboards(folderIds, dashboardIds),
            isOneItemSelected: selectIsOneItemSelected(state, listName)
        };
    };
};

export default connect(makeMapStateToProps, {
    modalsShowCreateDashboardAction: modalsShowCreateDashboard,
})(Dashboards);
