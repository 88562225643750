export const POST_TAGS_POSTS_GET_REQUEST = 'POST_TAGS_POSTS_GET_REQUEST_BACKGROUND';
export const POST_TAGS_POSTS_GET_SUCCESS = 'POST_TAGS_POSTS_GET_SUCCESS_BACKGROUND';
export const POST_TAGS_POSTS_GET_ERROR = 'POST_TAGS_POSTS_GET_ERROR_BACKGROUND';

export const postTagsPostInfoGetRequest = () => ({
    type: POST_TAGS_POSTS_GET_REQUEST
});

export const postTagsPostInfGetSuccess = (numberOfPostsByPostTag) => ({
    type: POST_TAGS_POSTS_GET_SUCCESS,
    payload: {
        numberOfPostsByPostTag
    }
});

export const postTagsPostInfoGetError = (error) => ({
    type: POST_TAGS_POSTS_GET_ERROR,
    payload: { error }
});
