import PageHeadline from 'src/components/header/layout/PageHeadline';
import IcomoonIcon from 'src/components/IcomoonIcon';
import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/pageHeadlineWithIcon.scss';
import headerStyles from 'src/stylesheets/headers/header.scss';
import withTooltip from 'src/components/withToolTip';

const IcomoonIconWithToolTip = withTooltip(IcomoonIcon);

const PageHeadlineWithIcon = ({
    children, className, small, icon, iconToolTip
}) => (
    <div className={styles.container}>
        <div className={styles.icon}>
            <IcomoonIconWithToolTip tooltip={iconToolTip} icon={icon} className={classnames(headerStyles.headline, { [styles.big]: !small, [styles.small]: small })} />
        </div>
        <div className={styles.pageHeadline}>
            <PageHeadline className={className} small={small}>{children}</PageHeadline>
        </div>
    </div>
);

PageHeadlineWithIcon.propTypes = {
    children: customPropTypes.children.isRequired,
    className: PropTypes.string,
    small: PropTypes.bool, // small is used in fixed headers (sticky at the top when scrolling)
    icon: PropTypes.string.isRequired,
    iconToolTip: PropTypes.node.isRequired
};

PageHeadlineWithIcon.defaultProps = {
    small: false
};

export default PageHeadlineWithIcon;
