import {
    all, call, put, select, takeEvery
} from 'redux-saga/effects';
import {
    FOLDER_ADD_REQUEST,
    FOLDER_EDIT_REQUEST,
    folderAddError,
    folderAddSuccess,
    folderEditError,
    folderEditSuccess,
    FOLDERS_AND_DASHBOARDS_DELETE_REQUEST,
    FOLDERS_AND_DASHBOARDS_MOVE_TO_FOLDER_REQUEST,
    foldersAndDashboardsDeleteError,
    foldersAndDashboardsDeleteSuccess,
    foldersAndDashboardsMoveToFolderError,
    foldersAndDashboardsMoveToFolderSuccess
} from 'src/actions/folders';
import { makeSelectDashboardMetricIdsByDashboardIds, makeSelectMetricIdsByDashboardMetricIds } from 'src/selectors/dashboards';
import {
    modalHideAddFolder,
    modalHideEditFolder,
    modalHideMoveToFolder,
    modalsHideDashboardAndFolderDelete,
    modaslHideEditDashboard
} from 'src/actions/overlays';
import {
    showFolderAddSuccessNotification,
    showFoldersAndDashboardDeleteSuccessOrWarning,
    showFoldersAndDashboardMoveSuccessOrWarning,
    showNotification
} from 'src/actions/notifications';
import * as routeActions from 'react-router-redux';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { dashboardMetricsCleanupAfterDashboardDelete } from 'src/actions/dashboardMetrics';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import { metricUsageCountUpdateRequest } from 'src/actions/metric';
import {
    parseFolder, parseOwnershipFolders, parseOwnershipFolder, parseOwnershipDashboards, parseOwnershipDashboard
} from 'src/parsers';
import { selectDashboardTypesByIds, selectFolderTypeById, selectFolderTypesByIds } from 'src/selectors/ownership';
import { reportError } from 'src/utils/reportError';
import { SubmissionError } from 'redux-form';
import { uncheckAllListValues } from 'src/actions/lists';
import _get from 'lodash/get';
import { makeSelectDashboardShareLinkIdsByDashboardIds } from 'src/selectors/dashboardShareLinks';
import { dashboardShareLinksCleanup } from 'src/actions/dashboardShareLinks';
import { dashboardReportsCleanup } from 'src/actions/dashboardReports';
import { makeSelectDashboardReportIdsByDashboardIds } from 'src/selectors/dashboardReports';

function* folderAddRequest(action) {
    const { newFolder } = action.payload;
    const {
        folderName, parentId, parentFolderType
    } = newFolder;
    const params = {
        name: folderName,
        parentId: parentId === parentFolderType ? null : parentId,
        parentFolderType
    };

    try {
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/create-folder');
        if (response) {
            const { jsonData } = response;
            const parsedFolders = parseOwnershipFolders(jsonData);
            yield put(folderAddSuccess(parsedFolders));
            yield put(modalHideAddFolder());
            yield put(showFolderAddSuccessNotification(folderName));
        }
        if (serverError) {
            yield put(folderAddError(new SubmissionError({ _error: serverError })));
        }
    } catch (applicationError) {
        reportError(applicationError);
        yield put(folderAddError(new SubmissionError({ _error: applicationError })));
    }
}

function* folderEditRequest(action) {
    const { updatedFolder } = action.payload;
    const { id, folderName } = updatedFolder;
    const folderType = yield select(selectFolderTypeById, id);
    const params = {
        folderId: id,
        name: folderName,
        folderType
    };
    try {
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/edit-folder');
        if (response) {
            const { jsonData } = response;
            const parsedFolder = parseFolder(jsonData);
            yield put(folderEditSuccess(parsedFolder));
            yield put(modalHideEditFolder(id));
            yield put(showNotification('Your folder was successfully updated.'));
        }
        if (serverError) {
            yield put(folderEditError(new SubmissionError({ _error: serverError })));
        }
    } catch (applicationError) {
        reportError(applicationError);
        yield put(folderEditError(new SubmissionError({ _error: applicationError })));
    }
}

function* dashboardOrFolderMovingRequest(serverRequest, type, id, endPoint) {
    const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, endPoint);
    return {
        response, serverError, type, id
    };
}

const gatherFolderAndDashboardBatchedResults = (collectedResponses) => {
    const folderIdsWithError = [];
    const folderSuccessfullyOperations = [];
    const dashboardIdsWithError = [];
    const dashboardSuccessfullyOperations = [];

    collectedResponses.forEach((responseContainer) => {
        const {
            response, serverError, type, id
        } = responseContainer;

        if (type === 'folder') {
            if (response) {
                const { jsonData } = response;
                folderSuccessfullyOperations.push({
                    movedFolder: parseOwnershipFolder(jsonData.movedFolder),
                    affectedFolders: parseOwnershipFolders(jsonData.affectedFolders),
                    affectedDashboards: parseOwnershipDashboards(jsonData.affectedDashboards)
                });
            }
            if (serverError) {
                folderIdsWithError.push(id);
            }
        } else if (type === 'dashboard') {
            if (response) {
                const { jsonData } = response;
                dashboardSuccessfullyOperations.push({
                    movedDashboard: parseOwnershipDashboard(jsonData.movedDashboard),
                    affectedFolders: parseOwnershipFolders(jsonData.affectedFolders),
                    affectedDashboards: parseOwnershipDashboards(jsonData.affectedDashboards)
                });
            }
            if (serverError) {
                dashboardIdsWithError.push(id);
            }
        }
    });

    const foldersResponses = {
        errors: folderIdsWithError,
        success: folderSuccessfullyOperations
    };

    const dashboardsResponses = {
        errors: dashboardIdsWithError,
        success: dashboardSuccessfullyOperations
    };

    return { foldersResponses, dashboardsResponses };
};

function* foldersAndDashboardsMoveToFolderRequest(action) {
    const {
        folderIds, dashboardIds, toFolderId, fromFolderId
    } = action.payload;
    try {
        const toFolderType = yield select(selectFolderTypeById, toFolderId);
        const dashboardTypesById = yield select(selectDashboardTypesByIds, dashboardIds);
        const folderTypesByIds = yield select(selectFolderTypesByIds, folderIds);

        const dashboardRequests = dashboardIds.map((dashboardId) => call(dashboardOrFolderMovingRequest, createServerRequest({
            dashboardId,
            dashboardType: _get(dashboardTypesById, dashboardId),
            toFolderId: toFolderId === toFolderType ? null : toFolderId,
            toFolderType
        }), 'dashboard', dashboardId, '/client-widget/move-dashboard'));

        const folderRequests = folderIds.map((folderId) => call(dashboardOrFolderMovingRequest, createServerRequest({
            folderId,
            folderType: _get(folderTypesByIds, folderId),
            toFolderType,
            toFolderId: toFolderId === toFolderType ? null : toFolderId
        }), 'folder', folderId, '/client-widget/move-folder'));

        const dashboardsAndFolderRequest = dashboardRequests.concat(folderRequests);
        const collectedResponses = yield all(dashboardsAndFolderRequest);

        const { foldersResponses, dashboardsResponses } = gatherFolderAndDashboardBatchedResults(collectedResponses);

        yield put(modalHideMoveToFolder());
        yield put(uncheckAllListValues('dashboards'));
        yield put(foldersAndDashboardsMoveToFolderSuccess(foldersResponses.success, dashboardsResponses.success, toFolderId, fromFolderId));
        yield put(showFoldersAndDashboardMoveSuccessOrWarning(foldersResponses, dashboardsResponses, toFolderId));
    } catch (applicationError) {
        reportError(applicationError);
        yield put(modalHideMoveToFolder());
        yield put(foldersAndDashboardsMoveToFolderError(applicationError, fromFolderId));
        yield put(showNotification(applicationError.message, 'error'));
    }
}

function* dashboardOrFolderDeletingRequest(serverRequest, type, id, endPoint) {
    const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, endPoint);
    return {
        response, serverError, type, id
    };
}

const gatherFolderAndDashboardDeleteBatchedResults = (collectedResponses) => {
    let folderIdsWithError = [];
    let folderIdsWithSuccess = [];
    let dashboardIdsWithError = [];
    let dashboardIdsWithSuccess = [];

    collectedResponses.forEach((responseContainer) => {
        const {
            response, serverError, type, id
        } = responseContainer;
        if (type === 'folder') {
            if (response) {
                const {
                    notDeletedFolderIds, deletedFolderIds, notDeletedDashboardIds, deletedDashboardIds
                } = response.jsonData;
                folderIdsWithError = folderIdsWithError.concat(notDeletedFolderIds.map((currentId) => currentId.toString()));
                folderIdsWithSuccess = folderIdsWithSuccess.concat(deletedFolderIds.map((currentId) => currentId.toString()));
                dashboardIdsWithError = dashboardIdsWithError.concat(notDeletedDashboardIds.map((currentId) => currentId.toString()));
                dashboardIdsWithSuccess = dashboardIdsWithSuccess.concat(deletedDashboardIds.map((currentId) => currentId.toString()));
            }
            if (serverError) {
                folderIdsWithError.push(id);
            }
        } else if (type === 'dashboard') {
            if (response) {
                dashboardIdsWithSuccess.push(id);
            }
            if (serverError) {
                dashboardIdsWithError.push(id);
            }
        }
    });

    const foldersResponses = {
        errors: folderIdsWithError,
        success: folderIdsWithSuccess
    };

    const dashboardsResponses = {
        errors: dashboardIdsWithError,
        success: dashboardIdsWithSuccess
    };

    return { foldersResponses, dashboardsResponses };
};

function* foldersAndDashboardsDeleteRequest(action) {
    const {
        folderIds, dashboardIds, fromFolderId, redirectToDashboards, forceDeleteFolder
    } = action.payload;
    try {
        const dashboardTypesByIds = yield select(selectDashboardTypesByIds, dashboardIds);
        const dashboardRequests = dashboardIds.map((dashboardId) => call(dashboardOrFolderDeletingRequest, createServerRequest({
            dashboardId,
            dashboardType: _get(dashboardTypesByIds, dashboardId)
        }), 'dashboard', dashboardId, '/client-widget/delete-dashboard'));

        const folderTypesByIds = yield select(selectFolderTypesByIds, folderIds);
        const folderRequests = folderIds.map((folderId) => call(dashboardOrFolderDeletingRequest, createServerRequest({
            id: folderId,
            folderType: _get(folderTypesByIds, folderId),
        }), 'folder', folderId, forceDeleteFolder ? '/client-widget/force-remove-folder' : '/client-widget/remove-folder'));

        const dashboardsAndFolderRequest = dashboardRequests.concat(folderRequests);
        const collectedResponses = yield all(dashboardsAndFolderRequest);
        const { foldersResponses, dashboardsResponses } = gatherFolderAndDashboardDeleteBatchedResults(collectedResponses);
        yield put(modaslHideEditDashboard(dashboardIds[0]));

        if (redirectToDashboards === true) {
            yield put(routeActions.replace({
                pathname: '/dashboards'
            }));
        }

        // if dashboards were deleted, get all deleted dashboard's dashboardMetricIds
        // to delete them AFTER the dashboardboard deletion too:
        let dashboardMetricsToCleanup = [];
        let dashboardShareLinkIdsToCleanup = [];
        let dashboardReportsIdsToCleanup = [];
        let metricsTobeUpdated = [];
        if (dashboardsResponses.success.length > 0) {
            const selectDashboardMetricIdsByDashboardIds = makeSelectDashboardMetricIdsByDashboardIds();
            const selectDashboardReportIdsByDashboardIds = makeSelectDashboardReportIdsByDashboardIds();
            const selectMetricIdsByDashboardMetricIds = makeSelectMetricIdsByDashboardMetricIds();
            const selectDashboardShareLinkByDashboardIds = makeSelectDashboardShareLinkIdsByDashboardIds();
            dashboardMetricsToCleanup = yield select(selectDashboardMetricIdsByDashboardIds, dashboardsResponses.success);
            metricsTobeUpdated = yield select(selectMetricIdsByDashboardMetricIds, dashboardMetricsToCleanup);
            dashboardShareLinkIdsToCleanup = yield select(selectDashboardShareLinkByDashboardIds, dashboardsResponses.success);
            dashboardReportsIdsToCleanup = yield select(selectDashboardReportIdsByDashboardIds, dashboardsResponses.success);
        }
        // select metricIds for usage update
        yield put(modalHideEditFolder(folderIds[0]));
        yield put(modalsHideDashboardAndFolderDelete());
        yield put(uncheckAllListValues('dashboards'));
        yield put(foldersAndDashboardsDeleteSuccess(foldersResponses.success, dashboardsResponses.success, fromFolderId));
        // Clean up dashboardMetrics now
        if (dashboardMetricsToCleanup.length > 0) {
            yield put(dashboardMetricsCleanupAfterDashboardDelete(dashboardMetricsToCleanup));
        }

        // update metric usage
        if (metricsTobeUpdated.length > 0) {
            yield put(metricUsageCountUpdateRequest(metricsTobeUpdated));
        }

        // cleanup dashboard share links
        if (dashboardShareLinkIdsToCleanup.length > 0) {
            yield put(dashboardShareLinksCleanup(dashboardShareLinkIdsToCleanup));
        }

        // cleanup dashboard reports
        if (dashboardReportsIdsToCleanup.length > 0) {
            yield put(dashboardReportsCleanup(dashboardReportsIdsToCleanup));
        }

        yield put(showFoldersAndDashboardDeleteSuccessOrWarning(foldersResponses, dashboardsResponses));
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(foldersAndDashboardsDeleteError(new SubmissionError({ _error: applicationOrServerError })));
    }
}

export default function* foldersSaga() {
    yield takeEvery(FOLDER_ADD_REQUEST, folderAddRequest);
    yield takeEvery(FOLDER_EDIT_REQUEST, folderEditRequest);
    yield takeEvery(FOLDERS_AND_DASHBOARDS_MOVE_TO_FOLDER_REQUEST, foldersAndDashboardsMoveToFolderRequest);
    yield takeEvery(FOLDERS_AND_DASHBOARDS_DELETE_REQUEST, foldersAndDashboardsDeleteRequest);
}
