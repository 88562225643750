export const METRIC_USAGE_COUNT_UPDATE_REQUEST = 'METRIC_USAGE_COUNT_UPDATE_REQUEST_BACKGROUND';
export const METRIC_USAGE_COUNT_UPDATE_SUCCESS = 'METRIC_USAGE_COUNT_UPDATE_SUCCESS_BACKGROUND';
export const METRIC_USAGE_COUNT_UPDATE_ERROR = 'METRIC_USAGE_COUNT_UPDATE_ERROR_BACKGROUND';

export const METRIC_GET_REQUEST = 'METRIC_GET_REQUEST';
export const METRIC_GET_SUCCESS = 'METRIC_GET_SUCCESS';
export const METRIC_GET_ERROR = 'METRIC_GET_ERROR';

export const metricUsageCountUpdateRequest = (metricIds) => ({
    type: METRIC_USAGE_COUNT_UPDATE_REQUEST,
    payload: { metricIds }
});

export const metricUsageCountUpdateSuccess = (metricUsageUpdates) => ({
    type: METRIC_USAGE_COUNT_UPDATE_SUCCESS,
    payload: { metricUsageUpdates }
});

export const metricUsageCountUpdateError = (error) => ({
    type: METRIC_USAGE_COUNT_UPDATE_ERROR,
    payload: error
});

export const metricGetRequest = (metricId) => ({
    type: METRIC_GET_REQUEST,
    payload: { metricId }
});

export const metricGetSuccess = (metricId, metric) => ({
    type: METRIC_GET_SUCCESS,
    payload: { metricId, metric }
});

export const metricGetError = (metricId, error) => ({
    type: METRIC_GET_ERROR,
    payload: { metricId, error }
});
