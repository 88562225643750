import React from 'react';
import { selectDataPushTaskLimitAndUsage } from 'src/selectors/client';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import IconButton from 'src/components/buttons/IconButton';
import withPopover from 'src/components/withPopover';
import DataPushTaskLimitPopover from 'src/components/popovers/DataPushTaskLimitPopover';
import styles from 'src/stylesheets/limit.scss';
import withTooltip from 'src/components/withToolTip';

const IconButtonWithPopover = withPopover(withTooltip(IconButton));

const DataPushTaskLimit = (props) => {
    const { dataPushTaskLimitAndUsage } = props;
    const { limit, usage } = dataPushTaskLimitAndUsage;
    return (
        <div className={styles.wrapper}>
            <div className={styles.text}>
                Data push task limit: {`${usage}/${limit}`}
            </div>
            <IconButtonWithPopover
              icon="info"
              className={styles.icon}
              overlay={<DataPushTaskLimitPopover />}
              tooltip="View details"
              tooltipPlacement="bottom"
            />
        </div>
    );
};

DataPushTaskLimit.propTypes = {
    dataPushTaskLimitAndUsage: customPropTypes.dataPushTaskLimitAndUsage.isRequired
};

const mapStateToProps = (state) => ({
    dataPushTaskLimitAndUsage: selectDataPushTaskLimitAndUsage(state)
});

export default connect(mapStateToProps)(DataPushTaskLimit);
