import { modalsShowEditDashboard } from 'src/actions/overlays';
import ContextAwareAdditionalFilterSelector from 'src/components/filterSelectors/ContextAwareAdditionalFilterSelector';
import PageHeadlineWithIcon from 'src/components/header/layout/PageHeadlineWithIcon';
import * as customPropType from 'src/customPropTypes';
import * as routeActions from 'react-router-redux';
import BatchMetricRequestContext from 'src/components/context/BatchMetricRequestContext';
import BottomRow from 'src/components/header/layout/BottomRow';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Column from 'src/components/header/layout/Column';
import { connect } from 'react-redux';
import DashboardExportButton from 'src/components/exportButtons/DashboardExportButton';
import DashboardRefreshButton from 'src/components/buttons/DashboardRefreshButton';
import ContextAwareDateSelector from 'src/components/filterSelectors/ContextAwareDateSelector';
import ContextAwareProfileSelector from 'src/components/filterSelectors/ContextAwareProfileSelector';
import Header from 'src/components/header/layout/Header';
import PropTypes from 'prop-types';
import React from 'react';
import { selectHasStickyHeader } from 'src/selectors/stickyHeader';
import SelectorButtonGroup from 'src/components/buttons/SelectorButtonGroup';
import styles from 'src/stylesheets/headers/dashboardHeader.scss';
import TopRow from 'src/components/header/layout/TopRow';
import withReactRouterLinkHandling from 'src/components/withReactRouterLinkHandling';
import BackLink from 'src/components/BackLink';
import { getFolderIconByOwnersipType, getReadableOwnershipAndEntityType } from 'src/utils/ownership';
import { getPathForFolderParentByType } from 'src/utils/routes';
import withPopover from 'src/components/withPopover';
import DashboardShareOptionsPopover from 'src/components/popovers/DashboardShareOptionsPopover';

const ButtonWithReactRouterLinkHandling = withReactRouterLinkHandling(Button);
const ButtonWithPopover = withPopover(Button);

const placeholderIfFixedStyle = {
    height: 176
};

const flexShrink = { flexShrink: '3' };

const DashboardHeader = (props) => {
    const {
        dashboard,
        linkToDiscover,
        modalsShowEditDashboardAction,
        sticky,
        type,
        routePushAction
    } = props;

    const handleOnBackButtonClick = () => {
        const path = getPathForFolderParentByType(dashboard.folderId, type);
        routePushAction(path);
    };

    const iconToolTip = `This is a ${getReadableOwnershipAndEntityType(type, 'dashboard')}`;
    const icon = getFolderIconByOwnersipType(type);

    if (sticky) {
        return (
            <div>
                <div style={placeholderIfFixedStyle} />
                <Header fixed>
                    <TopRow>
                        <Column flexGrow style={flexShrink}>
                            <PageHeadlineWithIcon icon={icon} iconToolTip={iconToolTip} small>
                                {dashboard.name}
                            </PageHeadlineWithIcon>
                        </Column>
                        <Column flexShrink>
                            <SelectorButtonGroup
                              small
                              rightAlign
                              dateSelector={<ContextAwareDateSelector small />}
                              profileSelector={<ContextAwareProfileSelector small />}
                              filterSelector={<ContextAwareAdditionalFilterSelector small />}
                            />
                        </Column>
                    </TopRow>
                </Header>
            </div>
        );
    }

    return (
        <BatchMetricRequestContext.Consumer>
            {
                (batchServerRequest) => (
                    <Header>
                        <TopRow>
                            <Column flexGrow className={styles.header}>
                                <div className={styles.backButton}>
                                    <BackLink icon="back" onClick={handleOnBackButtonClick} tooltip="Back to parent folder" />
                                </div>
                                <div className={styles.headline}>
                                    <PageHeadlineWithIcon
                                      iconToolTip={iconToolTip}
                                      icon={icon}
                                    >
                                        {dashboard.name}
                                    </PageHeadlineWithIcon>
                                </div>
                            </Column>
                            <Column>
                                <ButtonGroup>
                                    <ButtonWithReactRouterLinkHandling to={linkToDiscover} action label="Add metrics" icon="plus" />
                                    <Button label="Edit" onClick={() => modalsShowEditDashboardAction(dashboard.id, true)} />
                                    <ButtonWithPopover
                                      label="Share"
                                      placement="bottom-end"
                                      overlay={<DashboardShareOptionsPopover dashboardId={dashboard.id} />}
                                      multiAction
                                    />
                                    <DashboardExportButton
                                      fileName={dashboard.name}
                                      disabled={dashboard.dashboardMetricIds.length < 1}
                                      dashboardId={dashboard.id}
                                      batchServerRequest={batchServerRequest}
                                    />
                                    <DashboardRefreshButton batchServerRequest={batchServerRequest} />
                                </ButtonGroup>
                            </Column>
                        </TopRow>
                        <BottomRow>
                            <Column className={styles.selector} flexGrow>
                                <SelectorButtonGroup
                                  dateSelector={<ContextAwareDateSelector />}
                                  profileSelector={<ContextAwareProfileSelector />}
                                  filterSelector={<ContextAwareAdditionalFilterSelector />}
                                />
                            </Column>
                        </BottomRow>
                    </Header>
                )
            }
        </BatchMetricRequestContext.Consumer>
    );
};

DashboardHeader.propTypes = {
    dashboard: customPropType.dashboard.isRequired,
    linkToDiscover: customPropType.linkToDiscover.isRequired,
    modalsShowEditDashboardAction: PropTypes.func.isRequired,
    sticky: PropTypes.bool,
    stickyHeaderIdentifier: PropTypes.string.isRequired,
    routePushAction: PropTypes.func.isRequired,
    type: customPropType.ownershipType.isRequired
};

DashboardHeader.defaultProps = {
    sticky: false
};

const mapStateToProps = (state, ownProps) => ({
    sticky: selectHasStickyHeader(state, ownProps.stickyHeaderIdentifier),
});

export default connect(mapStateToProps, {
    modalsShowEditDashboardAction: modalsShowEditDashboard,
    routePushAction: routeActions.push
})(DashboardHeader);
