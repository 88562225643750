import DiscoverStart from 'src/components/views/discover/base/DiscoverStart';
import React from 'react';

const recommendationIdentifiers = [
    {
        type: 'recommendation',
        identifier: 'mostUsedDashboards',
        title: 'Popular dashboards'
    },
    {
        type: 'search',
        identifier: 'contentOptimizationDashboards',
        title: 'Dashboards focusing on content optimization',
        searchParams: {
            filterBy: 'dashboards',
            orderBy: 'mostUsed',
            tagIds: ['11'],
            limit: 6
        }
    },
    {
        type: 'search',
        identifier: 'privateStatisticsDashboards',
        title: 'Dive deep with private statistics',
        searchParams: {
            filterBy: 'dashboards',
            orderBy: 'mostUsed',
            tagIds: ['14'],
            limit: 6
        }
    }
];

const DiscoverStartDashboards = (props) => <DiscoverStart {...props} recommendationIdentifiers={recommendationIdentifiers} filterBy="dashboards" />;

export default DiscoverStartDashboards;
