import {
    createShallowEqualSelector, makeOrderBySorter
} from 'src/selectors/utils';
import { getCustomMetricsIdsFromStore, selectCustomMetrics } from 'src/selectors/metrics';
import { initialState, initialStateAutocomplete } from 'src/reducers/analysis';
import _difference from 'lodash/difference';
import _get from 'lodash/get';
import _has from 'lodash/has';
import { createSelector } from 'reselect';
import { makeSelectMetricSearchResultMetric } from 'src/selectors/metricSearch';
import { makeSelectQueryFromFilterSelectorByContext } from 'src/selectors/filterSelectors';
import { tagIdPrivateStatistics } from 'src/selectors/tags';
import { analysisRoute } from 'src/routePaths';
import { QUICK_ANALYSIS } from 'src/utils/filterSelectors';

export const selectAnalysisFilter = (state) => state.analysis.filter;

const getAnalysisAsyncStatesFromStore = (state) => state.analysis.asyncStatesByAction;
const getFavoriteAllIdsFromStore = (state) => state.analysis.favorites.allIds;
const getLastVisitedMetricIdFromStore = (state) => state.analysis.lastVisitedMetricId;
const basePath = '/analysis';

export const getAnalysisFilterResults = (state) => state.analysis.results;
export const getAnalysisAutocompleteResults = (state) => state.analysis.autocomplete;

export const makeSelectAnalysisMetricLink = () => {
    const selectQueryFromFilterSelectorByContext = makeSelectQueryFromFilterSelectorByContext();
    return createSelector(
        [
            selectQueryFromFilterSelectorByContext,
            (_, __, metricId) => metricId,
        ],
        (queryParameters, metricId) => ({
            pathname: `${basePath}/${metricId}`,
            query: queryParameters
        })
    );
};

export const makeSelectAnalysisQuickAccessLinksByFilter = () => {
    const orderBySorter = makeOrderBySorter();
    return createSelector(
        [
            getAnalysisFilterResults,
            (state, analysisFilter) => analysisFilter,
            (state) => {
                const customMetrics = selectCustomMetrics(state);
                return orderBySorter(customMetrics);
            }

        ],
        (analysisFilterResults, analysisFilter, customMetrics) => {
            if (analysisFilter === 'customMetrics') {
                const customMetricIds = customMetrics.map((customMetric) => customMetric.id);
                return {
                    loading: false,
                    error: null,
                    data: customMetricIds
                };
            }
            return _get(analysisFilterResults, analysisFilter, initialState);
        }
    );
};

export const makeSelectAnalysisAutoCompleteMetricsForSearchFromDiscover = () => createSelector(
    [
        getAnalysisAutocompleteResults,
        (_, searchTerm) => searchTerm,
    ],
    (analysisAutocompleteResults, searchTerm) => _get(analysisAutocompleteResults, searchTerm, Object.assign({}, initialStateAutocomplete, {
        requested: false
    }))
);

export const makeSelectAnalysisFavoritePublicMetricIdsByFilter = () => {
    const selectorCache = {};
    const getSelector = (id) => {
        const selector = _get(selectorCache, id);
        if (selector) { return selector; }
        Object.assign(selectorCache, { [id]: makeSelectMetricSearchResultMetric() });
        return selectorCache[id];
    };
    return createShallowEqualSelector(
        [
            (state, filter) => {
                let excludePrivateStatistics = false;
                const filterTagIds = filter.split(',');
                if (filterTagIds.indexOf(tagIdPrivateStatistics) === -1) {
                    excludePrivateStatistics = true;
                }
                const allFavoriteIds = getFavoriteAllIdsFromStore(state);
                const entities = {};
                allFavoriteIds.forEach((metricId) => {
                    const metricSearchResultMetric = getSelector(metricId)(state, metricId);
                    if (metricSearchResultMetric) {
                        const { tagIds } = metricSearchResultMetric;
                        if (_difference(filterTagIds, tagIds).length === 0) {
                            if (!excludePrivateStatistics) {
                                Object.assign(entities, { [metricId]: metricSearchResultMetric });
                            } else if (excludePrivateStatistics && tagIds.indexOf(tagIdPrivateStatistics) === -1) {
                                Object.assign(entities, { [metricId]: metricSearchResultMetric });
                            }
                        }
                    }
                });
                return entities;
            },
            getFavoriteAllIdsFromStore
        ],
        (matchedPublicMetrics, favoriteIds) => {
            const matchedIds = [];
            favoriteIds.forEach((favoriteId) => {
                if (_has(matchedPublicMetrics, favoriteId)) {
                    matchedIds.push(favoriteId);
                }
            });
            return matchedIds;
        }
    );
};

export const makeSelectAnalysisFavoriteCustomMetricIds = () => createShallowEqualSelector(
    [
        getCustomMetricsIdsFromStore,
        getFavoriteAllIdsFromStore
    ],
    (customMetricIds, favoriteIds) => favoriteIds.filter((favoriteId) => customMetricIds.indexOf(favoriteId) !== -1)
);

export const makeSelectAnalysisFavoriteMetricIdsByFilter = () => {
    const selectAnalysisFavoritePublicMetricIdsByFilter = makeSelectAnalysisFavoritePublicMetricIdsByFilter();
    const selectAnalysisFavoriteCustomMetricIds = makeSelectAnalysisFavoriteCustomMetricIds();
    return (state, filter) => {
        if (filter === 'customMetrics') {
            return selectAnalysisFavoriteCustomMetricIds(state);
        }
        return selectAnalysisFavoritePublicMetricIdsByFilter(state, filter);
    };
};

export const makeSelectIsMetricFavToggling = () => createSelector(
    [
        (state) => getAnalysisAsyncStatesFromStore(state).toggle,
        (_, id) => id
    ],
    (asyncStates, id) => _get(asyncStates, [id, 'isPending'], false)
);

export const makeSelectIsMetricFavorite = () => createSelector(
    [
        getFavoriteAllIdsFromStore,
        (_, metricId) => metricId
    ],
    (favoriteAllIds, metricId) => favoriteAllIds.indexOf(metricId) !== -1
);

export const makeSelectLinkToLastAnalysis = () => {
    const selectAnalysisMetricLink = makeSelectAnalysisMetricLink();
    return (state) => {
        const lastVisitedMetricId = getLastVisitedMetricIdFromStore(state);
        if (lastVisitedMetricId === null) {
            return analysisRoute;
        }
        return selectAnalysisMetricLink(state, QUICK_ANALYSIS, lastVisitedMetricId);
    };
};
