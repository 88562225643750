import * as customPropTypes from 'src/customPropTypes';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import React from 'react';
import UserIcon from 'src/components/UserIcon';
import styles from 'src/stylesheets/views/settings/userIconWithGravatarInfo.scss';

const UserIconWithGravatarInfo = (props) => {
    const {
        user,
        onClick,
        onMouseOut,
        onMouseOver,
        onFocus,
        onBlur
    } = props;
    const { gravatar100px } = user;
    return (
        <a
          href="https://gravatar.com/"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
          onClick={onClick}
          onMouseOut={onMouseOut}
          onMouseOver={onMouseOver}
          onFocus={onFocus}
          onBlur={onBlur}
        >
            <div className={styles.wrapper}>
                <UserIcon
                  iconUrl={gravatar100px}
                  size={100}
                />
                <div className={styles.iconWrapper}>
                    <IcomoonIcon
                      icon="edit"
                      className={styles.icon}
                    />
                </div>
            </div>
        </a>
    );
};

UserIconWithGravatarInfo.propTypes = {
    user: customPropTypes.user.isRequired,
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func
};

export default UserIconWithGravatarInfo;
