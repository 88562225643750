import * as customPropTypes from 'src/customPropTypes';
import Button from 'src/components/buttons/Button';
import Column from 'src/components/header/layout/Column';
import { connect } from 'react-redux';
import DiscoverBackButton from 'src/components/views/discover/DiscoverBackButton';
import Header from 'src/components/header/layout/Header';
import MetricNameHeaderWithIcons from 'src/components/MetricNameHeaderWithIcons';
import { modalsShowSaveDashboard } from 'src/actions/overlays';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/headers/discoverDashboardAndMetricHeader.scss';
import TopRow from 'src/components/header/layout/TopRow';

const DashboardDetailHeader = (props) => {
    const {
        searchResultDashboardTemplate, modalsShowSaveDashboardAction
    } = props;

    const { id, name, platformType } = searchResultDashboardTemplate;

    const { isBasedOnPrivateStatistics } = props;
    return (
        <Header>
            <TopRow>
                <Column flexGrow>
                    <div className={styles.headline}>
                        <div className={styles.backButton}>
                            <DiscoverBackButton />
                        </div>
                        <MetricNameHeaderWithIcons
                          name={name}
                          platformType={platformType}
                          isBasedOnPrivateStatistics={isBasedOnPrivateStatistics}
                          size="large"
                        />
                    </div>
                </Column>
                <Column>
                    <Button
                      action
                      label="Save dashboard"
                      icon="dashboard"
                      onClick={() => { modalsShowSaveDashboardAction(id); }}
                    />
                </Column>
            </TopRow>
        </Header>
    );
};

DashboardDetailHeader.propTypes = {
    searchResultDashboardTemplate: customPropTypes.searchResultDashboardTemplate.isRequired,
    modalsShowSaveDashboardAction: PropTypes.func.isRequired,
    isBasedOnPrivateStatistics: PropTypes.bool.isRequired
};

export default connect(null, {
    modalsShowSaveDashboardAction: modalsShowSaveDashboard,
})(DashboardDetailHeader);
