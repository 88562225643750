import React from 'react';
import PropTypes from 'prop-types';
import DataPushTaskLoader from 'src/components/views/integrations/DataPushTaskLoader';
import DataPushTaskDetailModalLoading from 'src/components/loadingIndicators/DataPushTaskDetailModalLoading';
import DataPushTaskLogDetail from 'src/components/views/integrations/DataPushTaskLogDetail';

const DataPushTaskLogDetailWithDataPushTask = (props) => {
    const { params } = props;
    const { dataPushTaskId, logId } = params;
    return (
        <DataPushTaskLoader loadingScreen={<DataPushTaskDetailModalLoading />} dataPushTaskId={dataPushTaskId}>
            {
                (dataPushTask) => <DataPushTaskLogDetail dataPushTask={dataPushTask} logId={logId} />
            }
        </DataPushTaskLoader>
    );
};

DataPushTaskLogDetailWithDataPushTask.propTypes = {
    params: PropTypes.shape({
        dataPushTaskId: PropTypes.string.isRequired,
        logId: PropTypes.string.isRequired
    }).isRequired
};

export default DataPushTaskLogDetailWithDataPushTask;
