import { FOLDERS_AND_DASHBOARDS_DELETE_SUCCESS } from 'src/actions/folders';
import { hasMetricRoutes } from 'src/utils/discoverUtiles';
import { LOCATION_CHANGE } from 'react-router-redux';
import { combineReducers } from 'redux';
import _without from 'lodash/without';
import _difference from 'lodash/difference';

const lastVisitedState = {
    dashboardId: false,
    discoverRouteState: {
        lastRoute: 'dashboards',
        metricLastRoute: 'metrics'
    },
    dashboards: []
};

const dashboards = (state = lastVisitedState.dashboards, action) => {
    const { type, payload } = action;
    switch (type) {
        case LOCATION_CHANGE: {
            const urlParts = payload.pathname.substring(1).split('/');
            // save dashboardId if changed
            if (urlParts[0] === 'dashboard' && /^\d+$/.test(urlParts[1])) {
                const newDashboardId = `${urlParts[1]}`;
                let newState = _without(state, newDashboardId);
                newState.unshift(newDashboardId);
                newState = newState.slice(0, 3);
                return newState;
            }
            return state;
        }
        case FOLDERS_AND_DASHBOARDS_DELETE_SUCCESS: {
            const { dashboardIds } = payload;
            return _difference(state, dashboardIds);
        }
        default:
            return state;
    }
};

const discoverRouteState = (state = lastVisitedState.discoverRouteState, action) => {
    const { type, payload } = action;
    switch (type) {
        case LOCATION_CHANGE: {
            const urlParts = payload.pathname.substring(1).split('/');
            // save dashboardId if changed
            if (urlParts[0] === 'discover') {
                if (urlParts[1] && (urlParts[1] === 'dashboards' || urlParts[1] === 'dashboard')) {
                    if (state.lastRoute !== 'dashboards') {
                        return Object.assign({}, state, { lastRoute: 'dashboards' });
                    }
                } else if (urlParts[1] && (hasMetricRoutes(urlParts[1]))) {
                    if (state.lastRoute !== urlParts[1]) {
                        return Object.assign({}, state, { lastRoute: urlParts[1], metricLastRoute: urlParts[1] });
                    }
                }
            }
            return state;
        }
        default:
            return state;
    }
};

export const lastVisited = combineReducers({
    discoverRouteState,
    dashboards
});
