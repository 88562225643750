import * as metricSearchActionCreators from 'src/actions/metricSearch';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { metricFilters } from 'src/utils/discoverUtiles';
import PropTypes from 'prop-types';
import Radio from 'src/components/forms/inputs/Radio';
import React from 'react';
import styles from 'src/stylesheets/views/discover/discoverFilter.scss';

const metricFilterByText = {
    metrics: 'All metrics',
    publicMetrics: 'Only default metrics',
    customMetrics: 'Only custom metrics'
};

const MetricTypeFilters = (props) => {
    const { filterType, metricSearchFilterByUpdateAction } = props;
    const handleOnChange = (filterBy) => {
        metricSearchFilterByUpdateAction(filterBy);
    };

    return (
        <div className={styles.metricFilters}>
            {
                metricFilters.map((metricFilterType, i) => (
                    <div
                      key={`${metricFilterType}`}
                      className={classnames({ [styles.metricType]: i < metricFilters.length - 1 })}
                    >
                        <Radio
                          name={metricFilterType}
                          text={metricFilterByText[metricFilterType]}
                          checked={filterType === metricFilterType}
                          textClassName={styles.radioText}
                          onChange={() => handleOnChange(metricFilterType)}
                        />
                    </div>
                ))
            }
        </div>
    );
};

MetricTypeFilters.propTypes = {
    filterType: PropTypes.string.isRequired,
    metricSearchFilterByUpdateAction: PropTypes.func.isRequired
};

export default connect(null, {
    metricSearchFilterByUpdateAction: metricSearchActionCreators.metricSearchFilterByUpdate
})(MetricTypeFilters);
