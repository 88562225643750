import AdditionalFilterSelector from 'src/components/additionalFilter/AdditionalFilterSelector';
import React from 'react';
import { connect } from 'react-redux';
import * as customPropTypes from 'src/customPropTypes';
import { withRouter } from 'react-router';
import * as routeActions from 'react-router-redux';
import { makeAdditionalFilterSelectorsByContext } from 'src/selectors/filterSelectors';
import PropTypes from 'prop-types';
import { additionalFilterToParams, getMappingAdditionalFilterToUrlParams } from 'src/utils/filterSelectors';
import _omit from 'lodash/omit';
import _values from 'lodash/values';
import withFilterContextConsumer from 'src/components/withFilterContextConsumer';

const ContextAwareAdditionalFilterSelector = ({
    postText, routePushAction, location, postTag, small, disabled, postTextExclude, adCampaign
}) => {
    const onApplyClick = (additionalFilters) => {
        const mappings = getMappingAdditionalFilterToUrlParams();
        const omitKeys = [..._values(mappings.postTag), ..._values(mappings.postText), ..._values(mappings.postTextExclude), ..._values(mappings.adCampaign)];

        // clean up the query to freshly set it again
        const cleanedQuery = _omit(location.query, omitKeys);
        const additionalFilterParams = additionalFilterToParams(additionalFilters);
        const finalQuery = Object.assign({}, cleanedQuery, additionalFilterParams);
        routePushAction({
            pathname: location.pathname,
            query: finalQuery
        });
    };

    return (
        <AdditionalFilterSelector
          adCampaign={adCampaign}
          postTextExclude={postTextExclude}
          postTag={postTag}
          postText={postText}
          onApplyClick={onApplyClick}
          small={small}
          disabled={disabled}
        />
    );
};

ContextAwareAdditionalFilterSelector.propTypes = {
    postText: customPropTypes.postTextFilter.isRequired,
    postTextExclude: customPropTypes.postTextExcludeFilter.isRequired,
    adCampaign: customPropTypes.adCampaignFilter.isRequired,
    postTag: customPropTypes.postTagFilter.isRequired,
    routePushAction: PropTypes.func.isRequired,
    location: customPropTypes.location.isRequired,
    small: PropTypes.bool,
    disabled: PropTypes.bool,
    filterContext: PropTypes.string.isRequired
};

ContextAwareAdditionalFilterSelector.defaultProps = {
    small: false,
    disabled: false
};

const makeMapStateToProps = () => {
    const {
        selectPostTagByContext, selectPostTextByContext, selectPostTextExcludeByContext, selectAdCampaignByContext
    } = makeAdditionalFilterSelectorsByContext();

    return (state, ownProps) => ({
        postText: selectPostTextByContext(state, ownProps.filterContext),
        postTextExclude: selectPostTextExcludeByContext(state, ownProps.filterContext),
        postTag: selectPostTagByContext(state, ownProps.filterContext),
        adCampaign: selectAdCampaignByContext(state, ownProps.filterContext)
    });
};

export default withFilterContextConsumer(connect(makeMapStateToProps, { routePushAction: routeActions.push })(withRouter(ContextAwareAdditionalFilterSelector)));
