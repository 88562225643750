import React from 'react';
import ListTableCell from 'src/components/listTable/ListTableCell';
import ListTableRow from 'src/components/listTable/ListTableRow';
import PropTypes from 'prop-types';
import * as customPropTypes from 'src/customPropTypes';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import Button from 'src/components/buttons/Button';
import styles from 'src/stylesheets/views/settings/spaceListTable.scss';
import { modalShowDeleteSpace, modalShowSpaceEdit } from 'src/actions/overlays';
import { connect } from 'react-redux';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import AppLogo from 'src/components/AppLogo';
import IcomoonIcon from 'src/components/IcomoonIcon';

const SpaceListTableRow = (props) => {
    const { space, modalShowSpaceEditAction, modalShowDeleteSpaceAction } = props;
    const {
        id, name, users, customLogo, profiles, pageLimit, adAccounts, adAccountLimit
    } = space;
    return (
        <ListTableRow key={id}>
            <ListTableCell>
                <DivWithTooltip tooltip={name}>
                    {name}
                </DivWithTooltip>
            </ListTableCell>
            <ListTableCell>
                <DivWithTooltip tooltip={id}>
                    {id}
                </DivWithTooltip>
            </ListTableCell>
            <ListTableCell onClick={() => { modalShowSpaceEditAction(id); }}>
                {
                    customLogo !== ''
                    && <AppLogo logoUrl={customLogo} />
                }
                {
                    customLogo === ''
                    && (
                        <div className={styles.addCustomLogo}>
                            <IcomoonIcon icon="plus" className={styles.icon} />
                            <span>Add logo</span>
                        </div>
                    )
                }
            </ListTableCell>
            <ListTableCell>
                {users.length}
            </ListTableCell>
            <ListTableCell>
                <div className={styles.status}>
                    {`${profiles}/${pageLimit}`}
                </div>
            </ListTableCell>
            <ListTableCell>
                <div className={styles.statusWithActionCell}>
                    <div className={styles.status}>
                        {`${adAccounts}/${adAccountLimit}`}
                    </div>
                    <ButtonGroup>
                        <Button label="Edit" onClick={() => { modalShowSpaceEditAction(id); }} small className="list-hover-button-opacity" />
                        <Button label="Delete" small warning onClick={() => { modalShowDeleteSpaceAction(id, name); }} className="list-hover-button-opacity" />
                    </ButtonGroup>
                </div>
            </ListTableCell>
        </ListTableRow>
    );
};

SpaceListTableRow.propTypes = {
    space: customPropTypes.spaceForManagement.isRequired,
    modalShowSpaceEditAction: PropTypes.func.isRequired,
    modalShowDeleteSpaceAction: PropTypes.func.isRequired
};

export default connect(null, {
    modalShowSpaceEditAction: modalShowSpaceEdit,
    modalShowDeleteSpaceAction: modalShowDeleteSpace
})(SpaceListTableRow);
