import Popover from 'src/components/popovers/layout/Popover';
import PropTypes from 'prop-types';
import React from 'react';
import { getLabelAndIconByIsTimeSeries } from 'src/utils/dataSources';

const wrapperStyle = {
    width: 400,
    padding: 20,
    fontSize: '13px',
    lineHeight: '1.38'
};

const TimeSeriesItemLevelDataDescriptionPopover = ({ isTimeSeries }) => {
    const { label, description } = getLabelAndIconByIsTimeSeries(isTimeSeries);
    return (
        <Popover id="timeSeriesItemLevelDataDescriptionPopover">
            <div style={wrapperStyle}>
                <b>{label}</b><br /><br />
                <div>
                    {description}
                </div>
                {
                    isTimeSeries
                    && (
                        <div>
                            Time-series data represents the development of a set of
                            metrics over time. More specifically, time-series data sources return one row per profile
                            per time interval. Each of our data sources supports one or more of the following
                            intervals: daily, weekly, monthly or total. E.g. a request with time period Jan 1st to
                            Jan 10th, daily interval and two profiles selected will return 20 rows. The total
                            interval by definition returns one row per profile holding the numbers for the single
                            total interval.
                            <br /><br />
                            Please note that for weekly and monthly intervals the start and end time of the selected
                            time period will be expanded to cover full intervals. E.g. for monthly interval a time
                            period from 3rd of March to 10th of April will be expanded to March 1st to April 30th to
                            cover full months.
                        </div>
                    )
                }
                {
                    !isTimeSeries
                    && (
                        <div>
                            Item level data holds single items like posts, videos or mentions. Data sources of this kind
                            return one row per item. The columns of this data source represent attributes and
                            metrics belonging to these items. For most item-level data sources the metrics belonging to
                            the item reflect the lifetime performance (since the creation of this item).
                            In some cases though metrics can also be specific for the selected time period.
                            Please check the description of the concrete column to be used for more details.
                        </div>
                    )
                }
            </div>
        </Popover>
    );
};

TimeSeriesItemLevelDataDescriptionPopover.propTypes = {
    isTimeSeries: PropTypes.bool.isRequired
};

export default TimeSeriesItemLevelDataDescriptionPopover;
