import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/autocompleteMenuHeader.scss';

const AutocompleteMenuHeader = ({ label }) => <div className={styles.header}>{label}</div>;

AutocompleteMenuHeader.propTypes = {
    label: PropTypes.string.isRequired
};

export default AutocompleteMenuHeader;
