import {
    makeSelectGroupsIdsByAdAccountIdsWithIndeterminatedGroups,
} from 'src/selectors/profiles';
import {
    cleanupProfileAddToOrRemoveFromGroup,
    profileAddToOrRemoveFromGroupError
} from 'src/actions/profiles';
import { SubmissionError } from 'redux-form';
import createServerRequest from 'src/requestHandling/createServerRequest';
import {
    gatherBatchedResults,
    getAddedAndRemovedIdsFromSelectedOptions,
    handleAuthorizedServerRequest
} from 'src/sagas/utils';
import { makeSelectAdAccountsByIds } from 'src/selectors/adAccounts';
import { showNotification, showProfileBatchAddingSuccessOrWarning } from 'src/actions/notifications';
import {
    modalsHideAdAccountDelete,
    modalsHideAdAccountDetails,
    modalsHideForceDeleteAdAccountModal,
    modalsShowForceDeleteAdAccountModal,
    modalHideAdAccountAdd,
    modalAdAccountAddResult, modalsHideGroupSelectorAdAccount
} from 'src/actions/overlays';
import {
    convertArrayElementsToString,
    getForceOperationalEntityIds,
    getForceOperationalEntityInfos, parseAdAccount,
    parseAdAccounts
} from 'src/parsers';
import {
    accountEntitiesGetRequest,
    collectBatchAccountDeleteResponses,
    createBatchAdAccountDeleteRequests,
    performSequentialBulkAccountAddRequests
} from 'src/sagas/accounts';
import {
    adAccountDeleteRequest,
    adAccountForceDeleteError,
    adAccountForceDeleteRequest,
    adAccountForceDeleteSuccess,
    adAccountDeleteSuccess,
    addAccountDeleteError,
    adAccountBulkAddRequest,
    adAccountBulkAddSuccess,
    adAccountBulkAddError,
    adAccountAddRequest,
    adAccountAddSuccess,
    adAccountAddError,
    adAccountAddCleanup,
    externalNetworkAdAccountAuthenticationLinkGetRequest,
    externalNetworkAdAccountAuthenticationLinkGetError,
    externalNetworkAdAccountAuthenticationLinkGetSuccess,
    adAccountAddToOrRemoveFromGroupRequest,
    adAccountAddToOrRemoveFromGroupSuccess,
    adAccountCustomizeRequest,
    adAccountCustomizeSuccess,
    adAccountCustomizeError
} from 'src/actions/adAccounts';
import {
    all, call, put, select, takeEvery
} from 'redux-saga/effects';
import { reportError } from 'src/utils/reportError';
import copy from 'copy-to-clipboard';
import { makeSelectSearchedAdAccountByIds } from 'src/selectors/adAccountSearch';
import _parseInt from 'lodash/parseInt';

const selectAdAccountsByIds = makeSelectAdAccountsByIds();
const selectSearchedAdAccountByIds = makeSelectSearchedAdAccountByIds();
const selectGroupsIdsByAdAccountIdsWithIndeterminatedGroups = makeSelectGroupsIdsByAdAccountIdsWithIndeterminatedGroups();

function* getForceOperationalAdAccountInfos(forceOperationalProfilesInfo) {
    const forceOperationalProfileIds = getForceOperationalEntityIds(forceOperationalProfilesInfo);
    const forceOperationalProfiles = yield select(selectAdAccountsByIds, forceOperationalProfileIds);
    const forceOperationalProfileInfos = getForceOperationalEntityInfos(forceOperationalProfiles, forceOperationalProfilesInfo);
    return forceOperationalProfileInfos;
}

function* adAccountDeleteRequestAction(action) {
    const { payload } = action;
    const { adAccountIds } = payload;
    // const selectAuthTransactionRequirementIdsByProfileIds = makeSelectAuthTransactionRequirementIdsByProfileIds();
    try {
        const profilesDeleteRequests = createBatchAdAccountDeleteRequests(adAccountIds);
        const responses = yield all(profilesDeleteRequests);
        const { allRemovedProfileIds, allNotRemovedProfileIds, allForceRemovableProfilesInfo } = collectBatchAccountDeleteResponses(responses);
        const profileCount = allRemovedProfileIds.length;
        yield put(modalsHideAdAccountDelete());
        yield put(modalsHideAdAccountDetails(adAccountIds[0]));
        yield put(adAccountDeleteSuccess(allRemovedProfileIds.map((id) => id.toString())));
        if (profileCount > 0) {
            // cleanup authTransaction and authTransactionRequirements
            // const authRequirementsIdsByAuthTransactions = yield select(selectAuthTransactionRequirementIdsByProfileIds, allRemovedProfileIds);
            // yield put(cleanUpAuthTransactionRequirements(authRequirementsIdsByAuthTransactions));

            const profileString = profileCount === 1 ? 'ad account was' : 'ad accounts were';
            yield put(showNotification(`${profileCount} ${profileString} successfully deleted from your account.`));
        }
        if (allForceRemovableProfilesInfo.length > 0 || allNotRemovedProfileIds.length > 0) {
            // forceable

            // handle this in the connected modal
            const forceOperationalAdAccountInfos = yield getForceOperationalAdAccountInfos(allForceRemovableProfilesInfo);
            yield put(modalsShowForceDeleteAdAccountModal(forceOperationalAdAccountInfos, convertArrayElementsToString(allNotRemovedProfileIds)));
        }
        yield call(accountEntitiesGetRequest);
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(modalsHideAdAccountDelete());
        yield put(addAccountDeleteError(new SubmissionError({ _error: applicationOrServerError })));
        yield put(showNotification('We are sorry, deleting your ad account failed.', 'error'));
    }
}

function* adAccountForceDeleteRequestAction(action) {
    const { payload } = action;
    const { adAccountIds } = payload;
    // const selectAuthTransactionRequirementIdsByProfileIds = makeSelectAuthTransactionRequirementIdsByProfileIds();
    const requestParams = { accountIds: JSON.stringify(adAccountIds.map((id) => _parseInt(id))) };
    try {
        const serverRequest = createServerRequest(requestParams);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/force-delete-ad-account');
        if (response) {
            const { removedProfileIds } = response.jsonData;
            const parsedProfileIds = convertArrayElementsToString(removedProfileIds);
            yield put(modalsHideForceDeleteAdAccountModal());
            yield put(adAccountForceDeleteSuccess(parsedProfileIds));
            const profileCount = parsedProfileIds.length;
            if (profileCount > 0) {
                // cleanup authTransaction and authTransactionRequirements
                // const authRequirementsIdsByAuthTransactions = yield select(selectAuthTransactionRequirementIdsByProfileIds, parsedProfileIds);
                // yield put(cleanUpAuthTransactionRequirements(authRequirementsIdsByAuthTransactions));
                const profileString = profileCount === 1 ? 'ad account was' : 'ad accounts were';
                yield put(showNotification(`${profileCount} ${profileString} successfully deleted from your account.`));
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(modalsHideForceDeleteAdAccountModal());
        yield put(adAccountForceDeleteError(new SubmissionError({ _error: applicationOrServerError })));
        yield put(showNotification('We are sorry, deleting your ad account failed.', 'error'));
    }
}

function* cleanAdAccountAddState(id) {
    yield put(adAccountAddCleanup(id));
}

function* adAccountAddRequestAction(id) {
    yield put(adAccountAddRequest(id));
}

function* addAccountAddSuccessAction(id) {
    yield put(adAccountAddSuccess(id));
}

function* addAccountAddErrorAction(id) {
    yield put(adAccountAddError(id, 'Ad account could not be added.'));
}

function* cleanAdAccountAddStates(searchedAdAccounts) {
    const stateCleanupRequests = searchedAdAccounts.map((searchedAdAccount) => call(cleanAdAccountAddState, searchedAdAccount.id));
    yield all(stateCleanupRequests);
}

function* adAccountBulkAddRequestAction(action) {
    const { payload } = action;
    try {
        const { checkedAdAccountIds } = payload;
        const searchedAdAccounts = yield select(selectSearchedAdAccountByIds, checkedAdAccountIds);
        const { allAddedAccounts, failedSearchedAccountLinks } = yield call(performSequentialBulkAccountAddRequests, searchedAdAccounts, '/client-index/add-ad-accounts', adAccountAddRequestAction, addAccountAddSuccessAction, addAccountAddErrorAction);
        const parsedAllAddedAdAccounts = parseAdAccounts(allAddedAccounts);
        yield call(accountEntitiesGetRequest);
        yield put(adAccountBulkAddSuccess(parsedAllAddedAdAccounts));
        yield put(modalHideAdAccountAdd());
        yield call(cleanAdAccountAddStates, searchedAdAccounts);
        yield put(modalAdAccountAddResult(Object.keys(parsedAllAddedAdAccounts), failedSearchedAccountLinks));
    } catch (applicationError) {
        yield put(adAccountBulkAddError(applicationError));
    }
}

function* getAddedAndRemovedGroupIds(adAccountIds, selectedGroupOptions) {
    const { selectedGroupIds, indeterminateGroupIds } = yield select(selectGroupsIdsByAdAccountIdsWithIndeterminatedGroups, adAccountIds);
    const { addedIds, removedIds } = getAddedAndRemovedIdsFromSelectedOptions(selectedGroupIds, indeterminateGroupIds, selectedGroupOptions);
    return {
        addToGroups: addedIds,
        removeFromGroups: removedIds
    };
}

function* adAccountAddToOrRemoveFromGroupServerRequest(adAccountId, addToGroups, removeFromGroups) {
    const serverRequest = createServerRequest({
        adAccountId,
        addToGroups: JSON.stringify(addToGroups),
        removeFromGroups: JSON.stringify(removeFromGroups)
    });
    const {
        response,
        serverError
    } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/add-remove-ad-accounts-to-group');
    return { response, serverError, adAccountId };
}

function* adAccountAddToOrRemoveFromGroupRequestAction(action) {
    const { payload } = action;
    const { selectedGroupOptions, adAccountIds } = payload;
    const { addToGroups, removeFromGroups } = yield call(getAddedAndRemovedGroupIds, adAccountIds, selectedGroupOptions);

    try {
        const requests = adAccountIds.map((adAccountId) => call(adAccountAddToOrRemoveFromGroupServerRequest, adAccountId, addToGroups, removeFromGroups));

        const collectedResponses = yield all(requests);
        const adAccountAddOrRemoveResults = gatherBatchedResults(collectedResponses);

        yield put(modalsHideGroupSelectorAdAccount());
        yield put(adAccountAddToOrRemoveFromGroupSuccess(adAccountAddOrRemoveResults.success));
        yield put(showProfileBatchAddingSuccessOrWarning(adAccountAddOrRemoveResults));
        yield put(cleanupProfileAddToOrRemoveFromGroup());
    } catch (applicationError) {
        reportError(applicationError);
        yield put(profileAddToOrRemoveFromGroupError(applicationError));
        yield put(showNotification('Unable to add or remove profiles from your group.', 'error'));
    }
}

function* externalNetworkAdAccountAuthenticationLinkGetRequestAction() {
    try {
        const serverRequest = createServerRequest();
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/get-ad-account-external-network-authentication-link');
        if (response) {
            const { link } = response.jsonData;
            yield call(copy, link);
            yield put(externalNetworkAdAccountAuthenticationLinkGetSuccess());
            yield put(showNotification('Link copied to clipboard.'));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification('Link could not be generated, please try again.', 'error'));
        yield put(externalNetworkAdAccountAuthenticationLinkGetError(applicationError.message));
    }
}

function* adAccountCustomizeRequestAction(action) {
    const { payload } = action;
    try {
        const {
            adAccountId, appendix, favorite, color
        } = payload;
        const serverRequest = createServerRequest({
            adAccountId,
            appendix,
            favorite,
            color
        });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/customize-ad-account');
        if (response) {
            const parsedAdAccount = parseAdAccount(response.jsonData);
            yield put(adAccountCustomizeSuccess(parsedAdAccount));
            yield put(showNotification('Ad account customized successfully.'));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(showNotification('Ad account could not be customized.', 'error'));
        yield put(adAccountCustomizeError(applicationError.message));
    }
}

export default function* adAccounts() {
    yield takeEvery(adAccountDeleteRequest.type, adAccountDeleteRequestAction);
    yield takeEvery(adAccountForceDeleteRequest.type, adAccountForceDeleteRequestAction);
    yield takeEvery(adAccountBulkAddRequest.type, adAccountBulkAddRequestAction);
    yield takeEvery(externalNetworkAdAccountAuthenticationLinkGetRequest.type, externalNetworkAdAccountAuthenticationLinkGetRequestAction);
    yield takeEvery(adAccountAddToOrRemoveFromGroupRequest.type, adAccountAddToOrRemoveFromGroupRequestAction);
    yield takeEvery(adAccountCustomizeRequest.type, adAccountCustomizeRequestAction);
}
