import * as customPropTypes from 'src/customPropTypes';
import * as overlayActions from 'src/actions/overlays';
import Button from 'src/components/buttons/Button';
import CheckedDashboardsAndFolders from 'src/components/views/dashboards/CheckedDashboardsAndFolders';
import { connect } from 'react-redux';
import { makeSelectCheckedValues } from 'src/selectors/lists';
import { makeSelectFolderById } from 'src/selectors/folders';
import PropTypes from 'prop-types';
import React from 'react';
import { splitIntoDashboardsAndFolders } from 'src/utils/dashboardFolders';
import Sidebar from 'src/components/sidebar/Sidebar';
import styles from 'src/stylesheets/views/dashboards/dashboardsSidebar.scss';
import IntercomVerticalWhiteSpace from 'src/components/sidebar/IntercomVerticalWhiteSpace';

const DashboardsSidebar = (props) => {
    const {
        folderAndDashboardIds, listName, dashboardAndFolderMoveAction, dashboardAndFolderDeleteAction, moveFromFolder
    } = props;
    const { folderIds, dashboardIds } = splitIntoDashboardsAndFolders(folderAndDashboardIds);

    const handleOnMoveClick = () => {
        dashboardAndFolderMoveAction(folderIds, dashboardIds, moveFromFolder.id, moveFromFolder.name);
    };

    const handleOnDeleteClick = () => {
        dashboardAndFolderDeleteAction(folderIds, dashboardIds, moveFromFolder.id, false);
    };
    const dashboardAndProfileSelected = folderAndDashboardIds.length > 0;

    return (
        <Sidebar>
            <div className={styles.wrapper}>
                <div className={styles.controls}>
                    <Button
                      small
                      stretch
                      disabled={!dashboardAndProfileSelected}
                      className={styles.button}
                      onClick={handleOnMoveClick}
                      label="Move"
                    />
                    <Button
                      small
                      stretch
                      disabled={!dashboardAndProfileSelected}
                      onClick={handleOnDeleteClick}
                      label="Delete"
                    />
                </div>
                <div className={styles.checkedItems}>
                    <CheckedDashboardsAndFolders listName={listName} folderIds={folderIds} dashboardIds={dashboardIds} />
                    <IntercomVerticalWhiteSpace />
                </div>
            </div>
        </Sidebar>
    );
};

const makeMapStateToProps = () => {
    const selectCheckedValues = makeSelectCheckedValues();
    const selectFolderById = makeSelectFolderById();
    return (state, ownProps) => {
        const folderAndDashboardIds = selectCheckedValues(state, ownProps.listName);
        const moveFromFolder = selectFolderById(state, ownProps.currentFolderId);
        return {
            folderAndDashboardIds,
            moveFromFolder
        };
    };
};

DashboardsSidebar.propTypes = {
    dashboardAndFolderMoveAction: PropTypes.func.isRequired,
    dashboardAndFolderDeleteAction: PropTypes.func.isRequired,
    folderAndDashboardIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    listName: PropTypes.string.isRequired,
    currentFolderId: PropTypes.string.isRequired,
    moveFromFolder: customPropTypes.folder.isRequired
};

export default connect(makeMapStateToProps, {
    dashboardAndFolderMoveAction: overlayActions.modalShowMoveToFolder,
    dashboardAndFolderDeleteAction: overlayActions.modalsShowDashboardAndFolderDelete
})(DashboardsSidebar);
