import React from 'react';
import styles from 'src/stylesheets/loadingIndicators/emptyMetricSkeleton.scss';
import EmptyDiv from 'src/components/loadingIndicators/EmptyDiv';

const EmptyMetricSekeleton = () => (
    <div className={styles.wrapper}>
        <div className={styles.headerRow}>
            <div className={styles.title}>
                <div className={styles.icon}>
                    <EmptyDiv height={24} width={24} />
                </div>
                <EmptyDiv height={24} width={100} />
            </div>
            <div className={styles.controls}>
                <EmptyDiv height={32} width={32} />
                <EmptyDiv height={32} width={32} classNames={styles.control} />
                <EmptyDiv height={32} width={32} classNames={styles.control} />
                <EmptyDiv height={32} width={32} classNames={styles.control} />
                <EmptyDiv height={32} width={32} classNames={styles.control} />
            </div>
        </div>
        <div className={styles.content}>
            <EmptyDiv height="100%" />
        </div>
    </div>
);

export default EmptyMetricSekeleton;
