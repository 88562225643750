import React from 'react';
import { connect } from 'react-redux';
import { makeSelectAdminSettingsState } from 'src/selectors/adminSettings';
import PropTypes from 'prop-types';
import { adminSettingsGetSettingsRequest } from 'src/actions/adminSettings';
import AsyncDataLoader from 'src/components/AsyncDataLoader';

const selectAdminSettingsState = makeSelectAdminSettingsState();

const AdminSettingsLoader = (props) => {
    const {
        alwaysReload,
        children,
        dataSelector,
        adminSettingsGetSettingsRequestAction,
        isListTable
    } = props;

    return (
        <AsyncDataLoader
          requestAction={adminSettingsGetSettingsRequestAction}
          dataSelector={dataSelector}
          asyncStateSelector={selectAdminSettingsState}
          alwaysReload={alwaysReload}
          isListTable={isListTable}
        >
            {children}
        </AsyncDataLoader>
    );
};

AdminSettingsLoader.propTypes = {
    adminSettingsGetSettingsRequestAction: PropTypes.func.isRequired,
    alwaysReload: PropTypes.bool,
    children: PropTypes.func.isRequired,
    dataSelector: PropTypes.func.isRequired,
    isListTable: PropTypes.bool
};

AdminSettingsLoader.defaultProps = {
    alwaysReload: true,
    isListTable: false
};

export default connect(null, {
    adminSettingsGetSettingsRequestAction: adminSettingsGetSettingsRequest
})(AdminSettingsLoader);
