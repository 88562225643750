import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import { makeSelectEditMetricLink, makeSelectLinkForDashboardMetrics } from 'src/selectors/filterSelectors';
import PropTypes from 'prop-types';
import React from 'react';
import MetricOpenInPopover from 'src/components/popovers/MetricOpenInPopover';
import { makeSelectAnalysisMetricLink } from 'src/selectors/analysis';
import withFilterContextConsumer from 'src/components/withFilterContextConsumer';

const DashboardMetricOpenInPopoverContainer = (props) => {
    const {
        dashboardMetric, context, editMetricLink, analysisMetricLink, onBackClick, showBackButton
    } = props;

    const { metric } = dashboardMetric;
    return (
        <MetricOpenInPopover
          context={context}
          metric={metric}
          editMetricLink={editMetricLink}
          analysisMetricLink={analysisMetricLink}
          onBackClick={onBackClick}
          showBackButton={showBackButton}
        />
    );
};

DashboardMetricOpenInPopoverContainer.propTypes = {
    context: customPropTypes.filterContext.isRequired,
    editMetricLink: customPropTypes.linkTo.isRequired,
    analysisMetricLink: customPropTypes.linkTo.isRequired,
    dashboardMetric: customPropTypes.dashboardMetric.isRequired,
    showBackButton: PropTypes.bool,
    onBackClick: PropTypes.func
};

const makeMapStateToProps = () => {
    const selectLinkForDashboardMetrics = makeSelectLinkForDashboardMetrics();
    const selectAnalysisMetricLink = makeSelectAnalysisMetricLink();
    const selectEditMetricLink = makeSelectEditMetricLink();

    return (state, ownProps) => {
        const { dashboardMetric, filterContext } = ownProps;
        const metricBuilderLink = selectEditMetricLink(state, filterContext, dashboardMetric.metricId);
        const editMetricLink = selectLinkForDashboardMetrics(state, dashboardMetric, metricBuilderLink);
        const analysisLink = selectAnalysisMetricLink(state, filterContext, dashboardMetric.metricId);
        const analysisMetricLink = selectLinkForDashboardMetrics(state, dashboardMetric, analysisLink);

        return {
            editMetricLink,
            analysisMetricLink
        };
    };
};

export default withFilterContextConsumer(connect(makeMapStateToProps)(DashboardMetricOpenInPopoverContainer));
