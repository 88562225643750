import * as customPropTypes from 'src/customPropTypes';
import React from 'react';
import { getTaskLogDuration } from 'src/utils/dataPushTasks';
import * as routeActions from 'react-router-redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DataPushTaskLogStatus from 'src/components/views/integrations/DataPushTaskLogStatus';
import Date from 'src/components/Date';
import Number from 'src/components/Number';
import DryRunDescriptionPopover from 'src/components/popovers/DryRunDescriptionPopover';
import ListTable from 'src/components/listTable/ListTable';
import ListTableHeader from 'src/components/listTable/ListTableHeader';
import ListTableCell from 'src/components/listTable/ListTableCell';
import ListTableRow from 'src/components/listTable/ListTableRow';
import { getDataPushLogDetailRoute } from 'src/utils/routes';

const columns = [
    {
        id: 'scheduleTime', content: 'Schedule time', width: '15%',
    },
    {
        id: 'startTime', content: 'Start time', width: '15%'
    },
    {
        id: 'duration', content: 'Duration (seconds)', width: '10%'
    },
    {
        id: 'dryRun', content: 'Dry run', infoPopover: <DryRunDescriptionPopover />, width: '22%'
    },
    {
        id: 'status', content: 'Status', width: '22%'
    },
    {
        id: 'rowsPushed', content: 'Rows pushed', align: 'right', width: '12%'
    }
];

const DataPushTaskLogsTable = (props) => {
    const { dataPushTaskLogs } = props;

    const openLogDetailAction = (logId) => {
        const { routerPushAction, dataPushTaskId } = props;
        routerPushAction(getDataPushLogDetailRoute(dataPushTaskId, logId));
    };

    const tableHeader = <ListTableHeader columns={columns} offsetFromPageHeader />;
    return (
        <ListTable header={tableHeader}>
            {
                dataPushTaskLogs.map(({
                    id, scheduleTime, startTime, endTime, rowInserted, isDryRun, status
                }) => (
                    <ListTableRow key={id} onClick={() => { openLogDetailAction(id); }}>
                        <ListTableCell markUnhealthy={status === 'failure' || status === 'partialFailure'}>
                            {
                                scheduleTime
                                    ? <Date date={scheduleTime} format="datetime" />
                                    : <div>N/A</div>
                            }
                        </ListTableCell>
                        <ListTableCell>
                            {
                                startTime
                                    ? <Date date={startTime} format="datetime" />
                                    : <div>N/A</div>
                            }
                        </ListTableCell>
                        <ListTableCell>
                            {
                                (startTime && endTime)
                                    ? getTaskLogDuration(startTime, endTime)
                                    : <div>N/A</div>
                            }
                        </ListTableCell>
                        <ListTableCell>
                            {
                                isDryRun && <span>Yes</span>
                            }
                            {
                                !isDryRun && <span>No</span>
                            }
                        </ListTableCell>
                        <ListTableCell>
                            <DataPushTaskLogStatus status={status} />
                        </ListTableCell>
                        <ListTableCell align="right">
                            <Number value={rowInserted} />
                        </ListTableCell>
                    </ListTableRow>
                ))
            }
        </ListTable>
    );
};

DataPushTaskLogsTable.propTypes = {
    dataPushTaskLogs: customPropTypes.dataPushTaskLogs.isRequired,
    dataPushTaskId: PropTypes.string.isRequired,
    routerPushAction: PropTypes.func.isRequired
};

export default connect(null, {
    routerPushAction: routeActions.push
})(DataPushTaskLogsTable);
