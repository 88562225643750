import { connect } from 'react-redux';
import { makeSelectIsOneItemSelected } from 'src/selectors/lists';
import { makeSelectProfileById } from 'src/selectors/profiles';
import { modalsShowProfileDetails } from 'src/actions/overlays';
import ProfileListItem from 'src/components/views/profiles/ProfileListItem';
import PropTypes from 'prop-types';

const makeMapStateToProps = () => {
    const selectIsOneItemSelected = makeSelectIsOneItemSelected();
    const selectProfileById = makeSelectProfileById();
    return (state, ownProps) => {
        const { profileId, listName } = ownProps;
        const profile = selectProfileById(state, profileId);
        return {
            profile,
            listName,
            buttonsDisabled: selectIsOneItemSelected(state, listName)
        };
    };
};

const ProfileListItemContainer = connect(makeMapStateToProps, {
    modalsShowProfileDetailsAction: modalsShowProfileDetails
})(ProfileListItem);

ProfileListItemContainer.propTypes = {
    profileId: PropTypes.string.isRequired
};

export default ProfileListItemContainer;
