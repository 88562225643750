import React from 'react';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import { getDataPushTaskLogsRequest } from 'src/actions/dataPushTaskLogs';
import PropTypes from 'prop-types';
import { makeSelectDataPushLogAsyncStateByDataPushTaskId } from 'src/selectors/dataPushTasks';
import withToolTip from 'src/components/withToolTip';

const ButtonWithToolTip = withToolTip(Button);

const ReloadDataPushTaskLogsButton = (props) => {
    const { dataPushTaskId, getDataPushTaskLogsRequestAction, isLoading } = props;
    return (
        <ButtonWithToolTip
          icon="refresh"
          tooltip="Reload logs"
          disabled={isLoading}
          onClick={() => { getDataPushTaskLogsRequestAction(dataPushTaskId); }}
        />
    );
};

ReloadDataPushTaskLogsButton.propTypes = {
    dataPushTaskId: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    getDataPushTaskLogsRequestAction: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const selectDataPushLogAsyncStateByDataPushTaskId = makeSelectDataPushLogAsyncStateByDataPushTaskId();
    return (state, ownProps) => ({
        isLoading: selectDataPushLogAsyncStateByDataPushTaskId(state, ownProps.dataPushTaskId)
    });
};

export default connect(makeMapStateToProps, {
    getDataPushTaskLogsRequestAction: getDataPushTaskLogsRequest
})(ReloadDataPushTaskLogsButton);
