import React from 'react';
import TopNav from 'src/components/navigations/TopNav';
import TopNavTextLink from 'src/components/buttons/TopNavTextLink';
import { userAccountRoute, preferenceRoute, securityRoute } from 'src/routePaths';

const AccountSettingsNav = () => (
    <TopNav>
        <TopNavTextLink to={userAccountRoute}>User overview</TopNavTextLink>
        <TopNavTextLink to={preferenceRoute}>Preferences</TopNavTextLink>
        <TopNavTextLink to={securityRoute}>Security</TopNavTextLink>
    </TopNav>
);

export default AccountSettingsNav;
