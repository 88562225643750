import * as customPropTypes from 'src/customPropTypes';
import * as overlayActions from 'src/actions/overlays';
import AuthTransactionItem from 'src/components/views/authentications/AuthTransactionItem';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import { makeSelectOpenedAndClosedTransactions } from 'src/selectors/authTransactions';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import styles from 'src/stylesheets/views/authentications/authTransactionListSidebar.scss';
import Sidebar from 'src/components/sidebar/Sidebar';
import { loadAuthTransactionsRequest } from 'src/actions/authTransactions';

const NUMBER_OF_TRANSACTIONS = 3;

const AuthTransactionListSidebar = (props) => {
    const {
        openedAuthTransactions,
        closedAuthTransactions,
        modalsShowAuthTransactionsDetailAction,
        modalsShowAuthTransactionDetailAction,
        loadAuthTransactionsRequestAction
    } = props;

    useEffect(() => {
        loadAuthTransactionsRequestAction();
    }, []);

    return (
        <Sidebar>
            <div className={styles.wrapper}>
                <div className={styles.title}>
                    Open authentications
                </div>
                {
                    openedAuthTransactions.map((authTransaction) => (
                        <div
                          className={styles.item}
                          key={authTransaction.id}
                          onClick={() => { modalsShowAuthTransactionDetailAction(authTransaction.id); }}
                        >
                            <AuthTransactionItem authTransaction={authTransaction} />
                        </div>
                    ))
                }
                {
                    openedAuthTransactions.length === 0
                    && (
                        <div className={styles.empty}>
                            None
                        </div>
                    )
                }
                <div className={styles.separator} />
                <div>
                    <div className={styles.title}>
                        Closed authentications
                    </div>
                    <div>
                        {
                            closedAuthTransactions.map((authTransaction) => (
                                <div className={styles.item} key={authTransaction.id} onClick={() => { modalsShowAuthTransactionDetailAction(authTransaction.id); }}>
                                    <AuthTransactionItem
                                      authTransaction={authTransaction}
                                    />
                                </div>
                            ))
                        }
                    </div>
                    {
                        closedAuthTransactions.length === 0
                        && (
                            <div className={styles.empty}>
                                None
                            </div>
                        )
                    }
                </div>
                <div className={styles.button}>
                    <Button
                      label="See all"
                      stretch
                      small
                      disabled={openedAuthTransactions.length === 0 && closedAuthTransactions.length === 0}
                      onClick={() => { modalsShowAuthTransactionsDetailAction(); }}
                    />
                </div>
            </div>
        </Sidebar>
    );
};

AuthTransactionListSidebar.propTypes = {
    openedAuthTransactions: customPropTypes.authTransactions.isRequired,
    closedAuthTransactions: customPropTypes.authTransactions.isRequired,
    modalsShowAuthTransactionsDetailAction: PropTypes.func.isRequired,
    modalsShowAuthTransactionDetailAction: PropTypes.func.isRequired,
    loadAuthTransactionsRequestAction: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const selectOpenedAndClosedTransactions = makeSelectOpenedAndClosedTransactions(NUMBER_OF_TRANSACTIONS);
    return (state) => {
        const { openedAuthTransactions, closedAuthTransactions } = selectOpenedAndClosedTransactions(state);
        return {
            openedAuthTransactions,
            closedAuthTransactions
        };
    };
};

export default connect(makeMapStateToProps, {
    modalsShowAuthTransactionsDetailAction: overlayActions.modalsShowAuthTransactionsDetail,
    modalsShowAuthTransactionDetailAction: overlayActions.modalsShowAuthTransactionDetail,
    loadAuthTransactionsRequestAction: loadAuthTransactionsRequest
})(AuthTransactionListSidebar);
