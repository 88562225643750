export const UPDATE_STICKY_HEADER = 'UPDATE_STICKY_HEADER';
export const CLEAN_UP_STICKY_HEADER = 'CLEAN_UP_STICKY_HEADER';

export const updateStickyHeader = (identifier, showSticky) => ({
    type: UPDATE_STICKY_HEADER,
    payload: { identifier, showSticky }
});

export const cleanUpStickyHeader = (identifier) => ({
    type: CLEAN_UP_STICKY_HEADER,
    payload: { identifier }
});
