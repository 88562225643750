import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import { makeSelectOnlyAdAccountById } from 'src/selectors/adAccounts';
import PropTypes from 'prop-types';
import React from 'react';
import BrokenAccountUseCasesRow from 'src/components/views/authentications/table/BrokenAccountUseCasesRow';

const BrokenProfileUseCasesRow = (props) => {
    const {
        adAccount,
        platformUseCases,
        invalidReasonsByUseCaseId
    } = props;
    return (
        <BrokenAccountUseCasesRow
          invalidReasonsByUseCaseId={invalidReasonsByUseCaseId}
          account={adAccount}
          accountType="adAccounts"
          platformUseCases={platformUseCases}
        />
    );
};

BrokenProfileUseCasesRow.propTypes = {
    invalidReasonsByUseCaseId: PropTypes.objectOf(PropTypes.string).isRequired,
    adAccountId: PropTypes.string.isRequired,
    adAccount: customPropTypes.adAccount.isRequired,
    platformUseCases: customPropTypes.useCases.isRequired
};

const makeMapStateToProps = () => {
    const selectOnlyAdAccountById = makeSelectOnlyAdAccountById();
    return (state, ownProps) => ({
        adAccount: selectOnlyAdAccountById(state, ownProps.adAccountId)
    });
};

export default connect(makeMapStateToProps)(BrokenProfileUseCasesRow);
