import {
    ENROLL_MULTI_FACTOR_AUTHENTICATION_ERROR,
    ENROLL_MULTI_FACTOR_AUTHENTICATION_REQUEST,
    ENROLL_MULTI_FACTOR_AUTHENTICATION_SUCCESS
} from 'src/actions/loggedInUser';
import { USER_SECURITY_LOAD_ERROR, USER_SECURITY_LOAD_REQUEST, USER_SECURITY_LOAD_SUCCESS } from 'src/actions/userSecurity';
import { createAsyncStatesReducerForAction, createAsyncStatesReducerForActionsWithErrorAndSuccessStates } from 'src/reducers/utils';
import { combineReducers } from 'redux';

export const userSecurity = combineReducers({
    overview: createAsyncStatesReducerForActionsWithErrorAndSuccessStates(
        (payload) => payload.userId,
        [USER_SECURITY_LOAD_REQUEST],
        [USER_SECURITY_LOAD_SUCCESS],
        [USER_SECURITY_LOAD_ERROR]
    ),
    enrollment: createAsyncStatesReducerForAction(() => 'enrollment', [ENROLL_MULTI_FACTOR_AUTHENTICATION_REQUEST], [ENROLL_MULTI_FACTOR_AUTHENTICATION_SUCCESS, ENROLL_MULTI_FACTOR_AUTHENTICATION_ERROR])
});
