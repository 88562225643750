import { selectDashboardById } from 'src/selectors/dashboards';
import { modalShowMoveToFolder, modalsShowCloneDashboard, modalsShowEditDashboard } from 'src/actions/overlays';
import { connect } from 'react-redux';
import DashboardListItem from 'src/components/views/dashboards/DashboardListItem';
import { makeSelectDashboardLink } from 'src/selectors/filterSelectors';
import { makeSelectIsOneItemSelected } from 'src/selectors/lists';
import PropTypes from 'prop-types';
import { makeSelectFolderByFolderIdAndType, selectDashboardTypeById } from 'src/selectors/ownership';
import { selectLoggedInUser } from 'src/selectors/users';
import * as customPropTypes from 'src/customPropTypes';
import { getDashboardContext } from 'src/utils/filterSelectors';

const makeMapStateToProps = () => {
    const selectDashboardLink = makeSelectDashboardLink();
    const selectFolderByFolderIdAndType = makeSelectFolderByFolderIdAndType();
    const selectIsOneItemSelected = makeSelectIsOneItemSelected();

    return (state, ownProps) => {
        const { dashboardId, listName } = ownProps;
        const dashboard = selectDashboardById(state, dashboardId);
        const dashboardType = selectDashboardTypeById(state, dashboardId);
        const link = selectDashboardLink(state, getDashboardContext(dashboard.id), dashboard.id);
        const parentFolder = selectFolderByFolderIdAndType(state, ownProps.type, dashboard.folderId);
        return {
            dashboard,
            dashboardType,
            parentFolder,
            link,
            loggedInUser: selectLoggedInUser(state),
            actionsDisabled: selectIsOneItemSelected(state, listName)
        };
    };
};

const DashboardListItemContainer = connect(makeMapStateToProps, {
    onEditClickAction: modalsShowEditDashboard,
    onMoveClickAction: modalShowMoveToFolder,
    onCloneClickAction: modalsShowCloneDashboard
})(DashboardListItem);

DashboardListItemContainer.propTypes = {
    dashboardId: PropTypes.string.isRequired,
    listName: PropTypes.string.isRequired,
    type: customPropTypes.ownershipType.isRequired
};

export default DashboardListItemContainer;
