import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Number from 'src/components/Number';
import styles from 'src/stylesheets/discoverTileFooter.scss';

class DiscoverTileFooter extends PureComponent {
    render() {
        const { usage, buttons } = this.props;

        return (
            <div className={styles.footer} onClick={(e) => { e.stopPropagation(); }}>
                <div className={styles.left}>
                    <span className={styles.usage}><Number value={usage} /> x used</span>
                </div>
                <div className={styles.right}>
                    {buttons}
                </div>
            </div>
        );
    }
}

DiscoverTileFooter.propTypes = {
    usage: PropTypes.number.isRequired,
    buttons: PropTypes.node
};

export default DiscoverTileFooter;
