import Body from 'src/components/Body';
import Footer from 'src/components/Footer';
import AllDashboardsHeader from 'src/components/header/AllDashboardsHeader';
import List from 'src/components/List';
import ListPageWrapper from 'src/components/ListPageWrapper';
import ScrollView from 'src/components/ScrollView';
import AllDashboardsListHeader from 'src/components/views/dashboards/AllDashboardsListHeader';
import FolderListItemSuperRootContainer from 'src/components/views/folders/FolderListItemSuperRootContainer';
import withScrollPosition from 'src/components/withScrollPosition';
import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import { getPathForFolderOfType } from 'src/utils/routes';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const ScrollViewWithScrollPosition = withScrollPosition(ScrollView, 'dashboards');

const AllDashboards = ({ location, routePushAction }) => (
    <ScrollViewWithScrollPosition
      scrollPositionIdentifier="super-root"
      locationKey={location.key}
      dataViewName="all-dashboards"
    >
        <AllDashboardsHeader />
        <Body>
            <ListPageWrapper>
                <AllDashboardsListHeader />
                <List>
                    <FolderListItemSuperRootContainer
                      folderId="user"
                      showFolderCount
                      icon="lock"
                      showLabel={false}
                      onClick={() => {
                          const path = getPathForFolderOfType('user', 'user');
                          routePushAction(path);
                      }}
                    />
                    <FolderListItemSuperRootContainer
                      folderId="shared"
                      showLabel={false}
                      showFolderCount
                      icon="share"
                      onClick={() => {
                          const path = getPathForFolderOfType('shared', 'shared');
                          routePushAction(path);
                      }}
                    />

                </List>
            </ListPageWrapper>
        </Body>
        <Footer />
    </ScrollViewWithScrollPosition>
);

AllDashboards.propTypes = {
    location: customPropTypes.location.isRequired,
    routePushAction: PropTypes.func.isRequired,
};

export default connect(null, {
    routePushAction: push
})(AllDashboards);
