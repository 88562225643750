import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import Auth0LoginHandler from 'src/components/auth0/Auth0LoginHandler';
import AppProviderTool from 'src/components/AppProviderTool';
import configuration from 'react-global-configuration';
import React, { StrictMode } from 'react';
import { render } from 'react-dom';
import 'src/stylesheets/globalDefault.scss';
import { initializeSentry } from 'src/utils/sentry';
import { Auth0Provider } from '@auth0/auth0-react';
import { browserHistory } from 'react-router';

initializeSentry();
configuration.set({}, { freeze: false });
render(
    <StrictMode>
        <Auth0Provider
          domain={global.AUTH0_DOMAIN}
          clientId={global.AUTH0_CLIENT_ID}
          redirectUri={window.location.origin}
          audience={global.AUTH0_AUDIENCE}
          onRedirectCallback={((appState) => {
              if (appState && appState.impersonateRedirect) {
                  browserHistory.replace(appState.impersonateRedirect);
              } else {
                  browserHistory.replace((appState && appState.returnTo) || window.location.pathname);
              }
          })}
        >
            <Auth0LoginHandler renderTool={() => <AppProviderTool />} />
        </Auth0Provider>
    </StrictMode>, document.getElementById('root')
);
