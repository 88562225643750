import { TOGGLE_IN_ACTIVE_DATA_PUSH_TASKS } from 'src/actions/dataPushTasks';

export function showInactiveDataPushTasks(state = false, action) {
    const { type } = action;
    switch (type) {
        case TOGGLE_IN_ACTIVE_DATA_PUSH_TASKS: {
            return !state;
        }
        default:
            return state;
    }
}
