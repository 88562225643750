import React, { useEffect } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import { connect } from 'react-redux';
import { makeSelectSampleServerData } from 'src/selectors/sampleServerData';
import PropTypes from 'prop-types';
import { sampleServerDataRequest } from 'src/actions/sampleServerData';

const withSampleServerData = (WrappedComponent) => {
    const WithSampleServerData = (props) => {
        useEffect(() => {
            const { sampleServerData, sampleServerDataRequestAction, metric } = props;
            if (_get(sampleServerData, 'requested') === false) {
                sampleServerDataRequestAction(metric.id, metric.visualization.id);
            }
        }, []);

        const { sampleServerData } = props;
        const cleanedProps = _omit(props, 'sampleServerData');
        return <WrappedComponent {...cleanedProps} {...sampleServerData} />;
    };

    WithSampleServerData.propTypes = {
        sampleServerData: PropTypes.object.isRequired,
        metric: customPropTypes.minimalMetricShapeForDataLoading.isRequired,
        sampleServerDataRequestAction: PropTypes.func.isRequired,
    };

    const makeMapStateToProps = () => {
        const selectSampleServerData = makeSelectSampleServerData();
        return (state, ownProps) => {
            const { metric } = ownProps;
            const sampleServerData = selectSampleServerData(state, metric.id, metric.visualization.id);
            return {
                sampleServerData,
            };
        };
    };

    return connect(makeMapStateToProps, {
        sampleServerDataRequestAction: sampleServerDataRequest
    })(WithSampleServerData);
};

export default withSampleServerData;
