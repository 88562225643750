import { ADMIN_ONLY, NON_READ_ONLY_USER } from 'src/selectors/privilegeCheckType';
import React from 'react';
import { selectIsAdmin, selectIsReadOnly } from 'src/selectors/loggedInUser';
import _isArray from 'lodash/isArray';
import { connect } from 'react-redux';
import MissingAdminRoleErrorView from 'src/components/errors/MissingAdminRoleErrorView';
import PropTypes from 'prop-types';
import ReadOnlyErrorView from 'src/components/errors/ReadOnlyErrorView';

const withPrivilegeCheck = (WrappedComponent, privilegeCheckType, adminOnlyErrorView = null, readOnlyErrorView = null) => {
    const WithAdminOnlyCheck = (props) => {
        const getComponentBasedOnPrivilegeType = (type) => {
            const { isAdmin, isReadOnlyInSpace } = props;
            let componentToRender = null;
            switch (type) {
                case ADMIN_ONLY: {
                    if (!isAdmin) {
                        componentToRender = adminOnlyErrorView !== null ? adminOnlyErrorView : <MissingAdminRoleErrorView />;
                    }
                    break;
                }
                case NON_READ_ONLY_USER: {
                    if (isReadOnlyInSpace) {
                        componentToRender = readOnlyErrorView !== null ? readOnlyErrorView : <ReadOnlyErrorView />;
                    }
                    break;
                }
                default:
                    break;
            }

            return (
                componentToRender
            );
        };

        const privilegesToCheck = _isArray(privilegeCheckType) ? privilegeCheckType : [privilegeCheckType];

        let componentToRender = <WrappedComponent {...props} />;
        for (let i = 0; i < privilegesToCheck.length; i += 1) {
            const component = getComponentBasedOnPrivilegeType(privilegesToCheck[i]);
            if (component) {
                componentToRender = component;
                break;
            }
        }
        return componentToRender;
    };

    WithAdminOnlyCheck.propTypes = {
        isAdmin: PropTypes.bool.isRequired,
        isReadOnlyInSpace: PropTypes.bool.isRequired,
    };

    const mapStateToProps = (state) => ({
        isAdmin: selectIsAdmin(state),
        isReadOnlyInSpace: selectIsReadOnly(state),
    });

    return connect(mapStateToProps)(WithAdminOnlyCheck);
};

export default withPrivilegeCheck;
