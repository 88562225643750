import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import { parseCamelCaseToSentenceCase } from 'src/parsers';
import ProgressBarWithLabels from 'src/components/ProgressBarWithLabels';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/spaceLimitProgressBars.scss';

const getLabelFromLimit = (limit) => parseCamelCaseToSentenceCase(limit);

const SpaceLimitProgressBars = (props) => {
    const { spaceLimits, showLimitsFor, horizontal } = props;
    const { limits, usage } = spaceLimits;

    const progressBars = showLimitsFor.map((type) => (
        <div className={styles.progressBarWrapper} key={`spaceLimitProgressBarWrapper-${type}`}>
            <ProgressBarWithLabels
              key={`spaceLimitProgressBar-${type}`}
              limit={limits[type]}
              usage={usage[type]}
              label={getLabelFromLimit(type)}
            />
        </div>
    ));

    return (
        <div className={classnames(styles.wrapper, { [styles.horizontal]: horizontal })}>
            {progressBars}
        </div>
    );
};

SpaceLimitProgressBars.propTypes = {
    horizontal: PropTypes.bool,
    showLimitsFor: PropTypes.arrayOf(PropTypes.string),
    spaceLimits: customPropTypes.spaceLimits.isRequired
};

SpaceLimitProgressBars.defaultProps = {
    showLimitsFor: ['profiles', 'profileSwaps'], // valid inputs are: profiles, profileSwaps
    horizontal: false
};

export default SpaceLimitProgressBars;
