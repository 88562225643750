import {
    LOAD_USER_INVITATION_REQUEST,
    loadUserInvitationError,
    loadUserInvitationSuccess
} from 'src/actions/userInvitations';
import { parseInvitation } from 'src/parsers';
import { put, takeEvery } from 'redux-saga/effects';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import { reportError } from 'src/utils/reportError';

function* loadUserInvitation(action) {
    const { userId, invitationCode } = action.payload;
    try {
        const params = { userId, invitationCode };
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-index/get-invitation');
        if (response) {
            yield put(loadUserInvitationSuccess(invitationCode, parseInvitation(response.jsonData)));
        }

        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(loadUserInvitationError(invitationCode, applicationOrServerError));
    }
}

export default function* userInvitationsSagas() {
    yield takeEvery(LOAD_USER_INVITATION_REQUEST, loadUserInvitation);
}
