import { connect } from 'react-redux';
import DiscoverStart from 'src/components/views/discover/base/DiscoverStart';
import PropTypes from 'prop-types';
import React from 'react';
import { selectCustomMetricsCount } from 'src/selectors/metrics';
import withMostUsedNetworks from 'src/components/views/withMostUsedNetworks';

const getRecommendationIdentifiers = (mostUsedNetwork, mostUsedNetworkTagId, customMetricCount) => {
    const recommendations = [
        {
            type: 'recommendation',
            identifier: 'featuredMetrics',
            title: 'Featured metrics',
            payload: '11,170,2,102,77,181'
        },
        {
            type: 'recommendation',
            identifier: 'newestPublicMetrics',
            title: 'Recently added metrics',
        }
    ];

    if (customMetricCount > 0 && mostUsedNetwork) {
        recommendations.push({
            type: 'search',
            identifier: `mostUsedCustomMetrics-${mostUsedNetworkTagId}`,
            title: `Most used custom metrics for ${mostUsedNetwork}`,
            searchParams: {
                filterBy: 'customMetrics',
                orderBy: 'mostUsed',
                tagIds: [mostUsedNetworkTagId],
                limit: 6
            }
        });
    }

    recommendations.push({
        type: 'search',
        identifier: 'crossNetworkMetrics',
        title: 'All social networks at a glance',
        searchParams: {
            filterBy: 'publicMetrics',
            orderBy: 'mostUsed',
            tagIds: ['17'],
            limit: 6
        }
    });

    return recommendations;
};

const DiscoverStartMetrics = (props) => {
    const { mostUsedNetwork, mostUsedNetworkTagId, customMetricCount } = props;
    const recommendationIdentifiers = getRecommendationIdentifiers(mostUsedNetwork, mostUsedNetworkTagId, customMetricCount);
    return (
        <DiscoverStart recommendationIdentifiers={recommendationIdentifiers} filterBy="metrics" />
    );
};

DiscoverStartMetrics.propTypes = {
    mostUsedNetwork: PropTypes.string.isRequired,
    mostUsedNetworkTagId: PropTypes.string.isRequired,
    customMetricCount: PropTypes.number.isRequired
};

const mapStateToProps = (state) => ({
    customMetricCount: selectCustomMetricsCount(state)
});

export default withMostUsedNetworks(connect(mapStateToProps)(DiscoverStartMetrics));
