export const GET_DATA_PUSH_TASK_LOGS_REQUEST = 'GET_DATA_PUSH_TASK_LOGS_REQUEST';
export const GET_DATA_PUSH_TASK_LOGS_SUCCESS = 'GET_DATA_PUSH_TASK_LOGS_SUCCESS';
export const GET_DATA_PUSH_TASK_LOGS_ERROR = 'GET_DATA_PUSH_TASK_LOGS_ERROR';

export const GET_DATA_PUSH_TASK_LOG_REQUEST = 'GET_DATA_PUSH_TASK_LOG_REQUEST';
export const GET_DATA_PUSH_TASK_LOG_SUCCESS = 'GET_DATA_PUSH_TASK_LOG_SUCCESS';
export const GET_DATA_PUSH_TASK_LOG_ERROR = 'GET_DATA_PUSH_TASK_LOG_ERROR';

export const GET_DATA_PUSH_TASK_LOG_DATA_REQUEST = 'GET_DATA_PUSH_TASK_LOG_DATA_REQUEST';
export const GET_DATA_PUSH_TASK_LOG_DATA_SUCCESS = 'GET_DATA_PUSH_TASK_LOG_DATA_SUCCESS';
export const GET_DATA_PUSH_TASK_LOG_DATA_ERROR = 'GET_DATA_PUSH_TASK_LOG_DATA_ERROR';

export const getDataPushTaskLogsRequest = (dataPushTaskId, startFrom = 0) => ({
    type: GET_DATA_PUSH_TASK_LOGS_REQUEST,
    payload: { dataPushTaskId, startFrom }
});

export const getDataPushTaskLogsSuccess = (dataPushTaskId, dataPushTaskLogs, lastStartTime) => ({
    type: GET_DATA_PUSH_TASK_LOGS_SUCCESS,
    payload: {
        dataPushTaskId,
        dataPushTaskLogs,
        lastStartTime
    }
});

export const getDataPushTaskLogsError = (dataPushTaskId, error) => ({
    type: GET_DATA_PUSH_TASK_LOGS_ERROR,
    payload: {
        dataPushTaskId, error
    }
});

export const getDataPushTaskLogRequest = (id, dataPushTaskId) => ({
    type: GET_DATA_PUSH_TASK_LOG_REQUEST,
    payload: { id, dataPushTaskId }
});

export const getDataPushTaskLogError = (id, error) => ({
    type: GET_DATA_PUSH_TASK_LOG_ERROR,
    payload: { id, error }
});

export const getDataPushTaskLogSuccess = (id, dataPushTaskLog) => ({
    type: GET_DATA_PUSH_TASK_LOG_SUCCESS,
    payload: { id, dataPushTaskLog }
});

export const getDataPushTaskLogDataRequest = (id, dataPushTaskId) => ({
    type: GET_DATA_PUSH_TASK_LOG_DATA_REQUEST,
    payload: { id, dataPushTaskId }
});

export const getDataPushTaskLogDataSuccess = (id, data) => ({
    type: GET_DATA_PUSH_TASK_LOG_DATA_SUCCESS,
    payload: { id, data }
});

export const getDataPushTaskLogDataError = (id, error) => ({
    type: GET_DATA_PUSH_TASK_LOG_DATA_ERROR,
    payload: { id, error }
});
