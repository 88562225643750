import * as metricSearchActionCreators from 'src/actions/metricSearch';
import { connect } from 'react-redux';
import DiscoverSearchAutocomplete from 'src/components/autocomplete/DiscoverSearchAutocomplete';
import { getTextsByFilter } from 'src/utils/discoverUtiles';
import PropTypes from 'prop-types';
import React from 'react';
import { selectMetricSearchFilterBy } from 'src/selectors/metricSearch';
import styles from 'src/stylesheets/views/discover/discoverSearchInput.scss';
import TextWithAutocomplete from 'src/components/forms/inputs/TextWithAutocomplete';
import withDiscoverAutocomplete from 'src/components/views/discover/withDiscoverAutocomplete';
import withOuterSearchTerm from 'src/components/forms/inputs/withOuterSearchTerm';

const TextWithDiscoverAutocomplete = withOuterSearchTerm(withDiscoverAutocomplete(TextWithAutocomplete));

const renderAutocompleteMenu = (autocompleteSuggestions, activeIndex, hide, onItemSelect, searchTerm) => (
    <DiscoverSearchAutocomplete
      activeIndex={activeIndex}
      autocompleteSuggestions={autocompleteSuggestions}
      onItemClick={onItemSelect}
      hidePopover={hide}
      searchTerm={searchTerm}
    />
);

const DiscoverSearchInput = (props) => {
    const handleOnItemSelect = (result) => {
        const { metricSearchTermChangeAction, metricSearchSelectionReplaceAction } = props;
        const { type, item } = result;

        switch (type) {
            case 'tag':
                metricSearchSelectionReplaceAction(item);
                break;
            case 'term':
                metricSearchTermChangeAction(item.term);
                break;
            default:
                break;
        }
    };
    const { layout, filterBy } = props;
    const texts = getTextsByFilter(filterBy);

    return (
        <div className={styles.wrapper}>
            <TextWithDiscoverAutocomplete
              onItemSelect={handleOnItemSelect}
              renderAutocompleteMenu={renderAutocompleteMenu}
              placeholder={texts.placeholder}
              layout={layout}
              autoComplete="off"
              autoFocus
              icon="search"
              resetAfterSelect
              minLength={1}
            />
        </div>
    );
};

DiscoverSearchInput.propTypes = {
    metricSearchTermChangeAction: PropTypes.func.isRequired,
    metricSearchSelectionReplaceAction: PropTypes.func.isRequired,
    layout: PropTypes.string,
    filterBy: PropTypes.string.isRequired
};

DiscoverSearchInput.defaultProps = {
    layout: 'default'
};

const mapStateToProps = (state) => ({
    filterBy: selectMetricSearchFilterBy(state)
});

export default connect(mapStateToProps, {
    metricSearchTermChangeAction: metricSearchActionCreators.metricSearchTermChanged,
    metricSearchSelectionReplaceAction: metricSearchActionCreators.metricSearchSelectionReplace
})(DiscoverSearchInput);
