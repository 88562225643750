import React, { Component } from 'react';

const withOuterSearchTerm = (WrappedComponent) => {
    class WithOuterSearchTerm extends Component {
        constructor(props) {
            super(props);
            this.state = {
                searchTerm: ''
            };
            this.handleOnChange = this.handleOnChange.bind(this);
        }

        handleOnChange(searchTerm) {
            this.setState({ searchTerm });
        }

        render() {
            const { searchTerm } = this.state;
            return <WrappedComponent {...this.props} searchTerm={searchTerm} onChange={this.handleOnChange} />;
        }
    }

    return WithOuterSearchTerm;
};

export default withOuterSearchTerm;
