import React, { PureComponent } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/selectorButtonGroup.scss';

class SelectorButtonGroup extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            lastHovered: 'profileSelector'
        };
        this.setLastHoveredSelector = this.setLastHoveredSelector.bind(this);
    }

    setLastHoveredSelector(lastHoveredSelector) {
        const {
            lastHovered
        } = this.state;
        if (lastHovered !== lastHoveredSelector) {
            this.setState({
                lastHovered: lastHoveredSelector
            });
        }
    }

    render() {
        const {
            profileSelector, dateSelector, small, rightAlign, forSummary, filterSelector
        } = this.props;

        const {
            lastHovered
        } = this.state;

        return (
            <ul className={classnames(styles.list, { [styles.small]: small, [styles.rightAlign]: rightAlign, [styles.forSummary]: forSummary })}>
                <li
                  onMouseEnter={() => { this.setLastHoveredSelector('profileSelector'); }}
                  className={classnames(styles.profileSelector, { [styles.higherZIndex]: lastHovered === 'profileSelector' })}
                >
                    {profileSelector}
                </li>
                <li
                  onMouseEnter={() => { this.setLastHoveredSelector('dateSelector'); }}
                  className={classnames(styles.dateSelector, { [styles.higherZIndex]: lastHovered === 'dateSelector' })}
                >
                    {dateSelector}
                </li>
                <li className={styles.filterSelector}>
                    {filterSelector}
                </li>

            </ul>
        );
    }
}

SelectorButtonGroup.propTypes = {
    profileSelector: PropTypes.node.isRequired,
    dateSelector: PropTypes.node.isRequired,
    filterSelector: PropTypes.node.isRequired,
    small: PropTypes.bool,
    rightAlign: PropTypes.bool,
    forSummary: PropTypes.bool
};

SelectorButtonGroup.defaultProps = {
    small: false,
    rightAlign: false,
    forSummary: false
};

export default SelectorButtonGroup;
