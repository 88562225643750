import Column from 'src/components/header/layout/Column';
import EmptyDiv from 'src/components/loadingIndicators/EmptyDiv';
import Header from 'src/components/header/layout/Header';
import React from 'react';
import TopRow from 'src/components/header/layout/TopRow';
import styles from 'src/stylesheets/headers/dataPushTaskHeader.scss';

const DataPushTaskLoadingHeader = () => (
    <Header>
        <TopRow>
            <Column flexGrow className={styles.wrapper}>
                <div className={styles.backButton}>
                    <EmptyDiv height={24} width={28} />
                </div>
                <div className={styles.googleBigQueryImage}>
                    <EmptyDiv height={24} width={24} />
                </div>
                <EmptyDiv height={35} width={300} />
            </Column>
        </TopRow>
    </Header>
);

export default DataPushTaskLoadingHeader;
