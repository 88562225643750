import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import NetworkIcon from 'src/components/NetworkIcon';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/dataSourceNameHeader.scss';
import classnames from 'classnames';
import { getDeprecationDateSentenceFromNow } from 'src/utils/dataSources';

const DataSourceNameHeader = (props) => {
    const {
        name,
        platformType,
        isDeprecated,
        deprecationDate,
        size
    } = props;

    const header = `${name}${isDeprecated ? ` (${getDeprecationDateSentenceFromNow(deprecationDate)})` : ''}`;

    return (
        <div
          className={classnames(
              styles.wrapper,
              { [styles.small]: size === 'small' },
              { [styles.large]: size === 'large' }
          )}
        >
            {
                platformType
                && (
                <NetworkIcon
                  platformType={platformType}
                  className={styles.networkIcon}
                />
                )
            }
            <div className={styles.ellipsisWrapper}>
                <DivWithTooltip
                  tooltip={header}
                  className={styles.dataSourceName}
                >
                    {header}
                </DivWithTooltip>
            </div>
        </div>
    );
};

DataSourceNameHeader.propTypes = {
    name: PropTypes.string.isRequired,
    platformType: PropTypes.string.isRequired,
    isDeprecated: PropTypes.bool.isRequired,
    deprecationDate: PropTypes.string,
    size: PropTypes.oneOf(['small', 'large'])
};

DataSourceNameHeader.defaultProps = {
    size: 'large',
    deprecationDate: ''
};

export default DataSourceNameHeader;
