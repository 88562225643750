import React from 'react';
import Button from 'src/components/buttons/Button';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeSelectDataPushTaskLogData, makeSelectDataPushLogById } from 'src/selectors/dataPushTaskLogs';
import { triggerDataPushTaskLogDownload } from 'src/actions/exports';
import * as customPropTypes from 'src/customPropTypes';

const DataPushTaskLogFileDownloadButton = (props) => {
    const { logData } = props;

    const handleOnDownload = () => {
        const { dataPushTaskLog, triggerDataPushTaskLogDownloadAction } = props;
        const { id, dataPushTaskId, rawDataType } = dataPushTaskLog;
        triggerDataPushTaskLogDownloadAction(id, dataPushTaskId, rawDataType);
    };
    return (
        <Button
          label="Download data"
          icon="export"
          action
          onClick={handleOnDownload}
          disabled={!logData}
        />
    );
};

DataPushTaskLogFileDownloadButton.propTypes = {
    id: PropTypes.string.isRequired,
    triggerDataPushTaskLogDownloadAction: PropTypes.func.isRequired,
    dataPushTaskLog: customPropTypes.dataPushTaskLog.isRequired,
    logData: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired
};

const makeMapStateToProps = () => {
    const selectDataPushTaskLogData = makeSelectDataPushTaskLogData();
    const selectDataPushLogById = makeSelectDataPushLogById();
    return (state, ownProps) => ({
        dataPushTaskLog: selectDataPushLogById(state, ownProps.id),
        logData: selectDataPushTaskLogData(state, ownProps.id)
    });
};

export default connect(makeMapStateToProps, {
    triggerDataPushTaskLogDownloadAction: triggerDataPushTaskLogDownload
})(DataPushTaskLogFileDownloadButton);
