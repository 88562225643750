import { connect } from 'react-redux';
import { makeSelectMetricSearchResultByTypeAndId } from 'src/selectors/metricSearch';
import { makeSelectTagsById } from 'src/selectors/tags';
import { selectLoggedInUser } from 'src/selectors/users';

const withStoreConnection = (WrappedComponent) => {
    const makeMapStateToProps = () => {
        const selectMetricSearchResultByTypeAndId = makeSelectMetricSearchResultByTypeAndId();
        const selectTagsById = makeSelectTagsById();

        return (state, ownProps) => {
            const metricSearchResult = selectMetricSearchResultByTypeAndId(state, ownProps.type, ownProps.id);
            const tags = selectTagsById(state, metricSearchResult.tagIds);

            return {
                metricSearchResult,
                tags,
                loggedInUser: selectLoggedInUser(state)
            };
        };
    };

    const WithStoreConnection = connect(makeMapStateToProps)(WrappedComponent);

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    WithStoreConnection.displayName = `withStoreConnection(${wrappedComponentName})`;

    return WithStoreConnection;
};

export default withStoreConnection;
