import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/headers/header.scss';

const PageDetail = ({ detail }) => <div className={styles.detail}>{detail}</div>;

PageDetail.propTypes = {
    detail: PropTypes.string.isRequired
};

export default PageDetail;
