import {
    CHECK_LATEST_USER_NOTIFICATION,
    CHECK_LATEST_USER_NOTIFICATION_ERROR,
    CHECK_LATEST_USER_NOTIFICATION_SUCCESS,
    GET_USER_NOTIFICATIONS_ERROR,
    GET_USER_NOTIFICATIONS_REQUEST,
    GET_USER_NOTIFICATIONS_SUCCESS,
    MARK_ALL_USER_NOTIFICATION_AS_READ_SUCCESS,
    MARK_USER_NOTIFICATION_SUCCESS,
    UPDATE_USER_NOTIFICATION_LAST_SEEN_TIME_SUCCESS,
    UPDATE_USER_NOTIFICATION_SETTINGS_SUCCESS,
} from 'src/actions/userNotification';
import _uniq from 'lodash/uniq';
import { combineReducers } from 'redux';
import { USER_LOGGED_IN } from 'src/actions/loggedInUser';

export function indicator(state = false, action) {
    const { type, payload } = action;
    switch (type) {
        case UPDATE_USER_NOTIFICATION_LAST_SEEN_TIME_SUCCESS:
        case MARK_ALL_USER_NOTIFICATION_AS_READ_SUCCESS:
        case CHECK_LATEST_USER_NOTIFICATION_SUCCESS: {
            const { hasNewNotifications } = payload;
            return hasNewNotifications;
        }
        default: {
            return state;
        }
    }
}

export function byId(state = {}, action) {
    const { type, payload } = action;
    switch (type) {
        case CHECK_LATEST_USER_NOTIFICATION_SUCCESS:
        case GET_USER_NOTIFICATIONS_SUCCESS: {
            const { userNotifications } = payload;
            const { parsedNotifications } = userNotifications;
            return Object.assign({}, state, parsedNotifications);
        }
        case MARK_USER_NOTIFICATION_SUCCESS: {
            const { userNotificationId, mark } = payload;
            const oldUserNotification = Object.assign({}, state[userNotificationId]);
            const updatedUserNotification = Object.assign({}, oldUserNotification, { wasRead: mark });
            return Object.assign({}, state, { [userNotificationId]: updatedUserNotification });
        }
        case MARK_ALL_USER_NOTIFICATION_AS_READ_SUCCESS: {
            const allOldNotifications = Object.assign({}, state);
            let updatedUserNotifications = {};
            Object.keys(allOldNotifications).forEach((userNotificationId) => {
                const oldUserNotification = allOldNotifications[userNotificationId];
                if (!oldUserNotification.wasRead) {
                    const updatedUserNotification = Object.assign({}, oldUserNotification, { wasRead: true });
                    updatedUserNotifications = Object.assign({}, updatedUserNotifications, { [userNotificationId]: updatedUserNotification });
                }
            });
            return Object.assign({}, state, updatedUserNotifications);
        }

        default: {
            return state;
        }
    }
}

export function notificationPageIds(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case GET_USER_NOTIFICATIONS_REQUEST: {
            const { reset } = payload;
            if (reset) {
                return [];
            }
            return state;
        }
        case GET_USER_NOTIFICATIONS_SUCCESS: {
            const { userNotifications } = payload;
            const { sortedIds } = userNotifications;
            return _uniq([...state, ...sortedIds]);
        }
        default: {
            return state;
        }
    }
}

export function pageRequestState(state = {
    loading: false, next: false, lastNotificationCreateTime: null, error: null
}, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_USER_NOTIFICATIONS_REQUEST: {
            const { reset } = payload;
            const next = reset ? false : state.next;
            return Object.assign({}, state, { loading: true, error: null, next });
        }
        case GET_USER_NOTIFICATIONS_SUCCESS: {
            const { next, lastNotificationCreateTime } = payload;
            const changes = { next, loading: false, error: null };
            if (lastNotificationCreateTime !== null) {
                changes.lastNotificationCreateTime = lastNotificationCreateTime;
            }
            return Object.assign({}, state, changes);
        }
        case GET_USER_NOTIFICATIONS_ERROR: {
            const { error } = payload;
            return Object.assign({}, state, { loading: false, error });
        }

        default: {
            return state;
        }
    }
}

export function notificationMenuIds(state = [], action) {
    const { type, payload } = action;
    switch (type) {
        case CHECK_LATEST_USER_NOTIFICATION_SUCCESS: {
            const { userNotifications } = payload;
            const { sortedIds } = userNotifications;
            return sortedIds;
        }
        default: {
            return state;
        }
    }
}

export function menuRequestState(state = { loading: true, error: null }, action) {
    const { type, payload } = action;

    switch (type) {
        case CHECK_LATEST_USER_NOTIFICATION: {
            return Object.assign({}, state, { loading: true, error: null });
        }
        case CHECK_LATEST_USER_NOTIFICATION_SUCCESS: {
            return Object.assign({}, state, { loading: false, error: null });
        }
        case CHECK_LATEST_USER_NOTIFICATION_ERROR: {
            const { error } = payload;
            return Object.assign({}, state, { loading: false, error });
        }

        default: {
            return state;
        }
    }
}

const menuNotifications = combineReducers({
    notificationMenuIds,
    menuRequestState,
    indicator
});

const pageNotifications = combineReducers({
    notificationPageIds,
    pageRequestState
});

export const userNotificationSettings = (state = {}, action) => {
    const { payload, type } = action;
    switch (type) {
        case USER_LOGGED_IN:
        case UPDATE_USER_NOTIFICATION_SETTINGS_SUCCESS:
            return Object.assign({}, state, payload.userNotificationSettings);
        default:
            return state;
    }
};

export const userNotification = combineReducers({
    menuNotifications,
    pageNotifications,
    byId,
    userNotificationSettings
});
