import * as customPropTypes from 'src/customPropTypes';
import React from 'react';
import styles from 'src/stylesheets/listPageWrapper.scss';

const ListPageWrapper = ({ children }) => (
    <div className={styles.wrapper}>
        {children}
    </div>
);

ListPageWrapper.propTypes = {
    children: customPropTypes.children.isRequired,
};

export default ListPageWrapper;
