import { modalShowDeleteUser, modalShowEditUser } from 'src/actions/overlays';
import { connect } from 'react-redux';
import { makeSelectUserById } from 'src/selectors/users';
import PropTypes from 'prop-types';
import UserListItem from 'src/components/views/settings/users/UserListItem';

const makeMapStateToProps = () => {
    const selectUserById = makeSelectUserById();
    return (state, ownProps) => {
        const user = selectUserById(state, ownProps.userId);
        return {
            id: user.id,
            email: user.email,
            lastLoginTime: user.lastLoginTime,
            spaces: user.spaces,
            isAdmin: user.admin,
            gravatar80px: user.gravatar80px,
            lastActiveTime: user.lastActiveTime,
            onboarded: user.onboarded,
            invitationPending: user.invitationPending,
            displayName: user.displayName
        };
    };
};

const UserListItemContainer = connect(makeMapStateToProps, {
    modalShowDeleteUserAction: modalShowDeleteUser,
    modalShowEditUserAction: modalShowEditUser
})(UserListItem);

UserListItemContainer.propTypes = {
    userId: PropTypes.string.isRequired
};

export default UserListItemContainer;
