import { Link, withRouter } from 'react-router';
import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import { isAlsoActiveOnRoutes, isExternalLink } from 'src/utils/routes';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/buttons/mainNavTextLink.scss';

const MainNavTextLink = (props) => {
    const {
        to,
        alsoActiveOn,
        location,
        children,
        className,
        onClick,
        active
    } = props;
    const url = to && to.pathname ? to.pathname : to;
    if (isExternalLink(url)) {
        return (
            <a href={url} target="_blank" rel="noopener noreferrer" className={styles.button}>
                {children}
            </a>
        );
    }

    const combinedClassName = classnames(styles.button, { [styles.active]: (isAlsoActiveOnRoutes(alsoActiveOn, location) || active) }, className);
    const linkProps = {
        activeClassName: to ? styles.active : null,
        className: combinedClassName
    };
    if (url) {
        linkProps.to = to;
    }
    if (onClick) {
        linkProps.onClick = (e) => { onClick(e); };
    }

    return (
        <Link {...linkProps}>
            {children}
        </Link>
    );
};

MainNavTextLink.propTypes = {
    onClick: PropTypes.func,
    to: customPropTypes.linkTo,
    children: customPropTypes.children,
    alsoActiveOn: PropTypes.arrayOf(PropTypes.string),
    location: customPropTypes.location.isRequired,
    className: PropTypes.string,
    active: PropTypes.bool
};

MainNavTextLink.defaultProps = {
    active: false
};

export default withRouter(MainNavTextLink);
