import { isCustomMetricFilter, metricFilters } from 'src/utils/discoverUtiles';
import React, { Component } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import * as metricSearchActionCreators from 'src/actions/metricSearch';
import Body from 'src/components/Body';
import { connect } from 'react-redux';
import DiscoverFilter from 'src/components/views/discover/DiscoverFilter';
import DiscoverHeader from 'src/components/header/DiscoverHeader';
import DiscoverSearchInput from 'src/components/views/discover/DiscoverSearchInput';
import Footer from 'src/components/Footer';
import PropTypes from 'prop-types';
import RecommendationsHandler from 'src/components/recommendation/RecommendationsHandler';
import ScrollView from 'src/components/ScrollView';
import { selectMetricSearchInput } from 'src/selectors/metricSearch';
import styles from 'src/stylesheets/views/discover/discover.scss';
import withCustomMetricCheck from 'src/components/views/discover/withCustomMetricCheck';
import { withRouter } from 'react-router';
import withScrollPosition from 'src/components/withScrollPosition';

const ScrollViewWithScrollPosition = withScrollPosition(ScrollView, 'discoverStart');

class DiscoverStart extends Component {
    componentDidMount() {
        const {
            metricSearchInput,
            metricSearchFilterByUpdateResetRestAction,
            filterBy
        } = this.props;

        if (metricSearchInput.filterBy !== filterBy || metricSearchInput.selectedTags.length > 0 || metricSearchInput.term !== '') {
            metricSearchFilterByUpdateResetRestAction(filterBy);
        }
    }

    render() {
        const { recommendationIdentifiers, filterBy, location } = this.props;
        const identifier = `discoverStart-${filterBy}`;

        let Recommendations = () => (
            recommendationIdentifiers
                ? <RecommendationsHandler identifiers={recommendationIdentifiers} />
                : null
        );

        if (isCustomMetricFilter(filterBy)) {
            Recommendations = withCustomMetricCheck(Recommendations);
        }

        return (
            <ScrollViewWithScrollPosition
              dataViewName="discover-start"
              scrollPositionIdentifier={identifier}
              locationKey={location.key}
              resetAfterRead
            >
                <DiscoverHeader />
                <Body>
                    <DiscoverSearchInput layout="discover" />
                    <div className={styles.body}>
                        <div className={styles.sidebar}>
                            <DiscoverFilter />
                        </div>
                        <div className={styles.recommendations}>
                            <Recommendations />
                        </div>
                    </div>
                </Body>
                <Footer />
            </ScrollViewWithScrollPosition>
        );
    }
}

DiscoverStart.propTypes = {
    metricSearchInput: customPropTypes.metricSearchInput.isRequired,
    filterBy: PropTypes.oneOf(['dashboards', ...metricFilters]).isRequired,
    recommendationIdentifiers: PropTypes.arrayOf(PropTypes.object).isRequired,
    metricSearchFilterByUpdateResetRestAction: PropTypes.func.isRequired,
    location: customPropTypes.location
};

const mapStateToProps = () => (state) => ({
    metricSearchInput: selectMetricSearchInput(state)
});

export default connect(mapStateToProps, {
    metricSearchFilterByUpdateResetRestAction: metricSearchActionCreators.metricSearchFilterByUpdateResetRest,
})(withRouter(DiscoverStart));
