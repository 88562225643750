import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/tagGroup.scss';

const TagGroup = ({ className, children }) => (
    <ul className={classnames(styles.tags, { [className]: (className) })}>
        {
            React.Children.map(children, (tag) => {
                if (tag) {
                    return <li>{tag}</li>;
                }
                return null;
            })
        }
    </ul>
);

TagGroup.propTypes = {
    children: customPropTypes.children.isRequired,
    className: PropTypes.string
};

export default TagGroup;
