export const METRIC_SEARCH_SEARCH_REQUEST = 'METRIC_SEARCH_SEARCH_REQUEST';
export const METRIC_SEARCH_SEARCH_SUCCESS = 'METRIC_SEARCH_SEARCH_SUCCESS';
export const METRIC_SEARCH_SEARCH_ERROR = 'METRIC_SEARCH_SEARCH_ERROR';

export const METRIC_SEARCH_ORDER_BY_UPDATE = 'METRIC_SEARCH_ORDER_BY_UPDATE';
export const METRIC_SEARCH_FILTER_BY_UPDATE = 'METRIC_SEARCH_FILTER_BY_UPDATE';
export const METRIC_SEARCH_TERM_CHANGED = 'METRIC_SEARCH_TERM_CHANGED';

export const METRIC_SEARCH_SELECTION_CHANGED = 'METRIC_SEARCH_SELECTION_CHANGED';
export const METRIC_SEARCH_SELECTION_REPLACE = 'METRIC_SEARCH_SELECTION_REPLACE';

export const METRIC_SEARCH_SET_TAG_REMOVE_TERM = 'METRIC_SEARCH_SET_TAG_REMOVE_TERM';

export const METRIC_SEARCH_SELECTION_REMOVE = 'METRIC_SEARCH_SELECTION_REMOVE';

export const METRIC_SEARCH_ALL_INPUTS_UPDATE = 'METRIC_SEARCH_ALL_INPUTS_UPDATE';

export const METRIC_SEARCH_RESULT_REQUEST = 'METRIC_SEARCH_RESULT_REQUEST';
export const METRIC_SEARCH_RESULT_SUCCESS = 'METRIC_SEARCH_RESULT_SUCCESS';
export const METRIC_SEARCH_RESULT_ERROR = 'METRIC_SEARCH_RESULT_ERROR';

export const METRIC_SEARCH_FILTER_BY_UPDATE_RESET_REST = 'METRIC_SEARCH_FILTER_BY_UPDATE_RESET_REST';
export const METRIC_SEARCH_EMPTY_SELECTION = 'METRIC_SEARCH_EMPTY_SELECTION';

export const metricSearchResultRequest = (type, id) => ({
    type: METRIC_SEARCH_RESULT_REQUEST,
    payload: { type, id }
});

export const metricSearchResultSuccess = (type, id, resultsForEntities) => ({
    type: METRIC_SEARCH_RESULT_SUCCESS,
    payload: { type, id, resultsForEntities }
});

export const metricSearchResultError = (type, id, error) => ({
    type: METRIC_SEARCH_RESULT_ERROR,
    payload: { type, id, error }
});

export const metricSearchSearchRequest = (metricSearchInput) => ({
    type: METRIC_SEARCH_SEARCH_REQUEST,
    payload: { metricSearchInput }
});

export const metricSearchSearchSuccess = (metricSearchInput, results, resultsForEntities) => ({
    type: METRIC_SEARCH_SEARCH_SUCCESS,
    payload: { metricSearchInput, results, resultsForEntities }
});

export const metricSearchSearchError = (metricSearchInput, error) => ({
    type: METRIC_SEARCH_SEARCH_ERROR,
    payload: { metricSearchInput, error }
});

export const metricSearchOrderByUpdate = (orderBy) => ({
    type: METRIC_SEARCH_ORDER_BY_UPDATE,
    payload: { orderBy }
});

export const metricSearchFilterByUpdate = (filterBy) => ({
    type: METRIC_SEARCH_FILTER_BY_UPDATE,
    payload: { filterBy }
});

export const metricSearchSelectionChanged = (selectedTags) => ({
    type: METRIC_SEARCH_SELECTION_CHANGED,
    payload: { selectedTags }
});

export const metricSearchTermChanged = (term) => ({
    type: METRIC_SEARCH_TERM_CHANGED,
    payload: { term }
});

export const metricSearchSelectionReplace = (selectedTag) => ({
    type: METRIC_SEARCH_SELECTION_REPLACE,
    payload: { selectedTag }
});

export const metricSearchSelectionRemove = (selectedTag) => ({
    type: METRIC_SEARCH_SELECTION_REMOVE,
    payload: { selectedTag }
});

export const metricSearchAllInputsUpdate = ({
    selectedTags, orderBy, filterBy, term
}) => ({
    type: METRIC_SEARCH_ALL_INPUTS_UPDATE,
    payload: {
        selectedTags, orderBy, filterBy, term
    }
});

export const metricSearchSetTagUnsetTerm = (selectedTag, filterBy = false) => ({
    type: METRIC_SEARCH_SET_TAG_REMOVE_TERM,
    payload: {
        selectedTag, filterBy
    }
});

export const metricSearchFilterByUpdateResetRest = (filterBy) => ({
    type: METRIC_SEARCH_FILTER_BY_UPDATE_RESET_REST,
    payload: { filterBy }
});

export const metricSearchEmptySelection = () => ({
    type: METRIC_SEARCH_EMPTY_SELECTION
});
