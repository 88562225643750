import { CLEAN_UP_STICKY_HEADER, UPDATE_STICKY_HEADER } from 'src/actions/stickyHeader';
import _omit from 'lodash/omit';

export function stickyHeader(state = {}, action) {
    const { type, payload } = action;
    switch (type) {
        case UPDATE_STICKY_HEADER: {
            const { identifier, showSticky } = payload;
            if (state[identifier] !== showSticky) {
                return Object.assign({}, state, { [identifier]: showSticky });
            }
            return state;
        }
        case CLEAN_UP_STICKY_HEADER: {
            const { identifier } = payload;
            return _omit(state, identifier);
        }
        default:
            return state;
    }
}
