import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import { makeSelectPostTagRulesByPostTagId } from 'src/selectors/postTagRules';
import { hasAllPostTagRulesJobCompleted } from 'src/utils/postTagRule';
import PostTagAutomationStateControl from 'src/components/views/postTags/postTagManager/PostTagAutomationStateControl';
import withTooltip from 'src/components/withToolTip';
import IcomoonIcon from 'src/components/IcomoonIcon';
import styles from 'src/stylesheets/views/postTags/postTagManager/postTagAutomationState.scss';
import _get from 'lodash/get';

const IcomoonIconIconWithTooltip = withTooltip(IcomoonIcon);

const PostTagAutomationState = (props) => {
    const { postTagRules, postTag } = props;
    if (postTagRules.length === 0) {
        return <div><div className={styles.emptyRules} /></div>;
    }
    const allAutomationJobCompleted = hasAllPostTagRulesJobCompleted(postTagRules.map((postTagRule) => _get(postTagRule, 'dateSelection.to')));
    if (allAutomationJobCompleted) {
        return (
            <IcomoonIconIconWithTooltip
              icon="success"
              tooltip="This automation has reached the specified end time."
              className={styles.completed}
            />
        );
    }
    return <PostTagAutomationStateControl postTag={postTag} />;
};

PostTagAutomationState.propTypes = {
    postTag: customPropTypes.postTag.isRequired,
    postTagRules: customPropTypes.postTagRules.isRequired
};

const makeMapStateToProps = () => {
    const selectPostTagRulesByPostTagId = makeSelectPostTagRulesByPostTagId();
    return (state, ownProps) => ({
        postTagRules: selectPostTagRulesByPostTagId(state, ownProps.postTag.id)
    });
};

export default connect(makeMapStateToProps)(PostTagAutomationState);
