import { connect } from 'react-redux';
import { selectAutoCompleteSuggestionsByTerm } from 'src/selectors/metricSearch';

const withDiscoverAutocomplete = (WrappedComponent) => {
    const mapStateToProps = (state, ownProps) => ({
        autocompleteSuggestions: selectAutoCompleteSuggestionsByTerm(state, ownProps.searchTerm)
    });
    return connect(mapStateToProps)(WrappedComponent);
};

export default withDiscoverAutocomplete;
