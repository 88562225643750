import React, { Component } from 'react';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import sizeMe from 'react-sizeme';
import styles from 'src/stylesheets/expandableContent.scss';

const ImageWithSize = sizeMe({ monitorHeight: true, noPlaceholder: true })((props) => <div><img {...props} /></div>);

class ExpandableImage extends Component {
    constructor(props) {
        super(props);
        this.expandContent = this.expandContent.bind(this);
        this.onSize = this.onSize.bind(this);
        this.state = {
            expanded: false,
            expandable: true,
            imageHeight: props.minHeight
        };
    }

    onSize(size) {
        const { props } = this;
        const { minHeight } = props;
        const { height, width } = size;
        if (height > 0 && width > 0) {
            if (height > minHeight) {
                this.setState({
                    expandable: true,
                    imageHeight: height
                });
            } else {
                this.setState({
                    expandable: false,
                    expanded: false,
                    imageHeight: height
                });
            }
        }
    }

    expandContent() {
        const { expanded } = this.state;
        this.setState({
            expanded: !expanded
        });
    }

    render() {
        const {
            src,
            minHeight,
            alt
        } = this.props;
        const { expanded, imageHeight, expandable } = this.state;
        const label = expanded ? 'View less' : 'View more';
        const icon = expanded ? 'chevron-big-up' : 'chevron-big-down';
        const calculatedMinHeight = imageHeight < minHeight ? imageHeight : minHeight;
        const style = !expanded ? { maxHeight: calculatedMinHeight, minHeight: calculatedMinHeight } : { maxHeight: imageHeight + 500 };

        return (
            <div className={styles.wrapper}>
                <div className={styles.body} style={style}>
                    <ImageWithSize
                      alt={alt}
                      src={src}
                      className={styles.content}
                      onSize={this.onSize}
                    />
                </div>
                {
                    expandable
                    && (
                    <div className={styles.expander}>
                        <div className={styles.button} onClick={this.expandContent}>
                            <IcomoonIcon icon={icon} className={styles.icon} />
                            <span className={styles.buttonLabel}>{label}</span>
                        </div>
                    </div>
                    )
                }
            </div>
        );
    }
}

ExpandableImage.propTypes = {
    minHeight: PropTypes.number,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
};

ExpandableImage.defaultProps = {
    minHeight: 400
};

export default ExpandableImage;
