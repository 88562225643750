import React, { PureComponent } from 'react';
import AnalysisMetricListItem from 'src/components/views/analysis/AnalysisMetricListItem';
import AutocompleteListItem from 'src/components/autocomplete/layout/AutocompleteListItem';
import AutocompleteLoadingList from 'src/components/autocomplete/layout/AutocompleteLoadingList';
import List from 'src/components/List';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/autocompletePopover.scss';
import withMetricFromSearchResults from 'src/components/views/analysis/withMetricFromSearchResults';

const AnalysisMetricListItemWithStoreConnection = withMetricFromSearchResults(AnalysisMetricListItem);

const renderMenuItemChildren = (item, active, onClick) => (
    <AutocompleteListItem key={item} active={active} onClick={onClick}>
        <AnalysisMetricListItemWithStoreConnection
          id={item}
        />
    </AutocompleteListItem>
);

class AnalysisSearchAutocomplete extends PureComponent {
    render() {
        const {
            filteredDashboardAndFolderIds, activeIndex, onItemClick, loading
        } = this.props;
        return (
            <div className={styles.wrapper}>
                {
                    !loading && filteredDashboardAndFolderIds.length > 0
                        && (
                        <div className={styles.searchResults}>
                            <List border={false}>
                                {
                                    filteredDashboardAndFolderIds.map((item, idx) => renderMenuItemChildren(
                                        item,
                                        idx === activeIndex,
                                        () => { onItemClick(item); }
                                    ))
                                }
                            </List>
                        </div>
                        )
                }
                {
                    !loading && filteredDashboardAndFolderIds.length < 1
                        && (
                        <div className={styles.noSearchResults}>
                            No search results found.<br />Try searching again using a different spelling or keyword.
                        </div>
                        )
                }
                {
                    loading
                    && <AutocompleteLoadingList />
                }
            </div>
        );
    }
}

AnalysisSearchAutocomplete.propTypes = {
    activeIndex: PropTypes.number.isRequired,
    onItemClick: PropTypes.func.isRequired,
    filteredDashboardAndFolderIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    loading: PropTypes.bool.isRequired
};

export default AnalysisSearchAutocomplete;
