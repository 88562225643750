import React from 'react';
import PropTypes from 'prop-types';
import { getPostIdsByCreateTimes } from 'src/utils/postSearch';
import { makeSelectPostByIds } from 'src/selectors/posts';
import { connect } from 'react-redux';
import PostTilesByCreateTime from 'src/components/views/postTags/postTagging/PostTilesByCreateTime';
import * as customPropTypes from 'src/customPropTypes';
import memoizeOne from 'memoize-one';

const memoizeGetPostIdsByCreateTimes = memoizeOne(getPostIdsByCreateTimes);

const sectionStyle = {
    paddingBottom: '32px'
};

const PostFloatingListTiles = (props) => {
    const { posts, listName, isPending } = props;
    const postsByCreateTimes = memoizeGetPostIdsByCreateTimes(posts);
    return Object.keys(postsByCreateTimes).map((createTime) => (
        <div style={sectionStyle} key={createTime}>
            <PostTilesByCreateTime
              createTime={createTime}
              timezone={postsByCreateTimes[createTime].timezone}
              postIds={postsByCreateTimes[createTime].postIds}
              disabled={isPending}
              listName={listName}
            />
        </div>
    ));
};

const makeMapStateToProps = () => {
    const selectPostByIds = makeSelectPostByIds();
    return (state, ownProps) => ({
        posts: selectPostByIds(state, ownProps.postIds)
    });
};

PostFloatingListTiles.propTypes = {
    posts: customPropTypes.posts.isRequired,
    postIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    listName: PropTypes.string.isRequired,
    isPending: PropTypes.bool.isRequired
};

export default connect(makeMapStateToProps)(PostFloatingListTiles);
