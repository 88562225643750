import { modalShowPostDetailInPostTagging } from 'src/actions/overlays';
import PostPostTagListInPostTile from 'src/components/views/postTags/postPostTagList/PostPostTagListInPostTile';
import _get from 'lodash/get';
import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import ListHandlingCheckbox from 'src/components/bulkList/ListHandlingCheckbox';
import styles from 'src/stylesheets/views/postTags/postTagging/postTile.scss';
import classnames from 'classnames';
import PostTileBody from 'src/components/views/postTags/postTagging/tile/PostTileBody';
import PostTileBorder from 'src/components/views/postTags/postTagging/tile/PostTileBorder';
import { connect } from 'react-redux';
import { makeSelectPostById } from 'src/selectors/posts';
import { POST_TAGGING } from 'src/utils/filterSelectors';
import { makeSelectPostTextByContext } from 'src/selectors/filterSelectors';

const PostTile = (props) => {
    const {
        post, listName, disabled, postTextFilter, modalShowPostDetailAction
    } = props;
    const { id } = post;

    const postTextValues = _get(postTextFilter, 'values');

    return (
        <div className={classnames(styles.wrapper, { [styles.disabled]: disabled })}>
            <div className={styles.checkbox}>
                <ListHandlingCheckbox listName={listName} name={id} disabled={disabled} />
            </div>
            <PostTileBorder postId={post.id} listName={listName}>
                <PostTileBody
                  highlightWords={postTextValues}
                  post={post}
                  onClick={modalShowPostDetailAction}
                  renderFooter={(postFromComponent) => <PostPostTagListInPostTile post={postFromComponent} listName={listName} />}
                />
            </PostTileBorder>
        </div>
    );
};

PostTile.propTypes = {
    listName: PropTypes.string.isRequired,
    postId: PropTypes.string.isRequired,
    post: customPropTypes.post.isRequired,
    disabled: PropTypes.bool.isRequired,
    postTextFilter: customPropTypes.postTextFilter.isRequired,
    modalShowPostDetailAction: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const selectPostById = makeSelectPostById();
    const selectPostTextByContext = makeSelectPostTextByContext();

    return (state, ownProps) => ({
        post: selectPostById(state, ownProps.postId),
        postTextFilter: selectPostTextByContext(state, POST_TAGGING)
    });
};

export default connect(makeMapStateToProps, {
    modalShowPostDetailAction: modalShowPostDetailInPostTagging
})(PostTile);
