import {
    DASHBOARD_METRICS_ADD_REQUEST,
    DASHBOARD_METRICS_DELETE_REQUEST,
    DASHBOARD_METRICS_UPDATE_LAYOUT_REQUEST,
    DASHBOARD_METRICS_UPDATE_SETTINGS_REQUEST,
    DASHBOARD_METRIC_CLONE_REQUEST,
    DASHBOARD_METRIC_MOVE_REQUEST,
    dashboardMetricsAddError,
    dashboardMetricsAddSuccess,
    dashboardMetricsDeleteError,
    dashboardMetricsDeleteSuccess,
    dashboardMetricsUpdateLayoutError,
    dashboardMetricsUpdateLayoutSuccess,
    dashboardMetricsUpdateSettingsError,
    dashboardMetricsUpdateSettingsSuccess,
    dashboardMetricCloneError,
    dashboardMetricCloneSuccess,
    dashboardMetricMoveError,
    dashboardMetricMoveSuccess,
    DASHBOARD_METRICS_UPDATE_NOTES_REQUEST,
    dashboardMetricsUpdateNotesSuccess,
    dashboardMetricsUpdateNotesError
} from 'src/actions/dashboardMetrics';
import {
    put, select, takeEvery
} from 'redux-saga/effects';
import {
    modalHideMetricSettingsTile, modalsHideDashboardMetricDelete, modalHideCloneDashboardMetric, modalHideMoveDashboardMetric
} from 'src/actions/overlays';
import { parseDashboardMetric, parseDashboardMetricIds, parseMetric } from 'src/parsers';
import { selectDashboardById, selectDashboardLayoutById } from 'src/selectors/dashboards';
import { makeSelectDashboardMetricById, selectDashboardMetricLayoutById } from 'src/selectors/dashboardMetrics';
import { showDashboardMetricsAddSuccessNotification, showNotification } from 'src/actions/notifications';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _parseInt from 'lodash/parseInt';
import _get from 'lodash/get';
import { calculateNewDashboardMetricPosition } from 'src/components/DashboardGrid';
import createServerRequest from 'src/requestHandling/createServerRequest';
import { handleAuthorizedServerRequest } from 'src/sagas/utils';
import { metricUsageCountUpdateRequest } from 'src/actions/metric';
import { selectDashboardTypeById } from 'src/selectors/ownership';
import { reportError } from 'src/utils/reportError';
import { SubmissionError } from 'redux-form';
import { setEditMode } from 'src/reducers/slices/dashboardMetricNoteEdit';

const selectDashboardMetricById = makeSelectDashboardMetricById();

function* dashboardMetricsDeleteRequest(action) {
    const { dashboardId, dashboardMetricId, metricId } = action.payload;
    const dashboardType = yield select(selectDashboardTypeById, dashboardId);
    try {
        const serverRequest = createServerRequest({ dashboardMetricId, dashboardId, dashboardType });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/delete-dashboard-metric');
        if (response) {
            yield put(dashboardMetricsDeleteSuccess(dashboardId, dashboardMetricId, metricId));
            yield put(metricUsageCountUpdateRequest([metricId]));
            yield put(modalsHideDashboardMetricDelete(dashboardMetricId));
            yield put(showNotification('Your dashboard metric was successfully deleted'));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(modalsHideDashboardMetricDelete(dashboardMetricId));
        yield put(dashboardMetricsDeleteError(dashboardId, dashboardMetricId, applicationOrServerError));
        yield put(showNotification(applicationOrServerError.message, 'error'));
    }
}

function createDashboardMetricSettingsParameters(dashboardMetricId, settings) {
    let settingProfileSelection = {
        groupIds: [],
        profileIds: []
    };

    if (settings.settingProfileSelection) {
        settingProfileSelection = settings.settingProfileSelection;
    }

    const dateSelection = Object.assign({}, settings.settingDateSelection);
    if (!_has(dateSelection, 'from')) {
        dateSelection.from = null;
    }
    if (!_has(dateSelection, 'to')) {
        dateSelection.to = null;
    }

    const params = {
        dashboardMetricId,
        bindedToProfileSelector: settings.isCustomProfileSelected ? 'N' : 'Y',
        bindedToDateSelector: settings.isCustomDateSelected ? 'N' : 'Y',
        bindedToAdditionalFilterSelector: settings.isCustomAdditionalFilterSelected ? 'N' : 'Y',
        notes: settings.notes,
        settingDateSelection: JSON.stringify(dateSelection),
        settingProfileSelection: JSON.stringify(settingProfileSelection),
        settingAdditionalFilterSelection: JSON.stringify(settings.settingAdditionalFilterSelection),
        inheritTimezone: settings.inheritTimezone
    };

    const { settingAdditional, alias, visualizationId } = settings;
    if (!_isEmpty(settingAdditional) || alias || visualizationId) {
        const mergedSettingAdditional = {};
        if (!_isEmpty(settingAdditional)) {
            Object.assign(mergedSettingAdditional, settingAdditional);
        }
        if (alias) {
            mergedSettingAdditional.alias = alias;
        }
        if (visualizationId) {
            mergedSettingAdditional.visualizationId = visualizationId;
        }
        params.settingAdditional = JSON.stringify(mergedSettingAdditional);
    }

    return params;
}

function* dashboardMetricUpdateSettingsRequest(action) {
    const {
        isCustomDateSelected,
        settingProfileSelection,
        isCustomProfileSelected,
        settingDateSelection,
        notes,
        hideColumns,
        limit,
        sortBy,
        sortDir,
        dashboardMetricId,
        alias,
        visualizationId,
        isCustomAdditionalFilterSelected,
        settingAdditionalFilterSelection,
        dashboardId,
        inheritTimezone
    } = action.payload.data;

    const { metricName, defaultVisualizationId } = action.payload;

    const newSettings = {
        isCustomDateSelected,
        settingProfileSelection,
        isCustomProfileSelected,
        settingDateSelection,
        isCustomAdditionalFilterSelected,
        settingAdditionalFilterSelection,
        notes,
        alias,
        inheritTimezone
    };

    if (visualizationId && defaultVisualizationId !== visualizationId) {
        newSettings.visualizationId = visualizationId;
    } else {
        newSettings.visualizationId = null;
    }

    const settingAdditional = {};

    if (limit && _parseInt(limit) !== 0) {
        settingAdditional.limit = _parseInt(limit);
    }
    if (hideColumns) {
        settingAdditional.hideColumns = hideColumns;
    }
    if (sortBy) {
        settingAdditional.sortBy = sortBy;
    }
    if (sortDir) {
        settingAdditional.sortDir = sortDir;
    }

    Object.assign(newSettings, { settingAdditional });

    const dashboardType = yield select(selectDashboardTypeById, dashboardId);

    try {
        const params = createDashboardMetricSettingsParameters(dashboardMetricId, newSettings);
        Object.assign(params, { dashboardId, dashboardType });

        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/save-dashboard-metric-settings');
        if (response) {
            const { jsonData } = response;
            const { dashboardMetric } = jsonData;
            const parsedDashboardMetric = parseDashboardMetric(dashboardMetric);
            const { settings } = parsedDashboardMetric;
            yield put(dashboardMetricsUpdateSettingsSuccess(dashboardMetricId, settings));
            yield put(modalHideMetricSettingsTile(dashboardMetricId));
            yield put(showNotification(`Metric settings of '${metricName}' were successfully updated.`));
        }
        if (serverError) {
            yield put(dashboardMetricsUpdateSettingsError(new SubmissionError({ _error: serverError })));
        }
    } catch (applicationError) {
        reportError(applicationError);
        yield put(dashboardMetricsUpdateSettingsError(new SubmissionError({ _error: applicationError })));
    }
}

function* dashboardMetricsUpdateLayoutRequest(action) {
    const { dashboardId, newLayout } = action.payload;
    const dashboardType = yield select(selectDashboardTypeById, dashboardId);

    try {
        // Add + 1 here to server
        const widgetData = newLayout.map((singleLayout) => (
            {
                dashboardMetricId: singleLayout.i,
                column: singleLayout.x + 1,
                row: singleLayout.y + 1,
                size_x: singleLayout.w,
                size_y: singleLayout.h
            }
        ));
        const serverRequest = createServerRequest({ dashboardId, widgetData: JSON.stringify(widgetData), dashboardType });
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/save-dashboard-metric-layout');

        if (response) {
            const { jsonData } = response;
            const { orderedDashboardWidgetIds } = jsonData;
            yield put(dashboardMetricsUpdateLayoutSuccess(dashboardId, newLayout, parseDashboardMetricIds(orderedDashboardWidgetIds)));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(dashboardMetricsUpdateLayoutError(dashboardId, newLayout, applicationOrServerError));
        yield put(showNotification('Something went wrong when positioning the metric on the dashboard.'
            + ' Please reload the dashboard as it might be in an inconsistent state.', 'error'));
    }
}

function* dashboardMetricsAddRequest(action) {
    const { dashboardId, metricId, isTable } = action.payload;
    const dashboardMetricLayouts = yield select(selectDashboardLayoutById, dashboardId);
    const dashboard = yield select(selectDashboardById, dashboardId);
    const dashboardType = yield select(selectDashboardTypeById, dashboardId);
    const position = calculateNewDashboardMetricPosition(dashboardMetricLayouts, dashboard, isTable);
    const {
        width, height, col, row
    } = position;
    try {
        // send +1 to server
        const serverRequest = createServerRequest({
            dashboardId: dashboard.id,
            dashboardType,
            metricId,
            col: col + 1,
            row: row + 1,
            width,
            height
        });

        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/add-dashboard-metric');

        if (response) {
            const { jsonData } = response;
            const { metric, dashboardMetric } = jsonData;
            const parsedDashboardMetric = parseDashboardMetric(dashboardMetric);
            const parsedMetric = parseMetric(metric);

            yield put(dashboardMetricsAddSuccess(dashboardId, parsedDashboardMetric, parsedMetric));

            // update metric usage
            yield put(metricUsageCountUpdateRequest([metricId]));
            yield put(showDashboardMetricsAddSuccessNotification(dashboard.id, dashboard.name, metric.name, dashboardMetric.id));
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(dashboardMetricsAddError(dashboard.id, applicationOrServerError));
        yield put(showNotification(applicationOrServerError.message, 'error'));
    }
}

function* dashboardMetricCloneRequest(action) {
    const {
        destinationDashboardId, sourceDashboardId, dashboardMetricId, alias
    } = action.payload;
    const dashboard = yield select(selectDashboardById, destinationDashboardId);
    const currentDashboardMetric = yield select(selectDashboardMetricById, dashboardMetricId);
    const sourceDashboardType = yield select(selectDashboardTypeById, sourceDashboardId);
    const destinationDashboardType = yield select(selectDashboardTypeById, destinationDashboardId);
    const {
        row, col, height, width
    } = yield select(selectDashboardMetricLayoutById, dashboardMetricId);
    const params = {
        sourceDashboardId,
        sourceDashboardType,
        destinationDashboardId,
        destinationDashboardType,
        dashboardMetricId,
        alias,
        row,
        col,
        height,
        width
    };
    if (destinationDashboardId !== currentDashboardMetric.dashboardId) {
        const visualizationType = _get(currentDashboardMetric, 'metric.visualization.type', '');
        const dashboardMetricLayouts = yield select(selectDashboardLayoutById, destinationDashboardId);
        const dashboardMetricLayout = calculateNewDashboardMetricPosition(dashboardMetricLayouts, dashboard, visualizationType === 'table');
        params.row = dashboardMetricLayout.row;
        params.col = dashboardMetricLayout.col;
        params.height = dashboardMetricLayout.height;
        params.width = dashboardMetricLayout.width;
    }
    params.row += 1;
    params.col += 1;
    try {
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/clone-dashboard-metric');
        if (response) {
            const { jsonData } = response;
            const { dashboardMetric } = jsonData;
            const parsedDashboardMetric = parseDashboardMetric(dashboardMetric);
            const metricName = alias || _get(currentDashboardMetric, 'metric.name');
            yield put(dashboardMetricCloneSuccess(parsedDashboardMetric));
            yield put(modalHideCloneDashboardMetric());
            if (destinationDashboardId !== currentDashboardMetric.dashboardId) {
                yield put(showDashboardMetricsAddSuccessNotification(dashboard.id, dashboard.name, metricName, dashboardMetric.id));
            }
        }

        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(dashboardMetricCloneError(new SubmissionError({ _error: applicationError })));
    }
}

function* dashboardMetricMoveRequest(action) {
    const {
        dashboardMetricId, sourceDashboardId, destinationDashboardId
    } = action.payload;
    const destinationDashboard = yield select(selectDashboardById, destinationDashboardId);
    const currentDashboardMetric = yield select(selectDashboardMetricById, dashboardMetricId);
    const visualizationType = _get(currentDashboardMetric, 'metric.visualization.type', '');
    const dashboardMetricLayouts = yield select(selectDashboardLayoutById, destinationDashboardId);
    const sourceDashboardType = yield select(selectDashboardTypeById, sourceDashboardId);
    const destinationDashboardType = yield select(selectDashboardTypeById, destinationDashboardId);

    const params = {
        dashboardMetricId,
        sourceDashboardId,
        sourceDashboardType,
        destinationDashboardId,
        destinationDashboardType
    };
    const {
        row, col, height, width
    } = calculateNewDashboardMetricPosition(dashboardMetricLayouts, destinationDashboard, visualizationType === 'table');
    params.row = row + 1;
    params.col = col + 1;
    params.height = height;
    params.width = width;
    try {
        const serverRequest = createServerRequest(params);
        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/move-dashboard-metric');
        if (response) {
            const { jsonData } = response;
            const { dashboardMetric } = jsonData;
            const parsedDashboardMetric = parseDashboardMetric(dashboardMetric);
            const metricName = _get(currentDashboardMetric, 'metric.name');
            yield put(modalHideMoveDashboardMetric());
            yield put(dashboardMetricMoveSuccess(parsedDashboardMetric, sourceDashboardId, dashboardMetricId));
            yield put(showDashboardMetricsAddSuccessNotification(destinationDashboard.id, destinationDashboard.name, metricName, dashboardMetric.id));
        }

        if (serverError) {
            throw serverError;
        }
    } catch (applicationError) {
        yield put(dashboardMetricMoveError(applicationError));
        yield put(showNotification(applicationError.message, 'error'));
    }
}

function* dashboardMetricUpdateNotesRequest(action) {
    const {
        notes,
        dashboardMetricId,
        dashboardId,
    } = action.payload;

    const dashboardType = yield select(selectDashboardTypeById, dashboardId);

    try {
        const serverRequest = createServerRequest({
            dashboardId, dashboardType, notes, dashboardMetricId
        });

        const { response, serverError } = yield handleAuthorizedServerRequest(serverRequest, '/client-widget/save-dashboard-metric-notes');
        if (response) {
            const { jsonData } = response;
            const { dashboardMetric } = jsonData;
            const parsedDashboardMetric = parseDashboardMetric(dashboardMetric);
            const { settings } = parsedDashboardMetric;
            yield put(dashboardMetricsUpdateNotesSuccess(dashboardMetricId, settings));
            if (notes.length > 0) {
                yield put(setEditMode(dashboardMetricId, false));
            }
        }
        if (serverError) {
            throw serverError;
        }
    } catch (applicationOrServerError) {
        reportError(applicationOrServerError);
        yield put(dashboardMetricsUpdateNotesError(dashboardMetricId));
        yield put(showNotification('Something went wrong. Please try again.', 'error'));
    }
}

export default function* dashboardMetricsSagas() {
    yield takeEvery(DASHBOARD_METRICS_DELETE_REQUEST, dashboardMetricsDeleteRequest);
    yield takeEvery(DASHBOARD_METRICS_UPDATE_LAYOUT_REQUEST, dashboardMetricsUpdateLayoutRequest);
    yield takeEvery(DASHBOARD_METRICS_ADD_REQUEST, dashboardMetricsAddRequest);
    yield takeEvery(DASHBOARD_METRIC_CLONE_REQUEST, dashboardMetricCloneRequest);
    yield takeEvery(DASHBOARD_METRIC_MOVE_REQUEST, dashboardMetricMoveRequest);
    yield takeEvery(DASHBOARD_METRICS_UPDATE_SETTINGS_REQUEST, dashboardMetricUpdateSettingsRequest);
    yield takeEvery(DASHBOARD_METRICS_UPDATE_NOTES_REQUEST, dashboardMetricUpdateNotesRequest);
}
