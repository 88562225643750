import {
    RECOMMENDATIONS_LOAD_ERROR,
    RECOMMENDATIONS_LOAD_REQUEST,
    RECOMMENDATIONS_LOAD_SUCCESS,
    RECOMMENDATIONS_SEARCH_LOAD_ERROR,
    RECOMMENDATIONS_SEARCH_LOAD_REQUEST,
    RECOMMENDATIONS_SEARCH_LOAD_SUCCESS
} from 'src/actions/recommendations';

export const initialState = { loading: true, data: null, error: null };

function results(state = {}, action) {
    const { payload, type } = action;
    switch (type) {
        case RECOMMENDATIONS_SEARCH_LOAD_REQUEST:
        case RECOMMENDATIONS_LOAD_REQUEST:
            return Object.assign({}, state, { [payload.identifier]: initialState });
        case RECOMMENDATIONS_SEARCH_LOAD_SUCCESS:
        case RECOMMENDATIONS_LOAD_SUCCESS: {
            const { results: recommendationResults } = payload;
            const resultIds = recommendationResults.map((recommendationResult) => ({
                type: recommendationResult.type,
                id: recommendationResult.id
            }));
            return Object.assign({}, state, {
                [payload.identifier]: {
                    loading: false,
                    data: resultIds,
                    error: null
                }
            });
        }
        case RECOMMENDATIONS_SEARCH_LOAD_ERROR:
        case RECOMMENDATIONS_LOAD_ERROR: {
            return Object.assign({}, state, {
                [payload.identifier]: {
                    loading: false, data: null, error: payload.error
                }
            });
        }
        default:
            return state;
    }
}

export const recommendations = results;
