import Button from 'src/components/buttons/Button';
import AdAccountOptionsPopover from 'src/components/popovers/AdAccountOptionsPopover';
import AdAccountInfoListItem from 'src/components/views/adAccounts/AdAccountInfoListItem';
import AdAccountGroupItemList from 'src/components/views/adAccounts/AdAccountGroupItemList';
import React, { useRef } from 'react';
import AccountListItem from 'src/components/account/layout/AccountListItem';
import ListHandlingCheckbox from 'src/components/bulkList/ListHandlingCheckbox';
import withPopover from 'src/components/withPopover';
import * as customPropTypes from 'src/customPropTypes';
import PropTypes from 'prop-types';
import AccountPrivateStats from 'src/components/AccountPrivateStats';
import AccountUseCasePopover from 'src/components/popovers/AccountUseCasePopover';
import AccountTypeContext from 'src/components/context/AccountTypeContext';

const AccountPrivateStatsWithPopover = withPopover(AccountPrivateStats);

const contextValues = { accountType: 'adAccounts', isDetailPage: false };

const ButtonWithPopover = withPopover(Button);

const AdAccountListItem = (props) => {
    const {
        adAccount, listName, modalsShowAdAccountDetailsAction, highlight, buttonsDisabled
    } = props;
    const privateStatsButtonRef = useRef();
    const adAccountUseCasesPopover = <AccountUseCasePopover account={adAccount} accountType="adAccounts" />;

    return (
        <AccountListItem>
            <AccountListItem.LeftColumn>
                <AccountListItem.CheckBoxWrapper>
                    <ListHandlingCheckbox
                      name={adAccount.id}
                      listName={listName}
                    />
                </AccountListItem.CheckBoxWrapper>
                <AccountListItem.AccountInfoWrapper onClick={() => {
                    modalsShowAdAccountDetailsAction(adAccount.id);
                }}
                >
                    <AdAccountInfoListItem adAccount={adAccount} highlight={highlight} showThumbnail={false} />
                </AccountListItem.AccountInfoWrapper>
            </AccountListItem.LeftColumn>
            <AccountListItem.RightColumn>
                <AccountListItem.Analysis>
                    <AccountTypeContext.Provider value={contextValues}>
                        <AccountPrivateStatsWithPopover
                          overlay={adAccountUseCasesPopover}
                          account={adAccount}
                          ref={privateStatsButtonRef}
                        />
                    </AccountTypeContext.Provider>
                </AccountListItem.Analysis>
                <AccountListItem.Groups>
                    <AdAccountGroupItemList adAccountId={adAccount.id} disabled={buttonsDisabled} />
                </AccountListItem.Groups>
                <AccountListItem.ButtonWrapper>
                    <ButtonWithPopover
                      small
                      icon="more"
                      overlay={<AdAccountOptionsPopover adAccountId={adAccount.id} />}
                      disabled={buttonsDisabled}
                      placement="bottom-end"
                      className="list-hover-button-opacity"
                    />
                </AccountListItem.ButtonWrapper>
            </AccountListItem.RightColumn>
        </AccountListItem>
    );
};

AdAccountListItem.propTypes = {
    adAccount: customPropTypes.adAccount.isRequired,
    listName: PropTypes.string.isRequired,
    modalsShowAdAccountDetailsAction: PropTypes.func.isRequired,
    highlight: PropTypes.string,
    buttonsDisabled: PropTypes.bool
};

AdAccountListItem.defaultProps = {
    highlight: '',
    buttonsDisabled: false
};

export default AdAccountListItem;
