import AppLogo from 'src/components/AppLogo';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import quintlyLogo from 'src/resources/images/facelift-logo.svg';
import React from 'react';
import styles from 'src/stylesheets/navigations/navLogo.scss';

const NavLogo = (props) => {
    const { to } = props;
    return (
        <Link to={to} className={styles.link}>
            <AppLogo logoUrl={quintlyLogo} />
        </Link>
    );
};

NavLogo.propTypes = {
    to: PropTypes.string.isRequired,
};

export default NavLogo;
