export const SAMPLE_SERVER_DATA_REQUEST = 'SAMPLE_SERVER_DATA_REQUEST';
export const SAMPLE_SERVER_DATA_SUCCESS = 'SAMPLE_SERVER_DATA_SUCCESS';
export const SAMPLE_SERVER_DATA_ERROR = 'SAMPLE_SERVER_DATA_ERROR';

export const sampleServerDataRequest = (metricId, visualizationId) => ({
    type: SAMPLE_SERVER_DATA_REQUEST,
    payload: {
        metricId,
        visualizationId
    }
});

export const sampleSeverDataSuccess = (metricId, visualizationId, data) => ({
    type: SAMPLE_SERVER_DATA_SUCCESS,
    payload: {
        metricId,
        visualizationId,
        data
    }
});

export const sampleServerDataError = (metricId, visualizationId, error) => ({
    type: SAMPLE_SERVER_DATA_ERROR,
    payload: {
        metricId,
        visualizationId,
        error
    }
});
