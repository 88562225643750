import { makeSelectDataPushTaskLatestLogIdByDataPushTaskId } from 'src/selectors/dataPushTasks';
import * as customPropTypes from 'src/customPropTypes';
import * as routeActions from 'react-router-redux';
import { connect } from 'react-redux';
import { makeSelectDataPushLogById } from 'src/selectors/dataPushTaskLogs';
import PropTypes from 'prop-types';
import React from 'react';
import CombinedDataPushTaskAndLogStatus from 'src/components/views/integrations/CombinedDataPushTaskAndLogStatus';
import Number from 'src/components/Number';
import ListTableCell from 'src/components/listTable/ListTableCell';
import ListTableRow from 'src/components/listTable/ListTableRow';
import withPopover from 'src/components/withPopover';
import Button from 'src/components/buttons/Button';
import DataPushTaskRowOptionsPopover from 'src/components/popovers/DataPushTaskRowOptionsPopover';
import DataPushTaskDestinationCells from 'src/components/views/integrations/dataPushTasks/dataPushTaskTable/DataPushTaskDestinationCells';

const ButtonWithPopover = withPopover(Button);

const DataPushTaskRow = (props) => {
    const { dataPushTask, pushAction, lastDataPushTaskLog } = props;
    const {
        isActive,
        destinationId,
        delivery
    } = dataPushTask;
    const numberOfRows = lastDataPushTaskLog ? lastDataPushTaskLog.rowInserted : 0;
    const isHealthy = lastDataPushTaskLog ? lastDataPushTaskLog.status === 'success' : true;

    const handleOnRowClick = () => {
        pushAction(`/data-push-tasks/${dataPushTask.id}`);
    };

    return (
        <ListTableRow disabled={!isActive}>
            <DataPushTaskDestinationCells
              dataPushTaskDestinationId={destinationId}
              onClick={handleOnRowClick}
              isHealthy={isHealthy}
            />
            <ListTableCell onClick={handleOnRowClick}>{delivery || <i>Delivery is disabled</i>}</ListTableCell>
            <ListTableCell onClick={handleOnRowClick}><CombinedDataPushTaskAndLogStatus dataPushTask={dataPushTask} dataPushTaskLog={lastDataPushTaskLog} /></ListTableCell>
            <ListTableCell rightContent={(
                <ButtonWithPopover
                  icon="more"
                  placement="bottom-end"
                  className="list-hover-button-opacity"
                  overlay={<DataPushTaskRowOptionsPopover dataPushTask={dataPushTask} />}
                />
            )}
            >
                <Number value={numberOfRows} />
            </ListTableCell>
        </ListTableRow>
    );
};

DataPushTaskRow.propTypes = {
    dataPushTask: customPropTypes.dataPushTask.isRequired,
    lastDataPushTaskLog: PropTypes.oneOfType([customPropTypes.dataPushTaskLog, PropTypes.bool]).isRequired,
    pushAction: PropTypes.func.isRequired
};

const makeMapStateToProps = () => {
    const selectDataPushTaskLatestLogIdByDataPushTaskId = makeSelectDataPushTaskLatestLogIdByDataPushTaskId();
    const selectDataPushLogById = makeSelectDataPushLogById();
    return (state, ownProps) => {
        const { dataPushTask } = ownProps;
        const dataPushTaskLogId = selectDataPushTaskLatestLogIdByDataPushTaskId(state, dataPushTask.id);
        const lastDataPushTaskLog = selectDataPushLogById(state, dataPushTaskLogId);
        return {
            dataPushTask,
            lastDataPushTaskLog
        };
    };
};

export default connect(makeMapStateToProps, {
    pushAction: routeActions.push
})(DataPushTaskRow);
