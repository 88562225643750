import * as customPropTypes from 'src/customPropTypes';
import EmptyCells from 'src/components/listTable/EmptyCells';
import FixBrokenAccountUseCasePopover from 'src/components/popovers/FixBrokenAccountUseCasePopover';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/authTransactions/authTransactionRequirementsTable.scss';
import UseCaseStatusLabel from 'src/components/labels/UseCaseStatusLabel';
import withPopover from 'src/components/withPopover';
import ListTableRow from 'src/components/listTable/ListTableRow';
import ListTableCell from 'src/components/listTable/ListTableCell';
import _includes from 'lodash/includes';
import AccountInfoListItemByAccountType from 'src/components/AccountInfoListItemByAccountType';
import { MAX_NUMBER_OF_COLUMNS } from 'src/utils/accountInsights';

const UseCaseStatusLabelWithPopover = withPopover(UseCaseStatusLabel);

const BrokenProfileUseCasesRow = (props) => {
    const {
        account,
        accountType,
        platformUseCases,
        invalidReasonsByUseCaseId
    } = props;
    const invalidUseCaseIds = Object.keys(invalidReasonsByUseCaseId);
    const emptyColumnLength = MAX_NUMBER_OF_COLUMNS - platformUseCases.length;
    return (
        <ListTableRow>
            <ListTableCell padded={false}><AccountInfoListItemByAccountType account={account} accountType={accountType} /></ListTableCell>
            {
                platformUseCases.map((platformUseCase) => {
                    const useCaseId = platformUseCase.id;
                    const isUseCaseInvalid = _includes(invalidUseCaseIds, useCaseId);
                    if (isUseCaseInvalid) {
                        const invalidReason = invalidReasonsByUseCaseId[useCaseId];
                        return (
                            <ListTableCell key={platformUseCase.id}>
                                <div className={styles.useCase}>
                                    <UseCaseStatusLabelWithPopover
                                      valid={false}
                                      invalidReason={invalidReason}
                                      overlay={(
                                          <FixBrokenAccountUseCasePopover
                                            account={account}
                                            accountType={accountType}
                                            useCaseId={useCaseId}
                                            invalidReason={invalidReason}
                                            placement="top"
                                          />
                                      )}
                                    />
                                </div>
                            </ListTableCell>
                        );
                    }
                    return <ListTableCell key={platformUseCase.id} />;
                })
            }
            {
                emptyColumnLength > 0 && <EmptyCells emptyColumnLength={emptyColumnLength} />
            }
        </ListTableRow>
    );
};

BrokenProfileUseCasesRow.propTypes = {
    invalidReasonsByUseCaseId: PropTypes.objectOf(PropTypes.string).isRequired,
    account: customPropTypes.account.isRequired,
    platformUseCases: customPropTypes.useCases.isRequired,
    accountType: customPropTypes.accountTypes.isRequired
};

export default BrokenProfileUseCasesRow;
