import PostTileBody from 'src/components/views/postTags/postTagging/tile/PostTileBody';
import React, { memo } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import styles from 'src/stylesheets/views/postTags/postTagManager/postTile.scss';
import PostPostTagList from 'src/components/views/postTags/postPostTagList/PostPostTagList';
import classnames from 'classnames';
import PropTypse from 'prop-types';
import { modalShowPostDetailInPostTagManager } from 'src/actions/overlays';

const PostTile = (props) => {
    const {
        post, postTagId, disabled, modalShowPostDetailAction
    } = props;
    return (
        <div className={classnames(styles.wrapper, { [styles.disabled]: disabled })} onClick={() => { modalShowPostDetailAction(post.id, postTagId); }}>
            <PostTileBody
              post={post}
              renderFooter={(postFromComponent) => <PostPostTagList post={postFromComponent} />}
            />
        </div>
    );
};

PostTile.propTypes = {
    post: customPropTypes.post.isRequired,
    postTagId: PropTypse.string.isRequired,
    disabled: PropTypse.bool.isRequired,
    modalShowPostDetailAction: PropTypse.func.isRequired,
};

export default memo(connect(null, {
    modalShowPostDetailAction: modalShowPostDetailInPostTagManager,
})(PostTile));
