import { connect } from 'react-redux';
import CustomMetricListItem from 'src/components/views/custom-metrics/CustomMetricListItem';
import { makeSelectEditMetricLink } from 'src/selectors/filterSelectors';
import { makeSelectMetricById } from 'src/selectors/metrics';
import PropTypes from 'prop-types';
import { selectLoggedInUser } from 'src/selectors/users';
import { METRIC_BUILDER } from 'src/utils/filterSelectors';

const makeMapStateToProps = () => {
    const selectEditMetricLink = makeSelectEditMetricLink();
    const selectMetricById = makeSelectMetricById();
    return (state, ownProps) => {
        const customMetric = selectMetricById(state, ownProps.customMetricId);
        const link = selectEditMetricLink(state, METRIC_BUILDER, customMetric.id);

        return {
            customMetric,
            loggedInUser: selectLoggedInUser(state),
            link
        };
    };
};

const CustomMetricListItemContainer = connect(makeMapStateToProps)(CustomMetricListItem);

CustomMetricListItemContainer.propTypes = {
    customMetricId: PropTypes.string.isRequired
};

export default CustomMetricListItemContainer;
