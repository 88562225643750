import { Select } from 'src/components/forms/fields';
import DashboardsOrFolderSearchInput from 'src/components/views/dashboards/DashboardsOrFolderSearchInput';
import ListFilters from 'src/components/ListFilters';
import ListOptionsBar from 'src/components/ListOptionsBar';
import React from 'react';
import _get from 'lodash/get';
import withReduxForm from 'src/components/forms/withReduxForm';
import { Field } from 'redux-form';

const inputWrapperStyle = { width: 300 };

export const formName = 'dashboardsFilterForm';
const sortingOptions = [
    { value: 'name', label: 'Name (A-Z)' },
    { value: 'createTime', label: 'Newest first' }
];

const sortingDirections = {
    name: 'asc',
    createTime: 'desc'
};

export const getSortDirection = (value) => _get(sortingDirections, value, 'asc');

export const initialValues = { sortBy: sortingOptions[0].value };

const DashboardsFilterBarForm = () => {
    const filters = (
        <ListFilters>
            <div style={inputWrapperStyle}>
                <DashboardsOrFolderSearchInput autoFocus minLength={1} />
            </div>
        </ListFilters>
    );

    const sorting = (
        <Field
          name="sortBy"
          component={Select}
          layout="listFilter"
        >
            {
            sortingOptions.map((option) => (
                <option key={`select-option-${option.value}`} value={option.value}>{option.label}</option>
            ))
        }
        </Field>
    );

    return (
        <ListOptionsBar filters={filters} sorting={sorting} />
    );
};

export default withReduxForm(DashboardsFilterBarForm, {
    form: formName,
    initialValues,
    destroyOnUnmount: false
});
