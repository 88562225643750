import Body from 'src/components/Body';
import DataPushTasksHeader from 'src/components/header/DataPushTasksHeader';
import React from 'react';
import ScrollView from 'src/components/ScrollView';
import Footer from 'src/components/Footer';
import * as dataPushTaskActions from 'src/actions/dataPushTasks';
import { connect } from 'react-redux';
import { makeSelectDataPushTasksLoadingState, getDataPushTasksIdsFromStore } from 'src/selectors/dataPushTasks';
import PropTypes from 'prop-types';
import DataPushTasksTable from 'src/components/views/integrations/dataPushTasks/dataPushTaskTable/DataPushTasksTable';
import * as customPropTypes from 'src/customPropTypes';
import withScrollPosition from 'src/components/withScrollPosition';
import DataPushTaskNotAllowedState from 'src/components/views/integrations/DataPushTaskNotAllowedState';
import styles from 'src/stylesheets/views/integrations/dataPushTasks.scss';
import AsyncDataLoader from 'src/components/AsyncDataLoader';
import { selectNumberOfDataPushTasksAllowed } from 'src/selectors/client';

const ScrollViewWithScrollPosition = withScrollPosition(ScrollView, 'dataPushTasks');

const selectDataPushTasksLoadingState = makeSelectDataPushTasksLoadingState();

const DataPushTasks = (props) => {
    const { location, getDataPushTasksRequestAction, numberOfDataPushTasksAllowed } = props;
    return (
        <ScrollViewWithScrollPosition
          scrollPositionIdentifier="dataPushTasks"
          locationKey={location.key}
          dataViewName="data-push-tasks"
        >
            <DataPushTasksHeader />
            <Body className={styles.body}>
                <AsyncDataLoader
                  asyncStateSelector={selectDataPushTasksLoadingState}
                  requestAction={getDataPushTasksRequestAction}
                  dataSelector={getDataPushTasksIdsFromStore}
                  isListTable
                  alwaysReload
                >
                    {
                        () => (
                            numberOfDataPushTasksAllowed > 0
                                ? <DataPushTasksTable />
                                : <DataPushTaskNotAllowedState />
                        )
                    }
                </AsyncDataLoader>
            </Body>
            <Footer />
        </ScrollViewWithScrollPosition>
    );
};

DataPushTasks.propTypes = {
    getDataPushTasksRequestAction: PropTypes.func.isRequired,
    location: customPropTypes.location.isRequired,
    numberOfDataPushTasksAllowed: PropTypes.number.isRequired
};

const mapStateToProps = (state) => ({
    numberOfDataPushTasksAllowed: selectNumberOfDataPushTasksAllowed(state)
});

export default connect(mapStateToProps, {
    getDataPushTasksRequestAction: dataPushTaskActions.getDataPushTasksRequest
})(DataPushTasks);
