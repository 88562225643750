import * as customPropTypes from 'src/customPropTypes';
import classnames from 'classnames';
import React from 'react';
import RelativeTime from 'src/components/RelativeTime';
import styles from 'src/stylesheets/userNotificationMenu/userNotificationMenuListItem.scss';
import UserNotificationItemMarker from 'src/components/UserNotificationItemMarker';
import PropTypes from 'prop-types';
import UserNotificationIcon from 'src/components/UserNotificationIcon';
import withTooltip from 'src/components/withToolTip';

const UserNotificationItemMarkerWithTooltip = withTooltip(UserNotificationItemMarker);

const UserNotificationMenuListItem = (props) => {
    const { userNotificationItem, onNotificationItemClick } = props;
    const {
        id, content, wasRead, createTime
    } = userNotificationItem;
    const { message, type } = content;
    const toolTip = wasRead ? 'Mark as Unread' : 'Mark as read';

    return (
        <div className={classnames(styles.wrapper, { [styles.unRead]: !wasRead, [styles.read]: wasRead })}>
            <div
              className={styles.body}
              onClick={() => { onNotificationItemClick(userNotificationItem); }}
            >
                <div className={styles.type}>
                    <UserNotificationIcon type={type} className={styles.icon} />
                </div>
                <div className={styles.messageWrapper}>
                    <div className={styles.message} dangerouslySetInnerHTML={{ __html: message }} />
                    <div className={styles.relativeTime}><RelativeTime date={createTime} thresholdTimestamp={365 * 86400} /></div>
                </div>
            </div>
            <div className={styles.markAction}>
                <UserNotificationItemMarkerWithTooltip
                  wasRead={wasRead}
                  id={id}
                  tooltip={toolTip}
                />
            </div>
        </div>
    );
};

UserNotificationMenuListItem.propTypes = {
    userNotificationItem: customPropTypes.userNotificationItem.isRequired,
    onNotificationItemClick: PropTypes.func.isRequired
};

export default UserNotificationMenuListItem;
