import { makeSelectIsMetricFavorite, makeSelectIsMetricFavToggling } from 'src/selectors/analysis';
import React, { useState } from 'react';
import { analysisToggleFavRequest } from 'src/actions/analysis';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from 'src/stylesheets/views/analysis/analysis.scss';
import withTooltip from 'src/components/withToolTip';

const ButtonWithTooltip = withTooltip(Button);

const AnalysisFavButton = (props) => {
    const [isHovering, setIsHovering] = useState(false);
    const {
        isLoading,
        isFavorite,
        analysisToggleFavRequestAction,
        metricId,
    } = props;
    const icon = isFavorite ? 'fave-filled' : 'fave';
    let iconToShow = icon;
    if (isLoading || isHovering) {
        const invertedFavorite = !isFavorite;
        iconToShow = invertedFavorite ? 'fave-filled' : 'fave';
    }
    const tooltip = isFavorite ? 'Remove from favorites' : 'Add to favorites';
    return (
        <ButtonWithTooltip
          disabled={isLoading}
          onClick={() => {
              analysisToggleFavRequestAction(metricId, !isFavorite);
          }}
          onMouseOver={() => setIsHovering(true)}
          onMouseOut={() => setIsHovering(false)}
          icon={iconToShow}
          small
          active={isFavorite}
          className={styles.favButton}
          favorite
          tooltip={tooltip}
        />
    );
};

AnalysisFavButton.propTypes = {
    metricId: PropTypes.string.isRequired,
    analysisToggleFavRequestAction: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isFavorite: PropTypes.bool.isRequired
};

const makeMapStateToProps = () => {
    const selectIsMetricFavToggling = makeSelectIsMetricFavToggling();
    const selectIsMetricFavorite = makeSelectIsMetricFavorite();

    return (state, ownProps) => ({
        isLoading: selectIsMetricFavToggling(state, ownProps.metricId),
        isFavorite: selectIsMetricFavorite(state, ownProps.metricId)
    });
};

export default connect(makeMapStateToProps, { analysisToggleFavRequestAction: analysisToggleFavRequest })(AnalysisFavButton);
