import * as userNotificationActions from 'src/actions/userNotification';
import _omit from 'lodash/omit';
import classnames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/userNotificationMenu/userNotificationItemMarker.scss';

const UserNotificationItemMarker = (props) => {
    const {
        id, wasRead, markUserNotificationAction
    } = props;
    const cleanedProps = _omit(props, ['id', 'wasRead', 'markUserNotificationAction']);
    return (
        <div
          className={classnames(
              styles.wrapper,
              { [styles.unRead]: !wasRead, [styles.read]: wasRead },
          )}
          onClick={() => { markUserNotificationAction(id, !wasRead); }}
          {...cleanedProps}
        />
    );
};

UserNotificationItemMarker.propTypes = {
    wasRead: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    markUserNotificationAction: PropTypes.func.isRequired
};

export default connect(null, {
    markUserNotificationAction: userNotificationActions.markUserNotification
})(UserNotificationItemMarker);
