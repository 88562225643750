import React, { useRef, useEffect } from 'react';
import Body from 'src/components/Body';
import ScrollView from 'src/components/ScrollView';
import ContentWithSidebar from 'src/components/views/ContentWithSidebar';
import PostsSidebar from 'src/components/views/postTags/postTagging/sidebar/PostsSidebar';
import * as customPropTypes from 'src/customPropTypes';
import PostsHeader from 'src/components/header/PostsHeader';
import withStickyHeaderHandling from 'src/components/withStickyHeaderHandling';
import withDynamicListNameListHandling from 'src/components/bulkList/withDynamicListNameListHandling';
import PostTilesByCreateTimes from 'src/components/views/postTags/postTagging/PostTilesByCreateTimes';
import PaginatedPostsData from 'src/components/views/postTags/postTagging/PaginatedPostsData';
import styles from 'src/stylesheets/views/postTags/postTagging/posts.scss';
import ParsedPostSearchParamsProvider from 'src/components/views/postTags/postTagging/ParsedPostSearchParamsProvider';
import PropTypes from 'prop-types';
import { getCurrentPageNumber } from 'src/selectors/posts';
import { connect } from 'react-redux';
import Footer from 'src/components/Footer';
import { getPostTaggingListName } from 'src/utils/postSearch';
import FilterContextProvider from 'src/components/views/FilterContextProvider';
import { POST_TAGGING } from 'src/utils/filterSelectors';

const ScrollViewWithStickyHeader = withStickyHeaderHandling(ScrollView);
const postTagsIdentifier = 'postTagging';
const PostTilesByCreateTimesWithDynamicListNameListHandling = withDynamicListNameListHandling(PostTilesByCreateTimes);

const Posts = (props) => {
    const { pageNumber, location } = props;
    let scrollRef = useRef(null);

    useEffect(() => {
        if (scrollRef) {
            if (scrollRef && scrollRef.scrollTop > 0) {
                scrollRef.scrollTop = 0;
            }
        }
    }, [pageNumber]);
    const listName = getPostTaggingListName(pageNumber);
    return (
        <ContentWithSidebar sidebar={<PostsSidebar listName={listName} />} showSidebar>
            <FilterContextProvider context={POST_TAGGING}>
                <ScrollViewWithStickyHeader
                  dataViewName="postTags"
                  stickyHeaderIdentifier={postTagsIdentifier}
                  bodyRefForScrollPosition={(node) => { scrollRef = node; }}
                  threshold={138}
                >
                    <ParsedPostSearchParamsProvider location={location}>
                        {(searchRequestInput) => (
                            <>
                                <PostsHeader stickyHeaderIdentifier={postTagsIdentifier} listName={listName} searchRequestInput={searchRequestInput} />
                                <Body>
                                    <PaginatedPostsData searchRequestInput={searchRequestInput}>
                                        {
                                            (postIds, isPending) => (
                                                <PostTilesByCreateTimesWithDynamicListNameListHandling
                                                  listItemIds={postIds}
                                                  postIds={postIds}
                                                  listName={listName}
                                                  isPending={isPending}
                                                  className={styles.body}
                                                />
                                            )
                                        }
                                    </PaginatedPostsData>
                                </Body>
                                <Footer />
                            </>
                        )}
                    </ParsedPostSearchParamsProvider>
                </ScrollViewWithStickyHeader>
            </FilterContextProvider>
        </ContentWithSidebar>
    );
};

Posts.propTypes = {
    pageNumber: PropTypes.number.isRequired,
    location: customPropTypes.location.isRequired
};

const mapStateToProps = (state) => ({
    pageNumber: getCurrentPageNumber(state)
});

export default connect(mapStateToProps)(Posts);
