import RequestInfoSummary from 'src/components/filter/RequestInfoSummary';
import * as customPropTypes from 'src/customPropTypes';
import AnalysisMetricDataHeader from 'src/components/metricTiles/layout/AnalysisMetricDataHeader';
import DataBody from 'src/components/metricTiles/layout/DataBody';
import MetricTile from 'src/components/metricTiles/layout/MetricTile';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/metricTile.scss';
import classnames from 'classnames';
import { getRequestInfoFromDataOrError } from 'src/utils/serverData';
import { getFiltersWithCustomBindingFromDashboardMetricSettings } from './utils';

const AnalysisDataTile = (props) => {
    const {
        selectedDate,
        selectedProfilesOrGroups,
        accountOptions,
        metric,
        settings,
        serverData,
    } = props;

    const { data, loading, error } = serverData;
    const requestInfo = getRequestInfoFromDataOrError(data, error);
    const filtersWithCustomBinding = getFiltersWithCustomBindingFromDashboardMetricSettings(settings);

    return (
        <MetricTile>
            <AnalysisMetricDataHeader metric={metric} />
            <div className={styles.analysisMetricBodyWrapper}>
                <DataBody
                  selectedDate={selectedDate}
                  selectedProfilesOrGroups={selectedProfilesOrGroups}
                  accountOptions={accountOptions}
                  metric={metric}
                  settings={settings}
                  data={data}
                  loading={loading}
                  error={error}
                />
            </div>
            {
                requestInfo
                && (
                    <div className={classnames(styles.filterWrapper, styles.analysisMetricFilterWrapper)}>
                        <RequestInfoSummary requestInfo={requestInfo} filtersWithCustomBinding={filtersWithCustomBinding} />
                    </div>
                )
            }
        </MetricTile>
    );
};

AnalysisDataTile.propTypes = {
    selectedDate: customPropTypes.selectedDate.isRequired,
    selectedProfilesOrGroups: customPropTypes.profileFilter.isRequired,
    accountOptions: customPropTypes.accountOptions.isRequired,
    metric: customPropTypes.minimalMetricShapeForDataLoading.isRequired,
    settings: customPropTypes.dashboardMetricSettings.isRequired,
    serverData: PropTypes.object.isRequired
};

export default AnalysisDataTile;
