import React from 'react';
import ListTable from 'src/components/listTable/ListTable';
import ListTableHeader from 'src/components/listTable/ListTableHeader';
import PostTagTableRow from 'src/components/views/postTags/postTagManager/PostTagTableRow';
import * as PropTypes from 'prop-types';
import withBulkCheckboxHandling from 'src/components/bulkList/withBulkCheckboxHandling';
import CheckBox from 'src/components/forms/inputs/Checkbox';
import { connect } from 'react-redux';
import { makeSelectPostTagsWithListFilterApplied, getListTableConfig } from 'src/selectors/postTags';
import * as postTagActions from 'src/actions/postTags';
import * as customPropTypes from 'src/customPropTypes';
import EmptyInfoScreen from 'src/components/EmptyInfoScreen';
import emptyImage from 'src/resources/illustrations/tags_empty.png';
import styles from 'src/stylesheets/views/viewListTable.scss';

const PostTagsBulkCheckbox = withBulkCheckboxHandling(CheckBox, true);

const PostTagsTable = (props) => {
    const {
        listName, filteredPostTagIds, sortPostTagsAction, tableConfig
    } = props;
    const { sortBy, sortDir } = tableConfig;
    const columns = [
        {
            id: 'bulkCheckbox', content: <PostTagsBulkCheckbox listName={listName} listItemIdsToHandle={filteredPostTagIds} />, className: styles.checkbox
        },
        {
            id: 'name', content: 'Tag name', isSortable: true, width: '25%'
        },
        {
            id: 'numberOfPosts', content: 'Posts', isSortable: true, width: '15%'
        },
        {
            id: 'creatorUserName', content: 'Creator', width: '20%'
        },
        {
            id: 'createTime', content: 'Creation date', isSortable: true, width: '20%'
        },
        {
            id: 'automation', content: 'Automation', width: '20%'
        }
    ];

    const tableHeader = <ListTableHeader columns={columns} sortByAction={sortPostTagsAction} sortBy={sortBy} sortDir={sortDir} />;

    if (filteredPostTagIds.length > 0) {
        return (
            <ListTable header={tableHeader}>
                {
                    filteredPostTagIds.map((id) => (
                        <PostTagTableRow id={id} key={id} listName={listName} />
                    ))
                }
            </ListTable>
        );
    }
    return (
        <EmptyInfoScreen
          image={emptyImage}
          title="No active tags"
          imageClassName={styles.empty}
        />
    );
};

PostTagsTable.propTypes = {
    filteredPostTagIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    listName: PropTypes.string.isRequired,
    sortPostTagsAction: PropTypes.func.isRequired,
    tableConfig: customPropTypes.postTagTableConfig.isRequired
};

const makeMapStateToProps = () => {
    const selectPostTagsWithListFilterApplied = makeSelectPostTagsWithListFilterApplied();
    return (state) => {
        const tableConfig = getListTableConfig(state);
        const { sortBy, sortDir, showArchived } = tableConfig;
        const filteredPostTagIds = selectPostTagsWithListFilterApplied(state, showArchived, sortBy, sortDir);
        return {
            filteredPostTagIds,
            tableConfig
        };
    };
};

export default connect(makeMapStateToProps, {
    sortPostTagsAction: postTagActions.sortPostTags
})(PostTagsTable);
