import { LOAD_USER_INVITATION_ERROR, LOAD_USER_INVITATION_REQUEST, LOAD_USER_INVITATION_SUCCESS } from 'src/actions/userInvitations';
import { createAsyncStatesReducerForActionsWithErrorAndSuccessStates } from 'src/reducers/utils';
import { combineReducers } from 'redux';

const asyncStatesById = createAsyncStatesReducerForActionsWithErrorAndSuccessStates((payload) => payload.invitationCode,
    [LOAD_USER_INVITATION_REQUEST],
    [LOAD_USER_INVITATION_SUCCESS],
    [LOAD_USER_INVITATION_ERROR]);

export const userInvitations = combineReducers({
    asyncStatesById
});
