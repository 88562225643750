import AdditionalFilterButton from 'src/components/buttons/AdditionalFilterButton';
import CombinedDateSelectorButton from 'src/components/buttons/CombinedDateSelectorButton';
import ContextAwareAdditionalFilterSelector from 'src/components/filterSelectors/ContextAwareAdditionalFilterSelector';
import * as customPropTypes from 'src/customPropTypes';
import _get from 'lodash/get';
import ContextAwareDateSelector from 'src/components/filterSelectors/ContextAwareDateSelector';
import ContextAwareProfileSelector from 'src/components/filterSelectors/ContextAwareProfileSelector';
import ProfileSelectorButton from 'src/components/buttons/ProfileSelectorButton';
import React from 'react';
import SelectorButtonGroup from 'src/components/buttons/SelectorButtonGroup';

import withAdditionalSettingsInterpretation from 'src/components/chartViews/withAdditionalSettingsInterpretation';

const AdditionalSettingAwareSelectors = (props) => {
    const {
        settings, selectedProfilesOrGroups, selectedDate, selectedPostTag, selectedPostText, selectedPostTextExclude
    } = props;
    let profileSelector;
    let dateSelector;
    let additionalFilterSelector;

    if (_get(settings, 'isCustomDateSelected', false)) {
        dateSelector = <CombinedDateSelectorButton disabled selectedDate={selectedDate} />;
    } else {
        dateSelector = <ContextAwareDateSelector />;
    }

    if (_get(settings, 'isCustomProfileSelected', false)) {
        profileSelector = <ProfileSelectorButton disabled selectedProfilesOrGroups={selectedProfilesOrGroups} />;
    } else {
        profileSelector = <ContextAwareProfileSelector />;
    }

    if (_get(settings, 'isCustomAdditionalFilterSelected', false)) {
        additionalFilterSelector = <AdditionalFilterButton onClick={() => {}} disabled postTag={selectedPostTag} postText={selectedPostText} postTextExclude={selectedPostTextExclude} />;
    } else {
        additionalFilterSelector = <ContextAwareAdditionalFilterSelector />;
    }

    return (
        <SelectorButtonGroup profileSelector={profileSelector} dateSelector={dateSelector} filterSelector={additionalFilterSelector} />
    );
};

AdditionalSettingAwareSelectors.propTypes = {
    settings: customPropTypes.dashboardMetricSettings.isRequired,
    selectedProfilesOrGroups: customPropTypes.profileFilter.isRequired,
    selectedDate: customPropTypes.selectedDate.isRequired,
    selectedPostTag: customPropTypes.postTagFilter.isRequired,
    selectedPostText: customPropTypes.postTextFilter.isRequired,
    selectedPostTextExclude: customPropTypes.postTextFilter.isRequired
};

export default withAdditionalSettingsInterpretation(AdditionalSettingAwareSelectors);
