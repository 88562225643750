import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import React from 'react';
import { selectDataPushTaskLimitAndUsage } from 'src/selectors/client';
import ProgressBarWithLabels from 'src/components/ProgressBarWithLabels';
import { Link } from 'react-router';
import { selectIsAdmin } from 'src/selectors/loggedInUser';
import PropTypes from 'prop-types';
import { getNetworkName } from 'src/utils/profiles';

const getAllowedNetworkNames = (dataPushTaskNetworks) => (dataPushTaskNetworks.length > 0 ? dataPushTaskNetworks.map((network) => getNetworkName(network)).join(', ') : 'N/A');

const DataPushTaskLimitStatus = (props) => {
    const { dataPushTaskLimitAndUsage, isAdmin } = props;
    const { limit, usage, dataPushTaskNetworks } = dataPushTaskLimitAndUsage;
    return (
        <div>
            <ProgressBarWithLabels
              label="Data push tasks"
              limit={limit}
              usage={usage}
            /><br />
            <div>
                <b>Networks: </b>{getAllowedNetworkNames(dataPushTaskNetworks)}
            </div>
            {
                isAdmin && (
                    <div>
                        <br />
                        <Link to="/settings/plan">View plan</Link>
                    </div>
                )
            }
        </div>
    );
};

DataPushTaskLimitStatus.propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    dataPushTaskLimitAndUsage: customPropTypes.dataPushTaskLimitAndUsage.isRequired
};

const mapStateToProps = (state) => ({
    dataPushTaskLimitAndUsage: selectDataPushTaskLimitAndUsage(state),
    isAdmin: selectIsAdmin(state)
});

export default connect(mapStateToProps)(DataPushTaskLimitStatus);
