import React, { useEffect } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import * as metricSearchActions from 'src/actions/metricSearch';
import _get from 'lodash/get';
import _omit from 'lodash/omit';
import { connect } from 'react-redux';
import ErrorView from 'src/components/errors/ErrorView';
import { makeSelectSearchQueryInputFromLocation } from 'src/selectors/metricSearch';
import PropTypes from 'prop-types';

const withParsedMetricSearchParams = (WrappedComponent) => {
    const WithParsedMetricSearchParams = (props) => {
        const {
            metricSearchInputFromRouteOrFalse,
            metricSearchAllInputsUpdateAction
        } = props;

        useEffect(() => {
            if (metricSearchInputFromRouteOrFalse) {
                metricSearchAllInputsUpdateAction(metricSearchInputFromRouteOrFalse);
            }
        }, [metricSearchInputFromRouteOrFalse]);

        if (!metricSearchInputFromRouteOrFalse) {
            return <ErrorView headline="Invalid search parameters" error={{ message: 'Please check your search query.' }} />;
        }
        const cleanedProps = _omit(props, 'metricSearchInputFromRouteOrFalse');
        return <WrappedComponent {...cleanedProps} metricSearchInput={metricSearchInputFromRouteOrFalse} />;
    };

    WithParsedMetricSearchParams.propTypes = {
        metricSearchInputFromRouteOrFalse: PropTypes.oneOfType([customPropTypes.metricSearchInput, PropTypes.bool]).isRequired,
        filterBy: PropTypes.oneOf(['dashboards', 'metrics', 'publicMetrics', 'customMetrics']).isRequired,
        location: PropTypes.shape({
            query: PropTypes.shape({
                search: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
                orderBy: PropTypes.string.isRequired,
                term: PropTypes.string
            }).isRequired
        }).isRequired,
        metricSearchAllInputsUpdateAction: PropTypes.func.isRequired
    };

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';
    WithParsedMetricSearchParams.displayName = `withParsedMetricSearchParams(${wrappedComponentName})`;

    const makeMapStateToProps = () => {
        const selectSearchQueryInputFromLocation = makeSelectSearchQueryInputFromLocation();
        return (state, ownProps) => {
            const search = _get(ownProps, 'location.query.search');
            const orderBy = _get(ownProps, 'location.query.orderBy');
            const term = _get(ownProps, 'location.query.term');
            const { filterBy } = ownProps;
            return {
                metricSearchInputFromRouteOrFalse: selectSearchQueryInputFromLocation(filterBy, search, orderBy, term)
            };
        };
    };

    return connect(makeMapStateToProps, {
        metricSearchAllInputsUpdateAction: metricSearchActions.metricSearchAllInputsUpdate
    })(WithParsedMetricSearchParams);
};

export default withParsedMetricSearchParams;
