import Date from 'src/components/Date';
import Number from 'src/components/Number';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/views/discover/metaInfo.scss';

const MetaInfo = (props) => {
    const { usage, createTime } = props;
    return (
        <div className={styles.wrapper}>
            <div>
                Used
                {' '}
                <Number value={usage} />
                {' times'}
                <br />
            </div>
            <div>
                Added on
                {' '}
                <Date date={createTime} format="date" />
            </div>
        </div>
    );
};

MetaInfo.propTypes = {
    usage: PropTypes.number.isRequired,
    createTime: PropTypes.string.isRequired
};

export default MetaInfo;
