import React from 'react';
import * as customPropTypes from 'src/customPropTypes';
import styles from 'src/stylesheets/views/postTags/postTagManager/postTagRuleItem.scss';
import PropTypes from 'prop-types';
import IcomoonIcon from 'src/components/IcomoonIcon';
import classnames from 'classnames';
import { hasPostTagRuleJobCompleted } from 'src/utils/postTagRule';
import withTooltip from 'src/components/withToolTip';
import PostTagRuleDeleteIcon from 'src/components/views/postTags/postTagManager/postTagRules/PostTagRuleDelete';
import PostTagRuleInSentence from 'src/components/views/postTags/postTagManager/postTagRules/PostTagRuleInSentence';

const IcomoonIconIconWithTooltip = withTooltip(IcomoonIcon);

const PostTagRuleItem = (props) => {
    const {
        postTagRule, onEditClick, onCopyClick
    } = props;
    const { endTime } = postTagRule;

    const jobRuleCompleted = hasPostTagRuleJobCompleted(endTime);

    return (
        <div className={styles.wrapper}>
            <div className={classnames(styles.description, { [styles.disabled]: jobRuleCompleted })}>
                <PostTagRuleInSentence postTagRule={postTagRule} />
            </div>
            <div className={styles.controls}>
                {
                    jobRuleCompleted
                    && (
                        <IcomoonIconIconWithTooltip
                          icon="success"
                          tooltip="This rule reached it's specified end time"
                          className={styles.completed}
                        />
                    )
                }
                <IcomoonIconIconWithTooltip
                  icon="edit"
                  tooltip="Edit rule"
                  onClick={onEditClick}
                  className={classnames(styles.icon, styles.edit)}
                />
                <IcomoonIconIconWithTooltip
                  icon="copy"
                  tooltip="Create copy"
                  onClick={onCopyClick}
                  className={classnames(styles.icon, styles.edit)}
                />
                <PostTagRuleDeleteIcon postTagRuleId={postTagRule.id} />
            </div>
        </div>
    );
};

PostTagRuleItem.propTypes = {
    postTagRule: customPropTypes.postTagRule.isRequired,
    onEditClick: PropTypes.func.isRequired,
    onCopyClick: PropTypes.func.isRequired
};

export default PostTagRuleItem;
