import * as customPropTypes from 'src/customPropTypes';
import { connect } from 'react-redux';
import { makeSelectBrokenProfileUseCasesByPlatformType } from 'src/selectors/authUsers';
import React from 'react';
import AccountUseCasesHealthStatus from 'src/components/account/AccountUseCasesHealthStatus';

const ProfileUseCasesHealthStatus = (props) => {
    const { brokenProfileUseCasesByPlatformType } = props;
    return (
        <AccountUseCasesHealthStatus
          brokenAccountUseCasesByPlatformType={brokenProfileUseCasesByPlatformType}
          accountType="profiles"
        />
    );
};

ProfileUseCasesHealthStatus.propTypes = {
    brokenProfileUseCasesByPlatformType: customPropTypes.brokenProfilesUseCasesByPlatformType.isRequired
};

const makeMapStateToProps = () => {
    const selectBrokenProfileUseCasesByPlatformType = makeSelectBrokenProfileUseCasesByPlatformType();
    return (state) => ({
        brokenProfileUseCasesByPlatformType: selectBrokenProfileUseCasesByPlatformType(state)
    });
};

export default connect(makeMapStateToProps)(ProfileUseCasesHealthStatus);
