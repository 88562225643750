import React from 'react';
import MenuButton from 'src/components/buttons/MenuButton';
import styles from 'src/stylesheets/metricOpenInPopover.scss';
import PropTypes from 'prop-types';

const BackButton = (props) => {
    const { onBackClick } = props;
    return (
        <div>
            <MenuButton
              label="Back"
              icon="chevron-big-left"
              onClick={onBackClick}
            />
            <hr className={styles.spacer} />
        </div>
    );
};

BackButton.propTypes = {
    onBackClick: PropTypes.func.isRequired
};

export default BackButton;
