import React, { Component } from 'react';
import * as routerActions from 'react-router-redux';
import AnalysisSearchAutocomplete from 'src/components/autocomplete/AnalysisSearchAutocomplete';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeSelectQueryFromFilterSelectorByContext } from 'src/selectors/filterSelectors';
import TextWithAutoComplete from 'src/components/forms/inputs/TextWithAutocomplete';
import withMetricAutocompleteLoading from 'src/components/views/analysis/withMetricAutocompleteLoading';
import withOuterSearchTerm from 'src/components/forms/inputs/withOuterSearchTerm';
import { QUICK_ANALYSIS } from 'src/utils/filterSelectors';

const TextwithMetricAutocompleteLoading = withOuterSearchTerm(withMetricAutocompleteLoading(TextWithAutoComplete));

const renderAutocompleteMenu = (autocompleteSuggestions, activeIndex, hide, onItemSelect, searchTerm, loading) => (
    <AnalysisSearchAutocomplete
      filteredDashboardAndFolderIds={autocompleteSuggestions}
      activeIndex={activeIndex}
      hidePopover={hide}
      onItemClick={onItemSelect}
      loading={loading}
    />
);

class AnalysisSearchInput extends Component {
    constructor(props) {
        super(props);
        this.handleOnItemSelect = this.handleOnItemSelect.bind(this);
    }

    handleOnItemSelect(item) {
        const { routePushAction, queryParams } = this.props;
        routePushAction({ pathname: `/analysis/${item}`, query: queryParams });
    }

    render() {
        return (
            <TextwithMetricAutocompleteLoading
              onItemSelect={this.handleOnItemSelect}
              renderAutocompleteMenu={renderAutocompleteMenu}
              minLength={2}
              autoFocus
              icon="search"
              resetAfterSelect
              placeholder="Search metrics"
              menuPlacement="bottom-end"
            />
        );
    }
}

AnalysisSearchInput.propTypes = {
    routePushAction: PropTypes.func.isRequired,
    queryParams: PropTypes.object.isRequired
};

const makeMapStateToProps = () => {
    const selectQueryFromFilterSelectorByContext = makeSelectQueryFromFilterSelectorByContext();
    return (state) => ({
        queryParams: selectQueryFromFilterSelectorByContext(state, QUICK_ANALYSIS)
    });
};

export default connect(makeMapStateToProps, {
    routePushAction: routerActions.push,
})(AnalysisSearchInput);
