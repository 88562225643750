import AnalysisQuickLink from 'src/components/buttons/AnalysisQuickLink';
import { connect } from 'react-redux';
import List from 'src/components/List';
import { makeSelectAnalysisFavoriteMetricIdsByFilter } from 'src/selectors/analysis';
import PropTypes from 'prop-types';
import React from 'react';
import styles from 'src/stylesheets/views/analysis/analysis.scss';
import withAnalysisQuickLinkFromStore from 'src/components/views/analysis/withAnalysisQuickLinkFromStore';

const AnalysisQuickLinkWithAnalysisQuickLinkFromStore = withAnalysisQuickLinkFromStore(AnalysisQuickLink);

const AnalysisFavoriteMetrics = (props) => {
    const { analysisFavoriteMetricIds } = props;
    return (
        <div>
            {
                analysisFavoriteMetricIds.length > 0
                && (
                <List border={false}>
                    {
                        analysisFavoriteMetricIds.map((metricId) => (
                            <AnalysisQuickLinkWithAnalysisQuickLinkFromStore
                              key={metricId}
                              id={metricId}
                            />
                        ))
                    }
                </List>
                )
            }
            {
                analysisFavoriteMetricIds.length === 0
                && <div className={styles.noFavs}><em>You have no favorites yet</em></div>
            }
        </div>
    );
};

AnalysisFavoriteMetrics.propTypes = {
    analysisFilter: PropTypes.string.isRequired,
    analysisFavoriteMetricIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

const makeMapStateToProps = () => {
    const selectAnalysisFavoriteMetricIdsByFilter = makeSelectAnalysisFavoriteMetricIdsByFilter();
    return (state, ownProps) => ({
        analysisFavoriteMetricIds: selectAnalysisFavoriteMetricIdsByFilter(state, ownProps.analysisFilter)
    });
};

export default connect(makeMapStateToProps)(AnalysisFavoriteMetrics);
