import React from 'react';
import styles from 'src/stylesheets/headers/dataSourceListHeader.scss';
import DataSourceFilterForm from 'src/components/forms/DataSourceFilterBarForm';
import * as customPropTypes from 'src/customPropTypes';
import { withRouter } from 'react-router';

const DataSourceListHeader = ({ location }) => (
    <div className={styles.wrapper}>
        <DataSourceFilterForm location={location} />
    </div>
);

DataSourceListHeader.propTypes = {
    location: customPropTypes.dataSourceSearchParamsLocation.isRequired
};

export default withRouter(DataSourceListHeader);
