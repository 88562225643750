import CombinedDateSelector from 'src/components/dateSelector/CombinedDateSelector';
import HotKeys from 'src/components/HotKeys';
import React, { Component, createRef } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import * as routeActions from 'react-router-redux';
import { connect } from 'react-redux';
import { makeSelectDateByContext } from 'src/selectors/filterSelectors';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import withFilterContextConsumer from 'src/components/withFilterContextConsumer';
import _omit from 'lodash/omit';

class ContextAwareDateSelector extends Component {
    constructor(props) {
        super(props);
        this.handleOnDateChange = this.handleOnDateChange.bind(this);
        this.combinedDateSelectorRef = createRef();
        this.openDateSelector = this.openDateSelector.bind(this);
    }

    handleOnDateChange(date) {
        const { routePushAction, location } = this.props;
        const query = Object.assign({}, _omit(location.query, ['from', 'to', 'dynamicDate']), date);
        routePushAction({
            pathname: location.pathname,
            query
        });
    }

    openDateSelector(event) {
        if (this.combinedDateSelectorRef.current) {
            event.preventDefault();
            this.combinedDateSelectorRef.current.click();
        }
    }

    render() {
        const {
            selectedDate, placement, small, disabled, showInterval
        } = this.props;
        return (
            <HotKeys keys="d" callback={this.openDateSelector}>
                <CombinedDateSelector
                  selectedDate={selectedDate}
                  onDateChange={this.handleOnDateChange}
                  small={small}
                  placement={placement}
                  disabled={disabled}
                  showInterval={showInterval}
                  ref={this.combinedDateSelectorRef}
                />
            </HotKeys>
        );
    }
}

ContextAwareDateSelector.propTypes = {
    selectedDate: customPropTypes.selectedDate.isRequired,
    placement: PropTypes.string,
    location: customPropTypes.location.isRequired,
    routePushAction: PropTypes.func.isRequired,
    small: PropTypes.bool,
    disabled: PropTypes.bool,
    filterContext: PropTypes.string.isRequired,
    showInterval: PropTypes.bool,
};

ContextAwareDateSelector.defaultProps = {
    placement: 'bottom-start',
    small: false,
    disabled: false,
    showInterval: true,
};

const makeMapStateToProps = () => {
    const selectDateByContext = makeSelectDateByContext();
    return (state, ownProps) => ({
        selectedDate: selectDateByContext(state, ownProps.filterContext)
    });
};

export default withFilterContextConsumer(connect(makeMapStateToProps, {
    routePushAction: routeActions.push
})(withRouter(ContextAwareDateSelector)));
