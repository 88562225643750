import * as customPropTypes from 'src/customPropTypes';
import DataBody from 'src/components/metricTiles/layout/DataBody';
import PropTypes from 'prop-types';
import React from 'react';
import SampleDataLabel from 'src/components/labels/SampleDataLabel';
import styles from 'src/stylesheets/metricTile.scss';

const settings = {};

const selectedDate = {
    from: '2017-08-21',
    to: '2017-08-29',
    interval: 'daily',
    timezone: 'UTC'
};

const selectedGroup = {
    groupIds: ['-1'],
    profileIds: []
};

const SampleMetricDataTile = (props) => {
    const {
        metric, accountOptions, data, loading, error
    } = props;
    return (
        <div className={styles.tile}>
            <div className={styles.sampleLabel}>
                <SampleDataLabel />
            </div>
            <DataBody
              selectedDate={selectedDate}
              selectedProfilesOrGroups={selectedGroup}
              accountOptions={accountOptions}
              metric={metric}
              settings={settings}
              data={data}
              loading={loading}
              error={error}
              isSampleMetric
              showLinkInTable="none"
            />
        </div>
    );
};

SampleMetricDataTile.propTypes = {
    metric: customPropTypes.minimalMetricShapeForDataLoading.isRequired,
    accountOptions: customPropTypes.accountOptions.isRequired,
    data: customPropTypes.metricServerResponse,
    loading: PropTypes.bool.isRequired,
    error: customPropTypes.dataLoadingError,
};

export default SampleMetricDataTile;
