import React from 'react';
import styles from 'src/stylesheets/views/dataSources/dataSourceInfoList.scss';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const DataSourceInfoListItem = (props) => {
    const {
        label,
        icon,
        onMouseOver,
        onMouseOut,
        onClick,
        active,
        disabled
    } = props;

    return (
        <div
          className={classnames(styles.wrapper, { [styles.active]: active, [styles.disabled]: disabled, [styles.enabled]: !disabled })}
          onClick={!disabled ? onClick : () => {}}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
        >
            <IcomoonIcon icon={icon} className={styles.icon} />
            <div className={styles.label}>{label}</div>
        </div>
    );
};

DataSourceInfoListItem.propTypes = {
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    active: PropTypes.bool,
    onMouseOver: PropTypes.func,
    onMouseOut: PropTypes.func,
    onClick: PropTypes.func.isRequired
};

DataSourceInfoListItem.defaultProps = {
    onMouseOver: () => {},
    onMouseOut: () => {},
    active: false,
    disabled: false
};

export default DataSourceInfoListItem;
