import React from 'react';
import styles from 'src/stylesheets/pageNumbersWithScroller.scss';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import PaginatorItem from 'src/components/paginator/PaginatorItem';
import PageScroller from 'src/components/paginator/PageScroller';
import { range } from 'src/utils/array';
import PaginatorSiblings from 'src/components/paginator/PaginatorSiblings';
import classnames from 'classnames';

const memoizedRange = memoizeOne(range);

const PageNumbersWithScroller = (props) => {
    const {
        count, currentPage, onPageClick, boundaryCount, siblingCount, disabled
    } = props;

    const startPages = memoizedRange(1, Math.min(boundaryCount, count));
    const endPages = memoizedRange(Math.max(count - boundaryCount + 1, boundaryCount + 1), count);

    const handleOnScrollerClick = (page) => {
        onPageClick(page);
    };

    return (
        <nav className={classnames(styles.nav, { [styles.disabled]: disabled })}>
            <ul className={styles.list}>
                <PageScroller
                  onClick={() => handleOnScrollerClick(currentPage - 1)}
                  icon="chevron-big-left"
                  tooltip="Previous page"
                  disabled={currentPage <= 1}
                />
                {
                    startPages.map((pageNumber) => (
                        <PaginatorItem
                          key={pageNumber}
                          pageNumber={pageNumber}
                          onPageClick={onPageClick}
                          selected={pageNumber === currentPage}
                        />
                    ))
                }
                <PaginatorSiblings
                  onPageClick={onPageClick}
                  count={count}
                  currentPage={currentPage}
                  endPages={endPages}
                  boundaryCount={boundaryCount}
                  siblingCount={siblingCount}
                />
                {
                    endPages.map((pageNumber) => (
                        <PaginatorItem
                          key={pageNumber}
                          pageNumber={pageNumber}
                          onPageClick={onPageClick}
                          selected={pageNumber === currentPage}
                        />
                    ))
                }
                <PageScroller
                  onClick={() => handleOnScrollerClick(currentPage + 1)}
                  icon="chevron-big-right"
                  tooltip="Next page"
                  disabled={currentPage >= count}
                />
            </ul>
        </nav>
    );
};

PageNumbersWithScroller.propTypes = {
    disabled: PropTypes.bool,
    count: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    boundaryCount: PropTypes.number,
    siblingCount: PropTypes.number,
    onPageClick: PropTypes.func.isRequired
};

PageNumbersWithScroller.defaultProps = {
    boundaryCount: 1,
    siblingCount: 3,
    disabled: false
};

export default PageNumbersWithScroller;
