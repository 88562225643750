import { ensurePermission, writePermissions } from 'src/middleware/userPermissionCheck';
import * as customPropTypes from 'src/customPropTypes';
import * as overlayActions from 'src/actions/overlays';
import AuthUserUseCasesLabel from 'src/components/labels/AuthUserUseCasesLabel';
import Button from 'src/components/buttons/Button';
import { connect } from 'react-redux';
import DivWithTooltip from 'src/components/toolTips/DivWithTooltip';
import IcomoonIcon from 'src/components/IcomoonIcon';
import PropTypes from 'prop-types';
import React from 'react';
import RelativeTime from 'src/components/RelativeTime';
import styles from 'src/stylesheets/views/authentications/authUser/authUserListItemHeader.scss';
import AuthUserEnvIcon from 'src/components/AuthUserEnvIcon';
import _includes from 'lodash/includes';

const authUsersToShowConnectedProfilesCount = ['graph', 'linkedIn', 'snapchat'];

const AuthUserListItemHeader = (props) => {
    const {
        authUser,
        numberOfConnectedAccounts,
        toggleListAction,
        menuState,
        modalsShowAuthUserDeleteAction
    } = props;
    const {
        name, environment, lastUpdatedTime, deprecated
    } = authUser;
    const icon = menuState ? 'chevron-big-up' : 'chevron-big-down';

    const lastUpdated = (
        <span className={styles.updateInfo}>Updated:&nbsp;<RelativeTime date={lastUpdatedTime} thresholdTimestamp={365 * 86400} /></span>
    );

    return (
        <div className={styles.wrapper}>
            <div className={styles.left} onClick={() => { toggleListAction(); }}>
                <div className={styles.toggleButton}>
                    <IcomoonIcon icon={icon} className={styles.icon} />
                </div>
                <div className={styles.userInfo}>
                    <div className={styles.networkIcon}>
                        <AuthUserEnvIcon isDeprecated={deprecated} environment={environment} className={styles.icon} />
                    </div>
                    <div className={styles.authUserLabel}>
                        <AuthUserUseCasesLabel
                          userName={name}
                          valid={authUser.valid}
                        />
                    </div>
                    <div className={styles.section}>
                        {
                            _includes(authUsersToShowConnectedProfilesCount, authUser.environment)
                            && (
                            <DivWithTooltip tooltip={`${numberOfConnectedAccounts} accounts connected`}>
                                {`${numberOfConnectedAccounts} accounts connected`}
                            </DivWithTooltip>
                            )
                        }
                    </div>
                    <div className={styles.section}>
                        <DivWithTooltip tooltip={lastUpdated}>
                            {lastUpdated}
                        </DivWithTooltip>
                    </div>
                </div>
            </div>
            <div className={styles.button}>
                <Button
                  label="Delete"
                  warning
                  small
                  onClick={() => { modalsShowAuthUserDeleteAction(authUser); }}
                  className={menuState ? '' : 'list-hover-button-opacity'}
                />
            </div>
        </div>
    );
};

AuthUserListItemHeader.propTypes = {
    authUser: customPropTypes.authUser.isRequired,
    toggleListAction: PropTypes.func.isRequired,
    menuState: PropTypes.bool,
    modalsShowAuthUserDeleteAction: PropTypes.func.isRequired,
    numberOfConnectedAccounts: PropTypes.number.isRequired
};

AuthUserListItemHeader.defaultProps = {
    menuState: false
};

export default connect(null, {
    modalsShowAuthUserDeleteAction: ensurePermission(overlayActions.modalsShowAuthUserDelete, writePermissions.deleteAuthUser)
})(AuthUserListItemHeader);
