import * as customPropTypes from 'src/customPropTypes';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import { connect } from 'react-redux';
import MainNavTextLink from 'src/components/buttons/MainNavTextLink';
import React from 'react';
import { selectLoggedInUser } from 'src/selectors/users';
import UserInfo from 'src/components/UserInfo';
import UserNotificationMenu from 'src/components/userNotificationMenu/UserNotificationMenu';
import { userRoutes } from 'src/routePaths';
import UserSettingWithSpaceSelectorPopover from 'src/components/UserSettingWithSpaceSelectorPopover';
import withPopover from 'src/components/withPopover';

const MainNavUserIconLinkWithPopover = withPopover(MainNavTextLink);

const BottomMenu = (props) => {
    const { user } = props;
    const userSettingWithSpaceSelectorPopover = <UserSettingWithSpaceSelectorPopover user={user} />;

    return (
        <ButtonGroup vertical>
            <UserNotificationMenu />
            <MainNavUserIconLinkWithPopover
              placement="right"
              alsoActiveOn={userRoutes}
              overlay={userSettingWithSpaceSelectorPopover}
            >
                <UserInfo
                  name={user.firstName}
                  iconUrl={user.gravatar35px}
                />
            </MainNavUserIconLinkWithPopover>
        </ButtonGroup>
    );
};

BottomMenu.propTypes = {
    user: customPropTypes.user.isRequired
};

const mapStateToProps = (state) => ({
    user: selectLoggedInUser(state)
});

export default connect(mapStateToProps)(BottomMenu);
