import { selectMetricSearchFilterBy, selectMetricSearchSelectedTags } from 'src/selectors/metricSearch';
import * as customPropTypes from 'src/customPropTypes';
import * as metricSearchActionCreators from 'src/actions/metricSearch';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { hasMetricFilters } from 'src/utils/discoverUtiles';
import IcomoonIcon from 'src/components/IcomoonIcon';
import MetricTypeFilters from 'src/components/views/discover/MetricTypeFilters';
import { parseCamelCaseToSentenceCase } from 'src/parsers';
import PropTypes from 'prop-types';
import React from 'react';
import { selectTagsClusteredByType, visualizationTagIconMapping } from 'src/selectors/tags';
import styles from 'src/stylesheets/views/discover/discoverFilter.scss';
import _get from 'lodash/get';

const DiscoverFilter = (props) => {
    const {
        tagTypesWithTags,
        selectedTags,
        onClickAdd,
        onClickRemove,
        filterType
    } = props;
    const selectedTagIds = selectedTags.map((selectedTag) => selectedTag.id);
    return (
        <div>
            <div className={styles.headline}>Filters</div>
            {
                hasMetricFilters(filterType)
                && <MetricTypeFilters filterType={filterType} />
            }
            {
                tagTypesWithTags.map((tagTypeWithTags) => {
                    if (!hasMetricFilters(filterType) && tagTypeWithTags.id === '3') {
                        return null;
                    }
                    return (
                        <div key={tagTypeWithTags.id} className={styles.tagTypeWrapper}>
                            <div className={styles.tagTypeHeadline}>{parseCamelCaseToSentenceCase(tagTypeWithTags.name)}</div>
                            <ul className={styles.tagList}>
                                { tagTypeWithTags.tags.map((tag) => {
                                    const icon = _get(visualizationTagIconMapping, tag.id, null);
                                    const isActive = selectedTagIds.indexOf(tag.id) >= 0;
                                    return (
                                        <li key={tag.id} className={styles.tagWrapper}>
                                            <a
                                              key={tag.id}
                                              value={tag.id}
                                              className={classnames(styles.tag, { [styles.activeTag]: isActive })}
                                              onClick={() => {
                                                  if (!isActive) {
                                                      onClickAdd(tag);
                                                  } else {
                                                      onClickRemove(tag);
                                                  }
                                              }}
                                            >
                                                { isActive && <IcomoonIcon icon="check-mark" className={styles.checkmark} /> }
                                                {
                                                    icon !== null
                                                    && (
                                                        <IcomoonIcon icon={icon} className={styles.icon} />
                                                    )
                                                }
                                                {tag.name}
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    );
                })
            }
        </div>
    );
};

DiscoverFilter.propTypes = {
    tagTypesWithTags: customPropTypes.tagTypesWithTags.isRequired,
    selectedTags: customPropTypes.tags.isRequired,
    onClickAdd: PropTypes.func.isRequired,
    onClickRemove: PropTypes.func.isRequired,
    filterType: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
    tagTypesWithTags: selectTagsClusteredByType(state),
    selectedTags: selectMetricSearchSelectedTags(state),
    filterType: selectMetricSearchFilterBy(state)
});

export default connect(mapStateToProps, {
    onClickAdd: metricSearchActionCreators.metricSearchSelectionReplace,
    onClickRemove: metricSearchActionCreators.metricSearchSelectionRemove
})(DiscoverFilter);
