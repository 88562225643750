import React from 'react';
import { ensurePermission, writePermissions } from 'src/middleware/userPermissionCheck';
import { modalShowPostTagRulesDelete } from 'src/actions/overlays';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import IcomoonIcon from 'src/components/IcomoonIcon';
import styles from 'src/stylesheets/views/postTags/postTagManager/postTagRuleItem.scss';
import withTooltip from 'src/components/withToolTip';

const IcomoonIconIconWithTooltip = withTooltip(IcomoonIcon);

const PostTagRuleDelete = (props) => {
    const { modalShowPostTagRulesDeleteAction, postTagRuleId } = props;
    return (
        <IcomoonIconIconWithTooltip
          icon="delete"
          tooltip="Delete rule"
          onClick={() => { modalShowPostTagRulesDeleteAction(postTagRuleId); }}
          className={styles.deleteIcon}
        />
    );
};

PostTagRuleDelete.propTypes = {
    postTagRuleId: PropTypes.string.isRequired,
    modalShowPostTagRulesDeleteAction: PropTypes.func.isRequired
};

export default connect(null, {
    modalShowPostTagRulesDeleteAction: ensurePermission(modalShowPostTagRulesDelete, writePermissions.deletePostTagRule)
})(PostTagRuleDelete);
