import React from 'react';
import IntegrationEmptyState from 'src/components/views/IntegrationEmptyState';
import emptyDataPushTasks from 'src/resources/illustrations/datapushtasks.png';

const DataPushTaskNotAllowedState = () => (
    <IntegrationEmptyState
      title="Push data to an external database"
      message="Automate all accessible data from quintly to your own external data table. Based on specific intervals,
            it allows for a higher frequency of data sent directly to you.
            Upgrade to data push tasks by contacting our customer success team."
      image={emptyDataPushTasks}
    />
);

export default DataPushTaskNotAllowedState;
