import React, { useState } from 'react';
import * as customPropTypes from 'src/customPropTypes';
import AuthUserListItemHeader from 'src/components/views/authentications/authUser/AuthUserListItemHeader';
import AccountsUseCasesStatusTable from 'src/components/views/authentications/table/AccountsUseCasesStatusTable';
import styles from 'src/stylesheets/views/authentications/authUser/authUserListItem.scss';
import { makePlatformTypeFilter } from 'src/selectors/utils';
import AccountTypeContext from 'src/components/context/AccountTypeContext';

const platformTypeFilter = makePlatformTypeFilter();

const AuthUserListItem = (props) => {
    const {
        authUser,
        profiles,
        adAccounts
    } = props;

    let numberOfConnectedAccounts = 0;

    const [menuState, toggleMenuState] = useState(false);
    const toggleMenuStateAction = () => {
        toggleMenuState(!menuState);
    };
    let body = null;

    const { environment } = authUser;
    if (environment === 'graph') {
        const facebook = platformTypeFilter(profiles, 'facebook');
        const instagram = platformTypeFilter(profiles, 'instagram');
        numberOfConnectedAccounts = facebook.length + instagram.length + adAccounts.length;
        body = (
            <div className={styles.listItemBody}>
                <div className={styles.network}>
                    <AccountTypeContext.Provider value={{ accountType: 'profiles' }}>
                        <AccountsUseCasesStatusTable
                          accounts={facebook}
                          platformType="facebook"
                          authUser={authUser}
                        />
                    </AccountTypeContext.Provider>
                </div>
                <div className={styles.network}>
                    <AccountTypeContext.Provider value={{ accountType: 'profiles' }}>
                        <AccountsUseCasesStatusTable
                          accounts={instagram}
                          platformType="instagram"
                          authUser={authUser}
                        />
                    </AccountTypeContext.Provider>
                </div>
                <div className={styles.network}>
                    <AccountTypeContext.Provider value={{ accountType: 'adAccounts' }}>
                        <AccountsUseCasesStatusTable
                          accounts={adAccounts}
                          platformType="meta"
                          authUser={authUser}
                        />
                    </AccountTypeContext.Provider>
                </div>
            </div>
        );
    } else if (environment === 'snapchat') {
        numberOfConnectedAccounts = profiles.length + adAccounts.length;
        body = (
            <div className={styles.listItemBody}>
                <div className={styles.network}>
                    <AccountTypeContext.Provider value={{ accountType: 'profiles' }}>
                        <AccountsUseCasesStatusTable
                          accounts={profiles}
                          platformType="snapchatShow"
                          authUser={authUser}
                        />
                    </AccountTypeContext.Provider>
                </div>
            </div>
        );
    } else {
        numberOfConnectedAccounts = profiles.length + adAccounts.length;
        body = (
            <div className={styles.listItemBody}>
                <div className={styles.network}>
                    <AccountTypeContext.Provider value={{ accountType: 'profiles' }}>
                        <AccountsUseCasesStatusTable
                          accounts={profiles}
                          platformType={environment}
                          authUser={authUser}
                        />
                    </AccountTypeContext.Provider>
                </div>
            </div>
        );
    }
    return (
        <div className={styles.listItem}>
            <div className={styles.header}>
                <AuthUserListItemHeader
                  menuState={menuState}
                  authUser={authUser}
                  numberOfConnectedAccounts={numberOfConnectedAccounts}
                  toggleListAction={toggleMenuStateAction}
                />
            </div>
            {
                menuState
                && body
            }
        </div>
    );
};

AuthUserListItem.propTypes = {
    authUser: customPropTypes.authUser.isRequired,
    profiles: customPropTypes.profiles.isRequired,
    adAccounts: customPropTypes.adAccounts.isRequired
};

export default AuthUserListItem;
