import { modalShowAddDashboardChoice, modalShowAddFolder } from 'src/actions/overlays';
import PageHeadlineWithIcon from 'src/components/header/layout/PageHeadlineWithIcon';
import EntityOwnershipNameResolver from 'src/components/onwership/EntityOwnershipNameResolver';
import * as customPropTypes from 'src/customPropTypes';
import * as routeActions from 'react-router-redux';
import BottomRow from 'src/components/header/layout/BottomRow';
import Breadcrumb from 'src/components/breadcrumb/Breadcrumb';
import Button from 'src/components/buttons/Button';
import ButtonGroup from 'src/components/buttons/ButtonGroup';
import Column from 'src/components/header/layout/Column';
import { connect } from 'react-redux';
import Header from 'src/components/header/layout/Header';
import PropTypes from 'prop-types';
import React from 'react';
import TopRow from 'src/components/header/layout/TopRow';
import { SUPER_ROOT } from 'src/utils/dashboardFolders';
import { getFolderIconByOwnersipType, getReadableOwnershipAndEntityType } from 'src/utils/ownership';
import { getPathForFolderOfType } from 'src/utils/routes';

const DashboardsHeader = (props) => {
    const onItemClick = (id) => {
        const { routePushAction, type } = props;
        const path = getPathForFolderOfType(id, type);
        routePushAction({ pathname: path });
    };
    const {
        folder, modalShowAddDashboardChoiceAction, modalShowAddFolderAction, type
    } = props;
    const { id, name } = folder;
    return (
        <Header>
            <TopRow>
                <Column flexGrow>
                    <PageHeadlineWithIcon
                      iconToolTip={`This is a ${getReadableOwnershipAndEntityType(type, 'folder')}`}
                      icon={getFolderIconByOwnersipType(type)}
                    >
                        {name}
                    </PageHeadlineWithIcon>
                </Column>
                <Column>
                    <ButtonGroup>
                        <Button
                          onClick={() => {
                              modalShowAddDashboardChoiceAction(id);
                          }}
                          label={<>Add <EntityOwnershipNameResolver entityId={id} entityType="folder" /> dashboard</>}
                          icon="plus"
                          action
                        />
                        <Button
                          onClick={() => {
                              modalShowAddFolderAction(id);
                          }}
                          label={<>Create <EntityOwnershipNameResolver entityType="folder" entityId={id} /> folder</>}
                        />
                    </ButtonGroup>
                </Column>
            </TopRow>
            <BottomRow>
                <Breadcrumb fixedItemBefore={{ id: SUPER_ROOT, name: 'Dashboards' }} currentFolderId={id} onItemClick={onItemClick} />
            </BottomRow>
        </Header>
    );
};

DashboardsHeader.propTypes = {
    modalShowAddDashboardChoiceAction: PropTypes.func.isRequired,
    modalShowAddFolderAction: PropTypes.func.isRequired,
    routePushAction: PropTypes.func.isRequired,
    folder: customPropTypes.folder.isRequired,
    type: customPropTypes.ownershipType.isRequired
};

export default connect(null, {
    modalShowAddDashboardChoiceAction: modalShowAddDashboardChoice,
    modalShowAddFolderAction: modalShowAddFolder,
    routePushAction: routeActions.push
})(DashboardsHeader);
